<template>
  <div ref="topo" v-if="Object.keys(teste).length > 0">
    <div v-for="pergunta in teste.json_questoes.questions" :key="pergunta.id">
      <template v-if="pergunta.type === 'text'">
        <TextoSimples
          :propValue="pergunta.value"
          :propRequired="pergunta.required"
          @resp="pergunta.resp = $event"
        />
      </template>
      <template v-else-if="pergunta.type === 'ru'">
        <RespostaUnica
          :propValue="pergunta.value"
          :propRequired="pergunta.required"
          :propOptions="pergunta.options"
          @resp="pergunta.resp = $event"
        />
      </template>
      <template v-else-if="pergunta.type === 'dico'">
        <RespostaUnica
          :propValue="pergunta.value"
          :propRequired="pergunta.required"
          :propOptions="pergunta.options"
          @resp="pergunta.resp = $event"
        />
      </template>
      <template v-else-if="pergunta.type === 'multi'">
        <MultiplaEscolha
          :propValue="pergunta.value"
          :propRequired="pergunta.required"
          :propOptions="pergunta.options"
          @resp="pergunta.resp = $event"
        />
      </template>
    </div>
    <div class="row pt-2 border-top">
      <div class="col d-flex justify-content-between">
          <base-button type="secondary" @click="propCancelaTeste">Cancelar</base-button
          >
          <base-button type="primary" @click="emiteDados">Enviar</base-button
          >
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loading } from 'element-ui';
import TextoSimples from './TextoSimples.vue'
import RespostaUnica from './RespostaUnica.vue';
import MultiplaEscolha from './MultiplaEscolha.vue';
Vue.use(Loading.directive);
export default {
  name: "RealizaTesteVaga",
  props: {
    propTeste: { type: Object },
    propTesteRepondido: { type: Function },
    propCancelaTeste: { type: Function }
  },
  components: {
    TextoSimples,
    RespostaUnica,
    MultiplaEscolha
  },
  data() {
    return {
      teste: {},
      loadingPergunta: false,
      flRespondido: false,
      perguntaAtual: 1,
    }
  },
  methods: {
    emiteDados() {
      this.$emit('resTeste', this.teste);
      setTimeout(() => {
        this.propTesteRepondido();
      }, 300);
    },
    voltarPergunta() {
      this.loadingPergunta = true;
      setTimeout(() => {
        this.perguntaAtual -= 1;
        this.loadingPergunta = false;
      }, 500);
    },
    submitQuestionario(){
      if (this.propTeste.json_questoes.questions.length > this.perguntaAtual) {
        console.log('propTeste.json_questoes.questions.length > perguntaAtual -> ')
        this.loadingPergunta = true;
        var teste = this.propTeste.json_questoes.questions.find( item => item.id === this.perguntaAtual);
        if (teste.required){
          console.log('entrou no required -> ')
          if (teste.type === 'multi'){
            if (teste.resp.length < 1) {
              this.loadingPergunta = false;
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              return false;
            } else {
              this.loadingPergunta = true;
              setTimeout(() => {
                this.perguntaAtual += 1;
                this.loadingPergunta = false;
              }, 500);
            }
          } else {
            console.log('entrou no else do required -> ')
            if (teste.resp === '') {
              this.loadingPergunta = false;
              alert(`Você precisa responder a pergunta: ${teste.value}`);
              return false;
            } else {
              this.loadingPergunta = true;
              setTimeout(() => {
                this.perguntaAtual += 1;
                this.loadingPergunta = false;
              }, 500);
            }
          }
        } else {
          this.loadingPergunta = true;
          setTimeout(() => {
            this.perguntaAtual += 1;
            this.loadingPergunta = false;
          }, 500);
        }
      } else {
        console.log('propTeste.json_questoes.questions.length < perguntaAtual -> ')
        setTimeout(() => {
          this.loadingPergunta = false;
          this.flRespondido = true;
          this.goto('topo');
        }, 5000);
      }
    },
    contaRespostas() {
      var res = this.propTeste.json_questoes.questions.filter(i => i.type === 'ru')
      var i = res.filter(i => i.resp === 'i')
      var c = res.filter(c => c.resp === 'c')
      var o = res.filter(o => o.resp === 'o')
      var a = res.filter(a => a.resp === 'a')
      console.log('i (s) -> ', i.length)
      console.log('c (i) -> ', c.length)
      console.log('o (c) -> ', o.length)
      console.log('a (d) -> ', a.length)
    },
    goto(refName) {
      var element = this.$refs[refName];
      if (Array.isArray(element)){
        element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      } else {
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      }
    },
  },
  mounted() {
    this.teste = structuredClone(this.propTeste)
  }
}
</script>

<style>

</style>
