import DefaultLayout from "@/views/Layout/DefaultLayout";
import Header from "@/layout/AppHeader";
import Footer from "@/layout/AppFooter";

const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login.vue');
const RecuperaSenha = () => import(/* webpackChunkName: "recuperarsenha" */ '@/views/RecuperaSenha.vue');

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue');

const Perfil  = () => import(/* webpackChunkName: "perfil" */ '@/views/Perfil/Account.vue');

const Curriculos  = () => import(/* webpackChunkName: "curriculos" */ '@/views/Curriculos/Curriculos.vue');
const NovoCurriculo  = () => import(/* webpackChunkName: "novocurriculo" */ '@/views/Curriculos/NovoCurriculo.vue');
const VisualizarCv  = () => import(/* webpackChunkName: "visualizarcv" */ '@/views/Curriculos/VisualizarCv.vue');

const ProcurarVagas  = () => import(/* webpackChunkName: "procurarvagas" */ '@/views/Vagas/ProcurarVagas.vue');
const VisualizarVaga  = () => import(/* webpackChunkName: "vaga" */ '@/views/Vagas/VisualizarVaga.vue');

const NotFound  = () => import(/* webpackChunkName: "NotFound" */ '@/views/Error.vue');

let authPages = {
  path: '/',
  name: 'Auth',
  beforeRouteEnter: (to, form, next) => {
    console.log('beforeEnter da Auth... ', to)
  },
  children: [
    {
      path: '/login',
      name: 'login',
      components: {
        //header: Header,
        default: Login,
        footer: Footer
      }
    },
    {
      path: "recuperarsenha",
      name: "recuperarsenha",
      components: {
        default: RecuperaSenha,
        footer: Footer
      }
    },
    { path: '*', component: NotFound }
  ]
};

const routes = [
  {
    path: "/",
    redirect: '/dashboard',
    name: "Inicio",
    beforeRouteEnter: (to, form, next) => {
      console.log('beforeEnter da routes... ', to)
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        components: {
          header: Header,
          default: Dashboard,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "perfil",
        name: "perfil",
        components: {
          header: Header,
          default: Perfil,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "curriculos",
        name: "curriculos",
        components: {
          header: Header,
          default: Curriculos,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "novocurriculo",
        name: "novocurriculo",
        components: {
          header: Header,
          default: NovoCurriculo,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "visualizarcv",
        name: "visualizarcv",
        components: {
          header: Header,
          default: VisualizarCv,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "procurarvagas/:cargo/:cidade",
        name: "procurarvagas",
        components: {
          header: Header,
          default: ProcurarVagas,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "vagas",
        name: "vagas",
        components: {
          header: Header,
          default: ProcurarVagas,
          footer: Footer
        },
        props: {
          header: { navbarType: "primary" }
        }
      },
      {
        path: "/vaga/:id/:url",
        name: "vaga",
        components: {
          header: Header,
          default: VisualizarVaga,
          footer: Footer
        },
        props: {
          header: {
            navbarType: "primary"
          }
        }
      }
    ]
  },
  authPages
  /* {
    path: "/login",
    name: "login",
    components: {
      default: Login,
      footer: Footer
    }
  },
  {
    path: "/recuperarsenha",
    name: "recuperarsenha",
    components: {
      default: RecuperaSenha,
      footer: Footer
    }
  } */

]

export default routes;
