<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row justify-content-sm-between border-bottom">
            <div class="col-lg-3 m-1">
              <h5 class="my-0"><span class="text-muted">Visualização de Vaga</span> <badge type="info" v-if="!candidaturaStatus">Você já se candidatou a essa vaga!</badge></h5>
              <a href="javascript:void(0)" @click="goBack"><small>Voltar</small></a>
            </div>
            <div class="col-lg-3 m-1">
              <base-button type="success" :disabled="!candidaturaStatus" @click="handleCandidatura">Candidatar-se</base-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="section-cards mb-5">
      <div class="content-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h3 class="title mb-1">{{vaga.nm_cargo}} - <badge :type="retornaTipoTrabalhoBadge(vaga.json_vaga.fl_tipo_trabalho)">{{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}}</badge></h3>
              <h4 class="mt-1 mb-2">
                <span class="text-muted">{{vaga.json_vaga.nm_cidade}} - {{vaga.json_vaga.nm_pais}}</span>
                <span class="text-success" v-if="vaga.json_vaga.fl_deficientes === '1'"> - Vaga também para PcD</span>
              </h4>
              <!-- Bloco do vídeo da vaga -->
              <div class="my-4" v-if="vaga.json_vaga.fl_tipo_video !== '0'">
              <template v-if="vaga.json_vaga.fl_tipo_video === '1'">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" :src="vaga.json_vaga.hl_video" allowfullscreen></iframe>
                </div>
              </template>
              <template v-else-if="vaga.json_vaga.fl_tipo_video === '2'">
                <div style="padding:56.25% 0 0 0;position:relative;">
                  <iframe :src="`${vaga.json_vaga.hl_video}?h=9eefc3beba&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title=""></iframe>
                </div>
                <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
              </template>
              </div>
              <!-- Detalhes da vaga -->
              <template v-if="vaga.json_vaga.fl_oculta_empresa === '0'">
                <h5 class="mb-1"><span class="text-muted">Empresa:</span> {{vaga.json_vaga.nm_empresa}}</h5>
              </template>
              <template v-if="vaga.json_vaga.fl_oculta_salario === '0'">
                <h5 class="mb-1"><span class="text-muted">Salário:</span> R$ {{vaga.json_vaga.nu_salario}}</h5>
              </template>
              <h5 class="mt-2 mb-1">
                <span class="text-muted">Área de atuação:</span> {{retornaAreaAtuacao(vaga.json_vaga.nm_area)}}
                <span class="text-muted"> - Cargo:</span> {{retornaNivelHierarquico(vaga.json_vaga.nm_nivel)}}
              </h5>
              <h5 class="mb-1">
                <span class="text-muted">Contratação:</span> {{retornaTiposContrato(vaga.json_vaga.fl_contratacao)}}
                <span class="text-muted"> - Jornada de trabalho:</span> {{retornaJornada(vaga.json_vaga.nm_turno_trabalho)}}
              </h5>
              <!-- Decrição e requisitos da vaga -->
              <h5 class="text-muted mt-4 mb-1">Descrição:</h5>
              <div v-html="vaga.json_vaga.tx_descricao"></div>
              <h5 class="text-muted mt-4 mb-1">Requisitos:</h5>
              <div v-html="vaga.json_vaga.tx_requisitos"></div>
              <template v-if="vaga.json_vaga.json_idiomas.length > 0">
                <h6 class="text-muted ml-1 mt-2">Idiomas:</h6>
                  <ul>
                  <template v-for="idioma in vaga.json_vaga.json_idiomas">
                    <li :key="idioma.id">{{idioma.value}} - {{retornaNivelIdioma(idioma.nivel)}}</li>
                  </template>
                  </ul>
              </template>
              <template v-if="vaga.json_vaga.json_formacao.length > 0">
                <h6 class="text-muted ml-1 mt-2">Formação:</h6>
                  <ul>
                  <template v-for="item in vaga.json_vaga.json_formacao">
                    <li :key="item.id">{{item.value}}</li>
                  </template>
                  </ul>
              </template>
              <template v-if="vaga.json_vaga.json_certificacao.length > 0">
                <h6 class="text-muted ml-1 mt-2">Certificação:</h6>
                  <ul>
                  <template v-for="item in vaga.json_vaga.json_certificacao">
                    <li :key="item.id">{{item.value}}</li>
                  </template>
                  </ul>
              </template>
              <!-- Beneficíos -->
              <template v-if="vaga.json_vaga.json_beneficios.length > 0">
                <h5 class="text-muted mt-4 mb-1">Beneficíos:</h5>
                  <ul>
                  <template v-for="(item, index) in vaga.json_vaga.json_beneficios">
                    <li :key="index">{{item}}</li>
                  </template>
                  </ul>
              </template>
              <!-- Tags -->
              <template v-if="vaga.json_tags !== null && vaga.json_tags.length > 0">
                <h5 class="text-muted mt-4 mb-1">Tags:</h5>
                  <template v-for="(item, index) in vaga.json_tags">
                    <span class="badge badge-default p-1 m-1" :key="index">{{item}}</span>
                  </template>
              </template>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-lg-3">
              <base-button type="success" :disabled="!candidaturaStatus" @click="handleCandidatura">Candidatar-se</base-button>
            </div>
          </div>
          <div class="row border-top">
            <div class="col-lg-1">
              <a href="javascript:void(0)" @click="goBack"><small>Voltar</small></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modal de Seleção de Currículos para CvPro -->
    <modal
      :show.sync="modalSeletorCv"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Selecione o Currículo para envar
      </h5>
      <div class="row mb-2" v-for="(cv, index) in curriculos" :key="index">
        <div class="col">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" name="cv" :id="index" :value="cv.id_curriculo" v-model="cvSelecionado">
            <label :for="index" class="custom-control-label">{{cv.nm_curriculo}} - {{retornaTipoCv(cv.fl_tipo)}}</label>
          </div>
        </div>
      </div>
      <!-- Botões Incluir e Cancelar -->
      <div class="row pt-2 border-top">
        <div class="col d-flex justify-content-between">
          <base-button
            type="secondary"
            @click="modalSeletorCv = false"
            >Cancelar</base-button
          >
          <base-button type="primary" @click="confirmaCandidatura(cvSelecionado)"
            >Enviar</base-button
          >
        </div>
      </div>
    </modal>
    <!-- Modal de Teste -->
    <modal
      :show.sync="modalTeste"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Responda as perguntas para confirmar a sua candidatura
      </h5>
      <div class="row mb-2">
        <div class="col">
          <RealizaTesteVaga
            v-if="vaga.json_vaga.id_teste !== ''"
            :propTeste="vaga.json_vaga.json_questoes"
            :propTesteRepondido="handleCandidaturaTeste"
            :propCancelaTeste="cancelarTeste"
            @resTeste="resTeste = $event"
          />
          <!-- <pre>{{vaga.json_vaga.json_questoes}}</pre> -->
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Modal from "@/components/Modal.vue";

import RealizaTesteVaga from "../../components/Testes/RealizaTesteVaga.vue";

export default {
  name: "VisualizarVaga",
  components: {
    Modal,
    RealizaTesteVaga
  },
  data() {
    return {
      loading: false,
      modalSeletorCv: false,
      modalTeste: false,
      cvSelecionado: "",
      resTeste: {},
      areasatuacao: [
      { value: "51", text: "Administração" },
      { value: "52", text: "Agricultura, Pecuária, Veterinária" },
      { value: "92", text: "Alimentação / Gastronomia" },
      { value: "55", text: "Arquitetura, Decoração, Design" },
      { value: "56", text: "Artes" },
      { value: "57", text: "Auditoria" },
      { value: "58", text: "Ciências, Pesquisa " },
      { value: "59", text: "Comercial, Vendas" },
      { value: "60", text: "Comércio Exterior, Importação, Exportação" },
      { value: "62", text: "Compras" },
      { value: "63", text: "Comunicação, TV, Cinema" },
      { value: "64", text: "Construção, Manutenção" },
      { value: "66", text: "Contábil, Finanças, Economia" },
      { value: "67", text: "Cultura, Lazer, Entretenimento" },
      { value: "69", text: "Educação, Ensino, Idiomas " },
      { value: "70", text: "Engenharia" },
      { value: "71", text: "Estética" },
      { value: "72", text: "Hotelaria, Turismo" },
      { value: "73", text: "Industrial, Produção, Fábrica" },
      { value: "74", text: "Informática, TI, Telecomunicações" },
      { value: "75", text: "Jurídica" },
      { value: "54", text: "Logística" },
      { value: "77", text: "Marketing" },
      { value: "78", text: "Meio Ambiente, Ecologia" },
      { value: "93", text: "Moda" },
      { value: "79", text: "Qualidade " },
      { value: "80", text: "Química, Petroquímica" },
      { value: "81", text: "Recursos Humanos" },
      { value: "82", text: "Saúde" },
      { value: "83", text: "Segurança" },
      { value: "76", text: "Serviços Gerais" },
      { value: "85", text: "Serviço Social e Comunitário" },
      { value: "86", text: "Telemarketing" },
      { value: "87", text: "Transportes" }
      ],
      niveishierarquicos: [
        { value: "1", text: "Estagiário" },
        { value: "2", text: "Operacional" },
        { value: "3", text: "Auxiliar" },
        { value: "4", text: "Assistente" },
        { value: "5", text: "Trainee" },
        { value: "6", text: "Analista" },
        { value: "7", text: "Encarregado" },
        { value: "8", text: "Supervisor" },
        { value: "9", text: "Consultor" },
        { value: "10", text: "Especialista" },
        { value: "11", text: "Coordenador" },
        { value: "12", text: "Gerente" },
        { value: "13", text: "Diretor" }
      ],
      tipoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Híbrido" },
        { value: "3", text: "Home Office" }
      ],
      jornadas: [
        { value: "1", text: "Período Integral" },
        { value: "2", text: "Parcial manhãs" },
        { value: "5", text: "Parcial tardes" },
        { value: "6", text: "Parcial noites" },
        { value: "12", text: "Noturno" }
      ],
      tiposcontrato: [
        { value: "1", text: "Autônomo" },
        { value: "2", text: "Cooperado" },
        { value: "3", text: "Efetivo (CLT)" },
        { value: "4", text: "Estágio" },
        { value: "5", text: "Outros" },
        { value: "6", text: "Prestador de Serviços (PJ)" },
        { value: "7", text: "Temporário" },
        { value: "8", text: "Trainee" }
      ],
      nivelIdioma: [
        { value: "1", text: "Básico" },
        { value: "2", text: "Intermediário" },
        { value: "3", text: "Avançado" },
        { value: "4", text: "Fluente" }
      ],
      tiposCvs: [
        { id: 1, text: "Padrão (Normal)", selected: true },
        { id: 2, text: "Inglês", selected: false },
        { id: 3, text: "Espanhol", selected: false },
        { id: 4, text: "Portfólio", selected: false }
      ],
    }
  },
  computed: {
    vaga() {
      return this.$store.state.vagas.find(v => v.id_processo_vaga === this.$route.params.id);
    },
    candidaturaStatus() {
      if(this.$store.state.candidaturas.findIndex(i => i.id_processo_vaga === this.$route.params.id) > -1){
        return false;
      } else {
        return true;
      }
    },
    curriculos() {
      return this.$store.state.curriculos;
    },
    candidato() {
      return this.$store.state.candidato;
    },
    cvpro() {
      return this.$store.state.cvpro;
    }
  },
  methods: {
    retornaAreaAtuacao(value) {
      var res = this.areasatuacao.find(i => i.value === value);
      return res.text;
    },
    retornaNivelHierarquico(value) {
      var res = this.niveishierarquicos.find(i => i.value === value);
      return res.text;
    },
    retornaTiposContrato(value) {
      var res = this.tiposcontrato.find(i => i.value === value);
      return res.text;
    },
    retornaJornada(value) {
      var res = this.jornadas.find(i => i.value === value);
      return res.text;
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoTrabalhoBadge(value) {
      if (value === '1'){
        return "primary";
      } else if(value === '2'){
        return "info";
      } else {
        return "success";
      }
    },
    retornaNivelIdioma(value) {
      var res = this.nivelIdioma.find(i => i.value === value);
      return res.text;
    },
    retornaTipoCv(tipo) {
      var tipo = this.tiposCvs.find(c => c.id === parseInt(tipo));
      return tipo.text;
    },
    handleCandidatura() {
      if (this.vaga.json_vaga.id_teste !== '') {
        // Vaga possui Teste
        this.modalTeste = true;
      } else {
        if(this.curriculos.length < 1) {
          alert('Você precisa criar um currículo para se candidatar!')
        } else if (this.curriculos.length > 1) {
          if(this.cvpro.fl_status) {
            this.modalSeletorCv = true;
          } else {
            var cv = this.curriculos.find(c => c.fl_principal === '1');
            this.confirmaCandidatura(cv.id_curriculo)
          }
        } else {
          this.confirmaCandidatura(this.curriculos[0].id_curriculo)
        }
      }
    },
    handleCandidaturaTeste() {
      this.modalTeste = false;
      if (this.curriculos.length > 1) {
        if(this.cvpro.fl_status) {
          this.modalSeletorCv = true;
        } else {
          var cv = this.curriculos.find(c => c.fl_principal === '1');
          this.confirmaCandidatura(cv.id_curriculo)
        }
      } else {
        this.confirmaCandidatura(this.curriculos[0].id_curriculo)
      }
    },
    async confirmaCandidatura(id) {
      this.modalSeletorCv = false;
      const cvDb = this.curriculos.find(c => c.id_curriculo === id);
      var novoCv = {
        id_processo_vaga: this.vaga.id_processo_vaga,
        id_empresa: this.vaga.id_empresa,
        id_curriculo : cvDb.id_curriculo,
        id_candidato : this.candidato.id_candidato,
        nm_nome  : this.candidato.nm_nome,
        hl_email  : this.candidato.hl_email,
        hl_foto  : this.candidato.hl_foto,
        fl_deficiencia  : this.candidato.fl_deficiencia,
        fl_empregado  : this.candidato.fl_empregado,
        dt_nasc  : this.candidato.dt_nasc,
        fl_sexo  : this.candidato.fl_sexo,
        fl_estadocivil  : this.candidato.fl_estadocivil,
        nu_nacionalidade  : this.candidato.nu_nacionalidade,
        nu_celular  : this.candidato.nu_celular,
        nu_telefone  : this.candidato.nu_telefone,
        endereco : {
          nu_cep : this.candidato.nu_cep,
          nm_estado : this.candidato.nm_estado,
          nm_cidade : this.candidato.nm_cidade,
          nm_bairro : this.candidato.nm_bairro,
          nm_endereco : this.candidato.nm_endereco,
          nu_numero : this.candidato.nu_numero,
          nm_complemento : this.candidato.nm_complemento
        },
        midias_sociais : {
          hl_site_pessoal : this.candidato.hl_site_pessoal,
          hl_skype : this.candidato.hl_skype,
          hl_google : this.candidato.hl_google,
          hl_facebook : this.candidato.hl_facebook,
          hl_linkedin : this.candidato.hl_linkedin,
          hl_twitter : this.candidato.hl_twitter,
          hl_instagram : this.candidato.hl_instagram
        },
        json_objetivo : {
          nm_cargo : cvDb.json_curriculo.nm_cargo,
          nm_area : cvDb.json_curriculo.nm_area,
          nm_nivel : cvDb.json_curriculo.nm_nivel,
          fl_jornada : cvDb.json_curriculo.fl_jornada,
          fl_tipo_contrato : cvDb.json_curriculo.fl_tipo_contrato,
          fl_pj : cvDb.json_curriculo.fl_pj,
          fl_primeiro_emprego: cvDb.json_curriculo.fl_primeiro_emprego,
          nu_salario : cvDb.json_curriculo.nu_salario,
          nm_cidade : cvDb.json_curriculo.nm_cidade
        },
        tx_resumo : cvDb.json_curriculo.tx_resumo,
        tx_info_extra : cvDb.json_curriculo.tx_info_extra,
        json_experiencias : cvDb.json_curriculo.experiencias,
        json_formacoes : cvDb.json_curriculo.formacoes,
        json_idiomas : cvDb.json_curriculo.idiomas,
        json_certificacoes : cvDb.json_curriculo.certificacoes,
        json_competencias : cvDb.json_curriculo.competencias,
        origem: 'matchjob'
      }
      if (this.vaga.json_vaga.id_teste !== '') {
        this.$set(novoCv, 'teste', this.resTeste)
      }
      //console.log('novoCv -> ', novoCv)
      //await apiVagas.get('http://api.vagas.matchjob.local/listaempresas')
      //await this.$http.post('http://api.vagas.matchjob.local/vaga/apply', novoCv)
      /*
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
      */
      //this.$httpVagas.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
      //this.$httpVagas.defaults.headers.post['Content-Type'] = 'application/json';
      //this.$httpVagas.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      //await this.$httpVagas.post('/vaga/apply', novoCv)
      const apiVagas = axios.create();
      await apiVagas({
        url: '/vaga/apply',
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          'X-Requested-With': 'XMLHttpRequest'
        },
        proxy: {
          protocol: 'https',
          host: 'api.vagas.matchjob.com.br',
          port: 443
        },
        data: novoCv
      })
        .then(res => {
          if (res.data) {
            var candidatura = {
              id_candidato: this.candidato.id_candidato,
              id_processo_vaga: this.vaga.id_processo_vaga,
              id_empresa: this.vaga.id_empresa,
              id_curriculo : cvDb.id_curriculo,
              fl_visualizado: '0'
            }
            this.$store.dispatch('adicionaCandidatura', candidatura);
            this.modalTeste = false;
            this.modalSeletorCv = false;
            alert('Candidatura confirmada!');
          } else {
            alert('Ops! Não foi possível confirmar a candidatura...')
          }
        })
        .catch(err => {
          //this.notificacao("Não foi possível atualizar a Foto.", 'warning');
          console.log(err);
          this.$toasted.global.defaultError({
            msg: "Erro na comunicação Tente mais tarde!"
          });
        });/*
        var addTempCandidatura = {
          id_candidato: this.candidatoDB.id_candidato,
          created_at: new Date()
        }
        this.configs.candidaturas.push(addTempCandidatura); */
    },
    cancelarTeste() {
      this.modalTeste = false;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
}
</script>

<style scoped>
.page-header {
  min-height: 0 !important;
  max-height: 999px;
  margin-top: 40px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
