<template>
  <div class="col-xl-3 col-md-6">
    <card class="card-frame">
      <template slot="body">
        <div class="row">
          <div class="col">
            <h5>{{ titulo }}</h5>
            <h6 class="text-muted">{{subtitulo}}</h6>
            <h2 class="font-weight-bold mt-0">{{ valor }}</h2>
          </div>
          <div class="col-auto">
            <div
              :class="
                'icon icon-shape bg-gradient-' +
                  cor +
                  ' text-white rounded-circle shadow'
              "
            >
              <i :class="'ni ' + icone"></i>
            </div>
          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
export default {
  props: {
    titulo: { type: String, required: true },
    subtitulo: { type: String, required: false },
    valor: { type: String, required: true },
    cor: { type: String, required: true },
    icone: { type: String, required: true }
  }
};
</script>

<style></style>
