<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label for="nmcomp">Competência <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">
                Exemplos:<br>
                <strong>Excel</strong> ou <strong>Pacote Office</strong><br>
                <strong>Microsoft Windows Server</strong><br>
                <strong>Impostos Diretos</strong> ou <strong>Planejamento tributário</strong><br>
                <strong>Direito Condonimial</strong> ou <strong>Direito Imobiliário</strong>
              </div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label>
          <el-autocomplete
            ref="nmcomp"
            id="nmcomp"
            class="inuptAutocomplete"
            :maxlength="255"
            v-model="novaCompetencia.nm_competencia"
            :fetch-suggestions="querySearchComp"
            placeholder="Competência"
            :trigger-on-focus="false"
          ></el-autocomplete>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label for="tipoformacao">Nível de Conhecimento <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">
                <strong>Básico / Júnior</strong> = De 0 a 4 anos de experiência<br>
                <strong>Intermediário / Pleno</strong> = De 4 a 8 anos de experiência<br>
                <strong>Avançado / Sênior</strong> = De 8 a 12 anos de experiência<br>
                <strong>Especialista</strong> = Mais de 12 anos de experiência
              </div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label>
          <el-select class="select-primary selectAutocomplete" v-model="novaCompetencia.fl_nivel">
            <el-option
              v-for="comp in nivelCompetencia"
              :key="comp.value"
              class="select-primary"
              :value="comp.value"
              :label="comp.text"></el-option>
          </el-select>
        </div>
      </div>
      <div
        class="col-lg-4 d-flex justify-content-end align-items-center"
      >
        <base-button
          type="secondary"
          size="sm"
          class="mt-2"
          @click.prevent="cancelaNovaComp"
          >Cancelar</base-button
        >
        <base-button
          type="primary"
          size="sm"
          class="mt-2"
          @click="addNovaComp"
          >Incluir</base-button
        >
      </div>
    </div>
    <hr />
    <!-- Cabeçalho das competencias cadastradas -->
    <div class="row">
      <div class="col-lg-1">
      </div>
      <div class="col-lg-4">
        <p>Competências - Nível</p>
      </div>
    </div>
    <div
      class="row"
      v-for="comp in competencias"
      :key="comp.id"
    >
      <div class="col-lg-1 d-flex justify-content-start align-items-center">
        <base-button
          type="primary"
          size="sm"
          class="mb-3"
          @click="removerComp(comp.id)"
          >Excluir</base-button
        >
      </div>
      <div class="col-lg-6">
        <p>
          {{comp.nm_competencia}} - <strong>{{ retornaNivelComp(comp.fl_nivel) }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, Autocomplete } from 'element-ui';

export default {
  name: "Competencias",
  props: {
    propCompetencias: { type: Array },
    procDadosCompetencias: { type: Function }
  },
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      competencias: [],
      novaCompetencia: {
        id: 0,
        nm_competencia: "",
        fl_nivel: 1
      },
      flNovaCompetencia: false,
      nivelCompetencia: [
        { value: 1, text: "Básico / Júnior" },
        { value: 2, text: "Intermediário / Pleno" },
        { value: 3, text: "Avançado / Sênior" },
        { value: 4, text: "Especialista" }
      ],
    }
  },
  computed: {
    competenciasState() {
      return this.$store.state.competencias;
    }
  },
  methods: {
    emiteDados() {
      this.$emit('resCompetencias', this.competencias);
      setTimeout(() => {
        this.procDadosCompetencias();
      }, 300);
    },
    retornaNivelComp(id) {
      var res = this.nivelCompetencia.find(i => i.value === parseInt(id));
      return res.text;
    },
    querySearchComp(queryString, cb) {
      let varComp = this.competenciasState;
      var results = queryString ? varComp.filter(this.createFilterComp(queryString)) : varComp;
      cb(results)
    },
    createFilterComp(queryString) {
      return (comp) => {
        return (comp.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    cancelaNovaComp() {
      this.novaCompetencia.id = 0;
      this.novaCompetencia.nm_competencia = "";
      this.novaCompetencia.fl_nivel = 1;
      this.flNovaCompetencia = false;
    },
    validaNovaComp() {
      if (this.novaCompetencia.nm_competencia === "") {
        return false;
      } else {
        return true;
      }
    },
    addNovaComp() {
      if (this.validaNovaComp()) {
        if (this.novaCompetencia.id === 0) {
          if (this.competencias.length > 0) {
            const lastItem = this.competencias.pop();
            let id = lastItem.id;
            this.competencias.push(lastItem);
            this.novaCompetencia.id = ++id;
          } else {
            this.novaCompetencia.id = 1;
          }
          let newItem = structuredClone(this.novaCompetencia)
          this.competencias.push(newItem);
          this.cancelaNovaComp();
          this.emiteDados();
        } else {
          var pos = this.competencias.findIndex( item => item.id === this.novaCompetencia.id);
          let tempRes = structuredClone(this.novaCompetencia);
          this.competencias.splice(pos, 1, tempRes);
          this.cancelaNovaComp();
          this.emiteDados();
        }
      }
    },
    removerComp(id) {
      if (confirm('Tem certeza que deseja remover essa competência?')) {
        var pos = this.competencias.findIndex( item => item.id === id);
        var tam = this.competencias.length;
        if (tam === (pos + 1)) {
          this.competencias.splice(pos, 1);
          this.emiteDados();
        } else {
          for (var i = pos+1; i < tam; i++){
            this.competencias[i].id = this.competencias[i].id-1;
          }
          this.competencias.splice(pos, 1);
          this.emiteDados();
        }
      }
    },
    editarComp(id) {
      var editComp = this.competencias.find( item => item.id === id);
      this.novaCompetencia.id = editComp.id;
      this.novaCompetencia.nm_idioma = editComp.nm_competencia;
      this.novaCompetencia.fl_nivel = editComp.fl_nivel;
      this.flNovaCompetencia = true;
    }
  },
  mounted() {
    this.competencias = structuredClone(this.propCompetencias);
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
