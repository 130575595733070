<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { baseApiUrl, userKey } from "@/global";
import { mapState } from "vuex";

export default {
  components: {
    FadeTransition
  },
  computed: mapState(["candidato"]),
  data: function() {
    return {
      validatingToken: true
    };
  },
  methods: {
    async validateToken() {
      this.validatingToken = true;

      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      //this.$store.commit('setCandidato', null)

      if (!userData) {
        this.validatingToken = false;
        this.$router.push({ name: "login" }).catch(() => {});
        return;
      }
      const res = await this.$http.get(`${baseApiUrl}/login/validateToken`);
      //console.log('Validate token App.vue -> '.res.data)
      if (res.data === false) {
        //localStorage.removeItem(userKey);
        sessionStorage.removeItem(userKey);
        //this.$session.destroy();
        this.$store.dispatch("logout", null);
        this.$router.push({ name: "login" }).catch(() => {});
        return;
      } else {
        this.loadCandidatoDados();
        this.carregaCargos();
      }
      this.validatingToken = false;
    },
    loadCandidatoDados() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.$http
        .get(`${baseApiUrl}/login/loadCandidatoDados`, {
          params: { id_candidato: userData.id_candidato }
        })
        .then(res => {
          this.$store.dispatch('defineCandidato', res.data);
          this.$store.dispatch('defineCurriculos', res.data.curriculos);
          this.$store.dispatch('defineCvPro', res.data.cvpro);
          this.$store.dispatch('defineCandidaturas', res.data.candidaturas);
        })
        .catch(() => {
          console.log('->>> Erro no loadCandidatoDados <<<-');
        });
    },
    async carregaCargos() {
      await this.$http.get('/sysinfos/getall')
        .then(res => {
          this.$store.dispatch('defineSysInfos', res);
        })
        .catch(err => {
          console.log('erro do axios ->> ', err)
        });
    },
  },
  beforeCreate() {
    const dateNow = new Date();
    const dateEndToken = new Date(sessionStorage.getItem('session_end_datetime'));
    //console.log('dateNow -> ', dateNow);
    //console.log('dateEndToken -> ', dateEndToken);
    const token = sessionStorage.getItem('access_token');
    if (token) {
      if (dateNow < dateEndToken) {
        this.$http.defaults.headers.common[
          "Authorization"
        ] = `bearer ${token}`;
        //console.log('dateNow > dateEndToken')
      } else {
        //console.log('entrou no App... else dateNow beforeCreate()...')
        sessionStorage.removeItem(userKey);
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('session_end_datetime');
        this.$store.dispatch("logout", null);
        this.$router.push({ name: "login" }).catch(() => {});
        //console.log('dateNow < dateEndToken')
      }
    } else {
      //console.log('entrou no App... else token beforeCreate()...')
      sessionStorage.removeItem(userKey);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('session_end_datetime');
      this.$store.dispatch("logout", null);
      this.$router.push({ name: "login" }).catch(() => {});
    }
  },
  created() {
    this.validateToken();
  }
};
</script>
