<template>
  <div>
    <base-button
      type="primary"
      size="sm"
      class="mb-3"
      @click="modals.experiencia = true"
    >
      Adicionar Experiência</base-button
    >
    <div
      v-for="exp in experiencias"
      :key="exp.id">
      <div class="row">
        <!-- Nome da Empresa -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Nome da Empresa: <br><strong>{{exp.nm_empresa}}</strong></p>
          </div>
        </div>
        <!-- Cargo -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Cargo<br><strong>{{exp.nm_cargo}}</strong></p>
          </div>
        </div>
        <!-- Local de trabalho -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Local de Trabalho<br><strong>{{exp.nm_local}}</strong></p>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Data de Inicio -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Data de Inicio<br><strong>{{exp.dt_inicio | formatDate}}</strong></p>
          </div>
        </div>
        <!-- Data Fim -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Data Fim<br><strong>{{exp.dt_fim | formatDate}}</strong></p>
          </div>
        </div>
        <!-- Flag Atual -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Emprego Atual?<br><strong class="text-muted">{{exp.fl_atual ? "Sim" : "Não"}}</strong></p>
          </div>
        </div>
      </div>
      <!-- Descrição da experiencia -->
      <div class="row">
        <div class="col-lg">
          <div class="form-group">
            <p>Descrição das atividades<br>
            <small>{{exp.tx_descricao}}</small></p>
          </div>
        </div>
      </div>
      <!-- Botão Excluir -->
      <div class="row">
        <div class="col-lg d-flex justify-content-end pb-4">
          <base-button
            type="danger"
            size="sm"
            @click.native="removerExp(exp.id)"
            >Apagar</base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butUp(exp.id)" @click.native="moveUp(exp.id)"
            ><i class="fas fa-arrow-up"></i></base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butDown(exp.id)" @click.native="moveDown(exp.id)"
            ><i class="fas fa-arrow-down"></i></base-button
          >
          <base-button
            type="primary"
            size="sm"
            @click="editarExp(exp.id)"
            >Editar</base-button
          >
        </div>
      </div>
      <!-- Dividor HR -->
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>
    <!-- Modal Add Experiencia -->
    <modal
      :show.sync="modals.experiencia"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Adicionar Experiência Profissional
      </h5>
      <div class="row">
        <!-- Nome da Empresa -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="empresa">Nome da Empresa</label>
            <el-input
            id="empresa"
            class="inuptAutocomplete"
            v-model="novaExp.nm_empresa"
            placeholder="Nome da Empresa"
            ></el-input>
            <small class="text-danger" v-if="validacaoForm.empresa === false"><strong>Você precisa informar o nome da empresa</strong></small>
          </div>
        </div>
        <!-- Cargo -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="cargo">Cargo Desejado <el-tooltip placement="top" effect="light"><div slot="content">Cargo ou posição que deseja se alocar<br/>Ex. Analista Administrativo</div><i class="fas fa-question-circle"></i></el-tooltip></label>
            <el-autocomplete
              ref="cargo"
              id="cargo"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaExp.nm_cargo"
              :fetch-suggestions="querySearchCargos"
              placeholder="Cargo"
              :trigger-on-focus="false"
            ></el-autocomplete>
            <small class="text-danger" v-if="validacaoForm.cargo === false"><strong>Você precisa informar o cargo</strong></small>
          </div>
        </div>
        <!-- Local de trabalho -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="cidade">Local de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">Informe a cidade que você trabalhou<br>nessa experiência.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
            <el-autocomplete
              ref="cidade"
              id="cidade"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaExp.nm_local"
              :fetch-suggestions="querySearchCidade"
              placeholder="Cidade"
              :trigger-on-focus="false"
            ></el-autocomplete>
            <small class="text-danger" v-if="validacaoForm.local === false"><strong>Você precisa informar a cidade</strong></small>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Data Inicio -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="inicio">Data de Inicio</label>
            <el-input
              type="date"
              id="inicio"
              class="inuptAutocomplete"
              v-model="novaExp.dt_inicio"
            ></el-input>
            <small class="text-danger" v-if="validacaoForm.inicio === false"><strong>Você precisa informar a data de inicio</strong></small>
          </div>
        </div>
        <!-- Data de Saída -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="fim">Data de Saida</label>
            <el-input
              type="date"
              id="fim"
              class="inuptAutocomplete"
              v-model="novaExp.dt_fim"
            ></el-input>
            <small class="text-danger" v-if="validacaoForm.fim === false"><strong>Você precisa informar a data de término ou marcar como 'Emprego Atual'</strong></small>
          </div>
        </div>
        <!-- Flag Atual -->
        <div class="col-lg-4">
          <div class="form-group">
            <div class="row ml-1">
              <label>Emprego Atual?</label>
            </div>
            <div class="row ml-1">
              <base-switch
                type="primary"
                on-text="Sim"
                off-text="Não"
                v-model="novaExp.fl_atual"
              ></base-switch>
              <small class="text-danger" v-if="validacaoForm.atual === false"><strong>Marque aqui se for o seu 'Emprego Atual'</strong></small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <div class="form-group">
            <label for="descricao">Descrição das atividades</label>
            <textarea
              class="form-control"
              id="descricao"
              rows="4"
              maxlength="4000"
              v-model="novaExp.tx_descricao"
            ></textarea>
            <small class="text-danger" v-if="validacaoForm.descricao === false"><strong>Você precisa informar as atividades realizadas nessa experiência profissional</strong></small>
          </div>
        </div>
      </div>
      <!-- Botões -->
      <div class="row d-flex justify-content-end">
        <base-button
          type="secondary"
          @click="cancelaNovaExp"
          >Cancelar</base-button
        >
        <base-button type="primary" @click="addExperiencia"
          >Incluir</base-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import { Autocomplete, Input } from 'element-ui';
import varCidades from '@/data/cidades_br.js';
import Modal from "@/components/Modal.vue";
import moment from "moment";

export default {
  name: "Experiencias",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    Modal
  },
  props: {
    propExperiencias: { type: Array },
    procDadosExperiencias: { type: Function }
  },
  data() {
    return {
      cidades: [],
      experiencias: [],
      //Variaveis de adição de experiencias
      novaExp: {
        id: 0,
        nm_empresa: "",
        nm_cargo: "",
        nm_local: "",
        dt_inicio: "",
        dt_fim: "",
        fl_atual: false,
        tx_descricao: ""
      },
      /* Modal */
      modals: {
        experiencia: false
      },
      validacaoForm: {
        empresa: true,
        cargo: true,
        local: true,
        inicio: true,
        fim: true,
        atual: true,
        descricao: true
      }
    }
  },
  computed: {
    cargosState() {
      return this.$store.state.cargos;
    },
  },
  methods:{
    emiteDados() {
      this.$emit('resExperiencias', this.experiencias);
      setTimeout(() => {
        this.procDadosExperiencias();
      }, 300);
    },
    querySearchCidade(queryString, cb) {
      let cidades = this.cidades;
      var results = queryString ? cidades.filter(this.createFilterCidade(queryString)) : cidades;
      cb(results)
    },
    createFilterCidade(queryString) {
      return (cidade) => {
        return (cidade.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    ajustaCidades() {
      varCidades.forEach(element => {
        let city = {
          id: element.id_cidade,
          value: element.nm_cidade_uf
        }
        this.cidades.push(city);
      });
    },
    querySearchCargos(queryString, cb) {
      let cargos = this.cargosState;
      var results = queryString ? cargos.filter(this.createFilterCargos(queryString)) : cargos;
      cb(results)
    },
    createFilterCargos(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    validaNovaExp() {
      if(this.addExpEmpresa === "") {
        this.validacaoForm.empresa = false;
        return false;
      } else if(this.addExpCargo === "") {
        this.validacaoForm.cargo = false;
        return false;
      } else if(this.addExpLocal === "") {
        this.validacaoForm.local = false;
        return false;
      } else if(this.addExpDataInicio === "") {
        this.validacaoForm.inicio = false;
        return false;
      } else if(this.addExpFlAtual === false && this.addExpDataFim === "") {
        this.validacaoForm.fim = false;
        this.validacaoForm.atual = false;
        return false;
      } else if(this.addExpDescricao === "") {
        this.validacaoForm.descricao = false;
        return false;
      } else {
        this.validacaoForm.empresa = true;
        this.validacaoForm.cargo = true;
        this.validacaoForm.local = true;
        this.validacaoForm.inicio = true;
        this.validacaoForm.fim = true;
        this.validacaoForm.atual = true;
        this.validacaoForm.descricao = true;
        return true;
      }
    },
    addExperiencia() {
      if(this.validaNovaExp()) {
        if (this.novaExp.id === 0) {
          if(this.experiencias.length > 0) {
            const lastItem = this.experiencias.pop();
            let id = lastItem.id;
            this.experiencias.push(lastItem);
            this.novaExp.id = ++id;
          } else {
            this.novaExp.id = 1;
          }
          let newItem = structuredClone(this.novaExp)
          this.experiencias.push(newItem);
          this.cancelaNovaExp();
          this.emiteDados();
        } else {
          var pos = this.experiencias.findIndex( item => item.id === this.novaExp.id);
          let tempRes = structuredClone(this.novaExp);
          this.experiencias.splice(pos, 1, tempRes);
          this.cancelaNovaExp();
          this.emiteDados();
        }
      }
    },
    cancelaNovaExp() {
      //Variavel de validação
      this.validacaoForm.empresa = true;
      this.validacaoForm.cargo = true;
      this.validacaoForm.local = true;
      this.validacaoForm.inicio = true;
      this.validacaoForm.fim = true;
      this.validacaoForm.atual = true;
      this.validacaoForm.descricao = true;
      //Variavel de Nova Experiencia
      this.novaExp.id = 0;
      this.novaExp.nm_empresa = "";
      this.novaExp.nm_cargo = "";
      this.novaExp.nm_local = "";
      this.novaExp.dt_inicio = "";
      this.novaExp.dt_fim = "";
      this.novaExp.fl_atual = false;
      this.novaExp.tx_descricao = "";
      this.modals.experiencia = false
    },
    butUp(id){
      var pos = this.experiencias.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      //var pos = this.newTest.findIndex( item => item.id === id);
      if (this.experiencias.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.experiencias.findIndex( item => item.id === idNow);
      var pos1 = this.experiencias.findIndex( item => item.id === idNext);
      var arr0 = this.experiencias.find( item => item.id === idNow);
      var arr1 = this.experiencias.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.experiencias.splice(pos0, 1, arr1);
      this.experiencias.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.experiencias.findIndex( item => item.id === idNow);
      var pos1 = this.experiencias.findIndex( item => item.id === idNext);
      var arr0 = this.experiencias.find( item => item.id === idNow);
      var arr1 = this.experiencias.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.experiencias.splice(pos0, 1, arr1);
      this.experiencias.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    removerExp(id) {
      if (confirm('Tem certeza que deseja remover essa experiência?')) {
        var pos = this.experiencias.findIndex( item => item.id === id);
        var tam = this.experiencias.length;
        if (tam === (pos + 1)) {
          this.experiencias.splice(pos, 1);
          this.emiteDados();
        } else {
          for (var i = pos+1; i < tam; i++){
            this.experiencias[i].id = this.experiencias[i].id-1;
          }
          this.experiencias.splice(pos, 1);
          this.emiteDados();
        }
      }
    },
    editarExp(id) {
      var exp = this.experiencias.find( item => item.id === id);
      this.novaExp.id = exp.id;
      this.novaExp.nm_empresa = exp.nm_empresa;
      this.novaExp.nm_cargo = exp.nm_cargo;
      this.novaExp.nm_local = exp.nm_local;
      this.novaExp.dt_inicio = exp.dt_inicio;
      this.novaExp.dt_fim = exp.dt_fim;
      this.novaExp.fl_atual = exp.fl_atual;
      this.novaExp.tx_descricao = exp.tx_descricao;
      this.modals.experiencia = true;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    const exp = structuredClone(this.propExperiencias);
    this.experiencias = exp;
    this.ajustaCidades();
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
