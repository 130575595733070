<template>
  <transition name="fade" mode="in-out">
    <base-alert type="default" v-if="ativo">
      {{ mensagem }}
    </base-alert>
  </transition>
</template>

<script>
export default {
  props: {
    tipo: { type: String, required: true },
    mensagem: { type: String, required: true }
  },
  data() {
    return {
      ativo: true
    };
  },
  methods: {
    desativarAlerta() {
      setTimeout(() => {
        this.ativo = false;
      }, 5000);
    }
  },
  mounted() {
    this.desativarAlerta();
  }
};
</script>

<style>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
</style>
