<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project14.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">Olá Candidato!</h1>
                <p class="text-lead text-white">
                  Aqui você irá utilizar as suas credenciais para acessar o seu
                  currículo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="upper">
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h3 class="display-3 mb-0">MATCHJOB</h3>
                <small>Entre com seu e-mail e senha.</small>
              </div>
              <form role="form">
                <div class="form-group mb-3">
                  <base-input
                    alternative
                    type="email"
                    name="username"
                    placeholder="E-mail"
                    addonLeftIcon="ni ni-email-83"
                    v-model="user.username"
                  ></base-input>
                </div>
                <div class="form-group">
                  <base-input
                    alternative
                    type="password"
                    name="password"
                    placeholder="Senha"
                    addonLeftIcon="ni ni-lock-circle-open"
                    v-model="user.password"
                  ></base-input>
                </div>
                <base-alert type="warning" v-if="errLogin.status">
                  <strong>Ops!</strong> {{ errLogin.msg }}
                </base-alert>
                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="signin"
                    >Entrar</base-button
                  >
                </div>
                <div class="text-center text-muted">
                  <small
                    >Esqueceu a sua senha?
                    <router-link to="/recuperarsenha" tag="a">
                      Recupere aqui</router-link
                    >.</small
                  >
                </div>
                <div class="text-center">
                  <small
                    >Ainda não tem cadastro?
                    <a href="https://www.matchjob.com.br/candidatos/cadastro"
                      >Cadastre-se Agora!</a
                    >.</small
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { baseApiUrl, userKey } from "@/global";
//import axios from "axios";

export default {
  bodyClass: "login-page",
  name: "Login",
  data: function() {
    return {
      user: {
        client_id: "testclient",
        client_secret: "testpass",
        grant_type: "password",
        username: "",
        password: ""
      },
      errLogin: {
        status: false,
        msg: ""
      }
    };
  },
  methods: {
    signin() {
      this.$http
        //.post(`${baseApiUrl}/login/entrar`, this.user)
        .post('/login/entrar', this.user)
        .then(res => {
          //this.$store.commit("setCandidato", res.data);
          this.$store.dispatch('defineCandidato', res.data);
          this.$store.dispatch('defineCurriculos', res.data.curriculos);
          this.$store.dispatch('defineCvPro', res.data.cvpro);
          this.$store.dispatch('defineCandidaturas', res.data.candidaturas);
          //console.log('res.data -> ', res.data)
          //localStorage.setItem(userKey, JSON.stringify(res.data));
          //localStorage.setItem('access_token', res.data.access_token);
          sessionStorage.setItem(userKey, JSON.stringify(res.data));
          sessionStorage.setItem('access_token', res.data.access_token);
          const dateNow = new Date();
          const dateFinal = new Date(dateNow.getTime() + (parseInt(res.data.full.expires_in) * 1000));
          sessionStorage.setItem('session_end_datetime', dateFinal);
          this.$http.defaults.headers.common[
            "Authorization"
          ] = `bearer ${res.data.access_token}`;
          //this.$session.start();
          //this.$session.set("access_token", res.data.access_token);
          this.$router.push({ path: "/" }).catch(() => {});
        })
        .catch(err => {
          this.errLogin.status = true;
          this.errLogin.msg = err.response.data.error_description;
        });
    }
  }
};
</script>
<style></style>
