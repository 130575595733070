<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row">
            <br />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-white">
      <div class="container card mb-0">
        <div class="row">
          <div class="col-md-3">
            <div class="section perfil">
              <!-- User Information -->
              <section class="perfil text-center">
                <!-- <div
                  class="fileinput fileinput-new text-center"
                  data-provides="fileinput"
                >
                  <image-upload
                    type="avatar"
                    select-text="Alterar"
                    @change="onAvatarChange"
                    btnClasses="btn-primary btn-sm btn-round mt-3"
                    :src="c.hl_foto"
                  />
                </div> -->
                <img
                  :src="imagePreview1"
                  alt="Sua foto de perfil"
                  class="img-fluid rounded-circle shadow"
                  style="width: 150px;"
                />
                <base-button
                  type="primary"
                  @click="modalFoto = true"
                  size="sm"
                  class="mt-2 mx-2"
                  >Alterar</base-button
                >
                <base-button
                  type="secondary"
                  @click="removerFoto"
                  size="sm"
                  class="mt-2 mx-2"
                  >Remover</base-button
                >
                <modal
                  :show.sync="modalFoto"
                  modal-classes="modal-dialog-centered modal-lg"
                >
                  <form
                    @submit.prevent="atualizarFoto"
                    enctype="multipart/form-data"
                  >
                    <h5
                      slot="header"
                      class="modal-title"
                      id="modal-title-default"
                    >
                      Alterar a sua foto de perfil
                    </h5>
                    <div class="row mx-4">
                      <div class="col-sm-4">
                        <img
                          :src="imagePreview"
                          alt="Sua foto de perfil"
                          class="img-fluid rounded-circle shadow"
                          style="width: 150px;"
                          ref="novaFoto"
                        />
                      </div>
                      <div class="col-sm-8">
                        <base-input
                          type="file"
                          inputClasses="custom-file-input"
                          @change="onChangeFoto($event)"
                        ></base-input>
                        <label class="custom-file-label" for="customFileLang"
                          >Selecione um arquivo.</label
                        >
                        <p>
                          A imagem deve ser JPG ou PNG, até 1 MB de tamanho e
                          preferencialmente quadrada 150x150 pixels.
                        </p>
                      </div>
                    </div>
                    <div class="row mx-4">
                      <base-button
                        type="secondary"
                        class="ml-auto"
                        @click="modalFoto = false"
                        >Cancelar
                      </base-button>
                      <button type="submit" class="btn btn-primary">
                        Gravar
                      </button>
                    </div>
                  </form>
                </modal>
                <h3 class="title mt-4">{{ c.nm_nome }}</h3>
              </section>
              <!-- User Information -->
              <!-- Profile Sidebar -->
              <section class="perfil">
                <br />
                <tabs
                  v-model="accountTab"
                  vertical
                  tabNavWrapperClasses="col-md-12 text-center"
                  no-content-space
                >
                  <tab-pane label="Cadastro"></tab-pane>
                  <tab-pane label="Endereço"></tab-pane>
                  <tab-pane label="Mídias Sociais"></tab-pane>
                  <tab-pane label="PDC"></tab-pane>
                  <tab-pane label="Segurança"></tab-pane>
                  <tab-pane label="Privacidade"></tab-pane>
                </tabs>
              </section>
              <!-- End Profile Sidebar -->
            </div>
          </div>
          <div class="col-md-8 ml-auto">
            <div class="section perfil">
              <div class="tab-content">
                <div v-if="accountTab === 'Cadastro'" class="tab-pane active">
                  <div>
                    <header>
                      <h2 class="text-uppercase">Informações de Cadastro</h2>
                    </header>
                    <hr class="line-primary" />
                    <br />
                    <!-- Linha Nome -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#firstName">Nome</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="firstName"
                          name="firstName"
                          type="text"
                          :value="c.nm_nome"
                          disabled
                        ></base-input>
                      </div>
                    </div>
                    <!-- Linha E-mail -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#email">E-mail</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="email"
                          name="email"
                          type="text"
                          :value="c.hl_email"
                          disabled
                        ></base-input>
                      </div>
                    </div>
                    <!-- Linha CPF -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#lastName">CPF</label>
                      </div>
                      <div class="col-md-9 align-self-center">
                        <base-input
                          id="cpd"
                          name="cpf"
                          type="text"
                          :value="c.nu_cpf"
                          disabled
                        ></base-input>
                      </div>
                    </div>
                    <!-- Linha Genero -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels"
                          >Eu sou <small>(gênero)</small></label
                        >
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="exampleFormControlSelect2"
                            v-model="c.fl_sexo"
                          >
                            <option
                              v-for="gender in genders"
                              :key="gender.id"
                              :value="gender.id"
                            >
                              {{ gender.text }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- Linha Data Nascimento -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">Data de nascimento</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="form-group">
                          <base-input
                            type="date"
                            v-model="c.dt_nasc"
                            :valid="c.dt_nasc != null"
                          ></base-input>
                        </div>
                      </div>
                    </div>
                    <!-- Linha Estado Civil -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#confirmEmail"
                          >Estado Civil - Select</label
                        >
                      </div>
                      <div class="col-md-9 align-self-center">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="exampleFormControlSelect2"
                            v-model="c.fl_estadocivil"
                          >
                            <option
                              v-for="estadocivil in estadoscivil"
                              :key="estadocivil.id"
                              :value="estadocivil.id"
                            >
                              {{ estadocivil.text }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- Linha Nacionalidade -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#location"
                          >Nacionalidade</label
                        >
                      </div>
                      <div class="col-md-9 align-self-center">
                        <div class="form-group">
                          <select
                            class="form-control"
                            id="exampleFormControlSelect2"
                            v-model="c.nu_nacionalidade"
                          >
                            <option
                              v-for="nac in nacionalidade"
                              :key="nac.id_nacionalidade"
                              :value="nac.id_nacionalidade"
                            >
                              {{ nac.nm_pais }}</option
                            >
                          </select>
                        </div>
                      </div>
                    </div>
                    <!-- Linha Celular -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels" for="#phone">Celular</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <base-input
                          id="celular"
                          name="celular"
                          type="tel"
                          v-model="c.nu_celular"
                          v-mask="'(##) #####-####'"
                          :valid="!Number.isInteger(c.nu_celular)"
                        ></base-input>
                      </div>
                    </div>
                    <!-- Linha Telefone -->
                    <div class="row">
                      <div class="col-md-3 align-self-center">
                        <label class="labels">Telefone</label>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="form-group">
                          <base-input
                            id="celular"
                            name="celular"
                            type="tel"
                            v-model="c.nu_telefone"
                            v-mask="'(##) ####-####'"
                          ></base-input>
                        </div>
                      </div>
                    </div>
                    <!-- Linha Botão Salvar -->
                    <div class="row mt-5">
                      <div class="col-md-6">
                        <base-button type="primary" @click="atualizaPerfil"
                          >Salvar</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="accountTab === 'Endereço'" class="tab-pane active">
                  <header>
                    <h2 class="text-uppercase">Endereço</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="row">
                    <!-- Linha CEP -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">CEP</label>
                    </div>
                    <div class="col-md-3 align-self-center">
                      <base-input
                        id="cep"
                        name="cep"
                        type="text"
                        v-model="c.nu_cep"
                        v-mask="'#####-###'"
                        @blur.prevent="atualizaCep()"
                        :valid="!null"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Endereço -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Endereço</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="endereco"
                        name="endereco"
                        type="text"
                        maxlength="100"
                        v-model="c.nm_endereco"
                        :valid="!null"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Numero -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Número</label>
                    </div>
                    <div class="col-md-3 align-self-center">
                      <base-input
                        id="numero"
                        name="numero"
                        type="text"
                        maxlength="11"
                        v-model="c.nu_numero"
                        :valid="!null"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Complemento -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Complemento</label>
                    </div>
                    <div class="col-md-5 align-self-center">
                      <base-input
                        id="complemento"
                        name="complemento"
                        type="text"
                        maxlength="30"
                        v-model="c.nm_complemento"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Bairro -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Bairro</label>
                    </div>
                    <div class="col-md-5 align-self-center">
                      <base-input
                        id="bairro"
                        name="bairro"
                        type="text"
                        maxlength="50"
                        v-model="c.nm_bairro"
                        :valid="!null"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Cidade / Estado -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Cidade / Estado</label>
                    </div>
                    <div class="col-md-6 align-self-center">
                      <base-input
                        id="cidade"
                        name="cidade"
                        type="text"
                        maxlength="30"
                        v-model="c.nm_cidade"
                        :valid="!null"
                      ></base-input>
                    </div>
                    <div class="col-md-3 align-self-center">
                      <base-input
                        id="estado"
                        name="estado"
                        type="text"
                        maxlength="2"
                        v-model="c.nm_estado"
                        :valid="!null"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Salvar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="atualizaPerfil"
                        >Salvar</base-button
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="accountTab === 'Mídias Sociais'"
                  class="tab-pane active"
                >
                  <header>
                    <h2 class="text-uppercase">Mídias Sociais</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="row">
                    <!-- Site Pessoal -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName"
                        >Site Pessoal</label
                      >
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="site"
                        name="site"
                        type="text"
                        v-model="c.hl_site_pessoal"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Skype -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Skype</label>
                    </div>
                    <div class="col-md-4 align-self-center">
                      <base-input
                        id="skype"
                        name="skype"
                        type="text"
                        v-model="c.hl_skype"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Google+ / Youtube -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName"
                        >Google+ / Youtube</label
                      >
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="google"
                        name="google"
                        type="text"
                        v-model="c.hl_google"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Facebook -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Facebook</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="facebook"
                        name="facebook"
                        type="text"
                        v-model="c.hl_facebook"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha LinkedIn -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">LinkedIn</label>
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="linkedin"
                        name="linkedin"
                        type="text"
                        v-model="c.hl_linkedin"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Linha Twitter -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#email">Twitter</label>
                    </div>
                    <div class="col-md-4 align-self-center">
                      <base-input
                        id="twitter"
                        name="twitter"
                        type="text"
                        v-model="c.hl_twitter"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Salvar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="atualizaPerfil"
                        >Salvar</base-button
                      >
                    </div>
                  </div>
                </div>

                <div v-if="accountTab === 'PDC'" class="tab-pane active">
                  <header>
                    <h2 class="text-uppercase">Pessoa Com Deficiência</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span>Você é portador de alguma deficiência?</span>
                    <base-switch
                      v-model="c.fl_deficiencia"
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                    ></base-switch>
                  </div>
                  <div class="row">
                    <!-- Linha Descreva a sua deficiência -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName"
                        >Descreva a sua deficiência</label
                      >
                    </div>
                    <div class="col-md-9 align-self-center">
                      <base-input
                        id="site"
                        name="site"
                        type="text"
                        v-model="c.nm_deficiencia"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Salvar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="atualizaPerfil"
                        >Salvar</base-button
                      >
                    </div>
                  </div>
                </div>

                <div v-if="accountTab === 'Segurança'" class="tab-pane active">
                  <header>
                    <h2 class="text-uppercase">Troca de Senha</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div class="row">
                    <!-- Nova Senha -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName">Nova Senha</label>
                    </div>
                    <div class="col-md-4 align-self-center">
                      <base-input
                        id="novasenha"
                        name="novasenha"
                        type="password"
                        v-model="novaSenha"
                        :valid="novaSenha.length >= 8"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Confirmar Senha -->
                    <div class="col-md-3 align-self-center">
                      <label class="labels" for="#firstName"
                        >Confirmar Senha</label
                      >
                    </div>
                    <div class="col-md-4 align-self-center">
                      <base-input
                        id="confirmarsenha"
                        name="confirmarsenha"
                        type="password"
                        v-model="confirmNovaSenha"
                        :valid="confirmNovaSenha === novaSenha"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Salvar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="atualizaSenha"
                        >Salvar</base-button
                      >
                    </div>
                  </div>
                  <header>
                    <h2 class="text-uppercase mb-0">Encerrar Conta</h2>
                  </header>
                  <hr class="line-primary" />
                  <div class="row">
                    <!-- Motivo encerramento -->
                    <div class="col-md align-self-center">
                      <p>
                        Esta sessão somente deve ser utilizada caso você deseje
                        encerrar a sua conta na MatchJob Empregos e apagar
                        completamente os seus dados pessoais, currículos,
                        históricos de candidaturas e demais informações.
                      </p>
                      <label class="labels" for="#firstName"
                        >Por favor, nos informe o que motiva a sua solicitação
                        de encerrar a sua conta:</label
                      >
                      <base-input
                        id="encarramento"
                        name="encarramento"
                        type="text"
                        v-model="motivoEncerramento"
                        maxlength="200"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Enviar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="modalEncerramente = true"
                        :disabled="motivoEncerramento.length < 10"
                        >Enviar</base-button
                      >
                      <modal
                        :show.sync="modalEncerramente"
                        gradient="danger"
                        modal-classes="modal-danger modal-dialog-centered"
                      >
                        <h6
                          slot="header"
                          class="modal-title"
                          id="modal-title-notification"
                        >
                          Encerramento de Conta
                        </h6>
                        <div class="py-3 text-center">
                          <i class="ni ni-bell-55 ni-3x"></i>
                          <h4 class="heading mt-4">Atenção!</h4>
                          <p>
                            Você realmente deseja encerrar a sua conta?<br />
                            Esta ação será irreversível!
                          </p>
                          <p>
                            Confirmando este encerramento, seus dados serão
                            apagados em até 15 dias.
                          </p>
                        </div>
                        <template slot="footer">
                          <base-button type="white" @click="encerrarConta"
                            >Sim, encerrar!
                          </base-button>
                          <base-button
                            type="link"
                            text-color="white"
                            class="ml-auto"
                            @click="modalEncerramente = false"
                          >
                            Cancelar
                          </base-button>
                        </template>
                      </modal>
                    </div>
                  </div>
                </div>
                <!-- Privacidade -->
                <div
                  v-if="accountTab === 'Privacidade'"
                  class="tab-pane active"
                >
                  <header>
                    <h2 class="text-uppercase">Opções de Privacidade</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Ocultar meus dados de contato<br />
                      <small
                        >Empresas não terão acesso ao seu e-mail e
                        telefones</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_ocultar_dados"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Ocultar meu currículo público<br />
                      <small
                        >O link do seu Currículo público não será
                        carregado</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_cv_publico"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Ocultar totalmente o meu perfil<br />
                      <small
                        >Você ficará invisivel a resultado de pesquisas</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_ocultar_perfil"
                    ></base-switch>
                  </div>
                  <header>
                    <h2 class="text-uppercase">E-mails e Comunicações</h2>
                  </header>
                  <hr class="line-primary" />
                  <br />
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Receber avisos de vagas gratuítos<br />
                      <small
                        >Avisos de vagas gratuítos conforme o seu perfil</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_avisos_grat"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Receber avisos de vagas patrocinados<br />
                      <small>Avisos de vagas patrocinadas ou urgentes</small>
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_avisos_patroc"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Receber Newsletters da Matchjob<br />
                      <small>Todas as novidades da Matchjob</small>
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_news"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >E-mail de publicidade de produtos e serviços de empresas
                      parceiras<br />
                      <small
                        >Avisos de promoções e outras novidades das empresas
                        parceiras da Matchjob</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_marketing_3"
                    ></base-switch>
                  </div>
                  <div
                    class="form-group d-flex align-items-center justify-content-between"
                  >
                    <span
                      >Receber avisos de mensagens privadas<br />
                      <small
                        >Você será avisado quando alguma empresa entrar em
                        contato com você</small
                      >
                    </span>
                    <base-switch
                      type="primary"
                      on-text="Sim"
                      off-text="Não"
                      v-model="c.privacidade.fl_msg_priv"
                    ></base-switch>
                  </div>
                  <div class="row mt-5">
                    <!-- Linha Botão Salvar -->
                    <div class="col-md-6">
                      <base-button
                        nativeType="submit"
                        type="primary"
                        @click="atualizaPrivacidade"
                        >Salvar</base-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userKey, baseApiUrl, showError } from "@/global";
import axios from "axios";

import Modal from "@/components/Modal.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import { VueMaskDirective } from "v-mask";

export default {
  bodyClass: "account-settings",
  directives: { mask: VueMaskDirective },
  components: {
    Tabs,
    TabPane,
    Modal
  },
  methods: {
    inicializaValores() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.id_candidato = userData.id_candidato;
    },
    onImageChange(file) {
      this.images.regular = file;
    },
    onAvatarChange(file) {
      this.images = file;
    },
    getCandidato() {
      this.$http
        .get(`${baseApiUrl}/candidato`, {
          params: { id_candidato: this.id_candidato }
        })
        .then(res => {
          this.c = res.data;
          this.c.fl_deficiencia = this.setSwtichsValue(this.c.fl_deficiencia);
          for (let priv in this.c.privacidade) {
            this.c.privacidade[priv] = this.setSwtichsValue(
              this.c.privacidade[priv]
            );
          }
        })
        .catch(showError);
    },
    setSwtichsValue(value) {
      if (value === "1") {
        return true;
      } else {
        return false;
      }
    },
    getNacionalidade() {
      this.$http
        .get(`${baseApiUrl}/sysinfos/nacionalidade`)
        .then(res => {
          this.nacionalidade = res.data;
        })
        .catch(showError);
    },
    atualizaPerfil() {
      this.$http
        .post(`${baseApiUrl}/candidato/updatecandidato`, this.c)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: "Dados de perfil atualizados!"
          });
          console.log("Atualizado!");
        });
    },
    atualizaSenha() {
      if (
        this.novaSenha.length >= 8 &&
        this.novaSenha === this.confirmNovaSenha
      ) {
        const data = {
          id_candidato: this.id_candidato,
          senha: this.novaSenha,
          confirmasenha: this.confirmNovaSenha
        };
        this.$http
          .post(`${baseApiUrl}/candidato/atualizasenha`, data)
          .then(() => {
            this.$toasted.global.defaultSuccess({
              msg: "Senha atualizada com sucesso!"
            });
            console.log("Senha Atualizada!");
          });
      }
    },
    encerrarConta() {
      const data = {
        id_candidato: this.id_candidato,
        tx_motivo: this.motivoEncerramento
      };
      this.$http.post(`${baseApiUrl}/candidato/encerraconta`, data).then(() => {
        //localStorage.removeItem(userKey);
        sessionStorage.removeItem(userKey);
        this.$store.commit("setCandidato", null);
        this.$session.destroy();
        this.$router.push({ name: "login" });
        console.log("Encerramento de conta realizado com sucesso!");
      });
    },
    atualizaPrivacidade() {
      const data = {
        id_priv_candidato: this.c.id_priv_candidato,
        id_candidato: this.id_candidato,
        fl_ocultar_perfil: this.c.privacidade.fl_ocultar_perfil,
        fl_ocultar_dados: this.c.privacidade.fl_ocultar_dados,
        fl_cv_publico: this.c.privacidade.fl_cv_publico,
        fl_avisos_grat: this.c.privacidade.fl_avisos_grat,
        fl_avisos_patroc: this.c.privacidade.fl_avisos_patroc,
        fl_news: this.c.privacidade.fl_news,
        fl_marketing_3: this.c.privacidade.fl_marketing_3,
        fl_msg_priv: this.c.privacidade.fl_msg_priv
      };
      this.$http
        .post(`${baseApiUrl}/candidato/atualizaprivacidade`, data)
        .then(() => {
          console.log("Privacidade atualizada com sucesso!");
          this.$toasted.global.defaultSuccess({
            msg: "Dados de privacidade atualizados!"
          });
        });
    },
    atualizaCep() {
      //Auto-preenchimento dos campos a partir do CEP
      const viacep = axios.create();
      viacep.defaults.headers.common = {};
      viacep.defaults.headers.common.accept = "application/json";
      //Verifica se campo cep possui valor informado.
      if (this.c.nu_cep != "") {
        //Deixa somente os digitos
        this.c.nu_cep = this.c.nu_cep.replace(/\D/g, "");
        //Expressão regular para validar somente numeros
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(this.c.nu_cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          this.c.nm_endereco = "...";
          this.c.nm_bairro = "...";
          this.c.nm_cidade = "...";
          this.c.nm_estado = "...";
          //Consulta o webservice viacep.com.br/
          viacep
            .get("//viacep.com.br/ws/" + this.c.nu_cep + "/json/")
            .then(dados => {
              //Atualiza os campos com os valores da consulta.
              this.c.nm_endereco = dados.data.logradouro;
              this.c.nm_bairro = dados.data.bairro;
              this.c.nm_cidade = dados.data.localidade;
              this.c.nm_estado = dados.data.uf;
            });
        } else {
          //cep é inválido.
          this.limpa_formulario_cep();
          alert("Formato de CEP inválido.");
        }
      } else {
        //cep sem valor, limpa formulário.
        this.limpa_formulario_cep();
      }
    },
    limpa_formulario_cep() {
      this.c.nu_cep = "";
    },
    onChangeFoto(event) {
      const file = event.target.files[0];
      this.novaFoto = URL.createObjectURL(file);
      this.novaFotoFile = file;
    },
    atualizarFoto() {
      const form = new FormData();
      form.append("id_candidato", this.c.id_candidato);
      form.append("foto", this.novaFotoFile);
      this.$http
        .post(`${baseApiUrl}/candidato/atualizafoto`, form)
        .then(res => {
          this.$toasted.global.defaultSuccess({
            msg: "Foto atualizada com sucesso!"
          });
          this.modalFoto = false;
          this.novaFoto1 = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    removerFoto() {
      if (confirm("Deseja realmente remover a sua foto de perfil?")) {
        const data = {
          id_candidato: this.id_candidato
        };
        this.$http
          .post(`${baseApiUrl}/candidato/removerfotoperfil`, data)
          .then(res => {
            this.$toasted.global.defaultSuccess({
              msg: "Foto removida com sucesso!"
            });
            this.modalFoto = false;
            this.novaFoto1 = res.data;
          });
      }
    }
  },
  computed: {
    imagePreview() {
      return this.novaFoto == "" ? this.c.hl_foto : this.novaFoto;
    },
    imagePreview1() {
      return this.novaFoto1 == "" ? this.c.hl_foto : this.novaFoto1;
    }
  },
  data() {
    return {
      c: {},
      id_candidato: "",
      nacionalidade: {},
      privacidade: {},
      validatingToken: true,
      accountTab: "Cadastro",
      novaSenha: "",
      confirmNovaSenha: "",
      motivoEncerramento: "",
      modalEncerramente: false,
      modalFoto: false,
      novaFoto: "",
      novaFoto1: "",
      novaFotoFile: null,
      genders: [
        { id: 1, text: "Masculino" },
        { id: 2, text: "Feminino" },
        { id: 3, text: "Prefiro não informar" }
      ],
      estadoscivil: [
        { id: 1, text: "Solteiro(a)" },
        { id: 2, text: "Casado(a)" },
        { id: 3, text: "Divorciado(a)" },
        { id: 4, text: "União Estável" },
        { id: 5, text: "Viúvo(a)" }
      ]
    };
  },
  mounted() {
    this.inicializaValores();
    this.getCandidato();
    this.getNacionalidade();
  },
  beforeUpdate() {}
};
</script>
<style scoped>
.page-header {
  min-height: 100px !important;
  max-height: 900px;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
.container {
  padding-top: 0px;
  margin-top: 90px;
}
.perfil {
  padding-top: 20px;
}
/*
.account-settings .nav {
  text-align: left;
}

.account-settings .nav .nav-item {
  padding: 1rem 0;
}

.account-settings .nav .nav-item:not(:last-child) {
  border-bottom: 1px solid #5e72e4;
} */
</style>
