<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row">
            <br />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-white" v-loading="loading">
      <div class="container card mb-0">
        <div class="row mt-3">
          <div class="col-lg-2 col-4">
            <img
              :src="candidato.hl_foto"
              alt="Raised image"
              class="img-fluid rounded"
              style="width: 150px;"
            />
          </div>
          <div class="col-lg-7 col-8">
            <h4 class="text-uppercase">{{ candidato.nm_nome }}</h4>
            <p>
              Brasileiro, {{ retornaEstadoCivil(candidato.fl_estadocivil) }},
              {{ calculaIdade(candidato.dt_nasc) }} anos. <br />
              E-mail:
              <a :href="`mailto:${candidato.hl_email}`">{{
                candidato.hl_email
              }}</a
              ><br />
              Telefone: {{ candidato.nu_celular }}
              <template v-if="candidato.nu_telefone != ''"> / </template>
              {{ candidato.nu_telefone }} <br />
              {{ candidato.nm_endereco }}, {{ candidato.nu_numero }} -
              {{ candidato.nm_complemento }} - {{ candidato.nm_bairro }} -
              {{ candidato.nm_cidade }} - {{ candidato.nm_estado }}<br />
            </p>
          </div>
          <!-- Links de Midias Sociais -->
          <div class="col-lg-3 p-0">
            <ul class="social-links">
              <li v-if="candidato.hl_site_pessoal">
                <i slot="icon" class="fa fa-home"></i>
                <a :href="candidato.hl_site_pessoal" target="_blank">
                  Site Pessoal</a
                >
              </li>
              <li v-if="candidato.hl_facebook">
                <i slot="icon" class="fa fa-facebook-square"></i>
                <a :href="candidato.hl_facebook" target="_blank"> Facebook</a>
              </li>
              <li v-if="candidato.hl_twitter">
                <i slot="icon" class="fa fa-twitter-square"></i>
                <a :href="candidato.hl_twitter" target="_blank"> Twitter</a>
              </li>
              <li v-if="candidato.hl_linkedin">
                <i slot="icon" class="fa fa-linkedin-square"></i>
                <a :href="candidato.hl_linkedin" target="_blank"> LinkedIn</a>
              </li>
              <li v-if="candidato.hl_google">
                <i slot="icon" class="fa fa-youtube"></i>
                <a :href="candidato.hl_google" target="_blank"> YoutTube</a>
              </li>
              <li v-if="candidato.hl_skype">
                <i slot="icon" class="fa fa-skype"></i>
                <a :href="candidato.hl_skype" target="_blank"> Skype</a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Objetivos Profissionais -->
        <div class="row">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted">
              Objetivo Profissional:
              <span class="display-5 text-default">
                {{ curriculo.json_curriculo.nm_cargo }}
              </span>
            </p>
            <p class="mini-titulo text-muted">
              Área de Atuação:
              <span class="display-5 text-default">
                {{ retornaAreaAtuacao(curriculo.json_curriculo.nm_area) }}
              </span>
            </p>
            <p class="mini-titulo text-muted">
              Nível Hierarquico:
              <span class="display-5 text-default">
                {{ retornaNivelHierarquico(curriculo.json_curriculo.nm_nivel) }}
              </span>
            </p>
            <p class="mini-titulo text-muted" v-if="curriculo.json_curriculo.nu_salario > 0">
              Pretensão Salarial:
              <span class="display-5 text-default">{{
                formataMoeda(curriculo.json_curriculo.nu_salario)
              }}</span>
            </p>
          </div>
        </div>
        <!-- Resumo Profissional -->
        <div class="row" v-if="curriculo.json_curriculo.tx_resumo !== ''">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted">
              Resumo Profissional
            </p>
            <p class="text-description">
              {{ curriculo.json_curriculo.tx_resumo }}
            </p>
          </div>
        </div>
        <!-- Experiências Profissionais -->
        <div class="row" v-if="curriculo.json_curriculo.experiencias.length > 0">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted pb-2">
              Experiência Profissional
            </p>
            <div v-for="exp in curriculo.json_curriculo.experiencias" :key="exp.id_experiencia">
              <div class="row pt-2">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Empresa</p>
                  <p class="text-titulo">{{ exp.nm_empresa }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Cargo</p>
                  <p class="text-titulo">
                    {{ exp.nm_cargo }}
                  </p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Local de Trabalho</p>
                  <p class="text-titulo">{{ exp.nm_local }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Data de Inicio</p>
                  <p class="text-titulo">{{ exp.dt_inicio | formatoData }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Data de Saída</p>
                  <p class="text-titulo">{{ exp.dt_fim | formatoData }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Emprego Atual?</p>
                  <p class="text-titulo">
                    {{ exp.fl_atual == "0" ? "Não" : "Sim" }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <p class="sub-mini-titulo">Resumo das Atividades</p>
                  <p class="text-description">
                    {{ exp.tx_descricao }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Formação Acadêmica -->
        <div class="row" v-if="curriculo.json_curriculo.formacoes.length > 0">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted pb-2">
              Formação Acadêmica
            </p>
            <div v-for="formacao in curriculo.json_curriculo.formacoes" :key="formacao.id_formacao">
              <div class="row pt-2">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Tipo da Formação</p>
                  <p class="text-titulo">
                    {{ retornaTipoFormacao(formacao.fl_tipo_formacao) }}
                  </p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Curso</p>
                  <p class="text-titulo">{{ formacao.nm_curso }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Instituição de Ensino</p>
                  <p class="text-titulo">
                    {{ formacao.nm_instituicao }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Data de Inicio</p>
                  <p class="text-titulo">
                    {{ formacao.dt_inicio | formatoData }}
                  </p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Data de Saída</p>
                  <p class="text-titulo">{{ formacao.dt_fim | formatoData }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Concluído?</p>
                  <p class="text-titulo">
                    {{ formacao.fl_status == "0" ? "Não" : "Sim" }}
                  </p>
                </div>
              </div>
              <div class="row" v-if="formacao.tx_observacoes != ''">
                <div class="col-lg-12">
                  <p class="sub-mini-titulo">Observações</p>
                  <p class="text-description">
                    {{ formacao.tx_observacoes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Inicio Competencias -->
        <div class="row" v-if="curriculo.json_curriculo.competencias.length > 0">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted pb-2">
              Competências
            </p>
            <div class="row pt-2">
              <div
                class="col-sm-4"
                v-for="comp in curriculo.json_curriculo.competencias"
                :key="comp.id_competencia"
              >
                <span class="text-titulo">{{ comp.nm_competencia }} - </span>
                <span class="sub-mini-titulo">{{
                  retornaNivelComp(comp.fl_nivel)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Inicio Certificações -->
        <div class="row" v-if="curriculo.json_curriculo.certificacoes.length > 0">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted pb-2">
              Certificações
            </p>
            <div
              v-for="certificacao in curriculo.json_curriculo.certificacoes"
              :key="certificacao.id_certificacao"
            >
              <div class="row pt-2">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Título</p>
                  <p class="text-titulo">{{ certificacao.nm_titulo }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Orgão Emissor</p>
                  <p class="text-titulo">{{ certificacao.nm_orgao_emissor }}</p>
                </div>
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Número</p>
                  <p class="text-titulo">
                    {{ certificacao.nm_numero }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <p class="sub-mini-titulo">Data da Certificação</p>
                  <p class="text-titulo">
                    {{ certificacao.dt_certificacao | formatoData }}
                  </p>
                </div>

                <div class="col-lg-8">
                  <p class="sub-mini-titulo">Link do Certificado</p>
                  <p class="text-titulo">
                    <a :href="certificacao.hl_link_certificado" target="_blank">
                      {{ certificacao.hl_link_certificado }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Inicio Idiomas -->
        <div class="row" v-if="curriculo.json_curriculo.idiomas.length > 0">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted pb-2">
              Idiomas
            </p>
            <div class="row pt-2">
              <div
                class="col-lg-3"
                v-for="idioma in curriculo.json_curriculo.idiomas"
                :key="idioma.id_idioma"
              >
                <span class="text-titulo">{{ idioma.nm_idioma }} - </span>
                <span class="sub-mini-titulo">{{
                  retornaNivelIdioma(idioma.fl_nivel)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Resumo Profissional -->
        <div class="row" v-if="curriculo.json_curriculo.tx_info_extra !== ''">
          <div class="col-lg-12">
            <hr />
            <p class="mini-titulo text-muted">
              Informações Extras
            </p>
            <p class="text-description">
              {{ curriculo.json_curriculo.tx_info_extra }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { userKey, baseApiUrl, cvAtual, showError } from "@/global";
import { Loading } from 'element-ui';

Vue.use(Loading.directive);

export default {
  bodyClass: "account-settings",
  components: {},
  data() {
    return {
      loading: true,
      id_candidato: "",
      id_curriculo: "",
      //curriculo: {},
      //candidato: {},
      objetivo: [],
      resumo: [],
      experiencias: [],
      formacoes: [],
      competencias: [],
      certificacoes: [],
      idiomas: [],
      estadoscivil: [
        { id: 1, text: "Solteiro(a)" },
        { id: 2, text: "Casado(a)" },
        { id: 3, text: "Divorciado(a)" },
        { id: 4, text: "União Estável" },
        { id: 5, text: "Viúvo(a)" }
      ],
      sysformacoes: [
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
      nivelexperiencia: [
        { id: 1, text: "Iniciante" },
        { id: 2, text: "Intermediário" },
        { id: 3, text: "Avançado" },
        { id: 4, text: "Especialista" }
      ],
      nivelidioma: [
        { id: 1, text: "Básico" },
        { id: 2, text: "Intermediário" },
        { id: 3, text: "Avançado" },
        { id: 4, text: "Fluente" }
      ],
      areasatuacao: [
        { id: 51, text: "Administração" },
        { id: 52, text: "Agricultura, Pecuária, Veterinária" },
        { id: 92, text: "Alimentação / Gastronomia" },
        { id: 55, text: "Arquitetura, Decoração, Design" },
        { id: 56, text: "Artes" },
        { id: 57, text: "Auditoria" },
        { id: 58, text: "Ciências, Pesquisa " },
        { id: 59, text: "Comercial, Vendas" },
        { id: 60, text: "Comércio Exterior, Importação, Exportação" },
        { id: 62, text: "Compras" },
        { id: 63, text: "Comunicação, TV, Cinema" },
        { id: 64, text: "Construção, Manutenção" },
        { id: 66, text: "Contábil, Finanças, Economia" },
        { id: 67, text: "Cultura, Lazer, Entretenimento" },
        { id: 69, text: "Educação, Ensino, Idiomas " },
        { id: 70, text: "Engenharia" },
        { id: 71, text: "Estética" },
        { id: 72, text: "Hotelaria, Turismo" },
        { id: 73, text: "Industrial, Produção, Fábrica" },
        { id: 74, text: "Informática, TI, Telecomunicações" },
        { id: 75, text: "Jurídica" },
        { id: 54, text: "Logística" },
        { id: 77, text: "Marketing" },
        { id: 78, text: "Meio Ambiente, Ecologia" },
        { id: 93, text: "Moda" },
        { id: 79, text: "Qualidade " },
        { id: 80, text: "Química, Petroquímica" },
        { id: 81, text: "Recursos Humanos" },
        { id: 82, text: "Saúde" },
        { id: 83, text: "Segurança" },
        { id: 76, text: "Serviços Gerais" },
        { id: 85, text: "Serviço Social e Comunitário" },
        { id: 86, text: "Telemarketing" },
        { id: 87, text: "Transportes" }
      ],
      niveishierarquicos: [
        { id: 0, text: "Nível Hierárquico" },
        { id: 1, text: "Estagiário" },
        { id: 2, text: "Operacional" },
        { id: 3, text: "Auxiliar" },
        { id: 4, text: "Assistente" },
        { id: 5, text: "Trainee" },
        { id: 6, text: "Analista" },
        { id: 7, text: "Encarregado" },
        { id: 8, text: "Supervisor" },
        { id: 9, text: "Consultor" },
        { id: 10, text: "Especialista" },
        { id: 11, text: "Coordenador" },
        { id: 12, text: "Gerente" },
        { id: 13, text: "Diretor" }
      ]
    };
  },
  computed: {
    candidato() {
      return this.$store.state.candidato;
    },
    curriculo() {
      const json2 = sessionStorage.getItem(cvAtual);
      const cvData = JSON.parse(json2);
      return this.$store.state.curriculos.find(c => c.id_curriculo === cvData.id_curriculo);
    }
  },
  methods: {
    inicializaValores() {
      this.id_candidato = this.candidato.id_candidato;
      const json2 = sessionStorage.getItem(cvAtual);
      const cvData = JSON.parse(json2);
      this.id_curriculo = cvData.id_curriculo;
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    getCandidato() {
      this.$http
        .get(`${baseApiUrl}/candidato`, {
          params: { id_candidato: this.id_candidato }
        })
        .then(res => {
          this.candidato = res.data;
        })
        .catch(showError);
    },
    loadCv() {
      this.$http
        .get(`${baseApiUrl}/curriculos/editar`, {
          params: {
            id_curriculo: this.id_curriculo
          }
        })
        .then(res => {
          this.curriculo = res.data;
          this.objetivo = res.data.objetivo;
          this.resumo = res.data.resumo;
          this.idiomas = res.data.idioma;
          this.experiencias = res.data.experiencia;
          this.formacoes = res.data.formacao;
          this.competencias = res.data.competencia;
          this.certificacoes = res.data.certificacao;
          console.log(res.data);
        })
        .catch(showError);
    },
    retornaNivelComp(id) {
      let item = this.nivelexperiencia.find(u => u.id == id);
      return item.text;
    },
    retornaTipoFormacao(id) {
      let item = this.sysformacoes.find(u => u.id == id);
      return item.text;
    },
    retornaEstadoCivil(id) {
      if (id === null) { id = 1;}
      let item = this.estadoscivil.find(u => u.id == id);
      return item.text;
    },
    retornaNivelIdioma(id) {
      let item = this.nivelidioma.find(u => u.id == id);
      return item.text;
    },
    retornaAreaAtuacao(id) {
      if (id === "") {
        let id = 51;
        var item = this.areasatuacao.find(u => u.id == id);
      } else {
        var item = this.areasatuacao.find(u => u.id == id);
      }
      return item.text;
    },
    retornaNivelHierarquico(id) {
      if (id === "") { id = 2;}
      let item = this.niveishierarquicos.find(u => u.id == id);
      return item.text;
    },
    formataMoeda(numero) {
      const formatado = new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL"
      }).format(numero);
      return formatado;
    },
    calculaIdade(dataNasc) {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      var anoNascParts = dataNasc.split("-");
      var diaNasc = anoNascParts[2];
      var mesNasc = anoNascParts[1];
      var anoNasc = anoNascParts[0];
      var idade = anoAtual - anoNasc;
      var mesAtual = dataAtual.getMonth() + 1;
      //Se mes atual for menor que o nascimento, nao fez aniversario ainda;
      if (mesAtual < mesNasc) {
        idade--;
      } else {
        //Se estiver no mes do nascimento, verificar o dia
        if (mesAtual == mesNasc) {
          if (new Date().getDate() < diaNasc) {
            //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
            idade--;
          }
        }
      }
      return idade;
    }
  },
  mounted() {
    this.inicializaValores();
    //this.loadCv();
    //this.getCandidato();
  }
};
</script>
<style scoped>
.page-header {
  min-height: 100px !important;
  max-height: 900px;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
.container {
  padding-top: 0px;
  margin-top: 90px;
}
.perfil {
  padding-top: 20px;
}
.default-p {
  color: black;
}
.mini-titulo {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0 !important;
}
.sub-mini-titulo {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0 !important;
}
.display-5 {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 0;
}
.text-description {
  font-size: 0.9rem;
  font-weight: 600;
}
.text-titulo {
  font-size: 0.9rem;
  font-weight: 600;
}
.social-links {
  list-style-type: none;
}
.doted {
  box-sizing: content-box !important;
  border-top: 1px dotted;
}
.box-area {
  border-style: solid;
  border-width: 0.01em;
  border-color: rgb(182, 178, 178);
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 1px;
  margin-left: 1px;
}
</style>
