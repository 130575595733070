import Vue from "vue";
import App from "./App.vue";

import store from "./store/store";
import router from "./routes/router";
import VueSession from "vue-session";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
locale.use(lang);

import "./plugins/axios";
import Argon from "./plugins/argon-kit";
import "./plugins/toast";
import moment from "moment";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faSearch);
library.add(faFontAwesome);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

var options = {
  persist: true
};
Vue.use(VueSession, options);
Vue.use(Argon);

Vue.filter("formatoData", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");
