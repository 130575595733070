<template>
  <div>
    <div class="row" v-if="propObjetivo !== undefined">
      <!-- Campo Cargo -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="cargo">Cargo Desejado <el-tooltip placement="top" effect="light"><div slot="content">Cargo ou posição que deseja se alocar<br/>Ex. Analista Administrativo</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-autocomplete
            ref="cargo"
            id="cargo"
            class="inuptAutocomplete"
            :maxlength="255"
            v-model="objetivo.nm_cargo"
            :fetch-suggestions="querySearchCargos"
            placeholder="Cargo"
            :trigger-on-focus="false"
            :suffix-icon="validaObjetivo.nm_cargo ? 'text-success el-icon-check' : ''"
            @blur="validaCampos('cargo')"
          ></el-autocomplete>
          <span class="text-danger" v-if="validaObjetivo.nm_cargo === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Campo Área de Atuação -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="areaatuacao">Área de Atuação <el-tooltip placement="top" effect="light"><div slot="content">Área em que você deseja trabalhar.<br/>Não é o ramo da empresa</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-select
            ref="areaatuacao"
            name="Área de Atuação"
            class="inuptAutocomplete"
            label="Área de Atuação"
            id="areaatuacao"
            v-model="objetivo.nm_area"
            @change="validaCampos('areaatuacao')"
            @blur="validaCampos('areaatuacao')"
          >
            <el-option
              v-for="area in areasatuacao"
              :key="area.id"
              :value="area.id"
              :label="area.text"
            ></el-option>
          </el-select>
          <span class="text-danger" v-if="validaObjetivo.nm_area === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Campo Nível Hierarquico -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="nivel">Nível Hieráquico <el-tooltip placement="top" effect="light"><div slot="content">Nível Hierárquico define o grau<br>de experiência e responsabilidades </div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-select
            ref="nivel"
            name="Nível Hieráquico"
            class="inuptAutocomplete"
            label="Nível Hieráquico"
            id="nivel"
            v-model="objetivo.nm_nivel"
            @change="validaCampos('nivel')"
            @blur="validaCampos('nivel')"
          >
            <el-option
              v-for="nivel in niveishierarquicos"
              :key="nivel.id"
              :value="nivel.id"
              :label="nivel.text"
            ></el-option>
          </el-select>
          <span class="text-danger" v-if="validaObjetivo.nm_nivel === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Campo Jornada -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="jornada">Jornada de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">A jornada se trabalho deve ser<br>entendida como o período de trabalho</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-select
            ref="jornada"
            name="Jornada de Trabalho"
            class="inuptAutocomplete"
            label="Jornada de Trabalho"
            id="jornada"
            v-model="objetivo.fl_jornada"
            @change="validaCampos('jornada')"
            @blur="validaCampos('jornada')"
          >
            <el-option
              v-for="jornada in jornadas"
              :key="jornada.id"
              :value="jornada.id"
              :label="jornada.text"
            ></el-option>
          </el-select>
          <span class="text-danger" v-if="validaObjetivo.fl_jornada === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Campo Tipo de contrato -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="tipocontrato">Tipo de Contrato <el-tooltip placement="top" effect="light"><div slot="content">A forma de você deseja ser contratato.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-select
            ref="tipocontrato"
            name="Tipo de Contrato"
            class="inuptAutocomplete"
            label="Tipo de Contrato"
            id="tipocontrato"
            v-model="objetivo.fl_tipo_contrato"
            @change="validaCampos('tipocontrato')"
            @blur="validaCampos('tipocontrato')"
          >
            <el-option
              v-for="tipo in tiposcontrato"
              :key="tipo.id"
              :value="tipo.id"
              :label="tipo.text"
            ></el-option>
          </el-select>
          <span class="text-danger" v-if="validaObjetivo.fl_tipo_contrato === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Radios PJ -->
      <div class="col-lg-4">
        <div class="row ml-1">
          <label>Aceita trabalhar como PJ? <el-tooltip placement="top" effect="light"><div slot="content">Marcar essa opção indicará que você<br>aceita trabalhar como Pessoa Jurídica.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
        </div>
        <div class="row ml-1">
          <base-switch
            type="primary"
            on-text="Sim"
            off-text="Não"
            v-model="objetivo.fl_pj"
            @blur="emiteDados"
          ></base-switch>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Salario -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="salario">Salário Desejado <el-tooltip placement="top" effect="light"><div slot="content">Deixe '0' caso não queira definir o salário desejado.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-input
            ref="salario"
            id="salario"
            type="number"
            class="inuptAutocomplete"
            v-model="objetivo.nu_salario"
            :suffix-icon="validaObjetivo.nu_salario ? 'text-success el-icon-check' : ''"
            @blur="validaCampos('salario')"
          ></el-input>
          <span class="text-danger" v-if="validaObjetivo.nu_salario === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Local de Trabalho -->
      <div class="col-lg-4">
        <div class="form-group">
          <label for="cidade">Local de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">Informe a cidade que você deseja trabalhar.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
          <el-autocomplete
            ref="cidade"
            id="cidade"
            class="inuptAutocomplete"
            :maxlength="255"
            v-model="objetivo.nm_cidade"
            :fetch-suggestions="querySearchCidade"
            placeholder="Cidade"
            :trigger-on-focus="false"
            :suffix-icon="validaObjetivo.nm_cidade ? 'text-success el-icon-check' : ''"
            @blur="validaCampos('cidade')"
          ></el-autocomplete>
          <span class="text-danger" v-if="validaObjetivo.nm_cidade === false"><small>Esse campo é obrigatório</small></span>
        </div>
      </div>
      <!-- Flag de primeiro emprego -->
      <!-- Radios PJ -->
      <div class="col-lg-4">
        <div class="row ml-1">
          <label>Primeiro Emprego? <el-tooltip placement="top" effect="light"><div slot="content">Marque essa opção se você busca<br>o seu primeiro emprego.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
        </div>
        <div class="row ml-1">
          <base-switch
            type="primary"
            on-text="Sim"
            off-text="Não"
            v-model="objetivo.fl_primeiro_emprego"
            @blur="emiteDados"
          ></base-switch>
        </div>
      </div>
    </div>
    <!-- Variaveis -->
    <!-- <div class="row">
      <div class="col">objetivo -> <pre>{{objetivo}}</pre></div>
      <div class="col">validaObjetivo -> <pre>{{validaObjetivo}}</pre></div>
      <div class="col"></div>
    </div> -->
  </div>
</template>

<script>
import { Select, Option, Autocomplete, Input } from 'element-ui';
import varCidades from '@/data/cidades_br.js';

export default {
  name: "Objetivos",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    propObjetivo: { type: Object },
    procDadosObjetivo: { type: Function },
    propValidacao: { type: Boolean }
  },
  data() {
    return {
      objetivo: {
        nm_cargo: "",
        nm_area: "",
        nm_nivel: "",
        fl_jornada: 1,
        fl_tipo_contrato: 3,
        fl_pj: true,
        fl_primeiro_emprego: false,
        nu_salario: 0,
        nm_cidade: ""
      },
      validaObjetivo: {
        nm_cargo: null,
        nm_area: null,
        nm_nivel: null,
        fl_jornada: null,
        fl_tipo_contrato: null,
        nu_salario: null,
        nm_cidade: null
      },
      cidades: [],
      areasatuacao: [
        { id: 51, text: "Administração" },
        { id: 52, text: "Agricultura, Pecuária, Veterinária" },
        { id: 92, text: "Alimentação / Gastronomia" },
        { id: 55, text: "Arquitetura, Decoração, Design" },
        { id: 56, text: "Artes" },
        { id: 57, text: "Auditoria" },
        { id: 58, text: "Ciências, Pesquisa " },
        { id: 59, text: "Comercial, Vendas" },
        { id: 60, text: "Comércio Exterior, Importação, Exportação" },
        { id: 62, text: "Compras" },
        { id: 63, text: "Comunicação, TV, Cinema" },
        { id: 64, text: "Construção, Manutenção" },
        { id: 66, text: "Contábil, Finanças, Economia" },
        { id: 67, text: "Cultura, Lazer, Entretenimento" },
        { id: 69, text: "Educação, Ensino, Idiomas " },
        { id: 70, text: "Engenharia" },
        { id: 71, text: "Estética" },
        { id: 72, text: "Hotelaria, Turismo" },
        { id: 73, text: "Industrial, Produção, Fábrica" },
        { id: 74, text: "Informática, TI, Telecomunicações" },
        { id: 75, text: "Jurídica" },
        { id: 54, text: "Logística" },
        { id: 77, text: "Marketing" },
        { id: 78, text: "Meio Ambiente, Ecologia" },
        { id: 93, text: "Moda" },
        { id: 79, text: "Qualidade " },
        { id: 80, text: "Química, Petroquímica" },
        { id: 81, text: "Recursos Humanos" },
        { id: 82, text: "Saúde" },
        { id: 83, text: "Segurança" },
        { id: 76, text: "Serviços Gerais" },
        { id: 85, text: "Serviço Social e Comunitário" },
        { id: 86, text: "Telemarketing" },
        { id: 87, text: "Transportes" }
      ],
      niveishierarquicos: [
        { id: 1, text: "Estagiário" },
        { id: 2, text: "Operacional" },
        { id: 3, text: "Auxiliar" },
        { id: 4, text: "Assistente" },
        { id: 5, text: "Trainee" },
        { id: 6, text: "Analista" },
        { id: 7, text: "Encarregado" },
        { id: 8, text: "Supervisor" },
        { id: 9, text: "Consultor" },
        { id: 10, text: "Especialista" },
        { id: 11, text: "Coordenador" },
        { id: 12, text: "Gerente" },
        { id: 13, text: "Diretor" }
      ],
      jornadas: [
        { id: 1, text: "Período Integral" },
        { id: 2, text: "Parcial manhãs" },
        { id: 5, text: "Parcial tardes" },
        { id: 6, text: "Parcial noites" },
        { id: 12, text: "Noturno" }
      ],
      tiposcontrato: [
        { id: 1, text: "Autônomo" },
        { id: 2, text: "Cooperado" },
        { id: 3, text: "Efetivo & CLT" },
        { id: 4, text: "Estágio" },
        { id: 5, text: "Outros" },
        { id: 6, text: "Prestador de Serviços (PJ)" },
        { id: 7, text: "Temporário" },
        { id: 8, text: "Trainee" }
      ],
    }
  },
  computed: {
    cargosState() {
      return this.$store.state.cargos;
    },
  },
  methods: {
    emiteDados() {
      this.$emit('resObjetivo', this.objetivo);
      setTimeout(() => {
        this.procDadosObjetivo();
      }, 300);
    },
    querySearchCidade(queryString, cb) {
      let cidades = this.cidades;
      var results = queryString ? cidades.filter(this.createFilterCidade(queryString)) : cidades;
      cb(results)
    },
    createFilterCidade(queryString) {
      return (cidade) => {
        return (cidade.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    ajustaCidades() {
      varCidades.forEach(element => {
        let city = {
          id: element.id_cidade,
          value: element.nm_cidade_uf
        }
        this.cidades.push(city);
      });
    },
    querySearchCargos(queryString, cb) {
      let cargos = this.cargosState;
      var results = queryString ? cargos.filter(this.createFilterCargos(queryString)) : cargos;
      cb(results)
    },
    createFilterCargos(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    validaCampos(campo) {
      var count = 0;
      if(campo === 'cargo') {
        if(this.objetivo.nm_cargo !== "") {
          count = count + 1;
          this.validaObjetivo.nm_cargo = true;
        } else {
          this.validaObjetivo.nm_cargo = false;
        }
      } else if(campo === 'areaatuacao') {
        if(this.objetivo.nm_area !== "") {
          this.validaObjetivo.nm_area = true;
        } else {
          this.validaObjetivo.nm_area = false;
        }
      } else if(campo === 'nivel') {
        if(this.objetivo.nm_nivel !== "") {
          count = count + 1;
          this.validaObjetivo.nm_nivel = true;
        } else {
          this.validaObjetivo.nm_nivel = false;
        }
      } else if(campo === 'jornada') {
        if(this.objetivo.fl_jornada !== "") {
          count = count + 1;
          this.validaObjetivo.fl_jornada = true;
        } else {
          this.validaObjetivo.fl_jornada = false;
        }
      } else if(campo === 'tipocontrato') {
        if(this.objetivo.fl_tipo_contrato !== "") {
          count = count + 1;
          this.validaObjetivo.fl_tipo_contrato = true;
        } else {
          this.validaObjetivo.fl_tipo_contrato = false;
        }
      } else if(campo === 'salario') {
        if(this.objetivo.nu_salario !== "") {
          count = count + 1;
          this.validaObjetivo.nu_salario = true;
        } else {
          this.validaObjetivo.nu_salario = false;
        }
      } else if(campo === 'cidade') {
        if(this.objetivo.nm_cidade !== "") {
          count = count + 1;
          this.validaObjetivo.nm_cidade = true;
          this.validacaoEmit();
        } else {
          this.validaObjetivo.nm_cidade = false;
        }
      }
    },
    validacaoEmit() {
      var count = 0;
      if(this.objetivo.nm_cargo !== "") {
        count = count + 1;
        this.validaObjetivo.nm_cargo = true;
      } else {
        this.validaObjetivo.nm_cargo = false;
      }
      if(this.objetivo.nm_area !== "") {
        count = count + 1;
        this.validaObjetivo.nm_area = true;
      } else {
        this.validaObjetivo.nm_area = false;
      }
      if(this.objetivo.nm_nivel !== "") {
        count = count + 1;
        this.validaObjetivo.nm_nivel = true;
      } else {
        this.validaObjetivo.nm_nivel = false;
      }
      if(this.objetivo.fl_jornada !== "") {
        count = count + 1;
        this.validaObjetivo.fl_jornada = true;
      } else {
        this.validaObjetivo.fl_jornada = false;
      }
      if(this.objetivo.fl_tipo_contrato !== "") {
        count = count + 1;
        this.validaObjetivo.fl_tipo_contrato = true;
      } else {
        this.validaObjetivo.fl_tipo_contrato = false;
      }
      if(this.objetivo.nu_salario !== "") {
        count = count + 1;
        this.validaObjetivo.nu_salario = true;
      } else {
        this.validaObjetivo.nu_salario = false;
      }
      if(this.objetivo.nm_cidade !== "") {
        count = count + 1;
        this.validaObjetivo.nm_cidade = true;
      } else {
        this.validaObjetivo.nm_cidade = false;
      }
      if(count === 7) {
        this.emiteDados();
      } else {
        return false;
      }
    }
  },
  mounted() {
    const objetivo = structuredClone(this.propObjetivo);
    this.objetivo.nm_cargo = objetivo.nm_cargo;
    this.objetivo.nm_area = objetivo.nm_area;
    this.objetivo.nm_nivel = objetivo.nm_nivel;
    this.objetivo.fl_jornada = objetivo.fl_jornada;
    this.objetivo.fl_tipo_contrato = objetivo.fl_tipo_contrato;
    this.objetivo.fl_pj = objetivo.fl_pj;
    this.objetivo.fl_primeiro_emprego = objetivo.fl_primeiro_emprego;
    this.objetivo.nu_salario = objetivo.nu_salario;
    this.objetivo.nm_cidade = objetivo.nm_cidade;
    this.ajustaCidades();
  },
  watch: {
    propValidacao: {
      handler(value) {
        if (value) {
          this.validacaoEmit();
        }
      }
    }
  }
}
</script>

<style>
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
