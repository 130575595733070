<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <h5 class="my-0">{{propValue}}<span v-if="propRequired" class="text-danger"> *</span></h5>
      </div>
    </div>
    <div class="row mb-3 mt-1 mx-1">
      <div class="col mx-3">
        <div v-for="opt in propOptions" :key="opt.value">
          <input
            type="radio"
            :id="opt.value + name"
            :name="name"
            :value="opt.value"
            class="form-check-input mb-3" v-model="resp"
            @change="emitData">
          <label class="form-check-label" :for="opt.value + name">{{ opt.text }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RespostaUnica",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean },
    propOptions: { type: Array }
  },
  data() {
    return {
      name: "",
      resp: "",
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    },
    makeName() {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < 8; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  created() {
    this.name = this.makeName();
  }
}
</script>

<style scoped>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
