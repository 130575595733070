<template>
  <router-view></router-view>
</template>

<script>
import Header from '../../layout/AppHeader.vue';
import Footer from '../../layout/AppFooter.vue';

export default {
  name: "DefaultLayout",
  components: {
    //Header,
    //Footer
  }
}
</script>

<style>

</style>
