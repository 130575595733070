<template>
  <footer class="footer">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-lg-6 col-sm-12">
          <div class="copyright">
            &copy; {{ year }}
            <a href="https://www.matchjob.com.br" target="_blank">MatchJob Empregos</a> <small class="text-muted">v1.0.4</small>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <a href="https://www.matchjob.com.br/ajuda" class="nav-link" target="_blank"
                >Ajuda</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.matchjob.com.br/sobre-nos"
                class="nav-link"
                target="_blank"
                >Sobre Nós</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://blog.matchjob.com.br/"
                class="nav-link"
                target="_blank"
                >Blog</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.matchjob.com.br/termos-de-uso"
                class="nav-link"
                target="_blank"
                >Termos de Uso</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.matchjob.com.br/politica-de-privacidade"
                class="nav-link"
                target="_blank"
                >Política de Privacidade</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
