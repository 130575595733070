<template>
  <div>
    <base-button
      type="primary"
      size="sm"
      class="mb-3"
      @click="flNovaFormacao = true"
    >
      Adicionar Formação</base-button
    >
    <div v-for="(formacao, index) in formacoes" :key="index">
      <div class="row">
        <!-- Campo tipo da Formação -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Tipo da Formação<br><strong>{{retornaTipoFormacao(formacao.fl_tipo_formacao)}}</strong></p>
          </div>
        </div>
        <!-- Campo Instituição -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Instituição<br><strong>{{formacao.nm_instituicao}}</strong></p>
          </div>
        </div>
        <!-- Campo Nome do Curso -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Curso<br><strong>{{formacao.nm_curso}}</strong></p>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Campo Data de Inicio -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Data de Inicio<br><strong>{{formacao.dt_inicio | formatDate}}</strong></p>
          </div>
        </div>
        <!-- Campo Data Fim -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Data de Conclusão<br><strong>{{formacao.dt_fim | formatDate}}</strong></p>
          </div>
        </div>
        <!-- Campo flag de Status de término -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Concluído?<br><strong class="text-muted">{{formacao.fl_status ? "Sim" : "Não"}}</strong></p>
          </div>
        </div>
      </div>
      <div class="row" v-if="formacao.tx_observacoes !== ''">
        <div class="col-lg">
          <div class="form-group">
            <p>Observações<br><small>{{formacao.tx_observacoes}}</small></p>
          </div>
        </div>
      </div>
      <!-- Botões Editar e Apagar -->
      <div class="row">
        <div class="col-lg d-flex justify-content-end pb-4">
          <base-button
            type="danger"
            size="sm"
            @click="removerFormacao(formacao.id)"
            >Apagar</base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butUp(formacao.id)" @click="moveUp(formacao.id)"
            ><i class="fas fa-arrow-up"></i></base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butDown(formacao.id)" @click="moveDown(formacao.id)"
            ><i class="fas fa-arrow-down"></i></base-button
          >
          <base-button
            type="primary"
            size="sm"
            @click="editarFormacao(formacao.id)"
            >Atualizar</base-button
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>
    <!-- <pre>{{propFormacoes}}</pre> -->
    <modal
      :show.sync="flNovaFormacao"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Adicionar Formação
      </h5>
      <div class="row">
        <!-- Campo Tipo Formação -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="tipoformacao">Tipo da Formação <el-tooltip placement="top" effect="light"><div slot="content">Escolha o tipo do curso informado</div><i class="fas fa-question-circle"></i></el-tooltip></label>
            <el-select
              ref="tipoformacao"
              name="Área de Atuação"
              class="inuptAutocomplete"
              label="Área de Atuação"
              id="tipoformacao"
              v-model="novaFormacao.fl_tipo_formacao"
            >
              <el-option
                v-for="formacao in sysformacoes"
                :key="formacao.id"
                :value="formacao.id"
                :label="formacao.text"
              ></el-option>
            </el-select>
            <small class="text-danger" v-if="validacaoForm.tipo === false"><strong>Você precisa informar o tipo da formação</strong></small>
          </div>
        </div>
        <!-- Campo Instituição -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="instituicao">Instituição <el-tooltip placement="top" effect="light"><div slot="content">Nome da Instituicao de ensino</div><i class="fas fa-question-circle"></i></el-tooltip></label>
            <el-autocomplete
              ref="instituicao"
              id="instituicao"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaFormacao.nm_instituicao"
              :fetch-suggestions="querySearchInstituicoes"
              placeholder="Instituição"
              :trigger-on-focus="false"
            ></el-autocomplete>
            <small class="text-danger" v-if="validacaoForm.instituicao === false"><strong>Você precisa informar a Instituição</strong></small>
          </div>
        </div>
        <!-- Campo Curso -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="curso">Curso <el-tooltip placement="top" effect="light"><div slot="content">Nome do Curso</div><i class="fas fa-question-circle"></i></el-tooltip></label>
            <el-autocomplete
              ref="curso"
              id="curso"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaFormacao.nm_curso"
              :fetch-suggestions="querySearchFormacoes"
              placeholder="Curso"
              :trigger-on-focus="false"
            ></el-autocomplete>
            <small class="text-danger" v-if="validacaoForm.curso === false"><strong>Você precisa informar o Curso</strong></small>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Campo Data de Inicio -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="inicio">Data de Inicio</label>
            <el-input
              type="date"
              id="inicio"
              class="inuptAutocomplete"
              v-model="novaFormacao.dt_inicio"
            ></el-input>
            <small class="text-danger" v-if="validacaoForm.inicio === false"><strong>Você precisa informar a data de inicio</strong></small>
          </div>
        </div>
        <!-- Campo Data Fim -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="fim">Data de Conclusão</label>
            <el-input
              type="date"
              id="fim"
              class="inuptAutocomplete"
              v-model="novaFormacao.dt_fim"
            ></el-input>
            <small class="text-danger" v-if="validacaoForm.fim === false"><strong>Você precisa informar a data de conclusão ou não marcar como 'Concluído'</strong></small>
          </div>
        </div>
        <!-- Campo flag de Status de término -->
        <div class="col-lg-4">
          <div class="form-group">
            <div class="row ml-1">
              <label>Concluído?</label>
            </div>
            <div class="row ml-1">
              <base-switch
                type="primary"
                on-text="Sim"
                off-text="Não"
                v-model="novaFormacao.fl_status"
              ></base-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="observacoes">Observações</label>
            <textarea
              class="form-control"
              id="observacoes"
              rows="3"
              maxlength="500"
              v-model="novaFormacao.tx_observacoes"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <!-- Botões Incluir e Cancelar -->
      <div class="row">
        <div class="col d-flex justify-content-end">
          <base-button
            type="secondary"
            @click="cancelaNovaFormacao"
            >Cancelar</base-button
          >
          <base-button type="primary" @click="addNovaFormacao"
            >Incluir</base-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Select, Option, Autocomplete, Input } from 'element-ui';
import Modal from "@/components/Modal.vue";
import moment from "moment";

export default {
  name: "Formacoes",
  components: {
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },
  props: {
    propFormacoes: { type: Array },
    procDadosFormacoes: { type: Function }
  },
  data() {
    return {
      formacoes: [],
      novaFormacao: {
        id: 0,
        fl_tipo_formacao: 0,
        nm_instituicao: "",
        nm_curso: "",
        dt_inicio: "",
        dt_fim: "",
        fl_status: true,
        tx_observacoes: ""
      },
      sysformacoes: [
        { id: 0, text: "Selecione..." },
        { id: 1, text: "Ensino Fundamental (1º grau)" },
        { id: 2, text: "Curso extra-curricular / Profissionalizante " },
        { id: 3, text: "Ensino Médio (2º Grau)" },
        { id: 4, text: "Curso Técnico" },
        { id: 5, text: "Ensino Superior" },
        { id: 6, text: "Pós-graduação - Especialização/MBA" },
        { id: 7, text: "Pós-graduação - Mestrado" },
        { id: 8, text: "Pós-graduação - Doutorado" }
      ],
      flNovaFormacao: false,
      validacaoForm: {
        tipo: true,
        instituicao: true,
        curso: true,
        inicio: true,
        conclusao: true,
        concluido: true,
      }
    }
  },
  computed: {
    instituicoesState() {
      return this.$store.state.instituicoes;
    },
    formacoesState() {
      return this.$store.state.formacoes;
    }
  },
  methods: {
    emiteDados() {
      this.$emit('resFormacoes', this.formacoes);
      setTimeout(() => {
        this.procDadosFormacoes();
      }, 300);
    },
    retornaTipoFormacao(id) {
      var res = this.sysformacoes.find(i => i.id === parseInt(id));
      return res.text;
    },
    querySearchInstituicoes(queryString, cb) {
      let instituicoes = this.instituicoesState;
      var results = queryString ? instituicoes.filter(this.createFilterInstituicoes(queryString)) : instituicoes;
      cb(results)
    },
    createFilterInstituicoes(queryString) {
      return (instituicao) => {
        return (instituicao.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    querySearchFormacoes(queryString, cb) {
      let formacoes = this.formacoesState;
      var results = queryString ? formacoes.filter(this.createFilterFormacoes(queryString)) : formacoes;
      cb(results)
    },
    createFilterFormacoes(queryString) {
      return (formacao) => {
        return (formacao.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    cancelaNovaFormacao() {
      //variavel de validação do form
      this.validacaoForm.tipo = true;
      this.validacaoForm.instituicao = true;
      this.validacaoForm.curso = true;
      this.validacaoForm.inicio = true;
      this.validacaoForm.conclusao = true;
      this.validacaoForm.concluido = true;
      //Variavel de nova formação
      this.novaFormacao.id = 0;
      this.novaFormacao.fl_tipo_formacao = "";
      this.novaFormacao.nm_instituicao = "";
      this.novaFormacao.nm_curso = "";
      this.novaFormacao.dt_inicio = "";
      this.novaFormacao.dt_fim = "";
      this.novaFormacao.fl_status = true;
      this.novaFormacao.tx_observacoes = "";
      this.flNovaFormacao = false;
    },
    validaNovaFormacao() {
      if(this.novaFormacao.fl_tipo_formacao === 0) {
        this.validacaoForm.tipo = false;
        return false;
      } else if (this.novaFormacao.nm_instituicao === "") {
        this.validacaoForm.instituicao = false;
        return false;
      } else if (this.novaFormacao.nm_curso === "") {
        this.validacaoForm.curso = false;
        return false;
      } else if (this.novaFormacao.dt_inicio === "") {
        this.validacaoForm.inicio = false;
        return false;
      } else if (this.novaFormacao.fl_status === false && this.novaFormacao.dt_fim === "") {
        this.validacaoForm.conclusao = false;
        this.validacaoForm.concluido = false;
        return false;
      } else {
        this.validacaoForm.tipo = true;
        this.validacaoForm.instituicao = true;
        this.validacaoForm.curso = true;
        this.validacaoForm.inicio = true;
        this.validacaoForm.conclusao = true;
        this.validacaoForm.concluido = true;
        return true;
      }
    },
    addNovaFormacao() {
      if (this.validaNovaFormacao()) {
        if (this.novaFormacao.id === 0) {
          if (this.formacoes.length > 0) {
            const lastItem = this.formacoes.pop();
            let id = lastItem.id;
            this.formacoes.push(lastItem);
            this.novaFormacao.id = ++id;
          } else {
            this.novaFormacao.id = 1;
          }
          let newItem = structuredClone(this.novaFormacao)
          this.formacoes.push(newItem);
          this.cancelaNovaFormacao();
          this.emiteDados();
        } else {
          var pos = this.formacoes.findIndex( item => item.id === this.novaFormacao.id);
          let tempRes = structuredClone(this.novaFormacao);
          this.formacoes.splice(pos, 1, tempRes);
          this.cancelaNovaFormacao();
          this.emiteDados();
        }
      }
    },
    butUp(id){
      var pos = this.formacoes.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      //var pos = this.newTest.findIndex( item => item.id === id);
      if (this.formacoes.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.formacoes.findIndex( item => item.id === idNow);
      var pos1 = this.formacoes.findIndex( item => item.id === idNext);
      var arr0 = this.formacoes.find( item => item.id === idNow);
      var arr1 = this.formacoes.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.formacoes.splice(pos0, 1, arr1);
      this.formacoes.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.formacoes.findIndex( item => item.id === idNow);
      var pos1 = this.formacoes.findIndex( item => item.id === idNext);
      var arr0 = this.formacoes.find( item => item.id === idNow);
      var arr1 = this.formacoes.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.formacoes.splice(pos0, 1, arr1);
      this.formacoes.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    removerFormacao(id) {
      if (confirm('Tem certeza que deseja remover essa formação?')) {
        var pos = this.formacoes.findIndex( item => item.id === id);
        var tam = this.formacoes.length;
        if (tam === (pos + 1)) {
          this.formacoes.splice(pos, 1);
          this.emiteDados();
        } else {
          for (var i = pos+1; i < tam; i++){
            this.formacoes[i].id = this.formacoes[i].id-1;
          }
          this.formacoes.splice(pos, 1);
          this.emiteDados();
        }
      }
    },
    editarFormacao(id) {
      var editFormacao = this.formacoes.find( item => item.id === id);
      this.novaFormacao.id = editFormacao.id;
      this.novaFormacao.fl_tipo_formacao = editFormacao.fl_tipo_formacao;
      this.novaFormacao.nm_instituicao = editFormacao.nm_instituicao;
      this.novaFormacao.nm_curso = editFormacao.nm_curso;
      this.novaFormacao.dt_inicio = editFormacao.dt_inicio;
      this.novaFormacao.dt_fim = editFormacao.dt_fim;
      this.novaFormacao.fl_status = editFormacao.fl_status;
      this.novaFormacao.tx_observacoes = editFormacao.tx_observacoes;
      this.flNovaFormacao = true;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.formacoes = structuredClone(this.propFormacoes);
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
