import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    candidato: {},
    curriculos: [],
    cvpro: {},
    candidaturas: [],
    cargos: [],
    competencias: [],
    idiomas: [],
    instituicoes: [],
    formacoes: [],
    certificacoes: [],
    vagas: []
  },
  mutations: {
    destroyState(state) {
      state.candidato = {};
      state.curriculos = [];
      state.cvpro = {};
      state.candidaturas = [];
    },
    setCandidato(state, candidato) {
      state.candidato = candidato.candidato;
      if (candidato) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `bearer ${candidato.access_token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },
    setCurriculos(state, curriculos) {
      state.curriculos = curriculos;
    },
    setCvPro(state, cvpro) {
      state.cvpro = cvpro;
    },
    setCandidaturas(state, candidaturas) {
      state.candidaturas = candidaturas;
    },
    atualizaCurriculoPorId(state, payload) {
      const indiceCV = state.curriculos.findIndex(i => i.id_curriculo === payload.id_curriculo);
      state.curriculos[indiceCV] = payload;
    },
    setSysInfos(state, payload) {
      state.cargos = payload.cargos;
      state.competencias = payload.competencias;
      state.idiomas = payload.idiomas;
      state.instituicoes = payload.instituicoes;
      state.formacoes = payload.formacoes;
      state.certificacoes = payload.certificacoes;
    },
    setNovoCvPrincipal(state, payload) {
      state.curriculos[payload.atual].fl_principal = "0";
      state.curriculos[payload.novo].fl_principal = "1";
    },
    setVagas(state, payload) {
      state.vagas = payload;
    },
    addCandidaturaState(state, payload) {
      state.candidaturas.push(payload)
    }
  },
  actions: {
    logout({commit}){
      commit('destroyState');
    },
    defineCandidato({commit}, data) {
      commit('setCandidato', data)
    },
    defineCurriculos({commit}, data) {
      commit('setCurriculos', data)
    },
    defineCvPro({commit}, data) {
      commit('setCvPro', data);
    },
    defineCandidaturas({commit}, data) {
      commit('setCandidaturas', data);
    },
    atualizaCurriculo({commit}, data) {
      commit('atualizaCurriculoPorId', data);
    },
    defineSysInfos({commit}, data) {
      //console.log('action -> ', data)
      commit('setSysInfos', data.data);
    },
    alteraCvPrincipal({commit}, data) {
      commit('setNovoCvPrincipal', data);
    },
    defineVagas({commit}, data) {
      commit('setVagas', data);
    },
    adicionaCandidatura({commit}, data) {
      commit('addCandidaturaState', data)
    }
  }
});
