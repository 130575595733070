export default [
  {
      id_cidade: "1",
      id_estado: "8",
      nm_cidade: "Afonso Cláudio",
      nm_cidade_uf: "Afonso Cláudio - ES"
  },
  {
      id_cidade: "2",
      id_estado: "8",
      nm_cidade: "Água Doce do Norte",
      nm_cidade_uf: "Água Doce do Norte - ES"
  },
  {
      id_cidade: "3",
      id_estado: "8",
      nm_cidade: "Águia Branca",
      nm_cidade_uf: "Águia Branca - ES"
  },
  {
      id_cidade: "4",
      id_estado: "8",
      nm_cidade: "Alegre",
      nm_cidade_uf: "Alegre - ES"
  },
  {
      id_cidade: "5",
      id_estado: "8",
      nm_cidade: "Alfredo Chaves",
      nm_cidade_uf: "Alfredo Chaves - ES"
  },
  {
      id_cidade: "6",
      id_estado: "8",
      nm_cidade: "Alto Rio Novo",
      nm_cidade_uf: "Alto Rio Novo - ES"
  },
  {
      id_cidade: "7",
      id_estado: "8",
      nm_cidade: "Anchieta",
      nm_cidade_uf: "Anchieta - ES"
  },
  {
      id_cidade: "8",
      id_estado: "8",
      nm_cidade: "Apiacá",
      nm_cidade_uf: "Apiacá - ES"
  },
  {
      id_cidade: "9",
      id_estado: "8",
      nm_cidade: "Aracruz",
      nm_cidade_uf: "Aracruz - ES"
  },
  {
      id_cidade: "10",
      id_estado: "8",
      nm_cidade: "Atilio Vivacqua",
      nm_cidade_uf: "Atilio Vivacqua - ES"
  },
  {
      id_cidade: "11",
      id_estado: "8",
      nm_cidade: "Baixo Guandu",
      nm_cidade_uf: "Baixo Guandu - ES"
  },
  {
      id_cidade: "12",
      id_estado: "8",
      nm_cidade: "Barra de São Francisco",
      nm_cidade_uf: "Barra de São Francisco - ES"
  },
  {
      id_cidade: "13",
      id_estado: "8",
      nm_cidade: "Boa Esperança",
      nm_cidade_uf: "Boa Esperança - ES"
  },
  {
      id_cidade: "14",
      id_estado: "8",
      nm_cidade: "Bom Jesus do Norte",
      nm_cidade_uf: "Bom Jesus do Norte - ES"
  },
  {
      id_cidade: "15",
      id_estado: "8",
      nm_cidade: "Brejetuba",
      nm_cidade_uf: "Brejetuba - ES"
  },
  {
      id_cidade: "16",
      id_estado: "8",
      nm_cidade: "Cachoeiro de Itapemirim",
      nm_cidade_uf: "Cachoeiro de Itapemirim - ES"
  },
  {
      id_cidade: "17",
      id_estado: "8",
      nm_cidade: "Cariacica",
      nm_cidade_uf: "Cariacica - ES"
  },
  {
      id_cidade: "18",
      id_estado: "8",
      nm_cidade: "Castelo",
      nm_cidade_uf: "Castelo - ES"
  },
  {
      id_cidade: "19",
      id_estado: "8",
      nm_cidade: "Colatina",
      nm_cidade_uf: "Colatina - ES"
  },
  {
      id_cidade: "20",
      id_estado: "8",
      nm_cidade: "Conceição da Barra",
      nm_cidade_uf: "Conceição da Barra - ES"
  },
  {
      id_cidade: "21",
      id_estado: "8",
      nm_cidade: "Conceição do Castelo",
      nm_cidade_uf: "Conceição do Castelo - ES"
  },
  {
      id_cidade: "22",
      id_estado: "8",
      nm_cidade: "Divino de São Lourenço",
      nm_cidade_uf: "Divino de São Lourenço - ES"
  },
  {
      id_cidade: "23",
      id_estado: "8",
      nm_cidade: "Domingos Martins",
      nm_cidade_uf: "Domingos Martins - ES"
  },
  {
      id_cidade: "24",
      id_estado: "8",
      nm_cidade: "Dores do Rio Preto",
      nm_cidade_uf: "Dores do Rio Preto - ES"
  },
  {
      id_cidade: "25",
      id_estado: "8",
      nm_cidade: "Ecoporanga",
      nm_cidade_uf: "Ecoporanga - ES"
  },
  {
      id_cidade: "26",
      id_estado: "8",
      nm_cidade: "Fundão",
      nm_cidade_uf: "Fundão - ES"
  },
  {
      id_cidade: "27",
      id_estado: "8",
      nm_cidade: "Governador Lindenberg",
      nm_cidade_uf: "Governador Lindenberg - ES"
  },
  {
      id_cidade: "28",
      id_estado: "8",
      nm_cidade: "Guaçuí",
      nm_cidade_uf: "Guaçuí - ES"
  },
  {
      id_cidade: "29",
      id_estado: "8",
      nm_cidade: "Guarapari",
      nm_cidade_uf: "Guarapari - ES"
  },
  {
      id_cidade: "30",
      id_estado: "8",
      nm_cidade: "Ibatiba",
      nm_cidade_uf: "Ibatiba - ES"
  },
  {
      id_cidade: "31",
      id_estado: "8",
      nm_cidade: "Ibiraçu",
      nm_cidade_uf: "Ibiraçu - ES"
  },
  {
      id_cidade: "32",
      id_estado: "8",
      nm_cidade: "Ibitirama",
      nm_cidade_uf: "Ibitirama - ES"
  },
  {
      id_cidade: "33",
      id_estado: "8",
      nm_cidade: "Iconha",
      nm_cidade_uf: "Iconha - ES"
  },
  {
      id_cidade: "34",
      id_estado: "8",
      nm_cidade: "Irupi",
      nm_cidade_uf: "Irupi - ES"
  },
  {
      id_cidade: "35",
      id_estado: "8",
      nm_cidade: "Itaguaçu",
      nm_cidade_uf: "Itaguaçu - ES"
  },
  {
      id_cidade: "36",
      id_estado: "8",
      nm_cidade: "Itapemirim",
      nm_cidade_uf: "Itapemirim - ES"
  },
  {
      id_cidade: "37",
      id_estado: "8",
      nm_cidade: "Itarana",
      nm_cidade_uf: "Itarana - ES"
  },
  {
      id_cidade: "38",
      id_estado: "8",
      nm_cidade: "Iúna",
      nm_cidade_uf: "Iúna - ES"
  },
  {
      id_cidade: "39",
      id_estado: "8",
      nm_cidade: "Jaguaré",
      nm_cidade_uf: "Jaguaré - ES"
  },
  {
      id_cidade: "40",
      id_estado: "8",
      nm_cidade: "Jerônimo Monteiro",
      nm_cidade_uf: "Jerônimo Monteiro - ES"
  },
  {
      id_cidade: "41",
      id_estado: "8",
      nm_cidade: "João Neiva",
      nm_cidade_uf: "João Neiva - ES"
  },
  {
      id_cidade: "42",
      id_estado: "8",
      nm_cidade: "Laranja da Terra",
      nm_cidade_uf: "Laranja da Terra - ES"
  },
  {
      id_cidade: "43",
      id_estado: "8",
      nm_cidade: "Linhares",
      nm_cidade_uf: "Linhares - ES"
  },
  {
      id_cidade: "44",
      id_estado: "8",
      nm_cidade: "Mantenópolis",
      nm_cidade_uf: "Mantenópolis - ES"
  },
  {
      id_cidade: "45",
      id_estado: "8",
      nm_cidade: "Marataízes",
      nm_cidade_uf: "Marataízes - ES"
  },
  {
      id_cidade: "46",
      id_estado: "8",
      nm_cidade: "Marechal Floriano",
      nm_cidade_uf: "Marechal Floriano - ES"
  },
  {
      id_cidade: "47",
      id_estado: "8",
      nm_cidade: "Marilândia",
      nm_cidade_uf: "Marilândia - ES"
  },
  {
      id_cidade: "48",
      id_estado: "8",
      nm_cidade: "Mimoso do Sul",
      nm_cidade_uf: "Mimoso do Sul - ES"
  },
  {
      id_cidade: "49",
      id_estado: "8",
      nm_cidade: "Montanha",
      nm_cidade_uf: "Montanha - ES"
  },
  {
      id_cidade: "50",
      id_estado: "8",
      nm_cidade: "Mucurici",
      nm_cidade_uf: "Mucurici - ES"
  },
  {
      id_cidade: "51",
      id_estado: "8",
      nm_cidade: "Muniz Freire",
      nm_cidade_uf: "Muniz Freire - ES"
  },
  {
      id_cidade: "52",
      id_estado: "8",
      nm_cidade: "Muqui",
      nm_cidade_uf: "Muqui - ES"
  },
  {
      id_cidade: "53",
      id_estado: "8",
      nm_cidade: "Nova Venécia",
      nm_cidade_uf: "Nova Venécia - ES"
  },
  {
      id_cidade: "54",
      id_estado: "8",
      nm_cidade: "Pancas",
      nm_cidade_uf: "Pancas - ES"
  },
  {
      id_cidade: "55",
      id_estado: "8",
      nm_cidade: "Pedro Canário",
      nm_cidade_uf: "Pedro Canário - ES"
  },
  {
      id_cidade: "56",
      id_estado: "8",
      nm_cidade: "Pinheiros",
      nm_cidade_uf: "Pinheiros - ES"
  },
  {
      id_cidade: "57",
      id_estado: "8",
      nm_cidade: "Piúma",
      nm_cidade_uf: "Piúma - ES"
  },
  {
      id_cidade: "58",
      id_estado: "8",
      nm_cidade: "Ponto Belo",
      nm_cidade_uf: "Ponto Belo - ES"
  },
  {
      id_cidade: "59",
      id_estado: "8",
      nm_cidade: "Presidente Kennedy",
      nm_cidade_uf: "Presidente Kennedy - ES"
  },
  {
      id_cidade: "60",
      id_estado: "8",
      nm_cidade: "Rio Bananal",
      nm_cidade_uf: "Rio Bananal - ES"
  },
  {
      id_cidade: "61",
      id_estado: "8",
      nm_cidade: "Rio Novo do Sul",
      nm_cidade_uf: "Rio Novo do Sul - ES"
  },
  {
      id_cidade: "62",
      id_estado: "8",
      nm_cidade: "Santa Leopoldina",
      nm_cidade_uf: "Santa Leopoldina - ES"
  },
  {
      id_cidade: "63",
      id_estado: "8",
      nm_cidade: "Santa Maria de Jetibá",
      nm_cidade_uf: "Santa Maria de Jetibá - ES"
  },
  {
      id_cidade: "64",
      id_estado: "8",
      nm_cidade: "Santa Teresa",
      nm_cidade_uf: "Santa Teresa - ES"
  },
  {
      id_cidade: "65",
      id_estado: "8",
      nm_cidade: "São Domingos do Norte",
      nm_cidade_uf: "São Domingos do Norte - ES"
  },
  {
      id_cidade: "66",
      id_estado: "8",
      nm_cidade: "São Gabriel da Palha",
      nm_cidade_uf: "São Gabriel da Palha - ES"
  },
  {
      id_cidade: "67",
      id_estado: "8",
      nm_cidade: "São José do Calçado",
      nm_cidade_uf: "São José do Calçado - ES"
  },
  {
      id_cidade: "68",
      id_estado: "8",
      nm_cidade: "São Mateus",
      nm_cidade_uf: "São Mateus - ES"
  },
  {
      id_cidade: "69",
      id_estado: "8",
      nm_cidade: "São Roque do Canaã",
      nm_cidade_uf: "São Roque do Canaã - ES"
  },
  {
      id_cidade: "70",
      id_estado: "8",
      nm_cidade: "Serra",
      nm_cidade_uf: "Serra - ES"
  },
  {
      id_cidade: "71",
      id_estado: "8",
      nm_cidade: "Sooretama",
      nm_cidade_uf: "Sooretama - ES"
  },
  {
      id_cidade: "72",
      id_estado: "8",
      nm_cidade: "Vargem Alta",
      nm_cidade_uf: "Vargem Alta - ES"
  },
  {
      id_cidade: "73",
      id_estado: "8",
      nm_cidade: "Venda Nova do Imigrante",
      nm_cidade_uf: "Venda Nova do Imigrante - ES"
  },
  {
      id_cidade: "74",
      id_estado: "8",
      nm_cidade: "Viana",
      nm_cidade_uf: "Viana - ES"
  },
  {
      id_cidade: "75",
      id_estado: "8",
      nm_cidade: "Vila Pavão",
      nm_cidade_uf: "Vila Pavão - ES"
  },
  {
      id_cidade: "76",
      id_estado: "8",
      nm_cidade: "Vila Valério",
      nm_cidade_uf: "Vila Valério - ES"
  },
  {
      id_cidade: "77",
      id_estado: "8",
      nm_cidade: "Vila Velha",
      nm_cidade_uf: "Vila Velha - ES"
  },
  {
      id_cidade: "78",
      id_estado: "8",
      nm_cidade: "Vitória",
      nm_cidade_uf: "Vitória - ES"
  },
  {
      id_cidade: "79",
      id_estado: "1",
      nm_cidade: "Acrelândia",
      nm_cidade_uf: "Acrelândia - AC"
  },
  {
      id_cidade: "80",
      id_estado: "1",
      nm_cidade: "Assis Brasil",
      nm_cidade_uf: "Assis Brasil - AC"
  },
  {
      id_cidade: "81",
      id_estado: "1",
      nm_cidade: "Brasiléia",
      nm_cidade_uf: "Brasiléia - AC"
  },
  {
      id_cidade: "82",
      id_estado: "1",
      nm_cidade: "Bujari",
      nm_cidade_uf: "Bujari - AC"
  },
  {
      id_cidade: "83",
      id_estado: "1",
      nm_cidade: "Capixaba",
      nm_cidade_uf: "Capixaba - AC"
  },
  {
      id_cidade: "84",
      id_estado: "1",
      nm_cidade: "Cruzeiro do Sul",
      nm_cidade_uf: "Cruzeiro do Sul - AC"
  },
  {
      id_cidade: "85",
      id_estado: "1",
      nm_cidade: "Epitaciolândia",
      nm_cidade_uf: "Epitaciolândia - AC"
  },
  {
      id_cidade: "86",
      id_estado: "1",
      nm_cidade: "Feijó",
      nm_cidade_uf: "Feijó - AC"
  },
  {
      id_cidade: "87",
      id_estado: "1",
      nm_cidade: "Jordão",
      nm_cidade_uf: "Jordão - AC"
  },
  {
      id_cidade: "88",
      id_estado: "1",
      nm_cidade: "Mâncio Lima",
      nm_cidade_uf: "Mâncio Lima - AC"
  },
  {
      id_cidade: "89",
      id_estado: "1",
      nm_cidade: "Manoel Urbano",
      nm_cidade_uf: "Manoel Urbano - AC"
  },
  {
      id_cidade: "90",
      id_estado: "1",
      nm_cidade: "Marechal Thaumaturgo",
      nm_cidade_uf: "Marechal Thaumaturgo - AC"
  },
  {
      id_cidade: "91",
      id_estado: "1",
      nm_cidade: "Plácido de Castro",
      nm_cidade_uf: "Plácido de Castro - AC"
  },
  {
      id_cidade: "92",
      id_estado: "1",
      nm_cidade: "Porto Acre",
      nm_cidade_uf: "Porto Acre - AC"
  },
  {
      id_cidade: "93",
      id_estado: "1",
      nm_cidade: "Porto Walter",
      nm_cidade_uf: "Porto Walter - AC"
  },
  {
      id_cidade: "94",
      id_estado: "1",
      nm_cidade: "Rio Branco",
      nm_cidade_uf: "Rio Branco - AC"
  },
  {
      id_cidade: "95",
      id_estado: "1",
      nm_cidade: "Rodrigues Alves",
      nm_cidade_uf: "Rodrigues Alves - AC"
  },
  {
      id_cidade: "96",
      id_estado: "1",
      nm_cidade: "Santa Rosa do Purus",
      nm_cidade_uf: "Santa Rosa do Purus - AC"
  },
  {
      id_cidade: "97",
      id_estado: "1",
      nm_cidade: "Sena Madureira",
      nm_cidade_uf: "Sena Madureira - AC"
  },
  {
      id_cidade: "98",
      id_estado: "1",
      nm_cidade: "Senador Guiomard",
      nm_cidade_uf: "Senador Guiomard - AC"
  },
  {
      id_cidade: "99",
      id_estado: "1",
      nm_cidade: "Tarauacá",
      nm_cidade_uf: "Tarauacá - AC"
  },
  {
      id_cidade: "100",
      id_estado: "1",
      nm_cidade: "Xapuri",
      nm_cidade_uf: "Xapuri - AC"
  },
  {
      id_cidade: "101",
      id_estado: "2",
      nm_cidade: "Água Branca",
      nm_cidade_uf: "Água Branca - AL"
  },
  {
      id_cidade: "102",
      id_estado: "2",
      nm_cidade: "Anadia",
      nm_cidade_uf: "Anadia - AL"
  },
  {
      id_cidade: "103",
      id_estado: "2",
      nm_cidade: "Arapiraca",
      nm_cidade_uf: "Arapiraca - AL"
  },
  {
      id_cidade: "104",
      id_estado: "2",
      nm_cidade: "Atalaia",
      nm_cidade_uf: "Atalaia - AL"
  },
  {
      id_cidade: "105",
      id_estado: "2",
      nm_cidade: "Barra de Santo Antônio",
      nm_cidade_uf: "Barra de Santo Antônio - AL"
  },
  {
      id_cidade: "106",
      id_estado: "2",
      nm_cidade: "Barra de São Miguel",
      nm_cidade_uf: "Barra de São Miguel - AL"
  },
  {
      id_cidade: "107",
      id_estado: "2",
      nm_cidade: "Batalha",
      nm_cidade_uf: "Batalha - AL"
  },
  {
      id_cidade: "108",
      id_estado: "2",
      nm_cidade: "Belém",
      nm_cidade_uf: "Belém - AL"
  },
  {
      id_cidade: "109",
      id_estado: "2",
      nm_cidade: "Belo Monte",
      nm_cidade_uf: "Belo Monte - AL"
  },
  {
      id_cidade: "110",
      id_estado: "2",
      nm_cidade: "Boca da Mata",
      nm_cidade_uf: "Boca da Mata - AL"
  },
  {
      id_cidade: "111",
      id_estado: "2",
      nm_cidade: "Branquinha",
      nm_cidade_uf: "Branquinha - AL"
  },
  {
      id_cidade: "112",
      id_estado: "2",
      nm_cidade: "Cacimbinhas",
      nm_cidade_uf: "Cacimbinhas - AL"
  },
  {
      id_cidade: "113",
      id_estado: "2",
      nm_cidade: "Cajueiro",
      nm_cidade_uf: "Cajueiro - AL"
  },
  {
      id_cidade: "114",
      id_estado: "2",
      nm_cidade: "Campestre",
      nm_cidade_uf: "Campestre - AL"
  },
  {
      id_cidade: "115",
      id_estado: "2",
      nm_cidade: "Campo Alegre",
      nm_cidade_uf: "Campo Alegre - AL"
  },
  {
      id_cidade: "116",
      id_estado: "2",
      nm_cidade: "Campo Grande",
      nm_cidade_uf: "Campo Grande - AL"
  },
  {
      id_cidade: "117",
      id_estado: "2",
      nm_cidade: "Canapi",
      nm_cidade_uf: "Canapi - AL"
  },
  {
      id_cidade: "118",
      id_estado: "2",
      nm_cidade: "Capela",
      nm_cidade_uf: "Capela - AL"
  },
  {
      id_cidade: "119",
      id_estado: "2",
      nm_cidade: "Carneiros",
      nm_cidade_uf: "Carneiros - AL"
  },
  {
      id_cidade: "120",
      id_estado: "2",
      nm_cidade: "Chã Preta",
      nm_cidade_uf: "Chã Preta - AL"
  },
  {
      id_cidade: "121",
      id_estado: "2",
      nm_cidade: "Coité do Nóia",
      nm_cidade_uf: "Coité do Nóia - AL"
  },
  {
      id_cidade: "122",
      id_estado: "2",
      nm_cidade: "Colônia Leopoldina",
      nm_cidade_uf: "Colônia Leopoldina - AL"
  },
  {
      id_cidade: "123",
      id_estado: "2",
      nm_cidade: "Coqueiro Seco",
      nm_cidade_uf: "Coqueiro Seco - AL"
  },
  {
      id_cidade: "124",
      id_estado: "2",
      nm_cidade: "Coruripe",
      nm_cidade_uf: "Coruripe - AL"
  },
  {
      id_cidade: "125",
      id_estado: "2",
      nm_cidade: "Craíbas",
      nm_cidade_uf: "Craíbas - AL"
  },
  {
      id_cidade: "126",
      id_estado: "2",
      nm_cidade: "Delmiro Gouveia",
      nm_cidade_uf: "Delmiro Gouveia - AL"
  },
  {
      id_cidade: "127",
      id_estado: "2",
      nm_cidade: "Dois Riachos",
      nm_cidade_uf: "Dois Riachos - AL"
  },
  {
      id_cidade: "128",
      id_estado: "2",
      nm_cidade: "Estrela de Alagoas",
      nm_cidade_uf: "Estrela de Alagoas - AL"
  },
  {
      id_cidade: "129",
      id_estado: "2",
      nm_cidade: "Feira Grande",
      nm_cidade_uf: "Feira Grande - AL"
  },
  {
      id_cidade: "130",
      id_estado: "2",
      nm_cidade: "Feliz Deserto",
      nm_cidade_uf: "Feliz Deserto - AL"
  },
  {
      id_cidade: "131",
      id_estado: "2",
      nm_cidade: "Flexeiras",
      nm_cidade_uf: "Flexeiras - AL"
  },
  {
      id_cidade: "132",
      id_estado: "2",
      nm_cidade: "Girau do Ponciano",
      nm_cidade_uf: "Girau do Ponciano - AL"
  },
  {
      id_cidade: "133",
      id_estado: "2",
      nm_cidade: "Ibateguara",
      nm_cidade_uf: "Ibateguara - AL"
  },
  {
      id_cidade: "134",
      id_estado: "2",
      nm_cidade: "Igaci",
      nm_cidade_uf: "Igaci - AL"
  },
  {
      id_cidade: "135",
      id_estado: "2",
      nm_cidade: "Igreja Nova",
      nm_cidade_uf: "Igreja Nova - AL"
  },
  {
      id_cidade: "136",
      id_estado: "2",
      nm_cidade: "Inhapi",
      nm_cidade_uf: "Inhapi - AL"
  },
  {
      id_cidade: "137",
      id_estado: "2",
      nm_cidade: "Jacaré dos Homens",
      nm_cidade_uf: "Jacaré dos Homens - AL"
  },
  {
      id_cidade: "138",
      id_estado: "2",
      nm_cidade: "Jacuípe",
      nm_cidade_uf: "Jacuípe - AL"
  },
  {
      id_cidade: "139",
      id_estado: "2",
      nm_cidade: "Japaratinga",
      nm_cidade_uf: "Japaratinga - AL"
  },
  {
      id_cidade: "140",
      id_estado: "2",
      nm_cidade: "Jaramataia",
      nm_cidade_uf: "Jaramataia - AL"
  },
  {
      id_cidade: "141",
      id_estado: "2",
      nm_cidade: "Jequiá da Praia",
      nm_cidade_uf: "Jequiá da Praia - AL"
  },
  {
      id_cidade: "142",
      id_estado: "2",
      nm_cidade: "Joaquim Gomes",
      nm_cidade_uf: "Joaquim Gomes - AL"
  },
  {
      id_cidade: "143",
      id_estado: "2",
      nm_cidade: "Jundiá",
      nm_cidade_uf: "Jundiá - AL"
  },
  {
      id_cidade: "144",
      id_estado: "2",
      nm_cidade: "Junqueiro",
      nm_cidade_uf: "Junqueiro - AL"
  },
  {
      id_cidade: "145",
      id_estado: "2",
      nm_cidade: "Lagoa da Canoa",
      nm_cidade_uf: "Lagoa da Canoa - AL"
  },
  {
      id_cidade: "146",
      id_estado: "2",
      nm_cidade: "Limoeiro de Anadia",
      nm_cidade_uf: "Limoeiro de Anadia - AL"
  },
  {
      id_cidade: "147",
      id_estado: "2",
      nm_cidade: "Maceió",
      nm_cidade_uf: "Maceió - AL"
  },
  {
      id_cidade: "148",
      id_estado: "2",
      nm_cidade: "Major Isidoro",
      nm_cidade_uf: "Major Isidoro - AL"
  },
  {
      id_cidade: "149",
      id_estado: "2",
      nm_cidade: "Mar Vermelho",
      nm_cidade_uf: "Mar Vermelho - AL"
  },
  {
      id_cidade: "150",
      id_estado: "2",
      nm_cidade: "Maragogi",
      nm_cidade_uf: "Maragogi - AL"
  },
  {
      id_cidade: "151",
      id_estado: "2",
      nm_cidade: "Maravilha",
      nm_cidade_uf: "Maravilha - AL"
  },
  {
      id_cidade: "152",
      id_estado: "2",
      nm_cidade: "Marechal Deodoro",
      nm_cidade_uf: "Marechal Deodoro - AL"
  },
  {
      id_cidade: "153",
      id_estado: "2",
      nm_cidade: "Maribondo",
      nm_cidade_uf: "Maribondo - AL"
  },
  {
      id_cidade: "154",
      id_estado: "2",
      nm_cidade: "Mata Grande",
      nm_cidade_uf: "Mata Grande - AL"
  },
  {
      id_cidade: "155",
      id_estado: "2",
      nm_cidade: "Matriz de Camaragibe",
      nm_cidade_uf: "Matriz de Camaragibe - AL"
  },
  {
      id_cidade: "156",
      id_estado: "2",
      nm_cidade: "Messias",
      nm_cidade_uf: "Messias - AL"
  },
  {
      id_cidade: "157",
      id_estado: "2",
      nm_cidade: "Minador do Negrão",
      nm_cidade_uf: "Minador do Negrão - AL"
  },
  {
      id_cidade: "158",
      id_estado: "2",
      nm_cidade: "Monteirópolis",
      nm_cidade_uf: "Monteirópolis - AL"
  },
  {
      id_cidade: "159",
      id_estado: "2",
      nm_cidade: "Murici",
      nm_cidade_uf: "Murici - AL"
  },
  {
      id_cidade: "160",
      id_estado: "2",
      nm_cidade: "Novo Lino",
      nm_cidade_uf: "Novo Lino - AL"
  },
  {
      id_cidade: "161",
      id_estado: "2",
      nm_cidade: "Olho d`Água das Flores",
      nm_cidade_uf: "Olho d`Água das Flores - AL"
  },
  {
      id_cidade: "162",
      id_estado: "2",
      nm_cidade: "Olho d`Água do Casado",
      nm_cidade_uf: "Olho d`Água do Casado - AL"
  },
  {
      id_cidade: "163",
      id_estado: "2",
      nm_cidade: "Olho d`Água Grande",
      nm_cidade_uf: "Olho d`Água Grande - AL"
  },
  {
      id_cidade: "164",
      id_estado: "2",
      nm_cidade: "Olivença",
      nm_cidade_uf: "Olivença - AL"
  },
  {
      id_cidade: "165",
      id_estado: "2",
      nm_cidade: "Ouro Branco",
      nm_cidade_uf: "Ouro Branco - AL"
  },
  {
      id_cidade: "166",
      id_estado: "2",
      nm_cidade: "Palestina",
      nm_cidade_uf: "Palestina - AL"
  },
  {
      id_cidade: "167",
      id_estado: "2",
      nm_cidade: "Palmeira dos Índios",
      nm_cidade_uf: "Palmeira dos Índios - AL"
  },
  {
      id_cidade: "168",
      id_estado: "2",
      nm_cidade: "Pão de Açúcar",
      nm_cidade_uf: "Pão de Açúcar - AL"
  },
  {
      id_cidade: "169",
      id_estado: "2",
      nm_cidade: "Pariconha",
      nm_cidade_uf: "Pariconha - AL"
  },
  {
      id_cidade: "170",
      id_estado: "2",
      nm_cidade: "Paripueira",
      nm_cidade_uf: "Paripueira - AL"
  },
  {
      id_cidade: "171",
      id_estado: "2",
      nm_cidade: "Passo de Camaragibe",
      nm_cidade_uf: "Passo de Camaragibe - AL"
  },
  {
      id_cidade: "172",
      id_estado: "2",
      nm_cidade: "Paulo Jacinto",
      nm_cidade_uf: "Paulo Jacinto - AL"
  },
  {
      id_cidade: "173",
      id_estado: "2",
      nm_cidade: "Penedo",
      nm_cidade_uf: "Penedo - AL"
  },
  {
      id_cidade: "174",
      id_estado: "2",
      nm_cidade: "Piaçabuçu",
      nm_cidade_uf: "Piaçabuçu - AL"
  },
  {
      id_cidade: "175",
      id_estado: "2",
      nm_cidade: "Pilar",
      nm_cidade_uf: "Pilar - AL"
  },
  {
      id_cidade: "176",
      id_estado: "2",
      nm_cidade: "Pindoba",
      nm_cidade_uf: "Pindoba - AL"
  },
  {
      id_cidade: "177",
      id_estado: "2",
      nm_cidade: "Piranhas",
      nm_cidade_uf: "Piranhas - AL"
  },
  {
      id_cidade: "178",
      id_estado: "2",
      nm_cidade: "Poço das Trincheiras",
      nm_cidade_uf: "Poço das Trincheiras - AL"
  },
  {
      id_cidade: "179",
      id_estado: "2",
      nm_cidade: "Porto Calvo",
      nm_cidade_uf: "Porto Calvo - AL"
  },
  {
      id_cidade: "180",
      id_estado: "2",
      nm_cidade: "Porto de Pedras",
      nm_cidade_uf: "Porto de Pedras - AL"
  },
  {
      id_cidade: "181",
      id_estado: "2",
      nm_cidade: "Porto Real do Colégio",
      nm_cidade_uf: "Porto Real do Colégio - AL"
  },
  {
      id_cidade: "182",
      id_estado: "2",
      nm_cidade: "Quebrangulo",
      nm_cidade_uf: "Quebrangulo - AL"
  },
  {
      id_cidade: "183",
      id_estado: "2",
      nm_cidade: "Rio Largo",
      nm_cidade_uf: "Rio Largo - AL"
  },
  {
      id_cidade: "184",
      id_estado: "2",
      nm_cidade: "Roteiro",
      nm_cidade_uf: "Roteiro - AL"
  },
  {
      id_cidade: "185",
      id_estado: "2",
      nm_cidade: "Santa Luzia do Norte",
      nm_cidade_uf: "Santa Luzia do Norte - AL"
  },
  {
      id_cidade: "186",
      id_estado: "2",
      nm_cidade: "Santana do Ipanema",
      nm_cidade_uf: "Santana do Ipanema - AL"
  },
  {
      id_cidade: "187",
      id_estado: "2",
      nm_cidade: "Santana do Mundaú",
      nm_cidade_uf: "Santana do Mundaú - AL"
  },
  {
      id_cidade: "188",
      id_estado: "2",
      nm_cidade: "São Brás",
      nm_cidade_uf: "São Brás - AL"
  },
  {
      id_cidade: "189",
      id_estado: "2",
      nm_cidade: "São José da Laje",
      nm_cidade_uf: "São José da Laje - AL"
  },
  {
      id_cidade: "190",
      id_estado: "2",
      nm_cidade: "São José da Tapera",
      nm_cidade_uf: "São José da Tapera - AL"
  },
  {
      id_cidade: "191",
      id_estado: "2",
      nm_cidade: "São Luís do Quitunde",
      nm_cidade_uf: "São Luís do Quitunde - AL"
  },
  {
      id_cidade: "192",
      id_estado: "2",
      nm_cidade: "São Miguel dos Campos",
      nm_cidade_uf: "São Miguel dos Campos - AL"
  },
  {
      id_cidade: "193",
      id_estado: "2",
      nm_cidade: "São Miguel dos Milagres",
      nm_cidade_uf: "São Miguel dos Milagres - AL"
  },
  {
      id_cidade: "194",
      id_estado: "2",
      nm_cidade: "São Sebastião",
      nm_cidade_uf: "São Sebastião - AL"
  },
  {
      id_cidade: "195",
      id_estado: "2",
      nm_cidade: "Satuba",
      nm_cidade_uf: "Satuba - AL"
  },
  {
      id_cidade: "196",
      id_estado: "2",
      nm_cidade: "Senador Rui Palmeira",
      nm_cidade_uf: "Senador Rui Palmeira - AL"
  },
  {
      id_cidade: "197",
      id_estado: "2",
      nm_cidade: "Tanque d`Arca",
      nm_cidade_uf: "Tanque d`Arca - AL"
  },
  {
      id_cidade: "198",
      id_estado: "2",
      nm_cidade: "Taquarana",
      nm_cidade_uf: "Taquarana - AL"
  },
  {
      id_cidade: "199",
      id_estado: "2",
      nm_cidade: "Teotônio Vilela",
      nm_cidade_uf: "Teotônio Vilela - AL"
  },
  {
      id_cidade: "200",
      id_estado: "2",
      nm_cidade: "Traipu",
      nm_cidade_uf: "Traipu - AL"
  },
  {
      id_cidade: "201",
      id_estado: "2",
      nm_cidade: "União dos Palmares",
      nm_cidade_uf: "União dos Palmares - AL"
  },
  {
      id_cidade: "202",
      id_estado: "2",
      nm_cidade: "Viçosa",
      nm_cidade_uf: "Viçosa - AL"
  },
  {
      id_cidade: "203",
      id_estado: "4",
      nm_cidade: "Amapá",
      nm_cidade_uf: "Amapá - AP"
  },
  {
      id_cidade: "204",
      id_estado: "4",
      nm_cidade: "Calçoene",
      nm_cidade_uf: "Calçoene - AP"
  },
  {
      id_cidade: "205",
      id_estado: "4",
      nm_cidade: "Cutias",
      nm_cidade_uf: "Cutias - AP"
  },
  {
      id_cidade: "206",
      id_estado: "4",
      nm_cidade: "Ferreira Gomes",
      nm_cidade_uf: "Ferreira Gomes - AP"
  },
  {
      id_cidade: "207",
      id_estado: "4",
      nm_cidade: "Itaubal",
      nm_cidade_uf: "Itaubal - AP"
  },
  {
      id_cidade: "208",
      id_estado: "4",
      nm_cidade: "Laranjal do Jari",
      nm_cidade_uf: "Laranjal do Jari - AP"
  },
  {
      id_cidade: "209",
      id_estado: "4",
      nm_cidade: "Macapá",
      nm_cidade_uf: "Macapá - AP"
  },
  {
      id_cidade: "210",
      id_estado: "4",
      nm_cidade: "Mazagão",
      nm_cidade_uf: "Mazagão - AP"
  },
  {
      id_cidade: "211",
      id_estado: "4",
      nm_cidade: "Oiapoque",
      nm_cidade_uf: "Oiapoque - AP"
  },
  {
      id_cidade: "212",
      id_estado: "4",
      nm_cidade: "Pedra Branca do Amaparí",
      nm_cidade_uf: "Pedra Branca do Amaparí - AP"
  },
  {
      id_cidade: "213",
      id_estado: "4",
      nm_cidade: "Porto Grande",
      nm_cidade_uf: "Porto Grande - AP"
  },
  {
      id_cidade: "214",
      id_estado: "4",
      nm_cidade: "Pracuúba",
      nm_cidade_uf: "Pracuúba - AP"
  },
  {
      id_cidade: "215",
      id_estado: "4",
      nm_cidade: "Santana",
      nm_cidade_uf: "Santana - AP"
  },
  {
      id_cidade: "216",
      id_estado: "4",
      nm_cidade: "Serra do Navio",
      nm_cidade_uf: "Serra do Navio - AP"
  },
  {
      id_cidade: "217",
      id_estado: "4",
      nm_cidade: "Tartarugalzinho",
      nm_cidade_uf: "Tartarugalzinho - AP"
  },
  {
      id_cidade: "218",
      id_estado: "4",
      nm_cidade: "Vitória do Jari",
      nm_cidade_uf: "Vitória do Jari - AP"
  },
  {
      id_cidade: "219",
      id_estado: "3",
      nm_cidade: "Alvarães",
      nm_cidade_uf: "Alvarães - AM"
  },
  {
      id_cidade: "220",
      id_estado: "3",
      nm_cidade: "Amaturá",
      nm_cidade_uf: "Amaturá - AM"
  },
  {
      id_cidade: "221",
      id_estado: "3",
      nm_cidade: "Anamã",
      nm_cidade_uf: "Anamã - AM"
  },
  {
      id_cidade: "222",
      id_estado: "3",
      nm_cidade: "Anori",
      nm_cidade_uf: "Anori - AM"
  },
  {
      id_cidade: "223",
      id_estado: "3",
      nm_cidade: "Apuí",
      nm_cidade_uf: "Apuí - AM"
  },
  {
      id_cidade: "224",
      id_estado: "3",
      nm_cidade: "Atalaia do Norte",
      nm_cidade_uf: "Atalaia do Norte - AM"
  },
  {
      id_cidade: "225",
      id_estado: "3",
      nm_cidade: "Autazes",
      nm_cidade_uf: "Autazes - AM"
  },
  {
      id_cidade: "226",
      id_estado: "3",
      nm_cidade: "Barcelos",
      nm_cidade_uf: "Barcelos - AM"
  },
  {
      id_cidade: "227",
      id_estado: "3",
      nm_cidade: "Barreirinha",
      nm_cidade_uf: "Barreirinha - AM"
  },
  {
      id_cidade: "228",
      id_estado: "3",
      nm_cidade: "Benjamin Constant",
      nm_cidade_uf: "Benjamin Constant - AM"
  },
  {
      id_cidade: "229",
      id_estado: "3",
      nm_cidade: "Beruri",
      nm_cidade_uf: "Beruri - AM"
  },
  {
      id_cidade: "230",
      id_estado: "3",
      nm_cidade: "Boa Vista do Ramos",
      nm_cidade_uf: "Boa Vista do Ramos - AM"
  },
  {
      id_cidade: "231",
      id_estado: "3",
      nm_cidade: "Boca do Acre",
      nm_cidade_uf: "Boca do Acre - AM"
  },
  {
      id_cidade: "232",
      id_estado: "3",
      nm_cidade: "Borba",
      nm_cidade_uf: "Borba - AM"
  },
  {
      id_cidade: "233",
      id_estado: "3",
      nm_cidade: "Caapiranga",
      nm_cidade_uf: "Caapiranga - AM"
  },
  {
      id_cidade: "234",
      id_estado: "3",
      nm_cidade: "Canutama",
      nm_cidade_uf: "Canutama - AM"
  },
  {
      id_cidade: "235",
      id_estado: "3",
      nm_cidade: "Carauari",
      nm_cidade_uf: "Carauari - AM"
  },
  {
      id_cidade: "236",
      id_estado: "3",
      nm_cidade: "Careiro",
      nm_cidade_uf: "Careiro - AM"
  },
  {
      id_cidade: "237",
      id_estado: "3",
      nm_cidade: "Careiro da Várzea",
      nm_cidade_uf: "Careiro da Várzea - AM"
  },
  {
      id_cidade: "238",
      id_estado: "3",
      nm_cidade: "Coari",
      nm_cidade_uf: "Coari - AM"
  },
  {
      id_cidade: "239",
      id_estado: "3",
      nm_cidade: "Codajás",
      nm_cidade_uf: "Codajás - AM"
  },
  {
      id_cidade: "240",
      id_estado: "3",
      nm_cidade: "Eirunepé",
      nm_cidade_uf: "Eirunepé - AM"
  },
  {
      id_cidade: "241",
      id_estado: "3",
      nm_cidade: "Envira",
      nm_cidade_uf: "Envira - AM"
  },
  {
      id_cidade: "242",
      id_estado: "3",
      nm_cidade: "Fonte Boa",
      nm_cidade_uf: "Fonte Boa - AM"
  },
  {
      id_cidade: "243",
      id_estado: "3",
      nm_cidade: "Guajará",
      nm_cidade_uf: "Guajará - AM"
  },
  {
      id_cidade: "244",
      id_estado: "3",
      nm_cidade: "Humaitá",
      nm_cidade_uf: "Humaitá - AM"
  },
  {
      id_cidade: "245",
      id_estado: "3",
      nm_cidade: "Ipixuna",
      nm_cidade_uf: "Ipixuna - AM"
  },
  {
      id_cidade: "246",
      id_estado: "3",
      nm_cidade: "Iranduba",
      nm_cidade_uf: "Iranduba - AM"
  },
  {
      id_cidade: "247",
      id_estado: "3",
      nm_cidade: "Itacoatiara",
      nm_cidade_uf: "Itacoatiara - AM"
  },
  {
      id_cidade: "248",
      id_estado: "3",
      nm_cidade: "Itamarati",
      nm_cidade_uf: "Itamarati - AM"
  },
  {
      id_cidade: "249",
      id_estado: "3",
      nm_cidade: "Itapiranga",
      nm_cidade_uf: "Itapiranga - AM"
  },
  {
      id_cidade: "250",
      id_estado: "3",
      nm_cidade: "Japurá",
      nm_cidade_uf: "Japurá - AM"
  },
  {
      id_cidade: "251",
      id_estado: "3",
      nm_cidade: "Juruá",
      nm_cidade_uf: "Juruá - AM"
  },
  {
      id_cidade: "252",
      id_estado: "3",
      nm_cidade: "Jutaí",
      nm_cidade_uf: "Jutaí - AM"
  },
  {
      id_cidade: "253",
      id_estado: "3",
      nm_cidade: "Lábrea",
      nm_cidade_uf: "Lábrea - AM"
  },
  {
      id_cidade: "254",
      id_estado: "3",
      nm_cidade: "Manacapuru",
      nm_cidade_uf: "Manacapuru - AM"
  },
  {
      id_cidade: "255",
      id_estado: "3",
      nm_cidade: "Manaquiri",
      nm_cidade_uf: "Manaquiri - AM"
  },
  {
      id_cidade: "256",
      id_estado: "3",
      nm_cidade: "Manaus",
      nm_cidade_uf: "Manaus - AM"
  },
  {
      id_cidade: "257",
      id_estado: "3",
      nm_cidade: "Manicoré",
      nm_cidade_uf: "Manicoré - AM"
  },
  {
      id_cidade: "258",
      id_estado: "3",
      nm_cidade: "Maraã",
      nm_cidade_uf: "Maraã - AM"
  },
  {
      id_cidade: "259",
      id_estado: "3",
      nm_cidade: "Maués",
      nm_cidade_uf: "Maués - AM"
  },
  {
      id_cidade: "260",
      id_estado: "3",
      nm_cidade: "Nhamundá",
      nm_cidade_uf: "Nhamundá - AM"
  },
  {
      id_cidade: "261",
      id_estado: "3",
      nm_cidade: "Nova Olinda do Norte",
      nm_cidade_uf: "Nova Olinda do Norte - AM"
  },
  {
      id_cidade: "262",
      id_estado: "3",
      nm_cidade: "Novo Airão",
      nm_cidade_uf: "Novo Airão - AM"
  },
  {
      id_cidade: "263",
      id_estado: "3",
      nm_cidade: "Novo Aripuanã",
      nm_cidade_uf: "Novo Aripuanã - AM"
  },
  {
      id_cidade: "264",
      id_estado: "3",
      nm_cidade: "Parintins",
      nm_cidade_uf: "Parintins - AM"
  },
  {
      id_cidade: "265",
      id_estado: "3",
      nm_cidade: "Pauini",
      nm_cidade_uf: "Pauini - AM"
  },
  {
      id_cidade: "266",
      id_estado: "3",
      nm_cidade: "Presidente Figueiredo",
      nm_cidade_uf: "Presidente Figueiredo - AM"
  },
  {
      id_cidade: "267",
      id_estado: "3",
      nm_cidade: "Rio Preto da Eva",
      nm_cidade_uf: "Rio Preto da Eva - AM"
  },
  {
      id_cidade: "268",
      id_estado: "3",
      nm_cidade: "Santa Isabel do Rio Negro",
      nm_cidade_uf: "Santa Isabel do Rio Negro - AM"
  },
  {
      id_cidade: "269",
      id_estado: "3",
      nm_cidade: "Santo Antônio do Içá",
      nm_cidade_uf: "Santo Antônio do Içá - AM"
  },
  {
      id_cidade: "270",
      id_estado: "3",
      nm_cidade: "São Gabriel da Cachoeira",
      nm_cidade_uf: "São Gabriel da Cachoeira - AM"
  },
  {
      id_cidade: "271",
      id_estado: "3",
      nm_cidade: "São Paulo de Olivença",
      nm_cidade_uf: "São Paulo de Olivença - AM"
  },
  {
      id_cidade: "272",
      id_estado: "3",
      nm_cidade: "São Sebastião do Uatumã",
      nm_cidade_uf: "São Sebastião do Uatumã - AM"
  },
  {
      id_cidade: "273",
      id_estado: "3",
      nm_cidade: "Silves",
      nm_cidade_uf: "Silves - AM"
  },
  {
      id_cidade: "274",
      id_estado: "3",
      nm_cidade: "Tabatinga",
      nm_cidade_uf: "Tabatinga - AM"
  },
  {
      id_cidade: "275",
      id_estado: "3",
      nm_cidade: "Tapauá",
      nm_cidade_uf: "Tapauá - AM"
  },
  {
      id_cidade: "276",
      id_estado: "3",
      nm_cidade: "Tefé",
      nm_cidade_uf: "Tefé - AM"
  },
  {
      id_cidade: "277",
      id_estado: "3",
      nm_cidade: "Tonantins",
      nm_cidade_uf: "Tonantins - AM"
  },
  {
      id_cidade: "278",
      id_estado: "3",
      nm_cidade: "Uarini",
      nm_cidade_uf: "Uarini - AM"
  },
  {
      id_cidade: "279",
      id_estado: "3",
      nm_cidade: "Urucará",
      nm_cidade_uf: "Urucará - AM"
  },
  {
      id_cidade: "280",
      id_estado: "3",
      nm_cidade: "Urucurituba",
      nm_cidade_uf: "Urucurituba - AM"
  },
  {
      id_cidade: "281",
      id_estado: "5",
      nm_cidade: "Abaíra",
      nm_cidade_uf: "Abaíra - BA"
  },
  {
      id_cidade: "282",
      id_estado: "5",
      nm_cidade: "Abaré",
      nm_cidade_uf: "Abaré - BA"
  },
  {
      id_cidade: "283",
      id_estado: "5",
      nm_cidade: "Acajutiba",
      nm_cidade_uf: "Acajutiba - BA"
  },
  {
      id_cidade: "284",
      id_estado: "5",
      nm_cidade: "Adustina",
      nm_cidade_uf: "Adustina - BA"
  },
  {
      id_cidade: "285",
      id_estado: "5",
      nm_cidade: "Água Fria",
      nm_cidade_uf: "Água Fria - BA"
  },
  {
      id_cidade: "286",
      id_estado: "5",
      nm_cidade: "Aiquara",
      nm_cidade_uf: "Aiquara - BA"
  },
  {
      id_cidade: "287",
      id_estado: "5",
      nm_cidade: "Alagoinhas",
      nm_cidade_uf: "Alagoinhas - BA"
  },
  {
      id_cidade: "288",
      id_estado: "5",
      nm_cidade: "Alcobaça",
      nm_cidade_uf: "Alcobaça - BA"
  },
  {
      id_cidade: "289",
      id_estado: "5",
      nm_cidade: "Almadina",
      nm_cidade_uf: "Almadina - BA"
  },
  {
      id_cidade: "290",
      id_estado: "5",
      nm_cidade: "Amargosa",
      nm_cidade_uf: "Amargosa - BA"
  },
  {
      id_cidade: "291",
      id_estado: "5",
      nm_cidade: "Amélia Rodrigues",
      nm_cidade_uf: "Amélia Rodrigues - BA"
  },
  {
      id_cidade: "292",
      id_estado: "5",
      nm_cidade: "América Dourada",
      nm_cidade_uf: "América Dourada - BA"
  },
  {
      id_cidade: "293",
      id_estado: "5",
      nm_cidade: "Anagé",
      nm_cidade_uf: "Anagé - BA"
  },
  {
      id_cidade: "294",
      id_estado: "5",
      nm_cidade: "Andaraí",
      nm_cidade_uf: "Andaraí - BA"
  },
  {
      id_cidade: "295",
      id_estado: "5",
      nm_cidade: "Andorinha",
      nm_cidade_uf: "Andorinha - BA"
  },
  {
      id_cidade: "296",
      id_estado: "5",
      nm_cidade: "Angical",
      nm_cidade_uf: "Angical - BA"
  },
  {
      id_cidade: "297",
      id_estado: "5",
      nm_cidade: "Anguera",
      nm_cidade_uf: "Anguera - BA"
  },
  {
      id_cidade: "298",
      id_estado: "5",
      nm_cidade: "Antas",
      nm_cidade_uf: "Antas - BA"
  },
  {
      id_cidade: "299",
      id_estado: "5",
      nm_cidade: "Antônio Cardoso",
      nm_cidade_uf: "Antônio Cardoso - BA"
  },
  {
      id_cidade: "300",
      id_estado: "5",
      nm_cidade: "Antônio Gonçalves",
      nm_cidade_uf: "Antônio Gonçalves - BA"
  },
  {
      id_cidade: "301",
      id_estado: "5",
      nm_cidade: "Aporá",
      nm_cidade_uf: "Aporá - BA"
  },
  {
      id_cidade: "302",
      id_estado: "5",
      nm_cidade: "Apuarema",
      nm_cidade_uf: "Apuarema - BA"
  },
  {
      id_cidade: "303",
      id_estado: "5",
      nm_cidade: "Araças",
      nm_cidade_uf: "Araças - BA"
  },
  {
      id_cidade: "304",
      id_estado: "5",
      nm_cidade: "Aracatu",
      nm_cidade_uf: "Aracatu - BA"
  },
  {
      id_cidade: "305",
      id_estado: "5",
      nm_cidade: "Araci",
      nm_cidade_uf: "Araci - BA"
  },
  {
      id_cidade: "306",
      id_estado: "5",
      nm_cidade: "Aramari",
      nm_cidade_uf: "Aramari - BA"
  },
  {
      id_cidade: "307",
      id_estado: "5",
      nm_cidade: "Arataca",
      nm_cidade_uf: "Arataca - BA"
  },
  {
      id_cidade: "308",
      id_estado: "5",
      nm_cidade: "Aratuípe",
      nm_cidade_uf: "Aratuípe - BA"
  },
  {
      id_cidade: "309",
      id_estado: "5",
      nm_cidade: "Aurelino Leal",
      nm_cidade_uf: "Aurelino Leal - BA"
  },
  {
      id_cidade: "310",
      id_estado: "5",
      nm_cidade: "Baianópolis",
      nm_cidade_uf: "Baianópolis - BA"
  },
  {
      id_cidade: "311",
      id_estado: "5",
      nm_cidade: "Baixa Grande",
      nm_cidade_uf: "Baixa Grande - BA"
  },
  {
      id_cidade: "312",
      id_estado: "5",
      nm_cidade: "Banzaê",
      nm_cidade_uf: "Banzaê - BA"
  },
  {
      id_cidade: "313",
      id_estado: "5",
      nm_cidade: "Barra",
      nm_cidade_uf: "Barra - BA"
  },
  {
      id_cidade: "314",
      id_estado: "5",
      nm_cidade: "Barra da Estiva",
      nm_cidade_uf: "Barra da Estiva - BA"
  },
  {
      id_cidade: "315",
      id_estado: "5",
      nm_cidade: "Barra do Choça",
      nm_cidade_uf: "Barra do Choça - BA"
  },
  {
      id_cidade: "316",
      id_estado: "5",
      nm_cidade: "Barra do Mendes",
      nm_cidade_uf: "Barra do Mendes - BA"
  },
  {
      id_cidade: "317",
      id_estado: "5",
      nm_cidade: "Barra do Rocha",
      nm_cidade_uf: "Barra do Rocha - BA"
  },
  {
      id_cidade: "318",
      id_estado: "5",
      nm_cidade: "Barreiras",
      nm_cidade_uf: "Barreiras - BA"
  },
  {
      id_cidade: "319",
      id_estado: "5",
      nm_cidade: "Barro Alto",
      nm_cidade_uf: "Barro Alto - BA"
  },
  {
      id_cidade: "320",
      id_estado: "5",
      nm_cidade: "Barro Preto",
      nm_cidade_uf: "Barro Preto - BA"
  },
  {
      id_cidade: "321",
      id_estado: "5",
      nm_cidade: "Barrocas",
      nm_cidade_uf: "Barrocas - BA"
  },
  {
      id_cidade: "322",
      id_estado: "5",
      nm_cidade: "Belmonte",
      nm_cidade_uf: "Belmonte - BA"
  },
  {
      id_cidade: "323",
      id_estado: "5",
      nm_cidade: "Belo Campo",
      nm_cidade_uf: "Belo Campo - BA"
  },
  {
      id_cidade: "324",
      id_estado: "5",
      nm_cidade: "Biritinga",
      nm_cidade_uf: "Biritinga - BA"
  },
  {
      id_cidade: "325",
      id_estado: "5",
      nm_cidade: "Boa Nova",
      nm_cidade_uf: "Boa Nova - BA"
  },
  {
      id_cidade: "326",
      id_estado: "5",
      nm_cidade: "Boa Vista do Tupim",
      nm_cidade_uf: "Boa Vista do Tupim - BA"
  },
  {
      id_cidade: "327",
      id_estado: "5",
      nm_cidade: "Bom Jesus da Lapa",
      nm_cidade_uf: "Bom Jesus da Lapa - BA"
  },
  {
      id_cidade: "328",
      id_estado: "5",
      nm_cidade: "Bom Jesus da Serra",
      nm_cidade_uf: "Bom Jesus da Serra - BA"
  },
  {
      id_cidade: "329",
      id_estado: "5",
      nm_cidade: "Boninal",
      nm_cidade_uf: "Boninal - BA"
  },
  {
      id_cidade: "330",
      id_estado: "5",
      nm_cidade: "Bonito",
      nm_cidade_uf: "Bonito - BA"
  },
  {
      id_cidade: "331",
      id_estado: "5",
      nm_cidade: "Boquira",
      nm_cidade_uf: "Boquira - BA"
  },
  {
      id_cidade: "332",
      id_estado: "5",
      nm_cidade: "Botuporã",
      nm_cidade_uf: "Botuporã - BA"
  },
  {
      id_cidade: "333",
      id_estado: "5",
      nm_cidade: "Brejões",
      nm_cidade_uf: "Brejões - BA"
  },
  {
      id_cidade: "334",
      id_estado: "5",
      nm_cidade: "Brejolândia",
      nm_cidade_uf: "Brejolândia - BA"
  },
  {
      id_cidade: "335",
      id_estado: "5",
      nm_cidade: "Brotas de Macaúbas",
      nm_cidade_uf: "Brotas de Macaúbas - BA"
  },
  {
      id_cidade: "336",
      id_estado: "5",
      nm_cidade: "Brumado",
      nm_cidade_uf: "Brumado - BA"
  },
  {
      id_cidade: "337",
      id_estado: "5",
      nm_cidade: "Buerarema",
      nm_cidade_uf: "Buerarema - BA"
  },
  {
      id_cidade: "338",
      id_estado: "5",
      nm_cidade: "Buritirama",
      nm_cidade_uf: "Buritirama - BA"
  },
  {
      id_cidade: "339",
      id_estado: "5",
      nm_cidade: "Caatiba",
      nm_cidade_uf: "Caatiba - BA"
  },
  {
      id_cidade: "340",
      id_estado: "5",
      nm_cidade: "Cabaceiras do Paraguaçu",
      nm_cidade_uf: "Cabaceiras do Paraguaçu - BA"
  },
  {
      id_cidade: "341",
      id_estado: "5",
      nm_cidade: "Cachoeira",
      nm_cidade_uf: "Cachoeira - BA"
  },
  {
      id_cidade: "342",
      id_estado: "5",
      nm_cidade: "Caculé",
      nm_cidade_uf: "Caculé - BA"
  },
  {
      id_cidade: "343",
      id_estado: "5",
      nm_cidade: "Caém",
      nm_cidade_uf: "Caém - BA"
  },
  {
      id_cidade: "344",
      id_estado: "5",
      nm_cidade: "Caetanos",
      nm_cidade_uf: "Caetanos - BA"
  },
  {
      id_cidade: "345",
      id_estado: "5",
      nm_cidade: "Caetité",
      nm_cidade_uf: "Caetité - BA"
  },
  {
      id_cidade: "346",
      id_estado: "5",
      nm_cidade: "Cafarnaum",
      nm_cidade_uf: "Cafarnaum - BA"
  },
  {
      id_cidade: "347",
      id_estado: "5",
      nm_cidade: "Cairu",
      nm_cidade_uf: "Cairu - BA"
  },
  {
      id_cidade: "348",
      id_estado: "5",
      nm_cidade: "Caldeirão Grande",
      nm_cidade_uf: "Caldeirão Grande - BA"
  },
  {
      id_cidade: "349",
      id_estado: "5",
      nm_cidade: "Camacan",
      nm_cidade_uf: "Camacan - BA"
  },
  {
      id_cidade: "350",
      id_estado: "5",
      nm_cidade: "Camaçari",
      nm_cidade_uf: "Camaçari - BA"
  },
  {
      id_cidade: "351",
      id_estado: "5",
      nm_cidade: "Camamu",
      nm_cidade_uf: "Camamu - BA"
  },
  {
      id_cidade: "352",
      id_estado: "5",
      nm_cidade: "Campo Alegre de Lourdes",
      nm_cidade_uf: "Campo Alegre de Lourdes - BA"
  },
  {
      id_cidade: "353",
      id_estado: "5",
      nm_cidade: "Campo Formoso",
      nm_cidade_uf: "Campo Formoso - BA"
  },
  {
      id_cidade: "354",
      id_estado: "5",
      nm_cidade: "Canápolis",
      nm_cidade_uf: "Canápolis - BA"
  },
  {
      id_cidade: "355",
      id_estado: "5",
      nm_cidade: "Canarana",
      nm_cidade_uf: "Canarana - BA"
  },
  {
      id_cidade: "356",
      id_estado: "5",
      nm_cidade: "Canavieiras",
      nm_cidade_uf: "Canavieiras - BA"
  },
  {
      id_cidade: "357",
      id_estado: "5",
      nm_cidade: "Candeal",
      nm_cidade_uf: "Candeal - BA"
  },
  {
      id_cidade: "358",
      id_estado: "5",
      nm_cidade: "Candeias",
      nm_cidade_uf: "Candeias - BA"
  },
  {
      id_cidade: "359",
      id_estado: "5",
      nm_cidade: "Candiba",
      nm_cidade_uf: "Candiba - BA"
  },
  {
      id_cidade: "360",
      id_estado: "5",
      nm_cidade: "Cândido Sales",
      nm_cidade_uf: "Cândido Sales - BA"
  },
  {
      id_cidade: "361",
      id_estado: "5",
      nm_cidade: "Cansanção",
      nm_cidade_uf: "Cansanção - BA"
  },
  {
      id_cidade: "362",
      id_estado: "5",
      nm_cidade: "Canudos",
      nm_cidade_uf: "Canudos - BA"
  },
  {
      id_cidade: "363",
      id_estado: "5",
      nm_cidade: "Capela do Alto Alegre",
      nm_cidade_uf: "Capela do Alto Alegre - BA"
  },
  {
      id_cidade: "364",
      id_estado: "5",
      nm_cidade: "Capim Grosso",
      nm_cidade_uf: "Capim Grosso - BA"
  },
  {
      id_cidade: "365",
      id_estado: "5",
      nm_cidade: "Caraíbas",
      nm_cidade_uf: "Caraíbas - BA"
  },
  {
      id_cidade: "366",
      id_estado: "5",
      nm_cidade: "Caravelas",
      nm_cidade_uf: "Caravelas - BA"
  },
  {
      id_cidade: "367",
      id_estado: "5",
      nm_cidade: "Cardeal da Silva",
      nm_cidade_uf: "Cardeal da Silva - BA"
  },
  {
      id_cidade: "368",
      id_estado: "5",
      nm_cidade: "Carinhanha",
      nm_cidade_uf: "Carinhanha - BA"
  },
  {
      id_cidade: "369",
      id_estado: "5",
      nm_cidade: "Casa Nova",
      nm_cidade_uf: "Casa Nova - BA"
  },
  {
      id_cidade: "370",
      id_estado: "5",
      nm_cidade: "Castro Alves",
      nm_cidade_uf: "Castro Alves - BA"
  },
  {
      id_cidade: "371",
      id_estado: "5",
      nm_cidade: "Catolândia",
      nm_cidade_uf: "Catolândia - BA"
  },
  {
      id_cidade: "372",
      id_estado: "5",
      nm_cidade: "Catu",
      nm_cidade_uf: "Catu - BA"
  },
  {
      id_cidade: "373",
      id_estado: "5",
      nm_cidade: "Caturama",
      nm_cidade_uf: "Caturama - BA"
  },
  {
      id_cidade: "374",
      id_estado: "5",
      nm_cidade: "Central",
      nm_cidade_uf: "Central - BA"
  },
  {
      id_cidade: "375",
      id_estado: "5",
      nm_cidade: "Chorrochó",
      nm_cidade_uf: "Chorrochó - BA"
  },
  {
      id_cidade: "376",
      id_estado: "5",
      nm_cidade: "Cícero Dantas",
      nm_cidade_uf: "Cícero Dantas - BA"
  },
  {
      id_cidade: "377",
      id_estado: "5",
      nm_cidade: "Cipó",
      nm_cidade_uf: "Cipó - BA"
  },
  {
      id_cidade: "378",
      id_estado: "5",
      nm_cidade: "Coaraci",
      nm_cidade_uf: "Coaraci - BA"
  },
  {
      id_cidade: "379",
      id_estado: "5",
      nm_cidade: "Cocos",
      nm_cidade_uf: "Cocos - BA"
  },
  {
      id_cidade: "380",
      id_estado: "5",
      nm_cidade: "Conceição da Feira",
      nm_cidade_uf: "Conceição da Feira - BA"
  },
  {
      id_cidade: "381",
      id_estado: "5",
      nm_cidade: "Conceição do Almeida",
      nm_cidade_uf: "Conceição do Almeida - BA"
  },
  {
      id_cidade: "382",
      id_estado: "5",
      nm_cidade: "Conceição do Coité",
      nm_cidade_uf: "Conceição do Coité - BA"
  },
  {
      id_cidade: "383",
      id_estado: "5",
      nm_cidade: "Conceição do Jacuípe",
      nm_cidade_uf: "Conceição do Jacuípe - BA"
  },
  {
      id_cidade: "384",
      id_estado: "5",
      nm_cidade: "Conde",
      nm_cidade_uf: "Conde - BA"
  },
  {
      id_cidade: "385",
      id_estado: "5",
      nm_cidade: "Condeúba",
      nm_cidade_uf: "Condeúba - BA"
  },
  {
      id_cidade: "386",
      id_estado: "5",
      nm_cidade: "Contendas do Sincorá",
      nm_cidade_uf: "Contendas do Sincorá - BA"
  },
  {
      id_cidade: "387",
      id_estado: "5",
      nm_cidade: "Coração de Maria",
      nm_cidade_uf: "Coração de Maria - BA"
  },
  {
      id_cidade: "388",
      id_estado: "5",
      nm_cidade: "Cordeiros",
      nm_cidade_uf: "Cordeiros - BA"
  },
  {
      id_cidade: "389",
      id_estado: "5",
      nm_cidade: "Coribe",
      nm_cidade_uf: "Coribe - BA"
  },
  {
      id_cidade: "390",
      id_estado: "5",
      nm_cidade: "Coronel João Sá",
      nm_cidade_uf: "Coronel João Sá - BA"
  },
  {
      id_cidade: "391",
      id_estado: "5",
      nm_cidade: "Correntina",
      nm_cidade_uf: "Correntina - BA"
  },
  {
      id_cidade: "392",
      id_estado: "5",
      nm_cidade: "Cotegipe",
      nm_cidade_uf: "Cotegipe - BA"
  },
  {
      id_cidade: "393",
      id_estado: "5",
      nm_cidade: "Cravolândia",
      nm_cidade_uf: "Cravolândia - BA"
  },
  {
      id_cidade: "394",
      id_estado: "5",
      nm_cidade: "Crisópolis",
      nm_cidade_uf: "Crisópolis - BA"
  },
  {
      id_cidade: "395",
      id_estado: "5",
      nm_cidade: "Cristópolis",
      nm_cidade_uf: "Cristópolis - BA"
  },
  {
      id_cidade: "396",
      id_estado: "5",
      nm_cidade: "Cruz das Almas",
      nm_cidade_uf: "Cruz das Almas - BA"
  },
  {
      id_cidade: "397",
      id_estado: "5",
      nm_cidade: "Curaçá",
      nm_cidade_uf: "Curaçá - BA"
  },
  {
      id_cidade: "398",
      id_estado: "5",
      nm_cidade: "Dário Meira",
      nm_cidade_uf: "Dário Meira - BA"
  },
  {
      id_cidade: "399",
      id_estado: "5",
      nm_cidade: "Dias d`Ávila",
      nm_cidade_uf: "Dias d`Ávila - BA"
  },
  {
      id_cidade: "400",
      id_estado: "5",
      nm_cidade: "Dom Basílio",
      nm_cidade_uf: "Dom Basílio - BA"
  },
  {
      id_cidade: "401",
      id_estado: "5",
      nm_cidade: "Dom Macedo Costa",
      nm_cidade_uf: "Dom Macedo Costa - BA"
  },
  {
      id_cidade: "402",
      id_estado: "5",
      nm_cidade: "Elísio Medrado",
      nm_cidade_uf: "Elísio Medrado - BA"
  },
  {
      id_cidade: "403",
      id_estado: "5",
      nm_cidade: "Encruzilhada",
      nm_cidade_uf: "Encruzilhada - BA"
  },
  {
      id_cidade: "404",
      id_estado: "5",
      nm_cidade: "Entre Rios",
      nm_cidade_uf: "Entre Rios - BA"
  },
  {
      id_cidade: "405",
      id_estado: "5",
      nm_cidade: "Érico Cardoso",
      nm_cidade_uf: "Érico Cardoso - BA"
  },
  {
      id_cidade: "406",
      id_estado: "5",
      nm_cidade: "Esplanada",
      nm_cidade_uf: "Esplanada - BA"
  },
  {
      id_cidade: "407",
      id_estado: "5",
      nm_cidade: "Euclides da Cunha",
      nm_cidade_uf: "Euclides da Cunha - BA"
  },
  {
      id_cidade: "408",
      id_estado: "5",
      nm_cidade: "Eunápolis",
      nm_cidade_uf: "Eunápolis - BA"
  },
  {
      id_cidade: "409",
      id_estado: "5",
      nm_cidade: "Fátima",
      nm_cidade_uf: "Fátima - BA"
  },
  {
      id_cidade: "410",
      id_estado: "5",
      nm_cidade: "Feira da Mata",
      nm_cidade_uf: "Feira da Mata - BA"
  },
  {
      id_cidade: "411",
      id_estado: "5",
      nm_cidade: "Feira de Santana",
      nm_cidade_uf: "Feira de Santana - BA"
  },
  {
      id_cidade: "412",
      id_estado: "5",
      nm_cidade: "Filadélfia",
      nm_cidade_uf: "Filadélfia - BA"
  },
  {
      id_cidade: "413",
      id_estado: "5",
      nm_cidade: "Firmino Alves",
      nm_cidade_uf: "Firmino Alves - BA"
  },
  {
      id_cidade: "414",
      id_estado: "5",
      nm_cidade: "Floresta Azul",
      nm_cidade_uf: "Floresta Azul - BA"
  },
  {
      id_cidade: "415",
      id_estado: "5",
      nm_cidade: "Formosa do Rio Preto",
      nm_cidade_uf: "Formosa do Rio Preto - BA"
  },
  {
      id_cidade: "416",
      id_estado: "5",
      nm_cidade: "Gandu",
      nm_cidade_uf: "Gandu - BA"
  },
  {
      id_cidade: "417",
      id_estado: "5",
      nm_cidade: "Gavião",
      nm_cidade_uf: "Gavião - BA"
  },
  {
      id_cidade: "418",
      id_estado: "5",
      nm_cidade: "Gentio do Ouro",
      nm_cidade_uf: "Gentio do Ouro - BA"
  },
  {
      id_cidade: "419",
      id_estado: "5",
      nm_cidade: "Glória",
      nm_cidade_uf: "Glória - BA"
  },
  {
      id_cidade: "420",
      id_estado: "5",
      nm_cidade: "Gongogi",
      nm_cidade_uf: "Gongogi - BA"
  },
  {
      id_cidade: "421",
      id_estado: "5",
      nm_cidade: "Governador Mangabeira",
      nm_cidade_uf: "Governador Mangabeira - BA"
  },
  {
      id_cidade: "422",
      id_estado: "5",
      nm_cidade: "Guajeru",
      nm_cidade_uf: "Guajeru - BA"
  },
  {
      id_cidade: "423",
      id_estado: "5",
      nm_cidade: "Guanambi",
      nm_cidade_uf: "Guanambi - BA"
  },
  {
      id_cidade: "424",
      id_estado: "5",
      nm_cidade: "Guaratinga",
      nm_cidade_uf: "Guaratinga - BA"
  },
  {
      id_cidade: "425",
      id_estado: "5",
      nm_cidade: "Heliópolis",
      nm_cidade_uf: "Heliópolis - BA"
  },
  {
      id_cidade: "426",
      id_estado: "5",
      nm_cidade: "Iaçu",
      nm_cidade_uf: "Iaçu - BA"
  },
  {
      id_cidade: "427",
      id_estado: "5",
      nm_cidade: "Ibiassucê",
      nm_cidade_uf: "Ibiassucê - BA"
  },
  {
      id_cidade: "428",
      id_estado: "5",
      nm_cidade: "Ibicaraí",
      nm_cidade_uf: "Ibicaraí - BA"
  },
  {
      id_cidade: "429",
      id_estado: "5",
      nm_cidade: "Ibicoara",
      nm_cidade_uf: "Ibicoara - BA"
  },
  {
      id_cidade: "430",
      id_estado: "5",
      nm_cidade: "Ibicuí",
      nm_cidade_uf: "Ibicuí - BA"
  },
  {
      id_cidade: "431",
      id_estado: "5",
      nm_cidade: "Ibipeba",
      nm_cidade_uf: "Ibipeba - BA"
  },
  {
      id_cidade: "432",
      id_estado: "5",
      nm_cidade: "Ibipitanga",
      nm_cidade_uf: "Ibipitanga - BA"
  },
  {
      id_cidade: "433",
      id_estado: "5",
      nm_cidade: "Ibiquera",
      nm_cidade_uf: "Ibiquera - BA"
  },
  {
      id_cidade: "434",
      id_estado: "5",
      nm_cidade: "Ibirapitanga",
      nm_cidade_uf: "Ibirapitanga - BA"
  },
  {
      id_cidade: "435",
      id_estado: "5",
      nm_cidade: "Ibirapuã",
      nm_cidade_uf: "Ibirapuã - BA"
  },
  {
      id_cidade: "436",
      id_estado: "5",
      nm_cidade: "Ibirataia",
      nm_cidade_uf: "Ibirataia - BA"
  },
  {
      id_cidade: "437",
      id_estado: "5",
      nm_cidade: "Ibitiara",
      nm_cidade_uf: "Ibitiara - BA"
  },
  {
      id_cidade: "438",
      id_estado: "5",
      nm_cidade: "Ibititá",
      nm_cidade_uf: "Ibititá - BA"
  },
  {
      id_cidade: "439",
      id_estado: "5",
      nm_cidade: "Ibotirama",
      nm_cidade_uf: "Ibotirama - BA"
  },
  {
      id_cidade: "440",
      id_estado: "5",
      nm_cidade: "Ichu",
      nm_cidade_uf: "Ichu - BA"
  },
  {
      id_cidade: "441",
      id_estado: "5",
      nm_cidade: "Igaporã",
      nm_cidade_uf: "Igaporã - BA"
  },
  {
      id_cidade: "442",
      id_estado: "5",
      nm_cidade: "Igrapiúna",
      nm_cidade_uf: "Igrapiúna - BA"
  },
  {
      id_cidade: "443",
      id_estado: "5",
      nm_cidade: "Iguaí",
      nm_cidade_uf: "Iguaí - BA"
  },
  {
      id_cidade: "444",
      id_estado: "5",
      nm_cidade: "Ilhéus",
      nm_cidade_uf: "Ilhéus - BA"
  },
  {
      id_cidade: "445",
      id_estado: "5",
      nm_cidade: "Inhambupe",
      nm_cidade_uf: "Inhambupe - BA"
  },
  {
      id_cidade: "446",
      id_estado: "5",
      nm_cidade: "Ipecaetá",
      nm_cidade_uf: "Ipecaetá - BA"
  },
  {
      id_cidade: "447",
      id_estado: "5",
      nm_cidade: "Ipiaú",
      nm_cidade_uf: "Ipiaú - BA"
  },
  {
      id_cidade: "448",
      id_estado: "5",
      nm_cidade: "Ipirá",
      nm_cidade_uf: "Ipirá - BA"
  },
  {
      id_cidade: "449",
      id_estado: "5",
      nm_cidade: "Ipupiara",
      nm_cidade_uf: "Ipupiara - BA"
  },
  {
      id_cidade: "450",
      id_estado: "5",
      nm_cidade: "Irajuba",
      nm_cidade_uf: "Irajuba - BA"
  },
  {
      id_cidade: "451",
      id_estado: "5",
      nm_cidade: "Iramaia",
      nm_cidade_uf: "Iramaia - BA"
  },
  {
      id_cidade: "452",
      id_estado: "5",
      nm_cidade: "Iraquara",
      nm_cidade_uf: "Iraquara - BA"
  },
  {
      id_cidade: "453",
      id_estado: "5",
      nm_cidade: "Irará",
      nm_cidade_uf: "Irará - BA"
  },
  {
      id_cidade: "454",
      id_estado: "5",
      nm_cidade: "Irecê",
      nm_cidade_uf: "Irecê - BA"
  },
  {
      id_cidade: "455",
      id_estado: "5",
      nm_cidade: "Itabela",
      nm_cidade_uf: "Itabela - BA"
  },
  {
      id_cidade: "456",
      id_estado: "5",
      nm_cidade: "Itaberaba",
      nm_cidade_uf: "Itaberaba - BA"
  },
  {
      id_cidade: "457",
      id_estado: "5",
      nm_cidade: "Itabuna",
      nm_cidade_uf: "Itabuna - BA"
  },
  {
      id_cidade: "458",
      id_estado: "5",
      nm_cidade: "Itacaré",
      nm_cidade_uf: "Itacaré - BA"
  },
  {
      id_cidade: "459",
      id_estado: "5",
      nm_cidade: "Itaeté",
      nm_cidade_uf: "Itaeté - BA"
  },
  {
      id_cidade: "460",
      id_estado: "5",
      nm_cidade: "Itagi",
      nm_cidade_uf: "Itagi - BA"
  },
  {
      id_cidade: "461",
      id_estado: "5",
      nm_cidade: "Itagibá",
      nm_cidade_uf: "Itagibá - BA"
  },
  {
      id_cidade: "462",
      id_estado: "5",
      nm_cidade: "Itagimirim",
      nm_cidade_uf: "Itagimirim - BA"
  },
  {
      id_cidade: "463",
      id_estado: "5",
      nm_cidade: "Itaguaçu da Bahia",
      nm_cidade_uf: "Itaguaçu da Bahia - BA"
  },
  {
      id_cidade: "464",
      id_estado: "5",
      nm_cidade: "Itaju do Colônia",
      nm_cidade_uf: "Itaju do Colônia - BA"
  },
  {
      id_cidade: "465",
      id_estado: "5",
      nm_cidade: "Itajuípe",
      nm_cidade_uf: "Itajuípe - BA"
  },
  {
      id_cidade: "466",
      id_estado: "5",
      nm_cidade: "Itamaraju",
      nm_cidade_uf: "Itamaraju - BA"
  },
  {
      id_cidade: "467",
      id_estado: "5",
      nm_cidade: "Itamari",
      nm_cidade_uf: "Itamari - BA"
  },
  {
      id_cidade: "468",
      id_estado: "5",
      nm_cidade: "Itambé",
      nm_cidade_uf: "Itambé - BA"
  },
  {
      id_cidade: "469",
      id_estado: "5",
      nm_cidade: "Itanagra",
      nm_cidade_uf: "Itanagra - BA"
  },
  {
      id_cidade: "470",
      id_estado: "5",
      nm_cidade: "Itanhém",
      nm_cidade_uf: "Itanhém - BA"
  },
  {
      id_cidade: "471",
      id_estado: "5",
      nm_cidade: "Itaparica",
      nm_cidade_uf: "Itaparica - BA"
  },
  {
      id_cidade: "472",
      id_estado: "5",
      nm_cidade: "Itapé",
      nm_cidade_uf: "Itapé - BA"
  },
  {
      id_cidade: "473",
      id_estado: "5",
      nm_cidade: "Itapebi",
      nm_cidade_uf: "Itapebi - BA"
  },
  {
      id_cidade: "474",
      id_estado: "5",
      nm_cidade: "Itapetinga",
      nm_cidade_uf: "Itapetinga - BA"
  },
  {
      id_cidade: "475",
      id_estado: "5",
      nm_cidade: "Itapicuru",
      nm_cidade_uf: "Itapicuru - BA"
  },
  {
      id_cidade: "476",
      id_estado: "5",
      nm_cidade: "Itapitanga",
      nm_cidade_uf: "Itapitanga - BA"
  },
  {
      id_cidade: "477",
      id_estado: "5",
      nm_cidade: "Itaquara",
      nm_cidade_uf: "Itaquara - BA"
  },
  {
      id_cidade: "478",
      id_estado: "5",
      nm_cidade: "Itarantim",
      nm_cidade_uf: "Itarantim - BA"
  },
  {
      id_cidade: "479",
      id_estado: "5",
      nm_cidade: "Itatim",
      nm_cidade_uf: "Itatim - BA"
  },
  {
      id_cidade: "480",
      id_estado: "5",
      nm_cidade: "Itiruçu",
      nm_cidade_uf: "Itiruçu - BA"
  },
  {
      id_cidade: "481",
      id_estado: "5",
      nm_cidade: "Itiúba",
      nm_cidade_uf: "Itiúba - BA"
  },
  {
      id_cidade: "482",
      id_estado: "5",
      nm_cidade: "Itororó",
      nm_cidade_uf: "Itororó - BA"
  },
  {
      id_cidade: "483",
      id_estado: "5",
      nm_cidade: "Ituaçu",
      nm_cidade_uf: "Ituaçu - BA"
  },
  {
      id_cidade: "484",
      id_estado: "5",
      nm_cidade: "Ituberá",
      nm_cidade_uf: "Ituberá - BA"
  },
  {
      id_cidade: "485",
      id_estado: "5",
      nm_cidade: "Iuiú",
      nm_cidade_uf: "Iuiú - BA"
  },
  {
      id_cidade: "486",
      id_estado: "5",
      nm_cidade: "Jaborandi",
      nm_cidade_uf: "Jaborandi - BA"
  },
  {
      id_cidade: "487",
      id_estado: "5",
      nm_cidade: "Jacaraci",
      nm_cidade_uf: "Jacaraci - BA"
  },
  {
      id_cidade: "488",
      id_estado: "5",
      nm_cidade: "Jacobina",
      nm_cidade_uf: "Jacobina - BA"
  },
  {
      id_cidade: "489",
      id_estado: "5",
      nm_cidade: "Jaguaquara",
      nm_cidade_uf: "Jaguaquara - BA"
  },
  {
      id_cidade: "490",
      id_estado: "5",
      nm_cidade: "Jaguarari",
      nm_cidade_uf: "Jaguarari - BA"
  },
  {
      id_cidade: "491",
      id_estado: "5",
      nm_cidade: "Jaguaripe",
      nm_cidade_uf: "Jaguaripe - BA"
  },
  {
      id_cidade: "492",
      id_estado: "5",
      nm_cidade: "Jandaíra",
      nm_cidade_uf: "Jandaíra - BA"
  },
  {
      id_cidade: "493",
      id_estado: "5",
      nm_cidade: "Jequié",
      nm_cidade_uf: "Jequié - BA"
  },
  {
      id_cidade: "494",
      id_estado: "5",
      nm_cidade: "Jeremoabo",
      nm_cidade_uf: "Jeremoabo - BA"
  },
  {
      id_cidade: "495",
      id_estado: "5",
      nm_cidade: "Jiquiriçá",
      nm_cidade_uf: "Jiquiriçá - BA"
  },
  {
      id_cidade: "496",
      id_estado: "5",
      nm_cidade: "Jitaúna",
      nm_cidade_uf: "Jitaúna - BA"
  },
  {
      id_cidade: "497",
      id_estado: "5",
      nm_cidade: "João Dourado",
      nm_cidade_uf: "João Dourado - BA"
  },
  {
      id_cidade: "498",
      id_estado: "5",
      nm_cidade: "Juazeiro",
      nm_cidade_uf: "Juazeiro - BA"
  },
  {
      id_cidade: "499",
      id_estado: "5",
      nm_cidade: "Jucuruçu",
      nm_cidade_uf: "Jucuruçu - BA"
  },
  {
      id_cidade: "500",
      id_estado: "5",
      nm_cidade: "Jussara",
      nm_cidade_uf: "Jussara - BA"
  },
  {
      id_cidade: "501",
      id_estado: "5",
      nm_cidade: "Jussari",
      nm_cidade_uf: "Jussari - BA"
  },
  {
      id_cidade: "502",
      id_estado: "5",
      nm_cidade: "Jussiape",
      nm_cidade_uf: "Jussiape - BA"
  },
  {
      id_cidade: "503",
      id_estado: "5",
      nm_cidade: "Lafaiete Coutinho",
      nm_cidade_uf: "Lafaiete Coutinho - BA"
  },
  {
      id_cidade: "504",
      id_estado: "5",
      nm_cidade: "Lagoa Real",
      nm_cidade_uf: "Lagoa Real - BA"
  },
  {
      id_cidade: "505",
      id_estado: "5",
      nm_cidade: "Laje",
      nm_cidade_uf: "Laje - BA"
  },
  {
      id_cidade: "506",
      id_estado: "5",
      nm_cidade: "Lajedão",
      nm_cidade_uf: "Lajedão - BA"
  },
  {
      id_cidade: "507",
      id_estado: "5",
      nm_cidade: "Lajedinho",
      nm_cidade_uf: "Lajedinho - BA"
  },
  {
      id_cidade: "508",
      id_estado: "5",
      nm_cidade: "Lajedo do Tabocal",
      nm_cidade_uf: "Lajedo do Tabocal - BA"
  },
  {
      id_cidade: "509",
      id_estado: "5",
      nm_cidade: "Lamarão",
      nm_cidade_uf: "Lamarão - BA"
  },
  {
      id_cidade: "510",
      id_estado: "5",
      nm_cidade: "Lapão",
      nm_cidade_uf: "Lapão - BA"
  },
  {
      id_cidade: "511",
      id_estado: "5",
      nm_cidade: "Lauro de Freitas",
      nm_cidade_uf: "Lauro de Freitas - BA"
  },
  {
      id_cidade: "512",
      id_estado: "5",
      nm_cidade: "Lençóis",
      nm_cidade_uf: "Lençóis - BA"
  },
  {
      id_cidade: "513",
      id_estado: "5",
      nm_cidade: "Licínio de Almeida",
      nm_cidade_uf: "Licínio de Almeida - BA"
  },
  {
      id_cidade: "514",
      id_estado: "5",
      nm_cidade: "Livramento de Nossa Senhora",
      nm_cidade_uf: "Livramento de Nossa Senhora - BA"
  },
  {
      id_cidade: "515",
      id_estado: "5",
      nm_cidade: "Luís Eduardo Magalhães",
      nm_cidade_uf: "Luís Eduardo Magalhães - BA"
  },
  {
      id_cidade: "516",
      id_estado: "5",
      nm_cidade: "Macajuba",
      nm_cidade_uf: "Macajuba - BA"
  },
  {
      id_cidade: "517",
      id_estado: "5",
      nm_cidade: "Macarani",
      nm_cidade_uf: "Macarani - BA"
  },
  {
      id_cidade: "518",
      id_estado: "5",
      nm_cidade: "Macaúbas",
      nm_cidade_uf: "Macaúbas - BA"
  },
  {
      id_cidade: "519",
      id_estado: "5",
      nm_cidade: "Macururé",
      nm_cidade_uf: "Macururé - BA"
  },
  {
      id_cidade: "520",
      id_estado: "5",
      nm_cidade: "Madre de Deus",
      nm_cidade_uf: "Madre de Deus - BA"
  },
  {
      id_cidade: "521",
      id_estado: "5",
      nm_cidade: "Maetinga",
      nm_cidade_uf: "Maetinga - BA"
  },
  {
      id_cidade: "522",
      id_estado: "5",
      nm_cidade: "Maiquinique",
      nm_cidade_uf: "Maiquinique - BA"
  },
  {
      id_cidade: "523",
      id_estado: "5",
      nm_cidade: "Mairi",
      nm_cidade_uf: "Mairi - BA"
  },
  {
      id_cidade: "524",
      id_estado: "5",
      nm_cidade: "Malhada",
      nm_cidade_uf: "Malhada - BA"
  },
  {
      id_cidade: "525",
      id_estado: "5",
      nm_cidade: "Malhada de Pedras",
      nm_cidade_uf: "Malhada de Pedras - BA"
  },
  {
      id_cidade: "526",
      id_estado: "5",
      nm_cidade: "Manoel Vitorino",
      nm_cidade_uf: "Manoel Vitorino - BA"
  },
  {
      id_cidade: "527",
      id_estado: "5",
      nm_cidade: "Mansidão",
      nm_cidade_uf: "Mansidão - BA"
  },
  {
      id_cidade: "528",
      id_estado: "5",
      nm_cidade: "Maracás",
      nm_cidade_uf: "Maracás - BA"
  },
  {
      id_cidade: "529",
      id_estado: "5",
      nm_cidade: "Maragogipe",
      nm_cidade_uf: "Maragogipe - BA"
  },
  {
      id_cidade: "530",
      id_estado: "5",
      nm_cidade: "Maraú",
      nm_cidade_uf: "Maraú - BA"
  },
  {
      id_cidade: "531",
      id_estado: "5",
      nm_cidade: "Marcionílio Souza",
      nm_cidade_uf: "Marcionílio Souza - BA"
  },
  {
      id_cidade: "532",
      id_estado: "5",
      nm_cidade: "Mascote",
      nm_cidade_uf: "Mascote - BA"
  },
  {
      id_cidade: "533",
      id_estado: "5",
      nm_cidade: "Mata de São João",
      nm_cidade_uf: "Mata de São João - BA"
  },
  {
      id_cidade: "534",
      id_estado: "5",
      nm_cidade: "Matina",
      nm_cidade_uf: "Matina - BA"
  },
  {
      id_cidade: "535",
      id_estado: "5",
      nm_cidade: "Medeiros Neto",
      nm_cidade_uf: "Medeiros Neto - BA"
  },
  {
      id_cidade: "536",
      id_estado: "5",
      nm_cidade: "Miguel Calmon",
      nm_cidade_uf: "Miguel Calmon - BA"
  },
  {
      id_cidade: "537",
      id_estado: "5",
      nm_cidade: "Milagres",
      nm_cidade_uf: "Milagres - BA"
  },
  {
      id_cidade: "538",
      id_estado: "5",
      nm_cidade: "Mirangaba",
      nm_cidade_uf: "Mirangaba - BA"
  },
  {
      id_cidade: "539",
      id_estado: "5",
      nm_cidade: "Mirante",
      nm_cidade_uf: "Mirante - BA"
  },
  {
      id_cidade: "540",
      id_estado: "5",
      nm_cidade: "Monte Santo",
      nm_cidade_uf: "Monte Santo - BA"
  },
  {
      id_cidade: "541",
      id_estado: "5",
      nm_cidade: "Morpará",
      nm_cidade_uf: "Morpará - BA"
  },
  {
      id_cidade: "542",
      id_estado: "5",
      nm_cidade: "Morro do Chapéu",
      nm_cidade_uf: "Morro do Chapéu - BA"
  },
  {
      id_cidade: "543",
      id_estado: "5",
      nm_cidade: "Mortugaba",
      nm_cidade_uf: "Mortugaba - BA"
  },
  {
      id_cidade: "544",
      id_estado: "5",
      nm_cidade: "Mucugê",
      nm_cidade_uf: "Mucugê - BA"
  },
  {
      id_cidade: "545",
      id_estado: "5",
      nm_cidade: "Mucuri",
      nm_cidade_uf: "Mucuri - BA"
  },
  {
      id_cidade: "546",
      id_estado: "5",
      nm_cidade: "Mulungu do Morro",
      nm_cidade_uf: "Mulungu do Morro - BA"
  },
  {
      id_cidade: "547",
      id_estado: "5",
      nm_cidade: "Mundo Novo",
      nm_cidade_uf: "Mundo Novo - BA"
  },
  {
      id_cidade: "548",
      id_estado: "5",
      nm_cidade: "Muniz Ferreira",
      nm_cidade_uf: "Muniz Ferreira - BA"
  },
  {
      id_cidade: "549",
      id_estado: "5",
      nm_cidade: "Muquém de São Francisco",
      nm_cidade_uf: "Muquém de São Francisco - BA"
  },
  {
      id_cidade: "550",
      id_estado: "5",
      nm_cidade: "Muritiba",
      nm_cidade_uf: "Muritiba - BA"
  },
  {
      id_cidade: "551",
      id_estado: "5",
      nm_cidade: "Mutuípe",
      nm_cidade_uf: "Mutuípe - BA"
  },
  {
      id_cidade: "552",
      id_estado: "5",
      nm_cidade: "Nazaré",
      nm_cidade_uf: "Nazaré - BA"
  },
  {
      id_cidade: "553",
      id_estado: "5",
      nm_cidade: "Nilo Peçanha",
      nm_cidade_uf: "Nilo Peçanha - BA"
  },
  {
      id_cidade: "554",
      id_estado: "5",
      nm_cidade: "Nordestina",
      nm_cidade_uf: "Nordestina - BA"
  },
  {
      id_cidade: "555",
      id_estado: "5",
      nm_cidade: "Nova Canaã",
      nm_cidade_uf: "Nova Canaã - BA"
  },
  {
      id_cidade: "556",
      id_estado: "5",
      nm_cidade: "Nova Fátima",
      nm_cidade_uf: "Nova Fátima - BA"
  },
  {
      id_cidade: "557",
      id_estado: "5",
      nm_cidade: "Nova Ibiá",
      nm_cidade_uf: "Nova Ibiá - BA"
  },
  {
      id_cidade: "558",
      id_estado: "5",
      nm_cidade: "Nova Itarana",
      nm_cidade_uf: "Nova Itarana - BA"
  },
  {
      id_cidade: "559",
      id_estado: "5",
      nm_cidade: "Nova Redenção",
      nm_cidade_uf: "Nova Redenção - BA"
  },
  {
      id_cidade: "560",
      id_estado: "5",
      nm_cidade: "Nova Soure",
      nm_cidade_uf: "Nova Soure - BA"
  },
  {
      id_cidade: "561",
      id_estado: "5",
      nm_cidade: "Nova Viçosa",
      nm_cidade_uf: "Nova Viçosa - BA"
  },
  {
      id_cidade: "562",
      id_estado: "5",
      nm_cidade: "Novo Horizonte",
      nm_cidade_uf: "Novo Horizonte - BA"
  },
  {
      id_cidade: "563",
      id_estado: "5",
      nm_cidade: "Novo Triunfo",
      nm_cidade_uf: "Novo Triunfo - BA"
  },
  {
      id_cidade: "564",
      id_estado: "5",
      nm_cidade: "Olindina",
      nm_cidade_uf: "Olindina - BA"
  },
  {
      id_cidade: "565",
      id_estado: "5",
      nm_cidade: "Oliveira dos Brejinhos",
      nm_cidade_uf: "Oliveira dos Brejinhos - BA"
  },
  {
      id_cidade: "566",
      id_estado: "5",
      nm_cidade: "Ouriçangas",
      nm_cidade_uf: "Ouriçangas - BA"
  },
  {
      id_cidade: "567",
      id_estado: "5",
      nm_cidade: "Ourolândia",
      nm_cidade_uf: "Ourolândia - BA"
  },
  {
      id_cidade: "568",
      id_estado: "5",
      nm_cidade: "Palmas de Monte Alto",
      nm_cidade_uf: "Palmas de Monte Alto - BA"
  },
  {
      id_cidade: "569",
      id_estado: "5",
      nm_cidade: "Palmeiras",
      nm_cidade_uf: "Palmeiras - BA"
  },
  {
      id_cidade: "570",
      id_estado: "5",
      nm_cidade: "Paramirim",
      nm_cidade_uf: "Paramirim - BA"
  },
  {
      id_cidade: "571",
      id_estado: "5",
      nm_cidade: "Paratinga",
      nm_cidade_uf: "Paratinga - BA"
  },
  {
      id_cidade: "572",
      id_estado: "5",
      nm_cidade: "Paripiranga",
      nm_cidade_uf: "Paripiranga - BA"
  },
  {
      id_cidade: "573",
      id_estado: "5",
      nm_cidade: "Pau Brasil",
      nm_cidade_uf: "Pau Brasil - BA"
  },
  {
      id_cidade: "574",
      id_estado: "5",
      nm_cidade: "Paulo Afonso",
      nm_cidade_uf: "Paulo Afonso - BA"
  },
  {
      id_cidade: "575",
      id_estado: "5",
      nm_cidade: "Pé de Serra",
      nm_cidade_uf: "Pé de Serra - BA"
  },
  {
      id_cidade: "576",
      id_estado: "5",
      nm_cidade: "Pedrão",
      nm_cidade_uf: "Pedrão - BA"
  },
  {
      id_cidade: "577",
      id_estado: "5",
      nm_cidade: "Pedro Alexandre",
      nm_cidade_uf: "Pedro Alexandre - BA"
  },
  {
      id_cidade: "578",
      id_estado: "5",
      nm_cidade: "Piatã",
      nm_cidade_uf: "Piatã - BA"
  },
  {
      id_cidade: "579",
      id_estado: "5",
      nm_cidade: "Pilão Arcado",
      nm_cidade_uf: "Pilão Arcado - BA"
  },
  {
      id_cidade: "580",
      id_estado: "5",
      nm_cidade: "Pindaí",
      nm_cidade_uf: "Pindaí - BA"
  },
  {
      id_cidade: "581",
      id_estado: "5",
      nm_cidade: "Pindobaçu",
      nm_cidade_uf: "Pindobaçu - BA"
  },
  {
      id_cidade: "582",
      id_estado: "5",
      nm_cidade: "Pintadas",
      nm_cidade_uf: "Pintadas - BA"
  },
  {
      id_cidade: "583",
      id_estado: "5",
      nm_cidade: "Piraí do Norte",
      nm_cidade_uf: "Piraí do Norte - BA"
  },
  {
      id_cidade: "584",
      id_estado: "5",
      nm_cidade: "Piripá",
      nm_cidade_uf: "Piripá - BA"
  },
  {
      id_cidade: "585",
      id_estado: "5",
      nm_cidade: "Piritiba",
      nm_cidade_uf: "Piritiba - BA"
  },
  {
      id_cidade: "586",
      id_estado: "5",
      nm_cidade: "Planaltino",
      nm_cidade_uf: "Planaltino - BA"
  },
  {
      id_cidade: "587",
      id_estado: "5",
      nm_cidade: "Planalto",
      nm_cidade_uf: "Planalto - BA"
  },
  {
      id_cidade: "588",
      id_estado: "5",
      nm_cidade: "Poções",
      nm_cidade_uf: "Poções - BA"
  },
  {
      id_cidade: "589",
      id_estado: "5",
      nm_cidade: "Pojuca",
      nm_cidade_uf: "Pojuca - BA"
  },
  {
      id_cidade: "590",
      id_estado: "5",
      nm_cidade: "Ponto Novo",
      nm_cidade_uf: "Ponto Novo - BA"
  },
  {
      id_cidade: "591",
      id_estado: "5",
      nm_cidade: "Porto Seguro",
      nm_cidade_uf: "Porto Seguro - BA"
  },
  {
      id_cidade: "592",
      id_estado: "5",
      nm_cidade: "Potiraguá",
      nm_cidade_uf: "Potiraguá - BA"
  },
  {
      id_cidade: "593",
      id_estado: "5",
      nm_cidade: "Prado",
      nm_cidade_uf: "Prado - BA"
  },
  {
      id_cidade: "594",
      id_estado: "5",
      nm_cidade: "Presidente Dutra",
      nm_cidade_uf: "Presidente Dutra - BA"
  },
  {
      id_cidade: "595",
      id_estado: "5",
      nm_cidade: "Presidente Jânio Quadros",
      nm_cidade_uf: "Presidente Jânio Quadros - BA"
  },
  {
      id_cidade: "596",
      id_estado: "5",
      nm_cidade: "Presidente Tancredo Neves",
      nm_cidade_uf: "Presidente Tancredo Neves - BA"
  },
  {
      id_cidade: "597",
      id_estado: "5",
      nm_cidade: "Queimadas",
      nm_cidade_uf: "Queimadas - BA"
  },
  {
      id_cidade: "598",
      id_estado: "5",
      nm_cidade: "Quijingue",
      nm_cidade_uf: "Quijingue - BA"
  },
  {
      id_cidade: "599",
      id_estado: "5",
      nm_cidade: "Quixabeira",
      nm_cidade_uf: "Quixabeira - BA"
  },
  {
      id_cidade: "600",
      id_estado: "5",
      nm_cidade: "Rafael Jambeiro",
      nm_cidade_uf: "Rafael Jambeiro - BA"
  },
  {
      id_cidade: "601",
      id_estado: "5",
      nm_cidade: "Remanso",
      nm_cidade_uf: "Remanso - BA"
  },
  {
      id_cidade: "602",
      id_estado: "5",
      nm_cidade: "Retirolândia",
      nm_cidade_uf: "Retirolândia - BA"
  },
  {
      id_cidade: "603",
      id_estado: "5",
      nm_cidade: "Riachão das Neves",
      nm_cidade_uf: "Riachão das Neves - BA"
  },
  {
      id_cidade: "604",
      id_estado: "5",
      nm_cidade: "Riachão do Jacuípe",
      nm_cidade_uf: "Riachão do Jacuípe - BA"
  },
  {
      id_cidade: "605",
      id_estado: "5",
      nm_cidade: "Riacho de Santana",
      nm_cidade_uf: "Riacho de Santana - BA"
  },
  {
      id_cidade: "606",
      id_estado: "5",
      nm_cidade: "Ribeira do Amparo",
      nm_cidade_uf: "Ribeira do Amparo - BA"
  },
  {
      id_cidade: "607",
      id_estado: "5",
      nm_cidade: "Ribeira do Pombal",
      nm_cidade_uf: "Ribeira do Pombal - BA"
  },
  {
      id_cidade: "608",
      id_estado: "5",
      nm_cidade: "Ribeirão do Largo",
      nm_cidade_uf: "Ribeirão do Largo - BA"
  },
  {
      id_cidade: "609",
      id_estado: "5",
      nm_cidade: "Rio de Contas",
      nm_cidade_uf: "Rio de Contas - BA"
  },
  {
      id_cidade: "610",
      id_estado: "5",
      nm_cidade: "Rio do Antônio",
      nm_cidade_uf: "Rio do Antônio - BA"
  },
  {
      id_cidade: "611",
      id_estado: "5",
      nm_cidade: "Rio do Pires",
      nm_cidade_uf: "Rio do Pires - BA"
  },
  {
      id_cidade: "612",
      id_estado: "5",
      nm_cidade: "Rio Real",
      nm_cidade_uf: "Rio Real - BA"
  },
  {
      id_cidade: "613",
      id_estado: "5",
      nm_cidade: "Rodelas",
      nm_cidade_uf: "Rodelas - BA"
  },
  {
      id_cidade: "614",
      id_estado: "5",
      nm_cidade: "Ruy Barbosa",
      nm_cidade_uf: "Ruy Barbosa - BA"
  },
  {
      id_cidade: "615",
      id_estado: "5",
      nm_cidade: "Salinas da Margarida",
      nm_cidade_uf: "Salinas da Margarida - BA"
  },
  {
      id_cidade: "616",
      id_estado: "5",
      nm_cidade: "Salvador",
      nm_cidade_uf: "Salvador - BA"
  },
  {
      id_cidade: "617",
      id_estado: "5",
      nm_cidade: "Santa Bárbara",
      nm_cidade_uf: "Santa Bárbara - BA"
  },
  {
      id_cidade: "618",
      id_estado: "5",
      nm_cidade: "Santa Brígida",
      nm_cidade_uf: "Santa Brígida - BA"
  },
  {
      id_cidade: "619",
      id_estado: "5",
      nm_cidade: "Santa Cruz Cabrália",
      nm_cidade_uf: "Santa Cruz Cabrália - BA"
  },
  {
      id_cidade: "620",
      id_estado: "5",
      nm_cidade: "Santa Cruz da Vitória",
      nm_cidade_uf: "Santa Cruz da Vitória - BA"
  },
  {
      id_cidade: "621",
      id_estado: "5",
      nm_cidade: "Santa Inês",
      nm_cidade_uf: "Santa Inês - BA"
  },
  {
      id_cidade: "622",
      id_estado: "5",
      nm_cidade: "Santa Luzia",
      nm_cidade_uf: "Santa Luzia - BA"
  },
  {
      id_cidade: "623",
      id_estado: "5",
      nm_cidade: "Santa Maria da Vitória",
      nm_cidade_uf: "Santa Maria da Vitória - BA"
  },
  {
      id_cidade: "624",
      id_estado: "5",
      nm_cidade: "Santa Rita de Cássia",
      nm_cidade_uf: "Santa Rita de Cássia - BA"
  },
  {
      id_cidade: "625",
      id_estado: "5",
      nm_cidade: "Santa Teresinha",
      nm_cidade_uf: "Santa Teresinha - BA"
  },
  {
      id_cidade: "626",
      id_estado: "5",
      nm_cidade: "Santaluz",
      nm_cidade_uf: "Santaluz - BA"
  },
  {
      id_cidade: "627",
      id_estado: "5",
      nm_cidade: "Santana",
      nm_cidade_uf: "Santana - BA"
  },
  {
      id_cidade: "628",
      id_estado: "5",
      nm_cidade: "Santanópolis",
      nm_cidade_uf: "Santanópolis - BA"
  },
  {
      id_cidade: "629",
      id_estado: "5",
      nm_cidade: "Santo Amaro",
      nm_cidade_uf: "Santo Amaro - BA"
  },
  {
      id_cidade: "630",
      id_estado: "5",
      nm_cidade: "Santo Antônio de Jesus",
      nm_cidade_uf: "Santo Antônio de Jesus - BA"
  },
  {
      id_cidade: "631",
      id_estado: "5",
      nm_cidade: "Santo Estêvão",
      nm_cidade_uf: "Santo Estêvão - BA"
  },
  {
      id_cidade: "632",
      id_estado: "5",
      nm_cidade: "São Desidério",
      nm_cidade_uf: "São Desidério - BA"
  },
  {
      id_cidade: "633",
      id_estado: "5",
      nm_cidade: "São Domingos",
      nm_cidade_uf: "São Domingos - BA"
  },
  {
      id_cidade: "634",
      id_estado: "5",
      nm_cidade: "São Felipe",
      nm_cidade_uf: "São Felipe - BA"
  },
  {
      id_cidade: "635",
      id_estado: "5",
      nm_cidade: "São Félix",
      nm_cidade_uf: "São Félix - BA"
  },
  {
      id_cidade: "636",
      id_estado: "5",
      nm_cidade: "São Félix do Coribe",
      nm_cidade_uf: "São Félix do Coribe - BA"
  },
  {
      id_cidade: "637",
      id_estado: "5",
      nm_cidade: "São Francisco do Conde",
      nm_cidade_uf: "São Francisco do Conde - BA"
  },
  {
      id_cidade: "638",
      id_estado: "5",
      nm_cidade: "São Gabriel",
      nm_cidade_uf: "São Gabriel - BA"
  },
  {
      id_cidade: "639",
      id_estado: "5",
      nm_cidade: "São Gonçalo dos Campos",
      nm_cidade_uf: "São Gonçalo dos Campos - BA"
  },
  {
      id_cidade: "640",
      id_estado: "5",
      nm_cidade: "São José da Vitória",
      nm_cidade_uf: "São José da Vitória - BA"
  },
  {
      id_cidade: "641",
      id_estado: "5",
      nm_cidade: "São José do Jacuípe",
      nm_cidade_uf: "São José do Jacuípe - BA"
  },
  {
      id_cidade: "642",
      id_estado: "5",
      nm_cidade: "São Miguel das Matas",
      nm_cidade_uf: "São Miguel das Matas - BA"
  },
  {
      id_cidade: "643",
      id_estado: "5",
      nm_cidade: "São Sebastião do Passé",
      nm_cidade_uf: "São Sebastião do Passé - BA"
  },
  {
      id_cidade: "644",
      id_estado: "5",
      nm_cidade: "Sapeaçu",
      nm_cidade_uf: "Sapeaçu - BA"
  },
  {
      id_cidade: "645",
      id_estado: "5",
      nm_cidade: "Sátiro Dias",
      nm_cidade_uf: "Sátiro Dias - BA"
  },
  {
      id_cidade: "646",
      id_estado: "5",
      nm_cidade: "Saubara",
      nm_cidade_uf: "Saubara - BA"
  },
  {
      id_cidade: "647",
      id_estado: "5",
      nm_cidade: "Saúde",
      nm_cidade_uf: "Saúde - BA"
  },
  {
      id_cidade: "648",
      id_estado: "5",
      nm_cidade: "Seabra",
      nm_cidade_uf: "Seabra - BA"
  },
  {
      id_cidade: "649",
      id_estado: "5",
      nm_cidade: "Sebastião Laranjeiras",
      nm_cidade_uf: "Sebastião Laranjeiras - BA"
  },
  {
      id_cidade: "650",
      id_estado: "5",
      nm_cidade: "Senhor do Bonfim",
      nm_cidade_uf: "Senhor do Bonfim - BA"
  },
  {
      id_cidade: "651",
      id_estado: "5",
      nm_cidade: "Sento Sé",
      nm_cidade_uf: "Sento Sé - BA"
  },
  {
      id_cidade: "652",
      id_estado: "5",
      nm_cidade: "Serra do Ramalho",
      nm_cidade_uf: "Serra do Ramalho - BA"
  },
  {
      id_cidade: "653",
      id_estado: "5",
      nm_cidade: "Serra Dourada",
      nm_cidade_uf: "Serra Dourada - BA"
  },
  {
      id_cidade: "654",
      id_estado: "5",
      nm_cidade: "Serra Preta",
      nm_cidade_uf: "Serra Preta - BA"
  },
  {
      id_cidade: "655",
      id_estado: "5",
      nm_cidade: "Serrinha",
      nm_cidade_uf: "Serrinha - BA"
  },
  {
      id_cidade: "656",
      id_estado: "5",
      nm_cidade: "Serrolândia",
      nm_cidade_uf: "Serrolândia - BA"
  },
  {
      id_cidade: "657",
      id_estado: "5",
      nm_cidade: "Simões Filho",
      nm_cidade_uf: "Simões Filho - BA"
  },
  {
      id_cidade: "658",
      id_estado: "5",
      nm_cidade: "Sítio do Mato",
      nm_cidade_uf: "Sítio do Mato - BA"
  },
  {
      id_cidade: "659",
      id_estado: "5",
      nm_cidade: "Sítio do Quinto",
      nm_cidade_uf: "Sítio do Quinto - BA"
  },
  {
      id_cidade: "660",
      id_estado: "5",
      nm_cidade: "Sobradinho",
      nm_cidade_uf: "Sobradinho - BA"
  },
  {
      id_cidade: "661",
      id_estado: "5",
      nm_cidade: "Souto Soares",
      nm_cidade_uf: "Souto Soares - BA"
  },
  {
      id_cidade: "662",
      id_estado: "5",
      nm_cidade: "Tabocas do Brejo Velho",
      nm_cidade_uf: "Tabocas do Brejo Velho - BA"
  },
  {
      id_cidade: "663",
      id_estado: "5",
      nm_cidade: "Tanhaçu",
      nm_cidade_uf: "Tanhaçu - BA"
  },
  {
      id_cidade: "664",
      id_estado: "5",
      nm_cidade: "Tanque Novo",
      nm_cidade_uf: "Tanque Novo - BA"
  },
  {
      id_cidade: "665",
      id_estado: "5",
      nm_cidade: "Tanquinho",
      nm_cidade_uf: "Tanquinho - BA"
  },
  {
      id_cidade: "666",
      id_estado: "5",
      nm_cidade: "Taperoá",
      nm_cidade_uf: "Taperoá - BA"
  },
  {
      id_cidade: "667",
      id_estado: "5",
      nm_cidade: "Tapiramutá",
      nm_cidade_uf: "Tapiramutá - BA"
  },
  {
      id_cidade: "668",
      id_estado: "5",
      nm_cidade: "Teixeira de Freitas",
      nm_cidade_uf: "Teixeira de Freitas - BA"
  },
  {
      id_cidade: "669",
      id_estado: "5",
      nm_cidade: "Teodoro Sampaio",
      nm_cidade_uf: "Teodoro Sampaio - BA"
  },
  {
      id_cidade: "670",
      id_estado: "5",
      nm_cidade: "Teofilândia",
      nm_cidade_uf: "Teofilândia - BA"
  },
  {
      id_cidade: "671",
      id_estado: "5",
      nm_cidade: "Teolândia",
      nm_cidade_uf: "Teolândia - BA"
  },
  {
      id_cidade: "672",
      id_estado: "5",
      nm_cidade: "Terra Nova",
      nm_cidade_uf: "Terra Nova - BA"
  },
  {
      id_cidade: "673",
      id_estado: "5",
      nm_cidade: "Tremedal",
      nm_cidade_uf: "Tremedal - BA"
  },
  {
      id_cidade: "674",
      id_estado: "5",
      nm_cidade: "Tucano",
      nm_cidade_uf: "Tucano - BA"
  },
  {
      id_cidade: "675",
      id_estado: "5",
      nm_cidade: "Uauá",
      nm_cidade_uf: "Uauá - BA"
  },
  {
      id_cidade: "676",
      id_estado: "5",
      nm_cidade: "Ubaíra",
      nm_cidade_uf: "Ubaíra - BA"
  },
  {
      id_cidade: "677",
      id_estado: "5",
      nm_cidade: "Ubaitaba",
      nm_cidade_uf: "Ubaitaba - BA"
  },
  {
      id_cidade: "678",
      id_estado: "5",
      nm_cidade: "Ubatã",
      nm_cidade_uf: "Ubatã - BA"
  },
  {
      id_cidade: "679",
      id_estado: "5",
      nm_cidade: "Uibaí",
      nm_cidade_uf: "Uibaí - BA"
  },
  {
      id_cidade: "680",
      id_estado: "5",
      nm_cidade: "Umburanas",
      nm_cidade_uf: "Umburanas - BA"
  },
  {
      id_cidade: "681",
      id_estado: "5",
      nm_cidade: "Una",
      nm_cidade_uf: "Una - BA"
  },
  {
      id_cidade: "682",
      id_estado: "5",
      nm_cidade: "Urandi",
      nm_cidade_uf: "Urandi - BA"
  },
  {
      id_cidade: "683",
      id_estado: "5",
      nm_cidade: "Uruçuca",
      nm_cidade_uf: "Uruçuca - BA"
  },
  {
      id_cidade: "684",
      id_estado: "5",
      nm_cidade: "Utinga",
      nm_cidade_uf: "Utinga - BA"
  },
  {
      id_cidade: "685",
      id_estado: "5",
      nm_cidade: "Valença",
      nm_cidade_uf: "Valença - BA"
  },
  {
      id_cidade: "686",
      id_estado: "5",
      nm_cidade: "Valente",
      nm_cidade_uf: "Valente - BA"
  },
  {
      id_cidade: "687",
      id_estado: "5",
      nm_cidade: "Várzea da Roça",
      nm_cidade_uf: "Várzea da Roça - BA"
  },
  {
      id_cidade: "688",
      id_estado: "5",
      nm_cidade: "Várzea do Poço",
      nm_cidade_uf: "Várzea do Poço - BA"
  },
  {
      id_cidade: "689",
      id_estado: "5",
      nm_cidade: "Várzea Nova",
      nm_cidade_uf: "Várzea Nova - BA"
  },
  {
      id_cidade: "690",
      id_estado: "5",
      nm_cidade: "Varzedo",
      nm_cidade_uf: "Varzedo - BA"
  },
  {
      id_cidade: "691",
      id_estado: "5",
      nm_cidade: "Vera Cruz",
      nm_cidade_uf: "Vera Cruz - BA"
  },
  {
      id_cidade: "692",
      id_estado: "5",
      nm_cidade: "Vereda",
      nm_cidade_uf: "Vereda - BA"
  },
  {
      id_cidade: "693",
      id_estado: "5",
      nm_cidade: "Vitória da Conquista",
      nm_cidade_uf: "Vitória da Conquista - BA"
  },
  {
      id_cidade: "694",
      id_estado: "5",
      nm_cidade: "Wagner",
      nm_cidade_uf: "Wagner - BA"
  },
  {
      id_cidade: "695",
      id_estado: "5",
      nm_cidade: "Wanderley",
      nm_cidade_uf: "Wanderley - BA"
  },
  {
      id_cidade: "696",
      id_estado: "5",
      nm_cidade: "Wenceslau Guimarães",
      nm_cidade_uf: "Wenceslau Guimarães - BA"
  },
  {
      id_cidade: "697",
      id_estado: "5",
      nm_cidade: "Xique-Xique",
      nm_cidade_uf: "Xique-Xique - BA"
  },
  {
      id_cidade: "698",
      id_estado: "6",
      nm_cidade: "Abaiara",
      nm_cidade_uf: "Abaiara - CE"
  },
  {
      id_cidade: "699",
      id_estado: "6",
      nm_cidade: "Acarape",
      nm_cidade_uf: "Acarape - CE"
  },
  {
      id_cidade: "700",
      id_estado: "6",
      nm_cidade: "Acaraú",
      nm_cidade_uf: "Acaraú - CE"
  },
  {
      id_cidade: "701",
      id_estado: "6",
      nm_cidade: "Acopiara",
      nm_cidade_uf: "Acopiara - CE"
  },
  {
      id_cidade: "702",
      id_estado: "6",
      nm_cidade: "Aiuaba",
      nm_cidade_uf: "Aiuaba - CE"
  },
  {
      id_cidade: "703",
      id_estado: "6",
      nm_cidade: "Alcântaras",
      nm_cidade_uf: "Alcântaras - CE"
  },
  {
      id_cidade: "704",
      id_estado: "6",
      nm_cidade: "Altaneira",
      nm_cidade_uf: "Altaneira - CE"
  },
  {
      id_cidade: "705",
      id_estado: "6",
      nm_cidade: "Alto Santo",
      nm_cidade_uf: "Alto Santo - CE"
  },
  {
      id_cidade: "706",
      id_estado: "6",
      nm_cidade: "Amontada",
      nm_cidade_uf: "Amontada - CE"
  },
  {
      id_cidade: "707",
      id_estado: "6",
      nm_cidade: "Antonina do Norte",
      nm_cidade_uf: "Antonina do Norte - CE"
  },
  {
      id_cidade: "708",
      id_estado: "6",
      nm_cidade: "Apuiarés",
      nm_cidade_uf: "Apuiarés - CE"
  },
  {
      id_cidade: "709",
      id_estado: "6",
      nm_cidade: "Aquiraz",
      nm_cidade_uf: "Aquiraz - CE"
  },
  {
      id_cidade: "710",
      id_estado: "6",
      nm_cidade: "Aracati",
      nm_cidade_uf: "Aracati - CE"
  },
  {
      id_cidade: "711",
      id_estado: "6",
      nm_cidade: "Aracoiaba",
      nm_cidade_uf: "Aracoiaba - CE"
  },
  {
      id_cidade: "712",
      id_estado: "6",
      nm_cidade: "Ararendá",
      nm_cidade_uf: "Ararendá - CE"
  },
  {
      id_cidade: "713",
      id_estado: "6",
      nm_cidade: "Araripe",
      nm_cidade_uf: "Araripe - CE"
  },
  {
      id_cidade: "714",
      id_estado: "6",
      nm_cidade: "Aratuba",
      nm_cidade_uf: "Aratuba - CE"
  },
  {
      id_cidade: "715",
      id_estado: "6",
      nm_cidade: "Arneiroz",
      nm_cidade_uf: "Arneiroz - CE"
  },
  {
      id_cidade: "716",
      id_estado: "6",
      nm_cidade: "Assaré",
      nm_cidade_uf: "Assaré - CE"
  },
  {
      id_cidade: "717",
      id_estado: "6",
      nm_cidade: "Aurora",
      nm_cidade_uf: "Aurora - CE"
  },
  {
      id_cidade: "718",
      id_estado: "6",
      nm_cidade: "Baixio",
      nm_cidade_uf: "Baixio - CE"
  },
  {
      id_cidade: "719",
      id_estado: "6",
      nm_cidade: "Banabuiú",
      nm_cidade_uf: "Banabuiú - CE"
  },
  {
      id_cidade: "720",
      id_estado: "6",
      nm_cidade: "Barbalha",
      nm_cidade_uf: "Barbalha - CE"
  },
  {
      id_cidade: "721",
      id_estado: "6",
      nm_cidade: "Barreira",
      nm_cidade_uf: "Barreira - CE"
  },
  {
      id_cidade: "722",
      id_estado: "6",
      nm_cidade: "Barro",
      nm_cidade_uf: "Barro - CE"
  },
  {
      id_cidade: "723",
      id_estado: "6",
      nm_cidade: "Barroquinha",
      nm_cidade_uf: "Barroquinha - CE"
  },
  {
      id_cidade: "724",
      id_estado: "6",
      nm_cidade: "Baturité",
      nm_cidade_uf: "Baturité - CE"
  },
  {
      id_cidade: "725",
      id_estado: "6",
      nm_cidade: "Beberibe",
      nm_cidade_uf: "Beberibe - CE"
  },
  {
      id_cidade: "726",
      id_estado: "6",
      nm_cidade: "Bela Cruz",
      nm_cidade_uf: "Bela Cruz - CE"
  },
  {
      id_cidade: "727",
      id_estado: "6",
      nm_cidade: "Boa Viagem",
      nm_cidade_uf: "Boa Viagem - CE"
  },
  {
      id_cidade: "728",
      id_estado: "6",
      nm_cidade: "Brejo Santo",
      nm_cidade_uf: "Brejo Santo - CE"
  },
  {
      id_cidade: "729",
      id_estado: "6",
      nm_cidade: "Camocim",
      nm_cidade_uf: "Camocim - CE"
  },
  {
      id_cidade: "730",
      id_estado: "6",
      nm_cidade: "Campos Sales",
      nm_cidade_uf: "Campos Sales - CE"
  },
  {
      id_cidade: "731",
      id_estado: "6",
      nm_cidade: "Canindé",
      nm_cidade_uf: "Canindé - CE"
  },
  {
      id_cidade: "732",
      id_estado: "6",
      nm_cidade: "Capistrano",
      nm_cidade_uf: "Capistrano - CE"
  },
  {
      id_cidade: "733",
      id_estado: "6",
      nm_cidade: "Caridade",
      nm_cidade_uf: "Caridade - CE"
  },
  {
      id_cidade: "734",
      id_estado: "6",
      nm_cidade: "Cariré",
      nm_cidade_uf: "Cariré - CE"
  },
  {
      id_cidade: "735",
      id_estado: "6",
      nm_cidade: "Caririaçu",
      nm_cidade_uf: "Caririaçu - CE"
  },
  {
      id_cidade: "736",
      id_estado: "6",
      nm_cidade: "Cariús",
      nm_cidade_uf: "Cariús - CE"
  },
  {
      id_cidade: "737",
      id_estado: "6",
      nm_cidade: "Carnaubal",
      nm_cidade_uf: "Carnaubal - CE"
  },
  {
      id_cidade: "738",
      id_estado: "6",
      nm_cidade: "Cascavel",
      nm_cidade_uf: "Cascavel - CE"
  },
  {
      id_cidade: "739",
      id_estado: "6",
      nm_cidade: "Catarina",
      nm_cidade_uf: "Catarina - CE"
  },
  {
      id_cidade: "740",
      id_estado: "6",
      nm_cidade: "Catunda",
      nm_cidade_uf: "Catunda - CE"
  },
  {
      id_cidade: "741",
      id_estado: "6",
      nm_cidade: "Caucaia",
      nm_cidade_uf: "Caucaia - CE"
  },
  {
      id_cidade: "742",
      id_estado: "6",
      nm_cidade: "Cedro",
      nm_cidade_uf: "Cedro - CE"
  },
  {
      id_cidade: "743",
      id_estado: "6",
      nm_cidade: "Chaval",
      nm_cidade_uf: "Chaval - CE"
  },
  {
      id_cidade: "744",
      id_estado: "6",
      nm_cidade: "Choró",
      nm_cidade_uf: "Choró - CE"
  },
  {
      id_cidade: "745",
      id_estado: "6",
      nm_cidade: "Chorozinho",
      nm_cidade_uf: "Chorozinho - CE"
  },
  {
      id_cidade: "746",
      id_estado: "6",
      nm_cidade: "Coreaú",
      nm_cidade_uf: "Coreaú - CE"
  },
  {
      id_cidade: "747",
      id_estado: "6",
      nm_cidade: "Crateús",
      nm_cidade_uf: "Crateús - CE"
  },
  {
      id_cidade: "748",
      id_estado: "6",
      nm_cidade: "Crato",
      nm_cidade_uf: "Crato - CE"
  },
  {
      id_cidade: "749",
      id_estado: "6",
      nm_cidade: "Croatá",
      nm_cidade_uf: "Croatá - CE"
  },
  {
      id_cidade: "750",
      id_estado: "6",
      nm_cidade: "Cruz",
      nm_cidade_uf: "Cruz - CE"
  },
  {
      id_cidade: "751",
      id_estado: "6",
      nm_cidade: "Deputado Irapuan Pinheiro",
      nm_cidade_uf: "Deputado Irapuan Pinheiro - CE"
  },
  {
      id_cidade: "752",
      id_estado: "6",
      nm_cidade: "Ererê",
      nm_cidade_uf: "Ererê - CE"
  },
  {
      id_cidade: "753",
      id_estado: "6",
      nm_cidade: "Eusébio",
      nm_cidade_uf: "Eusébio - CE"
  },
  {
      id_cidade: "754",
      id_estado: "6",
      nm_cidade: "Farias Brito",
      nm_cidade_uf: "Farias Brito - CE"
  },
  {
      id_cidade: "755",
      id_estado: "6",
      nm_cidade: "Forquilha",
      nm_cidade_uf: "Forquilha - CE"
  },
  {
      id_cidade: "756",
      id_estado: "6",
      nm_cidade: "Fortaleza",
      nm_cidade_uf: "Fortaleza - CE"
  },
  {
      id_cidade: "757",
      id_estado: "6",
      nm_cidade: "Fortim",
      nm_cidade_uf: "Fortim - CE"
  },
  {
      id_cidade: "758",
      id_estado: "6",
      nm_cidade: "Frecheirinha",
      nm_cidade_uf: "Frecheirinha - CE"
  },
  {
      id_cidade: "759",
      id_estado: "6",
      nm_cidade: "General Sampaio",
      nm_cidade_uf: "General Sampaio - CE"
  },
  {
      id_cidade: "760",
      id_estado: "6",
      nm_cidade: "Graça",
      nm_cidade_uf: "Graça - CE"
  },
  {
      id_cidade: "761",
      id_estado: "6",
      nm_cidade: "Granja",
      nm_cidade_uf: "Granja - CE"
  },
  {
      id_cidade: "762",
      id_estado: "6",
      nm_cidade: "Granjeiro",
      nm_cidade_uf: "Granjeiro - CE"
  },
  {
      id_cidade: "763",
      id_estado: "6",
      nm_cidade: "Groaíras",
      nm_cidade_uf: "Groaíras - CE"
  },
  {
      id_cidade: "764",
      id_estado: "6",
      nm_cidade: "Guaiúba",
      nm_cidade_uf: "Guaiúba - CE"
  },
  {
      id_cidade: "765",
      id_estado: "6",
      nm_cidade: "Guaraciaba do Norte",
      nm_cidade_uf: "Guaraciaba do Norte - CE"
  },
  {
      id_cidade: "766",
      id_estado: "6",
      nm_cidade: "Guaramiranga",
      nm_cidade_uf: "Guaramiranga - CE"
  },
  {
      id_cidade: "767",
      id_estado: "6",
      nm_cidade: "Hidrolândia",
      nm_cidade_uf: "Hidrolândia - CE"
  },
  {
      id_cidade: "768",
      id_estado: "6",
      nm_cidade: "Horizonte",
      nm_cidade_uf: "Horizonte - CE"
  },
  {
      id_cidade: "769",
      id_estado: "6",
      nm_cidade: "Ibaretama",
      nm_cidade_uf: "Ibaretama - CE"
  },
  {
      id_cidade: "770",
      id_estado: "6",
      nm_cidade: "Ibiapina",
      nm_cidade_uf: "Ibiapina - CE"
  },
  {
      id_cidade: "771",
      id_estado: "6",
      nm_cidade: "Ibicuitinga",
      nm_cidade_uf: "Ibicuitinga - CE"
  },
  {
      id_cidade: "772",
      id_estado: "6",
      nm_cidade: "Icapuí",
      nm_cidade_uf: "Icapuí - CE"
  },
  {
      id_cidade: "773",
      id_estado: "6",
      nm_cidade: "Icó",
      nm_cidade_uf: "Icó - CE"
  },
  {
      id_cidade: "774",
      id_estado: "6",
      nm_cidade: "Iguatu",
      nm_cidade_uf: "Iguatu - CE"
  },
  {
      id_cidade: "775",
      id_estado: "6",
      nm_cidade: "Independência",
      nm_cidade_uf: "Independência - CE"
  },
  {
      id_cidade: "776",
      id_estado: "6",
      nm_cidade: "Ipaporanga",
      nm_cidade_uf: "Ipaporanga - CE"
  },
  {
      id_cidade: "777",
      id_estado: "6",
      nm_cidade: "Ipaumirim",
      nm_cidade_uf: "Ipaumirim - CE"
  },
  {
      id_cidade: "778",
      id_estado: "6",
      nm_cidade: "Ipu",
      nm_cidade_uf: "Ipu - CE"
  },
  {
      id_cidade: "779",
      id_estado: "6",
      nm_cidade: "Ipueiras",
      nm_cidade_uf: "Ipueiras - CE"
  },
  {
      id_cidade: "780",
      id_estado: "6",
      nm_cidade: "Iracema",
      nm_cidade_uf: "Iracema - CE"
  },
  {
      id_cidade: "781",
      id_estado: "6",
      nm_cidade: "Irauçuba",
      nm_cidade_uf: "Irauçuba - CE"
  },
  {
      id_cidade: "782",
      id_estado: "6",
      nm_cidade: "Itaiçaba",
      nm_cidade_uf: "Itaiçaba - CE"
  },
  {
      id_cidade: "783",
      id_estado: "6",
      nm_cidade: "Itaitinga",
      nm_cidade_uf: "Itaitinga - CE"
  },
  {
      id_cidade: "784",
      id_estado: "6",
      nm_cidade: "Itapagé",
      nm_cidade_uf: "Itapagé - CE"
  },
  {
      id_cidade: "785",
      id_estado: "6",
      nm_cidade: "Itapipoca",
      nm_cidade_uf: "Itapipoca - CE"
  },
  {
      id_cidade: "786",
      id_estado: "6",
      nm_cidade: "Itapiúna",
      nm_cidade_uf: "Itapiúna - CE"
  },
  {
      id_cidade: "787",
      id_estado: "6",
      nm_cidade: "Itarema",
      nm_cidade_uf: "Itarema - CE"
  },
  {
      id_cidade: "788",
      id_estado: "6",
      nm_cidade: "Itatira",
      nm_cidade_uf: "Itatira - CE"
  },
  {
      id_cidade: "789",
      id_estado: "6",
      nm_cidade: "Jaguaretama",
      nm_cidade_uf: "Jaguaretama - CE"
  },
  {
      id_cidade: "790",
      id_estado: "6",
      nm_cidade: "Jaguaribara",
      nm_cidade_uf: "Jaguaribara - CE"
  },
  {
      id_cidade: "791",
      id_estado: "6",
      nm_cidade: "Jaguaribe",
      nm_cidade_uf: "Jaguaribe - CE"
  },
  {
      id_cidade: "792",
      id_estado: "6",
      nm_cidade: "Jaguaruana",
      nm_cidade_uf: "Jaguaruana - CE"
  },
  {
      id_cidade: "793",
      id_estado: "6",
      nm_cidade: "Jardim",
      nm_cidade_uf: "Jardim - CE"
  },
  {
      id_cidade: "794",
      id_estado: "6",
      nm_cidade: "Jati",
      nm_cidade_uf: "Jati - CE"
  },
  {
      id_cidade: "795",
      id_estado: "6",
      nm_cidade: "Jijoca de Jericoacoara",
      nm_cidade_uf: "Jijoca de Jericoacoara - CE"
  },
  {
      id_cidade: "796",
      id_estado: "6",
      nm_cidade: "Juazeiro do Norte",
      nm_cidade_uf: "Juazeiro do Norte - CE"
  },
  {
      id_cidade: "797",
      id_estado: "6",
      nm_cidade: "Jucás",
      nm_cidade_uf: "Jucás - CE"
  },
  {
      id_cidade: "798",
      id_estado: "6",
      nm_cidade: "Lavras da Mangabeira",
      nm_cidade_uf: "Lavras da Mangabeira - CE"
  },
  {
      id_cidade: "799",
      id_estado: "6",
      nm_cidade: "Limoeiro do Norte",
      nm_cidade_uf: "Limoeiro do Norte - CE"
  },
  {
      id_cidade: "800",
      id_estado: "6",
      nm_cidade: "Madalena",
      nm_cidade_uf: "Madalena - CE"
  },
  {
      id_cidade: "801",
      id_estado: "6",
      nm_cidade: "Maracanaú",
      nm_cidade_uf: "Maracanaú - CE"
  },
  {
      id_cidade: "802",
      id_estado: "6",
      nm_cidade: "Maranguape",
      nm_cidade_uf: "Maranguape - CE"
  },
  {
      id_cidade: "803",
      id_estado: "6",
      nm_cidade: "Marco",
      nm_cidade_uf: "Marco - CE"
  },
  {
      id_cidade: "804",
      id_estado: "6",
      nm_cidade: "Martinópole",
      nm_cidade_uf: "Martinópole - CE"
  },
  {
      id_cidade: "805",
      id_estado: "6",
      nm_cidade: "Massapê",
      nm_cidade_uf: "Massapê - CE"
  },
  {
      id_cidade: "806",
      id_estado: "6",
      nm_cidade: "Mauriti",
      nm_cidade_uf: "Mauriti - CE"
  },
  {
      id_cidade: "807",
      id_estado: "6",
      nm_cidade: "Meruoca",
      nm_cidade_uf: "Meruoca - CE"
  },
  {
      id_cidade: "808",
      id_estado: "6",
      nm_cidade: "Milagres",
      nm_cidade_uf: "Milagres - CE"
  },
  {
      id_cidade: "809",
      id_estado: "6",
      nm_cidade: "Milhã",
      nm_cidade_uf: "Milhã - CE"
  },
  {
      id_cidade: "810",
      id_estado: "6",
      nm_cidade: "Miraíma",
      nm_cidade_uf: "Miraíma - CE"
  },
  {
      id_cidade: "811",
      id_estado: "6",
      nm_cidade: "Missão Velha",
      nm_cidade_uf: "Missão Velha - CE"
  },
  {
      id_cidade: "812",
      id_estado: "6",
      nm_cidade: "Mombaça",
      nm_cidade_uf: "Mombaça - CE"
  },
  {
      id_cidade: "813",
      id_estado: "6",
      nm_cidade: "Monsenhor Tabosa",
      nm_cidade_uf: "Monsenhor Tabosa - CE"
  },
  {
      id_cidade: "814",
      id_estado: "6",
      nm_cidade: "Morada Nova",
      nm_cidade_uf: "Morada Nova - CE"
  },
  {
      id_cidade: "815",
      id_estado: "6",
      nm_cidade: "Moraújo",
      nm_cidade_uf: "Moraújo - CE"
  },
  {
      id_cidade: "816",
      id_estado: "6",
      nm_cidade: "Morrinhos",
      nm_cidade_uf: "Morrinhos - CE"
  },
  {
      id_cidade: "817",
      id_estado: "6",
      nm_cidade: "Mucambo",
      nm_cidade_uf: "Mucambo - CE"
  },
  {
      id_cidade: "818",
      id_estado: "6",
      nm_cidade: "Mulungu",
      nm_cidade_uf: "Mulungu - CE"
  },
  {
      id_cidade: "819",
      id_estado: "6",
      nm_cidade: "Nova Olinda",
      nm_cidade_uf: "Nova Olinda - CE"
  },
  {
      id_cidade: "820",
      id_estado: "6",
      nm_cidade: "Nova Russas",
      nm_cidade_uf: "Nova Russas - CE"
  },
  {
      id_cidade: "821",
      id_estado: "6",
      nm_cidade: "Novo Oriente",
      nm_cidade_uf: "Novo Oriente - CE"
  },
  {
      id_cidade: "822",
      id_estado: "6",
      nm_cidade: "Ocara",
      nm_cidade_uf: "Ocara - CE"
  },
  {
      id_cidade: "823",
      id_estado: "6",
      nm_cidade: "Orós",
      nm_cidade_uf: "Orós - CE"
  },
  {
      id_cidade: "824",
      id_estado: "6",
      nm_cidade: "Pacajus",
      nm_cidade_uf: "Pacajus - CE"
  },
  {
      id_cidade: "825",
      id_estado: "6",
      nm_cidade: "Pacatuba",
      nm_cidade_uf: "Pacatuba - CE"
  },
  {
      id_cidade: "826",
      id_estado: "6",
      nm_cidade: "Pacoti",
      nm_cidade_uf: "Pacoti - CE"
  },
  {
      id_cidade: "827",
      id_estado: "6",
      nm_cidade: "Pacujá",
      nm_cidade_uf: "Pacujá - CE"
  },
  {
      id_cidade: "828",
      id_estado: "6",
      nm_cidade: "Palhano",
      nm_cidade_uf: "Palhano - CE"
  },
  {
      id_cidade: "829",
      id_estado: "6",
      nm_cidade: "Palmácia",
      nm_cidade_uf: "Palmácia - CE"
  },
  {
      id_cidade: "830",
      id_estado: "6",
      nm_cidade: "Paracuru",
      nm_cidade_uf: "Paracuru - CE"
  },
  {
      id_cidade: "831",
      id_estado: "6",
      nm_cidade: "Paraipaba",
      nm_cidade_uf: "Paraipaba - CE"
  },
  {
      id_cidade: "832",
      id_estado: "6",
      nm_cidade: "Parambu",
      nm_cidade_uf: "Parambu - CE"
  },
  {
      id_cidade: "833",
      id_estado: "6",
      nm_cidade: "Paramoti",
      nm_cidade_uf: "Paramoti - CE"
  },
  {
      id_cidade: "834",
      id_estado: "6",
      nm_cidade: "Pedra Branca",
      nm_cidade_uf: "Pedra Branca - CE"
  },
  {
      id_cidade: "835",
      id_estado: "6",
      nm_cidade: "Penaforte",
      nm_cidade_uf: "Penaforte - CE"
  },
  {
      id_cidade: "836",
      id_estado: "6",
      nm_cidade: "Pentecoste",
      nm_cidade_uf: "Pentecoste - CE"
  },
  {
      id_cidade: "837",
      id_estado: "6",
      nm_cidade: "Pereiro",
      nm_cidade_uf: "Pereiro - CE"
  },
  {
      id_cidade: "838",
      id_estado: "6",
      nm_cidade: "Pindoretama",
      nm_cidade_uf: "Pindoretama - CE"
  },
  {
      id_cidade: "839",
      id_estado: "6",
      nm_cidade: "Piquet Carneiro",
      nm_cidade_uf: "Piquet Carneiro - CE"
  },
  {
      id_cidade: "840",
      id_estado: "6",
      nm_cidade: "Pires Ferreira",
      nm_cidade_uf: "Pires Ferreira - CE"
  },
  {
      id_cidade: "841",
      id_estado: "6",
      nm_cidade: "Poranga",
      nm_cidade_uf: "Poranga - CE"
  },
  {
      id_cidade: "842",
      id_estado: "6",
      nm_cidade: "Porteiras",
      nm_cidade_uf: "Porteiras - CE"
  },
  {
      id_cidade: "843",
      id_estado: "6",
      nm_cidade: "Potengi",
      nm_cidade_uf: "Potengi - CE"
  },
  {
      id_cidade: "844",
      id_estado: "6",
      nm_cidade: "Potiretama",
      nm_cidade_uf: "Potiretama - CE"
  },
  {
      id_cidade: "845",
      id_estado: "6",
      nm_cidade: "Quiterianópolis",
      nm_cidade_uf: "Quiterianópolis - CE"
  },
  {
      id_cidade: "846",
      id_estado: "6",
      nm_cidade: "Quixadá",
      nm_cidade_uf: "Quixadá - CE"
  },
  {
      id_cidade: "847",
      id_estado: "6",
      nm_cidade: "Quixelô",
      nm_cidade_uf: "Quixelô - CE"
  },
  {
      id_cidade: "848",
      id_estado: "6",
      nm_cidade: "Quixeramobim",
      nm_cidade_uf: "Quixeramobim - CE"
  },
  {
      id_cidade: "849",
      id_estado: "6",
      nm_cidade: "Quixeré",
      nm_cidade_uf: "Quixeré - CE"
  },
  {
      id_cidade: "850",
      id_estado: "6",
      nm_cidade: "Redenção",
      nm_cidade_uf: "Redenção - CE"
  },
  {
      id_cidade: "851",
      id_estado: "6",
      nm_cidade: "Reriutaba",
      nm_cidade_uf: "Reriutaba - CE"
  },
  {
      id_cidade: "852",
      id_estado: "6",
      nm_cidade: "Russas",
      nm_cidade_uf: "Russas - CE"
  },
  {
      id_cidade: "853",
      id_estado: "6",
      nm_cidade: "Saboeiro",
      nm_cidade_uf: "Saboeiro - CE"
  },
  {
      id_cidade: "854",
      id_estado: "6",
      nm_cidade: "Salitre",
      nm_cidade_uf: "Salitre - CE"
  },
  {
      id_cidade: "855",
      id_estado: "6",
      nm_cidade: "Santa Quitéria",
      nm_cidade_uf: "Santa Quitéria - CE"
  },
  {
      id_cidade: "856",
      id_estado: "6",
      nm_cidade: "Santana do Acaraú",
      nm_cidade_uf: "Santana do Acaraú - CE"
  },
  {
      id_cidade: "857",
      id_estado: "6",
      nm_cidade: "Santana do Cariri",
      nm_cidade_uf: "Santana do Cariri - CE"
  },
  {
      id_cidade: "858",
      id_estado: "6",
      nm_cidade: "São Benedito",
      nm_cidade_uf: "São Benedito - CE"
  },
  {
      id_cidade: "859",
      id_estado: "6",
      nm_cidade: "São Gonçalo do Amarante",
      nm_cidade_uf: "São Gonçalo do Amarante - CE"
  },
  {
      id_cidade: "860",
      id_estado: "6",
      nm_cidade: "São João do Jaguaribe",
      nm_cidade_uf: "São João do Jaguaribe - CE"
  },
  {
      id_cidade: "861",
      id_estado: "6",
      nm_cidade: "São Luís do Curu",
      nm_cidade_uf: "São Luís do Curu - CE"
  },
  {
      id_cidade: "862",
      id_estado: "6",
      nm_cidade: "Senador Pompeu",
      nm_cidade_uf: "Senador Pompeu - CE"
  },
  {
      id_cidade: "863",
      id_estado: "6",
      nm_cidade: "Senador Sá",
      nm_cidade_uf: "Senador Sá - CE"
  },
  {
      id_cidade: "864",
      id_estado: "6",
      nm_cidade: "Sobral",
      nm_cidade_uf: "Sobral - CE"
  },
  {
      id_cidade: "865",
      id_estado: "6",
      nm_cidade: "Solonópole",
      nm_cidade_uf: "Solonópole - CE"
  },
  {
      id_cidade: "866",
      id_estado: "6",
      nm_cidade: "Tabuleiro do Norte",
      nm_cidade_uf: "Tabuleiro do Norte - CE"
  },
  {
      id_cidade: "867",
      id_estado: "6",
      nm_cidade: "Tamboril",
      nm_cidade_uf: "Tamboril - CE"
  },
  {
      id_cidade: "868",
      id_estado: "6",
      nm_cidade: "Tarrafas",
      nm_cidade_uf: "Tarrafas - CE"
  },
  {
      id_cidade: "869",
      id_estado: "6",
      nm_cidade: "Tauá",
      nm_cidade_uf: "Tauá - CE"
  },
  {
      id_cidade: "870",
      id_estado: "6",
      nm_cidade: "Tejuçuoca",
      nm_cidade_uf: "Tejuçuoca - CE"
  },
  {
      id_cidade: "871",
      id_estado: "6",
      nm_cidade: "Tianguá",
      nm_cidade_uf: "Tianguá - CE"
  },
  {
      id_cidade: "872",
      id_estado: "6",
      nm_cidade: "Trairi",
      nm_cidade_uf: "Trairi - CE"
  },
  {
      id_cidade: "873",
      id_estado: "6",
      nm_cidade: "Tururu",
      nm_cidade_uf: "Tururu - CE"
  },
  {
      id_cidade: "874",
      id_estado: "6",
      nm_cidade: "Ubajara",
      nm_cidade_uf: "Ubajara - CE"
  },
  {
      id_cidade: "875",
      id_estado: "6",
      nm_cidade: "Umari",
      nm_cidade_uf: "Umari - CE"
  },
  {
      id_cidade: "876",
      id_estado: "6",
      nm_cidade: "Umirim",
      nm_cidade_uf: "Umirim - CE"
  },
  {
      id_cidade: "877",
      id_estado: "6",
      nm_cidade: "Uruburetama",
      nm_cidade_uf: "Uruburetama - CE"
  },
  {
      id_cidade: "878",
      id_estado: "6",
      nm_cidade: "Uruoca",
      nm_cidade_uf: "Uruoca - CE"
  },
  {
      id_cidade: "879",
      id_estado: "6",
      nm_cidade: "Varjota",
      nm_cidade_uf: "Varjota - CE"
  },
  {
      id_cidade: "880",
      id_estado: "6",
      nm_cidade: "Várzea Alegre",
      nm_cidade_uf: "Várzea Alegre - CE"
  },
  {
      id_cidade: "881",
      id_estado: "6",
      nm_cidade: "Viçosa do Ceará",
      nm_cidade_uf: "Viçosa do Ceará - CE"
  },
  {
      id_cidade: "882",
      id_estado: "7",
      nm_cidade: "Brasília",
      nm_cidade_uf: "Brasília - DF"
  },
  {
      id_cidade: "883",
      id_estado: "9",
      nm_cidade: "Abadia de Goiás",
      nm_cidade_uf: "Abadia de Goiás - GO"
  },
  {
      id_cidade: "884",
      id_estado: "9",
      nm_cidade: "Abadiânia",
      nm_cidade_uf: "Abadiânia - GO"
  },
  {
      id_cidade: "885",
      id_estado: "9",
      nm_cidade: "Acreúna",
      nm_cidade_uf: "Acreúna - GO"
  },
  {
      id_cidade: "886",
      id_estado: "9",
      nm_cidade: "Adelândia",
      nm_cidade_uf: "Adelândia - GO"
  },
  {
      id_cidade: "887",
      id_estado: "9",
      nm_cidade: "Água Fria de Goiás",
      nm_cidade_uf: "Água Fria de Goiás - GO"
  },
  {
      id_cidade: "888",
      id_estado: "9",
      nm_cidade: "Água Limpa",
      nm_cidade_uf: "Água Limpa - GO"
  },
  {
      id_cidade: "889",
      id_estado: "9",
      nm_cidade: "Águas Lindas de Goiás",
      nm_cidade_uf: "Águas Lindas de Goiás - GO"
  },
  {
      id_cidade: "890",
      id_estado: "9",
      nm_cidade: "Alexânia",
      nm_cidade_uf: "Alexânia - GO"
  },
  {
      id_cidade: "891",
      id_estado: "9",
      nm_cidade: "Aloândia",
      nm_cidade_uf: "Aloândia - GO"
  },
  {
      id_cidade: "892",
      id_estado: "9",
      nm_cidade: "Alto Horizonte",
      nm_cidade_uf: "Alto Horizonte - GO"
  },
  {
      id_cidade: "893",
      id_estado: "9",
      nm_cidade: "Alto Paraíso de Goiás",
      nm_cidade_uf: "Alto Paraíso de Goiás - GO"
  },
  {
      id_cidade: "894",
      id_estado: "9",
      nm_cidade: "Alvorada do Norte",
      nm_cidade_uf: "Alvorada do Norte - GO"
  },
  {
      id_cidade: "895",
      id_estado: "9",
      nm_cidade: "Amaralina",
      nm_cidade_uf: "Amaralina - GO"
  },
  {
      id_cidade: "896",
      id_estado: "9",
      nm_cidade: "Americano do Brasil",
      nm_cidade_uf: "Americano do Brasil - GO"
  },
  {
      id_cidade: "897",
      id_estado: "9",
      nm_cidade: "Amorinópolis",
      nm_cidade_uf: "Amorinópolis - GO"
  },
  {
      id_cidade: "898",
      id_estado: "9",
      nm_cidade: "Anápolis",
      nm_cidade_uf: "Anápolis - GO"
  },
  {
      id_cidade: "899",
      id_estado: "9",
      nm_cidade: "Anhanguera",
      nm_cidade_uf: "Anhanguera - GO"
  },
  {
      id_cidade: "900",
      id_estado: "9",
      nm_cidade: "Anicuns",
      nm_cidade_uf: "Anicuns - GO"
  },
  {
      id_cidade: "901",
      id_estado: "9",
      nm_cidade: "Aparecida de Goiânia",
      nm_cidade_uf: "Aparecida de Goiânia - GO"
  },
  {
      id_cidade: "902",
      id_estado: "9",
      nm_cidade: "Aparecida do Rio Doce",
      nm_cidade_uf: "Aparecida do Rio Doce - GO"
  },
  {
      id_cidade: "903",
      id_estado: "9",
      nm_cidade: "Aporé",
      nm_cidade_uf: "Aporé - GO"
  },
  {
      id_cidade: "904",
      id_estado: "9",
      nm_cidade: "Araçu",
      nm_cidade_uf: "Araçu - GO"
  },
  {
      id_cidade: "905",
      id_estado: "9",
      nm_cidade: "Aragarças",
      nm_cidade_uf: "Aragarças - GO"
  },
  {
      id_cidade: "906",
      id_estado: "9",
      nm_cidade: "Aragoiânia",
      nm_cidade_uf: "Aragoiânia - GO"
  },
  {
      id_cidade: "907",
      id_estado: "9",
      nm_cidade: "Araguapaz",
      nm_cidade_uf: "Araguapaz - GO"
  },
  {
      id_cidade: "908",
      id_estado: "9",
      nm_cidade: "Arenópolis",
      nm_cidade_uf: "Arenópolis - GO"
  },
  {
      id_cidade: "909",
      id_estado: "9",
      nm_cidade: "Aruanã",
      nm_cidade_uf: "Aruanã - GO"
  },
  {
      id_cidade: "910",
      id_estado: "9",
      nm_cidade: "Aurilândia",
      nm_cidade_uf: "Aurilândia - GO"
  },
  {
      id_cidade: "911",
      id_estado: "9",
      nm_cidade: "Avelinópolis",
      nm_cidade_uf: "Avelinópolis - GO"
  },
  {
      id_cidade: "912",
      id_estado: "9",
      nm_cidade: "Baliza",
      nm_cidade_uf: "Baliza - GO"
  },
  {
      id_cidade: "913",
      id_estado: "9",
      nm_cidade: "Barro Alto",
      nm_cidade_uf: "Barro Alto - GO"
  },
  {
      id_cidade: "914",
      id_estado: "9",
      nm_cidade: "Bela Vista de Goiás",
      nm_cidade_uf: "Bela Vista de Goiás - GO"
  },
  {
      id_cidade: "915",
      id_estado: "9",
      nm_cidade: "Bom Jardim de Goiás",
      nm_cidade_uf: "Bom Jardim de Goiás - GO"
  },
  {
      id_cidade: "916",
      id_estado: "9",
      nm_cidade: "Bom Jesus de Goiás",
      nm_cidade_uf: "Bom Jesus de Goiás - GO"
  },
  {
      id_cidade: "917",
      id_estado: "9",
      nm_cidade: "Bonfinópolis",
      nm_cidade_uf: "Bonfinópolis - GO"
  },
  {
      id_cidade: "918",
      id_estado: "9",
      nm_cidade: "Bonópolis",
      nm_cidade_uf: "Bonópolis - GO"
  },
  {
      id_cidade: "919",
      id_estado: "9",
      nm_cidade: "Brazabrantes",
      nm_cidade_uf: "Brazabrantes - GO"
  },
  {
      id_cidade: "920",
      id_estado: "9",
      nm_cidade: "Britânia",
      nm_cidade_uf: "Britânia - GO"
  },
  {
      id_cidade: "921",
      id_estado: "9",
      nm_cidade: "Buriti Alegre",
      nm_cidade_uf: "Buriti Alegre - GO"
  },
  {
      id_cidade: "922",
      id_estado: "9",
      nm_cidade: "Buriti de Goiás",
      nm_cidade_uf: "Buriti de Goiás - GO"
  },
  {
      id_cidade: "923",
      id_estado: "9",
      nm_cidade: "Buritinópolis",
      nm_cidade_uf: "Buritinópolis - GO"
  },
  {
      id_cidade: "924",
      id_estado: "9",
      nm_cidade: "Cabeceiras",
      nm_cidade_uf: "Cabeceiras - GO"
  },
  {
      id_cidade: "925",
      id_estado: "9",
      nm_cidade: "Cachoeira Alta",
      nm_cidade_uf: "Cachoeira Alta - GO"
  },
  {
      id_cidade: "926",
      id_estado: "9",
      nm_cidade: "Cachoeira de Goiás",
      nm_cidade_uf: "Cachoeira de Goiás - GO"
  },
  {
      id_cidade: "927",
      id_estado: "9",
      nm_cidade: "Cachoeira Dourada",
      nm_cidade_uf: "Cachoeira Dourada - GO"
  },
  {
      id_cidade: "928",
      id_estado: "9",
      nm_cidade: "Caçu",
      nm_cidade_uf: "Caçu - GO"
  },
  {
      id_cidade: "929",
      id_estado: "9",
      nm_cidade: "Caiapônia",
      nm_cidade_uf: "Caiapônia - GO"
  },
  {
      id_cidade: "930",
      id_estado: "9",
      nm_cidade: "Caldas Novas",
      nm_cidade_uf: "Caldas Novas - GO"
  },
  {
      id_cidade: "931",
      id_estado: "9",
      nm_cidade: "Caldazinha",
      nm_cidade_uf: "Caldazinha - GO"
  },
  {
      id_cidade: "932",
      id_estado: "9",
      nm_cidade: "Campestre de Goiás",
      nm_cidade_uf: "Campestre de Goiás - GO"
  },
  {
      id_cidade: "933",
      id_estado: "9",
      nm_cidade: "Campinaçu",
      nm_cidade_uf: "Campinaçu - GO"
  },
  {
      id_cidade: "934",
      id_estado: "9",
      nm_cidade: "Campinorte",
      nm_cidade_uf: "Campinorte - GO"
  },
  {
      id_cidade: "935",
      id_estado: "9",
      nm_cidade: "Campo Alegre de Goiás",
      nm_cidade_uf: "Campo Alegre de Goiás - GO"
  },
  {
      id_cidade: "936",
      id_estado: "9",
      nm_cidade: "Campo Limpo de Goiás",
      nm_cidade_uf: "Campo Limpo de Goiás - GO"
  },
  {
      id_cidade: "937",
      id_estado: "9",
      nm_cidade: "Campos Belos",
      nm_cidade_uf: "Campos Belos - GO"
  },
  {
      id_cidade: "938",
      id_estado: "9",
      nm_cidade: "Campos Verdes",
      nm_cidade_uf: "Campos Verdes - GO"
  },
  {
      id_cidade: "939",
      id_estado: "9",
      nm_cidade: "Carmo do Rio Verde",
      nm_cidade_uf: "Carmo do Rio Verde - GO"
  },
  {
      id_cidade: "940",
      id_estado: "9",
      nm_cidade: "Castelândia",
      nm_cidade_uf: "Castelândia - GO"
  },
  {
      id_cidade: "941",
      id_estado: "9",
      nm_cidade: "Catalão",
      nm_cidade_uf: "Catalão - GO"
  },
  {
      id_cidade: "942",
      id_estado: "9",
      nm_cidade: "Caturaí",
      nm_cidade_uf: "Caturaí - GO"
  },
  {
      id_cidade: "943",
      id_estado: "9",
      nm_cidade: "Cavalcante",
      nm_cidade_uf: "Cavalcante - GO"
  },
  {
      id_cidade: "944",
      id_estado: "9",
      nm_cidade: "Ceres",
      nm_cidade_uf: "Ceres - GO"
  },
  {
      id_cidade: "945",
      id_estado: "9",
      nm_cidade: "Cezarina",
      nm_cidade_uf: "Cezarina - GO"
  },
  {
      id_cidade: "946",
      id_estado: "9",
      nm_cidade: "Chapadão do Céu",
      nm_cidade_uf: "Chapadão do Céu - GO"
  },
  {
      id_cidade: "947",
      id_estado: "9",
      nm_cidade: "Cidade Ocidental",
      nm_cidade_uf: "Cidade Ocidental - GO"
  },
  {
      id_cidade: "948",
      id_estado: "9",
      nm_cidade: "Cocalzinho de Goiás",
      nm_cidade_uf: "Cocalzinho de Goiás - GO"
  },
  {
      id_cidade: "949",
      id_estado: "9",
      nm_cidade: "Colinas do Sul",
      nm_cidade_uf: "Colinas do Sul - GO"
  },
  {
      id_cidade: "950",
      id_estado: "9",
      nm_cidade: "Córrego do Ouro",
      nm_cidade_uf: "Córrego do Ouro - GO"
  },
  {
      id_cidade: "951",
      id_estado: "9",
      nm_cidade: "Corumbá de Goiás",
      nm_cidade_uf: "Corumbá de Goiás - GO"
  },
  {
      id_cidade: "952",
      id_estado: "9",
      nm_cidade: "Corumbaíba",
      nm_cidade_uf: "Corumbaíba - GO"
  },
  {
      id_cidade: "953",
      id_estado: "9",
      nm_cidade: "Cristalina",
      nm_cidade_uf: "Cristalina - GO"
  },
  {
      id_cidade: "954",
      id_estado: "9",
      nm_cidade: "Cristianópolis",
      nm_cidade_uf: "Cristianópolis - GO"
  },
  {
      id_cidade: "955",
      id_estado: "9",
      nm_cidade: "Crixás",
      nm_cidade_uf: "Crixás - GO"
  },
  {
      id_cidade: "956",
      id_estado: "9",
      nm_cidade: "Cromínia",
      nm_cidade_uf: "Cromínia - GO"
  },
  {
      id_cidade: "957",
      id_estado: "9",
      nm_cidade: "Cumari",
      nm_cidade_uf: "Cumari - GO"
  },
  {
      id_cidade: "958",
      id_estado: "9",
      nm_cidade: "Damianópolis",
      nm_cidade_uf: "Damianópolis - GO"
  },
  {
      id_cidade: "959",
      id_estado: "9",
      nm_cidade: "Damolândia",
      nm_cidade_uf: "Damolândia - GO"
  },
  {
      id_cidade: "960",
      id_estado: "9",
      nm_cidade: "Davinópolis",
      nm_cidade_uf: "Davinópolis - GO"
  },
  {
      id_cidade: "961",
      id_estado: "9",
      nm_cidade: "Diorama",
      nm_cidade_uf: "Diorama - GO"
  },
  {
      id_cidade: "962",
      id_estado: "9",
      nm_cidade: "Divinópolis de Goiás",
      nm_cidade_uf: "Divinópolis de Goiás - GO"
  },
  {
      id_cidade: "963",
      id_estado: "9",
      nm_cidade: "Doverlândia",
      nm_cidade_uf: "Doverlândia - GO"
  },
  {
      id_cidade: "964",
      id_estado: "9",
      nm_cidade: "Edealina",
      nm_cidade_uf: "Edealina - GO"
  },
  {
      id_cidade: "965",
      id_estado: "9",
      nm_cidade: "Edéia",
      nm_cidade_uf: "Edéia - GO"
  },
  {
      id_cidade: "966",
      id_estado: "9",
      nm_cidade: "Estrela do Norte",
      nm_cidade_uf: "Estrela do Norte - GO"
  },
  {
      id_cidade: "967",
      id_estado: "9",
      nm_cidade: "Faina",
      nm_cidade_uf: "Faina - GO"
  },
  {
      id_cidade: "968",
      id_estado: "9",
      nm_cidade: "Fazenda Nova",
      nm_cidade_uf: "Fazenda Nova - GO"
  },
  {
      id_cidade: "969",
      id_estado: "9",
      nm_cidade: "Firminópolis",
      nm_cidade_uf: "Firminópolis - GO"
  },
  {
      id_cidade: "970",
      id_estado: "9",
      nm_cidade: "Flores de Goiás",
      nm_cidade_uf: "Flores de Goiás - GO"
  },
  {
      id_cidade: "971",
      id_estado: "9",
      nm_cidade: "Formosa",
      nm_cidade_uf: "Formosa - GO"
  },
  {
      id_cidade: "972",
      id_estado: "9",
      nm_cidade: "Formoso",
      nm_cidade_uf: "Formoso - GO"
  },
  {
      id_cidade: "973",
      id_estado: "9",
      nm_cidade: "Gameleira de Goiás",
      nm_cidade_uf: "Gameleira de Goiás - GO"
  },
  {
      id_cidade: "974",
      id_estado: "9",
      nm_cidade: "Goianápolis",
      nm_cidade_uf: "Goianápolis - GO"
  },
  {
      id_cidade: "975",
      id_estado: "9",
      nm_cidade: "Goiandira",
      nm_cidade_uf: "Goiandira - GO"
  },
  {
      id_cidade: "976",
      id_estado: "9",
      nm_cidade: "Goianésia",
      nm_cidade_uf: "Goianésia - GO"
  },
  {
      id_cidade: "977",
      id_estado: "9",
      nm_cidade: "Goiânia",
      nm_cidade_uf: "Goiânia - GO"
  },
  {
      id_cidade: "978",
      id_estado: "9",
      nm_cidade: "Goianira",
      nm_cidade_uf: "Goianira - GO"
  },
  {
      id_cidade: "979",
      id_estado: "9",
      nm_cidade: "Goiás",
      nm_cidade_uf: "Goiás - GO"
  },
  {
      id_cidade: "980",
      id_estado: "9",
      nm_cidade: "Goiatuba",
      nm_cidade_uf: "Goiatuba - GO"
  },
  {
      id_cidade: "981",
      id_estado: "9",
      nm_cidade: "Gouvelândia",
      nm_cidade_uf: "Gouvelândia - GO"
  },
  {
      id_cidade: "982",
      id_estado: "9",
      nm_cidade: "Guapó",
      nm_cidade_uf: "Guapó - GO"
  },
  {
      id_cidade: "983",
      id_estado: "9",
      nm_cidade: "Guaraíta",
      nm_cidade_uf: "Guaraíta - GO"
  },
  {
      id_cidade: "984",
      id_estado: "9",
      nm_cidade: "Guarani de Goiás",
      nm_cidade_uf: "Guarani de Goiás - GO"
  },
  {
      id_cidade: "985",
      id_estado: "9",
      nm_cidade: "Guarinos",
      nm_cidade_uf: "Guarinos - GO"
  },
  {
      id_cidade: "986",
      id_estado: "9",
      nm_cidade: "Heitoraí",
      nm_cidade_uf: "Heitoraí - GO"
  },
  {
      id_cidade: "987",
      id_estado: "9",
      nm_cidade: "Hidrolândia",
      nm_cidade_uf: "Hidrolândia - GO"
  },
  {
      id_cidade: "988",
      id_estado: "9",
      nm_cidade: "Hidrolina",
      nm_cidade_uf: "Hidrolina - GO"
  },
  {
      id_cidade: "989",
      id_estado: "9",
      nm_cidade: "Iaciara",
      nm_cidade_uf: "Iaciara - GO"
  },
  {
      id_cidade: "990",
      id_estado: "9",
      nm_cidade: "Inaciolândia",
      nm_cidade_uf: "Inaciolândia - GO"
  },
  {
      id_cidade: "991",
      id_estado: "9",
      nm_cidade: "Indiara",
      nm_cidade_uf: "Indiara - GO"
  },
  {
      id_cidade: "992",
      id_estado: "9",
      nm_cidade: "Inhumas",
      nm_cidade_uf: "Inhumas - GO"
  },
  {
      id_cidade: "993",
      id_estado: "9",
      nm_cidade: "Ipameri",
      nm_cidade_uf: "Ipameri - GO"
  },
  {
      id_cidade: "994",
      id_estado: "9",
      nm_cidade: "Ipiranga de Goiás",
      nm_cidade_uf: "Ipiranga de Goiás - GO"
  },
  {
      id_cidade: "995",
      id_estado: "9",
      nm_cidade: "Iporá",
      nm_cidade_uf: "Iporá - GO"
  },
  {
      id_cidade: "996",
      id_estado: "9",
      nm_cidade: "Israelândia",
      nm_cidade_uf: "Israelândia - GO"
  },
  {
      id_cidade: "997",
      id_estado: "9",
      nm_cidade: "Itaberaí",
      nm_cidade_uf: "Itaberaí - GO"
  },
  {
      id_cidade: "998",
      id_estado: "9",
      nm_cidade: "Itaguari",
      nm_cidade_uf: "Itaguari - GO"
  },
  {
      id_cidade: "999",
      id_estado: "9",
      nm_cidade: "Itaguaru",
      nm_cidade_uf: "Itaguaru - GO"
  },
  {
      id_cidade: "1000",
      id_estado: "9",
      nm_cidade: "Itajá",
      nm_cidade_uf: "Itajá - GO"
  },
  {
      id_cidade: "1001",
      id_estado: "9",
      nm_cidade: "Itapaci",
      nm_cidade_uf: "Itapaci - GO"
  },
  {
      id_cidade: "1002",
      id_estado: "9",
      nm_cidade: "Itapirapuã",
      nm_cidade_uf: "Itapirapuã - GO"
  },
  {
      id_cidade: "1003",
      id_estado: "9",
      nm_cidade: "Itapuranga",
      nm_cidade_uf: "Itapuranga - GO"
  },
  {
      id_cidade: "1004",
      id_estado: "9",
      nm_cidade: "Itarumã",
      nm_cidade_uf: "Itarumã - GO"
  },
  {
      id_cidade: "1005",
      id_estado: "9",
      nm_cidade: "Itauçu",
      nm_cidade_uf: "Itauçu - GO"
  },
  {
      id_cidade: "1006",
      id_estado: "9",
      nm_cidade: "Itumbiara",
      nm_cidade_uf: "Itumbiara - GO"
  },
  {
      id_cidade: "1007",
      id_estado: "9",
      nm_cidade: "Ivolândia",
      nm_cidade_uf: "Ivolândia - GO"
  },
  {
      id_cidade: "1008",
      id_estado: "9",
      nm_cidade: "Jandaia",
      nm_cidade_uf: "Jandaia - GO"
  },
  {
      id_cidade: "1009",
      id_estado: "9",
      nm_cidade: "Jaraguá",
      nm_cidade_uf: "Jaraguá - GO"
  },
  {
      id_cidade: "1010",
      id_estado: "9",
      nm_cidade: "Jataí",
      nm_cidade_uf: "Jataí - GO"
  },
  {
      id_cidade: "1011",
      id_estado: "9",
      nm_cidade: "Jaupaci",
      nm_cidade_uf: "Jaupaci - GO"
  },
  {
      id_cidade: "1012",
      id_estado: "9",
      nm_cidade: "Jesúpolis",
      nm_cidade_uf: "Jesúpolis - GO"
  },
  {
      id_cidade: "1013",
      id_estado: "9",
      nm_cidade: "Joviânia",
      nm_cidade_uf: "Joviânia - GO"
  },
  {
      id_cidade: "1014",
      id_estado: "9",
      nm_cidade: "Jussara",
      nm_cidade_uf: "Jussara - GO"
  },
  {
      id_cidade: "1015",
      id_estado: "9",
      nm_cidade: "Lagoa Santa",
      nm_cidade_uf: "Lagoa Santa - GO"
  },
  {
      id_cidade: "1016",
      id_estado: "9",
      nm_cidade: "Leopoldo de Bulhões",
      nm_cidade_uf: "Leopoldo de Bulhões - GO"
  },
  {
      id_cidade: "1017",
      id_estado: "9",
      nm_cidade: "Luziânia",
      nm_cidade_uf: "Luziânia - GO"
  },
  {
      id_cidade: "1018",
      id_estado: "9",
      nm_cidade: "Mairipotaba",
      nm_cidade_uf: "Mairipotaba - GO"
  },
  {
      id_cidade: "1019",
      id_estado: "9",
      nm_cidade: "Mambaí",
      nm_cidade_uf: "Mambaí - GO"
  },
  {
      id_cidade: "1020",
      id_estado: "9",
      nm_cidade: "Mara Rosa",
      nm_cidade_uf: "Mara Rosa - GO"
  },
  {
      id_cidade: "1021",
      id_estado: "9",
      nm_cidade: "Marzagão",
      nm_cidade_uf: "Marzagão - GO"
  },
  {
      id_cidade: "1022",
      id_estado: "9",
      nm_cidade: "Matrinchã",
      nm_cidade_uf: "Matrinchã - GO"
  },
  {
      id_cidade: "1023",
      id_estado: "9",
      nm_cidade: "Maurilândia",
      nm_cidade_uf: "Maurilândia - GO"
  },
  {
      id_cidade: "1024",
      id_estado: "9",
      nm_cidade: "Mimoso de Goiás",
      nm_cidade_uf: "Mimoso de Goiás - GO"
  },
  {
      id_cidade: "1025",
      id_estado: "9",
      nm_cidade: "Minaçu",
      nm_cidade_uf: "Minaçu - GO"
  },
  {
      id_cidade: "1026",
      id_estado: "9",
      nm_cidade: "Mineiros",
      nm_cidade_uf: "Mineiros - GO"
  },
  {
      id_cidade: "1027",
      id_estado: "9",
      nm_cidade: "Moiporá",
      nm_cidade_uf: "Moiporá - GO"
  },
  {
      id_cidade: "1028",
      id_estado: "9",
      nm_cidade: "Monte Alegre de Goiás",
      nm_cidade_uf: "Monte Alegre de Goiás - GO"
  },
  {
      id_cidade: "1029",
      id_estado: "9",
      nm_cidade: "Montes Claros de Goiás",
      nm_cidade_uf: "Montes Claros de Goiás - GO"
  },
  {
      id_cidade: "1030",
      id_estado: "9",
      nm_cidade: "Montividiu",
      nm_cidade_uf: "Montividiu - GO"
  },
  {
      id_cidade: "1031",
      id_estado: "9",
      nm_cidade: "Montividiu do Norte",
      nm_cidade_uf: "Montividiu do Norte - GO"
  },
  {
      id_cidade: "1032",
      id_estado: "9",
      nm_cidade: "Morrinhos",
      nm_cidade_uf: "Morrinhos - GO"
  },
  {
      id_cidade: "1033",
      id_estado: "9",
      nm_cidade: "Morro Agudo de Goiás",
      nm_cidade_uf: "Morro Agudo de Goiás - GO"
  },
  {
      id_cidade: "1034",
      id_estado: "9",
      nm_cidade: "Mossâmedes",
      nm_cidade_uf: "Mossâmedes - GO"
  },
  {
      id_cidade: "1035",
      id_estado: "9",
      nm_cidade: "Mozarlândia",
      nm_cidade_uf: "Mozarlândia - GO"
  },
  {
      id_cidade: "1036",
      id_estado: "9",
      nm_cidade: "Mundo Novo",
      nm_cidade_uf: "Mundo Novo - GO"
  },
  {
      id_cidade: "1037",
      id_estado: "9",
      nm_cidade: "Mutunópolis",
      nm_cidade_uf: "Mutunópolis - GO"
  },
  {
      id_cidade: "1038",
      id_estado: "9",
      nm_cidade: "Nazário",
      nm_cidade_uf: "Nazário - GO"
  },
  {
      id_cidade: "1039",
      id_estado: "9",
      nm_cidade: "Nerópolis",
      nm_cidade_uf: "Nerópolis - GO"
  },
  {
      id_cidade: "1040",
      id_estado: "9",
      nm_cidade: "Niquelândia",
      nm_cidade_uf: "Niquelândia - GO"
  },
  {
      id_cidade: "1041",
      id_estado: "9",
      nm_cidade: "Nova América",
      nm_cidade_uf: "Nova América - GO"
  },
  {
      id_cidade: "1042",
      id_estado: "9",
      nm_cidade: "Nova Aurora",
      nm_cidade_uf: "Nova Aurora - GO"
  },
  {
      id_cidade: "1043",
      id_estado: "9",
      nm_cidade: "Nova Crixás",
      nm_cidade_uf: "Nova Crixás - GO"
  },
  {
      id_cidade: "1044",
      id_estado: "9",
      nm_cidade: "Nova Glória",
      nm_cidade_uf: "Nova Glória - GO"
  },
  {
      id_cidade: "1045",
      id_estado: "9",
      nm_cidade: "Nova Iguaçu de Goiás",
      nm_cidade_uf: "Nova Iguaçu de Goiás - GO"
  },
  {
      id_cidade: "1046",
      id_estado: "9",
      nm_cidade: "Nova Roma",
      nm_cidade_uf: "Nova Roma - GO"
  },
  {
      id_cidade: "1047",
      id_estado: "9",
      nm_cidade: "Nova Veneza",
      nm_cidade_uf: "Nova Veneza - GO"
  },
  {
      id_cidade: "1048",
      id_estado: "9",
      nm_cidade: "Novo Brasil",
      nm_cidade_uf: "Novo Brasil - GO"
  },
  {
      id_cidade: "1049",
      id_estado: "9",
      nm_cidade: "Novo Gama",
      nm_cidade_uf: "Novo Gama - GO"
  },
  {
      id_cidade: "1050",
      id_estado: "9",
      nm_cidade: "Novo Planalto",
      nm_cidade_uf: "Novo Planalto - GO"
  },
  {
      id_cidade: "1051",
      id_estado: "9",
      nm_cidade: "Orizona",
      nm_cidade_uf: "Orizona - GO"
  },
  {
      id_cidade: "1052",
      id_estado: "9",
      nm_cidade: "Ouro Verde de Goiás",
      nm_cidade_uf: "Ouro Verde de Goiás - GO"
  },
  {
      id_cidade: "1053",
      id_estado: "9",
      nm_cidade: "Ouvidor",
      nm_cidade_uf: "Ouvidor - GO"
  },
  {
      id_cidade: "1054",
      id_estado: "9",
      nm_cidade: "Padre Bernardo",
      nm_cidade_uf: "Padre Bernardo - GO"
  },
  {
      id_cidade: "1055",
      id_estado: "9",
      nm_cidade: "Palestina de Goiás",
      nm_cidade_uf: "Palestina de Goiás - GO"
  },
  {
      id_cidade: "1056",
      id_estado: "9",
      nm_cidade: "Palmeiras de Goiás",
      nm_cidade_uf: "Palmeiras de Goiás - GO"
  },
  {
      id_cidade: "1057",
      id_estado: "9",
      nm_cidade: "Palmelo",
      nm_cidade_uf: "Palmelo - GO"
  },
  {
      id_cidade: "1058",
      id_estado: "9",
      nm_cidade: "Palminópolis",
      nm_cidade_uf: "Palminópolis - GO"
  },
  {
      id_cidade: "1059",
      id_estado: "9",
      nm_cidade: "Panamá",
      nm_cidade_uf: "Panamá - GO"
  },
  {
      id_cidade: "1060",
      id_estado: "9",
      nm_cidade: "Paranaiguara",
      nm_cidade_uf: "Paranaiguara - GO"
  },
  {
      id_cidade: "1061",
      id_estado: "9",
      nm_cidade: "Paraúna",
      nm_cidade_uf: "Paraúna - GO"
  },
  {
      id_cidade: "1062",
      id_estado: "9",
      nm_cidade: "Perolândia",
      nm_cidade_uf: "Perolândia - GO"
  },
  {
      id_cidade: "1063",
      id_estado: "9",
      nm_cidade: "Petrolina de Goiás",
      nm_cidade_uf: "Petrolina de Goiás - GO"
  },
  {
      id_cidade: "1064",
      id_estado: "9",
      nm_cidade: "Pilar de Goiás",
      nm_cidade_uf: "Pilar de Goiás - GO"
  },
  {
      id_cidade: "1065",
      id_estado: "9",
      nm_cidade: "Piracanjuba",
      nm_cidade_uf: "Piracanjuba - GO"
  },
  {
      id_cidade: "1066",
      id_estado: "9",
      nm_cidade: "Piranhas",
      nm_cidade_uf: "Piranhas - GO"
  },
  {
      id_cidade: "1067",
      id_estado: "9",
      nm_cidade: "Pirenópolis",
      nm_cidade_uf: "Pirenópolis - GO"
  },
  {
      id_cidade: "1068",
      id_estado: "9",
      nm_cidade: "Pires do Rio",
      nm_cidade_uf: "Pires do Rio - GO"
  },
  {
      id_cidade: "1069",
      id_estado: "9",
      nm_cidade: "Planaltina",
      nm_cidade_uf: "Planaltina - GO"
  },
  {
      id_cidade: "1070",
      id_estado: "9",
      nm_cidade: "Pontalina",
      nm_cidade_uf: "Pontalina - GO"
  },
  {
      id_cidade: "1071",
      id_estado: "9",
      nm_cidade: "Porangatu",
      nm_cidade_uf: "Porangatu - GO"
  },
  {
      id_cidade: "1072",
      id_estado: "9",
      nm_cidade: "Porteirão",
      nm_cidade_uf: "Porteirão - GO"
  },
  {
      id_cidade: "1073",
      id_estado: "9",
      nm_cidade: "Portelândia",
      nm_cidade_uf: "Portelândia - GO"
  },
  {
      id_cidade: "1074",
      id_estado: "9",
      nm_cidade: "Posse",
      nm_cidade_uf: "Posse - GO"
  },
  {
      id_cidade: "1075",
      id_estado: "9",
      nm_cidade: "Professor Jamil",
      nm_cidade_uf: "Professor Jamil - GO"
  },
  {
      id_cidade: "1076",
      id_estado: "9",
      nm_cidade: "Quirinópolis",
      nm_cidade_uf: "Quirinópolis - GO"
  },
  {
      id_cidade: "1077",
      id_estado: "9",
      nm_cidade: "Rialma",
      nm_cidade_uf: "Rialma - GO"
  },
  {
      id_cidade: "1078",
      id_estado: "9",
      nm_cidade: "Rianápolis",
      nm_cidade_uf: "Rianápolis - GO"
  },
  {
      id_cidade: "1079",
      id_estado: "9",
      nm_cidade: "Rio Quente",
      nm_cidade_uf: "Rio Quente - GO"
  },
  {
      id_cidade: "1080",
      id_estado: "9",
      nm_cidade: "Rio Verde",
      nm_cidade_uf: "Rio Verde - GO"
  },
  {
      id_cidade: "1081",
      id_estado: "9",
      nm_cidade: "Rubiataba",
      nm_cidade_uf: "Rubiataba - GO"
  },
  {
      id_cidade: "1082",
      id_estado: "9",
      nm_cidade: "Sanclerlândia",
      nm_cidade_uf: "Sanclerlândia - GO"
  },
  {
      id_cidade: "1083",
      id_estado: "9",
      nm_cidade: "Santa Bárbara de Goiás",
      nm_cidade_uf: "Santa Bárbara de Goiás - GO"
  },
  {
      id_cidade: "1084",
      id_estado: "9",
      nm_cidade: "Santa Cruz de Goiás",
      nm_cidade_uf: "Santa Cruz de Goiás - GO"
  },
  {
      id_cidade: "1085",
      id_estado: "9",
      nm_cidade: "Santa Fé de Goiás",
      nm_cidade_uf: "Santa Fé de Goiás - GO"
  },
  {
      id_cidade: "1086",
      id_estado: "9",
      nm_cidade: "Santa Helena de Goiás",
      nm_cidade_uf: "Santa Helena de Goiás - GO"
  },
  {
      id_cidade: "1087",
      id_estado: "9",
      nm_cidade: "Santa Isabel",
      nm_cidade_uf: "Santa Isabel - GO"
  },
  {
      id_cidade: "1088",
      id_estado: "9",
      nm_cidade: "Santa Rita do Araguaia",
      nm_cidade_uf: "Santa Rita do Araguaia - GO"
  },
  {
      id_cidade: "1089",
      id_estado: "9",
      nm_cidade: "Santa Rita do Novo Destino",
      nm_cidade_uf: "Santa Rita do Novo Destino - GO"
  },
  {
      id_cidade: "1090",
      id_estado: "9",
      nm_cidade: "Santa Rosa de Goiás",
      nm_cidade_uf: "Santa Rosa de Goiás - GO"
  },
  {
      id_cidade: "1091",
      id_estado: "9",
      nm_cidade: "Santa Tereza de Goiás",
      nm_cidade_uf: "Santa Tereza de Goiás - GO"
  },
  {
      id_cidade: "1092",
      id_estado: "9",
      nm_cidade: "Santa Terezinha de Goiás",
      nm_cidade_uf: "Santa Terezinha de Goiás - GO"
  },
  {
      id_cidade: "1093",
      id_estado: "9",
      nm_cidade: "Santo Antônio da Barra",
      nm_cidade_uf: "Santo Antônio da Barra - GO"
  },
  {
      id_cidade: "1094",
      id_estado: "9",
      nm_cidade: "Santo Antônio de Goiás",
      nm_cidade_uf: "Santo Antônio de Goiás - GO"
  },
  {
      id_cidade: "1095",
      id_estado: "9",
      nm_cidade: "Santo Antônio do Descoberto",
      nm_cidade_uf: "Santo Antônio do Descoberto - GO"
  },
  {
      id_cidade: "1096",
      id_estado: "9",
      nm_cidade: "São Domingos",
      nm_cidade_uf: "São Domingos - GO"
  },
  {
      id_cidade: "1097",
      id_estado: "9",
      nm_cidade: "São Francisco de Goiás",
      nm_cidade_uf: "São Francisco de Goiás - GO"
  },
  {
      id_cidade: "1098",
      id_estado: "9",
      nm_cidade: "São João d`Aliança",
      nm_cidade_uf: "São João d`Aliança - GO"
  },
  {
      id_cidade: "1099",
      id_estado: "9",
      nm_cidade: "São João da Paraúna",
      nm_cidade_uf: "São João da Paraúna - GO"
  },
  {
      id_cidade: "1100",
      id_estado: "9",
      nm_cidade: "São Luís de Montes Belos",
      nm_cidade_uf: "São Luís de Montes Belos - GO"
  },
  {
      id_cidade: "1101",
      id_estado: "9",
      nm_cidade: "São Luíz do Norte",
      nm_cidade_uf: "São Luíz do Norte - GO"
  },
  {
      id_cidade: "1102",
      id_estado: "9",
      nm_cidade: "São Miguel do Araguaia",
      nm_cidade_uf: "São Miguel do Araguaia - GO"
  },
  {
      id_cidade: "1103",
      id_estado: "9",
      nm_cidade: "São Miguel do Passa Quatro",
      nm_cidade_uf: "São Miguel do Passa Quatro - GO"
  },
  {
      id_cidade: "1104",
      id_estado: "9",
      nm_cidade: "São Patrício",
      nm_cidade_uf: "São Patrício - GO"
  },
  {
      id_cidade: "1105",
      id_estado: "9",
      nm_cidade: "São Simão",
      nm_cidade_uf: "São Simão - GO"
  },
  {
      id_cidade: "1106",
      id_estado: "9",
      nm_cidade: "Senador Canedo",
      nm_cidade_uf: "Senador Canedo - GO"
  },
  {
      id_cidade: "1107",
      id_estado: "9",
      nm_cidade: "Serranópolis",
      nm_cidade_uf: "Serranópolis - GO"
  },
  {
      id_cidade: "1108",
      id_estado: "9",
      nm_cidade: "Silvânia",
      nm_cidade_uf: "Silvânia - GO"
  },
  {
      id_cidade: "1109",
      id_estado: "9",
      nm_cidade: "Simolândia",
      nm_cidade_uf: "Simolândia - GO"
  },
  {
      id_cidade: "1110",
      id_estado: "9",
      nm_cidade: "Sítio d`Abadia",
      nm_cidade_uf: "Sítio d`Abadia - GO"
  },
  {
      id_cidade: "1111",
      id_estado: "9",
      nm_cidade: "Taquaral de Goiás",
      nm_cidade_uf: "Taquaral de Goiás - GO"
  },
  {
      id_cidade: "1112",
      id_estado: "9",
      nm_cidade: "Teresina de Goiás",
      nm_cidade_uf: "Teresina de Goiás - GO"
  },
  {
      id_cidade: "1113",
      id_estado: "9",
      nm_cidade: "Terezópolis de Goiás",
      nm_cidade_uf: "Terezópolis de Goiás - GO"
  },
  {
      id_cidade: "1114",
      id_estado: "9",
      nm_cidade: "Três Ranchos",
      nm_cidade_uf: "Três Ranchos - GO"
  },
  {
      id_cidade: "1115",
      id_estado: "9",
      nm_cidade: "Trindade",
      nm_cidade_uf: "Trindade - GO"
  },
  {
      id_cidade: "1116",
      id_estado: "9",
      nm_cidade: "Trombas",
      nm_cidade_uf: "Trombas - GO"
  },
  {
      id_cidade: "1117",
      id_estado: "9",
      nm_cidade: "Turvânia",
      nm_cidade_uf: "Turvânia - GO"
  },
  {
      id_cidade: "1118",
      id_estado: "9",
      nm_cidade: "Turvelândia",
      nm_cidade_uf: "Turvelândia - GO"
  },
  {
      id_cidade: "1119",
      id_estado: "9",
      nm_cidade: "Uirapuru",
      nm_cidade_uf: "Uirapuru - GO"
  },
  {
      id_cidade: "1120",
      id_estado: "9",
      nm_cidade: "Uruaçu",
      nm_cidade_uf: "Uruaçu - GO"
  },
  {
      id_cidade: "1121",
      id_estado: "9",
      nm_cidade: "Uruana",
      nm_cidade_uf: "Uruana - GO"
  },
  {
      id_cidade: "1122",
      id_estado: "9",
      nm_cidade: "Urutaí",
      nm_cidade_uf: "Urutaí - GO"
  },
  {
      id_cidade: "1123",
      id_estado: "9",
      nm_cidade: "Valparaíso de Goiás",
      nm_cidade_uf: "Valparaíso de Goiás - GO"
  },
  {
      id_cidade: "1124",
      id_estado: "9",
      nm_cidade: "Varjão",
      nm_cidade_uf: "Varjão - GO"
  },
  {
      id_cidade: "1125",
      id_estado: "9",
      nm_cidade: "Vianópolis",
      nm_cidade_uf: "Vianópolis - GO"
  },
  {
      id_cidade: "1126",
      id_estado: "9",
      nm_cidade: "Vicentinópolis",
      nm_cidade_uf: "Vicentinópolis - GO"
  },
  {
      id_cidade: "1127",
      id_estado: "9",
      nm_cidade: "Vila Boa",
      nm_cidade_uf: "Vila Boa - GO"
  },
  {
      id_cidade: "1128",
      id_estado: "9",
      nm_cidade: "Vila Propício",
      nm_cidade_uf: "Vila Propício - GO"
  },
  {
      id_cidade: "1129",
      id_estado: "10",
      nm_cidade: "Açailândia",
      nm_cidade_uf: "Açailândia - MA"
  },
  {
      id_cidade: "1130",
      id_estado: "10",
      nm_cidade: "Afonso Cunha",
      nm_cidade_uf: "Afonso Cunha - MA"
  },
  {
      id_cidade: "1131",
      id_estado: "10",
      nm_cidade: "Água Doce do Maranhão",
      nm_cidade_uf: "Água Doce do Maranhão - MA"
  },
  {
      id_cidade: "1132",
      id_estado: "10",
      nm_cidade: "Alcântara",
      nm_cidade_uf: "Alcântara - MA"
  },
  {
      id_cidade: "1133",
      id_estado: "10",
      nm_cidade: "Aldeias Altas",
      nm_cidade_uf: "Aldeias Altas - MA"
  },
  {
      id_cidade: "1134",
      id_estado: "10",
      nm_cidade: "Altamira do Maranhão",
      nm_cidade_uf: "Altamira do Maranhão - MA"
  },
  {
      id_cidade: "1135",
      id_estado: "10",
      nm_cidade: "Alto Alegre do Maranhão",
      nm_cidade_uf: "Alto Alegre do Maranhão - MA"
  },
  {
      id_cidade: "1136",
      id_estado: "10",
      nm_cidade: "Alto Alegre do Pindaré",
      nm_cidade_uf: "Alto Alegre do Pindaré - MA"
  },
  {
      id_cidade: "1137",
      id_estado: "10",
      nm_cidade: "Alto Parnaíba",
      nm_cidade_uf: "Alto Parnaíba - MA"
  },
  {
      id_cidade: "1138",
      id_estado: "10",
      nm_cidade: "Amapá do Maranhão",
      nm_cidade_uf: "Amapá do Maranhão - MA"
  },
  {
      id_cidade: "1139",
      id_estado: "10",
      nm_cidade: "Amarante do Maranhão",
      nm_cidade_uf: "Amarante do Maranhão - MA"
  },
  {
      id_cidade: "1140",
      id_estado: "10",
      nm_cidade: "Anajatuba",
      nm_cidade_uf: "Anajatuba - MA"
  },
  {
      id_cidade: "1141",
      id_estado: "10",
      nm_cidade: "Anapurus",
      nm_cidade_uf: "Anapurus - MA"
  },
  {
      id_cidade: "1142",
      id_estado: "10",
      nm_cidade: "Apicum-Açu",
      nm_cidade_uf: "Apicum-Açu - MA"
  },
  {
      id_cidade: "1143",
      id_estado: "10",
      nm_cidade: "Araguanã",
      nm_cidade_uf: "Araguanã - MA"
  },
  {
      id_cidade: "1144",
      id_estado: "10",
      nm_cidade: "Araioses",
      nm_cidade_uf: "Araioses - MA"
  },
  {
      id_cidade: "1145",
      id_estado: "10",
      nm_cidade: "Arame",
      nm_cidade_uf: "Arame - MA"
  },
  {
      id_cidade: "1146",
      id_estado: "10",
      nm_cidade: "Arari",
      nm_cidade_uf: "Arari - MA"
  },
  {
      id_cidade: "1147",
      id_estado: "10",
      nm_cidade: "Axixá",
      nm_cidade_uf: "Axixá - MA"
  },
  {
      id_cidade: "1148",
      id_estado: "10",
      nm_cidade: "Bacabal",
      nm_cidade_uf: "Bacabal - MA"
  },
  {
      id_cidade: "1149",
      id_estado: "10",
      nm_cidade: "Bacabeira",
      nm_cidade_uf: "Bacabeira - MA"
  },
  {
      id_cidade: "1150",
      id_estado: "10",
      nm_cidade: "Bacuri",
      nm_cidade_uf: "Bacuri - MA"
  },
  {
      id_cidade: "1151",
      id_estado: "10",
      nm_cidade: "Bacurituba",
      nm_cidade_uf: "Bacurituba - MA"
  },
  {
      id_cidade: "1152",
      id_estado: "10",
      nm_cidade: "Balsas",
      nm_cidade_uf: "Balsas - MA"
  },
  {
      id_cidade: "1153",
      id_estado: "10",
      nm_cidade: "Barão de Grajaú",
      nm_cidade_uf: "Barão de Grajaú - MA"
  },
  {
      id_cidade: "1154",
      id_estado: "10",
      nm_cidade: "Barra do Corda",
      nm_cidade_uf: "Barra do Corda - MA"
  },
  {
      id_cidade: "1155",
      id_estado: "10",
      nm_cidade: "Barreirinhas",
      nm_cidade_uf: "Barreirinhas - MA"
  },
  {
      id_cidade: "1156",
      id_estado: "10",
      nm_cidade: "Bela Vista do Maranhão",
      nm_cidade_uf: "Bela Vista do Maranhão - MA"
  },
  {
      id_cidade: "1157",
      id_estado: "10",
      nm_cidade: "Belágua",
      nm_cidade_uf: "Belágua - MA"
  },
  {
      id_cidade: "1158",
      id_estado: "10",
      nm_cidade: "Benedito Leite",
      nm_cidade_uf: "Benedito Leite - MA"
  },
  {
      id_cidade: "1159",
      id_estado: "10",
      nm_cidade: "Bequimão",
      nm_cidade_uf: "Bequimão - MA"
  },
  {
      id_cidade: "1160",
      id_estado: "10",
      nm_cidade: "Bernardo do Mearim",
      nm_cidade_uf: "Bernardo do Mearim - MA"
  },
  {
      id_cidade: "1161",
      id_estado: "10",
      nm_cidade: "Boa Vista do Gurupi",
      nm_cidade_uf: "Boa Vista do Gurupi - MA"
  },
  {
      id_cidade: "1162",
      id_estado: "10",
      nm_cidade: "Bom Jardim",
      nm_cidade_uf: "Bom Jardim - MA"
  },
  {
      id_cidade: "1163",
      id_estado: "10",
      nm_cidade: "Bom Jesus das Selvas",
      nm_cidade_uf: "Bom Jesus das Selvas - MA"
  },
  {
      id_cidade: "1164",
      id_estado: "10",
      nm_cidade: "Bom Lugar",
      nm_cidade_uf: "Bom Lugar - MA"
  },
  {
      id_cidade: "1165",
      id_estado: "10",
      nm_cidade: "Brejo",
      nm_cidade_uf: "Brejo - MA"
  },
  {
      id_cidade: "1166",
      id_estado: "10",
      nm_cidade: "Brejo de Areia",
      nm_cidade_uf: "Brejo de Areia - MA"
  },
  {
      id_cidade: "1167",
      id_estado: "10",
      nm_cidade: "Buriti",
      nm_cidade_uf: "Buriti - MA"
  },
  {
      id_cidade: "1168",
      id_estado: "10",
      nm_cidade: "Buriti Bravo",
      nm_cidade_uf: "Buriti Bravo - MA"
  },
  {
      id_cidade: "1169",
      id_estado: "10",
      nm_cidade: "Buriticupu",
      nm_cidade_uf: "Buriticupu - MA"
  },
  {
      id_cidade: "1170",
      id_estado: "10",
      nm_cidade: "Buritirana",
      nm_cidade_uf: "Buritirana - MA"
  },
  {
      id_cidade: "1171",
      id_estado: "10",
      nm_cidade: "Cachoeira Grande",
      nm_cidade_uf: "Cachoeira Grande - MA"
  },
  {
      id_cidade: "1172",
      id_estado: "10",
      nm_cidade: "Cajapió",
      nm_cidade_uf: "Cajapió - MA"
  },
  {
      id_cidade: "1173",
      id_estado: "10",
      nm_cidade: "Cajari",
      nm_cidade_uf: "Cajari - MA"
  },
  {
      id_cidade: "1174",
      id_estado: "10",
      nm_cidade: "Campestre do Maranhão",
      nm_cidade_uf: "Campestre do Maranhão - MA"
  },
  {
      id_cidade: "1175",
      id_estado: "10",
      nm_cidade: "Cândido Mendes",
      nm_cidade_uf: "Cândido Mendes - MA"
  },
  {
      id_cidade: "1176",
      id_estado: "10",
      nm_cidade: "Cantanhede",
      nm_cidade_uf: "Cantanhede - MA"
  },
  {
      id_cidade: "1177",
      id_estado: "10",
      nm_cidade: "Capinzal do Norte",
      nm_cidade_uf: "Capinzal do Norte - MA"
  },
  {
      id_cidade: "1178",
      id_estado: "10",
      nm_cidade: "Carolina",
      nm_cidade_uf: "Carolina - MA"
  },
  {
      id_cidade: "1179",
      id_estado: "10",
      nm_cidade: "Carutapera",
      nm_cidade_uf: "Carutapera - MA"
  },
  {
      id_cidade: "1180",
      id_estado: "10",
      nm_cidade: "Caxias",
      nm_cidade_uf: "Caxias - MA"
  },
  {
      id_cidade: "1181",
      id_estado: "10",
      nm_cidade: "Cedral",
      nm_cidade_uf: "Cedral - MA"
  },
  {
      id_cidade: "1182",
      id_estado: "10",
      nm_cidade: "Central do Maranhão",
      nm_cidade_uf: "Central do Maranhão - MA"
  },
  {
      id_cidade: "1183",
      id_estado: "10",
      nm_cidade: "Centro do Guilherme",
      nm_cidade_uf: "Centro do Guilherme - MA"
  },
  {
      id_cidade: "1184",
      id_estado: "10",
      nm_cidade: "Centro Novo do Maranhão",
      nm_cidade_uf: "Centro Novo do Maranhão - MA"
  },
  {
      id_cidade: "1185",
      id_estado: "10",
      nm_cidade: "Chapadinha",
      nm_cidade_uf: "Chapadinha - MA"
  },
  {
      id_cidade: "1186",
      id_estado: "10",
      nm_cidade: "Cidelândia",
      nm_cidade_uf: "Cidelândia - MA"
  },
  {
      id_cidade: "1187",
      id_estado: "10",
      nm_cidade: "Codó",
      nm_cidade_uf: "Codó - MA"
  },
  {
      id_cidade: "1188",
      id_estado: "10",
      nm_cidade: "Coelho Neto",
      nm_cidade_uf: "Coelho Neto - MA"
  },
  {
      id_cidade: "1189",
      id_estado: "10",
      nm_cidade: "Colinas",
      nm_cidade_uf: "Colinas - MA"
  },
  {
      id_cidade: "1190",
      id_estado: "10",
      nm_cidade: "Conceição do Lago-Açu",
      nm_cidade_uf: "Conceição do Lago-Açu - MA"
  },
  {
      id_cidade: "1191",
      id_estado: "10",
      nm_cidade: "Coroatá",
      nm_cidade_uf: "Coroatá - MA"
  },
  {
      id_cidade: "1192",
      id_estado: "10",
      nm_cidade: "Cururupu",
      nm_cidade_uf: "Cururupu - MA"
  },
  {
      id_cidade: "1193",
      id_estado: "10",
      nm_cidade: "Davinópolis",
      nm_cidade_uf: "Davinópolis - MA"
  },
  {
      id_cidade: "1194",
      id_estado: "10",
      nm_cidade: "Dom Pedro",
      nm_cidade_uf: "Dom Pedro - MA"
  },
  {
      id_cidade: "1195",
      id_estado: "10",
      nm_cidade: "Duque Bacelar",
      nm_cidade_uf: "Duque Bacelar - MA"
  },
  {
      id_cidade: "1196",
      id_estado: "10",
      nm_cidade: "Esperantinópolis",
      nm_cidade_uf: "Esperantinópolis - MA"
  },
  {
      id_cidade: "1197",
      id_estado: "10",
      nm_cidade: "Estreito",
      nm_cidade_uf: "Estreito - MA"
  },
  {
      id_cidade: "1198",
      id_estado: "10",
      nm_cidade: "Feira Nova do Maranhão",
      nm_cidade_uf: "Feira Nova do Maranhão - MA"
  },
  {
      id_cidade: "1199",
      id_estado: "10",
      nm_cidade: "Fernando Falcão",
      nm_cidade_uf: "Fernando Falcão - MA"
  },
  {
      id_cidade: "1200",
      id_estado: "10",
      nm_cidade: "Formosa da Serra Negra",
      nm_cidade_uf: "Formosa da Serra Negra - MA"
  },
  {
      id_cidade: "1201",
      id_estado: "10",
      nm_cidade: "Fortaleza dos Nogueiras",
      nm_cidade_uf: "Fortaleza dos Nogueiras - MA"
  },
  {
      id_cidade: "1202",
      id_estado: "10",
      nm_cidade: "Fortuna",
      nm_cidade_uf: "Fortuna - MA"
  },
  {
      id_cidade: "1203",
      id_estado: "10",
      nm_cidade: "Godofredo Viana",
      nm_cidade_uf: "Godofredo Viana - MA"
  },
  {
      id_cidade: "1204",
      id_estado: "10",
      nm_cidade: "Gonçalves Dias",
      nm_cidade_uf: "Gonçalves Dias - MA"
  },
  {
      id_cidade: "1205",
      id_estado: "10",
      nm_cidade: "Governador Archer",
      nm_cidade_uf: "Governador Archer - MA"
  },
  {
      id_cidade: "1206",
      id_estado: "10",
      nm_cidade: "Governador Edison Lobão",
      nm_cidade_uf: "Governador Edison Lobão - MA"
  },
  {
      id_cidade: "1207",
      id_estado: "10",
      nm_cidade: "Governador Eugênio Barros",
      nm_cidade_uf: "Governador Eugênio Barros - MA"
  },
  {
      id_cidade: "1208",
      id_estado: "10",
      nm_cidade: "Governador Luiz Rocha",
      nm_cidade_uf: "Governador Luiz Rocha - MA"
  },
  {
      id_cidade: "1209",
      id_estado: "10",
      nm_cidade: "Governador Newton Bello",
      nm_cidade_uf: "Governador Newton Bello - MA"
  },
  {
      id_cidade: "1210",
      id_estado: "10",
      nm_cidade: "Governador Nunes Freire",
      nm_cidade_uf: "Governador Nunes Freire - MA"
  },
  {
      id_cidade: "1211",
      id_estado: "10",
      nm_cidade: "Graça Aranha",
      nm_cidade_uf: "Graça Aranha - MA"
  },
  {
      id_cidade: "1212",
      id_estado: "10",
      nm_cidade: "Grajaú",
      nm_cidade_uf: "Grajaú - MA"
  },
  {
      id_cidade: "1213",
      id_estado: "10",
      nm_cidade: "Guimarães",
      nm_cidade_uf: "Guimarães - MA"
  },
  {
      id_cidade: "1214",
      id_estado: "10",
      nm_cidade: "Humberto de Campos",
      nm_cidade_uf: "Humberto de Campos - MA"
  },
  {
      id_cidade: "1215",
      id_estado: "10",
      nm_cidade: "Icatu",
      nm_cidade_uf: "Icatu - MA"
  },
  {
      id_cidade: "1216",
      id_estado: "10",
      nm_cidade: "Igarapé do Meio",
      nm_cidade_uf: "Igarapé do Meio - MA"
  },
  {
      id_cidade: "1217",
      id_estado: "10",
      nm_cidade: "Igarapé Grande",
      nm_cidade_uf: "Igarapé Grande - MA"
  },
  {
      id_cidade: "1218",
      id_estado: "10",
      nm_cidade: "Imperatriz",
      nm_cidade_uf: "Imperatriz - MA"
  },
  {
      id_cidade: "1219",
      id_estado: "10",
      nm_cidade: "Itaipava do Grajaú",
      nm_cidade_uf: "Itaipava do Grajaú - MA"
  },
  {
      id_cidade: "1220",
      id_estado: "10",
      nm_cidade: "Itapecuru Mirim",
      nm_cidade_uf: "Itapecuru Mirim - MA"
  },
  {
      id_cidade: "1221",
      id_estado: "10",
      nm_cidade: "Itinga do Maranhão",
      nm_cidade_uf: "Itinga do Maranhão - MA"
  },
  {
      id_cidade: "1222",
      id_estado: "10",
      nm_cidade: "Jatobá",
      nm_cidade_uf: "Jatobá - MA"
  },
  {
      id_cidade: "1223",
      id_estado: "10",
      nm_cidade: "Jenipapo dos Vieiras",
      nm_cidade_uf: "Jenipapo dos Vieiras - MA"
  },
  {
      id_cidade: "1224",
      id_estado: "10",
      nm_cidade: "João Lisboa",
      nm_cidade_uf: "João Lisboa - MA"
  },
  {
      id_cidade: "1225",
      id_estado: "10",
      nm_cidade: "Joselândia",
      nm_cidade_uf: "Joselândia - MA"
  },
  {
      id_cidade: "1226",
      id_estado: "10",
      nm_cidade: "Junco do Maranhão",
      nm_cidade_uf: "Junco do Maranhão - MA"
  },
  {
      id_cidade: "1227",
      id_estado: "10",
      nm_cidade: "Lago da Pedra",
      nm_cidade_uf: "Lago da Pedra - MA"
  },
  {
      id_cidade: "1228",
      id_estado: "10",
      nm_cidade: "Lago do Junco",
      nm_cidade_uf: "Lago do Junco - MA"
  },
  {
      id_cidade: "1229",
      id_estado: "10",
      nm_cidade: "Lago dos Rodrigues",
      nm_cidade_uf: "Lago dos Rodrigues - MA"
  },
  {
      id_cidade: "1230",
      id_estado: "10",
      nm_cidade: "Lago Verde",
      nm_cidade_uf: "Lago Verde - MA"
  },
  {
      id_cidade: "1231",
      id_estado: "10",
      nm_cidade: "Lagoa do Mato",
      nm_cidade_uf: "Lagoa do Mato - MA"
  },
  {
      id_cidade: "1232",
      id_estado: "10",
      nm_cidade: "Lagoa Grande do Maranhão",
      nm_cidade_uf: "Lagoa Grande do Maranhão - MA"
  },
  {
      id_cidade: "1233",
      id_estado: "10",
      nm_cidade: "Lajeado Novo",
      nm_cidade_uf: "Lajeado Novo - MA"
  },
  {
      id_cidade: "1234",
      id_estado: "10",
      nm_cidade: "Lima Campos",
      nm_cidade_uf: "Lima Campos - MA"
  },
  {
      id_cidade: "1235",
      id_estado: "10",
      nm_cidade: "Loreto",
      nm_cidade_uf: "Loreto - MA"
  },
  {
      id_cidade: "1236",
      id_estado: "10",
      nm_cidade: "Luís Domingues",
      nm_cidade_uf: "Luís Domingues - MA"
  },
  {
      id_cidade: "1237",
      id_estado: "10",
      nm_cidade: "Magalhães de Almeida",
      nm_cidade_uf: "Magalhães de Almeida - MA"
  },
  {
      id_cidade: "1238",
      id_estado: "10",
      nm_cidade: "Maracaçumé",
      nm_cidade_uf: "Maracaçumé - MA"
  },
  {
      id_cidade: "1239",
      id_estado: "10",
      nm_cidade: "Marajá do Sena",
      nm_cidade_uf: "Marajá do Sena - MA"
  },
  {
      id_cidade: "1240",
      id_estado: "10",
      nm_cidade: "Maranhãozinho",
      nm_cidade_uf: "Maranhãozinho - MA"
  },
  {
      id_cidade: "1241",
      id_estado: "10",
      nm_cidade: "Mata Roma",
      nm_cidade_uf: "Mata Roma - MA"
  },
  {
      id_cidade: "1242",
      id_estado: "10",
      nm_cidade: "Matinha",
      nm_cidade_uf: "Matinha - MA"
  },
  {
      id_cidade: "1243",
      id_estado: "10",
      nm_cidade: "Matões",
      nm_cidade_uf: "Matões - MA"
  },
  {
      id_cidade: "1244",
      id_estado: "10",
      nm_cidade: "Matões do Norte",
      nm_cidade_uf: "Matões do Norte - MA"
  },
  {
      id_cidade: "1245",
      id_estado: "10",
      nm_cidade: "Milagres do Maranhão",
      nm_cidade_uf: "Milagres do Maranhão - MA"
  },
  {
      id_cidade: "1246",
      id_estado: "10",
      nm_cidade: "Mirador",
      nm_cidade_uf: "Mirador - MA"
  },
  {
      id_cidade: "1247",
      id_estado: "10",
      nm_cidade: "Miranda do Norte",
      nm_cidade_uf: "Miranda do Norte - MA"
  },
  {
      id_cidade: "1248",
      id_estado: "10",
      nm_cidade: "Mirinzal",
      nm_cidade_uf: "Mirinzal - MA"
  },
  {
      id_cidade: "1249",
      id_estado: "10",
      nm_cidade: "Monção",
      nm_cidade_uf: "Monção - MA"
  },
  {
      id_cidade: "1250",
      id_estado: "10",
      nm_cidade: "Montes Altos",
      nm_cidade_uf: "Montes Altos - MA"
  },
  {
      id_cidade: "1251",
      id_estado: "10",
      nm_cidade: "Morros",
      nm_cidade_uf: "Morros - MA"
  },
  {
      id_cidade: "1252",
      id_estado: "10",
      nm_cidade: "Nina Rodrigues",
      nm_cidade_uf: "Nina Rodrigues - MA"
  },
  {
      id_cidade: "1253",
      id_estado: "10",
      nm_cidade: "Nova Colinas",
      nm_cidade_uf: "Nova Colinas - MA"
  },
  {
      id_cidade: "1254",
      id_estado: "10",
      nm_cidade: "Nova Iorque",
      nm_cidade_uf: "Nova Iorque - MA"
  },
  {
      id_cidade: "1255",
      id_estado: "10",
      nm_cidade: "Nova Olinda do Maranhão",
      nm_cidade_uf: "Nova Olinda do Maranhão - MA"
  },
  {
      id_cidade: "1256",
      id_estado: "10",
      nm_cidade: "Olho d`Água das Cunhãs",
      nm_cidade_uf: "Olho d`Água das Cunhãs - MA"
  },
  {
      id_cidade: "1257",
      id_estado: "10",
      nm_cidade: "Olinda Nova do Maranhão",
      nm_cidade_uf: "Olinda Nova do Maranhão - MA"
  },
  {
      id_cidade: "1258",
      id_estado: "10",
      nm_cidade: "Paço do Lumiar",
      nm_cidade_uf: "Paço do Lumiar - MA"
  },
  {
      id_cidade: "1259",
      id_estado: "10",
      nm_cidade: "Palmeirândia",
      nm_cidade_uf: "Palmeirândia - MA"
  },
  {
      id_cidade: "1260",
      id_estado: "10",
      nm_cidade: "Paraibano",
      nm_cidade_uf: "Paraibano - MA"
  },
  {
      id_cidade: "1261",
      id_estado: "10",
      nm_cidade: "Parnarama",
      nm_cidade_uf: "Parnarama - MA"
  },
  {
      id_cidade: "1262",
      id_estado: "10",
      nm_cidade: "Passagem Franca",
      nm_cidade_uf: "Passagem Franca - MA"
  },
  {
      id_cidade: "1263",
      id_estado: "10",
      nm_cidade: "Pastos Bons",
      nm_cidade_uf: "Pastos Bons - MA"
  },
  {
      id_cidade: "1264",
      id_estado: "10",
      nm_cidade: "Paulino Neves",
      nm_cidade_uf: "Paulino Neves - MA"
  },
  {
      id_cidade: "1265",
      id_estado: "10",
      nm_cidade: "Paulo Ramos",
      nm_cidade_uf: "Paulo Ramos - MA"
  },
  {
      id_cidade: "1266",
      id_estado: "10",
      nm_cidade: "Pedreiras",
      nm_cidade_uf: "Pedreiras - MA"
  },
  {
      id_cidade: "1267",
      id_estado: "10",
      nm_cidade: "Pedro do Rosário",
      nm_cidade_uf: "Pedro do Rosário - MA"
  },
  {
      id_cidade: "1268",
      id_estado: "10",
      nm_cidade: "Penalva",
      nm_cidade_uf: "Penalva - MA"
  },
  {
      id_cidade: "1269",
      id_estado: "10",
      nm_cidade: "Peri Mirim",
      nm_cidade_uf: "Peri Mirim - MA"
  },
  {
      id_cidade: "1270",
      id_estado: "10",
      nm_cidade: "Peritoró",
      nm_cidade_uf: "Peritoró - MA"
  },
  {
      id_cidade: "1271",
      id_estado: "10",
      nm_cidade: "Pindaré-Mirim",
      nm_cidade_uf: "Pindaré-Mirim - MA"
  },
  {
      id_cidade: "1272",
      id_estado: "10",
      nm_cidade: "Pinheiro",
      nm_cidade_uf: "Pinheiro - MA"
  },
  {
      id_cidade: "1273",
      id_estado: "10",
      nm_cidade: "Pio XII",
      nm_cidade_uf: "Pio XII - MA"
  },
  {
      id_cidade: "1274",
      id_estado: "10",
      nm_cidade: "Pirapemas",
      nm_cidade_uf: "Pirapemas - MA"
  },
  {
      id_cidade: "1275",
      id_estado: "10",
      nm_cidade: "Poção de Pedras",
      nm_cidade_uf: "Poção de Pedras - MA"
  },
  {
      id_cidade: "1276",
      id_estado: "10",
      nm_cidade: "Porto Franco",
      nm_cidade_uf: "Porto Franco - MA"
  },
  {
      id_cidade: "1277",
      id_estado: "10",
      nm_cidade: "Porto Rico do Maranhão",
      nm_cidade_uf: "Porto Rico do Maranhão - MA"
  },
  {
      id_cidade: "1278",
      id_estado: "10",
      nm_cidade: "Presidente Dutra",
      nm_cidade_uf: "Presidente Dutra - MA"
  },
  {
      id_cidade: "1279",
      id_estado: "10",
      nm_cidade: "Presidente Juscelino",
      nm_cidade_uf: "Presidente Juscelino - MA"
  },
  {
      id_cidade: "1280",
      id_estado: "10",
      nm_cidade: "Presidente Médici",
      nm_cidade_uf: "Presidente Médici - MA"
  },
  {
      id_cidade: "1281",
      id_estado: "10",
      nm_cidade: "Presidente Sarney",
      nm_cidade_uf: "Presidente Sarney - MA"
  },
  {
      id_cidade: "1282",
      id_estado: "10",
      nm_cidade: "Presidente Vargas",
      nm_cidade_uf: "Presidente Vargas - MA"
  },
  {
      id_cidade: "1283",
      id_estado: "10",
      nm_cidade: "Primeira Cruz",
      nm_cidade_uf: "Primeira Cruz - MA"
  },
  {
      id_cidade: "1284",
      id_estado: "10",
      nm_cidade: "Raposa",
      nm_cidade_uf: "Raposa - MA"
  },
  {
      id_cidade: "1285",
      id_estado: "10",
      nm_cidade: "Riachão",
      nm_cidade_uf: "Riachão - MA"
  },
  {
      id_cidade: "1286",
      id_estado: "10",
      nm_cidade: "Ribamar Fiquene",
      nm_cidade_uf: "Ribamar Fiquene - MA"
  },
  {
      id_cidade: "1287",
      id_estado: "10",
      nm_cidade: "Rosário",
      nm_cidade_uf: "Rosário - MA"
  },
  {
      id_cidade: "1288",
      id_estado: "10",
      nm_cidade: "Sambaíba",
      nm_cidade_uf: "Sambaíba - MA"
  },
  {
      id_cidade: "1289",
      id_estado: "10",
      nm_cidade: "Santa Filomena do Maranhão",
      nm_cidade_uf: "Santa Filomena do Maranhão - MA"
  },
  {
      id_cidade: "1290",
      id_estado: "10",
      nm_cidade: "Santa Helena",
      nm_cidade_uf: "Santa Helena - MA"
  },
  {
      id_cidade: "1291",
      id_estado: "10",
      nm_cidade: "Santa Inês",
      nm_cidade_uf: "Santa Inês - MA"
  },
  {
      id_cidade: "1292",
      id_estado: "10",
      nm_cidade: "Santa Luzia",
      nm_cidade_uf: "Santa Luzia - MA"
  },
  {
      id_cidade: "1293",
      id_estado: "10",
      nm_cidade: "Santa Luzia do Paruá",
      nm_cidade_uf: "Santa Luzia do Paruá - MA"
  },
  {
      id_cidade: "1294",
      id_estado: "10",
      nm_cidade: "Santa Quitéria do Maranhão",
      nm_cidade_uf: "Santa Quitéria do Maranhão - MA"
  },
  {
      id_cidade: "1295",
      id_estado: "10",
      nm_cidade: "Santa Rita",
      nm_cidade_uf: "Santa Rita - MA"
  },
  {
      id_cidade: "1296",
      id_estado: "10",
      nm_cidade: "Santana do Maranhão",
      nm_cidade_uf: "Santana do Maranhão - MA"
  },
  {
      id_cidade: "1297",
      id_estado: "10",
      nm_cidade: "Santo Amaro do Maranhão",
      nm_cidade_uf: "Santo Amaro do Maranhão - MA"
  },
  {
      id_cidade: "1298",
      id_estado: "10",
      nm_cidade: "Santo Antônio dos Lopes",
      nm_cidade_uf: "Santo Antônio dos Lopes - MA"
  },
  {
      id_cidade: "1299",
      id_estado: "10",
      nm_cidade: "São Benedito do Rio Preto",
      nm_cidade_uf: "São Benedito do Rio Preto - MA"
  },
  {
      id_cidade: "1300",
      id_estado: "10",
      nm_cidade: "São Bento",
      nm_cidade_uf: "São Bento - MA"
  },
  {
      id_cidade: "1301",
      id_estado: "10",
      nm_cidade: "São Bernardo",
      nm_cidade_uf: "São Bernardo - MA"
  },
  {
      id_cidade: "1302",
      id_estado: "10",
      nm_cidade: "São Domingos do Azeitão",
      nm_cidade_uf: "São Domingos do Azeitão - MA"
  },
  {
      id_cidade: "1303",
      id_estado: "10",
      nm_cidade: "São Domingos do Maranhão",
      nm_cidade_uf: "São Domingos do Maranhão - MA"
  },
  {
      id_cidade: "1304",
      id_estado: "10",
      nm_cidade: "São Félix de Balsas",
      nm_cidade_uf: "São Félix de Balsas - MA"
  },
  {
      id_cidade: "1305",
      id_estado: "10",
      nm_cidade: "São Francisco do Brejão",
      nm_cidade_uf: "São Francisco do Brejão - MA"
  },
  {
      id_cidade: "1306",
      id_estado: "10",
      nm_cidade: "São Francisco do Maranhão",
      nm_cidade_uf: "São Francisco do Maranhão - MA"
  },
  {
      id_cidade: "1307",
      id_estado: "10",
      nm_cidade: "São João Batista",
      nm_cidade_uf: "São João Batista - MA"
  },
  {
      id_cidade: "1308",
      id_estado: "10",
      nm_cidade: "São João do Carú",
      nm_cidade_uf: "São João do Carú - MA"
  },
  {
      id_cidade: "1309",
      id_estado: "10",
      nm_cidade: "São João do Paraíso",
      nm_cidade_uf: "São João do Paraíso - MA"
  },
  {
      id_cidade: "1310",
      id_estado: "10",
      nm_cidade: "São João do Soter",
      nm_cidade_uf: "São João do Soter - MA"
  },
  {
      id_cidade: "1311",
      id_estado: "10",
      nm_cidade: "São João dos Patos",
      nm_cidade_uf: "São João dos Patos - MA"
  },
  {
      id_cidade: "1312",
      id_estado: "10",
      nm_cidade: "São José de Ribamar",
      nm_cidade_uf: "São José de Ribamar - MA"
  },
  {
      id_cidade: "1313",
      id_estado: "10",
      nm_cidade: "São José dos Basílios",
      nm_cidade_uf: "São José dos Basílios - MA"
  },
  {
      id_cidade: "1314",
      id_estado: "10",
      nm_cidade: "São Luís",
      nm_cidade_uf: "São Luís - MA"
  },
  {
      id_cidade: "1315",
      id_estado: "10",
      nm_cidade: "São Luís Gonzaga do Maranhão",
      nm_cidade_uf: "São Luís Gonzaga do Maranhão - MA"
  },
  {
      id_cidade: "1316",
      id_estado: "10",
      nm_cidade: "São Mateus do Maranhão",
      nm_cidade_uf: "São Mateus do Maranhão - MA"
  },
  {
      id_cidade: "1317",
      id_estado: "10",
      nm_cidade: "São Pedro da Água Branca",
      nm_cidade_uf: "São Pedro da Água Branca - MA"
  },
  {
      id_cidade: "1318",
      id_estado: "10",
      nm_cidade: "São Pedro dos Crentes",
      nm_cidade_uf: "São Pedro dos Crentes - MA"
  },
  {
      id_cidade: "1319",
      id_estado: "10",
      nm_cidade: "São Raimundo das Mangabeiras",
      nm_cidade_uf: "São Raimundo das Mangabeiras - MA"
  },
  {
      id_cidade: "1320",
      id_estado: "10",
      nm_cidade: "São Raimundo do Doca Bezerra",
      nm_cidade_uf: "São Raimundo do Doca Bezerra - MA"
  },
  {
      id_cidade: "1321",
      id_estado: "10",
      nm_cidade: "São Roberto",
      nm_cidade_uf: "São Roberto - MA"
  },
  {
      id_cidade: "1322",
      id_estado: "10",
      nm_cidade: "São Vicente Ferrer",
      nm_cidade_uf: "São Vicente Ferrer - MA"
  },
  {
      id_cidade: "1323",
      id_estado: "10",
      nm_cidade: "Satubinha",
      nm_cidade_uf: "Satubinha - MA"
  },
  {
      id_cidade: "1324",
      id_estado: "10",
      nm_cidade: "Senador Alexandre Costa",
      nm_cidade_uf: "Senador Alexandre Costa - MA"
  },
  {
      id_cidade: "1325",
      id_estado: "10",
      nm_cidade: "Senador La Rocque",
      nm_cidade_uf: "Senador La Rocque - MA"
  },
  {
      id_cidade: "1326",
      id_estado: "10",
      nm_cidade: "Serrano do Maranhão",
      nm_cidade_uf: "Serrano do Maranhão - MA"
  },
  {
      id_cidade: "1327",
      id_estado: "10",
      nm_cidade: "Sítio Novo",
      nm_cidade_uf: "Sítio Novo - MA"
  },
  {
      id_cidade: "1328",
      id_estado: "10",
      nm_cidade: "Sucupira do Norte",
      nm_cidade_uf: "Sucupira do Norte - MA"
  },
  {
      id_cidade: "1329",
      id_estado: "10",
      nm_cidade: "Sucupira do Riachão",
      nm_cidade_uf: "Sucupira do Riachão - MA"
  },
  {
      id_cidade: "1330",
      id_estado: "10",
      nm_cidade: "Tasso Fragoso",
      nm_cidade_uf: "Tasso Fragoso - MA"
  },
  {
      id_cidade: "1331",
      id_estado: "10",
      nm_cidade: "Timbiras",
      nm_cidade_uf: "Timbiras - MA"
  },
  {
      id_cidade: "1332",
      id_estado: "10",
      nm_cidade: "Timon",
      nm_cidade_uf: "Timon - MA"
  },
  {
      id_cidade: "1333",
      id_estado: "10",
      nm_cidade: "Trizidela do Vale",
      nm_cidade_uf: "Trizidela do Vale - MA"
  },
  {
      id_cidade: "1334",
      id_estado: "10",
      nm_cidade: "Tufilândia",
      nm_cidade_uf: "Tufilândia - MA"
  },
  {
      id_cidade: "1335",
      id_estado: "10",
      nm_cidade: "Tuntum",
      nm_cidade_uf: "Tuntum - MA"
  },
  {
      id_cidade: "1336",
      id_estado: "10",
      nm_cidade: "Turiaçu",
      nm_cidade_uf: "Turiaçu - MA"
  },
  {
      id_cidade: "1337",
      id_estado: "10",
      nm_cidade: "Turilândia",
      nm_cidade_uf: "Turilândia - MA"
  },
  {
      id_cidade: "1338",
      id_estado: "10",
      nm_cidade: "Tutóia",
      nm_cidade_uf: "Tutóia - MA"
  },
  {
      id_cidade: "1339",
      id_estado: "10",
      nm_cidade: "Urbano Santos",
      nm_cidade_uf: "Urbano Santos - MA"
  },
  {
      id_cidade: "1340",
      id_estado: "10",
      nm_cidade: "Vargem Grande",
      nm_cidade_uf: "Vargem Grande - MA"
  },
  {
      id_cidade: "1341",
      id_estado: "10",
      nm_cidade: "Viana",
      nm_cidade_uf: "Viana - MA"
  },
  {
      id_cidade: "1342",
      id_estado: "10",
      nm_cidade: "Vila Nova dos Martírios",
      nm_cidade_uf: "Vila Nova dos Martírios - MA"
  },
  {
      id_cidade: "1343",
      id_estado: "10",
      nm_cidade: "Vitória do Mearim",
      nm_cidade_uf: "Vitória do Mearim - MA"
  },
  {
      id_cidade: "1344",
      id_estado: "10",
      nm_cidade: "Vitorino Freire",
      nm_cidade_uf: "Vitorino Freire - MA"
  },
  {
      id_cidade: "1345",
      id_estado: "10",
      nm_cidade: "Zé Doca",
      nm_cidade_uf: "Zé Doca - MA"
  },
  {
      id_cidade: "1346",
      id_estado: "13",
      nm_cidade: "Acorizal",
      nm_cidade_uf: "Acorizal - MT"
  },
  {
      id_cidade: "1347",
      id_estado: "13",
      nm_cidade: "Água Boa",
      nm_cidade_uf: "Água Boa - MT"
  },
  {
      id_cidade: "1348",
      id_estado: "13",
      nm_cidade: "Alta Floresta",
      nm_cidade_uf: "Alta Floresta - MT"
  },
  {
      id_cidade: "1349",
      id_estado: "13",
      nm_cidade: "Alto Araguaia",
      nm_cidade_uf: "Alto Araguaia - MT"
  },
  {
      id_cidade: "1350",
      id_estado: "13",
      nm_cidade: "Alto Boa Vista",
      nm_cidade_uf: "Alto Boa Vista - MT"
  },
  {
      id_cidade: "1351",
      id_estado: "13",
      nm_cidade: "Alto Garças",
      nm_cidade_uf: "Alto Garças - MT"
  },
  {
      id_cidade: "1352",
      id_estado: "13",
      nm_cidade: "Alto Paraguai",
      nm_cidade_uf: "Alto Paraguai - MT"
  },
  {
      id_cidade: "1353",
      id_estado: "13",
      nm_cidade: "Alto Taquari",
      nm_cidade_uf: "Alto Taquari - MT"
  },
  {
      id_cidade: "1354",
      id_estado: "13",
      nm_cidade: "Apiacás",
      nm_cidade_uf: "Apiacás - MT"
  },
  {
      id_cidade: "1355",
      id_estado: "13",
      nm_cidade: "Araguaiana",
      nm_cidade_uf: "Araguaiana - MT"
  },
  {
      id_cidade: "1356",
      id_estado: "13",
      nm_cidade: "Araguainha",
      nm_cidade_uf: "Araguainha - MT"
  },
  {
      id_cidade: "1357",
      id_estado: "13",
      nm_cidade: "Araputanga",
      nm_cidade_uf: "Araputanga - MT"
  },
  {
      id_cidade: "1358",
      id_estado: "13",
      nm_cidade: "Arenápolis",
      nm_cidade_uf: "Arenápolis - MT"
  },
  {
      id_cidade: "1359",
      id_estado: "13",
      nm_cidade: "Aripuanã",
      nm_cidade_uf: "Aripuanã - MT"
  },
  {
      id_cidade: "1360",
      id_estado: "13",
      nm_cidade: "Barão de Melgaço",
      nm_cidade_uf: "Barão de Melgaço - MT"
  },
  {
      id_cidade: "1361",
      id_estado: "13",
      nm_cidade: "Barra do Bugres",
      nm_cidade_uf: "Barra do Bugres - MT"
  },
  {
      id_cidade: "1362",
      id_estado: "13",
      nm_cidade: "Barra do Garças",
      nm_cidade_uf: "Barra do Garças - MT"
  },
  {
      id_cidade: "1363",
      id_estado: "13",
      nm_cidade: "Bom Jesus do Araguaia",
      nm_cidade_uf: "Bom Jesus do Araguaia - MT"
  },
  {
      id_cidade: "1364",
      id_estado: "13",
      nm_cidade: "Brasnorte",
      nm_cidade_uf: "Brasnorte - MT"
  },
  {
      id_cidade: "1365",
      id_estado: "13",
      nm_cidade: "Cáceres",
      nm_cidade_uf: "Cáceres - MT"
  },
  {
      id_cidade: "1366",
      id_estado: "13",
      nm_cidade: "Campinápolis",
      nm_cidade_uf: "Campinápolis - MT"
  },
  {
      id_cidade: "1367",
      id_estado: "13",
      nm_cidade: "Campo Novo do Parecis",
      nm_cidade_uf: "Campo Novo do Parecis - MT"
  },
  {
      id_cidade: "1368",
      id_estado: "13",
      nm_cidade: "Campo Verde",
      nm_cidade_uf: "Campo Verde - MT"
  },
  {
      id_cidade: "1369",
      id_estado: "13",
      nm_cidade: "Campos de Júlio",
      nm_cidade_uf: "Campos de Júlio - MT"
  },
  {
      id_cidade: "1370",
      id_estado: "13",
      nm_cidade: "Canabrava do Norte",
      nm_cidade_uf: "Canabrava do Norte - MT"
  },
  {
      id_cidade: "1371",
      id_estado: "13",
      nm_cidade: "Canarana",
      nm_cidade_uf: "Canarana - MT"
  },
  {
      id_cidade: "1372",
      id_estado: "13",
      nm_cidade: "Carlinda",
      nm_cidade_uf: "Carlinda - MT"
  },
  {
      id_cidade: "1373",
      id_estado: "13",
      nm_cidade: "Castanheira",
      nm_cidade_uf: "Castanheira - MT"
  },
  {
      id_cidade: "1374",
      id_estado: "13",
      nm_cidade: "Chapada dos Guimarães",
      nm_cidade_uf: "Chapada dos Guimarães - MT"
  },
  {
      id_cidade: "1375",
      id_estado: "13",
      nm_cidade: "Cláudia",
      nm_cidade_uf: "Cláudia - MT"
  },
  {
      id_cidade: "1376",
      id_estado: "13",
      nm_cidade: "Cocalinho",
      nm_cidade_uf: "Cocalinho - MT"
  },
  {
      id_cidade: "1377",
      id_estado: "13",
      nm_cidade: "Colíder",
      nm_cidade_uf: "Colíder - MT"
  },
  {
      id_cidade: "1378",
      id_estado: "13",
      nm_cidade: "Colniza",
      nm_cidade_uf: "Colniza - MT"
  },
  {
      id_cidade: "1379",
      id_estado: "13",
      nm_cidade: "Comodoro",
      nm_cidade_uf: "Comodoro - MT"
  },
  {
      id_cidade: "1380",
      id_estado: "13",
      nm_cidade: "Confresa",
      nm_cidade_uf: "Confresa - MT"
  },
  {
      id_cidade: "1381",
      id_estado: "13",
      nm_cidade: "Conquista d`Oeste",
      nm_cidade_uf: "Conquista d`Oeste - MT"
  },
  {
      id_cidade: "1382",
      id_estado: "13",
      nm_cidade: "Cotriguaçu",
      nm_cidade_uf: "Cotriguaçu - MT"
  },
  {
      id_cidade: "1383",
      id_estado: "13",
      nm_cidade: "Cuiabá",
      nm_cidade_uf: "Cuiabá - MT"
  },
  {
      id_cidade: "1384",
      id_estado: "13",
      nm_cidade: "Curvelândia",
      nm_cidade_uf: "Curvelândia - MT"
  },
  {
      id_cidade: "1385",
      id_estado: "13",
      nm_cidade: "Curvelândia",
      nm_cidade_uf: "Curvelândia - MT"
  },
  {
      id_cidade: "1386",
      id_estado: "13",
      nm_cidade: "Denise",
      nm_cidade_uf: "Denise - MT"
  },
  {
      id_cidade: "1387",
      id_estado: "13",
      nm_cidade: "Diamantino",
      nm_cidade_uf: "Diamantino - MT"
  },
  {
      id_cidade: "1388",
      id_estado: "13",
      nm_cidade: "Dom Aquino",
      nm_cidade_uf: "Dom Aquino - MT"
  },
  {
      id_cidade: "1389",
      id_estado: "13",
      nm_cidade: "Feliz Natal",
      nm_cidade_uf: "Feliz Natal - MT"
  },
  {
      id_cidade: "1390",
      id_estado: "13",
      nm_cidade: "Figueirópolis d`Oeste",
      nm_cidade_uf: "Figueirópolis d`Oeste - MT"
  },
  {
      id_cidade: "1391",
      id_estado: "13",
      nm_cidade: "Gaúcha do Norte",
      nm_cidade_uf: "Gaúcha do Norte - MT"
  },
  {
      id_cidade: "1392",
      id_estado: "13",
      nm_cidade: "General Carneiro",
      nm_cidade_uf: "General Carneiro - MT"
  },
  {
      id_cidade: "1393",
      id_estado: "13",
      nm_cidade: "Glória d`Oeste",
      nm_cidade_uf: "Glória d`Oeste - MT"
  },
  {
      id_cidade: "1394",
      id_estado: "13",
      nm_cidade: "Guarantã do Norte",
      nm_cidade_uf: "Guarantã do Norte - MT"
  },
  {
      id_cidade: "1395",
      id_estado: "13",
      nm_cidade: "Guiratinga",
      nm_cidade_uf: "Guiratinga - MT"
  },
  {
      id_cidade: "1396",
      id_estado: "13",
      nm_cidade: "Indiavaí",
      nm_cidade_uf: "Indiavaí - MT"
  },
  {
      id_cidade: "1397",
      id_estado: "13",
      nm_cidade: "Ipiranga do Norte",
      nm_cidade_uf: "Ipiranga do Norte - MT"
  },
  {
      id_cidade: "1398",
      id_estado: "13",
      nm_cidade: "Itanhangá",
      nm_cidade_uf: "Itanhangá - MT"
  },
  {
      id_cidade: "1399",
      id_estado: "13",
      nm_cidade: "Itaúba",
      nm_cidade_uf: "Itaúba - MT"
  },
  {
      id_cidade: "1400",
      id_estado: "13",
      nm_cidade: "Itiquira",
      nm_cidade_uf: "Itiquira - MT"
  },
  {
      id_cidade: "1401",
      id_estado: "13",
      nm_cidade: "Jaciara",
      nm_cidade_uf: "Jaciara - MT"
  },
  {
      id_cidade: "1402",
      id_estado: "13",
      nm_cidade: "Jangada",
      nm_cidade_uf: "Jangada - MT"
  },
  {
      id_cidade: "1403",
      id_estado: "13",
      nm_cidade: "Jauru",
      nm_cidade_uf: "Jauru - MT"
  },
  {
      id_cidade: "1404",
      id_estado: "13",
      nm_cidade: "Juara",
      nm_cidade_uf: "Juara - MT"
  },
  {
      id_cidade: "1405",
      id_estado: "13",
      nm_cidade: "Juína",
      nm_cidade_uf: "Juína - MT"
  },
  {
      id_cidade: "1406",
      id_estado: "13",
      nm_cidade: "Juruena",
      nm_cidade_uf: "Juruena - MT"
  },
  {
      id_cidade: "1407",
      id_estado: "13",
      nm_cidade: "Juscimeira",
      nm_cidade_uf: "Juscimeira - MT"
  },
  {
      id_cidade: "1408",
      id_estado: "13",
      nm_cidade: "Lambari d`Oeste",
      nm_cidade_uf: "Lambari d`Oeste - MT"
  },
  {
      id_cidade: "1409",
      id_estado: "13",
      nm_cidade: "Lucas do Rio Verde",
      nm_cidade_uf: "Lucas do Rio Verde - MT"
  },
  {
      id_cidade: "1410",
      id_estado: "13",
      nm_cidade: "Luciára",
      nm_cidade_uf: "Luciára - MT"
  },
  {
      id_cidade: "1411",
      id_estado: "13",
      nm_cidade: "Marcelândia",
      nm_cidade_uf: "Marcelândia - MT"
  },
  {
      id_cidade: "1412",
      id_estado: "13",
      nm_cidade: "Matupá",
      nm_cidade_uf: "Matupá - MT"
  },
  {
      id_cidade: "1413",
      id_estado: "13",
      nm_cidade: "Mirassol d`Oeste",
      nm_cidade_uf: "Mirassol d`Oeste - MT"
  },
  {
      id_cidade: "1414",
      id_estado: "13",
      nm_cidade: "Nobres",
      nm_cidade_uf: "Nobres - MT"
  },
  {
      id_cidade: "1415",
      id_estado: "13",
      nm_cidade: "Nortelândia",
      nm_cidade_uf: "Nortelândia - MT"
  },
  {
      id_cidade: "1416",
      id_estado: "13",
      nm_cidade: "Nossa Senhora do Livramento",
      nm_cidade_uf: "Nossa Senhora do Livramento - MT"
  },
  {
      id_cidade: "1417",
      id_estado: "13",
      nm_cidade: "Nova Bandeirantes",
      nm_cidade_uf: "Nova Bandeirantes - MT"
  },
  {
      id_cidade: "1418",
      id_estado: "13",
      nm_cidade: "Nova Brasilândia",
      nm_cidade_uf: "Nova Brasilândia - MT"
  },
  {
      id_cidade: "1419",
      id_estado: "13",
      nm_cidade: "Nova Canaã do Norte",
      nm_cidade_uf: "Nova Canaã do Norte - MT"
  },
  {
      id_cidade: "1420",
      id_estado: "13",
      nm_cidade: "Nova Guarita",
      nm_cidade_uf: "Nova Guarita - MT"
  },
  {
      id_cidade: "1421",
      id_estado: "13",
      nm_cidade: "Nova Lacerda",
      nm_cidade_uf: "Nova Lacerda - MT"
  },
  {
      id_cidade: "1422",
      id_estado: "13",
      nm_cidade: "Nova Marilândia",
      nm_cidade_uf: "Nova Marilândia - MT"
  },
  {
      id_cidade: "1423",
      id_estado: "13",
      nm_cidade: "Nova Maringá",
      nm_cidade_uf: "Nova Maringá - MT"
  },
  {
      id_cidade: "1424",
      id_estado: "13",
      nm_cidade: "Nova Monte verde",
      nm_cidade_uf: "Nova Monte verde - MT"
  },
  {
      id_cidade: "1425",
      id_estado: "13",
      nm_cidade: "Nova Mutum",
      nm_cidade_uf: "Nova Mutum - MT"
  },
  {
      id_cidade: "1426",
      id_estado: "13",
      nm_cidade: "Nova Olímpia",
      nm_cidade_uf: "Nova Olímpia - MT"
  },
  {
      id_cidade: "1427",
      id_estado: "13",
      nm_cidade: "Nova Santa Helena",
      nm_cidade_uf: "Nova Santa Helena - MT"
  },
  {
      id_cidade: "1428",
      id_estado: "13",
      nm_cidade: "Nova Ubiratã",
      nm_cidade_uf: "Nova Ubiratã - MT"
  },
  {
      id_cidade: "1429",
      id_estado: "13",
      nm_cidade: "Nova Xavantina",
      nm_cidade_uf: "Nova Xavantina - MT"
  },
  {
      id_cidade: "1430",
      id_estado: "13",
      nm_cidade: "Novo Horizonte do Norte",
      nm_cidade_uf: "Novo Horizonte do Norte - MT"
  },
  {
      id_cidade: "1431",
      id_estado: "13",
      nm_cidade: "Novo Mundo",
      nm_cidade_uf: "Novo Mundo - MT"
  },
  {
      id_cidade: "1432",
      id_estado: "13",
      nm_cidade: "Novo Santo Antônio",
      nm_cidade_uf: "Novo Santo Antônio - MT"
  },
  {
      id_cidade: "1433",
      id_estado: "13",
      nm_cidade: "Novo São Joaquim",
      nm_cidade_uf: "Novo São Joaquim - MT"
  },
  {
      id_cidade: "1434",
      id_estado: "13",
      nm_cidade: "Paranaíta",
      nm_cidade_uf: "Paranaíta - MT"
  },
  {
      id_cidade: "1435",
      id_estado: "13",
      nm_cidade: "Paranatinga",
      nm_cidade_uf: "Paranatinga - MT"
  },
  {
      id_cidade: "1436",
      id_estado: "13",
      nm_cidade: "Pedra Preta",
      nm_cidade_uf: "Pedra Preta - MT"
  },
  {
      id_cidade: "1437",
      id_estado: "13",
      nm_cidade: "Peixoto de Azevedo",
      nm_cidade_uf: "Peixoto de Azevedo - MT"
  },
  {
      id_cidade: "1438",
      id_estado: "13",
      nm_cidade: "Planalto da Serra",
      nm_cidade_uf: "Planalto da Serra - MT"
  },
  {
      id_cidade: "1439",
      id_estado: "13",
      nm_cidade: "Poconé",
      nm_cidade_uf: "Poconé - MT"
  },
  {
      id_cidade: "1440",
      id_estado: "13",
      nm_cidade: "Pontal do Araguaia",
      nm_cidade_uf: "Pontal do Araguaia - MT"
  },
  {
      id_cidade: "1441",
      id_estado: "13",
      nm_cidade: "Ponte Branca",
      nm_cidade_uf: "Ponte Branca - MT"
  },
  {
      id_cidade: "1442",
      id_estado: "13",
      nm_cidade: "Pontes e Lacerda",
      nm_cidade_uf: "Pontes e Lacerda - MT"
  },
  {
      id_cidade: "1443",
      id_estado: "13",
      nm_cidade: "Porto Alegre do Norte",
      nm_cidade_uf: "Porto Alegre do Norte - MT"
  },
  {
      id_cidade: "1444",
      id_estado: "13",
      nm_cidade: "Porto dos Gaúchos",
      nm_cidade_uf: "Porto dos Gaúchos - MT"
  },
  {
      id_cidade: "1445",
      id_estado: "13",
      nm_cidade: "Porto Esperidião",
      nm_cidade_uf: "Porto Esperidião - MT"
  },
  {
      id_cidade: "1446",
      id_estado: "13",
      nm_cidade: "Porto Estrela",
      nm_cidade_uf: "Porto Estrela - MT"
  },
  {
      id_cidade: "1447",
      id_estado: "13",
      nm_cidade: "Poxoréo",
      nm_cidade_uf: "Poxoréo - MT"
  },
  {
      id_cidade: "1448",
      id_estado: "13",
      nm_cidade: "Primavera do Leste",
      nm_cidade_uf: "Primavera do Leste - MT"
  },
  {
      id_cidade: "1449",
      id_estado: "13",
      nm_cidade: "Querência",
      nm_cidade_uf: "Querência - MT"
  },
  {
      id_cidade: "1450",
      id_estado: "13",
      nm_cidade: "Reserva do Cabaçal",
      nm_cidade_uf: "Reserva do Cabaçal - MT"
  },
  {
      id_cidade: "1451",
      id_estado: "13",
      nm_cidade: "Ribeirão Cascalheira",
      nm_cidade_uf: "Ribeirão Cascalheira - MT"
  },
  {
      id_cidade: "1452",
      id_estado: "13",
      nm_cidade: "Ribeirãozinho",
      nm_cidade_uf: "Ribeirãozinho - MT"
  },
  {
      id_cidade: "1453",
      id_estado: "13",
      nm_cidade: "Rio Branco",
      nm_cidade_uf: "Rio Branco - MT"
  },
  {
      id_cidade: "1454",
      id_estado: "13",
      nm_cidade: "Rondolândia",
      nm_cidade_uf: "Rondolândia - MT"
  },
  {
      id_cidade: "1455",
      id_estado: "13",
      nm_cidade: "Rondonópolis",
      nm_cidade_uf: "Rondonópolis - MT"
  },
  {
      id_cidade: "1456",
      id_estado: "13",
      nm_cidade: "Rosário Oeste",
      nm_cidade_uf: "Rosário Oeste - MT"
  },
  {
      id_cidade: "1457",
      id_estado: "13",
      nm_cidade: "Salto do Céu",
      nm_cidade_uf: "Salto do Céu - MT"
  },
  {
      id_cidade: "1458",
      id_estado: "13",
      nm_cidade: "Santa Carmem",
      nm_cidade_uf: "Santa Carmem - MT"
  },
  {
      id_cidade: "1459",
      id_estado: "13",
      nm_cidade: "Santa Cruz do Xingu",
      nm_cidade_uf: "Santa Cruz do Xingu - MT"
  },
  {
      id_cidade: "1460",
      id_estado: "13",
      nm_cidade: "Santa Rita do Trivelato",
      nm_cidade_uf: "Santa Rita do Trivelato - MT"
  },
  {
      id_cidade: "1461",
      id_estado: "13",
      nm_cidade: "Santa Terezinha",
      nm_cidade_uf: "Santa Terezinha - MT"
  },
  {
      id_cidade: "1462",
      id_estado: "13",
      nm_cidade: "Santo Afonso",
      nm_cidade_uf: "Santo Afonso - MT"
  },
  {
      id_cidade: "1463",
      id_estado: "13",
      nm_cidade: "Santo Antônio do Leste",
      nm_cidade_uf: "Santo Antônio do Leste - MT"
  },
  {
      id_cidade: "1464",
      id_estado: "13",
      nm_cidade: "Santo Antônio do Leverger",
      nm_cidade_uf: "Santo Antônio do Leverger - MT"
  },
  {
      id_cidade: "1465",
      id_estado: "13",
      nm_cidade: "São Félix do Araguaia",
      nm_cidade_uf: "São Félix do Araguaia - MT"
  },
  {
      id_cidade: "1466",
      id_estado: "13",
      nm_cidade: "São José do Povo",
      nm_cidade_uf: "São José do Povo - MT"
  },
  {
      id_cidade: "1467",
      id_estado: "13",
      nm_cidade: "São José do Rio Claro",
      nm_cidade_uf: "São José do Rio Claro - MT"
  },
  {
      id_cidade: "1468",
      id_estado: "13",
      nm_cidade: "São José do Xingu",
      nm_cidade_uf: "São José do Xingu - MT"
  },
  {
      id_cidade: "1469",
      id_estado: "13",
      nm_cidade: "São José dos Quatro Marcos",
      nm_cidade_uf: "São José dos Quatro Marcos - MT"
  },
  {
      id_cidade: "1470",
      id_estado: "13",
      nm_cidade: "São Pedro da Cipa",
      nm_cidade_uf: "São Pedro da Cipa - MT"
  },
  {
      id_cidade: "1471",
      id_estado: "13",
      nm_cidade: "Sapezal",
      nm_cidade_uf: "Sapezal - MT"
  },
  {
      id_cidade: "1472",
      id_estado: "13",
      nm_cidade: "Serra Nova Dourada",
      nm_cidade_uf: "Serra Nova Dourada - MT"
  },
  {
      id_cidade: "1473",
      id_estado: "13",
      nm_cidade: "Sinop",
      nm_cidade_uf: "Sinop - MT"
  },
  {
      id_cidade: "1474",
      id_estado: "13",
      nm_cidade: "Sorriso",
      nm_cidade_uf: "Sorriso - MT"
  },
  {
      id_cidade: "1475",
      id_estado: "13",
      nm_cidade: "Tabaporã",
      nm_cidade_uf: "Tabaporã - MT"
  },
  {
      id_cidade: "1476",
      id_estado: "13",
      nm_cidade: "Tangará da Serra",
      nm_cidade_uf: "Tangará da Serra - MT"
  },
  {
      id_cidade: "1477",
      id_estado: "13",
      nm_cidade: "Tapurah",
      nm_cidade_uf: "Tapurah - MT"
  },
  {
      id_cidade: "1478",
      id_estado: "13",
      nm_cidade: "Terra Nova do Norte",
      nm_cidade_uf: "Terra Nova do Norte - MT"
  },
  {
      id_cidade: "1479",
      id_estado: "13",
      nm_cidade: "Tesouro",
      nm_cidade_uf: "Tesouro - MT"
  },
  {
      id_cidade: "1480",
      id_estado: "13",
      nm_cidade: "Torixoréu",
      nm_cidade_uf: "Torixoréu - MT"
  },
  {
      id_cidade: "1481",
      id_estado: "13",
      nm_cidade: "União do Sul",
      nm_cidade_uf: "União do Sul - MT"
  },
  {
      id_cidade: "1482",
      id_estado: "13",
      nm_cidade: "Vale de São Domingos",
      nm_cidade_uf: "Vale de São Domingos - MT"
  },
  {
      id_cidade: "1483",
      id_estado: "13",
      nm_cidade: "Várzea Grande",
      nm_cidade_uf: "Várzea Grande - MT"
  },
  {
      id_cidade: "1484",
      id_estado: "13",
      nm_cidade: "Vera",
      nm_cidade_uf: "Vera - MT"
  },
  {
      id_cidade: "1485",
      id_estado: "13",
      nm_cidade: "Vila Bela da Santíssima Trindade",
      nm_cidade_uf: "Vila Bela da Santíssima Trindade - MT"
  },
  {
      id_cidade: "1486",
      id_estado: "13",
      nm_cidade: "Vila Rica",
      nm_cidade_uf: "Vila Rica - MT"
  },
  {
      id_cidade: "1487",
      id_estado: "12",
      nm_cidade: "Água Clara",
      nm_cidade_uf: "Água Clara - MS"
  },
  {
      id_cidade: "1488",
      id_estado: "12",
      nm_cidade: "Alcinópolis",
      nm_cidade_uf: "Alcinópolis - MS"
  },
  {
      id_cidade: "1489",
      id_estado: "12",
      nm_cidade: "Amambaí",
      nm_cidade_uf: "Amambaí - MS"
  },
  {
      id_cidade: "1490",
      id_estado: "12",
      nm_cidade: "Anastácio",
      nm_cidade_uf: "Anastácio - MS"
  },
  {
      id_cidade: "1491",
      id_estado: "12",
      nm_cidade: "Anaurilândia",
      nm_cidade_uf: "Anaurilândia - MS"
  },
  {
      id_cidade: "1492",
      id_estado: "12",
      nm_cidade: "Angélica",
      nm_cidade_uf: "Angélica - MS"
  },
  {
      id_cidade: "1493",
      id_estado: "12",
      nm_cidade: "Antônio João",
      nm_cidade_uf: "Antônio João - MS"
  },
  {
      id_cidade: "1494",
      id_estado: "12",
      nm_cidade: "Aparecida do Taboado",
      nm_cidade_uf: "Aparecida do Taboado - MS"
  },
  {
      id_cidade: "1495",
      id_estado: "12",
      nm_cidade: "Aquidauana",
      nm_cidade_uf: "Aquidauana - MS"
  },
  {
      id_cidade: "1496",
      id_estado: "12",
      nm_cidade: "Aral Moreira",
      nm_cidade_uf: "Aral Moreira - MS"
  },
  {
      id_cidade: "1497",
      id_estado: "12",
      nm_cidade: "Bandeirantes",
      nm_cidade_uf: "Bandeirantes - MS"
  },
  {
      id_cidade: "1498",
      id_estado: "12",
      nm_cidade: "Bataguassu",
      nm_cidade_uf: "Bataguassu - MS"
  },
  {
      id_cidade: "1499",
      id_estado: "12",
      nm_cidade: "Bataiporã",
      nm_cidade_uf: "Bataiporã - MS"
  },
  {
      id_cidade: "1500",
      id_estado: "12",
      nm_cidade: "Bela Vista",
      nm_cidade_uf: "Bela Vista - MS"
  },
  {
      id_cidade: "1501",
      id_estado: "12",
      nm_cidade: "Bodoquena",
      nm_cidade_uf: "Bodoquena - MS"
  },
  {
      id_cidade: "1502",
      id_estado: "12",
      nm_cidade: "Bonito",
      nm_cidade_uf: "Bonito - MS"
  },
  {
      id_cidade: "1503",
      id_estado: "12",
      nm_cidade: "Brasilândia",
      nm_cidade_uf: "Brasilândia - MS"
  },
  {
      id_cidade: "1504",
      id_estado: "12",
      nm_cidade: "Caarapó",
      nm_cidade_uf: "Caarapó - MS"
  },
  {
      id_cidade: "1505",
      id_estado: "12",
      nm_cidade: "Camapuã",
      nm_cidade_uf: "Camapuã - MS"
  },
  {
      id_cidade: "1506",
      id_estado: "12",
      nm_cidade: "Campo Grande",
      nm_cidade_uf: "Campo Grande - MS"
  },
  {
      id_cidade: "1507",
      id_estado: "12",
      nm_cidade: "Caracol",
      nm_cidade_uf: "Caracol - MS"
  },
  {
      id_cidade: "1508",
      id_estado: "12",
      nm_cidade: "Cassilândia",
      nm_cidade_uf: "Cassilândia - MS"
  },
  {
      id_cidade: "1509",
      id_estado: "12",
      nm_cidade: "Chapadão do Sul",
      nm_cidade_uf: "Chapadão do Sul - MS"
  },
  {
      id_cidade: "1510",
      id_estado: "12",
      nm_cidade: "Corguinho",
      nm_cidade_uf: "Corguinho - MS"
  },
  {
      id_cidade: "1511",
      id_estado: "12",
      nm_cidade: "Coronel Sapucaia",
      nm_cidade_uf: "Coronel Sapucaia - MS"
  },
  {
      id_cidade: "1512",
      id_estado: "12",
      nm_cidade: "Corumbá",
      nm_cidade_uf: "Corumbá - MS"
  },
  {
      id_cidade: "1513",
      id_estado: "12",
      nm_cidade: "Costa Rica",
      nm_cidade_uf: "Costa Rica - MS"
  },
  {
      id_cidade: "1514",
      id_estado: "12",
      nm_cidade: "Coxim",
      nm_cidade_uf: "Coxim - MS"
  },
  {
      id_cidade: "1515",
      id_estado: "12",
      nm_cidade: "Deodápolis",
      nm_cidade_uf: "Deodápolis - MS"
  },
  {
      id_cidade: "1516",
      id_estado: "12",
      nm_cidade: "Dois Irmãos do Buriti",
      nm_cidade_uf: "Dois Irmãos do Buriti - MS"
  },
  {
      id_cidade: "1517",
      id_estado: "12",
      nm_cidade: "Douradina",
      nm_cidade_uf: "Douradina - MS"
  },
  {
      id_cidade: "1518",
      id_estado: "12",
      nm_cidade: "Dourados",
      nm_cidade_uf: "Dourados - MS"
  },
  {
      id_cidade: "1519",
      id_estado: "12",
      nm_cidade: "Eldorado",
      nm_cidade_uf: "Eldorado - MS"
  },
  {
      id_cidade: "1520",
      id_estado: "12",
      nm_cidade: "Fátima do Sul",
      nm_cidade_uf: "Fátima do Sul - MS"
  },
  {
      id_cidade: "1521",
      id_estado: "12",
      nm_cidade: "Figueirão",
      nm_cidade_uf: "Figueirão - MS"
  },
  {
      id_cidade: "1522",
      id_estado: "12",
      nm_cidade: "Glória de Dourados",
      nm_cidade_uf: "Glória de Dourados - MS"
  },
  {
      id_cidade: "1523",
      id_estado: "12",
      nm_cidade: "Guia Lopes da Laguna",
      nm_cidade_uf: "Guia Lopes da Laguna - MS"
  },
  {
      id_cidade: "1524",
      id_estado: "12",
      nm_cidade: "Iguatemi",
      nm_cidade_uf: "Iguatemi - MS"
  },
  {
      id_cidade: "1525",
      id_estado: "12",
      nm_cidade: "Inocência",
      nm_cidade_uf: "Inocência - MS"
  },
  {
      id_cidade: "1526",
      id_estado: "12",
      nm_cidade: "Itaporã",
      nm_cidade_uf: "Itaporã - MS"
  },
  {
      id_cidade: "1527",
      id_estado: "12",
      nm_cidade: "Itaquiraí",
      nm_cidade_uf: "Itaquiraí - MS"
  },
  {
      id_cidade: "1528",
      id_estado: "12",
      nm_cidade: "Ivinhema",
      nm_cidade_uf: "Ivinhema - MS"
  },
  {
      id_cidade: "1529",
      id_estado: "12",
      nm_cidade: "Japorã",
      nm_cidade_uf: "Japorã - MS"
  },
  {
      id_cidade: "1530",
      id_estado: "12",
      nm_cidade: "Jaraguari",
      nm_cidade_uf: "Jaraguari - MS"
  },
  {
      id_cidade: "1531",
      id_estado: "12",
      nm_cidade: "Jardim",
      nm_cidade_uf: "Jardim - MS"
  },
  {
      id_cidade: "1532",
      id_estado: "12",
      nm_cidade: "Jateí",
      nm_cidade_uf: "Jateí - MS"
  },
  {
      id_cidade: "1533",
      id_estado: "12",
      nm_cidade: "Juti",
      nm_cidade_uf: "Juti - MS"
  },
  {
      id_cidade: "1534",
      id_estado: "12",
      nm_cidade: "Ladário",
      nm_cidade_uf: "Ladário - MS"
  },
  {
      id_cidade: "1535",
      id_estado: "12",
      nm_cidade: "Laguna Carapã",
      nm_cidade_uf: "Laguna Carapã - MS"
  },
  {
      id_cidade: "1536",
      id_estado: "12",
      nm_cidade: "Maracaju",
      nm_cidade_uf: "Maracaju - MS"
  },
  {
      id_cidade: "1537",
      id_estado: "12",
      nm_cidade: "Miranda",
      nm_cidade_uf: "Miranda - MS"
  },
  {
      id_cidade: "1538",
      id_estado: "12",
      nm_cidade: "Mundo Novo",
      nm_cidade_uf: "Mundo Novo - MS"
  },
  {
      id_cidade: "1539",
      id_estado: "12",
      nm_cidade: "Naviraí",
      nm_cidade_uf: "Naviraí - MS"
  },
  {
      id_cidade: "1540",
      id_estado: "12",
      nm_cidade: "Nioaque",
      nm_cidade_uf: "Nioaque - MS"
  },
  {
      id_cidade: "1541",
      id_estado: "12",
      nm_cidade: "Nova Alvorada do Sul",
      nm_cidade_uf: "Nova Alvorada do Sul - MS"
  },
  {
      id_cidade: "1542",
      id_estado: "12",
      nm_cidade: "Nova Andradina",
      nm_cidade_uf: "Nova Andradina - MS"
  },
  {
      id_cidade: "1543",
      id_estado: "12",
      nm_cidade: "Novo Horizonte do Sul",
      nm_cidade_uf: "Novo Horizonte do Sul - MS"
  },
  {
      id_cidade: "1544",
      id_estado: "12",
      nm_cidade: "Paranaíba",
      nm_cidade_uf: "Paranaíba - MS"
  },
  {
      id_cidade: "1545",
      id_estado: "12",
      nm_cidade: "Paranhos",
      nm_cidade_uf: "Paranhos - MS"
  },
  {
      id_cidade: "1546",
      id_estado: "12",
      nm_cidade: "Pedro Gomes",
      nm_cidade_uf: "Pedro Gomes - MS"
  },
  {
      id_cidade: "1547",
      id_estado: "12",
      nm_cidade: "Ponta Porã",
      nm_cidade_uf: "Ponta Porã - MS"
  },
  {
      id_cidade: "1548",
      id_estado: "12",
      nm_cidade: "Porto Murtinho",
      nm_cidade_uf: "Porto Murtinho - MS"
  },
  {
      id_cidade: "1549",
      id_estado: "12",
      nm_cidade: "Ribas do Rio Pardo",
      nm_cidade_uf: "Ribas do Rio Pardo - MS"
  },
  {
      id_cidade: "1550",
      id_estado: "12",
      nm_cidade: "Rio Brilhante",
      nm_cidade_uf: "Rio Brilhante - MS"
  },
  {
      id_cidade: "1551",
      id_estado: "12",
      nm_cidade: "Rio Negro",
      nm_cidade_uf: "Rio Negro - MS"
  },
  {
      id_cidade: "1552",
      id_estado: "12",
      nm_cidade: "Rio Verde de Mato Grosso",
      nm_cidade_uf: "Rio Verde de Mato Grosso - MS"
  },
  {
      id_cidade: "1553",
      id_estado: "12",
      nm_cidade: "Rochedo",
      nm_cidade_uf: "Rochedo - MS"
  },
  {
      id_cidade: "1554",
      id_estado: "12",
      nm_cidade: "Santa Rita do Pardo",
      nm_cidade_uf: "Santa Rita do Pardo - MS"
  },
  {
      id_cidade: "1555",
      id_estado: "12",
      nm_cidade: "São Gabriel do Oeste",
      nm_cidade_uf: "São Gabriel do Oeste - MS"
  },
  {
      id_cidade: "1556",
      id_estado: "12",
      nm_cidade: "Selvíria",
      nm_cidade_uf: "Selvíria - MS"
  },
  {
      id_cidade: "1557",
      id_estado: "12",
      nm_cidade: "Sete Quedas",
      nm_cidade_uf: "Sete Quedas - MS"
  },
  {
      id_cidade: "1558",
      id_estado: "12",
      nm_cidade: "Sidrolândia",
      nm_cidade_uf: "Sidrolândia - MS"
  },
  {
      id_cidade: "1559",
      id_estado: "12",
      nm_cidade: "Sonora",
      nm_cidade_uf: "Sonora - MS"
  },
  {
      id_cidade: "1560",
      id_estado: "12",
      nm_cidade: "Tacuru",
      nm_cidade_uf: "Tacuru - MS"
  },
  {
      id_cidade: "1561",
      id_estado: "12",
      nm_cidade: "Taquarussu",
      nm_cidade_uf: "Taquarussu - MS"
  },
  {
      id_cidade: "1562",
      id_estado: "12",
      nm_cidade: "Terenos",
      nm_cidade_uf: "Terenos - MS"
  },
  {
      id_cidade: "1563",
      id_estado: "12",
      nm_cidade: "Três Lagoas",
      nm_cidade_uf: "Três Lagoas - MS"
  },
  {
      id_cidade: "1564",
      id_estado: "12",
      nm_cidade: "Vicentina",
      nm_cidade_uf: "Vicentina - MS"
  },
  {
      id_cidade: "1565",
      id_estado: "11",
      nm_cidade: "Abadia dos Dourados",
      nm_cidade_uf: "Abadia dos Dourados - MG"
  },
  {
      id_cidade: "1566",
      id_estado: "11",
      nm_cidade: "Abaeté",
      nm_cidade_uf: "Abaeté - MG"
  },
  {
      id_cidade: "1567",
      id_estado: "11",
      nm_cidade: "Abre Campo",
      nm_cidade_uf: "Abre Campo - MG"
  },
  {
      id_cidade: "1568",
      id_estado: "11",
      nm_cidade: "Acaiaca",
      nm_cidade_uf: "Acaiaca - MG"
  },
  {
      id_cidade: "1569",
      id_estado: "11",
      nm_cidade: "Açucena",
      nm_cidade_uf: "Açucena - MG"
  },
  {
      id_cidade: "1570",
      id_estado: "11",
      nm_cidade: "Água Boa",
      nm_cidade_uf: "Água Boa - MG"
  },
  {
      id_cidade: "1571",
      id_estado: "11",
      nm_cidade: "Água Comprida",
      nm_cidade_uf: "Água Comprida - MG"
  },
  {
      id_cidade: "1572",
      id_estado: "11",
      nm_cidade: "Aguanil",
      nm_cidade_uf: "Aguanil - MG"
  },
  {
      id_cidade: "1573",
      id_estado: "11",
      nm_cidade: "Águas Formosas",
      nm_cidade_uf: "Águas Formosas - MG"
  },
  {
      id_cidade: "1574",
      id_estado: "11",
      nm_cidade: "Águas Vermelhas",
      nm_cidade_uf: "Águas Vermelhas - MG"
  },
  {
      id_cidade: "1575",
      id_estado: "11",
      nm_cidade: "Aimorés",
      nm_cidade_uf: "Aimorés - MG"
  },
  {
      id_cidade: "1576",
      id_estado: "11",
      nm_cidade: "Aiuruoca",
      nm_cidade_uf: "Aiuruoca - MG"
  },
  {
      id_cidade: "1577",
      id_estado: "11",
      nm_cidade: "Alagoa",
      nm_cidade_uf: "Alagoa - MG"
  },
  {
      id_cidade: "1578",
      id_estado: "11",
      nm_cidade: "Albertina",
      nm_cidade_uf: "Albertina - MG"
  },
  {
      id_cidade: "1579",
      id_estado: "11",
      nm_cidade: "Além Paraíba",
      nm_cidade_uf: "Além Paraíba - MG"
  },
  {
      id_cidade: "1580",
      id_estado: "11",
      nm_cidade: "Alfenas",
      nm_cidade_uf: "Alfenas - MG"
  },
  {
      id_cidade: "1581",
      id_estado: "11",
      nm_cidade: "Alfredo Vasconcelos",
      nm_cidade_uf: "Alfredo Vasconcelos - MG"
  },
  {
      id_cidade: "1582",
      id_estado: "11",
      nm_cidade: "Almenara",
      nm_cidade_uf: "Almenara - MG"
  },
  {
      id_cidade: "1583",
      id_estado: "11",
      nm_cidade: "Alpercata",
      nm_cidade_uf: "Alpercata - MG"
  },
  {
      id_cidade: "1584",
      id_estado: "11",
      nm_cidade: "Alpinópolis",
      nm_cidade_uf: "Alpinópolis - MG"
  },
  {
      id_cidade: "1585",
      id_estado: "11",
      nm_cidade: "Alterosa",
      nm_cidade_uf: "Alterosa - MG"
  },
  {
      id_cidade: "1586",
      id_estado: "11",
      nm_cidade: "Alto Caparaó",
      nm_cidade_uf: "Alto Caparaó - MG"
  },
  {
      id_cidade: "1587",
      id_estado: "11",
      nm_cidade: "Alto Jequitibá",
      nm_cidade_uf: "Alto Jequitibá - MG"
  },
  {
      id_cidade: "1588",
      id_estado: "11",
      nm_cidade: "Alto Rio Doce",
      nm_cidade_uf: "Alto Rio Doce - MG"
  },
  {
      id_cidade: "1589",
      id_estado: "11",
      nm_cidade: "Alvarenga",
      nm_cidade_uf: "Alvarenga - MG"
  },
  {
      id_cidade: "1590",
      id_estado: "11",
      nm_cidade: "Alvinópolis",
      nm_cidade_uf: "Alvinópolis - MG"
  },
  {
      id_cidade: "1591",
      id_estado: "11",
      nm_cidade: "Alvorada de Minas",
      nm_cidade_uf: "Alvorada de Minas - MG"
  },
  {
      id_cidade: "1592",
      id_estado: "11",
      nm_cidade: "Amparo do Serra",
      nm_cidade_uf: "Amparo do Serra - MG"
  },
  {
      id_cidade: "1593",
      id_estado: "11",
      nm_cidade: "Andradas",
      nm_cidade_uf: "Andradas - MG"
  },
  {
      id_cidade: "1594",
      id_estado: "11",
      nm_cidade: "Andrelândia",
      nm_cidade_uf: "Andrelândia - MG"
  },
  {
      id_cidade: "1595",
      id_estado: "11",
      nm_cidade: "Angelândia",
      nm_cidade_uf: "Angelândia - MG"
  },
  {
      id_cidade: "1596",
      id_estado: "11",
      nm_cidade: "Antônio Carlos",
      nm_cidade_uf: "Antônio Carlos - MG"
  },
  {
      id_cidade: "1597",
      id_estado: "11",
      nm_cidade: "Antônio Dias",
      nm_cidade_uf: "Antônio Dias - MG"
  },
  {
      id_cidade: "1598",
      id_estado: "11",
      nm_cidade: "Antônio Prado de Minas",
      nm_cidade_uf: "Antônio Prado de Minas - MG"
  },
  {
      id_cidade: "1599",
      id_estado: "11",
      nm_cidade: "Araçaí",
      nm_cidade_uf: "Araçaí - MG"
  },
  {
      id_cidade: "1600",
      id_estado: "11",
      nm_cidade: "Aracitaba",
      nm_cidade_uf: "Aracitaba - MG"
  },
  {
      id_cidade: "1601",
      id_estado: "11",
      nm_cidade: "Araçuaí",
      nm_cidade_uf: "Araçuaí - MG"
  },
  {
      id_cidade: "1602",
      id_estado: "11",
      nm_cidade: "Araguari",
      nm_cidade_uf: "Araguari - MG"
  },
  {
      id_cidade: "1603",
      id_estado: "11",
      nm_cidade: "Arantina",
      nm_cidade_uf: "Arantina - MG"
  },
  {
      id_cidade: "1604",
      id_estado: "11",
      nm_cidade: "Araponga",
      nm_cidade_uf: "Araponga - MG"
  },
  {
      id_cidade: "1605",
      id_estado: "11",
      nm_cidade: "Araporã",
      nm_cidade_uf: "Araporã - MG"
  },
  {
      id_cidade: "1606",
      id_estado: "11",
      nm_cidade: "Arapuá",
      nm_cidade_uf: "Arapuá - MG"
  },
  {
      id_cidade: "1607",
      id_estado: "11",
      nm_cidade: "Araújos",
      nm_cidade_uf: "Araújos - MG"
  },
  {
      id_cidade: "1608",
      id_estado: "11",
      nm_cidade: "Araxá",
      nm_cidade_uf: "Araxá - MG"
  },
  {
      id_cidade: "1609",
      id_estado: "11",
      nm_cidade: "Arceburgo",
      nm_cidade_uf: "Arceburgo - MG"
  },
  {
      id_cidade: "1610",
      id_estado: "11",
      nm_cidade: "Arcos",
      nm_cidade_uf: "Arcos - MG"
  },
  {
      id_cidade: "1611",
      id_estado: "11",
      nm_cidade: "Areado",
      nm_cidade_uf: "Areado - MG"
  },
  {
      id_cidade: "1612",
      id_estado: "11",
      nm_cidade: "Argirita",
      nm_cidade_uf: "Argirita - MG"
  },
  {
      id_cidade: "1613",
      id_estado: "11",
      nm_cidade: "Aricanduva",
      nm_cidade_uf: "Aricanduva - MG"
  },
  {
      id_cidade: "1614",
      id_estado: "11",
      nm_cidade: "Arinos",
      nm_cidade_uf: "Arinos - MG"
  },
  {
      id_cidade: "1615",
      id_estado: "11",
      nm_cidade: "Astolfo Dutra",
      nm_cidade_uf: "Astolfo Dutra - MG"
  },
  {
      id_cidade: "1616",
      id_estado: "11",
      nm_cidade: "Ataléia",
      nm_cidade_uf: "Ataléia - MG"
  },
  {
      id_cidade: "1617",
      id_estado: "11",
      nm_cidade: "Augusto de Lima",
      nm_cidade_uf: "Augusto de Lima - MG"
  },
  {
      id_cidade: "1618",
      id_estado: "11",
      nm_cidade: "Baependi",
      nm_cidade_uf: "Baependi - MG"
  },
  {
      id_cidade: "1619",
      id_estado: "11",
      nm_cidade: "Baldim",
      nm_cidade_uf: "Baldim - MG"
  },
  {
      id_cidade: "1620",
      id_estado: "11",
      nm_cidade: "Bambuí",
      nm_cidade_uf: "Bambuí - MG"
  },
  {
      id_cidade: "1621",
      id_estado: "11",
      nm_cidade: "Bandeira",
      nm_cidade_uf: "Bandeira - MG"
  },
  {
      id_cidade: "1622",
      id_estado: "11",
      nm_cidade: "Bandeira do Sul",
      nm_cidade_uf: "Bandeira do Sul - MG"
  },
  {
      id_cidade: "1623",
      id_estado: "11",
      nm_cidade: "Barão de Cocais",
      nm_cidade_uf: "Barão de Cocais - MG"
  },
  {
      id_cidade: "1624",
      id_estado: "11",
      nm_cidade: "Barão de Monte Alto",
      nm_cidade_uf: "Barão de Monte Alto - MG"
  },
  {
      id_cidade: "1625",
      id_estado: "11",
      nm_cidade: "Barbacena",
      nm_cidade_uf: "Barbacena - MG"
  },
  {
      id_cidade: "1626",
      id_estado: "11",
      nm_cidade: "Barra Longa",
      nm_cidade_uf: "Barra Longa - MG"
  },
  {
      id_cidade: "1627",
      id_estado: "11",
      nm_cidade: "Barroso",
      nm_cidade_uf: "Barroso - MG"
  },
  {
      id_cidade: "1628",
      id_estado: "11",
      nm_cidade: "Bela Vista de Minas",
      nm_cidade_uf: "Bela Vista de Minas - MG"
  },
  {
      id_cidade: "1629",
      id_estado: "11",
      nm_cidade: "Belmiro Braga",
      nm_cidade_uf: "Belmiro Braga - MG"
  },
  {
      id_cidade: "1630",
      id_estado: "11",
      nm_cidade: "Belo Horizonte",
      nm_cidade_uf: "Belo Horizonte - MG"
  },
  {
      id_cidade: "1631",
      id_estado: "11",
      nm_cidade: "Belo Oriente",
      nm_cidade_uf: "Belo Oriente - MG"
  },
  {
      id_cidade: "1632",
      id_estado: "11",
      nm_cidade: "Belo Vale",
      nm_cidade_uf: "Belo Vale - MG"
  },
  {
      id_cidade: "1633",
      id_estado: "11",
      nm_cidade: "Berilo",
      nm_cidade_uf: "Berilo - MG"
  },
  {
      id_cidade: "1634",
      id_estado: "11",
      nm_cidade: "Berizal",
      nm_cidade_uf: "Berizal - MG"
  },
  {
      id_cidade: "1635",
      id_estado: "11",
      nm_cidade: "Bertópolis",
      nm_cidade_uf: "Bertópolis - MG"
  },
  {
      id_cidade: "1636",
      id_estado: "11",
      nm_cidade: "Betim",
      nm_cidade_uf: "Betim - MG"
  },
  {
      id_cidade: "1637",
      id_estado: "11",
      nm_cidade: "Bias Fortes",
      nm_cidade_uf: "Bias Fortes - MG"
  },
  {
      id_cidade: "1638",
      id_estado: "11",
      nm_cidade: "Bicas",
      nm_cidade_uf: "Bicas - MG"
  },
  {
      id_cidade: "1639",
      id_estado: "11",
      nm_cidade: "Biquinhas",
      nm_cidade_uf: "Biquinhas - MG"
  },
  {
      id_cidade: "1640",
      id_estado: "11",
      nm_cidade: "Boa Esperança",
      nm_cidade_uf: "Boa Esperança - MG"
  },
  {
      id_cidade: "1641",
      id_estado: "11",
      nm_cidade: "Bocaina de Minas",
      nm_cidade_uf: "Bocaina de Minas - MG"
  },
  {
      id_cidade: "1642",
      id_estado: "11",
      nm_cidade: "Bocaiúva",
      nm_cidade_uf: "Bocaiúva - MG"
  },
  {
      id_cidade: "1643",
      id_estado: "11",
      nm_cidade: "Bom Despacho",
      nm_cidade_uf: "Bom Despacho - MG"
  },
  {
      id_cidade: "1644",
      id_estado: "11",
      nm_cidade: "Bom Jardim de Minas",
      nm_cidade_uf: "Bom Jardim de Minas - MG"
  },
  {
      id_cidade: "1645",
      id_estado: "11",
      nm_cidade: "Bom Jesus da Penha",
      nm_cidade_uf: "Bom Jesus da Penha - MG"
  },
  {
      id_cidade: "1646",
      id_estado: "11",
      nm_cidade: "Bom Jesus do Amparo",
      nm_cidade_uf: "Bom Jesus do Amparo - MG"
  },
  {
      id_cidade: "1647",
      id_estado: "11",
      nm_cidade: "Bom Jesus do Galho",
      nm_cidade_uf: "Bom Jesus do Galho - MG"
  },
  {
      id_cidade: "1648",
      id_estado: "11",
      nm_cidade: "Bom Repouso",
      nm_cidade_uf: "Bom Repouso - MG"
  },
  {
      id_cidade: "1649",
      id_estado: "11",
      nm_cidade: "Bom Sucesso",
      nm_cidade_uf: "Bom Sucesso - MG"
  },
  {
      id_cidade: "1650",
      id_estado: "11",
      nm_cidade: "Bonfim",
      nm_cidade_uf: "Bonfim - MG"
  },
  {
      id_cidade: "1651",
      id_estado: "11",
      nm_cidade: "Bonfinópolis de Minas",
      nm_cidade_uf: "Bonfinópolis de Minas - MG"
  },
  {
      id_cidade: "1652",
      id_estado: "11",
      nm_cidade: "Bonito de Minas",
      nm_cidade_uf: "Bonito de Minas - MG"
  },
  {
      id_cidade: "1653",
      id_estado: "11",
      nm_cidade: "Borda da Mata",
      nm_cidade_uf: "Borda da Mata - MG"
  },
  {
      id_cidade: "1654",
      id_estado: "11",
      nm_cidade: "Botelhos",
      nm_cidade_uf: "Botelhos - MG"
  },
  {
      id_cidade: "1655",
      id_estado: "11",
      nm_cidade: "Botumirim",
      nm_cidade_uf: "Botumirim - MG"
  },
  {
      id_cidade: "1656",
      id_estado: "11",
      nm_cidade: "Brás Pires",
      nm_cidade_uf: "Brás Pires - MG"
  },
  {
      id_cidade: "1657",
      id_estado: "11",
      nm_cidade: "Brasilândia de Minas",
      nm_cidade_uf: "Brasilândia de Minas - MG"
  },
  {
      id_cidade: "1658",
      id_estado: "11",
      nm_cidade: "Brasília de Minas",
      nm_cidade_uf: "Brasília de Minas - MG"
  },
  {
      id_cidade: "1659",
      id_estado: "11",
      nm_cidade: "Brasópolis",
      nm_cidade_uf: "Brasópolis - MG"
  },
  {
      id_cidade: "1660",
      id_estado: "11",
      nm_cidade: "Braúnas",
      nm_cidade_uf: "Braúnas - MG"
  },
  {
      id_cidade: "1661",
      id_estado: "11",
      nm_cidade: "Brumadinho",
      nm_cidade_uf: "Brumadinho - MG"
  },
  {
      id_cidade: "1662",
      id_estado: "11",
      nm_cidade: "Bueno Brandão",
      nm_cidade_uf: "Bueno Brandão - MG"
  },
  {
      id_cidade: "1663",
      id_estado: "11",
      nm_cidade: "Buenópolis",
      nm_cidade_uf: "Buenópolis - MG"
  },
  {
      id_cidade: "1664",
      id_estado: "11",
      nm_cidade: "Bugre",
      nm_cidade_uf: "Bugre - MG"
  },
  {
      id_cidade: "1665",
      id_estado: "11",
      nm_cidade: "Buritis",
      nm_cidade_uf: "Buritis - MG"
  },
  {
      id_cidade: "1666",
      id_estado: "11",
      nm_cidade: "Buritizeiro",
      nm_cidade_uf: "Buritizeiro - MG"
  },
  {
      id_cidade: "1667",
      id_estado: "11",
      nm_cidade: "Cabeceira Grande",
      nm_cidade_uf: "Cabeceira Grande - MG"
  },
  {
      id_cidade: "1668",
      id_estado: "11",
      nm_cidade: "Cabo Verde",
      nm_cidade_uf: "Cabo Verde - MG"
  },
  {
      id_cidade: "1669",
      id_estado: "11",
      nm_cidade: "Cachoeira da Prata",
      nm_cidade_uf: "Cachoeira da Prata - MG"
  },
  {
      id_cidade: "1670",
      id_estado: "11",
      nm_cidade: "Cachoeira de Minas",
      nm_cidade_uf: "Cachoeira de Minas - MG"
  },
  {
      id_cidade: "1671",
      id_estado: "11",
      nm_cidade: "Cachoeira de Pajeú",
      nm_cidade_uf: "Cachoeira de Pajeú - MG"
  },
  {
      id_cidade: "1672",
      id_estado: "11",
      nm_cidade: "Cachoeira Dourada",
      nm_cidade_uf: "Cachoeira Dourada - MG"
  },
  {
      id_cidade: "1673",
      id_estado: "11",
      nm_cidade: "Caetanópolis",
      nm_cidade_uf: "Caetanópolis - MG"
  },
  {
      id_cidade: "1674",
      id_estado: "11",
      nm_cidade: "Caeté",
      nm_cidade_uf: "Caeté - MG"
  },
  {
      id_cidade: "1675",
      id_estado: "11",
      nm_cidade: "Caiana",
      nm_cidade_uf: "Caiana - MG"
  },
  {
      id_cidade: "1676",
      id_estado: "11",
      nm_cidade: "Cajuri",
      nm_cidade_uf: "Cajuri - MG"
  },
  {
      id_cidade: "1677",
      id_estado: "11",
      nm_cidade: "Caldas",
      nm_cidade_uf: "Caldas - MG"
  },
  {
      id_cidade: "1678",
      id_estado: "11",
      nm_cidade: "Camacho",
      nm_cidade_uf: "Camacho - MG"
  },
  {
      id_cidade: "1679",
      id_estado: "11",
      nm_cidade: "Camanducaia",
      nm_cidade_uf: "Camanducaia - MG"
  },
  {
      id_cidade: "1680",
      id_estado: "11",
      nm_cidade: "Cambuí",
      nm_cidade_uf: "Cambuí - MG"
  },
  {
      id_cidade: "1681",
      id_estado: "11",
      nm_cidade: "Cambuquira",
      nm_cidade_uf: "Cambuquira - MG"
  },
  {
      id_cidade: "1682",
      id_estado: "11",
      nm_cidade: "Campanário",
      nm_cidade_uf: "Campanário - MG"
  },
  {
      id_cidade: "1683",
      id_estado: "11",
      nm_cidade: "Campanha",
      nm_cidade_uf: "Campanha - MG"
  },
  {
      id_cidade: "1684",
      id_estado: "11",
      nm_cidade: "Campestre",
      nm_cidade_uf: "Campestre - MG"
  },
  {
      id_cidade: "1685",
      id_estado: "11",
      nm_cidade: "Campina Verde",
      nm_cidade_uf: "Campina Verde - MG"
  },
  {
      id_cidade: "1686",
      id_estado: "11",
      nm_cidade: "Campo Azul",
      nm_cidade_uf: "Campo Azul - MG"
  },
  {
      id_cidade: "1687",
      id_estado: "11",
      nm_cidade: "Campo Belo",
      nm_cidade_uf: "Campo Belo - MG"
  },
  {
      id_cidade: "1688",
      id_estado: "11",
      nm_cidade: "Campo do Meio",
      nm_cidade_uf: "Campo do Meio - MG"
  },
  {
      id_cidade: "1689",
      id_estado: "11",
      nm_cidade: "Campo Florido",
      nm_cidade_uf: "Campo Florido - MG"
  },
  {
      id_cidade: "1690",
      id_estado: "11",
      nm_cidade: "Campos Altos",
      nm_cidade_uf: "Campos Altos - MG"
  },
  {
      id_cidade: "1691",
      id_estado: "11",
      nm_cidade: "Campos Gerais",
      nm_cidade_uf: "Campos Gerais - MG"
  },
  {
      id_cidade: "1692",
      id_estado: "11",
      nm_cidade: "Cana Verde",
      nm_cidade_uf: "Cana Verde - MG"
  },
  {
      id_cidade: "1693",
      id_estado: "11",
      nm_cidade: "Canaã",
      nm_cidade_uf: "Canaã - MG"
  },
  {
      id_cidade: "1694",
      id_estado: "11",
      nm_cidade: "Canápolis",
      nm_cidade_uf: "Canápolis - MG"
  },
  {
      id_cidade: "1695",
      id_estado: "11",
      nm_cidade: "Candeias",
      nm_cidade_uf: "Candeias - MG"
  },
  {
      id_cidade: "1696",
      id_estado: "11",
      nm_cidade: "Cantagalo",
      nm_cidade_uf: "Cantagalo - MG"
  },
  {
      id_cidade: "1697",
      id_estado: "11",
      nm_cidade: "Caparaó",
      nm_cidade_uf: "Caparaó - MG"
  },
  {
      id_cidade: "1698",
      id_estado: "11",
      nm_cidade: "Capela Nova",
      nm_cidade_uf: "Capela Nova - MG"
  },
  {
      id_cidade: "1699",
      id_estado: "11",
      nm_cidade: "Capelinha",
      nm_cidade_uf: "Capelinha - MG"
  },
  {
      id_cidade: "1700",
      id_estado: "11",
      nm_cidade: "Capetinga",
      nm_cidade_uf: "Capetinga - MG"
  },
  {
      id_cidade: "1701",
      id_estado: "11",
      nm_cidade: "Capim Branco",
      nm_cidade_uf: "Capim Branco - MG"
  },
  {
      id_cidade: "1702",
      id_estado: "11",
      nm_cidade: "Capinópolis",
      nm_cidade_uf: "Capinópolis - MG"
  },
  {
      id_cidade: "1703",
      id_estado: "11",
      nm_cidade: "Capitão Andrade",
      nm_cidade_uf: "Capitão Andrade - MG"
  },
  {
      id_cidade: "1704",
      id_estado: "11",
      nm_cidade: "Capitão Enéas",
      nm_cidade_uf: "Capitão Enéas - MG"
  },
  {
      id_cidade: "1705",
      id_estado: "11",
      nm_cidade: "Capitólio",
      nm_cidade_uf: "Capitólio - MG"
  },
  {
      id_cidade: "1706",
      id_estado: "11",
      nm_cidade: "Caputira",
      nm_cidade_uf: "Caputira - MG"
  },
  {
      id_cidade: "1707",
      id_estado: "11",
      nm_cidade: "Caraí",
      nm_cidade_uf: "Caraí - MG"
  },
  {
      id_cidade: "1708",
      id_estado: "11",
      nm_cidade: "Caranaíba",
      nm_cidade_uf: "Caranaíba - MG"
  },
  {
      id_cidade: "1709",
      id_estado: "11",
      nm_cidade: "Carandaí",
      nm_cidade_uf: "Carandaí - MG"
  },
  {
      id_cidade: "1710",
      id_estado: "11",
      nm_cidade: "Carangola",
      nm_cidade_uf: "Carangola - MG"
  },
  {
      id_cidade: "1711",
      id_estado: "11",
      nm_cidade: "Caratinga",
      nm_cidade_uf: "Caratinga - MG"
  },
  {
      id_cidade: "1712",
      id_estado: "11",
      nm_cidade: "Carbonita",
      nm_cidade_uf: "Carbonita - MG"
  },
  {
      id_cidade: "1713",
      id_estado: "11",
      nm_cidade: "Careaçu",
      nm_cidade_uf: "Careaçu - MG"
  },
  {
      id_cidade: "1714",
      id_estado: "11",
      nm_cidade: "Carlos Chagas",
      nm_cidade_uf: "Carlos Chagas - MG"
  },
  {
      id_cidade: "1715",
      id_estado: "11",
      nm_cidade: "Carmésia",
      nm_cidade_uf: "Carmésia - MG"
  },
  {
      id_cidade: "1716",
      id_estado: "11",
      nm_cidade: "Carmo da Cachoeira",
      nm_cidade_uf: "Carmo da Cachoeira - MG"
  },
  {
      id_cidade: "1717",
      id_estado: "11",
      nm_cidade: "Carmo da Mata",
      nm_cidade_uf: "Carmo da Mata - MG"
  },
  {
      id_cidade: "1718",
      id_estado: "11",
      nm_cidade: "Carmo de Minas",
      nm_cidade_uf: "Carmo de Minas - MG"
  },
  {
      id_cidade: "1719",
      id_estado: "11",
      nm_cidade: "Carmo do Cajuru",
      nm_cidade_uf: "Carmo do Cajuru - MG"
  },
  {
      id_cidade: "1720",
      id_estado: "11",
      nm_cidade: "Carmo do Paranaíba",
      nm_cidade_uf: "Carmo do Paranaíba - MG"
  },
  {
      id_cidade: "1721",
      id_estado: "11",
      nm_cidade: "Carmo do Rio Claro",
      nm_cidade_uf: "Carmo do Rio Claro - MG"
  },
  {
      id_cidade: "1722",
      id_estado: "11",
      nm_cidade: "Carmópolis de Minas",
      nm_cidade_uf: "Carmópolis de Minas - MG"
  },
  {
      id_cidade: "1723",
      id_estado: "11",
      nm_cidade: "Carneirinho",
      nm_cidade_uf: "Carneirinho - MG"
  },
  {
      id_cidade: "1724",
      id_estado: "11",
      nm_cidade: "Carrancas",
      nm_cidade_uf: "Carrancas - MG"
  },
  {
      id_cidade: "1725",
      id_estado: "11",
      nm_cidade: "Carvalhópolis",
      nm_cidade_uf: "Carvalhópolis - MG"
  },
  {
      id_cidade: "1726",
      id_estado: "11",
      nm_cidade: "Carvalhos",
      nm_cidade_uf: "Carvalhos - MG"
  },
  {
      id_cidade: "1727",
      id_estado: "11",
      nm_cidade: "Casa Grande",
      nm_cidade_uf: "Casa Grande - MG"
  },
  {
      id_cidade: "1728",
      id_estado: "11",
      nm_cidade: "Cascalho Rico",
      nm_cidade_uf: "Cascalho Rico - MG"
  },
  {
      id_cidade: "1729",
      id_estado: "11",
      nm_cidade: "Cássia",
      nm_cidade_uf: "Cássia - MG"
  },
  {
      id_cidade: "1730",
      id_estado: "11",
      nm_cidade: "Cataguases",
      nm_cidade_uf: "Cataguases - MG"
  },
  {
      id_cidade: "1731",
      id_estado: "11",
      nm_cidade: "Catas Altas",
      nm_cidade_uf: "Catas Altas - MG"
  },
  {
      id_cidade: "1732",
      id_estado: "11",
      nm_cidade: "Catas Altas da Noruega",
      nm_cidade_uf: "Catas Altas da Noruega - MG"
  },
  {
      id_cidade: "1733",
      id_estado: "11",
      nm_cidade: "Catuji",
      nm_cidade_uf: "Catuji - MG"
  },
  {
      id_cidade: "1734",
      id_estado: "11",
      nm_cidade: "Catuti",
      nm_cidade_uf: "Catuti - MG"
  },
  {
      id_cidade: "1735",
      id_estado: "11",
      nm_cidade: "Caxambu",
      nm_cidade_uf: "Caxambu - MG"
  },
  {
      id_cidade: "1736",
      id_estado: "11",
      nm_cidade: "Cedro do Abaeté",
      nm_cidade_uf: "Cedro do Abaeté - MG"
  },
  {
      id_cidade: "1737",
      id_estado: "11",
      nm_cidade: "Central de Minas",
      nm_cidade_uf: "Central de Minas - MG"
  },
  {
      id_cidade: "1738",
      id_estado: "11",
      nm_cidade: "Centralina",
      nm_cidade_uf: "Centralina - MG"
  },
  {
      id_cidade: "1739",
      id_estado: "11",
      nm_cidade: "Chácara",
      nm_cidade_uf: "Chácara - MG"
  },
  {
      id_cidade: "1740",
      id_estado: "11",
      nm_cidade: "Chalé",
      nm_cidade_uf: "Chalé - MG"
  },
  {
      id_cidade: "1741",
      id_estado: "11",
      nm_cidade: "Chapada do Norte",
      nm_cidade_uf: "Chapada do Norte - MG"
  },
  {
      id_cidade: "1742",
      id_estado: "11",
      nm_cidade: "Chapada Gaúcha",
      nm_cidade_uf: "Chapada Gaúcha - MG"
  },
  {
      id_cidade: "1743",
      id_estado: "11",
      nm_cidade: "Chiador",
      nm_cidade_uf: "Chiador - MG"
  },
  {
      id_cidade: "1744",
      id_estado: "11",
      nm_cidade: "Cipotânea",
      nm_cidade_uf: "Cipotânea - MG"
  },
  {
      id_cidade: "1745",
      id_estado: "11",
      nm_cidade: "Claraval",
      nm_cidade_uf: "Claraval - MG"
  },
  {
      id_cidade: "1746",
      id_estado: "11",
      nm_cidade: "Claro dos Poções",
      nm_cidade_uf: "Claro dos Poções - MG"
  },
  {
      id_cidade: "1747",
      id_estado: "11",
      nm_cidade: "Cláudio",
      nm_cidade_uf: "Cláudio - MG"
  },
  {
      id_cidade: "1748",
      id_estado: "11",
      nm_cidade: "Coimbra",
      nm_cidade_uf: "Coimbra - MG"
  },
  {
      id_cidade: "1749",
      id_estado: "11",
      nm_cidade: "Coluna",
      nm_cidade_uf: "Coluna - MG"
  },
  {
      id_cidade: "1750",
      id_estado: "11",
      nm_cidade: "Comendador Gomes",
      nm_cidade_uf: "Comendador Gomes - MG"
  },
  {
      id_cidade: "1751",
      id_estado: "11",
      nm_cidade: "Comercinho",
      nm_cidade_uf: "Comercinho - MG"
  },
  {
      id_cidade: "1752",
      id_estado: "11",
      nm_cidade: "Conceição da Aparecida",
      nm_cidade_uf: "Conceição da Aparecida - MG"
  },
  {
      id_cidade: "1753",
      id_estado: "11",
      nm_cidade: "Conceição da Barra de Minas",
      nm_cidade_uf: "Conceição da Barra de Minas - MG"
  },
  {
      id_cidade: "1754",
      id_estado: "11",
      nm_cidade: "Conceição das Alagoas",
      nm_cidade_uf: "Conceição das Alagoas - MG"
  },
  {
      id_cidade: "1755",
      id_estado: "11",
      nm_cidade: "Conceição das Pedras",
      nm_cidade_uf: "Conceição das Pedras - MG"
  },
  {
      id_cidade: "1756",
      id_estado: "11",
      nm_cidade: "Conceição de Ipanema",
      nm_cidade_uf: "Conceição de Ipanema - MG"
  },
  {
      id_cidade: "1757",
      id_estado: "11",
      nm_cidade: "Conceição do Mato Dentro",
      nm_cidade_uf: "Conceição do Mato Dentro - MG"
  },
  {
      id_cidade: "1758",
      id_estado: "11",
      nm_cidade: "Conceição do Pará",
      nm_cidade_uf: "Conceição do Pará - MG"
  },
  {
      id_cidade: "1759",
      id_estado: "11",
      nm_cidade: "Conceição do Rio Verde",
      nm_cidade_uf: "Conceição do Rio Verde - MG"
  },
  {
      id_cidade: "1760",
      id_estado: "11",
      nm_cidade: "Conceição dos Ouros",
      nm_cidade_uf: "Conceição dos Ouros - MG"
  },
  {
      id_cidade: "1761",
      id_estado: "11",
      nm_cidade: "Cônego Marinho",
      nm_cidade_uf: "Cônego Marinho - MG"
  },
  {
      id_cidade: "1762",
      id_estado: "11",
      nm_cidade: "Confins",
      nm_cidade_uf: "Confins - MG"
  },
  {
      id_cidade: "1763",
      id_estado: "11",
      nm_cidade: "Congonhal",
      nm_cidade_uf: "Congonhal - MG"
  },
  {
      id_cidade: "1764",
      id_estado: "11",
      nm_cidade: "Congonhas",
      nm_cidade_uf: "Congonhas - MG"
  },
  {
      id_cidade: "1765",
      id_estado: "11",
      nm_cidade: "Congonhas do Norte",
      nm_cidade_uf: "Congonhas do Norte - MG"
  },
  {
      id_cidade: "1766",
      id_estado: "11",
      nm_cidade: "Conquista",
      nm_cidade_uf: "Conquista - MG"
  },
  {
      id_cidade: "1767",
      id_estado: "11",
      nm_cidade: "Conselheiro Lafaiete",
      nm_cidade_uf: "Conselheiro Lafaiete - MG"
  },
  {
      id_cidade: "1768",
      id_estado: "11",
      nm_cidade: "Conselheiro Pena",
      nm_cidade_uf: "Conselheiro Pena - MG"
  },
  {
      id_cidade: "1769",
      id_estado: "11",
      nm_cidade: "Consolação",
      nm_cidade_uf: "Consolação - MG"
  },
  {
      id_cidade: "1770",
      id_estado: "11",
      nm_cidade: "Contagem",
      nm_cidade_uf: "Contagem - MG"
  },
  {
      id_cidade: "1771",
      id_estado: "11",
      nm_cidade: "Coqueiral",
      nm_cidade_uf: "Coqueiral - MG"
  },
  {
      id_cidade: "1772",
      id_estado: "11",
      nm_cidade: "Coração de Jesus",
      nm_cidade_uf: "Coração de Jesus - MG"
  },
  {
      id_cidade: "1773",
      id_estado: "11",
      nm_cidade: "Cordisburgo",
      nm_cidade_uf: "Cordisburgo - MG"
  },
  {
      id_cidade: "1774",
      id_estado: "11",
      nm_cidade: "Cordislândia",
      nm_cidade_uf: "Cordislândia - MG"
  },
  {
      id_cidade: "1775",
      id_estado: "11",
      nm_cidade: "Corinto",
      nm_cidade_uf: "Corinto - MG"
  },
  {
      id_cidade: "1776",
      id_estado: "11",
      nm_cidade: "Coroaci",
      nm_cidade_uf: "Coroaci - MG"
  },
  {
      id_cidade: "1777",
      id_estado: "11",
      nm_cidade: "Coromandel",
      nm_cidade_uf: "Coromandel - MG"
  },
  {
      id_cidade: "1778",
      id_estado: "11",
      nm_cidade: "Coronel Fabriciano",
      nm_cidade_uf: "Coronel Fabriciano - MG"
  },
  {
      id_cidade: "1779",
      id_estado: "11",
      nm_cidade: "Coronel Murta",
      nm_cidade_uf: "Coronel Murta - MG"
  },
  {
      id_cidade: "1780",
      id_estado: "11",
      nm_cidade: "Coronel Pacheco",
      nm_cidade_uf: "Coronel Pacheco - MG"
  },
  {
      id_cidade: "1781",
      id_estado: "11",
      nm_cidade: "Coronel Xavier Chaves",
      nm_cidade_uf: "Coronel Xavier Chaves - MG"
  },
  {
      id_cidade: "1782",
      id_estado: "11",
      nm_cidade: "Córrego Danta",
      nm_cidade_uf: "Córrego Danta - MG"
  },
  {
      id_cidade: "1783",
      id_estado: "11",
      nm_cidade: "Córrego do Bom Jesus",
      nm_cidade_uf: "Córrego do Bom Jesus - MG"
  },
  {
      id_cidade: "1784",
      id_estado: "11",
      nm_cidade: "Córrego Fundo",
      nm_cidade_uf: "Córrego Fundo - MG"
  },
  {
      id_cidade: "1785",
      id_estado: "11",
      nm_cidade: "Córrego Novo",
      nm_cidade_uf: "Córrego Novo - MG"
  },
  {
      id_cidade: "1786",
      id_estado: "11",
      nm_cidade: "Couto de Magalhães de Minas",
      nm_cidade_uf: "Couto de Magalhães de Minas - MG"
  },
  {
      id_cidade: "1787",
      id_estado: "11",
      nm_cidade: "Crisólita",
      nm_cidade_uf: "Crisólita - MG"
  },
  {
      id_cidade: "1788",
      id_estado: "11",
      nm_cidade: "Cristais",
      nm_cidade_uf: "Cristais - MG"
  },
  {
      id_cidade: "1789",
      id_estado: "11",
      nm_cidade: "Cristália",
      nm_cidade_uf: "Cristália - MG"
  },
  {
      id_cidade: "1790",
      id_estado: "11",
      nm_cidade: "Cristiano Otoni",
      nm_cidade_uf: "Cristiano Otoni - MG"
  },
  {
      id_cidade: "1791",
      id_estado: "11",
      nm_cidade: "Cristina",
      nm_cidade_uf: "Cristina - MG"
  },
  {
      id_cidade: "1792",
      id_estado: "11",
      nm_cidade: "Crucilândia",
      nm_cidade_uf: "Crucilândia - MG"
  },
  {
      id_cidade: "1793",
      id_estado: "11",
      nm_cidade: "Cruzeiro da Fortaleza",
      nm_cidade_uf: "Cruzeiro da Fortaleza - MG"
  },
  {
      id_cidade: "1794",
      id_estado: "11",
      nm_cidade: "Cruzília",
      nm_cidade_uf: "Cruzília - MG"
  },
  {
      id_cidade: "1795",
      id_estado: "11",
      nm_cidade: "Cuparaque",
      nm_cidade_uf: "Cuparaque - MG"
  },
  {
      id_cidade: "1796",
      id_estado: "11",
      nm_cidade: "Curral de Dentro",
      nm_cidade_uf: "Curral de Dentro - MG"
  },
  {
      id_cidade: "1797",
      id_estado: "11",
      nm_cidade: "Curvelo",
      nm_cidade_uf: "Curvelo - MG"
  },
  {
      id_cidade: "1798",
      id_estado: "11",
      nm_cidade: "Datas",
      nm_cidade_uf: "Datas - MG"
  },
  {
      id_cidade: "1799",
      id_estado: "11",
      nm_cidade: "Delfim Moreira",
      nm_cidade_uf: "Delfim Moreira - MG"
  },
  {
      id_cidade: "1800",
      id_estado: "11",
      nm_cidade: "Delfinópolis",
      nm_cidade_uf: "Delfinópolis - MG"
  },
  {
      id_cidade: "1801",
      id_estado: "11",
      nm_cidade: "Delta",
      nm_cidade_uf: "Delta - MG"
  },
  {
      id_cidade: "1802",
      id_estado: "11",
      nm_cidade: "Descoberto",
      nm_cidade_uf: "Descoberto - MG"
  },
  {
      id_cidade: "1803",
      id_estado: "11",
      nm_cidade: "Desterro de Entre Rios",
      nm_cidade_uf: "Desterro de Entre Rios - MG"
  },
  {
      id_cidade: "1804",
      id_estado: "11",
      nm_cidade: "Desterro do Melo",
      nm_cidade_uf: "Desterro do Melo - MG"
  },
  {
      id_cidade: "1805",
      id_estado: "11",
      nm_cidade: "Diamantina",
      nm_cidade_uf: "Diamantina - MG"
  },
  {
      id_cidade: "1806",
      id_estado: "11",
      nm_cidade: "Diogo de Vasconcelos",
      nm_cidade_uf: "Diogo de Vasconcelos - MG"
  },
  {
      id_cidade: "1807",
      id_estado: "11",
      nm_cidade: "Dionísio",
      nm_cidade_uf: "Dionísio - MG"
  },
  {
      id_cidade: "1808",
      id_estado: "11",
      nm_cidade: "Divinésia",
      nm_cidade_uf: "Divinésia - MG"
  },
  {
      id_cidade: "1809",
      id_estado: "11",
      nm_cidade: "Divino",
      nm_cidade_uf: "Divino - MG"
  },
  {
      id_cidade: "1810",
      id_estado: "11",
      nm_cidade: "Divino das Laranjeiras",
      nm_cidade_uf: "Divino das Laranjeiras - MG"
  },
  {
      id_cidade: "1811",
      id_estado: "11",
      nm_cidade: "Divinolândia de Minas",
      nm_cidade_uf: "Divinolândia de Minas - MG"
  },
  {
      id_cidade: "1812",
      id_estado: "11",
      nm_cidade: "Divinópolis",
      nm_cidade_uf: "Divinópolis - MG"
  },
  {
      id_cidade: "1813",
      id_estado: "11",
      nm_cidade: "Divisa Alegre",
      nm_cidade_uf: "Divisa Alegre - MG"
  },
  {
      id_cidade: "1814",
      id_estado: "11",
      nm_cidade: "Divisa Nova",
      nm_cidade_uf: "Divisa Nova - MG"
  },
  {
      id_cidade: "1815",
      id_estado: "11",
      nm_cidade: "Divisópolis",
      nm_cidade_uf: "Divisópolis - MG"
  },
  {
      id_cidade: "1816",
      id_estado: "11",
      nm_cidade: "Dom Bosco",
      nm_cidade_uf: "Dom Bosco - MG"
  },
  {
      id_cidade: "1817",
      id_estado: "11",
      nm_cidade: "Dom Cavati",
      nm_cidade_uf: "Dom Cavati - MG"
  },
  {
      id_cidade: "1818",
      id_estado: "11",
      nm_cidade: "Dom Joaquim",
      nm_cidade_uf: "Dom Joaquim - MG"
  },
  {
      id_cidade: "1819",
      id_estado: "11",
      nm_cidade: "Dom Silvério",
      nm_cidade_uf: "Dom Silvério - MG"
  },
  {
      id_cidade: "1820",
      id_estado: "11",
      nm_cidade: "Dom Viçoso",
      nm_cidade_uf: "Dom Viçoso - MG"
  },
  {
      id_cidade: "1821",
      id_estado: "11",
      nm_cidade: "Dona Eusébia",
      nm_cidade_uf: "Dona Eusébia - MG"
  },
  {
      id_cidade: "1822",
      id_estado: "11",
      nm_cidade: "Dores de Campos",
      nm_cidade_uf: "Dores de Campos - MG"
  },
  {
      id_cidade: "1823",
      id_estado: "11",
      nm_cidade: "Dores de Guanhães",
      nm_cidade_uf: "Dores de Guanhães - MG"
  },
  {
      id_cidade: "1824",
      id_estado: "11",
      nm_cidade: "Dores do Indaiá",
      nm_cidade_uf: "Dores do Indaiá - MG"
  },
  {
      id_cidade: "1825",
      id_estado: "11",
      nm_cidade: "Dores do Turvo",
      nm_cidade_uf: "Dores do Turvo - MG"
  },
  {
      id_cidade: "1826",
      id_estado: "11",
      nm_cidade: "Doresópolis",
      nm_cidade_uf: "Doresópolis - MG"
  },
  {
      id_cidade: "1827",
      id_estado: "11",
      nm_cidade: "Douradoquara",
      nm_cidade_uf: "Douradoquara - MG"
  },
  {
      id_cidade: "1828",
      id_estado: "11",
      nm_cidade: "Durandé",
      nm_cidade_uf: "Durandé - MG"
  },
  {
      id_cidade: "1829",
      id_estado: "11",
      nm_cidade: "Elói Mendes",
      nm_cidade_uf: "Elói Mendes - MG"
  },
  {
      id_cidade: "1830",
      id_estado: "11",
      nm_cidade: "Engenheiro Caldas",
      nm_cidade_uf: "Engenheiro Caldas - MG"
  },
  {
      id_cidade: "1831",
      id_estado: "11",
      nm_cidade: "Engenheiro Navarro",
      nm_cidade_uf: "Engenheiro Navarro - MG"
  },
  {
      id_cidade: "1832",
      id_estado: "11",
      nm_cidade: "Entre Folhas",
      nm_cidade_uf: "Entre Folhas - MG"
  },
  {
      id_cidade: "1833",
      id_estado: "11",
      nm_cidade: "Entre Rios de Minas",
      nm_cidade_uf: "Entre Rios de Minas - MG"
  },
  {
      id_cidade: "1834",
      id_estado: "11",
      nm_cidade: "Ervália",
      nm_cidade_uf: "Ervália - MG"
  },
  {
      id_cidade: "1835",
      id_estado: "11",
      nm_cidade: "Esmeraldas",
      nm_cidade_uf: "Esmeraldas - MG"
  },
  {
      id_cidade: "1836",
      id_estado: "11",
      nm_cidade: "Espera Feliz",
      nm_cidade_uf: "Espera Feliz - MG"
  },
  {
      id_cidade: "1837",
      id_estado: "11",
      nm_cidade: "Espinosa",
      nm_cidade_uf: "Espinosa - MG"
  },
  {
      id_cidade: "1838",
      id_estado: "11",
      nm_cidade: "Espírito Santo do Dourado",
      nm_cidade_uf: "Espírito Santo do Dourado - MG"
  },
  {
      id_cidade: "1839",
      id_estado: "11",
      nm_cidade: "Estiva",
      nm_cidade_uf: "Estiva - MG"
  },
  {
      id_cidade: "1840",
      id_estado: "11",
      nm_cidade: "Estrela Dalva",
      nm_cidade_uf: "Estrela Dalva - MG"
  },
  {
      id_cidade: "1841",
      id_estado: "11",
      nm_cidade: "Estrela do Indaiá",
      nm_cidade_uf: "Estrela do Indaiá - MG"
  },
  {
      id_cidade: "1842",
      id_estado: "11",
      nm_cidade: "Estrela do Sul",
      nm_cidade_uf: "Estrela do Sul - MG"
  },
  {
      id_cidade: "1843",
      id_estado: "11",
      nm_cidade: "Eugenópolis",
      nm_cidade_uf: "Eugenópolis - MG"
  },
  {
      id_cidade: "1844",
      id_estado: "11",
      nm_cidade: "Ewbank da Câmara",
      nm_cidade_uf: "Ewbank da Câmara - MG"
  },
  {
      id_cidade: "1845",
      id_estado: "11",
      nm_cidade: "Extrema",
      nm_cidade_uf: "Extrema - MG"
  },
  {
      id_cidade: "1846",
      id_estado: "11",
      nm_cidade: "Fama",
      nm_cidade_uf: "Fama - MG"
  },
  {
      id_cidade: "1847",
      id_estado: "11",
      nm_cidade: "Faria Lemos",
      nm_cidade_uf: "Faria Lemos - MG"
  },
  {
      id_cidade: "1848",
      id_estado: "11",
      nm_cidade: "Felício dos Santos",
      nm_cidade_uf: "Felício dos Santos - MG"
  },
  {
      id_cidade: "1849",
      id_estado: "11",
      nm_cidade: "Felisburgo",
      nm_cidade_uf: "Felisburgo - MG"
  },
  {
      id_cidade: "1850",
      id_estado: "11",
      nm_cidade: "Felixlândia",
      nm_cidade_uf: "Felixlândia - MG"
  },
  {
      id_cidade: "1851",
      id_estado: "11",
      nm_cidade: "Fernandes Tourinho",
      nm_cidade_uf: "Fernandes Tourinho - MG"
  },
  {
      id_cidade: "1852",
      id_estado: "11",
      nm_cidade: "Ferros",
      nm_cidade_uf: "Ferros - MG"
  },
  {
      id_cidade: "1853",
      id_estado: "11",
      nm_cidade: "Fervedouro",
      nm_cidade_uf: "Fervedouro - MG"
  },
  {
      id_cidade: "1854",
      id_estado: "11",
      nm_cidade: "Florestal",
      nm_cidade_uf: "Florestal - MG"
  },
  {
      id_cidade: "1855",
      id_estado: "11",
      nm_cidade: "Formiga",
      nm_cidade_uf: "Formiga - MG"
  },
  {
      id_cidade: "1856",
      id_estado: "11",
      nm_cidade: "Formoso",
      nm_cidade_uf: "Formoso - MG"
  },
  {
      id_cidade: "1857",
      id_estado: "11",
      nm_cidade: "Fortaleza de Minas",
      nm_cidade_uf: "Fortaleza de Minas - MG"
  },
  {
      id_cidade: "1858",
      id_estado: "11",
      nm_cidade: "Fortuna de Minas",
      nm_cidade_uf: "Fortuna de Minas - MG"
  },
  {
      id_cidade: "1859",
      id_estado: "11",
      nm_cidade: "Francisco Badaró",
      nm_cidade_uf: "Francisco Badaró - MG"
  },
  {
      id_cidade: "1860",
      id_estado: "11",
      nm_cidade: "Francisco Dumont",
      nm_cidade_uf: "Francisco Dumont - MG"
  },
  {
      id_cidade: "1861",
      id_estado: "11",
      nm_cidade: "Francisco Sá",
      nm_cidade_uf: "Francisco Sá - MG"
  },
  {
      id_cidade: "1862",
      id_estado: "11",
      nm_cidade: "Franciscópolis",
      nm_cidade_uf: "Franciscópolis - MG"
  },
  {
      id_cidade: "1863",
      id_estado: "11",
      nm_cidade: "Frei Gaspar",
      nm_cidade_uf: "Frei Gaspar - MG"
  },
  {
      id_cidade: "1864",
      id_estado: "11",
      nm_cidade: "Frei Inocêncio",
      nm_cidade_uf: "Frei Inocêncio - MG"
  },
  {
      id_cidade: "1865",
      id_estado: "11",
      nm_cidade: "Frei Lagonegro",
      nm_cidade_uf: "Frei Lagonegro - MG"
  },
  {
      id_cidade: "1866",
      id_estado: "11",
      nm_cidade: "Fronteira",
      nm_cidade_uf: "Fronteira - MG"
  },
  {
      id_cidade: "1867",
      id_estado: "11",
      nm_cidade: "Fronteira dos Vales",
      nm_cidade_uf: "Fronteira dos Vales - MG"
  },
  {
      id_cidade: "1868",
      id_estado: "11",
      nm_cidade: "Fruta de Leite",
      nm_cidade_uf: "Fruta de Leite - MG"
  },
  {
      id_cidade: "1869",
      id_estado: "11",
      nm_cidade: "Frutal",
      nm_cidade_uf: "Frutal - MG"
  },
  {
      id_cidade: "1870",
      id_estado: "11",
      nm_cidade: "Funilândia",
      nm_cidade_uf: "Funilândia - MG"
  },
  {
      id_cidade: "1871",
      id_estado: "11",
      nm_cidade: "Galiléia",
      nm_cidade_uf: "Galiléia - MG"
  },
  {
      id_cidade: "1872",
      id_estado: "11",
      nm_cidade: "Gameleiras",
      nm_cidade_uf: "Gameleiras - MG"
  },
  {
      id_cidade: "1873",
      id_estado: "11",
      nm_cidade: "Glaucilândia",
      nm_cidade_uf: "Glaucilândia - MG"
  },
  {
      id_cidade: "1874",
      id_estado: "11",
      nm_cidade: "Goiabeira",
      nm_cidade_uf: "Goiabeira - MG"
  },
  {
      id_cidade: "1875",
      id_estado: "11",
      nm_cidade: "Goianá",
      nm_cidade_uf: "Goianá - MG"
  },
  {
      id_cidade: "1876",
      id_estado: "11",
      nm_cidade: "Gonçalves",
      nm_cidade_uf: "Gonçalves - MG"
  },
  {
      id_cidade: "1877",
      id_estado: "11",
      nm_cidade: "Gonzaga",
      nm_cidade_uf: "Gonzaga - MG"
  },
  {
      id_cidade: "1878",
      id_estado: "11",
      nm_cidade: "Gouveia",
      nm_cidade_uf: "Gouveia - MG"
  },
  {
      id_cidade: "1879",
      id_estado: "11",
      nm_cidade: "Governador Valadares",
      nm_cidade_uf: "Governador Valadares - MG"
  },
  {
      id_cidade: "1880",
      id_estado: "11",
      nm_cidade: "Grão Mogol",
      nm_cidade_uf: "Grão Mogol - MG"
  },
  {
      id_cidade: "1881",
      id_estado: "11",
      nm_cidade: "Grupiara",
      nm_cidade_uf: "Grupiara - MG"
  },
  {
      id_cidade: "1882",
      id_estado: "11",
      nm_cidade: "Guanhães",
      nm_cidade_uf: "Guanhães - MG"
  },
  {
      id_cidade: "1883",
      id_estado: "11",
      nm_cidade: "Guapé",
      nm_cidade_uf: "Guapé - MG"
  },
  {
      id_cidade: "1884",
      id_estado: "11",
      nm_cidade: "Guaraciaba",
      nm_cidade_uf: "Guaraciaba - MG"
  },
  {
      id_cidade: "1885",
      id_estado: "11",
      nm_cidade: "Guaraciama",
      nm_cidade_uf: "Guaraciama - MG"
  },
  {
      id_cidade: "1886",
      id_estado: "11",
      nm_cidade: "Guaranésia",
      nm_cidade_uf: "Guaranésia - MG"
  },
  {
      id_cidade: "1887",
      id_estado: "11",
      nm_cidade: "Guarani",
      nm_cidade_uf: "Guarani - MG"
  },
  {
      id_cidade: "1888",
      id_estado: "11",
      nm_cidade: "Guarará",
      nm_cidade_uf: "Guarará - MG"
  },
  {
      id_cidade: "1889",
      id_estado: "11",
      nm_cidade: "Guarda-Mor",
      nm_cidade_uf: "Guarda-Mor - MG"
  },
  {
      id_cidade: "1890",
      id_estado: "11",
      nm_cidade: "Guaxupé",
      nm_cidade_uf: "Guaxupé - MG"
  },
  {
      id_cidade: "1891",
      id_estado: "11",
      nm_cidade: "Guidoval",
      nm_cidade_uf: "Guidoval - MG"
  },
  {
      id_cidade: "1892",
      id_estado: "11",
      nm_cidade: "Guimarânia",
      nm_cidade_uf: "Guimarânia - MG"
  },
  {
      id_cidade: "1893",
      id_estado: "11",
      nm_cidade: "Guiricema",
      nm_cidade_uf: "Guiricema - MG"
  },
  {
      id_cidade: "1894",
      id_estado: "11",
      nm_cidade: "Gurinhatã",
      nm_cidade_uf: "Gurinhatã - MG"
  },
  {
      id_cidade: "1895",
      id_estado: "11",
      nm_cidade: "Heliodora",
      nm_cidade_uf: "Heliodora - MG"
  },
  {
      id_cidade: "1896",
      id_estado: "11",
      nm_cidade: "Iapu",
      nm_cidade_uf: "Iapu - MG"
  },
  {
      id_cidade: "1897",
      id_estado: "11",
      nm_cidade: "Ibertioga",
      nm_cidade_uf: "Ibertioga - MG"
  },
  {
      id_cidade: "1898",
      id_estado: "11",
      nm_cidade: "Ibiá",
      nm_cidade_uf: "Ibiá - MG"
  },
  {
      id_cidade: "1899",
      id_estado: "11",
      nm_cidade: "Ibiaí",
      nm_cidade_uf: "Ibiaí - MG"
  },
  {
      id_cidade: "1900",
      id_estado: "11",
      nm_cidade: "Ibiracatu",
      nm_cidade_uf: "Ibiracatu - MG"
  },
  {
      id_cidade: "1901",
      id_estado: "11",
      nm_cidade: "Ibiraci",
      nm_cidade_uf: "Ibiraci - MG"
  },
  {
      id_cidade: "1902",
      id_estado: "11",
      nm_cidade: "Ibirité",
      nm_cidade_uf: "Ibirité - MG"
  },
  {
      id_cidade: "1903",
      id_estado: "11",
      nm_cidade: "Ibitiúra de Minas",
      nm_cidade_uf: "Ibitiúra de Minas - MG"
  },
  {
      id_cidade: "1904",
      id_estado: "11",
      nm_cidade: "Ibituruna",
      nm_cidade_uf: "Ibituruna - MG"
  },
  {
      id_cidade: "1905",
      id_estado: "11",
      nm_cidade: "Icaraí de Minas",
      nm_cidade_uf: "Icaraí de Minas - MG"
  },
  {
      id_cidade: "1906",
      id_estado: "11",
      nm_cidade: "Igarapé",
      nm_cidade_uf: "Igarapé - MG"
  },
  {
      id_cidade: "1907",
      id_estado: "11",
      nm_cidade: "Igaratinga",
      nm_cidade_uf: "Igaratinga - MG"
  },
  {
      id_cidade: "1908",
      id_estado: "11",
      nm_cidade: "Iguatama",
      nm_cidade_uf: "Iguatama - MG"
  },
  {
      id_cidade: "1909",
      id_estado: "11",
      nm_cidade: "Ijaci",
      nm_cidade_uf: "Ijaci - MG"
  },
  {
      id_cidade: "1910",
      id_estado: "11",
      nm_cidade: "Ilicínea",
      nm_cidade_uf: "Ilicínea - MG"
  },
  {
      id_cidade: "1911",
      id_estado: "11",
      nm_cidade: "Imbé de Minas",
      nm_cidade_uf: "Imbé de Minas - MG"
  },
  {
      id_cidade: "1912",
      id_estado: "11",
      nm_cidade: "Inconfidentes",
      nm_cidade_uf: "Inconfidentes - MG"
  },
  {
      id_cidade: "1913",
      id_estado: "11",
      nm_cidade: "Indaiabira",
      nm_cidade_uf: "Indaiabira - MG"
  },
  {
      id_cidade: "1914",
      id_estado: "11",
      nm_cidade: "Indianópolis",
      nm_cidade_uf: "Indianópolis - MG"
  },
  {
      id_cidade: "1915",
      id_estado: "11",
      nm_cidade: "Ingaí",
      nm_cidade_uf: "Ingaí - MG"
  },
  {
      id_cidade: "1916",
      id_estado: "11",
      nm_cidade: "Inhapim",
      nm_cidade_uf: "Inhapim - MG"
  },
  {
      id_cidade: "1917",
      id_estado: "11",
      nm_cidade: "Inhaúma",
      nm_cidade_uf: "Inhaúma - MG"
  },
  {
      id_cidade: "1918",
      id_estado: "11",
      nm_cidade: "Inimutaba",
      nm_cidade_uf: "Inimutaba - MG"
  },
  {
      id_cidade: "1919",
      id_estado: "11",
      nm_cidade: "Ipaba",
      nm_cidade_uf: "Ipaba - MG"
  },
  {
      id_cidade: "1920",
      id_estado: "11",
      nm_cidade: "Ipanema",
      nm_cidade_uf: "Ipanema - MG"
  },
  {
      id_cidade: "1921",
      id_estado: "11",
      nm_cidade: "Ipatinga",
      nm_cidade_uf: "Ipatinga - MG"
  },
  {
      id_cidade: "1922",
      id_estado: "11",
      nm_cidade: "Ipiaçu",
      nm_cidade_uf: "Ipiaçu - MG"
  },
  {
      id_cidade: "1923",
      id_estado: "11",
      nm_cidade: "Ipuiúna",
      nm_cidade_uf: "Ipuiúna - MG"
  },
  {
      id_cidade: "1924",
      id_estado: "11",
      nm_cidade: "Iraí de Minas",
      nm_cidade_uf: "Iraí de Minas - MG"
  },
  {
      id_cidade: "1925",
      id_estado: "11",
      nm_cidade: "Itabira",
      nm_cidade_uf: "Itabira - MG"
  },
  {
      id_cidade: "1926",
      id_estado: "11",
      nm_cidade: "Itabirinha de Mantena",
      nm_cidade_uf: "Itabirinha de Mantena - MG"
  },
  {
      id_cidade: "1927",
      id_estado: "11",
      nm_cidade: "Itabirito",
      nm_cidade_uf: "Itabirito - MG"
  },
  {
      id_cidade: "1928",
      id_estado: "11",
      nm_cidade: "Itacambira",
      nm_cidade_uf: "Itacambira - MG"
  },
  {
      id_cidade: "1929",
      id_estado: "11",
      nm_cidade: "Itacarambi",
      nm_cidade_uf: "Itacarambi - MG"
  },
  {
      id_cidade: "1930",
      id_estado: "11",
      nm_cidade: "Itaguara",
      nm_cidade_uf: "Itaguara - MG"
  },
  {
      id_cidade: "1931",
      id_estado: "11",
      nm_cidade: "Itaipé",
      nm_cidade_uf: "Itaipé - MG"
  },
  {
      id_cidade: "1932",
      id_estado: "11",
      nm_cidade: "Itajubá",
      nm_cidade_uf: "Itajubá - MG"
  },
  {
      id_cidade: "1933",
      id_estado: "11",
      nm_cidade: "Itamarandiba",
      nm_cidade_uf: "Itamarandiba - MG"
  },
  {
      id_cidade: "1934",
      id_estado: "11",
      nm_cidade: "Itamarati de Minas",
      nm_cidade_uf: "Itamarati de Minas - MG"
  },
  {
      id_cidade: "1935",
      id_estado: "11",
      nm_cidade: "Itambacuri",
      nm_cidade_uf: "Itambacuri - MG"
  },
  {
      id_cidade: "1936",
      id_estado: "11",
      nm_cidade: "Itambé do Mato Dentro",
      nm_cidade_uf: "Itambé do Mato Dentro - MG"
  },
  {
      id_cidade: "1937",
      id_estado: "11",
      nm_cidade: "Itamogi",
      nm_cidade_uf: "Itamogi - MG"
  },
  {
      id_cidade: "1938",
      id_estado: "11",
      nm_cidade: "Itamonte",
      nm_cidade_uf: "Itamonte - MG"
  },
  {
      id_cidade: "1939",
      id_estado: "11",
      nm_cidade: "Itanhandu",
      nm_cidade_uf: "Itanhandu - MG"
  },
  {
      id_cidade: "1940",
      id_estado: "11",
      nm_cidade: "Itanhomi",
      nm_cidade_uf: "Itanhomi - MG"
  },
  {
      id_cidade: "1941",
      id_estado: "11",
      nm_cidade: "Itaobim",
      nm_cidade_uf: "Itaobim - MG"
  },
  {
      id_cidade: "1942",
      id_estado: "11",
      nm_cidade: "Itapagipe",
      nm_cidade_uf: "Itapagipe - MG"
  },
  {
      id_cidade: "1943",
      id_estado: "11",
      nm_cidade: "Itapecerica",
      nm_cidade_uf: "Itapecerica - MG"
  },
  {
      id_cidade: "1944",
      id_estado: "11",
      nm_cidade: "Itapeva",
      nm_cidade_uf: "Itapeva - MG"
  },
  {
      id_cidade: "1945",
      id_estado: "11",
      nm_cidade: "Itatiaiuçu",
      nm_cidade_uf: "Itatiaiuçu - MG"
  },
  {
      id_cidade: "1946",
      id_estado: "11",
      nm_cidade: "Itaú de Minas",
      nm_cidade_uf: "Itaú de Minas - MG"
  },
  {
      id_cidade: "1947",
      id_estado: "11",
      nm_cidade: "Itaúna",
      nm_cidade_uf: "Itaúna - MG"
  },
  {
      id_cidade: "1948",
      id_estado: "11",
      nm_cidade: "Itaverava",
      nm_cidade_uf: "Itaverava - MG"
  },
  {
      id_cidade: "1949",
      id_estado: "11",
      nm_cidade: "Itinga",
      nm_cidade_uf: "Itinga - MG"
  },
  {
      id_cidade: "1950",
      id_estado: "11",
      nm_cidade: "Itueta",
      nm_cidade_uf: "Itueta - MG"
  },
  {
      id_cidade: "1951",
      id_estado: "11",
      nm_cidade: "Ituiutaba",
      nm_cidade_uf: "Ituiutaba - MG"
  },
  {
      id_cidade: "1952",
      id_estado: "11",
      nm_cidade: "Itumirim",
      nm_cidade_uf: "Itumirim - MG"
  },
  {
      id_cidade: "1953",
      id_estado: "11",
      nm_cidade: "Iturama",
      nm_cidade_uf: "Iturama - MG"
  },
  {
      id_cidade: "1954",
      id_estado: "11",
      nm_cidade: "Itutinga",
      nm_cidade_uf: "Itutinga - MG"
  },
  {
      id_cidade: "1955",
      id_estado: "11",
      nm_cidade: "Jaboticatubas",
      nm_cidade_uf: "Jaboticatubas - MG"
  },
  {
      id_cidade: "1956",
      id_estado: "11",
      nm_cidade: "Jacinto",
      nm_cidade_uf: "Jacinto - MG"
  },
  {
      id_cidade: "1957",
      id_estado: "11",
      nm_cidade: "Jacuí",
      nm_cidade_uf: "Jacuí - MG"
  },
  {
      id_cidade: "1958",
      id_estado: "11",
      nm_cidade: "Jacutinga",
      nm_cidade_uf: "Jacutinga - MG"
  },
  {
      id_cidade: "1959",
      id_estado: "11",
      nm_cidade: "Jaguaraçu",
      nm_cidade_uf: "Jaguaraçu - MG"
  },
  {
      id_cidade: "1960",
      id_estado: "11",
      nm_cidade: "Jaíba",
      nm_cidade_uf: "Jaíba - MG"
  },
  {
      id_cidade: "1961",
      id_estado: "11",
      nm_cidade: "Jampruca",
      nm_cidade_uf: "Jampruca - MG"
  },
  {
      id_cidade: "1962",
      id_estado: "11",
      nm_cidade: "Janaúba",
      nm_cidade_uf: "Janaúba - MG"
  },
  {
      id_cidade: "1963",
      id_estado: "11",
      nm_cidade: "Januária",
      nm_cidade_uf: "Januária - MG"
  },
  {
      id_cidade: "1964",
      id_estado: "11",
      nm_cidade: "Japaraíba",
      nm_cidade_uf: "Japaraíba - MG"
  },
  {
      id_cidade: "1965",
      id_estado: "11",
      nm_cidade: "Japonvar",
      nm_cidade_uf: "Japonvar - MG"
  },
  {
      id_cidade: "1966",
      id_estado: "11",
      nm_cidade: "Jeceaba",
      nm_cidade_uf: "Jeceaba - MG"
  },
  {
      id_cidade: "1967",
      id_estado: "11",
      nm_cidade: "Jenipapo de Minas",
      nm_cidade_uf: "Jenipapo de Minas - MG"
  },
  {
      id_cidade: "1968",
      id_estado: "11",
      nm_cidade: "Jequeri",
      nm_cidade_uf: "Jequeri - MG"
  },
  {
      id_cidade: "1969",
      id_estado: "11",
      nm_cidade: "Jequitaí",
      nm_cidade_uf: "Jequitaí - MG"
  },
  {
      id_cidade: "1970",
      id_estado: "11",
      nm_cidade: "Jequitibá",
      nm_cidade_uf: "Jequitibá - MG"
  },
  {
      id_cidade: "1971",
      id_estado: "11",
      nm_cidade: "Jequitinhonha",
      nm_cidade_uf: "Jequitinhonha - MG"
  },
  {
      id_cidade: "1972",
      id_estado: "11",
      nm_cidade: "Jesuânia",
      nm_cidade_uf: "Jesuânia - MG"
  },
  {
      id_cidade: "1973",
      id_estado: "11",
      nm_cidade: "Joaíma",
      nm_cidade_uf: "Joaíma - MG"
  },
  {
      id_cidade: "1974",
      id_estado: "11",
      nm_cidade: "Joanésia",
      nm_cidade_uf: "Joanésia - MG"
  },
  {
      id_cidade: "1975",
      id_estado: "11",
      nm_cidade: "João Monlevade",
      nm_cidade_uf: "João Monlevade - MG"
  },
  {
      id_cidade: "1976",
      id_estado: "11",
      nm_cidade: "João Pinheiro",
      nm_cidade_uf: "João Pinheiro - MG"
  },
  {
      id_cidade: "1977",
      id_estado: "11",
      nm_cidade: "Joaquim Felício",
      nm_cidade_uf: "Joaquim Felício - MG"
  },
  {
      id_cidade: "1978",
      id_estado: "11",
      nm_cidade: "Jordânia",
      nm_cidade_uf: "Jordânia - MG"
  },
  {
      id_cidade: "1979",
      id_estado: "11",
      nm_cidade: "José Gonçalves de Minas",
      nm_cidade_uf: "José Gonçalves de Minas - MG"
  },
  {
      id_cidade: "1980",
      id_estado: "11",
      nm_cidade: "José Raydan",
      nm_cidade_uf: "José Raydan - MG"
  },
  {
      id_cidade: "1981",
      id_estado: "11",
      nm_cidade: "Josenópolis",
      nm_cidade_uf: "Josenópolis - MG"
  },
  {
      id_cidade: "1982",
      id_estado: "11",
      nm_cidade: "Juatuba",
      nm_cidade_uf: "Juatuba - MG"
  },
  {
      id_cidade: "1983",
      id_estado: "11",
      nm_cidade: "Juiz de Fora",
      nm_cidade_uf: "Juiz de Fora - MG"
  },
  {
      id_cidade: "1984",
      id_estado: "11",
      nm_cidade: "Juramento",
      nm_cidade_uf: "Juramento - MG"
  },
  {
      id_cidade: "1985",
      id_estado: "11",
      nm_cidade: "Juruaia",
      nm_cidade_uf: "Juruaia - MG"
  },
  {
      id_cidade: "1986",
      id_estado: "11",
      nm_cidade: "Juvenília",
      nm_cidade_uf: "Juvenília - MG"
  },
  {
      id_cidade: "1987",
      id_estado: "11",
      nm_cidade: "Ladainha",
      nm_cidade_uf: "Ladainha - MG"
  },
  {
      id_cidade: "1988",
      id_estado: "11",
      nm_cidade: "Lagamar",
      nm_cidade_uf: "Lagamar - MG"
  },
  {
      id_cidade: "1989",
      id_estado: "11",
      nm_cidade: "Lagoa da Prata",
      nm_cidade_uf: "Lagoa da Prata - MG"
  },
  {
      id_cidade: "1990",
      id_estado: "11",
      nm_cidade: "Lagoa dos Patos",
      nm_cidade_uf: "Lagoa dos Patos - MG"
  },
  {
      id_cidade: "1991",
      id_estado: "11",
      nm_cidade: "Lagoa Dourada",
      nm_cidade_uf: "Lagoa Dourada - MG"
  },
  {
      id_cidade: "1992",
      id_estado: "11",
      nm_cidade: "Lagoa Formosa",
      nm_cidade_uf: "Lagoa Formosa - MG"
  },
  {
      id_cidade: "1993",
      id_estado: "11",
      nm_cidade: "Lagoa Grande",
      nm_cidade_uf: "Lagoa Grande - MG"
  },
  {
      id_cidade: "1994",
      id_estado: "11",
      nm_cidade: "Lagoa Santa",
      nm_cidade_uf: "Lagoa Santa - MG"
  },
  {
      id_cidade: "1995",
      id_estado: "11",
      nm_cidade: "Lajinha",
      nm_cidade_uf: "Lajinha - MG"
  },
  {
      id_cidade: "1996",
      id_estado: "11",
      nm_cidade: "Lambari",
      nm_cidade_uf: "Lambari - MG"
  },
  {
      id_cidade: "1997",
      id_estado: "11",
      nm_cidade: "Lamim",
      nm_cidade_uf: "Lamim - MG"
  },
  {
      id_cidade: "1998",
      id_estado: "11",
      nm_cidade: "Laranjal",
      nm_cidade_uf: "Laranjal - MG"
  },
  {
      id_cidade: "1999",
      id_estado: "11",
      nm_cidade: "Lassance",
      nm_cidade_uf: "Lassance - MG"
  },
  {
      id_cidade: "2000",
      id_estado: "11",
      nm_cidade: "Lavras",
      nm_cidade_uf: "Lavras - MG"
  },
  {
      id_cidade: "2001",
      id_estado: "11",
      nm_cidade: "Leandro Ferreira",
      nm_cidade_uf: "Leandro Ferreira - MG"
  },
  {
      id_cidade: "2002",
      id_estado: "11",
      nm_cidade: "Leme do Prado",
      nm_cidade_uf: "Leme do Prado - MG"
  },
  {
      id_cidade: "2003",
      id_estado: "11",
      nm_cidade: "Leopoldina",
      nm_cidade_uf: "Leopoldina - MG"
  },
  {
      id_cidade: "2004",
      id_estado: "11",
      nm_cidade: "Liberdade",
      nm_cidade_uf: "Liberdade - MG"
  },
  {
      id_cidade: "2005",
      id_estado: "11",
      nm_cidade: "Lima Duarte",
      nm_cidade_uf: "Lima Duarte - MG"
  },
  {
      id_cidade: "2006",
      id_estado: "11",
      nm_cidade: "Limeira do Oeste",
      nm_cidade_uf: "Limeira do Oeste - MG"
  },
  {
      id_cidade: "2007",
      id_estado: "11",
      nm_cidade: "Lontra",
      nm_cidade_uf: "Lontra - MG"
  },
  {
      id_cidade: "2008",
      id_estado: "11",
      nm_cidade: "Luisburgo",
      nm_cidade_uf: "Luisburgo - MG"
  },
  {
      id_cidade: "2009",
      id_estado: "11",
      nm_cidade: "Luislândia",
      nm_cidade_uf: "Luislândia - MG"
  },
  {
      id_cidade: "2010",
      id_estado: "11",
      nm_cidade: "Luminárias",
      nm_cidade_uf: "Luminárias - MG"
  },
  {
      id_cidade: "2011",
      id_estado: "11",
      nm_cidade: "Luz",
      nm_cidade_uf: "Luz - MG"
  },
  {
      id_cidade: "2012",
      id_estado: "11",
      nm_cidade: "Machacalis",
      nm_cidade_uf: "Machacalis - MG"
  },
  {
      id_cidade: "2013",
      id_estado: "11",
      nm_cidade: "Machado",
      nm_cidade_uf: "Machado - MG"
  },
  {
      id_cidade: "2014",
      id_estado: "11",
      nm_cidade: "Madre de Deus de Minas",
      nm_cidade_uf: "Madre de Deus de Minas - MG"
  },
  {
      id_cidade: "2015",
      id_estado: "11",
      nm_cidade: "Malacacheta",
      nm_cidade_uf: "Malacacheta - MG"
  },
  {
      id_cidade: "2016",
      id_estado: "11",
      nm_cidade: "Mamonas",
      nm_cidade_uf: "Mamonas - MG"
  },
  {
      id_cidade: "2017",
      id_estado: "11",
      nm_cidade: "Manga",
      nm_cidade_uf: "Manga - MG"
  },
  {
      id_cidade: "2018",
      id_estado: "11",
      nm_cidade: "Manhuaçu",
      nm_cidade_uf: "Manhuaçu - MG"
  },
  {
      id_cidade: "2019",
      id_estado: "11",
      nm_cidade: "Manhumirim",
      nm_cidade_uf: "Manhumirim - MG"
  },
  {
      id_cidade: "2020",
      id_estado: "11",
      nm_cidade: "Mantena",
      nm_cidade_uf: "Mantena - MG"
  },
  {
      id_cidade: "2021",
      id_estado: "11",
      nm_cidade: "Mar de Espanha",
      nm_cidade_uf: "Mar de Espanha - MG"
  },
  {
      id_cidade: "2022",
      id_estado: "11",
      nm_cidade: "Maravilhas",
      nm_cidade_uf: "Maravilhas - MG"
  },
  {
      id_cidade: "2023",
      id_estado: "11",
      nm_cidade: "Maria da Fé",
      nm_cidade_uf: "Maria da Fé - MG"
  },
  {
      id_cidade: "2024",
      id_estado: "11",
      nm_cidade: "Mariana",
      nm_cidade_uf: "Mariana - MG"
  },
  {
      id_cidade: "2025",
      id_estado: "11",
      nm_cidade: "Marilac",
      nm_cidade_uf: "Marilac - MG"
  },
  {
      id_cidade: "2026",
      id_estado: "11",
      nm_cidade: "Mário Campos",
      nm_cidade_uf: "Mário Campos - MG"
  },
  {
      id_cidade: "2027",
      id_estado: "11",
      nm_cidade: "Maripá de Minas",
      nm_cidade_uf: "Maripá de Minas - MG"
  },
  {
      id_cidade: "2028",
      id_estado: "11",
      nm_cidade: "Marliéria",
      nm_cidade_uf: "Marliéria - MG"
  },
  {
      id_cidade: "2029",
      id_estado: "11",
      nm_cidade: "Marmelópolis",
      nm_cidade_uf: "Marmelópolis - MG"
  },
  {
      id_cidade: "2030",
      id_estado: "11",
      nm_cidade: "Martinho Campos",
      nm_cidade_uf: "Martinho Campos - MG"
  },
  {
      id_cidade: "2031",
      id_estado: "11",
      nm_cidade: "Martins Soares",
      nm_cidade_uf: "Martins Soares - MG"
  },
  {
      id_cidade: "2032",
      id_estado: "11",
      nm_cidade: "Mata Verde",
      nm_cidade_uf: "Mata Verde - MG"
  },
  {
      id_cidade: "2033",
      id_estado: "11",
      nm_cidade: "Materlândia",
      nm_cidade_uf: "Materlândia - MG"
  },
  {
      id_cidade: "2034",
      id_estado: "11",
      nm_cidade: "Mateus Leme",
      nm_cidade_uf: "Mateus Leme - MG"
  },
  {
      id_cidade: "2035",
      id_estado: "11",
      nm_cidade: "Mathias Lobato",
      nm_cidade_uf: "Mathias Lobato - MG"
  },
  {
      id_cidade: "2036",
      id_estado: "11",
      nm_cidade: "Matias Barbosa",
      nm_cidade_uf: "Matias Barbosa - MG"
  },
  {
      id_cidade: "2037",
      id_estado: "11",
      nm_cidade: "Matias Cardoso",
      nm_cidade_uf: "Matias Cardoso - MG"
  },
  {
      id_cidade: "2038",
      id_estado: "11",
      nm_cidade: "Matipó",
      nm_cidade_uf: "Matipó - MG"
  },
  {
      id_cidade: "2039",
      id_estado: "11",
      nm_cidade: "Mato Verde",
      nm_cidade_uf: "Mato Verde - MG"
  },
  {
      id_cidade: "2040",
      id_estado: "11",
      nm_cidade: "Matozinhos",
      nm_cidade_uf: "Matozinhos - MG"
  },
  {
      id_cidade: "2041",
      id_estado: "11",
      nm_cidade: "Matutina",
      nm_cidade_uf: "Matutina - MG"
  },
  {
      id_cidade: "2042",
      id_estado: "11",
      nm_cidade: "Medeiros",
      nm_cidade_uf: "Medeiros - MG"
  },
  {
      id_cidade: "2043",
      id_estado: "11",
      nm_cidade: "Medina",
      nm_cidade_uf: "Medina - MG"
  },
  {
      id_cidade: "2044",
      id_estado: "11",
      nm_cidade: "Mendes Pimentel",
      nm_cidade_uf: "Mendes Pimentel - MG"
  },
  {
      id_cidade: "2045",
      id_estado: "11",
      nm_cidade: "Mercês",
      nm_cidade_uf: "Mercês - MG"
  },
  {
      id_cidade: "2046",
      id_estado: "11",
      nm_cidade: "Mesquita",
      nm_cidade_uf: "Mesquita - MG"
  },
  {
      id_cidade: "2047",
      id_estado: "11",
      nm_cidade: "Minas Novas",
      nm_cidade_uf: "Minas Novas - MG"
  },
  {
      id_cidade: "2048",
      id_estado: "11",
      nm_cidade: "Minduri",
      nm_cidade_uf: "Minduri - MG"
  },
  {
      id_cidade: "2049",
      id_estado: "11",
      nm_cidade: "Mirabela",
      nm_cidade_uf: "Mirabela - MG"
  },
  {
      id_cidade: "2050",
      id_estado: "11",
      nm_cidade: "Miradouro",
      nm_cidade_uf: "Miradouro - MG"
  },
  {
      id_cidade: "2051",
      id_estado: "11",
      nm_cidade: "Miraí",
      nm_cidade_uf: "Miraí - MG"
  },
  {
      id_cidade: "2052",
      id_estado: "11",
      nm_cidade: "Miravânia",
      nm_cidade_uf: "Miravânia - MG"
  },
  {
      id_cidade: "2053",
      id_estado: "11",
      nm_cidade: "Moeda",
      nm_cidade_uf: "Moeda - MG"
  },
  {
      id_cidade: "2054",
      id_estado: "11",
      nm_cidade: "Moema",
      nm_cidade_uf: "Moema - MG"
  },
  {
      id_cidade: "2055",
      id_estado: "11",
      nm_cidade: "Monjolos",
      nm_cidade_uf: "Monjolos - MG"
  },
  {
      id_cidade: "2056",
      id_estado: "11",
      nm_cidade: "Monsenhor Paulo",
      nm_cidade_uf: "Monsenhor Paulo - MG"
  },
  {
      id_cidade: "2057",
      id_estado: "11",
      nm_cidade: "Montalvânia",
      nm_cidade_uf: "Montalvânia - MG"
  },
  {
      id_cidade: "2058",
      id_estado: "11",
      nm_cidade: "Monte Alegre de Minas",
      nm_cidade_uf: "Monte Alegre de Minas - MG"
  },
  {
      id_cidade: "2059",
      id_estado: "11",
      nm_cidade: "Monte Azul",
      nm_cidade_uf: "Monte Azul - MG"
  },
  {
      id_cidade: "2060",
      id_estado: "11",
      nm_cidade: "Monte Belo",
      nm_cidade_uf: "Monte Belo - MG"
  },
  {
      id_cidade: "2061",
      id_estado: "11",
      nm_cidade: "Monte Carmelo",
      nm_cidade_uf: "Monte Carmelo - MG"
  },
  {
      id_cidade: "2062",
      id_estado: "11",
      nm_cidade: "Monte Formoso",
      nm_cidade_uf: "Monte Formoso - MG"
  },
  {
      id_cidade: "2063",
      id_estado: "11",
      nm_cidade: "Monte Santo de Minas",
      nm_cidade_uf: "Monte Santo de Minas - MG"
  },
  {
      id_cidade: "2064",
      id_estado: "11",
      nm_cidade: "Monte Sião",
      nm_cidade_uf: "Monte Sião - MG"
  },
  {
      id_cidade: "2065",
      id_estado: "11",
      nm_cidade: "Montes Claros",
      nm_cidade_uf: "Montes Claros - MG"
  },
  {
      id_cidade: "2066",
      id_estado: "11",
      nm_cidade: "Montezuma",
      nm_cidade_uf: "Montezuma - MG"
  },
  {
      id_cidade: "2067",
      id_estado: "11",
      nm_cidade: "Morada Nova de Minas",
      nm_cidade_uf: "Morada Nova de Minas - MG"
  },
  {
      id_cidade: "2068",
      id_estado: "11",
      nm_cidade: "Morro da Garça",
      nm_cidade_uf: "Morro da Garça - MG"
  },
  {
      id_cidade: "2069",
      id_estado: "11",
      nm_cidade: "Morro do Pilar",
      nm_cidade_uf: "Morro do Pilar - MG"
  },
  {
      id_cidade: "2070",
      id_estado: "11",
      nm_cidade: "Munhoz",
      nm_cidade_uf: "Munhoz - MG"
  },
  {
      id_cidade: "2071",
      id_estado: "11",
      nm_cidade: "Muriaé",
      nm_cidade_uf: "Muriaé - MG"
  },
  {
      id_cidade: "2072",
      id_estado: "11",
      nm_cidade: "Mutum",
      nm_cidade_uf: "Mutum - MG"
  },
  {
      id_cidade: "2073",
      id_estado: "11",
      nm_cidade: "Muzambinho",
      nm_cidade_uf: "Muzambinho - MG"
  },
  {
      id_cidade: "2074",
      id_estado: "11",
      nm_cidade: "Nacip Raydan",
      nm_cidade_uf: "Nacip Raydan - MG"
  },
  {
      id_cidade: "2075",
      id_estado: "11",
      nm_cidade: "Nanuque",
      nm_cidade_uf: "Nanuque - MG"
  },
  {
      id_cidade: "2076",
      id_estado: "11",
      nm_cidade: "Naque",
      nm_cidade_uf: "Naque - MG"
  },
  {
      id_cidade: "2077",
      id_estado: "11",
      nm_cidade: "Natalândia",
      nm_cidade_uf: "Natalândia - MG"
  },
  {
      id_cidade: "2078",
      id_estado: "11",
      nm_cidade: "Natércia",
      nm_cidade_uf: "Natércia - MG"
  },
  {
      id_cidade: "2079",
      id_estado: "11",
      nm_cidade: "Nazareno",
      nm_cidade_uf: "Nazareno - MG"
  },
  {
      id_cidade: "2080",
      id_estado: "11",
      nm_cidade: "Nepomuceno",
      nm_cidade_uf: "Nepomuceno - MG"
  },
  {
      id_cidade: "2081",
      id_estado: "11",
      nm_cidade: "Ninheira",
      nm_cidade_uf: "Ninheira - MG"
  },
  {
      id_cidade: "2082",
      id_estado: "11",
      nm_cidade: "Nova Belém",
      nm_cidade_uf: "Nova Belém - MG"
  },
  {
      id_cidade: "2083",
      id_estado: "11",
      nm_cidade: "Nova Era",
      nm_cidade_uf: "Nova Era - MG"
  },
  {
      id_cidade: "2084",
      id_estado: "11",
      nm_cidade: "Nova Lima",
      nm_cidade_uf: "Nova Lima - MG"
  },
  {
      id_cidade: "2085",
      id_estado: "11",
      nm_cidade: "Nova Módica",
      nm_cidade_uf: "Nova Módica - MG"
  },
  {
      id_cidade: "2086",
      id_estado: "11",
      nm_cidade: "Nova Ponte",
      nm_cidade_uf: "Nova Ponte - MG"
  },
  {
      id_cidade: "2087",
      id_estado: "11",
      nm_cidade: "Nova Porteirinha",
      nm_cidade_uf: "Nova Porteirinha - MG"
  },
  {
      id_cidade: "2088",
      id_estado: "11",
      nm_cidade: "Nova Resende",
      nm_cidade_uf: "Nova Resende - MG"
  },
  {
      id_cidade: "2089",
      id_estado: "11",
      nm_cidade: "Nova Serrana",
      nm_cidade_uf: "Nova Serrana - MG"
  },
  {
      id_cidade: "2090",
      id_estado: "11",
      nm_cidade: "Nova União",
      nm_cidade_uf: "Nova União - MG"
  },
  {
      id_cidade: "2091",
      id_estado: "11",
      nm_cidade: "Novo Cruzeiro",
      nm_cidade_uf: "Novo Cruzeiro - MG"
  },
  {
      id_cidade: "2092",
      id_estado: "11",
      nm_cidade: "Novo Oriente de Minas",
      nm_cidade_uf: "Novo Oriente de Minas - MG"
  },
  {
      id_cidade: "2093",
      id_estado: "11",
      nm_cidade: "Novorizonte",
      nm_cidade_uf: "Novorizonte - MG"
  },
  {
      id_cidade: "2094",
      id_estado: "11",
      nm_cidade: "Olaria",
      nm_cidade_uf: "Olaria - MG"
  },
  {
      id_cidade: "2095",
      id_estado: "11",
      nm_cidade: "Olhos-d`Água",
      nm_cidade_uf: "Olhos-d`Água - MG"
  },
  {
      id_cidade: "2096",
      id_estado: "11",
      nm_cidade: "Olímpio Noronha",
      nm_cidade_uf: "Olímpio Noronha - MG"
  },
  {
      id_cidade: "2097",
      id_estado: "11",
      nm_cidade: "Oliveira",
      nm_cidade_uf: "Oliveira - MG"
  },
  {
      id_cidade: "2098",
      id_estado: "11",
      nm_cidade: "Oliveira Fortes",
      nm_cidade_uf: "Oliveira Fortes - MG"
  },
  {
      id_cidade: "2099",
      id_estado: "11",
      nm_cidade: "Onça de Pitangui",
      nm_cidade_uf: "Onça de Pitangui - MG"
  },
  {
      id_cidade: "2100",
      id_estado: "11",
      nm_cidade: "Oratórios",
      nm_cidade_uf: "Oratórios - MG"
  },
  {
      id_cidade: "2101",
      id_estado: "11",
      nm_cidade: "Orizânia",
      nm_cidade_uf: "Orizânia - MG"
  },
  {
      id_cidade: "2102",
      id_estado: "11",
      nm_cidade: "Ouro Branco",
      nm_cidade_uf: "Ouro Branco - MG"
  },
  {
      id_cidade: "2103",
      id_estado: "11",
      nm_cidade: "Ouro Fino",
      nm_cidade_uf: "Ouro Fino - MG"
  },
  {
      id_cidade: "2104",
      id_estado: "11",
      nm_cidade: "Ouro Preto",
      nm_cidade_uf: "Ouro Preto - MG"
  },
  {
      id_cidade: "2105",
      id_estado: "11",
      nm_cidade: "Ouro Verde de Minas",
      nm_cidade_uf: "Ouro Verde de Minas - MG"
  },
  {
      id_cidade: "2106",
      id_estado: "11",
      nm_cidade: "Padre Carvalho",
      nm_cidade_uf: "Padre Carvalho - MG"
  },
  {
      id_cidade: "2107",
      id_estado: "11",
      nm_cidade: "Padre Paraíso",
      nm_cidade_uf: "Padre Paraíso - MG"
  },
  {
      id_cidade: "2108",
      id_estado: "11",
      nm_cidade: "Pai Pedro",
      nm_cidade_uf: "Pai Pedro - MG"
  },
  {
      id_cidade: "2109",
      id_estado: "11",
      nm_cidade: "Paineiras",
      nm_cidade_uf: "Paineiras - MG"
  },
  {
      id_cidade: "2110",
      id_estado: "11",
      nm_cidade: "Pains",
      nm_cidade_uf: "Pains - MG"
  },
  {
      id_cidade: "2111",
      id_estado: "11",
      nm_cidade: "Paiva",
      nm_cidade_uf: "Paiva - MG"
  },
  {
      id_cidade: "2112",
      id_estado: "11",
      nm_cidade: "Palma",
      nm_cidade_uf: "Palma - MG"
  },
  {
      id_cidade: "2113",
      id_estado: "11",
      nm_cidade: "Palmópolis",
      nm_cidade_uf: "Palmópolis - MG"
  },
  {
      id_cidade: "2114",
      id_estado: "11",
      nm_cidade: "Papagaios",
      nm_cidade_uf: "Papagaios - MG"
  },
  {
      id_cidade: "2115",
      id_estado: "11",
      nm_cidade: "Pará de Minas",
      nm_cidade_uf: "Pará de Minas - MG"
  },
  {
      id_cidade: "2116",
      id_estado: "11",
      nm_cidade: "Paracatu",
      nm_cidade_uf: "Paracatu - MG"
  },
  {
      id_cidade: "2117",
      id_estado: "11",
      nm_cidade: "Paraguaçu",
      nm_cidade_uf: "Paraguaçu - MG"
  },
  {
      id_cidade: "2118",
      id_estado: "11",
      nm_cidade: "Paraisópolis",
      nm_cidade_uf: "Paraisópolis - MG"
  },
  {
      id_cidade: "2119",
      id_estado: "11",
      nm_cidade: "Paraopeba",
      nm_cidade_uf: "Paraopeba - MG"
  },
  {
      id_cidade: "2120",
      id_estado: "11",
      nm_cidade: "Passa Quatro",
      nm_cidade_uf: "Passa Quatro - MG"
  },
  {
      id_cidade: "2121",
      id_estado: "11",
      nm_cidade: "Passa Tempo",
      nm_cidade_uf: "Passa Tempo - MG"
  },
  {
      id_cidade: "2122",
      id_estado: "11",
      nm_cidade: "Passabém",
      nm_cidade_uf: "Passabém - MG"
  },
  {
      id_cidade: "2123",
      id_estado: "11",
      nm_cidade: "Passa-Vinte",
      nm_cidade_uf: "Passa-Vinte - MG"
  },
  {
      id_cidade: "2124",
      id_estado: "11",
      nm_cidade: "Passos",
      nm_cidade_uf: "Passos - MG"
  },
  {
      id_cidade: "2125",
      id_estado: "11",
      nm_cidade: "Patis",
      nm_cidade_uf: "Patis - MG"
  },
  {
      id_cidade: "2126",
      id_estado: "11",
      nm_cidade: "Patos de Minas",
      nm_cidade_uf: "Patos de Minas - MG"
  },
  {
      id_cidade: "2127",
      id_estado: "11",
      nm_cidade: "Patrocínio",
      nm_cidade_uf: "Patrocínio - MG"
  },
  {
      id_cidade: "2128",
      id_estado: "11",
      nm_cidade: "Patrocínio do Muriaé",
      nm_cidade_uf: "Patrocínio do Muriaé - MG"
  },
  {
      id_cidade: "2129",
      id_estado: "11",
      nm_cidade: "Paula Cândido",
      nm_cidade_uf: "Paula Cândido - MG"
  },
  {
      id_cidade: "2130",
      id_estado: "11",
      nm_cidade: "Paulistas",
      nm_cidade_uf: "Paulistas - MG"
  },
  {
      id_cidade: "2131",
      id_estado: "11",
      nm_cidade: "Pavão",
      nm_cidade_uf: "Pavão - MG"
  },
  {
      id_cidade: "2132",
      id_estado: "11",
      nm_cidade: "Peçanha",
      nm_cidade_uf: "Peçanha - MG"
  },
  {
      id_cidade: "2133",
      id_estado: "11",
      nm_cidade: "Pedra Azul",
      nm_cidade_uf: "Pedra Azul - MG"
  },
  {
      id_cidade: "2134",
      id_estado: "11",
      nm_cidade: "Pedra Bonita",
      nm_cidade_uf: "Pedra Bonita - MG"
  },
  {
      id_cidade: "2135",
      id_estado: "11",
      nm_cidade: "Pedra do Anta",
      nm_cidade_uf: "Pedra do Anta - MG"
  },
  {
      id_cidade: "2136",
      id_estado: "11",
      nm_cidade: "Pedra do Indaiá",
      nm_cidade_uf: "Pedra do Indaiá - MG"
  },
  {
      id_cidade: "2137",
      id_estado: "11",
      nm_cidade: "Pedra Dourada",
      nm_cidade_uf: "Pedra Dourada - MG"
  },
  {
      id_cidade: "2138",
      id_estado: "11",
      nm_cidade: "Pedralva",
      nm_cidade_uf: "Pedralva - MG"
  },
  {
      id_cidade: "2139",
      id_estado: "11",
      nm_cidade: "Pedras de Maria da Cruz",
      nm_cidade_uf: "Pedras de Maria da Cruz - MG"
  },
  {
      id_cidade: "2140",
      id_estado: "11",
      nm_cidade: "Pedrinópolis",
      nm_cidade_uf: "Pedrinópolis - MG"
  },
  {
      id_cidade: "2141",
      id_estado: "11",
      nm_cidade: "Pedro Leopoldo",
      nm_cidade_uf: "Pedro Leopoldo - MG"
  },
  {
      id_cidade: "2142",
      id_estado: "11",
      nm_cidade: "Pedro Teixeira",
      nm_cidade_uf: "Pedro Teixeira - MG"
  },
  {
      id_cidade: "2143",
      id_estado: "11",
      nm_cidade: "Pequeri",
      nm_cidade_uf: "Pequeri - MG"
  },
  {
      id_cidade: "2144",
      id_estado: "11",
      nm_cidade: "Pequi",
      nm_cidade_uf: "Pequi - MG"
  },
  {
      id_cidade: "2145",
      id_estado: "11",
      nm_cidade: "Perdigão",
      nm_cidade_uf: "Perdigão - MG"
  },
  {
      id_cidade: "2146",
      id_estado: "11",
      nm_cidade: "Perdizes",
      nm_cidade_uf: "Perdizes - MG"
  },
  {
      id_cidade: "2147",
      id_estado: "11",
      nm_cidade: "Perdões",
      nm_cidade_uf: "Perdões - MG"
  },
  {
      id_cidade: "2148",
      id_estado: "11",
      nm_cidade: "Periquito",
      nm_cidade_uf: "Periquito - MG"
  },
  {
      id_cidade: "2149",
      id_estado: "11",
      nm_cidade: "Pescador",
      nm_cidade_uf: "Pescador - MG"
  },
  {
      id_cidade: "2150",
      id_estado: "11",
      nm_cidade: "Piau",
      nm_cidade_uf: "Piau - MG"
  },
  {
      id_cidade: "2151",
      id_estado: "11",
      nm_cidade: "Piedade de Caratinga",
      nm_cidade_uf: "Piedade de Caratinga - MG"
  },
  {
      id_cidade: "2152",
      id_estado: "11",
      nm_cidade: "Piedade de Ponte Nova",
      nm_cidade_uf: "Piedade de Ponte Nova - MG"
  },
  {
      id_cidade: "2153",
      id_estado: "11",
      nm_cidade: "Piedade do Rio Grande",
      nm_cidade_uf: "Piedade do Rio Grande - MG"
  },
  {
      id_cidade: "2154",
      id_estado: "11",
      nm_cidade: "Piedade dos Gerais",
      nm_cidade_uf: "Piedade dos Gerais - MG"
  },
  {
      id_cidade: "2155",
      id_estado: "11",
      nm_cidade: "Pimenta",
      nm_cidade_uf: "Pimenta - MG"
  },
  {
      id_cidade: "2156",
      id_estado: "11",
      nm_cidade: "Pingo-d`Água",
      nm_cidade_uf: "Pingo-d`Água - MG"
  },
  {
      id_cidade: "2157",
      id_estado: "11",
      nm_cidade: "Pintópolis",
      nm_cidade_uf: "Pintópolis - MG"
  },
  {
      id_cidade: "2158",
      id_estado: "11",
      nm_cidade: "Piracema",
      nm_cidade_uf: "Piracema - MG"
  },
  {
      id_cidade: "2159",
      id_estado: "11",
      nm_cidade: "Pirajuba",
      nm_cidade_uf: "Pirajuba - MG"
  },
  {
      id_cidade: "2160",
      id_estado: "11",
      nm_cidade: "Piranga",
      nm_cidade_uf: "Piranga - MG"
  },
  {
      id_cidade: "2161",
      id_estado: "11",
      nm_cidade: "Piranguçu",
      nm_cidade_uf: "Piranguçu - MG"
  },
  {
      id_cidade: "2162",
      id_estado: "11",
      nm_cidade: "Piranguinho",
      nm_cidade_uf: "Piranguinho - MG"
  },
  {
      id_cidade: "2163",
      id_estado: "11",
      nm_cidade: "Pirapetinga",
      nm_cidade_uf: "Pirapetinga - MG"
  },
  {
      id_cidade: "2164",
      id_estado: "11",
      nm_cidade: "Pirapora",
      nm_cidade_uf: "Pirapora - MG"
  },
  {
      id_cidade: "2165",
      id_estado: "11",
      nm_cidade: "Piraúba",
      nm_cidade_uf: "Piraúba - MG"
  },
  {
      id_cidade: "2166",
      id_estado: "11",
      nm_cidade: "Pitangui",
      nm_cidade_uf: "Pitangui - MG"
  },
  {
      id_cidade: "2167",
      id_estado: "11",
      nm_cidade: "Piumhi",
      nm_cidade_uf: "Piumhi - MG"
  },
  {
      id_cidade: "2168",
      id_estado: "11",
      nm_cidade: "Planura",
      nm_cidade_uf: "Planura - MG"
  },
  {
      id_cidade: "2169",
      id_estado: "11",
      nm_cidade: "Poço Fundo",
      nm_cidade_uf: "Poço Fundo - MG"
  },
  {
      id_cidade: "2170",
      id_estado: "11",
      nm_cidade: "Poços de Caldas",
      nm_cidade_uf: "Poços de Caldas - MG"
  },
  {
      id_cidade: "2171",
      id_estado: "11",
      nm_cidade: "Pocrane",
      nm_cidade_uf: "Pocrane - MG"
  },
  {
      id_cidade: "2172",
      id_estado: "11",
      nm_cidade: "Pompéu",
      nm_cidade_uf: "Pompéu - MG"
  },
  {
      id_cidade: "2173",
      id_estado: "11",
      nm_cidade: "Ponte Nova",
      nm_cidade_uf: "Ponte Nova - MG"
  },
  {
      id_cidade: "2174",
      id_estado: "11",
      nm_cidade: "Ponto Chique",
      nm_cidade_uf: "Ponto Chique - MG"
  },
  {
      id_cidade: "2175",
      id_estado: "11",
      nm_cidade: "Ponto dos Volantes",
      nm_cidade_uf: "Ponto dos Volantes - MG"
  },
  {
      id_cidade: "2176",
      id_estado: "11",
      nm_cidade: "Porteirinha",
      nm_cidade_uf: "Porteirinha - MG"
  },
  {
      id_cidade: "2177",
      id_estado: "11",
      nm_cidade: "Porto Firme",
      nm_cidade_uf: "Porto Firme - MG"
  },
  {
      id_cidade: "2178",
      id_estado: "11",
      nm_cidade: "Poté",
      nm_cidade_uf: "Poté - MG"
  },
  {
      id_cidade: "2179",
      id_estado: "11",
      nm_cidade: "Pouso Alegre",
      nm_cidade_uf: "Pouso Alegre - MG"
  },
  {
      id_cidade: "2180",
      id_estado: "11",
      nm_cidade: "Pouso Alto",
      nm_cidade_uf: "Pouso Alto - MG"
  },
  {
      id_cidade: "2181",
      id_estado: "11",
      nm_cidade: "Prados",
      nm_cidade_uf: "Prados - MG"
  },
  {
      id_cidade: "2182",
      id_estado: "11",
      nm_cidade: "Prata",
      nm_cidade_uf: "Prata - MG"
  },
  {
      id_cidade: "2183",
      id_estado: "11",
      nm_cidade: "Pratápolis",
      nm_cidade_uf: "Pratápolis - MG"
  },
  {
      id_cidade: "2184",
      id_estado: "11",
      nm_cidade: "Pratinha",
      nm_cidade_uf: "Pratinha - MG"
  },
  {
      id_cidade: "2185",
      id_estado: "11",
      nm_cidade: "Presidente Bernardes",
      nm_cidade_uf: "Presidente Bernardes - MG"
  },
  {
      id_cidade: "2186",
      id_estado: "11",
      nm_cidade: "Presidente Juscelino",
      nm_cidade_uf: "Presidente Juscelino - MG"
  },
  {
      id_cidade: "2187",
      id_estado: "11",
      nm_cidade: "Presidente Kubitschek",
      nm_cidade_uf: "Presidente Kubitschek - MG"
  },
  {
      id_cidade: "2188",
      id_estado: "11",
      nm_cidade: "Presidente Olegário",
      nm_cidade_uf: "Presidente Olegário - MG"
  },
  {
      id_cidade: "2189",
      id_estado: "11",
      nm_cidade: "Prudente de Morais",
      nm_cidade_uf: "Prudente de Morais - MG"
  },
  {
      id_cidade: "2190",
      id_estado: "11",
      nm_cidade: "Quartel Geral",
      nm_cidade_uf: "Quartel Geral - MG"
  },
  {
      id_cidade: "2191",
      id_estado: "11",
      nm_cidade: "Queluzito",
      nm_cidade_uf: "Queluzito - MG"
  },
  {
      id_cidade: "2192",
      id_estado: "11",
      nm_cidade: "Raposos",
      nm_cidade_uf: "Raposos - MG"
  },
  {
      id_cidade: "2193",
      id_estado: "11",
      nm_cidade: "Raul Soares",
      nm_cidade_uf: "Raul Soares - MG"
  },
  {
      id_cidade: "2194",
      id_estado: "11",
      nm_cidade: "Recreio",
      nm_cidade_uf: "Recreio - MG"
  },
  {
      id_cidade: "2195",
      id_estado: "11",
      nm_cidade: "Reduto",
      nm_cidade_uf: "Reduto - MG"
  },
  {
      id_cidade: "2196",
      id_estado: "11",
      nm_cidade: "Resende Costa",
      nm_cidade_uf: "Resende Costa - MG"
  },
  {
      id_cidade: "2197",
      id_estado: "11",
      nm_cidade: "Resplendor",
      nm_cidade_uf: "Resplendor - MG"
  },
  {
      id_cidade: "2198",
      id_estado: "11",
      nm_cidade: "Ressaquinha",
      nm_cidade_uf: "Ressaquinha - MG"
  },
  {
      id_cidade: "2199",
      id_estado: "11",
      nm_cidade: "Riachinho",
      nm_cidade_uf: "Riachinho - MG"
  },
  {
      id_cidade: "2200",
      id_estado: "11",
      nm_cidade: "Riacho dos Machados",
      nm_cidade_uf: "Riacho dos Machados - MG"
  },
  {
      id_cidade: "2201",
      id_estado: "11",
      nm_cidade: "Ribeirão das Neves",
      nm_cidade_uf: "Ribeirão das Neves - MG"
  },
  {
      id_cidade: "2202",
      id_estado: "11",
      nm_cidade: "Ribeirão Vermelho",
      nm_cidade_uf: "Ribeirão Vermelho - MG"
  },
  {
      id_cidade: "2203",
      id_estado: "11",
      nm_cidade: "Rio Acima",
      nm_cidade_uf: "Rio Acima - MG"
  },
  {
      id_cidade: "2204",
      id_estado: "11",
      nm_cidade: "Rio Casca",
      nm_cidade_uf: "Rio Casca - MG"
  },
  {
      id_cidade: "2205",
      id_estado: "11",
      nm_cidade: "Rio do Prado",
      nm_cidade_uf: "Rio do Prado - MG"
  },
  {
      id_cidade: "2206",
      id_estado: "11",
      nm_cidade: "Rio Doce",
      nm_cidade_uf: "Rio Doce - MG"
  },
  {
      id_cidade: "2207",
      id_estado: "11",
      nm_cidade: "Rio Espera",
      nm_cidade_uf: "Rio Espera - MG"
  },
  {
      id_cidade: "2208",
      id_estado: "11",
      nm_cidade: "Rio Manso",
      nm_cidade_uf: "Rio Manso - MG"
  },
  {
      id_cidade: "2209",
      id_estado: "11",
      nm_cidade: "Rio Novo",
      nm_cidade_uf: "Rio Novo - MG"
  },
  {
      id_cidade: "2210",
      id_estado: "11",
      nm_cidade: "Rio Paranaíba",
      nm_cidade_uf: "Rio Paranaíba - MG"
  },
  {
      id_cidade: "2211",
      id_estado: "11",
      nm_cidade: "Rio Pardo de Minas",
      nm_cidade_uf: "Rio Pardo de Minas - MG"
  },
  {
      id_cidade: "2212",
      id_estado: "11",
      nm_cidade: "Rio Piracicaba",
      nm_cidade_uf: "Rio Piracicaba - MG"
  },
  {
      id_cidade: "2213",
      id_estado: "11",
      nm_cidade: "Rio Pomba",
      nm_cidade_uf: "Rio Pomba - MG"
  },
  {
      id_cidade: "2214",
      id_estado: "11",
      nm_cidade: "Rio Preto",
      nm_cidade_uf: "Rio Preto - MG"
  },
  {
      id_cidade: "2215",
      id_estado: "11",
      nm_cidade: "Rio Vermelho",
      nm_cidade_uf: "Rio Vermelho - MG"
  },
  {
      id_cidade: "2216",
      id_estado: "11",
      nm_cidade: "Ritápolis",
      nm_cidade_uf: "Ritápolis - MG"
  },
  {
      id_cidade: "2217",
      id_estado: "11",
      nm_cidade: "Rochedo de Minas",
      nm_cidade_uf: "Rochedo de Minas - MG"
  },
  {
      id_cidade: "2218",
      id_estado: "11",
      nm_cidade: "Rodeiro",
      nm_cidade_uf: "Rodeiro - MG"
  },
  {
      id_cidade: "2219",
      id_estado: "11",
      nm_cidade: "Romaria",
      nm_cidade_uf: "Romaria - MG"
  },
  {
      id_cidade: "2220",
      id_estado: "11",
      nm_cidade: "Rosário da Limeira",
      nm_cidade_uf: "Rosário da Limeira - MG"
  },
  {
      id_cidade: "2221",
      id_estado: "11",
      nm_cidade: "Rubelita",
      nm_cidade_uf: "Rubelita - MG"
  },
  {
      id_cidade: "2222",
      id_estado: "11",
      nm_cidade: "Rubim",
      nm_cidade_uf: "Rubim - MG"
  },
  {
      id_cidade: "2223",
      id_estado: "11",
      nm_cidade: "Sabará",
      nm_cidade_uf: "Sabará - MG"
  },
  {
      id_cidade: "2224",
      id_estado: "11",
      nm_cidade: "Sabinópolis",
      nm_cidade_uf: "Sabinópolis - MG"
  },
  {
      id_cidade: "2225",
      id_estado: "11",
      nm_cidade: "Sacramento",
      nm_cidade_uf: "Sacramento - MG"
  },
  {
      id_cidade: "2226",
      id_estado: "11",
      nm_cidade: "Salinas",
      nm_cidade_uf: "Salinas - MG"
  },
  {
      id_cidade: "2227",
      id_estado: "11",
      nm_cidade: "Salto da Divisa",
      nm_cidade_uf: "Salto da Divisa - MG"
  },
  {
      id_cidade: "2228",
      id_estado: "11",
      nm_cidade: "Santa Bárbara",
      nm_cidade_uf: "Santa Bárbara - MG"
  },
  {
      id_cidade: "2229",
      id_estado: "11",
      nm_cidade: "Santa Bárbara do Leste",
      nm_cidade_uf: "Santa Bárbara do Leste - MG"
  },
  {
      id_cidade: "2230",
      id_estado: "11",
      nm_cidade: "Santa Bárbara do Monte Verde",
      nm_cidade_uf: "Santa Bárbara do Monte Verde - MG"
  },
  {
      id_cidade: "2231",
      id_estado: "11",
      nm_cidade: "Santa Bárbara do Tugúrio",
      nm_cidade_uf: "Santa Bárbara do Tugúrio - MG"
  },
  {
      id_cidade: "2232",
      id_estado: "11",
      nm_cidade: "Santa Cruz de Minas",
      nm_cidade_uf: "Santa Cruz de Minas - MG"
  },
  {
      id_cidade: "2233",
      id_estado: "11",
      nm_cidade: "Santa Cruz de Salinas",
      nm_cidade_uf: "Santa Cruz de Salinas - MG"
  },
  {
      id_cidade: "2234",
      id_estado: "11",
      nm_cidade: "Santa Cruz do Escalvado",
      nm_cidade_uf: "Santa Cruz do Escalvado - MG"
  },
  {
      id_cidade: "2235",
      id_estado: "11",
      nm_cidade: "Santa Efigênia de Minas",
      nm_cidade_uf: "Santa Efigênia de Minas - MG"
  },
  {
      id_cidade: "2236",
      id_estado: "11",
      nm_cidade: "Santa Fé de Minas",
      nm_cidade_uf: "Santa Fé de Minas - MG"
  },
  {
      id_cidade: "2237",
      id_estado: "11",
      nm_cidade: "Santa Helena de Minas",
      nm_cidade_uf: "Santa Helena de Minas - MG"
  },
  {
      id_cidade: "2238",
      id_estado: "11",
      nm_cidade: "Santa Juliana",
      nm_cidade_uf: "Santa Juliana - MG"
  },
  {
      id_cidade: "2239",
      id_estado: "11",
      nm_cidade: "Santa Luzia",
      nm_cidade_uf: "Santa Luzia - MG"
  },
  {
      id_cidade: "2240",
      id_estado: "11",
      nm_cidade: "Santa Margarida",
      nm_cidade_uf: "Santa Margarida - MG"
  },
  {
      id_cidade: "2241",
      id_estado: "11",
      nm_cidade: "Santa Maria de Itabira",
      nm_cidade_uf: "Santa Maria de Itabira - MG"
  },
  {
      id_cidade: "2242",
      id_estado: "11",
      nm_cidade: "Santa Maria do Salto",
      nm_cidade_uf: "Santa Maria do Salto - MG"
  },
  {
      id_cidade: "2243",
      id_estado: "11",
      nm_cidade: "Santa Maria do Suaçuí",
      nm_cidade_uf: "Santa Maria do Suaçuí - MG"
  },
  {
      id_cidade: "2244",
      id_estado: "11",
      nm_cidade: "Santa Rita de Caldas",
      nm_cidade_uf: "Santa Rita de Caldas - MG"
  },
  {
      id_cidade: "2245",
      id_estado: "11",
      nm_cidade: "Santa Rita de Ibitipoca",
      nm_cidade_uf: "Santa Rita de Ibitipoca - MG"
  },
  {
      id_cidade: "2246",
      id_estado: "11",
      nm_cidade: "Santa Rita de Jacutinga",
      nm_cidade_uf: "Santa Rita de Jacutinga - MG"
  },
  {
      id_cidade: "2247",
      id_estado: "11",
      nm_cidade: "Santa Rita de Minas",
      nm_cidade_uf: "Santa Rita de Minas - MG"
  },
  {
      id_cidade: "2248",
      id_estado: "11",
      nm_cidade: "Santa Rita do Itueto",
      nm_cidade_uf: "Santa Rita do Itueto - MG"
  },
  {
      id_cidade: "2249",
      id_estado: "11",
      nm_cidade: "Santa Rita do Sapucaí",
      nm_cidade_uf: "Santa Rita do Sapucaí - MG"
  },
  {
      id_cidade: "2250",
      id_estado: "11",
      nm_cidade: "Santa Rosa da Serra",
      nm_cidade_uf: "Santa Rosa da Serra - MG"
  },
  {
      id_cidade: "2251",
      id_estado: "11",
      nm_cidade: "Santa Vitória",
      nm_cidade_uf: "Santa Vitória - MG"
  },
  {
      id_cidade: "2252",
      id_estado: "11",
      nm_cidade: "Santana da Vargem",
      nm_cidade_uf: "Santana da Vargem - MG"
  },
  {
      id_cidade: "2253",
      id_estado: "11",
      nm_cidade: "Santana de Cataguases",
      nm_cidade_uf: "Santana de Cataguases - MG"
  },
  {
      id_cidade: "2254",
      id_estado: "11",
      nm_cidade: "Santana de Pirapama",
      nm_cidade_uf: "Santana de Pirapama - MG"
  },
  {
      id_cidade: "2255",
      id_estado: "11",
      nm_cidade: "Santana do Deserto",
      nm_cidade_uf: "Santana do Deserto - MG"
  },
  {
      id_cidade: "2256",
      id_estado: "11",
      nm_cidade: "Santana do Garambéu",
      nm_cidade_uf: "Santana do Garambéu - MG"
  },
  {
      id_cidade: "2257",
      id_estado: "11",
      nm_cidade: "Santana do Jacaré",
      nm_cidade_uf: "Santana do Jacaré - MG"
  },
  {
      id_cidade: "2258",
      id_estado: "11",
      nm_cidade: "Santana do Manhuaçu",
      nm_cidade_uf: "Santana do Manhuaçu - MG"
  },
  {
      id_cidade: "2259",
      id_estado: "11",
      nm_cidade: "Santana do Paraíso",
      nm_cidade_uf: "Santana do Paraíso - MG"
  },
  {
      id_cidade: "2260",
      id_estado: "11",
      nm_cidade: "Santana do Riacho",
      nm_cidade_uf: "Santana do Riacho - MG"
  },
  {
      id_cidade: "2261",
      id_estado: "11",
      nm_cidade: "Santana dos Montes",
      nm_cidade_uf: "Santana dos Montes - MG"
  },
  {
      id_cidade: "2262",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Amparo",
      nm_cidade_uf: "Santo Antônio do Amparo - MG"
  },
  {
      id_cidade: "2263",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Aventureiro",
      nm_cidade_uf: "Santo Antônio do Aventureiro - MG"
  },
  {
      id_cidade: "2264",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Grama",
      nm_cidade_uf: "Santo Antônio do Grama - MG"
  },
  {
      id_cidade: "2265",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Itambé",
      nm_cidade_uf: "Santo Antônio do Itambé - MG"
  },
  {
      id_cidade: "2266",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Jacinto",
      nm_cidade_uf: "Santo Antônio do Jacinto - MG"
  },
  {
      id_cidade: "2267",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Monte",
      nm_cidade_uf: "Santo Antônio do Monte - MG"
  },
  {
      id_cidade: "2268",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Retiro",
      nm_cidade_uf: "Santo Antônio do Retiro - MG"
  },
  {
      id_cidade: "2269",
      id_estado: "11",
      nm_cidade: "Santo Antônio do Rio Abaixo",
      nm_cidade_uf: "Santo Antônio do Rio Abaixo - MG"
  },
  {
      id_cidade: "2270",
      id_estado: "11",
      nm_cidade: "Santo Hipólito",
      nm_cidade_uf: "Santo Hipólito - MG"
  },
  {
      id_cidade: "2271",
      id_estado: "11",
      nm_cidade: "Santos Dumont",
      nm_cidade_uf: "Santos Dumont - MG"
  },
  {
      id_cidade: "2272",
      id_estado: "11",
      nm_cidade: "São Bento Abade",
      nm_cidade_uf: "São Bento Abade - MG"
  },
  {
      id_cidade: "2273",
      id_estado: "11",
      nm_cidade: "São Brás do Suaçuí",
      nm_cidade_uf: "São Brás do Suaçuí - MG"
  },
  {
      id_cidade: "2274",
      id_estado: "11",
      nm_cidade: "São Domingos das Dores",
      nm_cidade_uf: "São Domingos das Dores - MG"
  },
  {
      id_cidade: "2275",
      id_estado: "11",
      nm_cidade: "São Domingos do Prata",
      nm_cidade_uf: "São Domingos do Prata - MG"
  },
  {
      id_cidade: "2276",
      id_estado: "11",
      nm_cidade: "São Félix de Minas",
      nm_cidade_uf: "São Félix de Minas - MG"
  },
  {
      id_cidade: "2277",
      id_estado: "11",
      nm_cidade: "São Francisco",
      nm_cidade_uf: "São Francisco - MG"
  },
  {
      id_cidade: "2278",
      id_estado: "11",
      nm_cidade: "São Francisco de Paula",
      nm_cidade_uf: "São Francisco de Paula - MG"
  },
  {
      id_cidade: "2279",
      id_estado: "11",
      nm_cidade: "São Francisco de Sales",
      nm_cidade_uf: "São Francisco de Sales - MG"
  },
  {
      id_cidade: "2280",
      id_estado: "11",
      nm_cidade: "São Francisco do Glória",
      nm_cidade_uf: "São Francisco do Glória - MG"
  },
  {
      id_cidade: "2281",
      id_estado: "11",
      nm_cidade: "São Geraldo",
      nm_cidade_uf: "São Geraldo - MG"
  },
  {
      id_cidade: "2282",
      id_estado: "11",
      nm_cidade: "São Geraldo da Piedade",
      nm_cidade_uf: "São Geraldo da Piedade - MG"
  },
  {
      id_cidade: "2283",
      id_estado: "11",
      nm_cidade: "São Geraldo do Baixio",
      nm_cidade_uf: "São Geraldo do Baixio - MG"
  },
  {
      id_cidade: "2284",
      id_estado: "11",
      nm_cidade: "São Gonçalo do Abaeté",
      nm_cidade_uf: "São Gonçalo do Abaeté - MG"
  },
  {
      id_cidade: "2285",
      id_estado: "11",
      nm_cidade: "São Gonçalo do Pará",
      nm_cidade_uf: "São Gonçalo do Pará - MG"
  },
  {
      id_cidade: "2286",
      id_estado: "11",
      nm_cidade: "São Gonçalo do Rio Abaixo",
      nm_cidade_uf: "São Gonçalo do Rio Abaixo - MG"
  },
  {
      id_cidade: "2287",
      id_estado: "11",
      nm_cidade: "São Gonçalo do Rio Preto",
      nm_cidade_uf: "São Gonçalo do Rio Preto - MG"
  },
  {
      id_cidade: "2288",
      id_estado: "11",
      nm_cidade: "São Gonçalo do Sapucaí",
      nm_cidade_uf: "São Gonçalo do Sapucaí - MG"
  },
  {
      id_cidade: "2289",
      id_estado: "11",
      nm_cidade: "São Gotardo",
      nm_cidade_uf: "São Gotardo - MG"
  },
  {
      id_cidade: "2290",
      id_estado: "11",
      nm_cidade: "São João Batista do Glória",
      nm_cidade_uf: "São João Batista do Glória - MG"
  },
  {
      id_cidade: "2291",
      id_estado: "11",
      nm_cidade: "São João da Lagoa",
      nm_cidade_uf: "São João da Lagoa - MG"
  },
  {
      id_cidade: "2292",
      id_estado: "11",
      nm_cidade: "São João da Mata",
      nm_cidade_uf: "São João da Mata - MG"
  },
  {
      id_cidade: "2293",
      id_estado: "11",
      nm_cidade: "São João da Ponte",
      nm_cidade_uf: "São João da Ponte - MG"
  },
  {
      id_cidade: "2294",
      id_estado: "11",
      nm_cidade: "São João das Missões",
      nm_cidade_uf: "São João das Missões - MG"
  },
  {
      id_cidade: "2295",
      id_estado: "11",
      nm_cidade: "São João del Rei",
      nm_cidade_uf: "São João del Rei - MG"
  },
  {
      id_cidade: "2296",
      id_estado: "11",
      nm_cidade: "São João do Manhuaçu",
      nm_cidade_uf: "São João do Manhuaçu - MG"
  },
  {
      id_cidade: "2297",
      id_estado: "11",
      nm_cidade: "São João do Manteninha",
      nm_cidade_uf: "São João do Manteninha - MG"
  },
  {
      id_cidade: "2298",
      id_estado: "11",
      nm_cidade: "São João do Oriente",
      nm_cidade_uf: "São João do Oriente - MG"
  },
  {
      id_cidade: "2299",
      id_estado: "11",
      nm_cidade: "São João do Pacuí",
      nm_cidade_uf: "São João do Pacuí - MG"
  },
  {
      id_cidade: "2300",
      id_estado: "11",
      nm_cidade: "São João do Paraíso",
      nm_cidade_uf: "São João do Paraíso - MG"
  },
  {
      id_cidade: "2301",
      id_estado: "11",
      nm_cidade: "São João Evangelista",
      nm_cidade_uf: "São João Evangelista - MG"
  },
  {
      id_cidade: "2302",
      id_estado: "11",
      nm_cidade: "São João Nepomuceno",
      nm_cidade_uf: "São João Nepomuceno - MG"
  },
  {
      id_cidade: "2303",
      id_estado: "11",
      nm_cidade: "São Joaquim de Bicas",
      nm_cidade_uf: "São Joaquim de Bicas - MG"
  },
  {
      id_cidade: "2304",
      id_estado: "11",
      nm_cidade: "São José da Barra",
      nm_cidade_uf: "São José da Barra - MG"
  },
  {
      id_cidade: "2305",
      id_estado: "11",
      nm_cidade: "São José da Lapa",
      nm_cidade_uf: "São José da Lapa - MG"
  },
  {
      id_cidade: "2306",
      id_estado: "11",
      nm_cidade: "São José da Safira",
      nm_cidade_uf: "São José da Safira - MG"
  },
  {
      id_cidade: "2307",
      id_estado: "11",
      nm_cidade: "São José da Varginha",
      nm_cidade_uf: "São José da Varginha - MG"
  },
  {
      id_cidade: "2308",
      id_estado: "11",
      nm_cidade: "São José do Alegre",
      nm_cidade_uf: "São José do Alegre - MG"
  },
  {
      id_cidade: "2309",
      id_estado: "11",
      nm_cidade: "São José do Divino",
      nm_cidade_uf: "São José do Divino - MG"
  },
  {
      id_cidade: "2310",
      id_estado: "11",
      nm_cidade: "São José do Goiabal",
      nm_cidade_uf: "São José do Goiabal - MG"
  },
  {
      id_cidade: "2311",
      id_estado: "11",
      nm_cidade: "São José do Jacuri",
      nm_cidade_uf: "São José do Jacuri - MG"
  },
  {
      id_cidade: "2312",
      id_estado: "11",
      nm_cidade: "São José do Mantimento",
      nm_cidade_uf: "São José do Mantimento - MG"
  },
  {
      id_cidade: "2313",
      id_estado: "11",
      nm_cidade: "São Lourenço",
      nm_cidade_uf: "São Lourenço - MG"
  },
  {
      id_cidade: "2314",
      id_estado: "11",
      nm_cidade: "São Miguel do Anta",
      nm_cidade_uf: "São Miguel do Anta - MG"
  },
  {
      id_cidade: "2315",
      id_estado: "11",
      nm_cidade: "São Pedro da União",
      nm_cidade_uf: "São Pedro da União - MG"
  },
  {
      id_cidade: "2316",
      id_estado: "11",
      nm_cidade: "São Pedro do Suaçuí",
      nm_cidade_uf: "São Pedro do Suaçuí - MG"
  },
  {
      id_cidade: "2317",
      id_estado: "11",
      nm_cidade: "São Pedro dos Ferros",
      nm_cidade_uf: "São Pedro dos Ferros - MG"
  },
  {
      id_cidade: "2318",
      id_estado: "11",
      nm_cidade: "São Romão",
      nm_cidade_uf: "São Romão - MG"
  },
  {
      id_cidade: "2319",
      id_estado: "11",
      nm_cidade: "São Roque de Minas",
      nm_cidade_uf: "São Roque de Minas - MG"
  },
  {
      id_cidade: "2320",
      id_estado: "11",
      nm_cidade: "São Sebastião da Bela Vista",
      nm_cidade_uf: "São Sebastião da Bela Vista - MG"
  },
  {
      id_cidade: "2321",
      id_estado: "11",
      nm_cidade: "São Sebastião da Vargem Alegre",
      nm_cidade_uf: "São Sebastião da Vargem Alegre - MG"
  },
  {
      id_cidade: "2322",
      id_estado: "11",
      nm_cidade: "São Sebastião do Anta",
      nm_cidade_uf: "São Sebastião do Anta - MG"
  },
  {
      id_cidade: "2323",
      id_estado: "11",
      nm_cidade: "São Sebastião do Maranhão",
      nm_cidade_uf: "São Sebastião do Maranhão - MG"
  },
  {
      id_cidade: "2324",
      id_estado: "11",
      nm_cidade: "São Sebastião do Oeste",
      nm_cidade_uf: "São Sebastião do Oeste - MG"
  },
  {
      id_cidade: "2325",
      id_estado: "11",
      nm_cidade: "São Sebastião do Paraíso",
      nm_cidade_uf: "São Sebastião do Paraíso - MG"
  },
  {
      id_cidade: "2326",
      id_estado: "11",
      nm_cidade: "São Sebastião do Rio Preto",
      nm_cidade_uf: "São Sebastião do Rio Preto - MG"
  },
  {
      id_cidade: "2327",
      id_estado: "11",
      nm_cidade: "São Sebastião do Rio Verde",
      nm_cidade_uf: "São Sebastião do Rio Verde - MG"
  },
  {
      id_cidade: "2328",
      id_estado: "11",
      nm_cidade: "São Thomé das Letras",
      nm_cidade_uf: "São Thomé das Letras - MG"
  },
  {
      id_cidade: "2329",
      id_estado: "11",
      nm_cidade: "São Tiago",
      nm_cidade_uf: "São Tiago - MG"
  },
  {
      id_cidade: "2330",
      id_estado: "11",
      nm_cidade: "São Tomás de Aquino",
      nm_cidade_uf: "São Tomás de Aquino - MG"
  },
  {
      id_cidade: "2331",
      id_estado: "11",
      nm_cidade: "São Vicente de Minas",
      nm_cidade_uf: "São Vicente de Minas - MG"
  },
  {
      id_cidade: "2332",
      id_estado: "11",
      nm_cidade: "Sapucaí-Mirim",
      nm_cidade_uf: "Sapucaí-Mirim - MG"
  },
  {
      id_cidade: "2333",
      id_estado: "11",
      nm_cidade: "Sardoá",
      nm_cidade_uf: "Sardoá - MG"
  },
  {
      id_cidade: "2334",
      id_estado: "11",
      nm_cidade: "Sarzedo",
      nm_cidade_uf: "Sarzedo - MG"
  },
  {
      id_cidade: "2335",
      id_estado: "11",
      nm_cidade: "Sem-Peixe",
      nm_cidade_uf: "Sem-Peixe - MG"
  },
  {
      id_cidade: "2336",
      id_estado: "11",
      nm_cidade: "Senador Amaral",
      nm_cidade_uf: "Senador Amaral - MG"
  },
  {
      id_cidade: "2337",
      id_estado: "11",
      nm_cidade: "Senador Cortes",
      nm_cidade_uf: "Senador Cortes - MG"
  },
  {
      id_cidade: "2338",
      id_estado: "11",
      nm_cidade: "Senador Firmino",
      nm_cidade_uf: "Senador Firmino - MG"
  },
  {
      id_cidade: "2339",
      id_estado: "11",
      nm_cidade: "Senador José Bento",
      nm_cidade_uf: "Senador José Bento - MG"
  },
  {
      id_cidade: "2340",
      id_estado: "11",
      nm_cidade: "Senador Modestino Gonçalves",
      nm_cidade_uf: "Senador Modestino Gonçalves - MG"
  },
  {
      id_cidade: "2341",
      id_estado: "11",
      nm_cidade: "Senhora de Oliveira",
      nm_cidade_uf: "Senhora de Oliveira - MG"
  },
  {
      id_cidade: "2342",
      id_estado: "11",
      nm_cidade: "Senhora do Porto",
      nm_cidade_uf: "Senhora do Porto - MG"
  },
  {
      id_cidade: "2343",
      id_estado: "11",
      nm_cidade: "Senhora dos Remédios",
      nm_cidade_uf: "Senhora dos Remédios - MG"
  },
  {
      id_cidade: "2344",
      id_estado: "11",
      nm_cidade: "Sericita",
      nm_cidade_uf: "Sericita - MG"
  },
  {
      id_cidade: "2345",
      id_estado: "11",
      nm_cidade: "Seritinga",
      nm_cidade_uf: "Seritinga - MG"
  },
  {
      id_cidade: "2346",
      id_estado: "11",
      nm_cidade: "Serra Azul de Minas",
      nm_cidade_uf: "Serra Azul de Minas - MG"
  },
  {
      id_cidade: "2347",
      id_estado: "11",
      nm_cidade: "Serra da Saudade",
      nm_cidade_uf: "Serra da Saudade - MG"
  },
  {
      id_cidade: "2348",
      id_estado: "11",
      nm_cidade: "Serra do Salitre",
      nm_cidade_uf: "Serra do Salitre - MG"
  },
  {
      id_cidade: "2349",
      id_estado: "11",
      nm_cidade: "Serra dos Aimorés",
      nm_cidade_uf: "Serra dos Aimorés - MG"
  },
  {
      id_cidade: "2350",
      id_estado: "11",
      nm_cidade: "Serrania",
      nm_cidade_uf: "Serrania - MG"
  },
  {
      id_cidade: "2351",
      id_estado: "11",
      nm_cidade: "Serranópolis de Minas",
      nm_cidade_uf: "Serranópolis de Minas - MG"
  },
  {
      id_cidade: "2352",
      id_estado: "11",
      nm_cidade: "Serranos",
      nm_cidade_uf: "Serranos - MG"
  },
  {
      id_cidade: "2353",
      id_estado: "11",
      nm_cidade: "Serro",
      nm_cidade_uf: "Serro - MG"
  },
  {
      id_cidade: "2354",
      id_estado: "11",
      nm_cidade: "Sete Lagoas",
      nm_cidade_uf: "Sete Lagoas - MG"
  },
  {
      id_cidade: "2355",
      id_estado: "11",
      nm_cidade: "Setubinha",
      nm_cidade_uf: "Setubinha - MG"
  },
  {
      id_cidade: "2356",
      id_estado: "11",
      nm_cidade: "Silveirânia",
      nm_cidade_uf: "Silveirânia - MG"
  },
  {
      id_cidade: "2357",
      id_estado: "11",
      nm_cidade: "Silvianópolis",
      nm_cidade_uf: "Silvianópolis - MG"
  },
  {
      id_cidade: "2358",
      id_estado: "11",
      nm_cidade: "Simão Pereira",
      nm_cidade_uf: "Simão Pereira - MG"
  },
  {
      id_cidade: "2359",
      id_estado: "11",
      nm_cidade: "Simonésia",
      nm_cidade_uf: "Simonésia - MG"
  },
  {
      id_cidade: "2360",
      id_estado: "11",
      nm_cidade: "Sobrália",
      nm_cidade_uf: "Sobrália - MG"
  },
  {
      id_cidade: "2361",
      id_estado: "11",
      nm_cidade: "Soledade de Minas",
      nm_cidade_uf: "Soledade de Minas - MG"
  },
  {
      id_cidade: "2362",
      id_estado: "11",
      nm_cidade: "Tabuleiro",
      nm_cidade_uf: "Tabuleiro - MG"
  },
  {
      id_cidade: "2363",
      id_estado: "11",
      nm_cidade: "Taiobeiras",
      nm_cidade_uf: "Taiobeiras - MG"
  },
  {
      id_cidade: "2364",
      id_estado: "11",
      nm_cidade: "Taparuba",
      nm_cidade_uf: "Taparuba - MG"
  },
  {
      id_cidade: "2365",
      id_estado: "11",
      nm_cidade: "Tapira",
      nm_cidade_uf: "Tapira - MG"
  },
  {
      id_cidade: "2366",
      id_estado: "11",
      nm_cidade: "Tapiraí",
      nm_cidade_uf: "Tapiraí - MG"
  },
  {
      id_cidade: "2367",
      id_estado: "11",
      nm_cidade: "Taquaraçu de Minas",
      nm_cidade_uf: "Taquaraçu de Minas - MG"
  },
  {
      id_cidade: "2368",
      id_estado: "11",
      nm_cidade: "Tarumirim",
      nm_cidade_uf: "Tarumirim - MG"
  },
  {
      id_cidade: "2369",
      id_estado: "11",
      nm_cidade: "Teixeiras",
      nm_cidade_uf: "Teixeiras - MG"
  },
  {
      id_cidade: "2370",
      id_estado: "11",
      nm_cidade: "Teófilo Otoni",
      nm_cidade_uf: "Teófilo Otoni - MG"
  },
  {
      id_cidade: "2371",
      id_estado: "11",
      nm_cidade: "Timóteo",
      nm_cidade_uf: "Timóteo - MG"
  },
  {
      id_cidade: "2372",
      id_estado: "11",
      nm_cidade: "Tiradentes",
      nm_cidade_uf: "Tiradentes - MG"
  },
  {
      id_cidade: "2373",
      id_estado: "11",
      nm_cidade: "Tiros",
      nm_cidade_uf: "Tiros - MG"
  },
  {
      id_cidade: "2374",
      id_estado: "11",
      nm_cidade: "Tocantins",
      nm_cidade_uf: "Tocantins - MG"
  },
  {
      id_cidade: "2375",
      id_estado: "11",
      nm_cidade: "Tocos do Moji",
      nm_cidade_uf: "Tocos do Moji - MG"
  },
  {
      id_cidade: "2376",
      id_estado: "11",
      nm_cidade: "Toledo",
      nm_cidade_uf: "Toledo - MG"
  },
  {
      id_cidade: "2377",
      id_estado: "11",
      nm_cidade: "Tombos",
      nm_cidade_uf: "Tombos - MG"
  },
  {
      id_cidade: "2378",
      id_estado: "11",
      nm_cidade: "Três Corações",
      nm_cidade_uf: "Três Corações - MG"
  },
  {
      id_cidade: "2379",
      id_estado: "11",
      nm_cidade: "Três Marias",
      nm_cidade_uf: "Três Marias - MG"
  },
  {
      id_cidade: "2380",
      id_estado: "11",
      nm_cidade: "Três Pontas",
      nm_cidade_uf: "Três Pontas - MG"
  },
  {
      id_cidade: "2381",
      id_estado: "11",
      nm_cidade: "Tumiritinga",
      nm_cidade_uf: "Tumiritinga - MG"
  },
  {
      id_cidade: "2382",
      id_estado: "11",
      nm_cidade: "Tupaciguara",
      nm_cidade_uf: "Tupaciguara - MG"
  },
  {
      id_cidade: "2383",
      id_estado: "11",
      nm_cidade: "Turmalina",
      nm_cidade_uf: "Turmalina - MG"
  },
  {
      id_cidade: "2384",
      id_estado: "11",
      nm_cidade: "Turvolândia",
      nm_cidade_uf: "Turvolândia - MG"
  },
  {
      id_cidade: "2385",
      id_estado: "11",
      nm_cidade: "Ubá",
      nm_cidade_uf: "Ubá - MG"
  },
  {
      id_cidade: "2386",
      id_estado: "11",
      nm_cidade: "Ubaí",
      nm_cidade_uf: "Ubaí - MG"
  },
  {
      id_cidade: "2387",
      id_estado: "11",
      nm_cidade: "Ubaporanga",
      nm_cidade_uf: "Ubaporanga - MG"
  },
  {
      id_cidade: "2388",
      id_estado: "11",
      nm_cidade: "Uberaba",
      nm_cidade_uf: "Uberaba - MG"
  },
  {
      id_cidade: "2389",
      id_estado: "11",
      nm_cidade: "Uberlândia",
      nm_cidade_uf: "Uberlândia - MG"
  },
  {
      id_cidade: "2390",
      id_estado: "11",
      nm_cidade: "Umburatiba",
      nm_cidade_uf: "Umburatiba - MG"
  },
  {
      id_cidade: "2391",
      id_estado: "11",
      nm_cidade: "Unaí",
      nm_cidade_uf: "Unaí - MG"
  },
  {
      id_cidade: "2392",
      id_estado: "11",
      nm_cidade: "União de Minas",
      nm_cidade_uf: "União de Minas - MG"
  },
  {
      id_cidade: "2393",
      id_estado: "11",
      nm_cidade: "Uruana de Minas",
      nm_cidade_uf: "Uruana de Minas - MG"
  },
  {
      id_cidade: "2394",
      id_estado: "11",
      nm_cidade: "Urucânia",
      nm_cidade_uf: "Urucânia - MG"
  },
  {
      id_cidade: "2395",
      id_estado: "11",
      nm_cidade: "Urucuia",
      nm_cidade_uf: "Urucuia - MG"
  },
  {
      id_cidade: "2396",
      id_estado: "11",
      nm_cidade: "Vargem Alegre",
      nm_cidade_uf: "Vargem Alegre - MG"
  },
  {
      id_cidade: "2397",
      id_estado: "11",
      nm_cidade: "Vargem Bonita",
      nm_cidade_uf: "Vargem Bonita - MG"
  },
  {
      id_cidade: "2398",
      id_estado: "11",
      nm_cidade: "Vargem Grande do Rio Pardo",
      nm_cidade_uf: "Vargem Grande do Rio Pardo - MG"
  },
  {
      id_cidade: "2399",
      id_estado: "11",
      nm_cidade: "Varginha",
      nm_cidade_uf: "Varginha - MG"
  },
  {
      id_cidade: "2400",
      id_estado: "11",
      nm_cidade: "Varjão de Minas",
      nm_cidade_uf: "Varjão de Minas - MG"
  },
  {
      id_cidade: "2401",
      id_estado: "11",
      nm_cidade: "Várzea da Palma",
      nm_cidade_uf: "Várzea da Palma - MG"
  },
  {
      id_cidade: "2402",
      id_estado: "11",
      nm_cidade: "Varzelândia",
      nm_cidade_uf: "Varzelândia - MG"
  },
  {
      id_cidade: "2403",
      id_estado: "11",
      nm_cidade: "Vazante",
      nm_cidade_uf: "Vazante - MG"
  },
  {
      id_cidade: "2404",
      id_estado: "11",
      nm_cidade: "Verdelândia",
      nm_cidade_uf: "Verdelândia - MG"
  },
  {
      id_cidade: "2405",
      id_estado: "11",
      nm_cidade: "Veredinha",
      nm_cidade_uf: "Veredinha - MG"
  },
  {
      id_cidade: "2406",
      id_estado: "11",
      nm_cidade: "Veríssimo",
      nm_cidade_uf: "Veríssimo - MG"
  },
  {
      id_cidade: "2407",
      id_estado: "11",
      nm_cidade: "Vermelho Novo",
      nm_cidade_uf: "Vermelho Novo - MG"
  },
  {
      id_cidade: "2408",
      id_estado: "11",
      nm_cidade: "Vespasiano",
      nm_cidade_uf: "Vespasiano - MG"
  },
  {
      id_cidade: "2409",
      id_estado: "11",
      nm_cidade: "Viçosa",
      nm_cidade_uf: "Viçosa - MG"
  },
  {
      id_cidade: "2410",
      id_estado: "11",
      nm_cidade: "Vieiras",
      nm_cidade_uf: "Vieiras - MG"
  },
  {
      id_cidade: "2411",
      id_estado: "11",
      nm_cidade: "Virgem da Lapa",
      nm_cidade_uf: "Virgem da Lapa - MG"
  },
  {
      id_cidade: "2412",
      id_estado: "11",
      nm_cidade: "Virgínia",
      nm_cidade_uf: "Virgínia - MG"
  },
  {
      id_cidade: "2413",
      id_estado: "11",
      nm_cidade: "Virginópolis",
      nm_cidade_uf: "Virginópolis - MG"
  },
  {
      id_cidade: "2414",
      id_estado: "11",
      nm_cidade: "Virgolândia",
      nm_cidade_uf: "Virgolândia - MG"
  },
  {
      id_cidade: "2415",
      id_estado: "11",
      nm_cidade: "Visconde do Rio Branco",
      nm_cidade_uf: "Visconde do Rio Branco - MG"
  },
  {
      id_cidade: "2416",
      id_estado: "11",
      nm_cidade: "Volta Grande",
      nm_cidade_uf: "Volta Grande - MG"
  },
  {
      id_cidade: "2417",
      id_estado: "11",
      nm_cidade: "Wenceslau Braz",
      nm_cidade_uf: "Wenceslau Braz - MG"
  },
  {
      id_cidade: "2418",
      id_estado: "14",
      nm_cidade: "Abaetetuba",
      nm_cidade_uf: "Abaetetuba - PA"
  },
  {
      id_cidade: "2419",
      id_estado: "14",
      nm_cidade: "Abel Figueiredo",
      nm_cidade_uf: "Abel Figueiredo - PA"
  },
  {
      id_cidade: "2420",
      id_estado: "14",
      nm_cidade: "Acará",
      nm_cidade_uf: "Acará - PA"
  },
  {
      id_cidade: "2421",
      id_estado: "14",
      nm_cidade: "Afuá",
      nm_cidade_uf: "Afuá - PA"
  },
  {
      id_cidade: "2422",
      id_estado: "14",
      nm_cidade: "Água Azul do Norte",
      nm_cidade_uf: "Água Azul do Norte - PA"
  },
  {
      id_cidade: "2423",
      id_estado: "14",
      nm_cidade: "Alenquer",
      nm_cidade_uf: "Alenquer - PA"
  },
  {
      id_cidade: "2424",
      id_estado: "14",
      nm_cidade: "Almeirim",
      nm_cidade_uf: "Almeirim - PA"
  },
  {
      id_cidade: "2425",
      id_estado: "14",
      nm_cidade: "Altamira",
      nm_cidade_uf: "Altamira - PA"
  },
  {
      id_cidade: "2426",
      id_estado: "14",
      nm_cidade: "Anajás",
      nm_cidade_uf: "Anajás - PA"
  },
  {
      id_cidade: "2427",
      id_estado: "14",
      nm_cidade: "Ananindeua",
      nm_cidade_uf: "Ananindeua - PA"
  },
  {
      id_cidade: "2428",
      id_estado: "14",
      nm_cidade: "Anapu",
      nm_cidade_uf: "Anapu - PA"
  },
  {
      id_cidade: "2429",
      id_estado: "14",
      nm_cidade: "Augusto Corrêa",
      nm_cidade_uf: "Augusto Corrêa - PA"
  },
  {
      id_cidade: "2430",
      id_estado: "14",
      nm_cidade: "Aurora do Pará",
      nm_cidade_uf: "Aurora do Pará - PA"
  },
  {
      id_cidade: "2431",
      id_estado: "14",
      nm_cidade: "Aveiro",
      nm_cidade_uf: "Aveiro - PA"
  },
  {
      id_cidade: "2432",
      id_estado: "14",
      nm_cidade: "Bagre",
      nm_cidade_uf: "Bagre - PA"
  },
  {
      id_cidade: "2433",
      id_estado: "14",
      nm_cidade: "Baião",
      nm_cidade_uf: "Baião - PA"
  },
  {
      id_cidade: "2434",
      id_estado: "14",
      nm_cidade: "Bannach",
      nm_cidade_uf: "Bannach - PA"
  },
  {
      id_cidade: "2435",
      id_estado: "14",
      nm_cidade: "Barcarena",
      nm_cidade_uf: "Barcarena - PA"
  },
  {
      id_cidade: "2436",
      id_estado: "14",
      nm_cidade: "Belém",
      nm_cidade_uf: "Belém - PA"
  },
  {
      id_cidade: "2437",
      id_estado: "14",
      nm_cidade: "Belterra",
      nm_cidade_uf: "Belterra - PA"
  },
  {
      id_cidade: "2438",
      id_estado: "14",
      nm_cidade: "Benevides",
      nm_cidade_uf: "Benevides - PA"
  },
  {
      id_cidade: "2439",
      id_estado: "14",
      nm_cidade: "Bom Jesus do Tocantins",
      nm_cidade_uf: "Bom Jesus do Tocantins - PA"
  },
  {
      id_cidade: "2440",
      id_estado: "14",
      nm_cidade: "Bonito",
      nm_cidade_uf: "Bonito - PA"
  },
  {
      id_cidade: "2441",
      id_estado: "14",
      nm_cidade: "Bragança",
      nm_cidade_uf: "Bragança - PA"
  },
  {
      id_cidade: "2442",
      id_estado: "14",
      nm_cidade: "Brasil Novo",
      nm_cidade_uf: "Brasil Novo - PA"
  },
  {
      id_cidade: "2443",
      id_estado: "14",
      nm_cidade: "Brejo Grande do Araguaia",
      nm_cidade_uf: "Brejo Grande do Araguaia - PA"
  },
  {
      id_cidade: "2444",
      id_estado: "14",
      nm_cidade: "Breu Branco",
      nm_cidade_uf: "Breu Branco - PA"
  },
  {
      id_cidade: "2445",
      id_estado: "14",
      nm_cidade: "Breves",
      nm_cidade_uf: "Breves - PA"
  },
  {
      id_cidade: "2446",
      id_estado: "14",
      nm_cidade: "Bujaru",
      nm_cidade_uf: "Bujaru - PA"
  },
  {
      id_cidade: "2447",
      id_estado: "14",
      nm_cidade: "Cachoeira do Arari",
      nm_cidade_uf: "Cachoeira do Arari - PA"
  },
  {
      id_cidade: "2448",
      id_estado: "14",
      nm_cidade: "Cachoeira do Piriá",
      nm_cidade_uf: "Cachoeira do Piriá - PA"
  },
  {
      id_cidade: "2449",
      id_estado: "14",
      nm_cidade: "Cametá",
      nm_cidade_uf: "Cametá - PA"
  },
  {
      id_cidade: "2450",
      id_estado: "14",
      nm_cidade: "Canaã dos Carajás",
      nm_cidade_uf: "Canaã dos Carajás - PA"
  },
  {
      id_cidade: "2451",
      id_estado: "14",
      nm_cidade: "Capanema",
      nm_cidade_uf: "Capanema - PA"
  },
  {
      id_cidade: "2452",
      id_estado: "14",
      nm_cidade: "Capitão Poço",
      nm_cidade_uf: "Capitão Poço - PA"
  },
  {
      id_cidade: "2453",
      id_estado: "14",
      nm_cidade: "Castanhal",
      nm_cidade_uf: "Castanhal - PA"
  },
  {
      id_cidade: "2454",
      id_estado: "14",
      nm_cidade: "Chaves",
      nm_cidade_uf: "Chaves - PA"
  },
  {
      id_cidade: "2455",
      id_estado: "14",
      nm_cidade: "Colares",
      nm_cidade_uf: "Colares - PA"
  },
  {
      id_cidade: "2456",
      id_estado: "14",
      nm_cidade: "Conceição do Araguaia",
      nm_cidade_uf: "Conceição do Araguaia - PA"
  },
  {
      id_cidade: "2457",
      id_estado: "14",
      nm_cidade: "Concórdia do Pará",
      nm_cidade_uf: "Concórdia do Pará - PA"
  },
  {
      id_cidade: "2458",
      id_estado: "14",
      nm_cidade: "Cumaru do Norte",
      nm_cidade_uf: "Cumaru do Norte - PA"
  },
  {
      id_cidade: "2459",
      id_estado: "14",
      nm_cidade: "Curionópolis",
      nm_cidade_uf: "Curionópolis - PA"
  },
  {
      id_cidade: "2460",
      id_estado: "14",
      nm_cidade: "Curralinho",
      nm_cidade_uf: "Curralinho - PA"
  },
  {
      id_cidade: "2461",
      id_estado: "14",
      nm_cidade: "Curuá",
      nm_cidade_uf: "Curuá - PA"
  },
  {
      id_cidade: "2462",
      id_estado: "14",
      nm_cidade: "Curuçá",
      nm_cidade_uf: "Curuçá - PA"
  },
  {
      id_cidade: "2463",
      id_estado: "14",
      nm_cidade: "Dom Eliseu",
      nm_cidade_uf: "Dom Eliseu - PA"
  },
  {
      id_cidade: "2464",
      id_estado: "14",
      nm_cidade: "Eldorado dos Carajás",
      nm_cidade_uf: "Eldorado dos Carajás - PA"
  },
  {
      id_cidade: "2465",
      id_estado: "14",
      nm_cidade: "Faro",
      nm_cidade_uf: "Faro - PA"
  },
  {
      id_cidade: "2466",
      id_estado: "14",
      nm_cidade: "Floresta do Araguaia",
      nm_cidade_uf: "Floresta do Araguaia - PA"
  },
  {
      id_cidade: "2467",
      id_estado: "14",
      nm_cidade: "Garrafão do Norte",
      nm_cidade_uf: "Garrafão do Norte - PA"
  },
  {
      id_cidade: "2468",
      id_estado: "14",
      nm_cidade: "Goianésia do Pará",
      nm_cidade_uf: "Goianésia do Pará - PA"
  },
  {
      id_cidade: "2469",
      id_estado: "14",
      nm_cidade: "Gurupá",
      nm_cidade_uf: "Gurupá - PA"
  },
  {
      id_cidade: "2470",
      id_estado: "14",
      nm_cidade: "Igarapé-Açu",
      nm_cidade_uf: "Igarapé-Açu - PA"
  },
  {
      id_cidade: "2471",
      id_estado: "14",
      nm_cidade: "Igarapé-Miri",
      nm_cidade_uf: "Igarapé-Miri - PA"
  },
  {
      id_cidade: "2472",
      id_estado: "14",
      nm_cidade: "Inhangapi",
      nm_cidade_uf: "Inhangapi - PA"
  },
  {
      id_cidade: "2473",
      id_estado: "14",
      nm_cidade: "Ipixuna do Pará",
      nm_cidade_uf: "Ipixuna do Pará - PA"
  },
  {
      id_cidade: "2474",
      id_estado: "14",
      nm_cidade: "Irituia",
      nm_cidade_uf: "Irituia - PA"
  },
  {
      id_cidade: "2475",
      id_estado: "14",
      nm_cidade: "Itaituba",
      nm_cidade_uf: "Itaituba - PA"
  },
  {
      id_cidade: "2476",
      id_estado: "14",
      nm_cidade: "Itupiranga",
      nm_cidade_uf: "Itupiranga - PA"
  },
  {
      id_cidade: "2477",
      id_estado: "14",
      nm_cidade: "Jacareacanga",
      nm_cidade_uf: "Jacareacanga - PA"
  },
  {
      id_cidade: "2478",
      id_estado: "14",
      nm_cidade: "Jacundá",
      nm_cidade_uf: "Jacundá - PA"
  },
  {
      id_cidade: "2479",
      id_estado: "14",
      nm_cidade: "Juruti",
      nm_cidade_uf: "Juruti - PA"
  },
  {
      id_cidade: "2480",
      id_estado: "14",
      nm_cidade: "Limoeiro do Ajuru",
      nm_cidade_uf: "Limoeiro do Ajuru - PA"
  },
  {
      id_cidade: "2481",
      id_estado: "14",
      nm_cidade: "Mãe do Rio",
      nm_cidade_uf: "Mãe do Rio - PA"
  },
  {
      id_cidade: "2482",
      id_estado: "14",
      nm_cidade: "Magalhães Barata",
      nm_cidade_uf: "Magalhães Barata - PA"
  },
  {
      id_cidade: "2483",
      id_estado: "14",
      nm_cidade: "Marabá",
      nm_cidade_uf: "Marabá - PA"
  },
  {
      id_cidade: "2484",
      id_estado: "14",
      nm_cidade: "Maracanã",
      nm_cidade_uf: "Maracanã - PA"
  },
  {
      id_cidade: "2485",
      id_estado: "14",
      nm_cidade: "Marapanim",
      nm_cidade_uf: "Marapanim - PA"
  },
  {
      id_cidade: "2486",
      id_estado: "14",
      nm_cidade: "Marituba",
      nm_cidade_uf: "Marituba - PA"
  },
  {
      id_cidade: "2487",
      id_estado: "14",
      nm_cidade: "Medicilândia",
      nm_cidade_uf: "Medicilândia - PA"
  },
  {
      id_cidade: "2488",
      id_estado: "14",
      nm_cidade: "Melgaço",
      nm_cidade_uf: "Melgaço - PA"
  },
  {
      id_cidade: "2489",
      id_estado: "14",
      nm_cidade: "Mocajuba",
      nm_cidade_uf: "Mocajuba - PA"
  },
  {
      id_cidade: "2490",
      id_estado: "14",
      nm_cidade: "Moju",
      nm_cidade_uf: "Moju - PA"
  },
  {
      id_cidade: "2491",
      id_estado: "14",
      nm_cidade: "Monte Alegre",
      nm_cidade_uf: "Monte Alegre - PA"
  },
  {
      id_cidade: "2492",
      id_estado: "14",
      nm_cidade: "Muaná",
      nm_cidade_uf: "Muaná - PA"
  },
  {
      id_cidade: "2493",
      id_estado: "14",
      nm_cidade: "Nova Esperança do Piriá",
      nm_cidade_uf: "Nova Esperança do Piriá - PA"
  },
  {
      id_cidade: "2494",
      id_estado: "14",
      nm_cidade: "Nova Ipixuna",
      nm_cidade_uf: "Nova Ipixuna - PA"
  },
  {
      id_cidade: "2495",
      id_estado: "14",
      nm_cidade: "Nova Timboteua",
      nm_cidade_uf: "Nova Timboteua - PA"
  },
  {
      id_cidade: "2496",
      id_estado: "14",
      nm_cidade: "Novo Progresso",
      nm_cidade_uf: "Novo Progresso - PA"
  },
  {
      id_cidade: "2497",
      id_estado: "14",
      nm_cidade: "Novo Repartimento",
      nm_cidade_uf: "Novo Repartimento - PA"
  },
  {
      id_cidade: "2498",
      id_estado: "14",
      nm_cidade: "Óbidos",
      nm_cidade_uf: "Óbidos - PA"
  },
  {
      id_cidade: "2499",
      id_estado: "14",
      nm_cidade: "Oeiras do Pará",
      nm_cidade_uf: "Oeiras do Pará - PA"
  },
  {
      id_cidade: "2500",
      id_estado: "14",
      nm_cidade: "Oriximiná",
      nm_cidade_uf: "Oriximiná - PA"
  },
  {
      id_cidade: "2501",
      id_estado: "14",
      nm_cidade: "Ourém",
      nm_cidade_uf: "Ourém - PA"
  },
  {
      id_cidade: "2502",
      id_estado: "14",
      nm_cidade: "Ourilândia do Norte",
      nm_cidade_uf: "Ourilândia do Norte - PA"
  },
  {
      id_cidade: "2503",
      id_estado: "14",
      nm_cidade: "Pacajá",
      nm_cidade_uf: "Pacajá - PA"
  },
  {
      id_cidade: "2504",
      id_estado: "14",
      nm_cidade: "Palestina do Pará",
      nm_cidade_uf: "Palestina do Pará - PA"
  },
  {
      id_cidade: "2505",
      id_estado: "14",
      nm_cidade: "Paragominas",
      nm_cidade_uf: "Paragominas - PA"
  },
  {
      id_cidade: "2506",
      id_estado: "14",
      nm_cidade: "Parauapebas",
      nm_cidade_uf: "Parauapebas - PA"
  },
  {
      id_cidade: "2507",
      id_estado: "14",
      nm_cidade: "Pau d`Arco",
      nm_cidade_uf: "Pau d`Arco - PA"
  },
  {
      id_cidade: "2508",
      id_estado: "14",
      nm_cidade: "Peixe-Boi",
      nm_cidade_uf: "Peixe-Boi - PA"
  },
  {
      id_cidade: "2509",
      id_estado: "14",
      nm_cidade: "Piçarra",
      nm_cidade_uf: "Piçarra - PA"
  },
  {
      id_cidade: "2510",
      id_estado: "14",
      nm_cidade: "Placas",
      nm_cidade_uf: "Placas - PA"
  },
  {
      id_cidade: "2511",
      id_estado: "14",
      nm_cidade: "Ponta de Pedras",
      nm_cidade_uf: "Ponta de Pedras - PA"
  },
  {
      id_cidade: "2512",
      id_estado: "14",
      nm_cidade: "Portel",
      nm_cidade_uf: "Portel - PA"
  },
  {
      id_cidade: "2513",
      id_estado: "14",
      nm_cidade: "Porto de Moz",
      nm_cidade_uf: "Porto de Moz - PA"
  },
  {
      id_cidade: "2514",
      id_estado: "14",
      nm_cidade: "Prainha",
      nm_cidade_uf: "Prainha - PA"
  },
  {
      id_cidade: "2515",
      id_estado: "14",
      nm_cidade: "Primavera",
      nm_cidade_uf: "Primavera - PA"
  },
  {
      id_cidade: "2516",
      id_estado: "14",
      nm_cidade: "Quatipuru",
      nm_cidade_uf: "Quatipuru - PA"
  },
  {
      id_cidade: "2517",
      id_estado: "14",
      nm_cidade: "Redenção",
      nm_cidade_uf: "Redenção - PA"
  },
  {
      id_cidade: "2518",
      id_estado: "14",
      nm_cidade: "Rio Maria",
      nm_cidade_uf: "Rio Maria - PA"
  },
  {
      id_cidade: "2519",
      id_estado: "14",
      nm_cidade: "Rondon do Pará",
      nm_cidade_uf: "Rondon do Pará - PA"
  },
  {
      id_cidade: "2520",
      id_estado: "14",
      nm_cidade: "Rurópolis",
      nm_cidade_uf: "Rurópolis - PA"
  },
  {
      id_cidade: "2521",
      id_estado: "14",
      nm_cidade: "Salinópolis",
      nm_cidade_uf: "Salinópolis - PA"
  },
  {
      id_cidade: "2522",
      id_estado: "14",
      nm_cidade: "Salvaterra",
      nm_cidade_uf: "Salvaterra - PA"
  },
  {
      id_cidade: "2523",
      id_estado: "14",
      nm_cidade: "Santa Bárbara do Pará",
      nm_cidade_uf: "Santa Bárbara do Pará - PA"
  },
  {
      id_cidade: "2524",
      id_estado: "14",
      nm_cidade: "Santa Cruz do Arari",
      nm_cidade_uf: "Santa Cruz do Arari - PA"
  },
  {
      id_cidade: "2525",
      id_estado: "14",
      nm_cidade: "Santa Isabel do Pará",
      nm_cidade_uf: "Santa Isabel do Pará - PA"
  },
  {
      id_cidade: "2526",
      id_estado: "14",
      nm_cidade: "Santa Luzia do Pará",
      nm_cidade_uf: "Santa Luzia do Pará - PA"
  },
  {
      id_cidade: "2527",
      id_estado: "14",
      nm_cidade: "Santa Maria das Barreiras",
      nm_cidade_uf: "Santa Maria das Barreiras - PA"
  },
  {
      id_cidade: "2528",
      id_estado: "14",
      nm_cidade: "Santa Maria do Pará",
      nm_cidade_uf: "Santa Maria do Pará - PA"
  },
  {
      id_cidade: "2529",
      id_estado: "14",
      nm_cidade: "Santana do Araguaia",
      nm_cidade_uf: "Santana do Araguaia - PA"
  },
  {
      id_cidade: "2530",
      id_estado: "14",
      nm_cidade: "Santarém",
      nm_cidade_uf: "Santarém - PA"
  },
  {
      id_cidade: "2531",
      id_estado: "14",
      nm_cidade: "Santarém Novo",
      nm_cidade_uf: "Santarém Novo - PA"
  },
  {
      id_cidade: "2532",
      id_estado: "14",
      nm_cidade: "Santo Antônio do Tauá",
      nm_cidade_uf: "Santo Antônio do Tauá - PA"
  },
  {
      id_cidade: "2533",
      id_estado: "14",
      nm_cidade: "São Caetano de Odivelas",
      nm_cidade_uf: "São Caetano de Odivelas - PA"
  },
  {
      id_cidade: "2534",
      id_estado: "14",
      nm_cidade: "São Domingos do Araguaia",
      nm_cidade_uf: "São Domingos do Araguaia - PA"
  },
  {
      id_cidade: "2535",
      id_estado: "14",
      nm_cidade: "São Domingos do Capim",
      nm_cidade_uf: "São Domingos do Capim - PA"
  },
  {
      id_cidade: "2536",
      id_estado: "14",
      nm_cidade: "São Félix do Xingu",
      nm_cidade_uf: "São Félix do Xingu - PA"
  },
  {
      id_cidade: "2537",
      id_estado: "14",
      nm_cidade: "São Francisco do Pará",
      nm_cidade_uf: "São Francisco do Pará - PA"
  },
  {
      id_cidade: "2538",
      id_estado: "14",
      nm_cidade: "São Geraldo do Araguaia",
      nm_cidade_uf: "São Geraldo do Araguaia - PA"
  },
  {
      id_cidade: "2539",
      id_estado: "14",
      nm_cidade: "São João da Ponta",
      nm_cidade_uf: "São João da Ponta - PA"
  },
  {
      id_cidade: "2540",
      id_estado: "14",
      nm_cidade: "São João de Pirabas",
      nm_cidade_uf: "São João de Pirabas - PA"
  },
  {
      id_cidade: "2541",
      id_estado: "14",
      nm_cidade: "São João do Araguaia",
      nm_cidade_uf: "São João do Araguaia - PA"
  },
  {
      id_cidade: "2542",
      id_estado: "14",
      nm_cidade: "São Miguel do Guamá",
      nm_cidade_uf: "São Miguel do Guamá - PA"
  },
  {
      id_cidade: "2543",
      id_estado: "14",
      nm_cidade: "São Sebastião da Boa Vista",
      nm_cidade_uf: "São Sebastião da Boa Vista - PA"
  },
  {
      id_cidade: "2544",
      id_estado: "14",
      nm_cidade: "Sapucaia",
      nm_cidade_uf: "Sapucaia - PA"
  },
  {
      id_cidade: "2545",
      id_estado: "14",
      nm_cidade: "Senador José Porfírio",
      nm_cidade_uf: "Senador José Porfírio - PA"
  },
  {
      id_cidade: "2546",
      id_estado: "14",
      nm_cidade: "Soure",
      nm_cidade_uf: "Soure - PA"
  },
  {
      id_cidade: "2547",
      id_estado: "14",
      nm_cidade: "Tailândia",
      nm_cidade_uf: "Tailândia - PA"
  },
  {
      id_cidade: "2548",
      id_estado: "14",
      nm_cidade: "Terra Alta",
      nm_cidade_uf: "Terra Alta - PA"
  },
  {
      id_cidade: "2549",
      id_estado: "14",
      nm_cidade: "Terra Santa",
      nm_cidade_uf: "Terra Santa - PA"
  },
  {
      id_cidade: "2550",
      id_estado: "14",
      nm_cidade: "Tomé-Açu",
      nm_cidade_uf: "Tomé-Açu - PA"
  },
  {
      id_cidade: "2551",
      id_estado: "14",
      nm_cidade: "Tracuateua",
      nm_cidade_uf: "Tracuateua - PA"
  },
  {
      id_cidade: "2552",
      id_estado: "14",
      nm_cidade: "Trairão",
      nm_cidade_uf: "Trairão - PA"
  },
  {
      id_cidade: "2553",
      id_estado: "14",
      nm_cidade: "Tucumã",
      nm_cidade_uf: "Tucumã - PA"
  },
  {
      id_cidade: "2554",
      id_estado: "14",
      nm_cidade: "Tucuruí",
      nm_cidade_uf: "Tucuruí - PA"
  },
  {
      id_cidade: "2555",
      id_estado: "14",
      nm_cidade: "Ulianópolis",
      nm_cidade_uf: "Ulianópolis - PA"
  },
  {
      id_cidade: "2556",
      id_estado: "14",
      nm_cidade: "Uruará",
      nm_cidade_uf: "Uruará - PA"
  },
  {
      id_cidade: "2557",
      id_estado: "14",
      nm_cidade: "Vigia",
      nm_cidade_uf: "Vigia - PA"
  },
  {
      id_cidade: "2558",
      id_estado: "14",
      nm_cidade: "Viseu",
      nm_cidade_uf: "Viseu - PA"
  },
  {
      id_cidade: "2559",
      id_estado: "14",
      nm_cidade: "Vitória do Xingu",
      nm_cidade_uf: "Vitória do Xingu - PA"
  },
  {
      id_cidade: "2560",
      id_estado: "14",
      nm_cidade: "Xinguara",
      nm_cidade_uf: "Xinguara - PA"
  },
  {
      id_cidade: "2561",
      id_estado: "15",
      nm_cidade: "Água Branca",
      nm_cidade_uf: "Água Branca - PB"
  },
  {
      id_cidade: "2562",
      id_estado: "15",
      nm_cidade: "Aguiar",
      nm_cidade_uf: "Aguiar - PB"
  },
  {
      id_cidade: "2563",
      id_estado: "15",
      nm_cidade: "Alagoa Grande",
      nm_cidade_uf: "Alagoa Grande - PB"
  },
  {
      id_cidade: "2564",
      id_estado: "15",
      nm_cidade: "Alagoa Nova",
      nm_cidade_uf: "Alagoa Nova - PB"
  },
  {
      id_cidade: "2565",
      id_estado: "15",
      nm_cidade: "Alagoinha",
      nm_cidade_uf: "Alagoinha - PB"
  },
  {
      id_cidade: "2566",
      id_estado: "15",
      nm_cidade: "Alcantil",
      nm_cidade_uf: "Alcantil - PB"
  },
  {
      id_cidade: "2567",
      id_estado: "15",
      nm_cidade: "Algodão de Jandaíra",
      nm_cidade_uf: "Algodão de Jandaíra - PB"
  },
  {
      id_cidade: "2568",
      id_estado: "15",
      nm_cidade: "Alhandra",
      nm_cidade_uf: "Alhandra - PB"
  },
  {
      id_cidade: "2569",
      id_estado: "15",
      nm_cidade: "Amparo",
      nm_cidade_uf: "Amparo - PB"
  },
  {
      id_cidade: "2570",
      id_estado: "15",
      nm_cidade: "Aparecida",
      nm_cidade_uf: "Aparecida - PB"
  },
  {
      id_cidade: "2571",
      id_estado: "15",
      nm_cidade: "Araçagi",
      nm_cidade_uf: "Araçagi - PB"
  },
  {
      id_cidade: "2572",
      id_estado: "15",
      nm_cidade: "Arara",
      nm_cidade_uf: "Arara - PB"
  },
  {
      id_cidade: "2573",
      id_estado: "15",
      nm_cidade: "Araruna",
      nm_cidade_uf: "Araruna - PB"
  },
  {
      id_cidade: "2574",
      id_estado: "15",
      nm_cidade: "Areia",
      nm_cidade_uf: "Areia - PB"
  },
  {
      id_cidade: "2575",
      id_estado: "15",
      nm_cidade: "Areia de Baraúnas",
      nm_cidade_uf: "Areia de Baraúnas - PB"
  },
  {
      id_cidade: "2576",
      id_estado: "15",
      nm_cidade: "Areial",
      nm_cidade_uf: "Areial - PB"
  },
  {
      id_cidade: "2577",
      id_estado: "15",
      nm_cidade: "Aroeiras",
      nm_cidade_uf: "Aroeiras - PB"
  },
  {
      id_cidade: "2578",
      id_estado: "15",
      nm_cidade: "Assunção",
      nm_cidade_uf: "Assunção - PB"
  },
  {
      id_cidade: "2579",
      id_estado: "15",
      nm_cidade: "Baía da Traição",
      nm_cidade_uf: "Baía da Traição - PB"
  },
  {
      id_cidade: "2580",
      id_estado: "15",
      nm_cidade: "Bananeiras",
      nm_cidade_uf: "Bananeiras - PB"
  },
  {
      id_cidade: "2581",
      id_estado: "15",
      nm_cidade: "Baraúna",
      nm_cidade_uf: "Baraúna - PB"
  },
  {
      id_cidade: "2582",
      id_estado: "15",
      nm_cidade: "Barra de Santa Rosa",
      nm_cidade_uf: "Barra de Santa Rosa - PB"
  },
  {
      id_cidade: "2583",
      id_estado: "15",
      nm_cidade: "Barra de Santana",
      nm_cidade_uf: "Barra de Santana - PB"
  },
  {
      id_cidade: "2584",
      id_estado: "15",
      nm_cidade: "Barra de São Miguel",
      nm_cidade_uf: "Barra de São Miguel - PB"
  },
  {
      id_cidade: "2585",
      id_estado: "15",
      nm_cidade: "Bayeux",
      nm_cidade_uf: "Bayeux - PB"
  },
  {
      id_cidade: "2586",
      id_estado: "15",
      nm_cidade: "Belém",
      nm_cidade_uf: "Belém - PB"
  },
  {
      id_cidade: "2587",
      id_estado: "15",
      nm_cidade: "Belém do Brejo do Cruz",
      nm_cidade_uf: "Belém do Brejo do Cruz - PB"
  },
  {
      id_cidade: "2588",
      id_estado: "15",
      nm_cidade: "Bernardino Batista",
      nm_cidade_uf: "Bernardino Batista - PB"
  },
  {
      id_cidade: "2589",
      id_estado: "15",
      nm_cidade: "Boa Ventura",
      nm_cidade_uf: "Boa Ventura - PB"
  },
  {
      id_cidade: "2590",
      id_estado: "15",
      nm_cidade: "Boa Vista",
      nm_cidade_uf: "Boa Vista - PB"
  },
  {
      id_cidade: "2591",
      id_estado: "15",
      nm_cidade: "Bom Jesus",
      nm_cidade_uf: "Bom Jesus - PB"
  },
  {
      id_cidade: "2592",
      id_estado: "15",
      nm_cidade: "Bom Sucesso",
      nm_cidade_uf: "Bom Sucesso - PB"
  },
  {
      id_cidade: "2593",
      id_estado: "15",
      nm_cidade: "Bonito de Santa Fé",
      nm_cidade_uf: "Bonito de Santa Fé - PB"
  },
  {
      id_cidade: "2594",
      id_estado: "15",
      nm_cidade: "Boqueirão",
      nm_cidade_uf: "Boqueirão - PB"
  },
  {
      id_cidade: "2595",
      id_estado: "15",
      nm_cidade: "Borborema",
      nm_cidade_uf: "Borborema - PB"
  },
  {
      id_cidade: "2596",
      id_estado: "15",
      nm_cidade: "Brejo do Cruz",
      nm_cidade_uf: "Brejo do Cruz - PB"
  },
  {
      id_cidade: "2597",
      id_estado: "15",
      nm_cidade: "Brejo dos Santos",
      nm_cidade_uf: "Brejo dos Santos - PB"
  },
  {
      id_cidade: "2598",
      id_estado: "15",
      nm_cidade: "Caaporã",
      nm_cidade_uf: "Caaporã - PB"
  },
  {
      id_cidade: "2599",
      id_estado: "15",
      nm_cidade: "Cabaceiras",
      nm_cidade_uf: "Cabaceiras - PB"
  },
  {
      id_cidade: "2600",
      id_estado: "15",
      nm_cidade: "Cabedelo",
      nm_cidade_uf: "Cabedelo - PB"
  },
  {
      id_cidade: "2601",
      id_estado: "15",
      nm_cidade: "Cachoeira dos Índios",
      nm_cidade_uf: "Cachoeira dos Índios - PB"
  },
  {
      id_cidade: "2602",
      id_estado: "15",
      nm_cidade: "Cacimba de Areia",
      nm_cidade_uf: "Cacimba de Areia - PB"
  },
  {
      id_cidade: "2603",
      id_estado: "15",
      nm_cidade: "Cacimba de Dentro",
      nm_cidade_uf: "Cacimba de Dentro - PB"
  },
  {
      id_cidade: "2604",
      id_estado: "15",
      nm_cidade: "Cacimbas",
      nm_cidade_uf: "Cacimbas - PB"
  },
  {
      id_cidade: "2605",
      id_estado: "15",
      nm_cidade: "Caiçara",
      nm_cidade_uf: "Caiçara - PB"
  },
  {
      id_cidade: "2606",
      id_estado: "15",
      nm_cidade: "Cajazeiras",
      nm_cidade_uf: "Cajazeiras - PB"
  },
  {
      id_cidade: "2607",
      id_estado: "15",
      nm_cidade: "Cajazeirinhas",
      nm_cidade_uf: "Cajazeirinhas - PB"
  },
  {
      id_cidade: "2608",
      id_estado: "15",
      nm_cidade: "Caldas Brandão",
      nm_cidade_uf: "Caldas Brandão - PB"
  },
  {
      id_cidade: "2609",
      id_estado: "15",
      nm_cidade: "Camalaú",
      nm_cidade_uf: "Camalaú - PB"
  },
  {
      id_cidade: "2610",
      id_estado: "15",
      nm_cidade: "Campina Grande",
      nm_cidade_uf: "Campina Grande - PB"
  },
  {
      id_cidade: "2611",
      id_estado: "15",
      nm_cidade: "Campo de Santana",
      nm_cidade_uf: "Campo de Santana - PB"
  },
  {
      id_cidade: "2612",
      id_estado: "15",
      nm_cidade: "Capim",
      nm_cidade_uf: "Capim - PB"
  },
  {
      id_cidade: "2613",
      id_estado: "15",
      nm_cidade: "Caraúbas",
      nm_cidade_uf: "Caraúbas - PB"
  },
  {
      id_cidade: "2614",
      id_estado: "15",
      nm_cidade: "Carrapateira",
      nm_cidade_uf: "Carrapateira - PB"
  },
  {
      id_cidade: "2615",
      id_estado: "15",
      nm_cidade: "Casserengue",
      nm_cidade_uf: "Casserengue - PB"
  },
  {
      id_cidade: "2616",
      id_estado: "15",
      nm_cidade: "Catingueira",
      nm_cidade_uf: "Catingueira - PB"
  },
  {
      id_cidade: "2617",
      id_estado: "15",
      nm_cidade: "Catolé do Rocha",
      nm_cidade_uf: "Catolé do Rocha - PB"
  },
  {
      id_cidade: "2618",
      id_estado: "15",
      nm_cidade: "Caturité",
      nm_cidade_uf: "Caturité - PB"
  },
  {
      id_cidade: "2619",
      id_estado: "15",
      nm_cidade: "Conceição",
      nm_cidade_uf: "Conceição - PB"
  },
  {
      id_cidade: "2620",
      id_estado: "15",
      nm_cidade: "Condado",
      nm_cidade_uf: "Condado - PB"
  },
  {
      id_cidade: "2621",
      id_estado: "15",
      nm_cidade: "Conde",
      nm_cidade_uf: "Conde - PB"
  },
  {
      id_cidade: "2622",
      id_estado: "15",
      nm_cidade: "Congo",
      nm_cidade_uf: "Congo - PB"
  },
  {
      id_cidade: "2623",
      id_estado: "15",
      nm_cidade: "Coremas",
      nm_cidade_uf: "Coremas - PB"
  },
  {
      id_cidade: "2624",
      id_estado: "15",
      nm_cidade: "Coxixola",
      nm_cidade_uf: "Coxixola - PB"
  },
  {
      id_cidade: "2625",
      id_estado: "15",
      nm_cidade: "Cruz do Espírito Santo",
      nm_cidade_uf: "Cruz do Espírito Santo - PB"
  },
  {
      id_cidade: "2626",
      id_estado: "15",
      nm_cidade: "Cubati",
      nm_cidade_uf: "Cubati - PB"
  },
  {
      id_cidade: "2627",
      id_estado: "15",
      nm_cidade: "Cuité",
      nm_cidade_uf: "Cuité - PB"
  },
  {
      id_cidade: "2628",
      id_estado: "15",
      nm_cidade: "Cuité de Mamanguape",
      nm_cidade_uf: "Cuité de Mamanguape - PB"
  },
  {
      id_cidade: "2629",
      id_estado: "15",
      nm_cidade: "Cuitegi",
      nm_cidade_uf: "Cuitegi - PB"
  },
  {
      id_cidade: "2630",
      id_estado: "15",
      nm_cidade: "Curral de Cima",
      nm_cidade_uf: "Curral de Cima - PB"
  },
  {
      id_cidade: "2631",
      id_estado: "15",
      nm_cidade: "Curral Velho",
      nm_cidade_uf: "Curral Velho - PB"
  },
  {
      id_cidade: "2632",
      id_estado: "15",
      nm_cidade: "Damião",
      nm_cidade_uf: "Damião - PB"
  },
  {
      id_cidade: "2633",
      id_estado: "15",
      nm_cidade: "Desterro",
      nm_cidade_uf: "Desterro - PB"
  },
  {
      id_cidade: "2634",
      id_estado: "15",
      nm_cidade: "Diamante",
      nm_cidade_uf: "Diamante - PB"
  },
  {
      id_cidade: "2635",
      id_estado: "15",
      nm_cidade: "Dona Inês",
      nm_cidade_uf: "Dona Inês - PB"
  },
  {
      id_cidade: "2636",
      id_estado: "15",
      nm_cidade: "Duas Estradas",
      nm_cidade_uf: "Duas Estradas - PB"
  },
  {
      id_cidade: "2637",
      id_estado: "15",
      nm_cidade: "Emas",
      nm_cidade_uf: "Emas - PB"
  },
  {
      id_cidade: "2638",
      id_estado: "15",
      nm_cidade: "Esperança",
      nm_cidade_uf: "Esperança - PB"
  },
  {
      id_cidade: "2639",
      id_estado: "15",
      nm_cidade: "Fagundes",
      nm_cidade_uf: "Fagundes - PB"
  },
  {
      id_cidade: "2640",
      id_estado: "15",
      nm_cidade: "Frei Martinho",
      nm_cidade_uf: "Frei Martinho - PB"
  },
  {
      id_cidade: "2641",
      id_estado: "15",
      nm_cidade: "Gado Bravo",
      nm_cidade_uf: "Gado Bravo - PB"
  },
  {
      id_cidade: "2642",
      id_estado: "15",
      nm_cidade: "Guarabira",
      nm_cidade_uf: "Guarabira - PB"
  },
  {
      id_cidade: "2643",
      id_estado: "15",
      nm_cidade: "Gurinhém",
      nm_cidade_uf: "Gurinhém - PB"
  },
  {
      id_cidade: "2644",
      id_estado: "15",
      nm_cidade: "Gurjão",
      nm_cidade_uf: "Gurjão - PB"
  },
  {
      id_cidade: "2645",
      id_estado: "15",
      nm_cidade: "Ibiara",
      nm_cidade_uf: "Ibiara - PB"
  },
  {
      id_cidade: "2646",
      id_estado: "15",
      nm_cidade: "Igaracy",
      nm_cidade_uf: "Igaracy - PB"
  },
  {
      id_cidade: "2647",
      id_estado: "15",
      nm_cidade: "Imaculada",
      nm_cidade_uf: "Imaculada - PB"
  },
  {
      id_cidade: "2648",
      id_estado: "15",
      nm_cidade: "Ingá",
      nm_cidade_uf: "Ingá - PB"
  },
  {
      id_cidade: "2649",
      id_estado: "15",
      nm_cidade: "Itabaiana",
      nm_cidade_uf: "Itabaiana - PB"
  },
  {
      id_cidade: "2650",
      id_estado: "15",
      nm_cidade: "Itaporanga",
      nm_cidade_uf: "Itaporanga - PB"
  },
  {
      id_cidade: "2651",
      id_estado: "15",
      nm_cidade: "Itapororoca",
      nm_cidade_uf: "Itapororoca - PB"
  },
  {
      id_cidade: "2652",
      id_estado: "15",
      nm_cidade: "Itatuba",
      nm_cidade_uf: "Itatuba - PB"
  },
  {
      id_cidade: "2653",
      id_estado: "15",
      nm_cidade: "Jacaraú",
      nm_cidade_uf: "Jacaraú - PB"
  },
  {
      id_cidade: "2654",
      id_estado: "15",
      nm_cidade: "Jericó",
      nm_cidade_uf: "Jericó - PB"
  },
  {
      id_cidade: "2655",
      id_estado: "15",
      nm_cidade: "João Pessoa",
      nm_cidade_uf: "João Pessoa - PB"
  },
  {
      id_cidade: "2656",
      id_estado: "15",
      nm_cidade: "Juarez Távora",
      nm_cidade_uf: "Juarez Távora - PB"
  },
  {
      id_cidade: "2657",
      id_estado: "15",
      nm_cidade: "Juazeirinho",
      nm_cidade_uf: "Juazeirinho - PB"
  },
  {
      id_cidade: "2658",
      id_estado: "15",
      nm_cidade: "Junco do Seridó",
      nm_cidade_uf: "Junco do Seridó - PB"
  },
  {
      id_cidade: "2659",
      id_estado: "15",
      nm_cidade: "Juripiranga",
      nm_cidade_uf: "Juripiranga - PB"
  },
  {
      id_cidade: "2660",
      id_estado: "15",
      nm_cidade: "Juru",
      nm_cidade_uf: "Juru - PB"
  },
  {
      id_cidade: "2661",
      id_estado: "15",
      nm_cidade: "Lagoa",
      nm_cidade_uf: "Lagoa - PB"
  },
  {
      id_cidade: "2662",
      id_estado: "15",
      nm_cidade: "Lagoa de Dentro",
      nm_cidade_uf: "Lagoa de Dentro - PB"
  },
  {
      id_cidade: "2663",
      id_estado: "15",
      nm_cidade: "Lagoa Seca",
      nm_cidade_uf: "Lagoa Seca - PB"
  },
  {
      id_cidade: "2664",
      id_estado: "15",
      nm_cidade: "Lastro",
      nm_cidade_uf: "Lastro - PB"
  },
  {
      id_cidade: "2665",
      id_estado: "15",
      nm_cidade: "Livramento",
      nm_cidade_uf: "Livramento - PB"
  },
  {
      id_cidade: "2666",
      id_estado: "15",
      nm_cidade: "Logradouro",
      nm_cidade_uf: "Logradouro - PB"
  },
  {
      id_cidade: "2667",
      id_estado: "15",
      nm_cidade: "Lucena",
      nm_cidade_uf: "Lucena - PB"
  },
  {
      id_cidade: "2668",
      id_estado: "15",
      nm_cidade: "Mãe d`Água",
      nm_cidade_uf: "Mãe d`Água - PB"
  },
  {
      id_cidade: "2669",
      id_estado: "15",
      nm_cidade: "Malta",
      nm_cidade_uf: "Malta - PB"
  },
  {
      id_cidade: "2670",
      id_estado: "15",
      nm_cidade: "Mamanguape",
      nm_cidade_uf: "Mamanguape - PB"
  },
  {
      id_cidade: "2671",
      id_estado: "15",
      nm_cidade: "Manaíra",
      nm_cidade_uf: "Manaíra - PB"
  },
  {
      id_cidade: "2672",
      id_estado: "15",
      nm_cidade: "Marcação",
      nm_cidade_uf: "Marcação - PB"
  },
  {
      id_cidade: "2673",
      id_estado: "15",
      nm_cidade: "Mari",
      nm_cidade_uf: "Mari - PB"
  },
  {
      id_cidade: "2674",
      id_estado: "15",
      nm_cidade: "Marizópolis",
      nm_cidade_uf: "Marizópolis - PB"
  },
  {
      id_cidade: "2675",
      id_estado: "15",
      nm_cidade: "Massaranduba",
      nm_cidade_uf: "Massaranduba - PB"
  },
  {
      id_cidade: "2676",
      id_estado: "15",
      nm_cidade: "Mataraca",
      nm_cidade_uf: "Mataraca - PB"
  },
  {
      id_cidade: "2677",
      id_estado: "15",
      nm_cidade: "Matinhas",
      nm_cidade_uf: "Matinhas - PB"
  },
  {
      id_cidade: "2678",
      id_estado: "15",
      nm_cidade: "Mato Grosso",
      nm_cidade_uf: "Mato Grosso - PB"
  },
  {
      id_cidade: "2679",
      id_estado: "15",
      nm_cidade: "Maturéia",
      nm_cidade_uf: "Maturéia - PB"
  },
  {
      id_cidade: "2680",
      id_estado: "15",
      nm_cidade: "Mogeiro",
      nm_cidade_uf: "Mogeiro - PB"
  },
  {
      id_cidade: "2681",
      id_estado: "15",
      nm_cidade: "Montadas",
      nm_cidade_uf: "Montadas - PB"
  },
  {
      id_cidade: "2682",
      id_estado: "15",
      nm_cidade: "Monte Horebe",
      nm_cidade_uf: "Monte Horebe - PB"
  },
  {
      id_cidade: "2683",
      id_estado: "15",
      nm_cidade: "Monteiro",
      nm_cidade_uf: "Monteiro - PB"
  },
  {
      id_cidade: "2684",
      id_estado: "15",
      nm_cidade: "Mulungu",
      nm_cidade_uf: "Mulungu - PB"
  },
  {
      id_cidade: "2685",
      id_estado: "15",
      nm_cidade: "Natuba",
      nm_cidade_uf: "Natuba - PB"
  },
  {
      id_cidade: "2686",
      id_estado: "15",
      nm_cidade: "Nazarezinho",
      nm_cidade_uf: "Nazarezinho - PB"
  },
  {
      id_cidade: "2687",
      id_estado: "15",
      nm_cidade: "Nova Floresta",
      nm_cidade_uf: "Nova Floresta - PB"
  },
  {
      id_cidade: "2688",
      id_estado: "15",
      nm_cidade: "Nova Olinda",
      nm_cidade_uf: "Nova Olinda - PB"
  },
  {
      id_cidade: "2689",
      id_estado: "15",
      nm_cidade: "Nova Palmeira",
      nm_cidade_uf: "Nova Palmeira - PB"
  },
  {
      id_cidade: "2690",
      id_estado: "15",
      nm_cidade: "Olho d`Água",
      nm_cidade_uf: "Olho d`Água - PB"
  },
  {
      id_cidade: "2691",
      id_estado: "15",
      nm_cidade: "Olivedos",
      nm_cidade_uf: "Olivedos - PB"
  },
  {
      id_cidade: "2692",
      id_estado: "15",
      nm_cidade: "Ouro Velho",
      nm_cidade_uf: "Ouro Velho - PB"
  },
  {
      id_cidade: "2693",
      id_estado: "15",
      nm_cidade: "Parari",
      nm_cidade_uf: "Parari - PB"
  },
  {
      id_cidade: "2694",
      id_estado: "15",
      nm_cidade: "Passagem",
      nm_cidade_uf: "Passagem - PB"
  },
  {
      id_cidade: "2695",
      id_estado: "15",
      nm_cidade: "Patos",
      nm_cidade_uf: "Patos - PB"
  },
  {
      id_cidade: "2696",
      id_estado: "15",
      nm_cidade: "Paulista",
      nm_cidade_uf: "Paulista - PB"
  },
  {
      id_cidade: "2697",
      id_estado: "15",
      nm_cidade: "Pedra Branca",
      nm_cidade_uf: "Pedra Branca - PB"
  },
  {
      id_cidade: "2698",
      id_estado: "15",
      nm_cidade: "Pedra Lavrada",
      nm_cidade_uf: "Pedra Lavrada - PB"
  },
  {
      id_cidade: "2699",
      id_estado: "15",
      nm_cidade: "Pedras de Fogo",
      nm_cidade_uf: "Pedras de Fogo - PB"
  },
  {
      id_cidade: "2700",
      id_estado: "15",
      nm_cidade: "Pedro Régis",
      nm_cidade_uf: "Pedro Régis - PB"
  },
  {
      id_cidade: "2701",
      id_estado: "15",
      nm_cidade: "Piancó",
      nm_cidade_uf: "Piancó - PB"
  },
  {
      id_cidade: "2702",
      id_estado: "15",
      nm_cidade: "Picuí",
      nm_cidade_uf: "Picuí - PB"
  },
  {
      id_cidade: "2703",
      id_estado: "15",
      nm_cidade: "Pilar",
      nm_cidade_uf: "Pilar - PB"
  },
  {
      id_cidade: "2704",
      id_estado: "15",
      nm_cidade: "Pilões",
      nm_cidade_uf: "Pilões - PB"
  },
  {
      id_cidade: "2705",
      id_estado: "15",
      nm_cidade: "Pilõezinhos",
      nm_cidade_uf: "Pilõezinhos - PB"
  },
  {
      id_cidade: "2706",
      id_estado: "15",
      nm_cidade: "Pirpirituba",
      nm_cidade_uf: "Pirpirituba - PB"
  },
  {
      id_cidade: "2707",
      id_estado: "15",
      nm_cidade: "Pitimbu",
      nm_cidade_uf: "Pitimbu - PB"
  },
  {
      id_cidade: "2708",
      id_estado: "15",
      nm_cidade: "Pocinhos",
      nm_cidade_uf: "Pocinhos - PB"
  },
  {
      id_cidade: "2709",
      id_estado: "15",
      nm_cidade: "Poço Dantas",
      nm_cidade_uf: "Poço Dantas - PB"
  },
  {
      id_cidade: "2710",
      id_estado: "15",
      nm_cidade: "Poço de José de Moura",
      nm_cidade_uf: "Poço de José de Moura - PB"
  },
  {
      id_cidade: "2711",
      id_estado: "15",
      nm_cidade: "Pombal",
      nm_cidade_uf: "Pombal - PB"
  },
  {
      id_cidade: "2712",
      id_estado: "15",
      nm_cidade: "Prata",
      nm_cidade_uf: "Prata - PB"
  },
  {
      id_cidade: "2713",
      id_estado: "15",
      nm_cidade: "Princesa Isabel",
      nm_cidade_uf: "Princesa Isabel - PB"
  },
  {
      id_cidade: "2714",
      id_estado: "15",
      nm_cidade: "Puxinanã",
      nm_cidade_uf: "Puxinanã - PB"
  },
  {
      id_cidade: "2715",
      id_estado: "15",
      nm_cidade: "Queimadas",
      nm_cidade_uf: "Queimadas - PB"
  },
  {
      id_cidade: "2716",
      id_estado: "15",
      nm_cidade: "Quixabá",
      nm_cidade_uf: "Quixabá - PB"
  },
  {
      id_cidade: "2717",
      id_estado: "15",
      nm_cidade: "Remígio",
      nm_cidade_uf: "Remígio - PB"
  },
  {
      id_cidade: "2718",
      id_estado: "15",
      nm_cidade: "Riachão",
      nm_cidade_uf: "Riachão - PB"
  },
  {
      id_cidade: "2719",
      id_estado: "15",
      nm_cidade: "Riachão do Bacamarte",
      nm_cidade_uf: "Riachão do Bacamarte - PB"
  },
  {
      id_cidade: "2720",
      id_estado: "15",
      nm_cidade: "Riachão do Poço",
      nm_cidade_uf: "Riachão do Poço - PB"
  },
  {
      id_cidade: "2721",
      id_estado: "15",
      nm_cidade: "Riacho de Santo Antônio",
      nm_cidade_uf: "Riacho de Santo Antônio - PB"
  },
  {
      id_cidade: "2722",
      id_estado: "15",
      nm_cidade: "Riacho dos Cavalos",
      nm_cidade_uf: "Riacho dos Cavalos - PB"
  },
  {
      id_cidade: "2723",
      id_estado: "15",
      nm_cidade: "Rio Tinto",
      nm_cidade_uf: "Rio Tinto - PB"
  },
  {
      id_cidade: "2724",
      id_estado: "15",
      nm_cidade: "Salgadinho",
      nm_cidade_uf: "Salgadinho - PB"
  },
  {
      id_cidade: "2725",
      id_estado: "15",
      nm_cidade: "Salgado de São Félix",
      nm_cidade_uf: "Salgado de São Félix - PB"
  },
  {
      id_cidade: "2726",
      id_estado: "15",
      nm_cidade: "Santa Cecília",
      nm_cidade_uf: "Santa Cecília - PB"
  },
  {
      id_cidade: "2727",
      id_estado: "15",
      nm_cidade: "Santa Cruz",
      nm_cidade_uf: "Santa Cruz - PB"
  },
  {
      id_cidade: "2728",
      id_estado: "15",
      nm_cidade: "Santa Helena",
      nm_cidade_uf: "Santa Helena - PB"
  },
  {
      id_cidade: "2729",
      id_estado: "15",
      nm_cidade: "Santa Inês",
      nm_cidade_uf: "Santa Inês - PB"
  },
  {
      id_cidade: "2730",
      id_estado: "15",
      nm_cidade: "Santa Luzia",
      nm_cidade_uf: "Santa Luzia - PB"
  },
  {
      id_cidade: "2731",
      id_estado: "15",
      nm_cidade: "Santa Rita",
      nm_cidade_uf: "Santa Rita - PB"
  },
  {
      id_cidade: "2732",
      id_estado: "15",
      nm_cidade: "Santa Teresinha",
      nm_cidade_uf: "Santa Teresinha - PB"
  },
  {
      id_cidade: "2733",
      id_estado: "15",
      nm_cidade: "Santana de Mangueira",
      nm_cidade_uf: "Santana de Mangueira - PB"
  },
  {
      id_cidade: "2734",
      id_estado: "15",
      nm_cidade: "Santana dos Garrotes",
      nm_cidade_uf: "Santana dos Garrotes - PB"
  },
  {
      id_cidade: "2735",
      id_estado: "15",
      nm_cidade: "Santarém",
      nm_cidade_uf: "Santarém - PB"
  },
  {
      id_cidade: "2736",
      id_estado: "15",
      nm_cidade: "Santo André",
      nm_cidade_uf: "Santo André - PB"
  },
  {
      id_cidade: "2737",
      id_estado: "15",
      nm_cidade: "São Bentinho",
      nm_cidade_uf: "São Bentinho - PB"
  },
  {
      id_cidade: "2738",
      id_estado: "15",
      nm_cidade: "São Bento",
      nm_cidade_uf: "São Bento - PB"
  },
  {
      id_cidade: "2739",
      id_estado: "15",
      nm_cidade: "São Domingos de Pombal",
      nm_cidade_uf: "São Domingos de Pombal - PB"
  },
  {
      id_cidade: "2740",
      id_estado: "15",
      nm_cidade: "São Domingos do Cariri",
      nm_cidade_uf: "São Domingos do Cariri - PB"
  },
  {
      id_cidade: "2741",
      id_estado: "15",
      nm_cidade: "São Francisco",
      nm_cidade_uf: "São Francisco - PB"
  },
  {
      id_cidade: "2742",
      id_estado: "15",
      nm_cidade: "São João do Cariri",
      nm_cidade_uf: "São João do Cariri - PB"
  },
  {
      id_cidade: "2743",
      id_estado: "15",
      nm_cidade: "São João do Rio do Peixe",
      nm_cidade_uf: "São João do Rio do Peixe - PB"
  },
  {
      id_cidade: "2744",
      id_estado: "15",
      nm_cidade: "São João do Tigre",
      nm_cidade_uf: "São João do Tigre - PB"
  },
  {
      id_cidade: "2745",
      id_estado: "15",
      nm_cidade: "São José da Lagoa Tapada",
      nm_cidade_uf: "São José da Lagoa Tapada - PB"
  },
  {
      id_cidade: "2746",
      id_estado: "15",
      nm_cidade: "São José de Caiana",
      nm_cidade_uf: "São José de Caiana - PB"
  },
  {
      id_cidade: "2747",
      id_estado: "15",
      nm_cidade: "São José de Espinharas",
      nm_cidade_uf: "São José de Espinharas - PB"
  },
  {
      id_cidade: "2748",
      id_estado: "15",
      nm_cidade: "São José de Piranhas",
      nm_cidade_uf: "São José de Piranhas - PB"
  },
  {
      id_cidade: "2749",
      id_estado: "15",
      nm_cidade: "São José de Princesa",
      nm_cidade_uf: "São José de Princesa - PB"
  },
  {
      id_cidade: "2750",
      id_estado: "15",
      nm_cidade: "São José do Bonfim",
      nm_cidade_uf: "São José do Bonfim - PB"
  },
  {
      id_cidade: "2751",
      id_estado: "15",
      nm_cidade: "São José do Brejo do Cruz",
      nm_cidade_uf: "São José do Brejo do Cruz - PB"
  },
  {
      id_cidade: "2752",
      id_estado: "15",
      nm_cidade: "São José do Sabugi",
      nm_cidade_uf: "São José do Sabugi - PB"
  },
  {
      id_cidade: "2753",
      id_estado: "15",
      nm_cidade: "São José dos Cordeiros",
      nm_cidade_uf: "São José dos Cordeiros - PB"
  },
  {
      id_cidade: "2754",
      id_estado: "15",
      nm_cidade: "São José dos Ramos",
      nm_cidade_uf: "São José dos Ramos - PB"
  },
  {
      id_cidade: "2755",
      id_estado: "15",
      nm_cidade: "São Mamede",
      nm_cidade_uf: "São Mamede - PB"
  },
  {
      id_cidade: "2756",
      id_estado: "15",
      nm_cidade: "São Miguel de Taipu",
      nm_cidade_uf: "São Miguel de Taipu - PB"
  },
  {
      id_cidade: "2757",
      id_estado: "15",
      nm_cidade: "São Sebastião de Lagoa de Roça",
      nm_cidade_uf: "São Sebastião de Lagoa de Roça - PB"
  },
  {
      id_cidade: "2758",
      id_estado: "15",
      nm_cidade: "São Sebastião do Umbuzeiro",
      nm_cidade_uf: "São Sebastião do Umbuzeiro - PB"
  },
  {
      id_cidade: "2759",
      id_estado: "15",
      nm_cidade: "Sapé",
      nm_cidade_uf: "Sapé - PB"
  },
  {
      id_cidade: "2760",
      id_estado: "15",
      nm_cidade: "Seridó",
      nm_cidade_uf: "Seridó - PB"
  },
  {
      id_cidade: "2761",
      id_estado: "15",
      nm_cidade: "Serra Branca",
      nm_cidade_uf: "Serra Branca - PB"
  },
  {
      id_cidade: "2762",
      id_estado: "15",
      nm_cidade: "Serra da Raiz",
      nm_cidade_uf: "Serra da Raiz - PB"
  },
  {
      id_cidade: "2763",
      id_estado: "15",
      nm_cidade: "Serra Grande",
      nm_cidade_uf: "Serra Grande - PB"
  },
  {
      id_cidade: "2764",
      id_estado: "15",
      nm_cidade: "Serra Redonda",
      nm_cidade_uf: "Serra Redonda - PB"
  },
  {
      id_cidade: "2765",
      id_estado: "15",
      nm_cidade: "Serraria",
      nm_cidade_uf: "Serraria - PB"
  },
  {
      id_cidade: "2766",
      id_estado: "15",
      nm_cidade: "Sertãozinho",
      nm_cidade_uf: "Sertãozinho - PB"
  },
  {
      id_cidade: "2767",
      id_estado: "15",
      nm_cidade: "Sobrado",
      nm_cidade_uf: "Sobrado - PB"
  },
  {
      id_cidade: "2768",
      id_estado: "15",
      nm_cidade: "Solânea",
      nm_cidade_uf: "Solânea - PB"
  },
  {
      id_cidade: "2769",
      id_estado: "15",
      nm_cidade: "Soledade",
      nm_cidade_uf: "Soledade - PB"
  },
  {
      id_cidade: "2770",
      id_estado: "15",
      nm_cidade: "Sossêgo",
      nm_cidade_uf: "Sossêgo - PB"
  },
  {
      id_cidade: "2771",
      id_estado: "15",
      nm_cidade: "Sousa",
      nm_cidade_uf: "Sousa - PB"
  },
  {
      id_cidade: "2772",
      id_estado: "15",
      nm_cidade: "Sumé",
      nm_cidade_uf: "Sumé - PB"
  },
  {
      id_cidade: "2773",
      id_estado: "15",
      nm_cidade: "Taperoá",
      nm_cidade_uf: "Taperoá - PB"
  },
  {
      id_cidade: "2774",
      id_estado: "15",
      nm_cidade: "Tavares",
      nm_cidade_uf: "Tavares - PB"
  },
  {
      id_cidade: "2775",
      id_estado: "15",
      nm_cidade: "Teixeira",
      nm_cidade_uf: "Teixeira - PB"
  },
  {
      id_cidade: "2776",
      id_estado: "15",
      nm_cidade: "Tenório",
      nm_cidade_uf: "Tenório - PB"
  },
  {
      id_cidade: "2777",
      id_estado: "15",
      nm_cidade: "Triunfo",
      nm_cidade_uf: "Triunfo - PB"
  },
  {
      id_cidade: "2778",
      id_estado: "15",
      nm_cidade: "Uiraúna",
      nm_cidade_uf: "Uiraúna - PB"
  },
  {
      id_cidade: "2779",
      id_estado: "15",
      nm_cidade: "Umbuzeiro",
      nm_cidade_uf: "Umbuzeiro - PB"
  },
  {
      id_cidade: "2780",
      id_estado: "15",
      nm_cidade: "Várzea",
      nm_cidade_uf: "Várzea - PB"
  },
  {
      id_cidade: "2781",
      id_estado: "15",
      nm_cidade: "Vieirópolis",
      nm_cidade_uf: "Vieirópolis - PB"
  },
  {
      id_cidade: "2782",
      id_estado: "15",
      nm_cidade: "Vista Serrana",
      nm_cidade_uf: "Vista Serrana - PB"
  },
  {
      id_cidade: "2783",
      id_estado: "15",
      nm_cidade: "Zabelê",
      nm_cidade_uf: "Zabelê - PB"
  },
  {
      id_cidade: "2784",
      id_estado: "18",
      nm_cidade: "Abatiá",
      nm_cidade_uf: "Abatiá - PR"
  },
  {
      id_cidade: "2785",
      id_estado: "18",
      nm_cidade: "Adrianópolis",
      nm_cidade_uf: "Adrianópolis - PR"
  },
  {
      id_cidade: "2786",
      id_estado: "18",
      nm_cidade: "Agudos do Sul",
      nm_cidade_uf: "Agudos do Sul - PR"
  },
  {
      id_cidade: "2787",
      id_estado: "18",
      nm_cidade: "Almirante Tamandaré",
      nm_cidade_uf: "Almirante Tamandaré - PR"
  },
  {
      id_cidade: "2788",
      id_estado: "18",
      nm_cidade: "Altamira do Paraná",
      nm_cidade_uf: "Altamira do Paraná - PR"
  },
  {
      id_cidade: "2789",
      id_estado: "18",
      nm_cidade: "Alto Paraíso",
      nm_cidade_uf: "Alto Paraíso - PR"
  },
  {
      id_cidade: "2790",
      id_estado: "18",
      nm_cidade: "Alto Paraná",
      nm_cidade_uf: "Alto Paraná - PR"
  },
  {
      id_cidade: "2791",
      id_estado: "18",
      nm_cidade: "Alto Piquiri",
      nm_cidade_uf: "Alto Piquiri - PR"
  },
  {
      id_cidade: "2792",
      id_estado: "18",
      nm_cidade: "Altônia",
      nm_cidade_uf: "Altônia - PR"
  },
  {
      id_cidade: "2793",
      id_estado: "18",
      nm_cidade: "Alvorada do Sul",
      nm_cidade_uf: "Alvorada do Sul - PR"
  },
  {
      id_cidade: "2794",
      id_estado: "18",
      nm_cidade: "Amaporã",
      nm_cidade_uf: "Amaporã - PR"
  },
  {
      id_cidade: "2795",
      id_estado: "18",
      nm_cidade: "Ampére",
      nm_cidade_uf: "Ampére - PR"
  },
  {
      id_cidade: "2796",
      id_estado: "18",
      nm_cidade: "Anahy",
      nm_cidade_uf: "Anahy - PR"
  },
  {
      id_cidade: "2797",
      id_estado: "18",
      nm_cidade: "Andirá",
      nm_cidade_uf: "Andirá - PR"
  },
  {
      id_cidade: "2798",
      id_estado: "18",
      nm_cidade: "Ângulo",
      nm_cidade_uf: "Ângulo - PR"
  },
  {
      id_cidade: "2799",
      id_estado: "18",
      nm_cidade: "Antonina",
      nm_cidade_uf: "Antonina - PR"
  },
  {
      id_cidade: "2800",
      id_estado: "18",
      nm_cidade: "Antônio Olinto",
      nm_cidade_uf: "Antônio Olinto - PR"
  },
  {
      id_cidade: "2801",
      id_estado: "18",
      nm_cidade: "Apucarana",
      nm_cidade_uf: "Apucarana - PR"
  },
  {
      id_cidade: "2802",
      id_estado: "18",
      nm_cidade: "Arapongas",
      nm_cidade_uf: "Arapongas - PR"
  },
  {
      id_cidade: "2803",
      id_estado: "18",
      nm_cidade: "Arapoti",
      nm_cidade_uf: "Arapoti - PR"
  },
  {
      id_cidade: "2804",
      id_estado: "18",
      nm_cidade: "Arapuã",
      nm_cidade_uf: "Arapuã - PR"
  },
  {
      id_cidade: "2805",
      id_estado: "18",
      nm_cidade: "Araruna",
      nm_cidade_uf: "Araruna - PR"
  },
  {
      id_cidade: "2806",
      id_estado: "18",
      nm_cidade: "Araucária",
      nm_cidade_uf: "Araucária - PR"
  },
  {
      id_cidade: "2807",
      id_estado: "18",
      nm_cidade: "Ariranha do Ivaí",
      nm_cidade_uf: "Ariranha do Ivaí - PR"
  },
  {
      id_cidade: "2808",
      id_estado: "18",
      nm_cidade: "Assaí",
      nm_cidade_uf: "Assaí - PR"
  },
  {
      id_cidade: "2809",
      id_estado: "18",
      nm_cidade: "Assis Chateaubriand",
      nm_cidade_uf: "Assis Chateaubriand - PR"
  },
  {
      id_cidade: "2810",
      id_estado: "18",
      nm_cidade: "Astorga",
      nm_cidade_uf: "Astorga - PR"
  },
  {
      id_cidade: "2811",
      id_estado: "18",
      nm_cidade: "Atalaia",
      nm_cidade_uf: "Atalaia - PR"
  },
  {
      id_cidade: "2812",
      id_estado: "18",
      nm_cidade: "Balsa Nova",
      nm_cidade_uf: "Balsa Nova - PR"
  },
  {
      id_cidade: "2813",
      id_estado: "18",
      nm_cidade: "Bandeirantes",
      nm_cidade_uf: "Bandeirantes - PR"
  },
  {
      id_cidade: "2814",
      id_estado: "18",
      nm_cidade: "Barbosa Ferraz",
      nm_cidade_uf: "Barbosa Ferraz - PR"
  },
  {
      id_cidade: "2815",
      id_estado: "18",
      nm_cidade: "Barra do Jacaré",
      nm_cidade_uf: "Barra do Jacaré - PR"
  },
  {
      id_cidade: "2816",
      id_estado: "18",
      nm_cidade: "Barracão",
      nm_cidade_uf: "Barracão - PR"
  },
  {
      id_cidade: "2817",
      id_estado: "18",
      nm_cidade: "Bela Vista da Caroba",
      nm_cidade_uf: "Bela Vista da Caroba - PR"
  },
  {
      id_cidade: "2818",
      id_estado: "18",
      nm_cidade: "Bela Vista do Paraíso",
      nm_cidade_uf: "Bela Vista do Paraíso - PR"
  },
  {
      id_cidade: "2819",
      id_estado: "18",
      nm_cidade: "Bituruna",
      nm_cidade_uf: "Bituruna - PR"
  },
  {
      id_cidade: "2820",
      id_estado: "18",
      nm_cidade: "Boa Esperança",
      nm_cidade_uf: "Boa Esperança - PR"
  },
  {
      id_cidade: "2821",
      id_estado: "18",
      nm_cidade: "Boa Esperança do Iguaçu",
      nm_cidade_uf: "Boa Esperança do Iguaçu - PR"
  },
  {
      id_cidade: "2822",
      id_estado: "18",
      nm_cidade: "Boa Ventura de São Roque",
      nm_cidade_uf: "Boa Ventura de São Roque - PR"
  },
  {
      id_cidade: "2823",
      id_estado: "18",
      nm_cidade: "Boa Vista da Aparecida",
      nm_cidade_uf: "Boa Vista da Aparecida - PR"
  },
  {
      id_cidade: "2824",
      id_estado: "18",
      nm_cidade: "Bocaiúva do Sul",
      nm_cidade_uf: "Bocaiúva do Sul - PR"
  },
  {
      id_cidade: "2825",
      id_estado: "18",
      nm_cidade: "Bom Jesus do Sul",
      nm_cidade_uf: "Bom Jesus do Sul - PR"
  },
  {
      id_cidade: "2826",
      id_estado: "18",
      nm_cidade: "Bom Sucesso",
      nm_cidade_uf: "Bom Sucesso - PR"
  },
  {
      id_cidade: "2827",
      id_estado: "18",
      nm_cidade: "Bom Sucesso do Sul",
      nm_cidade_uf: "Bom Sucesso do Sul - PR"
  },
  {
      id_cidade: "2828",
      id_estado: "18",
      nm_cidade: "Borrazópolis",
      nm_cidade_uf: "Borrazópolis - PR"
  },
  {
      id_cidade: "2829",
      id_estado: "18",
      nm_cidade: "Braganey",
      nm_cidade_uf: "Braganey - PR"
  },
  {
      id_cidade: "2830",
      id_estado: "18",
      nm_cidade: "Brasilândia do Sul",
      nm_cidade_uf: "Brasilândia do Sul - PR"
  },
  {
      id_cidade: "2831",
      id_estado: "18",
      nm_cidade: "Cafeara",
      nm_cidade_uf: "Cafeara - PR"
  },
  {
      id_cidade: "2832",
      id_estado: "18",
      nm_cidade: "Cafelândia",
      nm_cidade_uf: "Cafelândia - PR"
  },
  {
      id_cidade: "2833",
      id_estado: "18",
      nm_cidade: "Cafezal do Sul",
      nm_cidade_uf: "Cafezal do Sul - PR"
  },
  {
      id_cidade: "2834",
      id_estado: "18",
      nm_cidade: "Califórnia",
      nm_cidade_uf: "Califórnia - PR"
  },
  {
      id_cidade: "2835",
      id_estado: "18",
      nm_cidade: "Cambará",
      nm_cidade_uf: "Cambará - PR"
  },
  {
      id_cidade: "2836",
      id_estado: "18",
      nm_cidade: "Cambé",
      nm_cidade_uf: "Cambé - PR"
  },
  {
      id_cidade: "2837",
      id_estado: "18",
      nm_cidade: "Cambira",
      nm_cidade_uf: "Cambira - PR"
  },
  {
      id_cidade: "2838",
      id_estado: "18",
      nm_cidade: "Campina da Lagoa",
      nm_cidade_uf: "Campina da Lagoa - PR"
  },
  {
      id_cidade: "2839",
      id_estado: "18",
      nm_cidade: "Campina do Simão",
      nm_cidade_uf: "Campina do Simão - PR"
  },
  {
      id_cidade: "2840",
      id_estado: "18",
      nm_cidade: "Campina Grande do Sul",
      nm_cidade_uf: "Campina Grande do Sul - PR"
  },
  {
      id_cidade: "2841",
      id_estado: "18",
      nm_cidade: "Campo Bonito",
      nm_cidade_uf: "Campo Bonito - PR"
  },
  {
      id_cidade: "2842",
      id_estado: "18",
      nm_cidade: "Campo do Tenente",
      nm_cidade_uf: "Campo do Tenente - PR"
  },
  {
      id_cidade: "2843",
      id_estado: "18",
      nm_cidade: "Campo Largo",
      nm_cidade_uf: "Campo Largo - PR"
  },
  {
      id_cidade: "2844",
      id_estado: "18",
      nm_cidade: "Campo Magro",
      nm_cidade_uf: "Campo Magro - PR"
  },
  {
      id_cidade: "2845",
      id_estado: "18",
      nm_cidade: "Campo Mourão",
      nm_cidade_uf: "Campo Mourão - PR"
  },
  {
      id_cidade: "2846",
      id_estado: "18",
      nm_cidade: "Cândido de Abreu",
      nm_cidade_uf: "Cândido de Abreu - PR"
  },
  {
      id_cidade: "2847",
      id_estado: "18",
      nm_cidade: "Candói",
      nm_cidade_uf: "Candói - PR"
  },
  {
      id_cidade: "2848",
      id_estado: "18",
      nm_cidade: "Cantagalo",
      nm_cidade_uf: "Cantagalo - PR"
  },
  {
      id_cidade: "2849",
      id_estado: "18",
      nm_cidade: "Capanema",
      nm_cidade_uf: "Capanema - PR"
  },
  {
      id_cidade: "2850",
      id_estado: "18",
      nm_cidade: "Capitão Leônidas Marques",
      nm_cidade_uf: "Capitão Leônidas Marques - PR"
  },
  {
      id_cidade: "2851",
      id_estado: "18",
      nm_cidade: "Carambeí",
      nm_cidade_uf: "Carambeí - PR"
  },
  {
      id_cidade: "2852",
      id_estado: "18",
      nm_cidade: "Carlópolis",
      nm_cidade_uf: "Carlópolis - PR"
  },
  {
      id_cidade: "2853",
      id_estado: "18",
      nm_cidade: "Cascavel",
      nm_cidade_uf: "Cascavel - PR"
  },
  {
      id_cidade: "2854",
      id_estado: "18",
      nm_cidade: "Castro",
      nm_cidade_uf: "Castro - PR"
  },
  {
      id_cidade: "2855",
      id_estado: "18",
      nm_cidade: "Catanduvas",
      nm_cidade_uf: "Catanduvas - PR"
  },
  {
      id_cidade: "2856",
      id_estado: "18",
      nm_cidade: "Centenário do Sul",
      nm_cidade_uf: "Centenário do Sul - PR"
  },
  {
      id_cidade: "2857",
      id_estado: "18",
      nm_cidade: "Cerro Azul",
      nm_cidade_uf: "Cerro Azul - PR"
  },
  {
      id_cidade: "2858",
      id_estado: "18",
      nm_cidade: "Céu Azul",
      nm_cidade_uf: "Céu Azul - PR"
  },
  {
      id_cidade: "2859",
      id_estado: "18",
      nm_cidade: "Chopinzinho",
      nm_cidade_uf: "Chopinzinho - PR"
  },
  {
      id_cidade: "2860",
      id_estado: "18",
      nm_cidade: "Cianorte",
      nm_cidade_uf: "Cianorte - PR"
  },
  {
      id_cidade: "2861",
      id_estado: "18",
      nm_cidade: "Cidade Gaúcha",
      nm_cidade_uf: "Cidade Gaúcha - PR"
  },
  {
      id_cidade: "2862",
      id_estado: "18",
      nm_cidade: "Clevelândia",
      nm_cidade_uf: "Clevelândia - PR"
  },
  {
      id_cidade: "2863",
      id_estado: "18",
      nm_cidade: "Colombo",
      nm_cidade_uf: "Colombo - PR"
  },
  {
      id_cidade: "2864",
      id_estado: "18",
      nm_cidade: "Colorado",
      nm_cidade_uf: "Colorado - PR"
  },
  {
      id_cidade: "2865",
      id_estado: "18",
      nm_cidade: "Congonhinhas",
      nm_cidade_uf: "Congonhinhas - PR"
  },
  {
      id_cidade: "2866",
      id_estado: "18",
      nm_cidade: "Conselheiro Mairinck",
      nm_cidade_uf: "Conselheiro Mairinck - PR"
  },
  {
      id_cidade: "2867",
      id_estado: "18",
      nm_cidade: "Contenda",
      nm_cidade_uf: "Contenda - PR"
  },
  {
      id_cidade: "2868",
      id_estado: "18",
      nm_cidade: "Corbélia",
      nm_cidade_uf: "Corbélia - PR"
  },
  {
      id_cidade: "2869",
      id_estado: "18",
      nm_cidade: "Cornélio Procópio",
      nm_cidade_uf: "Cornélio Procópio - PR"
  },
  {
      id_cidade: "2870",
      id_estado: "18",
      nm_cidade: "Coronel Domingos Soares",
      nm_cidade_uf: "Coronel Domingos Soares - PR"
  },
  {
      id_cidade: "2871",
      id_estado: "18",
      nm_cidade: "Coronel Vivida",
      nm_cidade_uf: "Coronel Vivida - PR"
  },
  {
      id_cidade: "2872",
      id_estado: "18",
      nm_cidade: "Corumbataí do Sul",
      nm_cidade_uf: "Corumbataí do Sul - PR"
  },
  {
      id_cidade: "2873",
      id_estado: "18",
      nm_cidade: "Cruz Machado",
      nm_cidade_uf: "Cruz Machado - PR"
  },
  {
      id_cidade: "2874",
      id_estado: "18",
      nm_cidade: "Cruzeiro do Iguaçu",
      nm_cidade_uf: "Cruzeiro do Iguaçu - PR"
  },
  {
      id_cidade: "2875",
      id_estado: "18",
      nm_cidade: "Cruzeiro do Oeste",
      nm_cidade_uf: "Cruzeiro do Oeste - PR"
  },
  {
      id_cidade: "2876",
      id_estado: "18",
      nm_cidade: "Cruzeiro do Sul",
      nm_cidade_uf: "Cruzeiro do Sul - PR"
  },
  {
      id_cidade: "2877",
      id_estado: "18",
      nm_cidade: "Cruzmaltina",
      nm_cidade_uf: "Cruzmaltina - PR"
  },
  {
      id_cidade: "2878",
      id_estado: "18",
      nm_cidade: "Curitiba",
      nm_cidade_uf: "Curitiba - PR"
  },
  {
      id_cidade: "2879",
      id_estado: "18",
      nm_cidade: "Curiúva",
      nm_cidade_uf: "Curiúva - PR"
  },
  {
      id_cidade: "2880",
      id_estado: "18",
      nm_cidade: "Diamante d`Oeste",
      nm_cidade_uf: "Diamante d`Oeste - PR"
  },
  {
      id_cidade: "2881",
      id_estado: "18",
      nm_cidade: "Diamante do Norte",
      nm_cidade_uf: "Diamante do Norte - PR"
  },
  {
      id_cidade: "2882",
      id_estado: "18",
      nm_cidade: "Diamante do Sul",
      nm_cidade_uf: "Diamante do Sul - PR"
  },
  {
      id_cidade: "2883",
      id_estado: "18",
      nm_cidade: "Dois Vizinhos",
      nm_cidade_uf: "Dois Vizinhos - PR"
  },
  {
      id_cidade: "2884",
      id_estado: "18",
      nm_cidade: "Douradina",
      nm_cidade_uf: "Douradina - PR"
  },
  {
      id_cidade: "2885",
      id_estado: "18",
      nm_cidade: "Doutor Camargo",
      nm_cidade_uf: "Doutor Camargo - PR"
  },
  {
      id_cidade: "2886",
      id_estado: "18",
      nm_cidade: "Doutor Ulysses",
      nm_cidade_uf: "Doutor Ulysses - PR"
  },
  {
      id_cidade: "2887",
      id_estado: "18",
      nm_cidade: "Enéas Marques",
      nm_cidade_uf: "Enéas Marques - PR"
  },
  {
      id_cidade: "2888",
      id_estado: "18",
      nm_cidade: "Engenheiro Beltrão",
      nm_cidade_uf: "Engenheiro Beltrão - PR"
  },
  {
      id_cidade: "2889",
      id_estado: "18",
      nm_cidade: "Entre Rios do Oeste",
      nm_cidade_uf: "Entre Rios do Oeste - PR"
  },
  {
      id_cidade: "2890",
      id_estado: "18",
      nm_cidade: "Esperança Nova",
      nm_cidade_uf: "Esperança Nova - PR"
  },
  {
      id_cidade: "2891",
      id_estado: "18",
      nm_cidade: "Espigão Alto do Iguaçu",
      nm_cidade_uf: "Espigão Alto do Iguaçu - PR"
  },
  {
      id_cidade: "2892",
      id_estado: "18",
      nm_cidade: "Farol",
      nm_cidade_uf: "Farol - PR"
  },
  {
      id_cidade: "2893",
      id_estado: "18",
      nm_cidade: "Faxinal",
      nm_cidade_uf: "Faxinal - PR"
  },
  {
      id_cidade: "2894",
      id_estado: "18",
      nm_cidade: "Fazenda Rio Grande",
      nm_cidade_uf: "Fazenda Rio Grande - PR"
  },
  {
      id_cidade: "2895",
      id_estado: "18",
      nm_cidade: "Fênix",
      nm_cidade_uf: "Fênix - PR"
  },
  {
      id_cidade: "2896",
      id_estado: "18",
      nm_cidade: "Fernandes Pinheiro",
      nm_cidade_uf: "Fernandes Pinheiro - PR"
  },
  {
      id_cidade: "2897",
      id_estado: "18",
      nm_cidade: "Figueira",
      nm_cidade_uf: "Figueira - PR"
  },
  {
      id_cidade: "2898",
      id_estado: "18",
      nm_cidade: "Flor da Serra do Sul",
      nm_cidade_uf: "Flor da Serra do Sul - PR"
  },
  {
      id_cidade: "2899",
      id_estado: "18",
      nm_cidade: "Floraí",
      nm_cidade_uf: "Floraí - PR"
  },
  {
      id_cidade: "2900",
      id_estado: "18",
      nm_cidade: "Floresta",
      nm_cidade_uf: "Floresta - PR"
  },
  {
      id_cidade: "2901",
      id_estado: "18",
      nm_cidade: "Florestópolis",
      nm_cidade_uf: "Florestópolis - PR"
  },
  {
      id_cidade: "2902",
      id_estado: "18",
      nm_cidade: "Flórida",
      nm_cidade_uf: "Flórida - PR"
  },
  {
      id_cidade: "2903",
      id_estado: "18",
      nm_cidade: "Formosa do Oeste",
      nm_cidade_uf: "Formosa do Oeste - PR"
  },
  {
      id_cidade: "2904",
      id_estado: "18",
      nm_cidade: "Foz do Iguaçu",
      nm_cidade_uf: "Foz do Iguaçu - PR"
  },
  {
      id_cidade: "2905",
      id_estado: "18",
      nm_cidade: "Foz do Jordão",
      nm_cidade_uf: "Foz do Jordão - PR"
  },
  {
      id_cidade: "2906",
      id_estado: "18",
      nm_cidade: "Francisco Alves",
      nm_cidade_uf: "Francisco Alves - PR"
  },
  {
      id_cidade: "2907",
      id_estado: "18",
      nm_cidade: "Francisco Beltrão",
      nm_cidade_uf: "Francisco Beltrão - PR"
  },
  {
      id_cidade: "2908",
      id_estado: "18",
      nm_cidade: "General Carneiro",
      nm_cidade_uf: "General Carneiro - PR"
  },
  {
      id_cidade: "2909",
      id_estado: "18",
      nm_cidade: "Godoy Moreira",
      nm_cidade_uf: "Godoy Moreira - PR"
  },
  {
      id_cidade: "2910",
      id_estado: "18",
      nm_cidade: "Goioerê",
      nm_cidade_uf: "Goioerê - PR"
  },
  {
      id_cidade: "2911",
      id_estado: "18",
      nm_cidade: "Goioxim",
      nm_cidade_uf: "Goioxim - PR"
  },
  {
      id_cidade: "2912",
      id_estado: "18",
      nm_cidade: "Grandes Rios",
      nm_cidade_uf: "Grandes Rios - PR"
  },
  {
      id_cidade: "2913",
      id_estado: "18",
      nm_cidade: "Guaíra",
      nm_cidade_uf: "Guaíra - PR"
  },
  {
      id_cidade: "2914",
      id_estado: "18",
      nm_cidade: "Guairaçá",
      nm_cidade_uf: "Guairaçá - PR"
  },
  {
      id_cidade: "2915",
      id_estado: "18",
      nm_cidade: "Guamiranga",
      nm_cidade_uf: "Guamiranga - PR"
  },
  {
      id_cidade: "2916",
      id_estado: "18",
      nm_cidade: "Guapirama",
      nm_cidade_uf: "Guapirama - PR"
  },
  {
      id_cidade: "2917",
      id_estado: "18",
      nm_cidade: "Guaporema",
      nm_cidade_uf: "Guaporema - PR"
  },
  {
      id_cidade: "2918",
      id_estado: "18",
      nm_cidade: "Guaraci",
      nm_cidade_uf: "Guaraci - PR"
  },
  {
      id_cidade: "2919",
      id_estado: "18",
      nm_cidade: "Guaraniaçu",
      nm_cidade_uf: "Guaraniaçu - PR"
  },
  {
      id_cidade: "2920",
      id_estado: "18",
      nm_cidade: "Guarapuava",
      nm_cidade_uf: "Guarapuava - PR"
  },
  {
      id_cidade: "2921",
      id_estado: "18",
      nm_cidade: "Guaraqueçaba",
      nm_cidade_uf: "Guaraqueçaba - PR"
  },
  {
      id_cidade: "2922",
      id_estado: "18",
      nm_cidade: "Guaratuba",
      nm_cidade_uf: "Guaratuba - PR"
  },
  {
      id_cidade: "2923",
      id_estado: "18",
      nm_cidade: "Honório Serpa",
      nm_cidade_uf: "Honório Serpa - PR"
  },
  {
      id_cidade: "2924",
      id_estado: "18",
      nm_cidade: "Ibaiti",
      nm_cidade_uf: "Ibaiti - PR"
  },
  {
      id_cidade: "2925",
      id_estado: "18",
      nm_cidade: "Ibema",
      nm_cidade_uf: "Ibema - PR"
  },
  {
      id_cidade: "2926",
      id_estado: "18",
      nm_cidade: "Ibiporã",
      nm_cidade_uf: "Ibiporã - PR"
  },
  {
      id_cidade: "2927",
      id_estado: "18",
      nm_cidade: "Icaraíma",
      nm_cidade_uf: "Icaraíma - PR"
  },
  {
      id_cidade: "2928",
      id_estado: "18",
      nm_cidade: "Iguaraçu",
      nm_cidade_uf: "Iguaraçu - PR"
  },
  {
      id_cidade: "2929",
      id_estado: "18",
      nm_cidade: "Iguatu",
      nm_cidade_uf: "Iguatu - PR"
  },
  {
      id_cidade: "2930",
      id_estado: "18",
      nm_cidade: "Imbaú",
      nm_cidade_uf: "Imbaú - PR"
  },
  {
      id_cidade: "2931",
      id_estado: "18",
      nm_cidade: "Imbituva",
      nm_cidade_uf: "Imbituva - PR"
  },
  {
      id_cidade: "2932",
      id_estado: "18",
      nm_cidade: "Inácio Martins",
      nm_cidade_uf: "Inácio Martins - PR"
  },
  {
      id_cidade: "2933",
      id_estado: "18",
      nm_cidade: "Inajá",
      nm_cidade_uf: "Inajá - PR"
  },
  {
      id_cidade: "2934",
      id_estado: "18",
      nm_cidade: "Indianópolis",
      nm_cidade_uf: "Indianópolis - PR"
  },
  {
      id_cidade: "2935",
      id_estado: "18",
      nm_cidade: "Ipiranga",
      nm_cidade_uf: "Ipiranga - PR"
  },
  {
      id_cidade: "2936",
      id_estado: "18",
      nm_cidade: "Iporã",
      nm_cidade_uf: "Iporã - PR"
  },
  {
      id_cidade: "2937",
      id_estado: "18",
      nm_cidade: "Iracema do Oeste",
      nm_cidade_uf: "Iracema do Oeste - PR"
  },
  {
      id_cidade: "2938",
      id_estado: "18",
      nm_cidade: "Irati",
      nm_cidade_uf: "Irati - PR"
  },
  {
      id_cidade: "2939",
      id_estado: "18",
      nm_cidade: "Iretama",
      nm_cidade_uf: "Iretama - PR"
  },
  {
      id_cidade: "2940",
      id_estado: "18",
      nm_cidade: "Itaguajé",
      nm_cidade_uf: "Itaguajé - PR"
  },
  {
      id_cidade: "2941",
      id_estado: "18",
      nm_cidade: "Itaipulândia",
      nm_cidade_uf: "Itaipulândia - PR"
  },
  {
      id_cidade: "2942",
      id_estado: "18",
      nm_cidade: "Itambaracá",
      nm_cidade_uf: "Itambaracá - PR"
  },
  {
      id_cidade: "2943",
      id_estado: "18",
      nm_cidade: "Itambé",
      nm_cidade_uf: "Itambé - PR"
  },
  {
      id_cidade: "2944",
      id_estado: "18",
      nm_cidade: "Itapejara d`Oeste",
      nm_cidade_uf: "Itapejara d`Oeste - PR"
  },
  {
      id_cidade: "2945",
      id_estado: "18",
      nm_cidade: "Itaperuçu",
      nm_cidade_uf: "Itaperuçu - PR"
  },
  {
      id_cidade: "2946",
      id_estado: "18",
      nm_cidade: "Itaúna do Sul",
      nm_cidade_uf: "Itaúna do Sul - PR"
  },
  {
      id_cidade: "2947",
      id_estado: "18",
      nm_cidade: "Ivaí",
      nm_cidade_uf: "Ivaí - PR"
  },
  {
      id_cidade: "2948",
      id_estado: "18",
      nm_cidade: "Ivaiporã",
      nm_cidade_uf: "Ivaiporã - PR"
  },
  {
      id_cidade: "2949",
      id_estado: "18",
      nm_cidade: "Ivaté",
      nm_cidade_uf: "Ivaté - PR"
  },
  {
      id_cidade: "2950",
      id_estado: "18",
      nm_cidade: "Ivatuba",
      nm_cidade_uf: "Ivatuba - PR"
  },
  {
      id_cidade: "2951",
      id_estado: "18",
      nm_cidade: "Jaboti",
      nm_cidade_uf: "Jaboti - PR"
  },
  {
      id_cidade: "2952",
      id_estado: "18",
      nm_cidade: "Jacarezinho",
      nm_cidade_uf: "Jacarezinho - PR"
  },
  {
      id_cidade: "2953",
      id_estado: "18",
      nm_cidade: "Jaguapitã",
      nm_cidade_uf: "Jaguapitã - PR"
  },
  {
      id_cidade: "2954",
      id_estado: "18",
      nm_cidade: "Jaguariaíva",
      nm_cidade_uf: "Jaguariaíva - PR"
  },
  {
      id_cidade: "2955",
      id_estado: "18",
      nm_cidade: "Jandaia do Sul",
      nm_cidade_uf: "Jandaia do Sul - PR"
  },
  {
      id_cidade: "2956",
      id_estado: "18",
      nm_cidade: "Janiópolis",
      nm_cidade_uf: "Janiópolis - PR"
  },
  {
      id_cidade: "2957",
      id_estado: "18",
      nm_cidade: "Japira",
      nm_cidade_uf: "Japira - PR"
  },
  {
      id_cidade: "2958",
      id_estado: "18",
      nm_cidade: "Japurá",
      nm_cidade_uf: "Japurá - PR"
  },
  {
      id_cidade: "2959",
      id_estado: "18",
      nm_cidade: "Jardim Alegre",
      nm_cidade_uf: "Jardim Alegre - PR"
  },
  {
      id_cidade: "2960",
      id_estado: "18",
      nm_cidade: "Jardim Olinda",
      nm_cidade_uf: "Jardim Olinda - PR"
  },
  {
      id_cidade: "2961",
      id_estado: "18",
      nm_cidade: "Jataizinho",
      nm_cidade_uf: "Jataizinho - PR"
  },
  {
      id_cidade: "2962",
      id_estado: "18",
      nm_cidade: "Jesuítas",
      nm_cidade_uf: "Jesuítas - PR"
  },
  {
      id_cidade: "2963",
      id_estado: "18",
      nm_cidade: "Joaquim Távora",
      nm_cidade_uf: "Joaquim Távora - PR"
  },
  {
      id_cidade: "2964",
      id_estado: "18",
      nm_cidade: "Jundiaí do Sul",
      nm_cidade_uf: "Jundiaí do Sul - PR"
  },
  {
      id_cidade: "2965",
      id_estado: "18",
      nm_cidade: "Juranda",
      nm_cidade_uf: "Juranda - PR"
  },
  {
      id_cidade: "2966",
      id_estado: "18",
      nm_cidade: "Jussara",
      nm_cidade_uf: "Jussara - PR"
  },
  {
      id_cidade: "2967",
      id_estado: "18",
      nm_cidade: "Kaloré",
      nm_cidade_uf: "Kaloré - PR"
  },
  {
      id_cidade: "2968",
      id_estado: "18",
      nm_cidade: "Lapa",
      nm_cidade_uf: "Lapa - PR"
  },
  {
      id_cidade: "2969",
      id_estado: "18",
      nm_cidade: "Laranjal",
      nm_cidade_uf: "Laranjal - PR"
  },
  {
      id_cidade: "2970",
      id_estado: "18",
      nm_cidade: "Laranjeiras do Sul",
      nm_cidade_uf: "Laranjeiras do Sul - PR"
  },
  {
      id_cidade: "2971",
      id_estado: "18",
      nm_cidade: "Leópolis",
      nm_cidade_uf: "Leópolis - PR"
  },
  {
      id_cidade: "2972",
      id_estado: "18",
      nm_cidade: "Lidianópolis",
      nm_cidade_uf: "Lidianópolis - PR"
  },
  {
      id_cidade: "2973",
      id_estado: "18",
      nm_cidade: "Lindoeste",
      nm_cidade_uf: "Lindoeste - PR"
  },
  {
      id_cidade: "2974",
      id_estado: "18",
      nm_cidade: "Loanda",
      nm_cidade_uf: "Loanda - PR"
  },
  {
      id_cidade: "2975",
      id_estado: "18",
      nm_cidade: "Lobato",
      nm_cidade_uf: "Lobato - PR"
  },
  {
      id_cidade: "2976",
      id_estado: "18",
      nm_cidade: "Londrina",
      nm_cidade_uf: "Londrina - PR"
  },
  {
      id_cidade: "2977",
      id_estado: "18",
      nm_cidade: "Luiziana",
      nm_cidade_uf: "Luiziana - PR"
  },
  {
      id_cidade: "2978",
      id_estado: "18",
      nm_cidade: "Lunardelli",
      nm_cidade_uf: "Lunardelli - PR"
  },
  {
      id_cidade: "2979",
      id_estado: "18",
      nm_cidade: "Lupionópolis",
      nm_cidade_uf: "Lupionópolis - PR"
  },
  {
      id_cidade: "2980",
      id_estado: "18",
      nm_cidade: "Mallet",
      nm_cidade_uf: "Mallet - PR"
  },
  {
      id_cidade: "2981",
      id_estado: "18",
      nm_cidade: "Mamborê",
      nm_cidade_uf: "Mamborê - PR"
  },
  {
      id_cidade: "2982",
      id_estado: "18",
      nm_cidade: "Mandaguaçu",
      nm_cidade_uf: "Mandaguaçu - PR"
  },
  {
      id_cidade: "2983",
      id_estado: "18",
      nm_cidade: "Mandaguari",
      nm_cidade_uf: "Mandaguari - PR"
  },
  {
      id_cidade: "2984",
      id_estado: "18",
      nm_cidade: "Mandirituba",
      nm_cidade_uf: "Mandirituba - PR"
  },
  {
      id_cidade: "2985",
      id_estado: "18",
      nm_cidade: "Manfrinópolis",
      nm_cidade_uf: "Manfrinópolis - PR"
  },
  {
      id_cidade: "2986",
      id_estado: "18",
      nm_cidade: "Mangueirinha",
      nm_cidade_uf: "Mangueirinha - PR"
  },
  {
      id_cidade: "2987",
      id_estado: "18",
      nm_cidade: "Manoel Ribas",
      nm_cidade_uf: "Manoel Ribas - PR"
  },
  {
      id_cidade: "2988",
      id_estado: "18",
      nm_cidade: "Marechal Cândido Rondon",
      nm_cidade_uf: "Marechal Cândido Rondon - PR"
  },
  {
      id_cidade: "2989",
      id_estado: "18",
      nm_cidade: "Maria Helena",
      nm_cidade_uf: "Maria Helena - PR"
  },
  {
      id_cidade: "2990",
      id_estado: "18",
      nm_cidade: "Marialva",
      nm_cidade_uf: "Marialva - PR"
  },
  {
      id_cidade: "2991",
      id_estado: "18",
      nm_cidade: "Marilândia do Sul",
      nm_cidade_uf: "Marilândia do Sul - PR"
  },
  {
      id_cidade: "2992",
      id_estado: "18",
      nm_cidade: "Marilena",
      nm_cidade_uf: "Marilena - PR"
  },
  {
      id_cidade: "2993",
      id_estado: "18",
      nm_cidade: "Mariluz",
      nm_cidade_uf: "Mariluz - PR"
  },
  {
      id_cidade: "2994",
      id_estado: "18",
      nm_cidade: "Maringá",
      nm_cidade_uf: "Maringá - PR"
  },
  {
      id_cidade: "2995",
      id_estado: "18",
      nm_cidade: "Mariópolis",
      nm_cidade_uf: "Mariópolis - PR"
  },
  {
      id_cidade: "2996",
      id_estado: "18",
      nm_cidade: "Maripá",
      nm_cidade_uf: "Maripá - PR"
  },
  {
      id_cidade: "2997",
      id_estado: "18",
      nm_cidade: "Marmeleiro",
      nm_cidade_uf: "Marmeleiro - PR"
  },
  {
      id_cidade: "2998",
      id_estado: "18",
      nm_cidade: "Marquinho",
      nm_cidade_uf: "Marquinho - PR"
  },
  {
      id_cidade: "2999",
      id_estado: "18",
      nm_cidade: "Marumbi",
      nm_cidade_uf: "Marumbi - PR"
  },
  {
      id_cidade: "3000",
      id_estado: "18",
      nm_cidade: "Matelândia",
      nm_cidade_uf: "Matelândia - PR"
  },
  {
      id_cidade: "3001",
      id_estado: "18",
      nm_cidade: "Matinhos",
      nm_cidade_uf: "Matinhos - PR"
  },
  {
      id_cidade: "3002",
      id_estado: "18",
      nm_cidade: "Mato Rico",
      nm_cidade_uf: "Mato Rico - PR"
  },
  {
      id_cidade: "3003",
      id_estado: "18",
      nm_cidade: "Mauá da Serra",
      nm_cidade_uf: "Mauá da Serra - PR"
  },
  {
      id_cidade: "3004",
      id_estado: "18",
      nm_cidade: "Medianeira",
      nm_cidade_uf: "Medianeira - PR"
  },
  {
      id_cidade: "3005",
      id_estado: "18",
      nm_cidade: "Mercedes",
      nm_cidade_uf: "Mercedes - PR"
  },
  {
      id_cidade: "3006",
      id_estado: "18",
      nm_cidade: "Mirador",
      nm_cidade_uf: "Mirador - PR"
  },
  {
      id_cidade: "3007",
      id_estado: "18",
      nm_cidade: "Miraselva",
      nm_cidade_uf: "Miraselva - PR"
  },
  {
      id_cidade: "3008",
      id_estado: "18",
      nm_cidade: "Missal",
      nm_cidade_uf: "Missal - PR"
  },
  {
      id_cidade: "3009",
      id_estado: "18",
      nm_cidade: "Moreira Sales",
      nm_cidade_uf: "Moreira Sales - PR"
  },
  {
      id_cidade: "3010",
      id_estado: "18",
      nm_cidade: "Morretes",
      nm_cidade_uf: "Morretes - PR"
  },
  {
      id_cidade: "3011",
      id_estado: "18",
      nm_cidade: "Munhoz de Melo",
      nm_cidade_uf: "Munhoz de Melo - PR"
  },
  {
      id_cidade: "3012",
      id_estado: "18",
      nm_cidade: "Nossa Senhora das Graças",
      nm_cidade_uf: "Nossa Senhora das Graças - PR"
  },
  {
      id_cidade: "3013",
      id_estado: "18",
      nm_cidade: "Nova Aliança do Ivaí",
      nm_cidade_uf: "Nova Aliança do Ivaí - PR"
  },
  {
      id_cidade: "3014",
      id_estado: "18",
      nm_cidade: "Nova América da Colina",
      nm_cidade_uf: "Nova América da Colina - PR"
  },
  {
      id_cidade: "3015",
      id_estado: "18",
      nm_cidade: "Nova Aurora",
      nm_cidade_uf: "Nova Aurora - PR"
  },
  {
      id_cidade: "3016",
      id_estado: "18",
      nm_cidade: "Nova Cantu",
      nm_cidade_uf: "Nova Cantu - PR"
  },
  {
      id_cidade: "3017",
      id_estado: "18",
      nm_cidade: "Nova Esperança",
      nm_cidade_uf: "Nova Esperança - PR"
  },
  {
      id_cidade: "3018",
      id_estado: "18",
      nm_cidade: "Nova Esperança do Sudoeste",
      nm_cidade_uf: "Nova Esperança do Sudoeste - PR"
  },
  {
      id_cidade: "3019",
      id_estado: "18",
      nm_cidade: "Nova Fátima",
      nm_cidade_uf: "Nova Fátima - PR"
  },
  {
      id_cidade: "3020",
      id_estado: "18",
      nm_cidade: "Nova Laranjeiras",
      nm_cidade_uf: "Nova Laranjeiras - PR"
  },
  {
      id_cidade: "3021",
      id_estado: "18",
      nm_cidade: "Nova Londrina",
      nm_cidade_uf: "Nova Londrina - PR"
  },
  {
      id_cidade: "3022",
      id_estado: "18",
      nm_cidade: "Nova Olímpia",
      nm_cidade_uf: "Nova Olímpia - PR"
  },
  {
      id_cidade: "3023",
      id_estado: "18",
      nm_cidade: "Nova Prata do Iguaçu",
      nm_cidade_uf: "Nova Prata do Iguaçu - PR"
  },
  {
      id_cidade: "3024",
      id_estado: "18",
      nm_cidade: "Nova Santa Bárbara",
      nm_cidade_uf: "Nova Santa Bárbara - PR"
  },
  {
      id_cidade: "3025",
      id_estado: "18",
      nm_cidade: "Nova Santa Rosa",
      nm_cidade_uf: "Nova Santa Rosa - PR"
  },
  {
      id_cidade: "3026",
      id_estado: "18",
      nm_cidade: "Nova Tebas",
      nm_cidade_uf: "Nova Tebas - PR"
  },
  {
      id_cidade: "3027",
      id_estado: "18",
      nm_cidade: "Novo Itacolomi",
      nm_cidade_uf: "Novo Itacolomi - PR"
  },
  {
      id_cidade: "3028",
      id_estado: "18",
      nm_cidade: "Ortigueira",
      nm_cidade_uf: "Ortigueira - PR"
  },
  {
      id_cidade: "3029",
      id_estado: "18",
      nm_cidade: "Ourizona",
      nm_cidade_uf: "Ourizona - PR"
  },
  {
      id_cidade: "3030",
      id_estado: "18",
      nm_cidade: "Ouro Verde do Oeste",
      nm_cidade_uf: "Ouro Verde do Oeste - PR"
  },
  {
      id_cidade: "3031",
      id_estado: "18",
      nm_cidade: "Paiçandu",
      nm_cidade_uf: "Paiçandu - PR"
  },
  {
      id_cidade: "3032",
      id_estado: "18",
      nm_cidade: "Palmas",
      nm_cidade_uf: "Palmas - PR"
  },
  {
      id_cidade: "3033",
      id_estado: "18",
      nm_cidade: "Palmeira",
      nm_cidade_uf: "Palmeira - PR"
  },
  {
      id_cidade: "3034",
      id_estado: "18",
      nm_cidade: "Palmital",
      nm_cidade_uf: "Palmital - PR"
  },
  {
      id_cidade: "3035",
      id_estado: "18",
      nm_cidade: "Palotina",
      nm_cidade_uf: "Palotina - PR"
  },
  {
      id_cidade: "3036",
      id_estado: "18",
      nm_cidade: "Paraíso do Norte",
      nm_cidade_uf: "Paraíso do Norte - PR"
  },
  {
      id_cidade: "3037",
      id_estado: "18",
      nm_cidade: "Paranacity",
      nm_cidade_uf: "Paranacity - PR"
  },
  {
      id_cidade: "3038",
      id_estado: "18",
      nm_cidade: "Paranaguá",
      nm_cidade_uf: "Paranaguá - PR"
  },
  {
      id_cidade: "3039",
      id_estado: "18",
      nm_cidade: "Paranapoema",
      nm_cidade_uf: "Paranapoema - PR"
  },
  {
      id_cidade: "3040",
      id_estado: "18",
      nm_cidade: "Paranavaí",
      nm_cidade_uf: "Paranavaí - PR"
  },
  {
      id_cidade: "3041",
      id_estado: "18",
      nm_cidade: "Pato Bragado",
      nm_cidade_uf: "Pato Bragado - PR"
  },
  {
      id_cidade: "3042",
      id_estado: "18",
      nm_cidade: "Pato Branco",
      nm_cidade_uf: "Pato Branco - PR"
  },
  {
      id_cidade: "3043",
      id_estado: "18",
      nm_cidade: "Paula Freitas",
      nm_cidade_uf: "Paula Freitas - PR"
  },
  {
      id_cidade: "3044",
      id_estado: "18",
      nm_cidade: "Paulo Frontin",
      nm_cidade_uf: "Paulo Frontin - PR"
  },
  {
      id_cidade: "3045",
      id_estado: "18",
      nm_cidade: "Peabiru",
      nm_cidade_uf: "Peabiru - PR"
  },
  {
      id_cidade: "3046",
      id_estado: "18",
      nm_cidade: "Perobal",
      nm_cidade_uf: "Perobal - PR"
  },
  {
      id_cidade: "3047",
      id_estado: "18",
      nm_cidade: "Pérola",
      nm_cidade_uf: "Pérola - PR"
  },
  {
      id_cidade: "3048",
      id_estado: "18",
      nm_cidade: "Pérola d`Oeste",
      nm_cidade_uf: "Pérola d`Oeste - PR"
  },
  {
      id_cidade: "3049",
      id_estado: "18",
      nm_cidade: "Piên",
      nm_cidade_uf: "Piên - PR"
  },
  {
      id_cidade: "3050",
      id_estado: "18",
      nm_cidade: "Pinhais",
      nm_cidade_uf: "Pinhais - PR"
  },
  {
      id_cidade: "3051",
      id_estado: "18",
      nm_cidade: "Pinhal de São Bento",
      nm_cidade_uf: "Pinhal de São Bento - PR"
  },
  {
      id_cidade: "3052",
      id_estado: "18",
      nm_cidade: "Pinhalão",
      nm_cidade_uf: "Pinhalão - PR"
  },
  {
      id_cidade: "3053",
      id_estado: "18",
      nm_cidade: "Pinhão",
      nm_cidade_uf: "Pinhão - PR"
  },
  {
      id_cidade: "3054",
      id_estado: "18",
      nm_cidade: "Piraí do Sul",
      nm_cidade_uf: "Piraí do Sul - PR"
  },
  {
      id_cidade: "3055",
      id_estado: "18",
      nm_cidade: "Piraquara",
      nm_cidade_uf: "Piraquara - PR"
  },
  {
      id_cidade: "3056",
      id_estado: "18",
      nm_cidade: "Pitanga",
      nm_cidade_uf: "Pitanga - PR"
  },
  {
      id_cidade: "3057",
      id_estado: "18",
      nm_cidade: "Pitangueiras",
      nm_cidade_uf: "Pitangueiras - PR"
  },
  {
      id_cidade: "3058",
      id_estado: "18",
      nm_cidade: "Planaltina do Paraná",
      nm_cidade_uf: "Planaltina do Paraná - PR"
  },
  {
      id_cidade: "3059",
      id_estado: "18",
      nm_cidade: "Planalto",
      nm_cidade_uf: "Planalto - PR"
  },
  {
      id_cidade: "3060",
      id_estado: "18",
      nm_cidade: "Ponta Grossa",
      nm_cidade_uf: "Ponta Grossa - PR"
  },
  {
      id_cidade: "3061",
      id_estado: "18",
      nm_cidade: "Pontal do Paraná",
      nm_cidade_uf: "Pontal do Paraná - PR"
  },
  {
      id_cidade: "3062",
      id_estado: "18",
      nm_cidade: "Porecatu",
      nm_cidade_uf: "Porecatu - PR"
  },
  {
      id_cidade: "3063",
      id_estado: "18",
      nm_cidade: "Porto Amazonas",
      nm_cidade_uf: "Porto Amazonas - PR"
  },
  {
      id_cidade: "3064",
      id_estado: "18",
      nm_cidade: "Porto Barreiro",
      nm_cidade_uf: "Porto Barreiro - PR"
  },
  {
      id_cidade: "3065",
      id_estado: "18",
      nm_cidade: "Porto Rico",
      nm_cidade_uf: "Porto Rico - PR"
  },
  {
      id_cidade: "3066",
      id_estado: "18",
      nm_cidade: "Porto Vitória",
      nm_cidade_uf: "Porto Vitória - PR"
  },
  {
      id_cidade: "3067",
      id_estado: "18",
      nm_cidade: "Prado Ferreira",
      nm_cidade_uf: "Prado Ferreira - PR"
  },
  {
      id_cidade: "3068",
      id_estado: "18",
      nm_cidade: "Pranchita",
      nm_cidade_uf: "Pranchita - PR"
  },
  {
      id_cidade: "3069",
      id_estado: "18",
      nm_cidade: "Presidente Castelo Branco",
      nm_cidade_uf: "Presidente Castelo Branco - PR"
  },
  {
      id_cidade: "3070",
      id_estado: "18",
      nm_cidade: "Primeiro de Maio",
      nm_cidade_uf: "Primeiro de Maio - PR"
  },
  {
      id_cidade: "3071",
      id_estado: "18",
      nm_cidade: "Prudentópolis",
      nm_cidade_uf: "Prudentópolis - PR"
  },
  {
      id_cidade: "3072",
      id_estado: "18",
      nm_cidade: "Quarto Centenário",
      nm_cidade_uf: "Quarto Centenário - PR"
  },
  {
      id_cidade: "3073",
      id_estado: "18",
      nm_cidade: "Quatiguá",
      nm_cidade_uf: "Quatiguá - PR"
  },
  {
      id_cidade: "3074",
      id_estado: "18",
      nm_cidade: "Quatro Barras",
      nm_cidade_uf: "Quatro Barras - PR"
  },
  {
      id_cidade: "3075",
      id_estado: "18",
      nm_cidade: "Quatro Pontes",
      nm_cidade_uf: "Quatro Pontes - PR"
  },
  {
      id_cidade: "3076",
      id_estado: "18",
      nm_cidade: "Quedas do Iguaçu",
      nm_cidade_uf: "Quedas do Iguaçu - PR"
  },
  {
      id_cidade: "3077",
      id_estado: "18",
      nm_cidade: "Querência do Norte",
      nm_cidade_uf: "Querência do Norte - PR"
  },
  {
      id_cidade: "3078",
      id_estado: "18",
      nm_cidade: "Quinta do Sol",
      nm_cidade_uf: "Quinta do Sol - PR"
  },
  {
      id_cidade: "3079",
      id_estado: "18",
      nm_cidade: "Quitandinha",
      nm_cidade_uf: "Quitandinha - PR"
  },
  {
      id_cidade: "3080",
      id_estado: "18",
      nm_cidade: "Ramilândia",
      nm_cidade_uf: "Ramilândia - PR"
  },
  {
      id_cidade: "3081",
      id_estado: "18",
      nm_cidade: "Rancho Alegre",
      nm_cidade_uf: "Rancho Alegre - PR"
  },
  {
      id_cidade: "3082",
      id_estado: "18",
      nm_cidade: "Rancho Alegre d`Oeste",
      nm_cidade_uf: "Rancho Alegre d`Oeste - PR"
  },
  {
      id_cidade: "3083",
      id_estado: "18",
      nm_cidade: "Realeza",
      nm_cidade_uf: "Realeza - PR"
  },
  {
      id_cidade: "3084",
      id_estado: "18",
      nm_cidade: "Rebouças",
      nm_cidade_uf: "Rebouças - PR"
  },
  {
      id_cidade: "3085",
      id_estado: "18",
      nm_cidade: "Renascença",
      nm_cidade_uf: "Renascença - PR"
  },
  {
      id_cidade: "3086",
      id_estado: "18",
      nm_cidade: "Reserva",
      nm_cidade_uf: "Reserva - PR"
  },
  {
      id_cidade: "3087",
      id_estado: "18",
      nm_cidade: "Reserva do Iguaçu",
      nm_cidade_uf: "Reserva do Iguaçu - PR"
  },
  {
      id_cidade: "3088",
      id_estado: "18",
      nm_cidade: "Ribeirão Claro",
      nm_cidade_uf: "Ribeirão Claro - PR"
  },
  {
      id_cidade: "3089",
      id_estado: "18",
      nm_cidade: "Ribeirão do Pinhal",
      nm_cidade_uf: "Ribeirão do Pinhal - PR"
  },
  {
      id_cidade: "3090",
      id_estado: "18",
      nm_cidade: "Rio Azul",
      nm_cidade_uf: "Rio Azul - PR"
  },
  {
      id_cidade: "3091",
      id_estado: "18",
      nm_cidade: "Rio Bom",
      nm_cidade_uf: "Rio Bom - PR"
  },
  {
      id_cidade: "3092",
      id_estado: "18",
      nm_cidade: "Rio Bonito do Iguaçu",
      nm_cidade_uf: "Rio Bonito do Iguaçu - PR"
  },
  {
      id_cidade: "3093",
      id_estado: "18",
      nm_cidade: "Rio Branco do Ivaí",
      nm_cidade_uf: "Rio Branco do Ivaí - PR"
  },
  {
      id_cidade: "3094",
      id_estado: "18",
      nm_cidade: "Rio Branco do Sul",
      nm_cidade_uf: "Rio Branco do Sul - PR"
  },
  {
      id_cidade: "3095",
      id_estado: "18",
      nm_cidade: "Rio Negro",
      nm_cidade_uf: "Rio Negro - PR"
  },
  {
      id_cidade: "3096",
      id_estado: "18",
      nm_cidade: "Rolândia",
      nm_cidade_uf: "Rolândia - PR"
  },
  {
      id_cidade: "3097",
      id_estado: "18",
      nm_cidade: "Roncador",
      nm_cidade_uf: "Roncador - PR"
  },
  {
      id_cidade: "3098",
      id_estado: "18",
      nm_cidade: "Rondon",
      nm_cidade_uf: "Rondon - PR"
  },
  {
      id_cidade: "3099",
      id_estado: "18",
      nm_cidade: "Rosário do Ivaí",
      nm_cidade_uf: "Rosário do Ivaí - PR"
  },
  {
      id_cidade: "3100",
      id_estado: "18",
      nm_cidade: "Sabáudia",
      nm_cidade_uf: "Sabáudia - PR"
  },
  {
      id_cidade: "3101",
      id_estado: "18",
      nm_cidade: "Salgado Filho",
      nm_cidade_uf: "Salgado Filho - PR"
  },
  {
      id_cidade: "3102",
      id_estado: "18",
      nm_cidade: "Salto do Itararé",
      nm_cidade_uf: "Salto do Itararé - PR"
  },
  {
      id_cidade: "3103",
      id_estado: "18",
      nm_cidade: "Salto do Lontra",
      nm_cidade_uf: "Salto do Lontra - PR"
  },
  {
      id_cidade: "3104",
      id_estado: "18",
      nm_cidade: "Santa Amélia",
      nm_cidade_uf: "Santa Amélia - PR"
  },
  {
      id_cidade: "3105",
      id_estado: "18",
      nm_cidade: "Santa Cecília do Pavão",
      nm_cidade_uf: "Santa Cecília do Pavão - PR"
  },
  {
      id_cidade: "3106",
      id_estado: "18",
      nm_cidade: "Santa Cruz de Monte Castelo",
      nm_cidade_uf: "Santa Cruz de Monte Castelo - PR"
  },
  {
      id_cidade: "3107",
      id_estado: "18",
      nm_cidade: "Santa Fé",
      nm_cidade_uf: "Santa Fé - PR"
  },
  {
      id_cidade: "3108",
      id_estado: "18",
      nm_cidade: "Santa Helena",
      nm_cidade_uf: "Santa Helena - PR"
  },
  {
      id_cidade: "3109",
      id_estado: "18",
      nm_cidade: "Santa Inês",
      nm_cidade_uf: "Santa Inês - PR"
  },
  {
      id_cidade: "3110",
      id_estado: "18",
      nm_cidade: "Santa Isabel do Ivaí",
      nm_cidade_uf: "Santa Isabel do Ivaí - PR"
  },
  {
      id_cidade: "3111",
      id_estado: "18",
      nm_cidade: "Santa Izabel do Oeste",
      nm_cidade_uf: "Santa Izabel do Oeste - PR"
  },
  {
      id_cidade: "3112",
      id_estado: "18",
      nm_cidade: "Santa Lúcia",
      nm_cidade_uf: "Santa Lúcia - PR"
  },
  {
      id_cidade: "3113",
      id_estado: "18",
      nm_cidade: "Santa Maria do Oeste",
      nm_cidade_uf: "Santa Maria do Oeste - PR"
  },
  {
      id_cidade: "3114",
      id_estado: "18",
      nm_cidade: "Santa Mariana",
      nm_cidade_uf: "Santa Mariana - PR"
  },
  {
      id_cidade: "3115",
      id_estado: "18",
      nm_cidade: "Santa Mônica",
      nm_cidade_uf: "Santa Mônica - PR"
  },
  {
      id_cidade: "3116",
      id_estado: "18",
      nm_cidade: "Santa Tereza do Oeste",
      nm_cidade_uf: "Santa Tereza do Oeste - PR"
  },
  {
      id_cidade: "3117",
      id_estado: "18",
      nm_cidade: "Santa Terezinha de Itaipu",
      nm_cidade_uf: "Santa Terezinha de Itaipu - PR"
  },
  {
      id_cidade: "3118",
      id_estado: "18",
      nm_cidade: "Santana do Itararé",
      nm_cidade_uf: "Santana do Itararé - PR"
  },
  {
      id_cidade: "3119",
      id_estado: "18",
      nm_cidade: "Santo Antônio da Platina",
      nm_cidade_uf: "Santo Antônio da Platina - PR"
  },
  {
      id_cidade: "3120",
      id_estado: "18",
      nm_cidade: "Santo Antônio do Caiuá",
      nm_cidade_uf: "Santo Antônio do Caiuá - PR"
  },
  {
      id_cidade: "3121",
      id_estado: "18",
      nm_cidade: "Santo Antônio do Paraíso",
      nm_cidade_uf: "Santo Antônio do Paraíso - PR"
  },
  {
      id_cidade: "3122",
      id_estado: "18",
      nm_cidade: "Santo Antônio do Sudoeste",
      nm_cidade_uf: "Santo Antônio do Sudoeste - PR"
  },
  {
      id_cidade: "3123",
      id_estado: "18",
      nm_cidade: "Santo Inácio",
      nm_cidade_uf: "Santo Inácio - PR"
  },
  {
      id_cidade: "3124",
      id_estado: "18",
      nm_cidade: "São Carlos do Ivaí",
      nm_cidade_uf: "São Carlos do Ivaí - PR"
  },
  {
      id_cidade: "3125",
      id_estado: "18",
      nm_cidade: "São Jerônimo da Serra",
      nm_cidade_uf: "São Jerônimo da Serra - PR"
  },
  {
      id_cidade: "3126",
      id_estado: "18",
      nm_cidade: "São João",
      nm_cidade_uf: "São João - PR"
  },
  {
      id_cidade: "3127",
      id_estado: "18",
      nm_cidade: "São João do Caiuá",
      nm_cidade_uf: "São João do Caiuá - PR"
  },
  {
      id_cidade: "3128",
      id_estado: "18",
      nm_cidade: "São João do Ivaí",
      nm_cidade_uf: "São João do Ivaí - PR"
  },
  {
      id_cidade: "3129",
      id_estado: "18",
      nm_cidade: "São João do Triunfo",
      nm_cidade_uf: "São João do Triunfo - PR"
  },
  {
      id_cidade: "3130",
      id_estado: "18",
      nm_cidade: "São Jorge d`Oeste",
      nm_cidade_uf: "São Jorge d`Oeste - PR"
  },
  {
      id_cidade: "3131",
      id_estado: "18",
      nm_cidade: "São Jorge do Ivaí",
      nm_cidade_uf: "São Jorge do Ivaí - PR"
  },
  {
      id_cidade: "3132",
      id_estado: "18",
      nm_cidade: "São Jorge do Patrocínio",
      nm_cidade_uf: "São Jorge do Patrocínio - PR"
  },
  {
      id_cidade: "3133",
      id_estado: "18",
      nm_cidade: "São José da Boa Vista",
      nm_cidade_uf: "São José da Boa Vista - PR"
  },
  {
      id_cidade: "3134",
      id_estado: "18",
      nm_cidade: "São José das Palmeiras",
      nm_cidade_uf: "São José das Palmeiras - PR"
  },
  {
      id_cidade: "3135",
      id_estado: "18",
      nm_cidade: "São José dos Pinhais",
      nm_cidade_uf: "São José dos Pinhais - PR"
  },
  {
      id_cidade: "3136",
      id_estado: "18",
      nm_cidade: "São Manoel do Paraná",
      nm_cidade_uf: "São Manoel do Paraná - PR"
  },
  {
      id_cidade: "3137",
      id_estado: "18",
      nm_cidade: "São Mateus do Sul",
      nm_cidade_uf: "São Mateus do Sul - PR"
  },
  {
      id_cidade: "3138",
      id_estado: "18",
      nm_cidade: "São Miguel do Iguaçu",
      nm_cidade_uf: "São Miguel do Iguaçu - PR"
  },
  {
      id_cidade: "3139",
      id_estado: "18",
      nm_cidade: "São Pedro do Iguaçu",
      nm_cidade_uf: "São Pedro do Iguaçu - PR"
  },
  {
      id_cidade: "3140",
      id_estado: "18",
      nm_cidade: "São Pedro do Ivaí",
      nm_cidade_uf: "São Pedro do Ivaí - PR"
  },
  {
      id_cidade: "3141",
      id_estado: "18",
      nm_cidade: "São Pedro do Paraná",
      nm_cidade_uf: "São Pedro do Paraná - PR"
  },
  {
      id_cidade: "3142",
      id_estado: "18",
      nm_cidade: "São Sebastião da Amoreira",
      nm_cidade_uf: "São Sebastião da Amoreira - PR"
  },
  {
      id_cidade: "3143",
      id_estado: "18",
      nm_cidade: "São Tomé",
      nm_cidade_uf: "São Tomé - PR"
  },
  {
      id_cidade: "3144",
      id_estado: "18",
      nm_cidade: "Sapopema",
      nm_cidade_uf: "Sapopema - PR"
  },
  {
      id_cidade: "3145",
      id_estado: "18",
      nm_cidade: "Sarandi",
      nm_cidade_uf: "Sarandi - PR"
  },
  {
      id_cidade: "3146",
      id_estado: "18",
      nm_cidade: "Saudade do Iguaçu",
      nm_cidade_uf: "Saudade do Iguaçu - PR"
  },
  {
      id_cidade: "3147",
      id_estado: "18",
      nm_cidade: "Sengés",
      nm_cidade_uf: "Sengés - PR"
  },
  {
      id_cidade: "3148",
      id_estado: "18",
      nm_cidade: "Serranópolis do Iguaçu",
      nm_cidade_uf: "Serranópolis do Iguaçu - PR"
  },
  {
      id_cidade: "3149",
      id_estado: "18",
      nm_cidade: "Sertaneja",
      nm_cidade_uf: "Sertaneja - PR"
  },
  {
      id_cidade: "3150",
      id_estado: "18",
      nm_cidade: "Sertanópolis",
      nm_cidade_uf: "Sertanópolis - PR"
  },
  {
      id_cidade: "3151",
      id_estado: "18",
      nm_cidade: "Siqueira Campos",
      nm_cidade_uf: "Siqueira Campos - PR"
  },
  {
      id_cidade: "3152",
      id_estado: "18",
      nm_cidade: "Sulina",
      nm_cidade_uf: "Sulina - PR"
  },
  {
      id_cidade: "3153",
      id_estado: "18",
      nm_cidade: "Tamarana",
      nm_cidade_uf: "Tamarana - PR"
  },
  {
      id_cidade: "3154",
      id_estado: "18",
      nm_cidade: "Tamboara",
      nm_cidade_uf: "Tamboara - PR"
  },
  {
      id_cidade: "3155",
      id_estado: "18",
      nm_cidade: "Tapejara",
      nm_cidade_uf: "Tapejara - PR"
  },
  {
      id_cidade: "3156",
      id_estado: "18",
      nm_cidade: "Tapira",
      nm_cidade_uf: "Tapira - PR"
  },
  {
      id_cidade: "3157",
      id_estado: "18",
      nm_cidade: "Teixeira Soares",
      nm_cidade_uf: "Teixeira Soares - PR"
  },
  {
      id_cidade: "3158",
      id_estado: "18",
      nm_cidade: "Telêmaco Borba",
      nm_cidade_uf: "Telêmaco Borba - PR"
  },
  {
      id_cidade: "3159",
      id_estado: "18",
      nm_cidade: "Terra Boa",
      nm_cidade_uf: "Terra Boa - PR"
  },
  {
      id_cidade: "3160",
      id_estado: "18",
      nm_cidade: "Terra Rica",
      nm_cidade_uf: "Terra Rica - PR"
  },
  {
      id_cidade: "3161",
      id_estado: "18",
      nm_cidade: "Terra Roxa",
      nm_cidade_uf: "Terra Roxa - PR"
  },
  {
      id_cidade: "3162",
      id_estado: "18",
      nm_cidade: "Tibagi",
      nm_cidade_uf: "Tibagi - PR"
  },
  {
      id_cidade: "3163",
      id_estado: "18",
      nm_cidade: "Tijucas do Sul",
      nm_cidade_uf: "Tijucas do Sul - PR"
  },
  {
      id_cidade: "3164",
      id_estado: "18",
      nm_cidade: "Toledo",
      nm_cidade_uf: "Toledo - PR"
  },
  {
      id_cidade: "3165",
      id_estado: "18",
      nm_cidade: "Tomazina",
      nm_cidade_uf: "Tomazina - PR"
  },
  {
      id_cidade: "3166",
      id_estado: "18",
      nm_cidade: "Três Barras do Paraná",
      nm_cidade_uf: "Três Barras do Paraná - PR"
  },
  {
      id_cidade: "3167",
      id_estado: "18",
      nm_cidade: "Tunas do Paraná",
      nm_cidade_uf: "Tunas do Paraná - PR"
  },
  {
      id_cidade: "3168",
      id_estado: "18",
      nm_cidade: "Tuneiras do Oeste",
      nm_cidade_uf: "Tuneiras do Oeste - PR"
  },
  {
      id_cidade: "3169",
      id_estado: "18",
      nm_cidade: "Tupãssi",
      nm_cidade_uf: "Tupãssi - PR"
  },
  {
      id_cidade: "3170",
      id_estado: "18",
      nm_cidade: "Turvo",
      nm_cidade_uf: "Turvo - PR"
  },
  {
      id_cidade: "3171",
      id_estado: "18",
      nm_cidade: "Ubiratã",
      nm_cidade_uf: "Ubiratã - PR"
  },
  {
      id_cidade: "3172",
      id_estado: "18",
      nm_cidade: "Umuarama",
      nm_cidade_uf: "Umuarama - PR"
  },
  {
      id_cidade: "3173",
      id_estado: "18",
      nm_cidade: "União da Vitória",
      nm_cidade_uf: "União da Vitória - PR"
  },
  {
      id_cidade: "3174",
      id_estado: "18",
      nm_cidade: "Uniflor",
      nm_cidade_uf: "Uniflor - PR"
  },
  {
      id_cidade: "3175",
      id_estado: "18",
      nm_cidade: "Uraí",
      nm_cidade_uf: "Uraí - PR"
  },
  {
      id_cidade: "3176",
      id_estado: "18",
      nm_cidade: "Ventania",
      nm_cidade_uf: "Ventania - PR"
  },
  {
      id_cidade: "3177",
      id_estado: "18",
      nm_cidade: "Vera Cruz do Oeste",
      nm_cidade_uf: "Vera Cruz do Oeste - PR"
  },
  {
      id_cidade: "3178",
      id_estado: "18",
      nm_cidade: "Verê",
      nm_cidade_uf: "Verê - PR"
  },
  {
      id_cidade: "3179",
      id_estado: "18",
      nm_cidade: "Virmond",
      nm_cidade_uf: "Virmond - PR"
  },
  {
      id_cidade: "3180",
      id_estado: "18",
      nm_cidade: "Vitorino",
      nm_cidade_uf: "Vitorino - PR"
  },
  {
      id_cidade: "3181",
      id_estado: "18",
      nm_cidade: "Wenceslau Braz",
      nm_cidade_uf: "Wenceslau Braz - PR"
  },
  {
      id_cidade: "3182",
      id_estado: "18",
      nm_cidade: "Xambrê",
      nm_cidade_uf: "Xambrê - PR"
  },
  {
      id_cidade: "3183",
      id_estado: "16",
      nm_cidade: "Abreu e Lima",
      nm_cidade_uf: "Abreu e Lima - PE"
  },
  {
      id_cidade: "3184",
      id_estado: "16",
      nm_cidade: "Afogados da Ingazeira",
      nm_cidade_uf: "Afogados da Ingazeira - PE"
  },
  {
      id_cidade: "3185",
      id_estado: "16",
      nm_cidade: "Afrânio",
      nm_cidade_uf: "Afrânio - PE"
  },
  {
      id_cidade: "3186",
      id_estado: "16",
      nm_cidade: "Agrestina",
      nm_cidade_uf: "Agrestina - PE"
  },
  {
      id_cidade: "3187",
      id_estado: "16",
      nm_cidade: "Água Preta",
      nm_cidade_uf: "Água Preta - PE"
  },
  {
      id_cidade: "3188",
      id_estado: "16",
      nm_cidade: "Águas Belas",
      nm_cidade_uf: "Águas Belas - PE"
  },
  {
      id_cidade: "3189",
      id_estado: "16",
      nm_cidade: "Alagoinha",
      nm_cidade_uf: "Alagoinha - PE"
  },
  {
      id_cidade: "3190",
      id_estado: "16",
      nm_cidade: "Aliança",
      nm_cidade_uf: "Aliança - PE"
  },
  {
      id_cidade: "3191",
      id_estado: "16",
      nm_cidade: "Altinho",
      nm_cidade_uf: "Altinho - PE"
  },
  {
      id_cidade: "3192",
      id_estado: "16",
      nm_cidade: "Amaraji",
      nm_cidade_uf: "Amaraji - PE"
  },
  {
      id_cidade: "3193",
      id_estado: "16",
      nm_cidade: "Angelim",
      nm_cidade_uf: "Angelim - PE"
  },
  {
      id_cidade: "3194",
      id_estado: "16",
      nm_cidade: "Araçoiaba",
      nm_cidade_uf: "Araçoiaba - PE"
  },
  {
      id_cidade: "3195",
      id_estado: "16",
      nm_cidade: "Araripina",
      nm_cidade_uf: "Araripina - PE"
  },
  {
      id_cidade: "3196",
      id_estado: "16",
      nm_cidade: "Arcoverde",
      nm_cidade_uf: "Arcoverde - PE"
  },
  {
      id_cidade: "3197",
      id_estado: "16",
      nm_cidade: "Barra de Guabiraba",
      nm_cidade_uf: "Barra de Guabiraba - PE"
  },
  {
      id_cidade: "3198",
      id_estado: "16",
      nm_cidade: "Barreiros",
      nm_cidade_uf: "Barreiros - PE"
  },
  {
      id_cidade: "3199",
      id_estado: "16",
      nm_cidade: "Belém de Maria",
      nm_cidade_uf: "Belém de Maria - PE"
  },
  {
      id_cidade: "3200",
      id_estado: "16",
      nm_cidade: "Belém de São Francisco",
      nm_cidade_uf: "Belém de São Francisco - PE"
  },
  {
      id_cidade: "3201",
      id_estado: "16",
      nm_cidade: "Belo Jardim",
      nm_cidade_uf: "Belo Jardim - PE"
  },
  {
      id_cidade: "3202",
      id_estado: "16",
      nm_cidade: "Betânia",
      nm_cidade_uf: "Betânia - PE"
  },
  {
      id_cidade: "3203",
      id_estado: "16",
      nm_cidade: "Bezerros",
      nm_cidade_uf: "Bezerros - PE"
  },
  {
      id_cidade: "3204",
      id_estado: "16",
      nm_cidade: "Bodocó",
      nm_cidade_uf: "Bodocó - PE"
  },
  {
      id_cidade: "3205",
      id_estado: "16",
      nm_cidade: "Bom Conselho",
      nm_cidade_uf: "Bom Conselho - PE"
  },
  {
      id_cidade: "3206",
      id_estado: "16",
      nm_cidade: "Bom Jardim",
      nm_cidade_uf: "Bom Jardim - PE"
  },
  {
      id_cidade: "3207",
      id_estado: "16",
      nm_cidade: "Bonito",
      nm_cidade_uf: "Bonito - PE"
  },
  {
      id_cidade: "3208",
      id_estado: "16",
      nm_cidade: "Brejão",
      nm_cidade_uf: "Brejão - PE"
  },
  {
      id_cidade: "3209",
      id_estado: "16",
      nm_cidade: "Brejinho",
      nm_cidade_uf: "Brejinho - PE"
  },
  {
      id_cidade: "3210",
      id_estado: "16",
      nm_cidade: "Brejo da Madre de Deus",
      nm_cidade_uf: "Brejo da Madre de Deus - PE"
  },
  {
      id_cidade: "3211",
      id_estado: "16",
      nm_cidade: "Buenos Aires",
      nm_cidade_uf: "Buenos Aires - PE"
  },
  {
      id_cidade: "3212",
      id_estado: "16",
      nm_cidade: "Buíque",
      nm_cidade_uf: "Buíque - PE"
  },
  {
      id_cidade: "3213",
      id_estado: "16",
      nm_cidade: "Cabo de Santo Agostinho",
      nm_cidade_uf: "Cabo de Santo Agostinho - PE"
  },
  {
      id_cidade: "3214",
      id_estado: "16",
      nm_cidade: "Cabrobó",
      nm_cidade_uf: "Cabrobó - PE"
  },
  {
      id_cidade: "3215",
      id_estado: "16",
      nm_cidade: "Cachoeirinha",
      nm_cidade_uf: "Cachoeirinha - PE"
  },
  {
      id_cidade: "3216",
      id_estado: "16",
      nm_cidade: "Caetés",
      nm_cidade_uf: "Caetés - PE"
  },
  {
      id_cidade: "3217",
      id_estado: "16",
      nm_cidade: "Calçado",
      nm_cidade_uf: "Calçado - PE"
  },
  {
      id_cidade: "3218",
      id_estado: "16",
      nm_cidade: "Calumbi",
      nm_cidade_uf: "Calumbi - PE"
  },
  {
      id_cidade: "3219",
      id_estado: "16",
      nm_cidade: "Camaragibe",
      nm_cidade_uf: "Camaragibe - PE"
  },
  {
      id_cidade: "3220",
      id_estado: "16",
      nm_cidade: "Camocim de São Félix",
      nm_cidade_uf: "Camocim de São Félix - PE"
  },
  {
      id_cidade: "3221",
      id_estado: "16",
      nm_cidade: "Camutanga",
      nm_cidade_uf: "Camutanga - PE"
  },
  {
      id_cidade: "3222",
      id_estado: "16",
      nm_cidade: "Canhotinho",
      nm_cidade_uf: "Canhotinho - PE"
  },
  {
      id_cidade: "3223",
      id_estado: "16",
      nm_cidade: "Capoeiras",
      nm_cidade_uf: "Capoeiras - PE"
  },
  {
      id_cidade: "3224",
      id_estado: "16",
      nm_cidade: "Carnaíba",
      nm_cidade_uf: "Carnaíba - PE"
  },
  {
      id_cidade: "3225",
      id_estado: "16",
      nm_cidade: "Carnaubeira da Penha",
      nm_cidade_uf: "Carnaubeira da Penha - PE"
  },
  {
      id_cidade: "3226",
      id_estado: "16",
      nm_cidade: "Carpina",
      nm_cidade_uf: "Carpina - PE"
  },
  {
      id_cidade: "3227",
      id_estado: "16",
      nm_cidade: "Caruaru",
      nm_cidade_uf: "Caruaru - PE"
  },
  {
      id_cidade: "3228",
      id_estado: "16",
      nm_cidade: "Casinhas",
      nm_cidade_uf: "Casinhas - PE"
  },
  {
      id_cidade: "3229",
      id_estado: "16",
      nm_cidade: "Catende",
      nm_cidade_uf: "Catende - PE"
  },
  {
      id_cidade: "3230",
      id_estado: "16",
      nm_cidade: "Cedro",
      nm_cidade_uf: "Cedro - PE"
  },
  {
      id_cidade: "3231",
      id_estado: "16",
      nm_cidade: "Chã de Alegria",
      nm_cidade_uf: "Chã de Alegria - PE"
  },
  {
      id_cidade: "3232",
      id_estado: "16",
      nm_cidade: "Chã Grande",
      nm_cidade_uf: "Chã Grande - PE"
  },
  {
      id_cidade: "3233",
      id_estado: "16",
      nm_cidade: "Condado",
      nm_cidade_uf: "Condado - PE"
  },
  {
      id_cidade: "3234",
      id_estado: "16",
      nm_cidade: "Correntes",
      nm_cidade_uf: "Correntes - PE"
  },
  {
      id_cidade: "3235",
      id_estado: "16",
      nm_cidade: "Cortês",
      nm_cidade_uf: "Cortês - PE"
  },
  {
      id_cidade: "3236",
      id_estado: "16",
      nm_cidade: "Cumaru",
      nm_cidade_uf: "Cumaru - PE"
  },
  {
      id_cidade: "3237",
      id_estado: "16",
      nm_cidade: "Cupira",
      nm_cidade_uf: "Cupira - PE"
  },
  {
      id_cidade: "3238",
      id_estado: "16",
      nm_cidade: "Custódia",
      nm_cidade_uf: "Custódia - PE"
  },
  {
      id_cidade: "3239",
      id_estado: "16",
      nm_cidade: "Dormentes",
      nm_cidade_uf: "Dormentes - PE"
  },
  {
      id_cidade: "3240",
      id_estado: "16",
      nm_cidade: "Escada",
      nm_cidade_uf: "Escada - PE"
  },
  {
      id_cidade: "3241",
      id_estado: "16",
      nm_cidade: "Exu",
      nm_cidade_uf: "Exu - PE"
  },
  {
      id_cidade: "3242",
      id_estado: "16",
      nm_cidade: "Feira Nova",
      nm_cidade_uf: "Feira Nova - PE"
  },
  {
      id_cidade: "3243",
      id_estado: "16",
      nm_cidade: "Fernando de Noronha",
      nm_cidade_uf: "Fernando de Noronha - PE"
  },
  {
      id_cidade: "3244",
      id_estado: "16",
      nm_cidade: "Ferreiros",
      nm_cidade_uf: "Ferreiros - PE"
  },
  {
      id_cidade: "3245",
      id_estado: "16",
      nm_cidade: "Flores",
      nm_cidade_uf: "Flores - PE"
  },
  {
      id_cidade: "3246",
      id_estado: "16",
      nm_cidade: "Floresta",
      nm_cidade_uf: "Floresta - PE"
  },
  {
      id_cidade: "3247",
      id_estado: "16",
      nm_cidade: "Frei Miguelinho",
      nm_cidade_uf: "Frei Miguelinho - PE"
  },
  {
      id_cidade: "3248",
      id_estado: "16",
      nm_cidade: "Gameleira",
      nm_cidade_uf: "Gameleira - PE"
  },
  {
      id_cidade: "3249",
      id_estado: "16",
      nm_cidade: "Garanhuns",
      nm_cidade_uf: "Garanhuns - PE"
  },
  {
      id_cidade: "3250",
      id_estado: "16",
      nm_cidade: "Glória do Goitá",
      nm_cidade_uf: "Glória do Goitá - PE"
  },
  {
      id_cidade: "3251",
      id_estado: "16",
      nm_cidade: "Goiana",
      nm_cidade_uf: "Goiana - PE"
  },
  {
      id_cidade: "3252",
      id_estado: "16",
      nm_cidade: "Granito",
      nm_cidade_uf: "Granito - PE"
  },
  {
      id_cidade: "3253",
      id_estado: "16",
      nm_cidade: "Gravatá",
      nm_cidade_uf: "Gravatá - PE"
  },
  {
      id_cidade: "3254",
      id_estado: "16",
      nm_cidade: "Iati",
      nm_cidade_uf: "Iati - PE"
  },
  {
      id_cidade: "3255",
      id_estado: "16",
      nm_cidade: "Ibimirim",
      nm_cidade_uf: "Ibimirim - PE"
  },
  {
      id_cidade: "3256",
      id_estado: "16",
      nm_cidade: "Ibirajuba",
      nm_cidade_uf: "Ibirajuba - PE"
  },
  {
      id_cidade: "3257",
      id_estado: "16",
      nm_cidade: "Igarassu",
      nm_cidade_uf: "Igarassu - PE"
  },
  {
      id_cidade: "3258",
      id_estado: "16",
      nm_cidade: "Iguaraci",
      nm_cidade_uf: "Iguaraci - PE"
  },
  {
      id_cidade: "3259",
      id_estado: "16",
      nm_cidade: "Ilha de Itamaracá",
      nm_cidade_uf: "Ilha de Itamaracá - PE"
  },
  {
      id_cidade: "3260",
      id_estado: "16",
      nm_cidade: "Inajá",
      nm_cidade_uf: "Inajá - PE"
  },
  {
      id_cidade: "3261",
      id_estado: "16",
      nm_cidade: "Ingazeira",
      nm_cidade_uf: "Ingazeira - PE"
  },
  {
      id_cidade: "3262",
      id_estado: "16",
      nm_cidade: "Ipojuca",
      nm_cidade_uf: "Ipojuca - PE"
  },
  {
      id_cidade: "3263",
      id_estado: "16",
      nm_cidade: "Ipubi",
      nm_cidade_uf: "Ipubi - PE"
  },
  {
      id_cidade: "3264",
      id_estado: "16",
      nm_cidade: "Itacuruba",
      nm_cidade_uf: "Itacuruba - PE"
  },
  {
      id_cidade: "3265",
      id_estado: "16",
      nm_cidade: "Itaíba",
      nm_cidade_uf: "Itaíba - PE"
  },
  {
      id_cidade: "3266",
      id_estado: "16",
      nm_cidade: "Itambé",
      nm_cidade_uf: "Itambé - PE"
  },
  {
      id_cidade: "3267",
      id_estado: "16",
      nm_cidade: "Itapetim",
      nm_cidade_uf: "Itapetim - PE"
  },
  {
      id_cidade: "3268",
      id_estado: "16",
      nm_cidade: "Itapissuma",
      nm_cidade_uf: "Itapissuma - PE"
  },
  {
      id_cidade: "3269",
      id_estado: "16",
      nm_cidade: "Itaquitinga",
      nm_cidade_uf: "Itaquitinga - PE"
  },
  {
      id_cidade: "3270",
      id_estado: "16",
      nm_cidade: "Jaboatão dos Guararapes",
      nm_cidade_uf: "Jaboatão dos Guararapes - PE"
  },
  {
      id_cidade: "3271",
      id_estado: "16",
      nm_cidade: "Jaqueira",
      nm_cidade_uf: "Jaqueira - PE"
  },
  {
      id_cidade: "3272",
      id_estado: "16",
      nm_cidade: "Jataúba",
      nm_cidade_uf: "Jataúba - PE"
  },
  {
      id_cidade: "3273",
      id_estado: "16",
      nm_cidade: "Jatobá",
      nm_cidade_uf: "Jatobá - PE"
  },
  {
      id_cidade: "3274",
      id_estado: "16",
      nm_cidade: "João Alfredo",
      nm_cidade_uf: "João Alfredo - PE"
  },
  {
      id_cidade: "3275",
      id_estado: "16",
      nm_cidade: "Joaquim Nabuco",
      nm_cidade_uf: "Joaquim Nabuco - PE"
  },
  {
      id_cidade: "3276",
      id_estado: "16",
      nm_cidade: "Jucati",
      nm_cidade_uf: "Jucati - PE"
  },
  {
      id_cidade: "3277",
      id_estado: "16",
      nm_cidade: "Jupi",
      nm_cidade_uf: "Jupi - PE"
  },
  {
      id_cidade: "3278",
      id_estado: "16",
      nm_cidade: "Jurema",
      nm_cidade_uf: "Jurema - PE"
  },
  {
      id_cidade: "3279",
      id_estado: "16",
      nm_cidade: "Lagoa do Carro",
      nm_cidade_uf: "Lagoa do Carro - PE"
  },
  {
      id_cidade: "3280",
      id_estado: "16",
      nm_cidade: "Lagoa do Itaenga",
      nm_cidade_uf: "Lagoa do Itaenga - PE"
  },
  {
      id_cidade: "3281",
      id_estado: "16",
      nm_cidade: "Lagoa do Ouro",
      nm_cidade_uf: "Lagoa do Ouro - PE"
  },
  {
      id_cidade: "3282",
      id_estado: "16",
      nm_cidade: "Lagoa dos Gatos",
      nm_cidade_uf: "Lagoa dos Gatos - PE"
  },
  {
      id_cidade: "3283",
      id_estado: "16",
      nm_cidade: "Lagoa Grande",
      nm_cidade_uf: "Lagoa Grande - PE"
  },
  {
      id_cidade: "3284",
      id_estado: "16",
      nm_cidade: "Lajedo",
      nm_cidade_uf: "Lajedo - PE"
  },
  {
      id_cidade: "3285",
      id_estado: "16",
      nm_cidade: "Limoeiro",
      nm_cidade_uf: "Limoeiro - PE"
  },
  {
      id_cidade: "3286",
      id_estado: "16",
      nm_cidade: "Macaparana",
      nm_cidade_uf: "Macaparana - PE"
  },
  {
      id_cidade: "3287",
      id_estado: "16",
      nm_cidade: "Machados",
      nm_cidade_uf: "Machados - PE"
  },
  {
      id_cidade: "3288",
      id_estado: "16",
      nm_cidade: "Manari",
      nm_cidade_uf: "Manari - PE"
  },
  {
      id_cidade: "3289",
      id_estado: "16",
      nm_cidade: "Maraial",
      nm_cidade_uf: "Maraial - PE"
  },
  {
      id_cidade: "3290",
      id_estado: "16",
      nm_cidade: "Mirandiba",
      nm_cidade_uf: "Mirandiba - PE"
  },
  {
      id_cidade: "3291",
      id_estado: "16",
      nm_cidade: "Moreilândia",
      nm_cidade_uf: "Moreilândia - PE"
  },
  {
      id_cidade: "3292",
      id_estado: "16",
      nm_cidade: "Moreno",
      nm_cidade_uf: "Moreno - PE"
  },
  {
      id_cidade: "3293",
      id_estado: "16",
      nm_cidade: "Nazaré da Mata",
      nm_cidade_uf: "Nazaré da Mata - PE"
  },
  {
      id_cidade: "3294",
      id_estado: "16",
      nm_cidade: "Olinda",
      nm_cidade_uf: "Olinda - PE"
  },
  {
      id_cidade: "3295",
      id_estado: "16",
      nm_cidade: "Orobó",
      nm_cidade_uf: "Orobó - PE"
  },
  {
      id_cidade: "3296",
      id_estado: "16",
      nm_cidade: "Orocó",
      nm_cidade_uf: "Orocó - PE"
  },
  {
      id_cidade: "3297",
      id_estado: "16",
      nm_cidade: "Ouricuri",
      nm_cidade_uf: "Ouricuri - PE"
  },
  {
      id_cidade: "3298",
      id_estado: "16",
      nm_cidade: "Palmares",
      nm_cidade_uf: "Palmares - PE"
  },
  {
      id_cidade: "3299",
      id_estado: "16",
      nm_cidade: "Palmeirina",
      nm_cidade_uf: "Palmeirina - PE"
  },
  {
      id_cidade: "3300",
      id_estado: "16",
      nm_cidade: "Panelas",
      nm_cidade_uf: "Panelas - PE"
  },
  {
      id_cidade: "3301",
      id_estado: "16",
      nm_cidade: "Paranatama",
      nm_cidade_uf: "Paranatama - PE"
  },
  {
      id_cidade: "3302",
      id_estado: "16",
      nm_cidade: "Parnamirim",
      nm_cidade_uf: "Parnamirim - PE"
  },
  {
      id_cidade: "3303",
      id_estado: "16",
      nm_cidade: "Passira",
      nm_cidade_uf: "Passira - PE"
  },
  {
      id_cidade: "3304",
      id_estado: "16",
      nm_cidade: "Paudalho",
      nm_cidade_uf: "Paudalho - PE"
  },
  {
      id_cidade: "3305",
      id_estado: "16",
      nm_cidade: "Paulista",
      nm_cidade_uf: "Paulista - PE"
  },
  {
      id_cidade: "3306",
      id_estado: "16",
      nm_cidade: "Pedra",
      nm_cidade_uf: "Pedra - PE"
  },
  {
      id_cidade: "3307",
      id_estado: "16",
      nm_cidade: "Pesqueira",
      nm_cidade_uf: "Pesqueira - PE"
  },
  {
      id_cidade: "3308",
      id_estado: "16",
      nm_cidade: "Petrolândia",
      nm_cidade_uf: "Petrolândia - PE"
  },
  {
      id_cidade: "3309",
      id_estado: "16",
      nm_cidade: "Petrolina",
      nm_cidade_uf: "Petrolina - PE"
  },
  {
      id_cidade: "3310",
      id_estado: "16",
      nm_cidade: "Poção",
      nm_cidade_uf: "Poção - PE"
  },
  {
      id_cidade: "3311",
      id_estado: "16",
      nm_cidade: "Pombos",
      nm_cidade_uf: "Pombos - PE"
  },
  {
      id_cidade: "3312",
      id_estado: "16",
      nm_cidade: "Primavera",
      nm_cidade_uf: "Primavera - PE"
  },
  {
      id_cidade: "3313",
      id_estado: "16",
      nm_cidade: "Quipapá",
      nm_cidade_uf: "Quipapá - PE"
  },
  {
      id_cidade: "3314",
      id_estado: "16",
      nm_cidade: "Quixaba",
      nm_cidade_uf: "Quixaba - PE"
  },
  {
      id_cidade: "3315",
      id_estado: "16",
      nm_cidade: "Recife",
      nm_cidade_uf: "Recife - PE"
  },
  {
      id_cidade: "3316",
      id_estado: "16",
      nm_cidade: "Riacho das Almas",
      nm_cidade_uf: "Riacho das Almas - PE"
  },
  {
      id_cidade: "3317",
      id_estado: "16",
      nm_cidade: "Ribeirão",
      nm_cidade_uf: "Ribeirão - PE"
  },
  {
      id_cidade: "3318",
      id_estado: "16",
      nm_cidade: "Rio Formoso",
      nm_cidade_uf: "Rio Formoso - PE"
  },
  {
      id_cidade: "3319",
      id_estado: "16",
      nm_cidade: "Sairé",
      nm_cidade_uf: "Sairé - PE"
  },
  {
      id_cidade: "3320",
      id_estado: "16",
      nm_cidade: "Salgadinho",
      nm_cidade_uf: "Salgadinho - PE"
  },
  {
      id_cidade: "3321",
      id_estado: "16",
      nm_cidade: "Salgueiro",
      nm_cidade_uf: "Salgueiro - PE"
  },
  {
      id_cidade: "3322",
      id_estado: "16",
      nm_cidade: "Saloá",
      nm_cidade_uf: "Saloá - PE"
  },
  {
      id_cidade: "3323",
      id_estado: "16",
      nm_cidade: "Sanharó",
      nm_cidade_uf: "Sanharó - PE"
  },
  {
      id_cidade: "3324",
      id_estado: "16",
      nm_cidade: "Santa Cruz",
      nm_cidade_uf: "Santa Cruz - PE"
  },
  {
      id_cidade: "3325",
      id_estado: "16",
      nm_cidade: "Santa Cruz da Baixa Verde",
      nm_cidade_uf: "Santa Cruz da Baixa Verde - PE"
  },
  {
      id_cidade: "3326",
      id_estado: "16",
      nm_cidade: "Santa Cruz do Capibaribe",
      nm_cidade_uf: "Santa Cruz do Capibaribe - PE"
  },
  {
      id_cidade: "3327",
      id_estado: "16",
      nm_cidade: "Santa Filomena",
      nm_cidade_uf: "Santa Filomena - PE"
  },
  {
      id_cidade: "3328",
      id_estado: "16",
      nm_cidade: "Santa Maria da Boa Vista",
      nm_cidade_uf: "Santa Maria da Boa Vista - PE"
  },
  {
      id_cidade: "3329",
      id_estado: "16",
      nm_cidade: "Santa Maria do Cambucá",
      nm_cidade_uf: "Santa Maria do Cambucá - PE"
  },
  {
      id_cidade: "3330",
      id_estado: "16",
      nm_cidade: "Santa Terezinha",
      nm_cidade_uf: "Santa Terezinha - PE"
  },
  {
      id_cidade: "3331",
      id_estado: "16",
      nm_cidade: "São Benedito do Sul",
      nm_cidade_uf: "São Benedito do Sul - PE"
  },
  {
      id_cidade: "3332",
      id_estado: "16",
      nm_cidade: "São Bento do Una",
      nm_cidade_uf: "São Bento do Una - PE"
  },
  {
      id_cidade: "3333",
      id_estado: "16",
      nm_cidade: "São Caitano",
      nm_cidade_uf: "São Caitano - PE"
  },
  {
      id_cidade: "3334",
      id_estado: "16",
      nm_cidade: "São João",
      nm_cidade_uf: "São João - PE"
  },
  {
      id_cidade: "3335",
      id_estado: "16",
      nm_cidade: "São Joaquim do Monte",
      nm_cidade_uf: "São Joaquim do Monte - PE"
  },
  {
      id_cidade: "3336",
      id_estado: "16",
      nm_cidade: "São José da Coroa Grande",
      nm_cidade_uf: "São José da Coroa Grande - PE"
  },
  {
      id_cidade: "3337",
      id_estado: "16",
      nm_cidade: "São José do Belmonte",
      nm_cidade_uf: "São José do Belmonte - PE"
  },
  {
      id_cidade: "3338",
      id_estado: "16",
      nm_cidade: "São José do Egito",
      nm_cidade_uf: "São José do Egito - PE"
  },
  {
      id_cidade: "3339",
      id_estado: "16",
      nm_cidade: "São Lourenço da Mata",
      nm_cidade_uf: "São Lourenço da Mata - PE"
  },
  {
      id_cidade: "3340",
      id_estado: "16",
      nm_cidade: "São Vicente Ferrer",
      nm_cidade_uf: "São Vicente Ferrer - PE"
  },
  {
      id_cidade: "3341",
      id_estado: "16",
      nm_cidade: "Serra Talhada",
      nm_cidade_uf: "Serra Talhada - PE"
  },
  {
      id_cidade: "3342",
      id_estado: "16",
      nm_cidade: "Serrita",
      nm_cidade_uf: "Serrita - PE"
  },
  {
      id_cidade: "3343",
      id_estado: "16",
      nm_cidade: "Sertânia",
      nm_cidade_uf: "Sertânia - PE"
  },
  {
      id_cidade: "3344",
      id_estado: "16",
      nm_cidade: "Sirinhaém",
      nm_cidade_uf: "Sirinhaém - PE"
  },
  {
      id_cidade: "3345",
      id_estado: "16",
      nm_cidade: "Solidão",
      nm_cidade_uf: "Solidão - PE"
  },
  {
      id_cidade: "3346",
      id_estado: "16",
      nm_cidade: "Surubim",
      nm_cidade_uf: "Surubim - PE"
  },
  {
      id_cidade: "3347",
      id_estado: "16",
      nm_cidade: "Tabira",
      nm_cidade_uf: "Tabira - PE"
  },
  {
      id_cidade: "3348",
      id_estado: "16",
      nm_cidade: "Tacaimbó",
      nm_cidade_uf: "Tacaimbó - PE"
  },
  {
      id_cidade: "3349",
      id_estado: "16",
      nm_cidade: "Tacaratu",
      nm_cidade_uf: "Tacaratu - PE"
  },
  {
      id_cidade: "3350",
      id_estado: "16",
      nm_cidade: "Tamandaré",
      nm_cidade_uf: "Tamandaré - PE"
  },
  {
      id_cidade: "3351",
      id_estado: "16",
      nm_cidade: "Taquaritinga do Norte",
      nm_cidade_uf: "Taquaritinga do Norte - PE"
  },
  {
      id_cidade: "3352",
      id_estado: "16",
      nm_cidade: "Terezinha",
      nm_cidade_uf: "Terezinha - PE"
  },
  {
      id_cidade: "3353",
      id_estado: "16",
      nm_cidade: "Terra Nova",
      nm_cidade_uf: "Terra Nova - PE"
  },
  {
      id_cidade: "3354",
      id_estado: "16",
      nm_cidade: "Timbaúba",
      nm_cidade_uf: "Timbaúba - PE"
  },
  {
      id_cidade: "3355",
      id_estado: "16",
      nm_cidade: "Toritama",
      nm_cidade_uf: "Toritama - PE"
  },
  {
      id_cidade: "3356",
      id_estado: "16",
      nm_cidade: "Tracunhaém",
      nm_cidade_uf: "Tracunhaém - PE"
  },
  {
      id_cidade: "3357",
      id_estado: "16",
      nm_cidade: "Trindade",
      nm_cidade_uf: "Trindade - PE"
  },
  {
      id_cidade: "3358",
      id_estado: "16",
      nm_cidade: "Triunfo",
      nm_cidade_uf: "Triunfo - PE"
  },
  {
      id_cidade: "3359",
      id_estado: "16",
      nm_cidade: "Tupanatinga",
      nm_cidade_uf: "Tupanatinga - PE"
  },
  {
      id_cidade: "3360",
      id_estado: "16",
      nm_cidade: "Tuparetama",
      nm_cidade_uf: "Tuparetama - PE"
  },
  {
      id_cidade: "3361",
      id_estado: "16",
      nm_cidade: "Venturosa",
      nm_cidade_uf: "Venturosa - PE"
  },
  {
      id_cidade: "3362",
      id_estado: "16",
      nm_cidade: "Verdejante",
      nm_cidade_uf: "Verdejante - PE"
  },
  {
      id_cidade: "3363",
      id_estado: "16",
      nm_cidade: "Vertente do Lério",
      nm_cidade_uf: "Vertente do Lério - PE"
  },
  {
      id_cidade: "3364",
      id_estado: "16",
      nm_cidade: "Vertentes",
      nm_cidade_uf: "Vertentes - PE"
  },
  {
      id_cidade: "3365",
      id_estado: "16",
      nm_cidade: "Vicência",
      nm_cidade_uf: "Vicência - PE"
  },
  {
      id_cidade: "3366",
      id_estado: "16",
      nm_cidade: "Vitória de Santo Antão",
      nm_cidade_uf: "Vitória de Santo Antão - PE"
  },
  {
      id_cidade: "3367",
      id_estado: "16",
      nm_cidade: "Xexéu",
      nm_cidade_uf: "Xexéu - PE"
  },
  {
      id_cidade: "3368",
      id_estado: "17",
      nm_cidade: "Acauã",
      nm_cidade_uf: "Acauã - PI"
  },
  {
      id_cidade: "3369",
      id_estado: "17",
      nm_cidade: "Agricolândia",
      nm_cidade_uf: "Agricolândia - PI"
  },
  {
      id_cidade: "3370",
      id_estado: "17",
      nm_cidade: "Água Branca",
      nm_cidade_uf: "Água Branca - PI"
  },
  {
      id_cidade: "3371",
      id_estado: "17",
      nm_cidade: "Alagoinha do Piauí",
      nm_cidade_uf: "Alagoinha do Piauí - PI"
  },
  {
      id_cidade: "3372",
      id_estado: "17",
      nm_cidade: "Alegrete do Piauí",
      nm_cidade_uf: "Alegrete do Piauí - PI"
  },
  {
      id_cidade: "3373",
      id_estado: "17",
      nm_cidade: "Alto Longá",
      nm_cidade_uf: "Alto Longá - PI"
  },
  {
      id_cidade: "3374",
      id_estado: "17",
      nm_cidade: "Altos",
      nm_cidade_uf: "Altos - PI"
  },
  {
      id_cidade: "3375",
      id_estado: "17",
      nm_cidade: "Alvorada do Gurguéia",
      nm_cidade_uf: "Alvorada do Gurguéia - PI"
  },
  {
      id_cidade: "3376",
      id_estado: "17",
      nm_cidade: "Amarante",
      nm_cidade_uf: "Amarante - PI"
  },
  {
      id_cidade: "3377",
      id_estado: "17",
      nm_cidade: "Angical do Piauí",
      nm_cidade_uf: "Angical do Piauí - PI"
  },
  {
      id_cidade: "3378",
      id_estado: "17",
      nm_cidade: "Anísio de Abreu",
      nm_cidade_uf: "Anísio de Abreu - PI"
  },
  {
      id_cidade: "3379",
      id_estado: "17",
      nm_cidade: "Antônio Almeida",
      nm_cidade_uf: "Antônio Almeida - PI"
  },
  {
      id_cidade: "3380",
      id_estado: "17",
      nm_cidade: "Aroazes",
      nm_cidade_uf: "Aroazes - PI"
  },
  {
      id_cidade: "3381",
      id_estado: "17",
      nm_cidade: "Aroeiras do Itaim",
      nm_cidade_uf: "Aroeiras do Itaim - PI"
  },
  {
      id_cidade: "3382",
      id_estado: "17",
      nm_cidade: "Arraial",
      nm_cidade_uf: "Arraial - PI"
  },
  {
      id_cidade: "3383",
      id_estado: "17",
      nm_cidade: "Assunção do Piauí",
      nm_cidade_uf: "Assunção do Piauí - PI"
  },
  {
      id_cidade: "3384",
      id_estado: "17",
      nm_cidade: "Avelino Lopes",
      nm_cidade_uf: "Avelino Lopes - PI"
  },
  {
      id_cidade: "3385",
      id_estado: "17",
      nm_cidade: "Baixa Grande do Ribeiro",
      nm_cidade_uf: "Baixa Grande do Ribeiro - PI"
  },
  {
      id_cidade: "3386",
      id_estado: "17",
      nm_cidade: "Barra d`Alcântara",
      nm_cidade_uf: "Barra d`Alcântara - PI"
  },
  {
      id_cidade: "3387",
      id_estado: "17",
      nm_cidade: "Barras",
      nm_cidade_uf: "Barras - PI"
  },
  {
      id_cidade: "3388",
      id_estado: "17",
      nm_cidade: "Barreiras do Piauí",
      nm_cidade_uf: "Barreiras do Piauí - PI"
  },
  {
      id_cidade: "3389",
      id_estado: "17",
      nm_cidade: "Barro Duro",
      nm_cidade_uf: "Barro Duro - PI"
  },
  {
      id_cidade: "3390",
      id_estado: "17",
      nm_cidade: "Batalha",
      nm_cidade_uf: "Batalha - PI"
  },
  {
      id_cidade: "3391",
      id_estado: "17",
      nm_cidade: "Bela Vista do Piauí",
      nm_cidade_uf: "Bela Vista do Piauí - PI"
  },
  {
      id_cidade: "3392",
      id_estado: "17",
      nm_cidade: "Belém do Piauí",
      nm_cidade_uf: "Belém do Piauí - PI"
  },
  {
      id_cidade: "3393",
      id_estado: "17",
      nm_cidade: "Beneditinos",
      nm_cidade_uf: "Beneditinos - PI"
  },
  {
      id_cidade: "3394",
      id_estado: "17",
      nm_cidade: "Bertolínia",
      nm_cidade_uf: "Bertolínia - PI"
  },
  {
      id_cidade: "3395",
      id_estado: "17",
      nm_cidade: "Betânia do Piauí",
      nm_cidade_uf: "Betânia do Piauí - PI"
  },
  {
      id_cidade: "3396",
      id_estado: "17",
      nm_cidade: "Boa Hora",
      nm_cidade_uf: "Boa Hora - PI"
  },
  {
      id_cidade: "3397",
      id_estado: "17",
      nm_cidade: "Bocaina",
      nm_cidade_uf: "Bocaina - PI"
  },
  {
      id_cidade: "3398",
      id_estado: "17",
      nm_cidade: "Bom Jesus",
      nm_cidade_uf: "Bom Jesus - PI"
  },
  {
      id_cidade: "3399",
      id_estado: "17",
      nm_cidade: "Bom Princípio do Piauí",
      nm_cidade_uf: "Bom Princípio do Piauí - PI"
  },
  {
      id_cidade: "3400",
      id_estado: "17",
      nm_cidade: "Bonfim do Piauí",
      nm_cidade_uf: "Bonfim do Piauí - PI"
  },
  {
      id_cidade: "3401",
      id_estado: "17",
      nm_cidade: "Boqueirão do Piauí",
      nm_cidade_uf: "Boqueirão do Piauí - PI"
  },
  {
      id_cidade: "3402",
      id_estado: "17",
      nm_cidade: "Brasileira",
      nm_cidade_uf: "Brasileira - PI"
  },
  {
      id_cidade: "3403",
      id_estado: "17",
      nm_cidade: "Brejo do Piauí",
      nm_cidade_uf: "Brejo do Piauí - PI"
  },
  {
      id_cidade: "3404",
      id_estado: "17",
      nm_cidade: "Buriti dos Lopes",
      nm_cidade_uf: "Buriti dos Lopes - PI"
  },
  {
      id_cidade: "3405",
      id_estado: "17",
      nm_cidade: "Buriti dos Montes",
      nm_cidade_uf: "Buriti dos Montes - PI"
  },
  {
      id_cidade: "3406",
      id_estado: "17",
      nm_cidade: "Cabeceiras do Piauí",
      nm_cidade_uf: "Cabeceiras do Piauí - PI"
  },
  {
      id_cidade: "3407",
      id_estado: "17",
      nm_cidade: "Cajazeiras do Piauí",
      nm_cidade_uf: "Cajazeiras do Piauí - PI"
  },
  {
      id_cidade: "3408",
      id_estado: "17",
      nm_cidade: "Cajueiro da Praia",
      nm_cidade_uf: "Cajueiro da Praia - PI"
  },
  {
      id_cidade: "3409",
      id_estado: "17",
      nm_cidade: "Caldeirão Grande do Piauí",
      nm_cidade_uf: "Caldeirão Grande do Piauí - PI"
  },
  {
      id_cidade: "3410",
      id_estado: "17",
      nm_cidade: "Campinas do Piauí",
      nm_cidade_uf: "Campinas do Piauí - PI"
  },
  {
      id_cidade: "3411",
      id_estado: "17",
      nm_cidade: "Campo Alegre do Fidalgo",
      nm_cidade_uf: "Campo Alegre do Fidalgo - PI"
  },
  {
      id_cidade: "3412",
      id_estado: "17",
      nm_cidade: "Campo Grande do Piauí",
      nm_cidade_uf: "Campo Grande do Piauí - PI"
  },
  {
      id_cidade: "3413",
      id_estado: "17",
      nm_cidade: "Campo Largo do Piauí",
      nm_cidade_uf: "Campo Largo do Piauí - PI"
  },
  {
      id_cidade: "3414",
      id_estado: "17",
      nm_cidade: "Campo Maior",
      nm_cidade_uf: "Campo Maior - PI"
  },
  {
      id_cidade: "3415",
      id_estado: "17",
      nm_cidade: "Canavieira",
      nm_cidade_uf: "Canavieira - PI"
  },
  {
      id_cidade: "3416",
      id_estado: "17",
      nm_cidade: "Canto do Buriti",
      nm_cidade_uf: "Canto do Buriti - PI"
  },
  {
      id_cidade: "3417",
      id_estado: "17",
      nm_cidade: "Capitão de Campos",
      nm_cidade_uf: "Capitão de Campos - PI"
  },
  {
      id_cidade: "3418",
      id_estado: "17",
      nm_cidade: "Capitão Gervásio Oliveira",
      nm_cidade_uf: "Capitão Gervásio Oliveira - PI"
  },
  {
      id_cidade: "3419",
      id_estado: "17",
      nm_cidade: "Caracol",
      nm_cidade_uf: "Caracol - PI"
  },
  {
      id_cidade: "3420",
      id_estado: "17",
      nm_cidade: "Caraúbas do Piauí",
      nm_cidade_uf: "Caraúbas do Piauí - PI"
  },
  {
      id_cidade: "3421",
      id_estado: "17",
      nm_cidade: "Caridade do Piauí",
      nm_cidade_uf: "Caridade do Piauí - PI"
  },
  {
      id_cidade: "3422",
      id_estado: "17",
      nm_cidade: "Castelo do Piauí",
      nm_cidade_uf: "Castelo do Piauí - PI"
  },
  {
      id_cidade: "3423",
      id_estado: "17",
      nm_cidade: "Caxingó",
      nm_cidade_uf: "Caxingó - PI"
  },
  {
      id_cidade: "3424",
      id_estado: "17",
      nm_cidade: "Cocal",
      nm_cidade_uf: "Cocal - PI"
  },
  {
      id_cidade: "3425",
      id_estado: "17",
      nm_cidade: "Cocal de Telha",
      nm_cidade_uf: "Cocal de Telha - PI"
  },
  {
      id_cidade: "3426",
      id_estado: "17",
      nm_cidade: "Cocal dos Alves",
      nm_cidade_uf: "Cocal dos Alves - PI"
  },
  {
      id_cidade: "3427",
      id_estado: "17",
      nm_cidade: "Coivaras",
      nm_cidade_uf: "Coivaras - PI"
  },
  {
      id_cidade: "3428",
      id_estado: "17",
      nm_cidade: "Colônia do Gurguéia",
      nm_cidade_uf: "Colônia do Gurguéia - PI"
  },
  {
      id_cidade: "3429",
      id_estado: "17",
      nm_cidade: "Colônia do Piauí",
      nm_cidade_uf: "Colônia do Piauí - PI"
  },
  {
      id_cidade: "3430",
      id_estado: "17",
      nm_cidade: "Conceição do Canindé",
      nm_cidade_uf: "Conceição do Canindé - PI"
  },
  {
      id_cidade: "3431",
      id_estado: "17",
      nm_cidade: "Coronel José Dias",
      nm_cidade_uf: "Coronel José Dias - PI"
  },
  {
      id_cidade: "3432",
      id_estado: "17",
      nm_cidade: "Corrente",
      nm_cidade_uf: "Corrente - PI"
  },
  {
      id_cidade: "3433",
      id_estado: "17",
      nm_cidade: "Cristalândia do Piauí",
      nm_cidade_uf: "Cristalândia do Piauí - PI"
  },
  {
      id_cidade: "3434",
      id_estado: "17",
      nm_cidade: "Cristino Castro",
      nm_cidade_uf: "Cristino Castro - PI"
  },
  {
      id_cidade: "3435",
      id_estado: "17",
      nm_cidade: "Curimatá",
      nm_cidade_uf: "Curimatá - PI"
  },
  {
      id_cidade: "3436",
      id_estado: "17",
      nm_cidade: "Currais",
      nm_cidade_uf: "Currais - PI"
  },
  {
      id_cidade: "3437",
      id_estado: "17",
      nm_cidade: "Curral Novo do Piauí",
      nm_cidade_uf: "Curral Novo do Piauí - PI"
  },
  {
      id_cidade: "3438",
      id_estado: "17",
      nm_cidade: "Curralinhos",
      nm_cidade_uf: "Curralinhos - PI"
  },
  {
      id_cidade: "3439",
      id_estado: "17",
      nm_cidade: "Demerval Lobão",
      nm_cidade_uf: "Demerval Lobão - PI"
  },
  {
      id_cidade: "3440",
      id_estado: "17",
      nm_cidade: "Dirceu Arcoverde",
      nm_cidade_uf: "Dirceu Arcoverde - PI"
  },
  {
      id_cidade: "3441",
      id_estado: "17",
      nm_cidade: "Dom Expedito Lopes",
      nm_cidade_uf: "Dom Expedito Lopes - PI"
  },
  {
      id_cidade: "3442",
      id_estado: "17",
      nm_cidade: "Dom Inocêncio",
      nm_cidade_uf: "Dom Inocêncio - PI"
  },
  {
      id_cidade: "3443",
      id_estado: "17",
      nm_cidade: "Domingos Mourão",
      nm_cidade_uf: "Domingos Mourão - PI"
  },
  {
      id_cidade: "3444",
      id_estado: "17",
      nm_cidade: "Elesbão Veloso",
      nm_cidade_uf: "Elesbão Veloso - PI"
  },
  {
      id_cidade: "3445",
      id_estado: "17",
      nm_cidade: "Eliseu Martins",
      nm_cidade_uf: "Eliseu Martins - PI"
  },
  {
      id_cidade: "3446",
      id_estado: "17",
      nm_cidade: "Esperantina",
      nm_cidade_uf: "Esperantina - PI"
  },
  {
      id_cidade: "3447",
      id_estado: "17",
      nm_cidade: "Fartura do Piauí",
      nm_cidade_uf: "Fartura do Piauí - PI"
  },
  {
      id_cidade: "3448",
      id_estado: "17",
      nm_cidade: "Flores do Piauí",
      nm_cidade_uf: "Flores do Piauí - PI"
  },
  {
      id_cidade: "3449",
      id_estado: "17",
      nm_cidade: "Floresta do Piauí",
      nm_cidade_uf: "Floresta do Piauí - PI"
  },
  {
      id_cidade: "3450",
      id_estado: "17",
      nm_cidade: "Floriano",
      nm_cidade_uf: "Floriano - PI"
  },
  {
      id_cidade: "3451",
      id_estado: "17",
      nm_cidade: "Francinópolis",
      nm_cidade_uf: "Francinópolis - PI"
  },
  {
      id_cidade: "3452",
      id_estado: "17",
      nm_cidade: "Francisco Ayres",
      nm_cidade_uf: "Francisco Ayres - PI"
  },
  {
      id_cidade: "3453",
      id_estado: "17",
      nm_cidade: "Francisco Macedo",
      nm_cidade_uf: "Francisco Macedo - PI"
  },
  {
      id_cidade: "3454",
      id_estado: "17",
      nm_cidade: "Francisco Santos",
      nm_cidade_uf: "Francisco Santos - PI"
  },
  {
      id_cidade: "3455",
      id_estado: "17",
      nm_cidade: "Fronteiras",
      nm_cidade_uf: "Fronteiras - PI"
  },
  {
      id_cidade: "3456",
      id_estado: "17",
      nm_cidade: "Geminiano",
      nm_cidade_uf: "Geminiano - PI"
  },
  {
      id_cidade: "3457",
      id_estado: "17",
      nm_cidade: "Gilbués",
      nm_cidade_uf: "Gilbués - PI"
  },
  {
      id_cidade: "3458",
      id_estado: "17",
      nm_cidade: "Guadalupe",
      nm_cidade_uf: "Guadalupe - PI"
  },
  {
      id_cidade: "3459",
      id_estado: "17",
      nm_cidade: "Guaribas",
      nm_cidade_uf: "Guaribas - PI"
  },
  {
      id_cidade: "3460",
      id_estado: "17",
      nm_cidade: "Hugo Napoleão",
      nm_cidade_uf: "Hugo Napoleão - PI"
  },
  {
      id_cidade: "3461",
      id_estado: "17",
      nm_cidade: "Ilha Grande",
      nm_cidade_uf: "Ilha Grande - PI"
  },
  {
      id_cidade: "3462",
      id_estado: "17",
      nm_cidade: "Inhuma",
      nm_cidade_uf: "Inhuma - PI"
  },
  {
      id_cidade: "3463",
      id_estado: "17",
      nm_cidade: "Ipiranga do Piauí",
      nm_cidade_uf: "Ipiranga do Piauí - PI"
  },
  {
      id_cidade: "3464",
      id_estado: "17",
      nm_cidade: "Isaías Coelho",
      nm_cidade_uf: "Isaías Coelho - PI"
  },
  {
      id_cidade: "3465",
      id_estado: "17",
      nm_cidade: "Itainópolis",
      nm_cidade_uf: "Itainópolis - PI"
  },
  {
      id_cidade: "3466",
      id_estado: "17",
      nm_cidade: "Itaueira",
      nm_cidade_uf: "Itaueira - PI"
  },
  {
      id_cidade: "3467",
      id_estado: "17",
      nm_cidade: "Jacobina do Piauí",
      nm_cidade_uf: "Jacobina do Piauí - PI"
  },
  {
      id_cidade: "3468",
      id_estado: "17",
      nm_cidade: "Jaicós",
      nm_cidade_uf: "Jaicós - PI"
  },
  {
      id_cidade: "3469",
      id_estado: "17",
      nm_cidade: "Jardim do Mulato",
      nm_cidade_uf: "Jardim do Mulato - PI"
  },
  {
      id_cidade: "3470",
      id_estado: "17",
      nm_cidade: "Jatobá do Piauí",
      nm_cidade_uf: "Jatobá do Piauí - PI"
  },
  {
      id_cidade: "3471",
      id_estado: "17",
      nm_cidade: "Jerumenha",
      nm_cidade_uf: "Jerumenha - PI"
  },
  {
      id_cidade: "3472",
      id_estado: "17",
      nm_cidade: "João Costa",
      nm_cidade_uf: "João Costa - PI"
  },
  {
      id_cidade: "3473",
      id_estado: "17",
      nm_cidade: "Joaquim Pires",
      nm_cidade_uf: "Joaquim Pires - PI"
  },
  {
      id_cidade: "3474",
      id_estado: "17",
      nm_cidade: "Joca Marques",
      nm_cidade_uf: "Joca Marques - PI"
  },
  {
      id_cidade: "3475",
      id_estado: "17",
      nm_cidade: "José de Freitas",
      nm_cidade_uf: "José de Freitas - PI"
  },
  {
      id_cidade: "3476",
      id_estado: "17",
      nm_cidade: "Juazeiro do Piauí",
      nm_cidade_uf: "Juazeiro do Piauí - PI"
  },
  {
      id_cidade: "3477",
      id_estado: "17",
      nm_cidade: "Júlio Borges",
      nm_cidade_uf: "Júlio Borges - PI"
  },
  {
      id_cidade: "3478",
      id_estado: "17",
      nm_cidade: "Jurema",
      nm_cidade_uf: "Jurema - PI"
  },
  {
      id_cidade: "3479",
      id_estado: "17",
      nm_cidade: "Lagoa Alegre",
      nm_cidade_uf: "Lagoa Alegre - PI"
  },
  {
      id_cidade: "3480",
      id_estado: "17",
      nm_cidade: "Lagoa de São Francisco",
      nm_cidade_uf: "Lagoa de São Francisco - PI"
  },
  {
      id_cidade: "3481",
      id_estado: "17",
      nm_cidade: "Lagoa do Barro do Piauí",
      nm_cidade_uf: "Lagoa do Barro do Piauí - PI"
  },
  {
      id_cidade: "3482",
      id_estado: "17",
      nm_cidade: "Lagoa do Piauí",
      nm_cidade_uf: "Lagoa do Piauí - PI"
  },
  {
      id_cidade: "3483",
      id_estado: "17",
      nm_cidade: "Lagoa do Sítio",
      nm_cidade_uf: "Lagoa do Sítio - PI"
  },
  {
      id_cidade: "3484",
      id_estado: "17",
      nm_cidade: "Lagoinha do Piauí",
      nm_cidade_uf: "Lagoinha do Piauí - PI"
  },
  {
      id_cidade: "3485",
      id_estado: "17",
      nm_cidade: "Landri Sales",
      nm_cidade_uf: "Landri Sales - PI"
  },
  {
      id_cidade: "3486",
      id_estado: "17",
      nm_cidade: "Luís Correia",
      nm_cidade_uf: "Luís Correia - PI"
  },
  {
      id_cidade: "3487",
      id_estado: "17",
      nm_cidade: "Luzilândia",
      nm_cidade_uf: "Luzilândia - PI"
  },
  {
      id_cidade: "3488",
      id_estado: "17",
      nm_cidade: "Madeiro",
      nm_cidade_uf: "Madeiro - PI"
  },
  {
      id_cidade: "3489",
      id_estado: "17",
      nm_cidade: "Manoel Emídio",
      nm_cidade_uf: "Manoel Emídio - PI"
  },
  {
      id_cidade: "3490",
      id_estado: "17",
      nm_cidade: "Marcolândia",
      nm_cidade_uf: "Marcolândia - PI"
  },
  {
      id_cidade: "3491",
      id_estado: "17",
      nm_cidade: "Marcos Parente",
      nm_cidade_uf: "Marcos Parente - PI"
  },
  {
      id_cidade: "3492",
      id_estado: "17",
      nm_cidade: "Massapê do Piauí",
      nm_cidade_uf: "Massapê do Piauí - PI"
  },
  {
      id_cidade: "3493",
      id_estado: "17",
      nm_cidade: "Matias Olímpio",
      nm_cidade_uf: "Matias Olímpio - PI"
  },
  {
      id_cidade: "3494",
      id_estado: "17",
      nm_cidade: "Miguel Alves",
      nm_cidade_uf: "Miguel Alves - PI"
  },
  {
      id_cidade: "3495",
      id_estado: "17",
      nm_cidade: "Miguel Leão",
      nm_cidade_uf: "Miguel Leão - PI"
  },
  {
      id_cidade: "3496",
      id_estado: "17",
      nm_cidade: "Milton Brandão",
      nm_cidade_uf: "Milton Brandão - PI"
  },
  {
      id_cidade: "3497",
      id_estado: "17",
      nm_cidade: "Monsenhor Gil",
      nm_cidade_uf: "Monsenhor Gil - PI"
  },
  {
      id_cidade: "3498",
      id_estado: "17",
      nm_cidade: "Monsenhor Hipólito",
      nm_cidade_uf: "Monsenhor Hipólito - PI"
  },
  {
      id_cidade: "3499",
      id_estado: "17",
      nm_cidade: "Monte Alegre do Piauí",
      nm_cidade_uf: "Monte Alegre do Piauí - PI"
  },
  {
      id_cidade: "3500",
      id_estado: "17",
      nm_cidade: "Morro Cabeça no Tempo",
      nm_cidade_uf: "Morro Cabeça no Tempo - PI"
  },
  {
      id_cidade: "3501",
      id_estado: "17",
      nm_cidade: "Morro do Chapéu do Piauí",
      nm_cidade_uf: "Morro do Chapéu do Piauí - PI"
  },
  {
      id_cidade: "3502",
      id_estado: "17",
      nm_cidade: "Murici dos Portelas",
      nm_cidade_uf: "Murici dos Portelas - PI"
  },
  {
      id_cidade: "3503",
      id_estado: "17",
      nm_cidade: "Nazaré do Piauí",
      nm_cidade_uf: "Nazaré do Piauí - PI"
  },
  {
      id_cidade: "3504",
      id_estado: "17",
      nm_cidade: "Nossa Senhora de Nazaré",
      nm_cidade_uf: "Nossa Senhora de Nazaré - PI"
  },
  {
      id_cidade: "3505",
      id_estado: "17",
      nm_cidade: "Nossa Senhora dos Remédios",
      nm_cidade_uf: "Nossa Senhora dos Remédios - PI"
  },
  {
      id_cidade: "3506",
      id_estado: "17",
      nm_cidade: "Nova Santa Rita",
      nm_cidade_uf: "Nova Santa Rita - PI"
  },
  {
      id_cidade: "3507",
      id_estado: "17",
      nm_cidade: "Novo Oriente do Piauí",
      nm_cidade_uf: "Novo Oriente do Piauí - PI"
  },
  {
      id_cidade: "3508",
      id_estado: "17",
      nm_cidade: "Novo Santo Antônio",
      nm_cidade_uf: "Novo Santo Antônio - PI"
  },
  {
      id_cidade: "3509",
      id_estado: "17",
      nm_cidade: "Oeiras",
      nm_cidade_uf: "Oeiras - PI"
  },
  {
      id_cidade: "3510",
      id_estado: "17",
      nm_cidade: "Olho d`Água do Piauí",
      nm_cidade_uf: "Olho d`Água do Piauí - PI"
  },
  {
      id_cidade: "3511",
      id_estado: "17",
      nm_cidade: "Padre Marcos",
      nm_cidade_uf: "Padre Marcos - PI"
  },
  {
      id_cidade: "3512",
      id_estado: "17",
      nm_cidade: "Paes Landim",
      nm_cidade_uf: "Paes Landim - PI"
  },
  {
      id_cidade: "3513",
      id_estado: "17",
      nm_cidade: "Pajeú do Piauí",
      nm_cidade_uf: "Pajeú do Piauí - PI"
  },
  {
      id_cidade: "3514",
      id_estado: "17",
      nm_cidade: "Palmeira do Piauí",
      nm_cidade_uf: "Palmeira do Piauí - PI"
  },
  {
      id_cidade: "3515",
      id_estado: "17",
      nm_cidade: "Palmeirais",
      nm_cidade_uf: "Palmeirais - PI"
  },
  {
      id_cidade: "3516",
      id_estado: "17",
      nm_cidade: "Paquetá",
      nm_cidade_uf: "Paquetá - PI"
  },
  {
      id_cidade: "3517",
      id_estado: "17",
      nm_cidade: "Parnaguá",
      nm_cidade_uf: "Parnaguá - PI"
  },
  {
      id_cidade: "3518",
      id_estado: "17",
      nm_cidade: "Parnaíba",
      nm_cidade_uf: "Parnaíba - PI"
  },
  {
      id_cidade: "3519",
      id_estado: "17",
      nm_cidade: "Passagem Franca do Piauí",
      nm_cidade_uf: "Passagem Franca do Piauí - PI"
  },
  {
      id_cidade: "3520",
      id_estado: "17",
      nm_cidade: "Patos do Piauí",
      nm_cidade_uf: "Patos do Piauí - PI"
  },
  {
      id_cidade: "3521",
      id_estado: "17",
      nm_cidade: "Pau d`Arco do Piauí",
      nm_cidade_uf: "Pau d`Arco do Piauí - PI"
  },
  {
      id_cidade: "3522",
      id_estado: "17",
      nm_cidade: "Paulistana",
      nm_cidade_uf: "Paulistana - PI"
  },
  {
      id_cidade: "3523",
      id_estado: "17",
      nm_cidade: "Pavussu",
      nm_cidade_uf: "Pavussu - PI"
  },
  {
      id_cidade: "3524",
      id_estado: "17",
      nm_cidade: "Pedro II",
      nm_cidade_uf: "Pedro II - PI"
  },
  {
      id_cidade: "3525",
      id_estado: "17",
      nm_cidade: "Pedro Laurentino",
      nm_cidade_uf: "Pedro Laurentino - PI"
  },
  {
      id_cidade: "3526",
      id_estado: "17",
      nm_cidade: "Picos",
      nm_cidade_uf: "Picos - PI"
  },
  {
      id_cidade: "3527",
      id_estado: "17",
      nm_cidade: "Pimenteiras",
      nm_cidade_uf: "Pimenteiras - PI"
  },
  {
      id_cidade: "3528",
      id_estado: "17",
      nm_cidade: "Pio IX",
      nm_cidade_uf: "Pio IX - PI"
  },
  {
      id_cidade: "3529",
      id_estado: "17",
      nm_cidade: "Piracuruca",
      nm_cidade_uf: "Piracuruca - PI"
  },
  {
      id_cidade: "3530",
      id_estado: "17",
      nm_cidade: "Piripiri",
      nm_cidade_uf: "Piripiri - PI"
  },
  {
      id_cidade: "3531",
      id_estado: "17",
      nm_cidade: "Porto",
      nm_cidade_uf: "Porto - PI"
  },
  {
      id_cidade: "3532",
      id_estado: "17",
      nm_cidade: "Porto Alegre do Piauí",
      nm_cidade_uf: "Porto Alegre do Piauí - PI"
  },
  {
      id_cidade: "3533",
      id_estado: "17",
      nm_cidade: "Prata do Piauí",
      nm_cidade_uf: "Prata do Piauí - PI"
  },
  {
      id_cidade: "3534",
      id_estado: "17",
      nm_cidade: "Queimada Nova",
      nm_cidade_uf: "Queimada Nova - PI"
  },
  {
      id_cidade: "3535",
      id_estado: "17",
      nm_cidade: "Redenção do Gurguéia",
      nm_cidade_uf: "Redenção do Gurguéia - PI"
  },
  {
      id_cidade: "3536",
      id_estado: "17",
      nm_cidade: "Regeneração",
      nm_cidade_uf: "Regeneração - PI"
  },
  {
      id_cidade: "3537",
      id_estado: "17",
      nm_cidade: "Riacho Frio",
      nm_cidade_uf: "Riacho Frio - PI"
  },
  {
      id_cidade: "3538",
      id_estado: "17",
      nm_cidade: "Ribeira do Piauí",
      nm_cidade_uf: "Ribeira do Piauí - PI"
  },
  {
      id_cidade: "3539",
      id_estado: "17",
      nm_cidade: "Ribeiro Gonçalves",
      nm_cidade_uf: "Ribeiro Gonçalves - PI"
  },
  {
      id_cidade: "3540",
      id_estado: "17",
      nm_cidade: "Rio Grande do Piauí",
      nm_cidade_uf: "Rio Grande do Piauí - PI"
  },
  {
      id_cidade: "3541",
      id_estado: "17",
      nm_cidade: "Santa Cruz do Piauí",
      nm_cidade_uf: "Santa Cruz do Piauí - PI"
  },
  {
      id_cidade: "3542",
      id_estado: "17",
      nm_cidade: "Santa Cruz dos Milagres",
      nm_cidade_uf: "Santa Cruz dos Milagres - PI"
  },
  {
      id_cidade: "3543",
      id_estado: "17",
      nm_cidade: "Santa Filomena",
      nm_cidade_uf: "Santa Filomena - PI"
  },
  {
      id_cidade: "3544",
      id_estado: "17",
      nm_cidade: "Santa Luz",
      nm_cidade_uf: "Santa Luz - PI"
  },
  {
      id_cidade: "3545",
      id_estado: "17",
      nm_cidade: "Santa Rosa do Piauí",
      nm_cidade_uf: "Santa Rosa do Piauí - PI"
  },
  {
      id_cidade: "3546",
      id_estado: "17",
      nm_cidade: "Santana do Piauí",
      nm_cidade_uf: "Santana do Piauí - PI"
  },
  {
      id_cidade: "3547",
      id_estado: "17",
      nm_cidade: "Santo Antônio de Lisboa",
      nm_cidade_uf: "Santo Antônio de Lisboa - PI"
  },
  {
      id_cidade: "3548",
      id_estado: "17",
      nm_cidade: "Santo Antônio dos Milagres",
      nm_cidade_uf: "Santo Antônio dos Milagres - PI"
  },
  {
      id_cidade: "3549",
      id_estado: "17",
      nm_cidade: "Santo Inácio do Piauí",
      nm_cidade_uf: "Santo Inácio do Piauí - PI"
  },
  {
      id_cidade: "3550",
      id_estado: "17",
      nm_cidade: "São Braz do Piauí",
      nm_cidade_uf: "São Braz do Piauí - PI"
  },
  {
      id_cidade: "3551",
      id_estado: "17",
      nm_cidade: "São Félix do Piauí",
      nm_cidade_uf: "São Félix do Piauí - PI"
  },
  {
      id_cidade: "3552",
      id_estado: "17",
      nm_cidade: "São Francisco de Assis do Piauí",
      nm_cidade_uf: "São Francisco de Assis do Piauí - PI"
  },
  {
      id_cidade: "3553",
      id_estado: "17",
      nm_cidade: "São Francisco do Piauí",
      nm_cidade_uf: "São Francisco do Piauí - PI"
  },
  {
      id_cidade: "3554",
      id_estado: "17",
      nm_cidade: "São Gonçalo do Gurguéia",
      nm_cidade_uf: "São Gonçalo do Gurguéia - PI"
  },
  {
      id_cidade: "3555",
      id_estado: "17",
      nm_cidade: "São Gonçalo do Piauí",
      nm_cidade_uf: "São Gonçalo do Piauí - PI"
  },
  {
      id_cidade: "3556",
      id_estado: "17",
      nm_cidade: "São João da Canabrava",
      nm_cidade_uf: "São João da Canabrava - PI"
  },
  {
      id_cidade: "3557",
      id_estado: "17",
      nm_cidade: "São João da Fronteira",
      nm_cidade_uf: "São João da Fronteira - PI"
  },
  {
      id_cidade: "3558",
      id_estado: "17",
      nm_cidade: "São João da Serra",
      nm_cidade_uf: "São João da Serra - PI"
  },
  {
      id_cidade: "3559",
      id_estado: "17",
      nm_cidade: "São João da Varjota",
      nm_cidade_uf: "São João da Varjota - PI"
  },
  {
      id_cidade: "3560",
      id_estado: "17",
      nm_cidade: "São João do Arraial",
      nm_cidade_uf: "São João do Arraial - PI"
  },
  {
      id_cidade: "3561",
      id_estado: "17",
      nm_cidade: "São João do Piauí",
      nm_cidade_uf: "São João do Piauí - PI"
  },
  {
      id_cidade: "3562",
      id_estado: "17",
      nm_cidade: "São José do Divino",
      nm_cidade_uf: "São José do Divino - PI"
  },
  {
      id_cidade: "3563",
      id_estado: "17",
      nm_cidade: "São José do Peixe",
      nm_cidade_uf: "São José do Peixe - PI"
  },
  {
      id_cidade: "3564",
      id_estado: "17",
      nm_cidade: "São José do Piauí",
      nm_cidade_uf: "São José do Piauí - PI"
  },
  {
      id_cidade: "3565",
      id_estado: "17",
      nm_cidade: "São Julião",
      nm_cidade_uf: "São Julião - PI"
  },
  {
      id_cidade: "3566",
      id_estado: "17",
      nm_cidade: "São Lourenço do Piauí",
      nm_cidade_uf: "São Lourenço do Piauí - PI"
  },
  {
      id_cidade: "3567",
      id_estado: "17",
      nm_cidade: "São Luis do Piauí",
      nm_cidade_uf: "São Luis do Piauí - PI"
  },
  {
      id_cidade: "3568",
      id_estado: "17",
      nm_cidade: "São Miguel da Baixa Grande",
      nm_cidade_uf: "São Miguel da Baixa Grande - PI"
  },
  {
      id_cidade: "3569",
      id_estado: "17",
      nm_cidade: "São Miguel do Fidalgo",
      nm_cidade_uf: "São Miguel do Fidalgo - PI"
  },
  {
      id_cidade: "3570",
      id_estado: "17",
      nm_cidade: "São Miguel do Tapuio",
      nm_cidade_uf: "São Miguel do Tapuio - PI"
  },
  {
      id_cidade: "3571",
      id_estado: "17",
      nm_cidade: "São Pedro do Piauí",
      nm_cidade_uf: "São Pedro do Piauí - PI"
  },
  {
      id_cidade: "3572",
      id_estado: "17",
      nm_cidade: "São Raimundo Nonato",
      nm_cidade_uf: "São Raimundo Nonato - PI"
  },
  {
      id_cidade: "3573",
      id_estado: "17",
      nm_cidade: "Sebastião Barros",
      nm_cidade_uf: "Sebastião Barros - PI"
  },
  {
      id_cidade: "3574",
      id_estado: "17",
      nm_cidade: "Sebastião Leal",
      nm_cidade_uf: "Sebastião Leal - PI"
  },
  {
      id_cidade: "3575",
      id_estado: "17",
      nm_cidade: "Sigefredo Pacheco",
      nm_cidade_uf: "Sigefredo Pacheco - PI"
  },
  {
      id_cidade: "3576",
      id_estado: "17",
      nm_cidade: "Simões",
      nm_cidade_uf: "Simões - PI"
  },
  {
      id_cidade: "3577",
      id_estado: "17",
      nm_cidade: "Simplício Mendes",
      nm_cidade_uf: "Simplício Mendes - PI"
  },
  {
      id_cidade: "3578",
      id_estado: "17",
      nm_cidade: "Socorro do Piauí",
      nm_cidade_uf: "Socorro do Piauí - PI"
  },
  {
      id_cidade: "3579",
      id_estado: "17",
      nm_cidade: "Sussuapara",
      nm_cidade_uf: "Sussuapara - PI"
  },
  {
      id_cidade: "3580",
      id_estado: "17",
      nm_cidade: "Tamboril do Piauí",
      nm_cidade_uf: "Tamboril do Piauí - PI"
  },
  {
      id_cidade: "3581",
      id_estado: "17",
      nm_cidade: "Tanque do Piauí",
      nm_cidade_uf: "Tanque do Piauí - PI"
  },
  {
      id_cidade: "3582",
      id_estado: "17",
      nm_cidade: "Teresina",
      nm_cidade_uf: "Teresina - PI"
  },
  {
      id_cidade: "3583",
      id_estado: "17",
      nm_cidade: "União",
      nm_cidade_uf: "União - PI"
  },
  {
      id_cidade: "3584",
      id_estado: "17",
      nm_cidade: "Uruçuí",
      nm_cidade_uf: "Uruçuí - PI"
  },
  {
      id_cidade: "3585",
      id_estado: "17",
      nm_cidade: "Valença do Piauí",
      nm_cidade_uf: "Valença do Piauí - PI"
  },
  {
      id_cidade: "3586",
      id_estado: "17",
      nm_cidade: "Várzea Branca",
      nm_cidade_uf: "Várzea Branca - PI"
  },
  {
      id_cidade: "3587",
      id_estado: "17",
      nm_cidade: "Várzea Grande",
      nm_cidade_uf: "Várzea Grande - PI"
  },
  {
      id_cidade: "3588",
      id_estado: "17",
      nm_cidade: "Vera Mendes",
      nm_cidade_uf: "Vera Mendes - PI"
  },
  {
      id_cidade: "3589",
      id_estado: "17",
      nm_cidade: "Vila Nova do Piauí",
      nm_cidade_uf: "Vila Nova do Piauí - PI"
  },
  {
      id_cidade: "3590",
      id_estado: "17",
      nm_cidade: "Wall Ferraz",
      nm_cidade_uf: "Wall Ferraz - PI"
  },
  {
      id_cidade: "3591",
      id_estado: "19",
      nm_cidade: "Angra dos Reis",
      nm_cidade_uf: "Angra dos Reis - RJ"
  },
  {
      id_cidade: "3592",
      id_estado: "19",
      nm_cidade: "Aperibé",
      nm_cidade_uf: "Aperibé - RJ"
  },
  {
      id_cidade: "3593",
      id_estado: "19",
      nm_cidade: "Araruama",
      nm_cidade_uf: "Araruama - RJ"
  },
  {
      id_cidade: "3594",
      id_estado: "19",
      nm_cidade: "Areal",
      nm_cidade_uf: "Areal - RJ"
  },
  {
      id_cidade: "3595",
      id_estado: "19",
      nm_cidade: "Armação dos Búzios",
      nm_cidade_uf: "Armação dos Búzios - RJ"
  },
  {
      id_cidade: "3596",
      id_estado: "19",
      nm_cidade: "Arraial do Cabo",
      nm_cidade_uf: "Arraial do Cabo - RJ"
  },
  {
      id_cidade: "3597",
      id_estado: "19",
      nm_cidade: "Barra do Piraí",
      nm_cidade_uf: "Barra do Piraí - RJ"
  },
  {
      id_cidade: "3598",
      id_estado: "19",
      nm_cidade: "Barra Mansa",
      nm_cidade_uf: "Barra Mansa - RJ"
  },
  {
      id_cidade: "3599",
      id_estado: "19",
      nm_cidade: "Belford Roxo",
      nm_cidade_uf: "Belford Roxo - RJ"
  },
  {
      id_cidade: "3600",
      id_estado: "19",
      nm_cidade: "Bom Jardim",
      nm_cidade_uf: "Bom Jardim - RJ"
  },
  {
      id_cidade: "3601",
      id_estado: "19",
      nm_cidade: "Bom Jesus do Itabapoana",
      nm_cidade_uf: "Bom Jesus do Itabapoana - RJ"
  },
  {
      id_cidade: "3602",
      id_estado: "19",
      nm_cidade: "Cabo Frio",
      nm_cidade_uf: "Cabo Frio - RJ"
  },
  {
      id_cidade: "3603",
      id_estado: "19",
      nm_cidade: "Cachoeiras de Macacu",
      nm_cidade_uf: "Cachoeiras de Macacu - RJ"
  },
  {
      id_cidade: "3604",
      id_estado: "19",
      nm_cidade: "Cambuci",
      nm_cidade_uf: "Cambuci - RJ"
  },
  {
      id_cidade: "3605",
      id_estado: "19",
      nm_cidade: "Campos dos Goytacazes",
      nm_cidade_uf: "Campos dos Goytacazes - RJ"
  },
  {
      id_cidade: "3606",
      id_estado: "19",
      nm_cidade: "Cantagalo",
      nm_cidade_uf: "Cantagalo - RJ"
  },
  {
      id_cidade: "3607",
      id_estado: "19",
      nm_cidade: "Carapebus",
      nm_cidade_uf: "Carapebus - RJ"
  },
  {
      id_cidade: "3608",
      id_estado: "19",
      nm_cidade: "Cardoso Moreira",
      nm_cidade_uf: "Cardoso Moreira - RJ"
  },
  {
      id_cidade: "3609",
      id_estado: "19",
      nm_cidade: "Carmo",
      nm_cidade_uf: "Carmo - RJ"
  },
  {
      id_cidade: "3610",
      id_estado: "19",
      nm_cidade: "Casimiro de Abreu",
      nm_cidade_uf: "Casimiro de Abreu - RJ"
  },
  {
      id_cidade: "3611",
      id_estado: "19",
      nm_cidade: "Comendador Levy Gasparian",
      nm_cidade_uf: "Comendador Levy Gasparian - RJ"
  },
  {
      id_cidade: "3612",
      id_estado: "19",
      nm_cidade: "Conceição de Macabu",
      nm_cidade_uf: "Conceição de Macabu - RJ"
  },
  {
      id_cidade: "3613",
      id_estado: "19",
      nm_cidade: "Cordeiro",
      nm_cidade_uf: "Cordeiro - RJ"
  },
  {
      id_cidade: "3614",
      id_estado: "19",
      nm_cidade: "Duas Barras",
      nm_cidade_uf: "Duas Barras - RJ"
  },
  {
      id_cidade: "3615",
      id_estado: "19",
      nm_cidade: "Duque de Caxias",
      nm_cidade_uf: "Duque de Caxias - RJ"
  },
  {
      id_cidade: "3616",
      id_estado: "19",
      nm_cidade: "Engenheiro Paulo de Frontin",
      nm_cidade_uf: "Engenheiro Paulo de Frontin - RJ"
  },
  {
      id_cidade: "3617",
      id_estado: "19",
      nm_cidade: "Guapimirim",
      nm_cidade_uf: "Guapimirim - RJ"
  },
  {
      id_cidade: "3618",
      id_estado: "19",
      nm_cidade: "Iguaba Grande",
      nm_cidade_uf: "Iguaba Grande - RJ"
  },
  {
      id_cidade: "3619",
      id_estado: "19",
      nm_cidade: "Itaboraí",
      nm_cidade_uf: "Itaboraí - RJ"
  },
  {
      id_cidade: "3620",
      id_estado: "19",
      nm_cidade: "Itaguaí",
      nm_cidade_uf: "Itaguaí - RJ"
  },
  {
      id_cidade: "3621",
      id_estado: "19",
      nm_cidade: "Italva",
      nm_cidade_uf: "Italva - RJ"
  },
  {
      id_cidade: "3622",
      id_estado: "19",
      nm_cidade: "Itaocara",
      nm_cidade_uf: "Itaocara - RJ"
  },
  {
      id_cidade: "3623",
      id_estado: "19",
      nm_cidade: "Itaperuna",
      nm_cidade_uf: "Itaperuna - RJ"
  },
  {
      id_cidade: "3624",
      id_estado: "19",
      nm_cidade: "Itatiaia",
      nm_cidade_uf: "Itatiaia - RJ"
  },
  {
      id_cidade: "3625",
      id_estado: "19",
      nm_cidade: "Japeri",
      nm_cidade_uf: "Japeri - RJ"
  },
  {
      id_cidade: "3626",
      id_estado: "19",
      nm_cidade: "Laje do Muriaé",
      nm_cidade_uf: "Laje do Muriaé - RJ"
  },
  {
      id_cidade: "3627",
      id_estado: "19",
      nm_cidade: "Macaé",
      nm_cidade_uf: "Macaé - RJ"
  },
  {
      id_cidade: "3628",
      id_estado: "19",
      nm_cidade: "Macuco",
      nm_cidade_uf: "Macuco - RJ"
  },
  {
      id_cidade: "3629",
      id_estado: "19",
      nm_cidade: "Magé",
      nm_cidade_uf: "Magé - RJ"
  },
  {
      id_cidade: "3630",
      id_estado: "19",
      nm_cidade: "Mangaratiba",
      nm_cidade_uf: "Mangaratiba - RJ"
  },
  {
      id_cidade: "3631",
      id_estado: "19",
      nm_cidade: "Maricá",
      nm_cidade_uf: "Maricá - RJ"
  },
  {
      id_cidade: "3632",
      id_estado: "19",
      nm_cidade: "Mendes",
      nm_cidade_uf: "Mendes - RJ"
  },
  {
      id_cidade: "3633",
      id_estado: "19",
      nm_cidade: "Mesquita",
      nm_cidade_uf: "Mesquita - RJ"
  },
  {
      id_cidade: "3634",
      id_estado: "19",
      nm_cidade: "Miguel Pereira",
      nm_cidade_uf: "Miguel Pereira - RJ"
  },
  {
      id_cidade: "3635",
      id_estado: "19",
      nm_cidade: "Miracema",
      nm_cidade_uf: "Miracema - RJ"
  },
  {
      id_cidade: "3636",
      id_estado: "19",
      nm_cidade: "Natividade",
      nm_cidade_uf: "Natividade - RJ"
  },
  {
      id_cidade: "3637",
      id_estado: "19",
      nm_cidade: "Nilópolis",
      nm_cidade_uf: "Nilópolis - RJ"
  },
  {
      id_cidade: "3638",
      id_estado: "19",
      nm_cidade: "Niterói",
      nm_cidade_uf: "Niterói - RJ"
  },
  {
      id_cidade: "3639",
      id_estado: "19",
      nm_cidade: "Nova Friburgo",
      nm_cidade_uf: "Nova Friburgo - RJ"
  },
  {
      id_cidade: "3640",
      id_estado: "19",
      nm_cidade: "Nova Iguaçu",
      nm_cidade_uf: "Nova Iguaçu - RJ"
  },
  {
      id_cidade: "3641",
      id_estado: "19",
      nm_cidade: "Paracambi",
      nm_cidade_uf: "Paracambi - RJ"
  },
  {
      id_cidade: "3642",
      id_estado: "19",
      nm_cidade: "Paraíba do Sul",
      nm_cidade_uf: "Paraíba do Sul - RJ"
  },
  {
      id_cidade: "3643",
      id_estado: "19",
      nm_cidade: "Parati",
      nm_cidade_uf: "Parati - RJ"
  },
  {
      id_cidade: "3644",
      id_estado: "19",
      nm_cidade: "Paty do Alferes",
      nm_cidade_uf: "Paty do Alferes - RJ"
  },
  {
      id_cidade: "3645",
      id_estado: "19",
      nm_cidade: "Petrópolis",
      nm_cidade_uf: "Petrópolis - RJ"
  },
  {
      id_cidade: "3646",
      id_estado: "19",
      nm_cidade: "Pinheiral",
      nm_cidade_uf: "Pinheiral - RJ"
  },
  {
      id_cidade: "3647",
      id_estado: "19",
      nm_cidade: "Piraí",
      nm_cidade_uf: "Piraí - RJ"
  },
  {
      id_cidade: "3648",
      id_estado: "19",
      nm_cidade: "Porciúncula",
      nm_cidade_uf: "Porciúncula - RJ"
  },
  {
      id_cidade: "3649",
      id_estado: "19",
      nm_cidade: "Porto Real",
      nm_cidade_uf: "Porto Real - RJ"
  },
  {
      id_cidade: "3650",
      id_estado: "19",
      nm_cidade: "Quatis",
      nm_cidade_uf: "Quatis - RJ"
  },
  {
      id_cidade: "3651",
      id_estado: "19",
      nm_cidade: "Queimados",
      nm_cidade_uf: "Queimados - RJ"
  },
  {
      id_cidade: "3652",
      id_estado: "19",
      nm_cidade: "Quissamã",
      nm_cidade_uf: "Quissamã - RJ"
  },
  {
      id_cidade: "3653",
      id_estado: "19",
      nm_cidade: "Resende",
      nm_cidade_uf: "Resende - RJ"
  },
  {
      id_cidade: "3654",
      id_estado: "19",
      nm_cidade: "Rio Bonito",
      nm_cidade_uf: "Rio Bonito - RJ"
  },
  {
      id_cidade: "3655",
      id_estado: "19",
      nm_cidade: "Rio Claro",
      nm_cidade_uf: "Rio Claro - RJ"
  },
  {
      id_cidade: "3656",
      id_estado: "19",
      nm_cidade: "Rio das Flores",
      nm_cidade_uf: "Rio das Flores - RJ"
  },
  {
      id_cidade: "3657",
      id_estado: "19",
      nm_cidade: "Rio das Ostras",
      nm_cidade_uf: "Rio das Ostras - RJ"
  },
  {
      id_cidade: "3658",
      id_estado: "19",
      nm_cidade: "Rio de Janeiro",
      nm_cidade_uf: "Rio de Janeiro - RJ"
  },
  {
      id_cidade: "3659",
      id_estado: "19",
      nm_cidade: "Santa Maria Madalena",
      nm_cidade_uf: "Santa Maria Madalena - RJ"
  },
  {
      id_cidade: "3660",
      id_estado: "19",
      nm_cidade: "Santo Antônio de Pádua",
      nm_cidade_uf: "Santo Antônio de Pádua - RJ"
  },
  {
      id_cidade: "3661",
      id_estado: "19",
      nm_cidade: "São Fidélis",
      nm_cidade_uf: "São Fidélis - RJ"
  },
  {
      id_cidade: "3662",
      id_estado: "19",
      nm_cidade: "São Francisco de Itabapoana",
      nm_cidade_uf: "São Francisco de Itabapoana - RJ"
  },
  {
      id_cidade: "3663",
      id_estado: "19",
      nm_cidade: "São Gonçalo",
      nm_cidade_uf: "São Gonçalo - RJ"
  },
  {
      id_cidade: "3664",
      id_estado: "19",
      nm_cidade: "São João da Barra",
      nm_cidade_uf: "São João da Barra - RJ"
  },
  {
      id_cidade: "3665",
      id_estado: "19",
      nm_cidade: "São João de Meriti",
      nm_cidade_uf: "São João de Meriti - RJ"
  },
  {
      id_cidade: "3666",
      id_estado: "19",
      nm_cidade: "São José de Ubá",
      nm_cidade_uf: "São José de Ubá - RJ"
  },
  {
      id_cidade: "3667",
      id_estado: "19",
      nm_cidade: "São José do Vale do Rio Preto",
      nm_cidade_uf: "São José do Vale do Rio Preto - RJ"
  },
  {
      id_cidade: "3668",
      id_estado: "19",
      nm_cidade: "São Pedro da Aldeia",
      nm_cidade_uf: "São Pedro da Aldeia - RJ"
  },
  {
      id_cidade: "3669",
      id_estado: "19",
      nm_cidade: "São Sebastião do Alto",
      nm_cidade_uf: "São Sebastião do Alto - RJ"
  },
  {
      id_cidade: "3670",
      id_estado: "19",
      nm_cidade: "Sapucaia",
      nm_cidade_uf: "Sapucaia - RJ"
  },
  {
      id_cidade: "3671",
      id_estado: "19",
      nm_cidade: "Saquarema",
      nm_cidade_uf: "Saquarema - RJ"
  },
  {
      id_cidade: "3672",
      id_estado: "19",
      nm_cidade: "Seropédica",
      nm_cidade_uf: "Seropédica - RJ"
  },
  {
      id_cidade: "3673",
      id_estado: "19",
      nm_cidade: "Silva Jardim",
      nm_cidade_uf: "Silva Jardim - RJ"
  },
  {
      id_cidade: "3674",
      id_estado: "19",
      nm_cidade: "Sumidouro",
      nm_cidade_uf: "Sumidouro - RJ"
  },
  {
      id_cidade: "3675",
      id_estado: "19",
      nm_cidade: "Tanguá",
      nm_cidade_uf: "Tanguá - RJ"
  },
  {
      id_cidade: "3676",
      id_estado: "19",
      nm_cidade: "Teresópolis",
      nm_cidade_uf: "Teresópolis - RJ"
  },
  {
      id_cidade: "3677",
      id_estado: "19",
      nm_cidade: "Trajano de Morais",
      nm_cidade_uf: "Trajano de Morais - RJ"
  },
  {
      id_cidade: "3678",
      id_estado: "19",
      nm_cidade: "Três Rios",
      nm_cidade_uf: "Três Rios - RJ"
  },
  {
      id_cidade: "3679",
      id_estado: "19",
      nm_cidade: "Valença",
      nm_cidade_uf: "Valença - RJ"
  },
  {
      id_cidade: "3680",
      id_estado: "19",
      nm_cidade: "Varre-Sai",
      nm_cidade_uf: "Varre-Sai - RJ"
  },
  {
      id_cidade: "3681",
      id_estado: "19",
      nm_cidade: "Vassouras",
      nm_cidade_uf: "Vassouras - RJ"
  },
  {
      id_cidade: "3682",
      id_estado: "19",
      nm_cidade: "Volta Redonda",
      nm_cidade_uf: "Volta Redonda - RJ"
  },
  {
      id_cidade: "3683",
      id_estado: "20",
      nm_cidade: "Acari",
      nm_cidade_uf: "Acari - RN"
  },
  {
      id_cidade: "3684",
      id_estado: "20",
      nm_cidade: "Açu",
      nm_cidade_uf: "Açu - RN"
  },
  {
      id_cidade: "3685",
      id_estado: "20",
      nm_cidade: "Afonso Bezerra",
      nm_cidade_uf: "Afonso Bezerra - RN"
  },
  {
      id_cidade: "3686",
      id_estado: "20",
      nm_cidade: "Água Nova",
      nm_cidade_uf: "Água Nova - RN"
  },
  {
      id_cidade: "3687",
      id_estado: "20",
      nm_cidade: "Alexandria",
      nm_cidade_uf: "Alexandria - RN"
  },
  {
      id_cidade: "3688",
      id_estado: "20",
      nm_cidade: "Almino Afonso",
      nm_cidade_uf: "Almino Afonso - RN"
  },
  {
      id_cidade: "3689",
      id_estado: "20",
      nm_cidade: "Alto do Rodrigues",
      nm_cidade_uf: "Alto do Rodrigues - RN"
  },
  {
      id_cidade: "3690",
      id_estado: "20",
      nm_cidade: "Angicos",
      nm_cidade_uf: "Angicos - RN"
  },
  {
      id_cidade: "3691",
      id_estado: "20",
      nm_cidade: "Antônio Martins",
      nm_cidade_uf: "Antônio Martins - RN"
  },
  {
      id_cidade: "3692",
      id_estado: "20",
      nm_cidade: "Apodi",
      nm_cidade_uf: "Apodi - RN"
  },
  {
      id_cidade: "3693",
      id_estado: "20",
      nm_cidade: "Areia Branca",
      nm_cidade_uf: "Areia Branca - RN"
  },
  {
      id_cidade: "3694",
      id_estado: "20",
      nm_cidade: "Arês",
      nm_cidade_uf: "Arês - RN"
  },
  {
      id_cidade: "3695",
      id_estado: "20",
      nm_cidade: "Augusto Severo",
      nm_cidade_uf: "Augusto Severo - RN"
  },
  {
      id_cidade: "3696",
      id_estado: "20",
      nm_cidade: "Baía Formosa",
      nm_cidade_uf: "Baía Formosa - RN"
  },
  {
      id_cidade: "3697",
      id_estado: "20",
      nm_cidade: "Baraúna",
      nm_cidade_uf: "Baraúna - RN"
  },
  {
      id_cidade: "3698",
      id_estado: "20",
      nm_cidade: "Barcelona",
      nm_cidade_uf: "Barcelona - RN"
  },
  {
      id_cidade: "3699",
      id_estado: "20",
      nm_cidade: "Bento Fernandes",
      nm_cidade_uf: "Bento Fernandes - RN"
  },
  {
      id_cidade: "3700",
      id_estado: "20",
      nm_cidade: "Bodó",
      nm_cidade_uf: "Bodó - RN"
  },
  {
      id_cidade: "3701",
      id_estado: "20",
      nm_cidade: "Bom Jesus",
      nm_cidade_uf: "Bom Jesus - RN"
  },
  {
      id_cidade: "3702",
      id_estado: "20",
      nm_cidade: "Brejinho",
      nm_cidade_uf: "Brejinho - RN"
  },
  {
      id_cidade: "3703",
      id_estado: "20",
      nm_cidade: "Caiçara do Norte",
      nm_cidade_uf: "Caiçara do Norte - RN"
  },
  {
      id_cidade: "3704",
      id_estado: "20",
      nm_cidade: "Caiçara do Rio do Vento",
      nm_cidade_uf: "Caiçara do Rio do Vento - RN"
  },
  {
      id_cidade: "3705",
      id_estado: "20",
      nm_cidade: "Caicó",
      nm_cidade_uf: "Caicó - RN"
  },
  {
      id_cidade: "3706",
      id_estado: "20",
      nm_cidade: "Campo Redondo",
      nm_cidade_uf: "Campo Redondo - RN"
  },
  {
      id_cidade: "3707",
      id_estado: "20",
      nm_cidade: "Canguaretama",
      nm_cidade_uf: "Canguaretama - RN"
  },
  {
      id_cidade: "3708",
      id_estado: "20",
      nm_cidade: "Caraúbas",
      nm_cidade_uf: "Caraúbas - RN"
  },
  {
      id_cidade: "3709",
      id_estado: "20",
      nm_cidade: "Carnaúba dos Dantas",
      nm_cidade_uf: "Carnaúba dos Dantas - RN"
  },
  {
      id_cidade: "3710",
      id_estado: "20",
      nm_cidade: "Carnaubais",
      nm_cidade_uf: "Carnaubais - RN"
  },
  {
      id_cidade: "3711",
      id_estado: "20",
      nm_cidade: "Ceará-Mirim",
      nm_cidade_uf: "Ceará-Mirim - RN"
  },
  {
      id_cidade: "3712",
      id_estado: "20",
      nm_cidade: "Cerro Corá",
      nm_cidade_uf: "Cerro Corá - RN"
  },
  {
      id_cidade: "3713",
      id_estado: "20",
      nm_cidade: "Coronel Ezequiel",
      nm_cidade_uf: "Coronel Ezequiel - RN"
  },
  {
      id_cidade: "3714",
      id_estado: "20",
      nm_cidade: "Coronel João Pessoa",
      nm_cidade_uf: "Coronel João Pessoa - RN"
  },
  {
      id_cidade: "3715",
      id_estado: "20",
      nm_cidade: "Cruzeta",
      nm_cidade_uf: "Cruzeta - RN"
  },
  {
      id_cidade: "3716",
      id_estado: "20",
      nm_cidade: "Currais Novos",
      nm_cidade_uf: "Currais Novos - RN"
  },
  {
      id_cidade: "3717",
      id_estado: "20",
      nm_cidade: "Doutor Severiano",
      nm_cidade_uf: "Doutor Severiano - RN"
  },
  {
      id_cidade: "3718",
      id_estado: "20",
      nm_cidade: "Encanto",
      nm_cidade_uf: "Encanto - RN"
  },
  {
      id_cidade: "3719",
      id_estado: "20",
      nm_cidade: "Equador",
      nm_cidade_uf: "Equador - RN"
  },
  {
      id_cidade: "3720",
      id_estado: "20",
      nm_cidade: "Espírito Santo",
      nm_cidade_uf: "Espírito Santo - RN"
  },
  {
      id_cidade: "3721",
      id_estado: "20",
      nm_cidade: "Extremoz",
      nm_cidade_uf: "Extremoz - RN"
  },
  {
      id_cidade: "3722",
      id_estado: "20",
      nm_cidade: "Felipe Guerra",
      nm_cidade_uf: "Felipe Guerra - RN"
  },
  {
      id_cidade: "3723",
      id_estado: "20",
      nm_cidade: "Fernando Pedroza",
      nm_cidade_uf: "Fernando Pedroza - RN"
  },
  {
      id_cidade: "3724",
      id_estado: "20",
      nm_cidade: "Florânia",
      nm_cidade_uf: "Florânia - RN"
  },
  {
      id_cidade: "3725",
      id_estado: "20",
      nm_cidade: "Francisco Dantas",
      nm_cidade_uf: "Francisco Dantas - RN"
  },
  {
      id_cidade: "3726",
      id_estado: "20",
      nm_cidade: "Frutuoso Gomes",
      nm_cidade_uf: "Frutuoso Gomes - RN"
  },
  {
      id_cidade: "3727",
      id_estado: "20",
      nm_cidade: "Galinhos",
      nm_cidade_uf: "Galinhos - RN"
  },
  {
      id_cidade: "3728",
      id_estado: "20",
      nm_cidade: "Goianinha",
      nm_cidade_uf: "Goianinha - RN"
  },
  {
      id_cidade: "3729",
      id_estado: "20",
      nm_cidade: "Governador Dix-Sept Rosado",
      nm_cidade_uf: "Governador Dix-Sept Rosado - RN"
  },
  {
      id_cidade: "3730",
      id_estado: "20",
      nm_cidade: "Grossos",
      nm_cidade_uf: "Grossos - RN"
  },
  {
      id_cidade: "3731",
      id_estado: "20",
      nm_cidade: "Guamaré",
      nm_cidade_uf: "Guamaré - RN"
  },
  {
      id_cidade: "3732",
      id_estado: "20",
      nm_cidade: "Ielmo Marinho",
      nm_cidade_uf: "Ielmo Marinho - RN"
  },
  {
      id_cidade: "3733",
      id_estado: "20",
      nm_cidade: "Ipanguaçu",
      nm_cidade_uf: "Ipanguaçu - RN"
  },
  {
      id_cidade: "3734",
      id_estado: "20",
      nm_cidade: "Ipueira",
      nm_cidade_uf: "Ipueira - RN"
  },
  {
      id_cidade: "3735",
      id_estado: "20",
      nm_cidade: "Itajá",
      nm_cidade_uf: "Itajá - RN"
  },
  {
      id_cidade: "3736",
      id_estado: "20",
      nm_cidade: "Itaú",
      nm_cidade_uf: "Itaú - RN"
  },
  {
      id_cidade: "3737",
      id_estado: "20",
      nm_cidade: "Jaçanã",
      nm_cidade_uf: "Jaçanã - RN"
  },
  {
      id_cidade: "3738",
      id_estado: "20",
      nm_cidade: "Jandaíra",
      nm_cidade_uf: "Jandaíra - RN"
  },
  {
      id_cidade: "3739",
      id_estado: "20",
      nm_cidade: "Janduís",
      nm_cidade_uf: "Janduís - RN"
  },
  {
      id_cidade: "3740",
      id_estado: "20",
      nm_cidade: "Januário Cicco",
      nm_cidade_uf: "Januário Cicco - RN"
  },
  {
      id_cidade: "3741",
      id_estado: "20",
      nm_cidade: "Japi",
      nm_cidade_uf: "Japi - RN"
  },
  {
      id_cidade: "3742",
      id_estado: "20",
      nm_cidade: "Jardim de Angicos",
      nm_cidade_uf: "Jardim de Angicos - RN"
  },
  {
      id_cidade: "3743",
      id_estado: "20",
      nm_cidade: "Jardim de Piranhas",
      nm_cidade_uf: "Jardim de Piranhas - RN"
  },
  {
      id_cidade: "3744",
      id_estado: "20",
      nm_cidade: "Jardim do Seridó",
      nm_cidade_uf: "Jardim do Seridó - RN"
  },
  {
      id_cidade: "3745",
      id_estado: "20",
      nm_cidade: "João Câmara",
      nm_cidade_uf: "João Câmara - RN"
  },
  {
      id_cidade: "3746",
      id_estado: "20",
      nm_cidade: "João Dias",
      nm_cidade_uf: "João Dias - RN"
  },
  {
      id_cidade: "3747",
      id_estado: "20",
      nm_cidade: "José da Penha",
      nm_cidade_uf: "José da Penha - RN"
  },
  {
      id_cidade: "3748",
      id_estado: "20",
      nm_cidade: "Jucurutu",
      nm_cidade_uf: "Jucurutu - RN"
  },
  {
      id_cidade: "3749",
      id_estado: "20",
      nm_cidade: "Jundiá",
      nm_cidade_uf: "Jundiá - RN"
  },
  {
      id_cidade: "3750",
      id_estado: "20",
      nm_cidade: "Lagoa d`Anta",
      nm_cidade_uf: "Lagoa d`Anta - RN"
  },
  {
      id_cidade: "3751",
      id_estado: "20",
      nm_cidade: "Lagoa de Pedras",
      nm_cidade_uf: "Lagoa de Pedras - RN"
  },
  {
      id_cidade: "3752",
      id_estado: "20",
      nm_cidade: "Lagoa de Velhos",
      nm_cidade_uf: "Lagoa de Velhos - RN"
  },
  {
      id_cidade: "3753",
      id_estado: "20",
      nm_cidade: "Lagoa Nova",
      nm_cidade_uf: "Lagoa Nova - RN"
  },
  {
      id_cidade: "3754",
      id_estado: "20",
      nm_cidade: "Lagoa Salgada",
      nm_cidade_uf: "Lagoa Salgada - RN"
  },
  {
      id_cidade: "3755",
      id_estado: "20",
      nm_cidade: "Lajes",
      nm_cidade_uf: "Lajes - RN"
  },
  {
      id_cidade: "3756",
      id_estado: "20",
      nm_cidade: "Lajes Pintadas",
      nm_cidade_uf: "Lajes Pintadas - RN"
  },
  {
      id_cidade: "3757",
      id_estado: "20",
      nm_cidade: "Lucrécia",
      nm_cidade_uf: "Lucrécia - RN"
  },
  {
      id_cidade: "3758",
      id_estado: "20",
      nm_cidade: "Luís Gomes",
      nm_cidade_uf: "Luís Gomes - RN"
  },
  {
      id_cidade: "3759",
      id_estado: "20",
      nm_cidade: "Macaíba",
      nm_cidade_uf: "Macaíba - RN"
  },
  {
      id_cidade: "3760",
      id_estado: "20",
      nm_cidade: "Macau",
      nm_cidade_uf: "Macau - RN"
  },
  {
      id_cidade: "3761",
      id_estado: "20",
      nm_cidade: "Major Sales",
      nm_cidade_uf: "Major Sales - RN"
  },
  {
      id_cidade: "3762",
      id_estado: "20",
      nm_cidade: "Marcelino Vieira",
      nm_cidade_uf: "Marcelino Vieira - RN"
  },
  {
      id_cidade: "3763",
      id_estado: "20",
      nm_cidade: "Martins",
      nm_cidade_uf: "Martins - RN"
  },
  {
      id_cidade: "3764",
      id_estado: "20",
      nm_cidade: "Maxaranguape",
      nm_cidade_uf: "Maxaranguape - RN"
  },
  {
      id_cidade: "3765",
      id_estado: "20",
      nm_cidade: "Messias Targino",
      nm_cidade_uf: "Messias Targino - RN"
  },
  {
      id_cidade: "3766",
      id_estado: "20",
      nm_cidade: "Montanhas",
      nm_cidade_uf: "Montanhas - RN"
  },
  {
      id_cidade: "3767",
      id_estado: "20",
      nm_cidade: "Monte Alegre",
      nm_cidade_uf: "Monte Alegre - RN"
  },
  {
      id_cidade: "3768",
      id_estado: "20",
      nm_cidade: "Monte das Gameleiras",
      nm_cidade_uf: "Monte das Gameleiras - RN"
  },
  {
      id_cidade: "3769",
      id_estado: "20",
      nm_cidade: "Mossoró",
      nm_cidade_uf: "Mossoró - RN"
  },
  {
      id_cidade: "3770",
      id_estado: "20",
      nm_cidade: "Natal",
      nm_cidade_uf: "Natal - RN"
  },
  {
      id_cidade: "3771",
      id_estado: "20",
      nm_cidade: "Nísia Floresta",
      nm_cidade_uf: "Nísia Floresta - RN"
  },
  {
      id_cidade: "3772",
      id_estado: "20",
      nm_cidade: "Nova Cruz",
      nm_cidade_uf: "Nova Cruz - RN"
  },
  {
      id_cidade: "3773",
      id_estado: "20",
      nm_cidade: "Olho-d`Água do Borges",
      nm_cidade_uf: "Olho-d`Água do Borges - RN"
  },
  {
      id_cidade: "3774",
      id_estado: "20",
      nm_cidade: "Ouro Branco",
      nm_cidade_uf: "Ouro Branco - RN"
  },
  {
      id_cidade: "3775",
      id_estado: "20",
      nm_cidade: "Paraná",
      nm_cidade_uf: "Paraná - RN"
  },
  {
      id_cidade: "3776",
      id_estado: "20",
      nm_cidade: "Paraú",
      nm_cidade_uf: "Paraú - RN"
  },
  {
      id_cidade: "3777",
      id_estado: "20",
      nm_cidade: "Parazinho",
      nm_cidade_uf: "Parazinho - RN"
  },
  {
      id_cidade: "3778",
      id_estado: "20",
      nm_cidade: "Parelhas",
      nm_cidade_uf: "Parelhas - RN"
  },
  {
      id_cidade: "3779",
      id_estado: "20",
      nm_cidade: "Parnamirim",
      nm_cidade_uf: "Parnamirim - RN"
  },
  {
      id_cidade: "3780",
      id_estado: "20",
      nm_cidade: "Passa e Fica",
      nm_cidade_uf: "Passa e Fica - RN"
  },
  {
      id_cidade: "3781",
      id_estado: "20",
      nm_cidade: "Passagem",
      nm_cidade_uf: "Passagem - RN"
  },
  {
      id_cidade: "3782",
      id_estado: "20",
      nm_cidade: "Patu",
      nm_cidade_uf: "Patu - RN"
  },
  {
      id_cidade: "3783",
      id_estado: "20",
      nm_cidade: "Pau dos Ferros",
      nm_cidade_uf: "Pau dos Ferros - RN"
  },
  {
      id_cidade: "3784",
      id_estado: "20",
      nm_cidade: "Pedra Grande",
      nm_cidade_uf: "Pedra Grande - RN"
  },
  {
      id_cidade: "3785",
      id_estado: "20",
      nm_cidade: "Pedra Preta",
      nm_cidade_uf: "Pedra Preta - RN"
  },
  {
      id_cidade: "3786",
      id_estado: "20",
      nm_cidade: "Pedro Avelino",
      nm_cidade_uf: "Pedro Avelino - RN"
  },
  {
      id_cidade: "3787",
      id_estado: "20",
      nm_cidade: "Pedro Velho",
      nm_cidade_uf: "Pedro Velho - RN"
  },
  {
      id_cidade: "3788",
      id_estado: "20",
      nm_cidade: "Pendências",
      nm_cidade_uf: "Pendências - RN"
  },
  {
      id_cidade: "3789",
      id_estado: "20",
      nm_cidade: "Pilões",
      nm_cidade_uf: "Pilões - RN"
  },
  {
      id_cidade: "3790",
      id_estado: "20",
      nm_cidade: "Poço Branco",
      nm_cidade_uf: "Poço Branco - RN"
  },
  {
      id_cidade: "3791",
      id_estado: "20",
      nm_cidade: "Portalegre",
      nm_cidade_uf: "Portalegre - RN"
  },
  {
      id_cidade: "3792",
      id_estado: "20",
      nm_cidade: "Porto do Mangue",
      nm_cidade_uf: "Porto do Mangue - RN"
  },
  {
      id_cidade: "3793",
      id_estado: "20",
      nm_cidade: "Presidente Juscelino",
      nm_cidade_uf: "Presidente Juscelino - RN"
  },
  {
      id_cidade: "3794",
      id_estado: "20",
      nm_cidade: "Pureza",
      nm_cidade_uf: "Pureza - RN"
  },
  {
      id_cidade: "3795",
      id_estado: "20",
      nm_cidade: "Rafael Fernandes",
      nm_cidade_uf: "Rafael Fernandes - RN"
  },
  {
      id_cidade: "3796",
      id_estado: "20",
      nm_cidade: "Rafael Godeiro",
      nm_cidade_uf: "Rafael Godeiro - RN"
  },
  {
      id_cidade: "3797",
      id_estado: "20",
      nm_cidade: "Riacho da Cruz",
      nm_cidade_uf: "Riacho da Cruz - RN"
  },
  {
      id_cidade: "3798",
      id_estado: "20",
      nm_cidade: "Riacho de Santana",
      nm_cidade_uf: "Riacho de Santana - RN"
  },
  {
      id_cidade: "3799",
      id_estado: "20",
      nm_cidade: "Riachuelo",
      nm_cidade_uf: "Riachuelo - RN"
  },
  {
      id_cidade: "3800",
      id_estado: "20",
      nm_cidade: "Rio do Fogo",
      nm_cidade_uf: "Rio do Fogo - RN"
  },
  {
      id_cidade: "3801",
      id_estado: "20",
      nm_cidade: "Rodolfo Fernandes",
      nm_cidade_uf: "Rodolfo Fernandes - RN"
  },
  {
      id_cidade: "3802",
      id_estado: "20",
      nm_cidade: "Ruy Barbosa",
      nm_cidade_uf: "Ruy Barbosa - RN"
  },
  {
      id_cidade: "3803",
      id_estado: "20",
      nm_cidade: "Santa Cruz",
      nm_cidade_uf: "Santa Cruz - RN"
  },
  {
      id_cidade: "3804",
      id_estado: "20",
      nm_cidade: "Santa Maria",
      nm_cidade_uf: "Santa Maria - RN"
  },
  {
      id_cidade: "3805",
      id_estado: "20",
      nm_cidade: "Santana do Matos",
      nm_cidade_uf: "Santana do Matos - RN"
  },
  {
      id_cidade: "3806",
      id_estado: "20",
      nm_cidade: "Santana do Seridó",
      nm_cidade_uf: "Santana do Seridó - RN"
  },
  {
      id_cidade: "3807",
      id_estado: "20",
      nm_cidade: "Santo Antônio",
      nm_cidade_uf: "Santo Antônio - RN"
  },
  {
      id_cidade: "3808",
      id_estado: "20",
      nm_cidade: "São Bento do Norte",
      nm_cidade_uf: "São Bento do Norte - RN"
  },
  {
      id_cidade: "3809",
      id_estado: "20",
      nm_cidade: "São Bento do Trairí",
      nm_cidade_uf: "São Bento do Trairí - RN"
  },
  {
      id_cidade: "3810",
      id_estado: "20",
      nm_cidade: "São Fernando",
      nm_cidade_uf: "São Fernando - RN"
  },
  {
      id_cidade: "3811",
      id_estado: "20",
      nm_cidade: "São Francisco do Oeste",
      nm_cidade_uf: "São Francisco do Oeste - RN"
  },
  {
      id_cidade: "3812",
      id_estado: "20",
      nm_cidade: "São Gonçalo do Amarante",
      nm_cidade_uf: "São Gonçalo do Amarante - RN"
  },
  {
      id_cidade: "3813",
      id_estado: "20",
      nm_cidade: "São João do Sabugi",
      nm_cidade_uf: "São João do Sabugi - RN"
  },
  {
      id_cidade: "3814",
      id_estado: "20",
      nm_cidade: "São José de Mipibu",
      nm_cidade_uf: "São José de Mipibu - RN"
  },
  {
      id_cidade: "3815",
      id_estado: "20",
      nm_cidade: "São José do Campestre",
      nm_cidade_uf: "São José do Campestre - RN"
  },
  {
      id_cidade: "3816",
      id_estado: "20",
      nm_cidade: "São José do Seridó",
      nm_cidade_uf: "São José do Seridó - RN"
  },
  {
      id_cidade: "3817",
      id_estado: "20",
      nm_cidade: "São Miguel",
      nm_cidade_uf: "São Miguel - RN"
  },
  {
      id_cidade: "3818",
      id_estado: "20",
      nm_cidade: "São Miguel do Gostoso",
      nm_cidade_uf: "São Miguel do Gostoso - RN"
  },
  {
      id_cidade: "3819",
      id_estado: "20",
      nm_cidade: "São Paulo do Potengi",
      nm_cidade_uf: "São Paulo do Potengi - RN"
  },
  {
      id_cidade: "3820",
      id_estado: "20",
      nm_cidade: "São Pedro",
      nm_cidade_uf: "São Pedro - RN"
  },
  {
      id_cidade: "3821",
      id_estado: "20",
      nm_cidade: "São Rafael",
      nm_cidade_uf: "São Rafael - RN"
  },
  {
      id_cidade: "3822",
      id_estado: "20",
      nm_cidade: "São Tomé",
      nm_cidade_uf: "São Tomé - RN"
  },
  {
      id_cidade: "3823",
      id_estado: "20",
      nm_cidade: "São Vicente",
      nm_cidade_uf: "São Vicente - RN"
  },
  {
      id_cidade: "3824",
      id_estado: "20",
      nm_cidade: "Senador Elói de Souza",
      nm_cidade_uf: "Senador Elói de Souza - RN"
  },
  {
      id_cidade: "3825",
      id_estado: "20",
      nm_cidade: "Senador Georgino Avelino",
      nm_cidade_uf: "Senador Georgino Avelino - RN"
  },
  {
      id_cidade: "3826",
      id_estado: "20",
      nm_cidade: "Serra de São Bento",
      nm_cidade_uf: "Serra de São Bento - RN"
  },
  {
      id_cidade: "3827",
      id_estado: "20",
      nm_cidade: "Serra do Mel",
      nm_cidade_uf: "Serra do Mel - RN"
  },
  {
      id_cidade: "3828",
      id_estado: "20",
      nm_cidade: "Serra Negra do Norte",
      nm_cidade_uf: "Serra Negra do Norte - RN"
  },
  {
      id_cidade: "3829",
      id_estado: "20",
      nm_cidade: "Serrinha",
      nm_cidade_uf: "Serrinha - RN"
  },
  {
      id_cidade: "3830",
      id_estado: "20",
      nm_cidade: "Serrinha dos Pintos",
      nm_cidade_uf: "Serrinha dos Pintos - RN"
  },
  {
      id_cidade: "3831",
      id_estado: "20",
      nm_cidade: "Severiano Melo",
      nm_cidade_uf: "Severiano Melo - RN"
  },
  {
      id_cidade: "3832",
      id_estado: "20",
      nm_cidade: "Sítio Novo",
      nm_cidade_uf: "Sítio Novo - RN"
  },
  {
      id_cidade: "3833",
      id_estado: "20",
      nm_cidade: "Taboleiro Grande",
      nm_cidade_uf: "Taboleiro Grande - RN"
  },
  {
      id_cidade: "3834",
      id_estado: "20",
      nm_cidade: "Taipu",
      nm_cidade_uf: "Taipu - RN"
  },
  {
      id_cidade: "3835",
      id_estado: "20",
      nm_cidade: "Tangará",
      nm_cidade_uf: "Tangará - RN"
  },
  {
      id_cidade: "3836",
      id_estado: "20",
      nm_cidade: "Tenente Ananias",
      nm_cidade_uf: "Tenente Ananias - RN"
  },
  {
      id_cidade: "3837",
      id_estado: "20",
      nm_cidade: "Tenente Laurentino Cruz",
      nm_cidade_uf: "Tenente Laurentino Cruz - RN"
  },
  {
      id_cidade: "3838",
      id_estado: "20",
      nm_cidade: "Tibau",
      nm_cidade_uf: "Tibau - RN"
  },
  {
      id_cidade: "3839",
      id_estado: "20",
      nm_cidade: "Tibau do Sul",
      nm_cidade_uf: "Tibau do Sul - RN"
  },
  {
      id_cidade: "3840",
      id_estado: "20",
      nm_cidade: "Timbaúba dos Batistas",
      nm_cidade_uf: "Timbaúba dos Batistas - RN"
  },
  {
      id_cidade: "3841",
      id_estado: "20",
      nm_cidade: "Touros",
      nm_cidade_uf: "Touros - RN"
  },
  {
      id_cidade: "3842",
      id_estado: "20",
      nm_cidade: "Triunfo Potiguar",
      nm_cidade_uf: "Triunfo Potiguar - RN"
  },
  {
      id_cidade: "3843",
      id_estado: "20",
      nm_cidade: "Umarizal",
      nm_cidade_uf: "Umarizal - RN"
  },
  {
      id_cidade: "3844",
      id_estado: "20",
      nm_cidade: "Upanema",
      nm_cidade_uf: "Upanema - RN"
  },
  {
      id_cidade: "3845",
      id_estado: "20",
      nm_cidade: "Várzea",
      nm_cidade_uf: "Várzea - RN"
  },
  {
      id_cidade: "3846",
      id_estado: "20",
      nm_cidade: "Venha-Ver",
      nm_cidade_uf: "Venha-Ver - RN"
  },
  {
      id_cidade: "3847",
      id_estado: "20",
      nm_cidade: "Vera Cruz",
      nm_cidade_uf: "Vera Cruz - RN"
  },
  {
      id_cidade: "3848",
      id_estado: "20",
      nm_cidade: "Viçosa",
      nm_cidade_uf: "Viçosa - RN"
  },
  {
      id_cidade: "3849",
      id_estado: "20",
      nm_cidade: "Vila Flor",
      nm_cidade_uf: "Vila Flor - RN"
  },
  {
      id_cidade: "3850",
      id_estado: "23",
      nm_cidade: "Aceguá",
      nm_cidade_uf: "Aceguá - RS"
  },
  {
      id_cidade: "3851",
      id_estado: "23",
      nm_cidade: "Água Santa",
      nm_cidade_uf: "Água Santa - RS"
  },
  {
      id_cidade: "3852",
      id_estado: "23",
      nm_cidade: "Agudo",
      nm_cidade_uf: "Agudo - RS"
  },
  {
      id_cidade: "3853",
      id_estado: "23",
      nm_cidade: "Ajuricaba",
      nm_cidade_uf: "Ajuricaba - RS"
  },
  {
      id_cidade: "3854",
      id_estado: "23",
      nm_cidade: "Alecrim",
      nm_cidade_uf: "Alecrim - RS"
  },
  {
      id_cidade: "3855",
      id_estado: "23",
      nm_cidade: "Alegrete",
      nm_cidade_uf: "Alegrete - RS"
  },
  {
      id_cidade: "3856",
      id_estado: "23",
      nm_cidade: "Alegria",
      nm_cidade_uf: "Alegria - RS"
  },
  {
      id_cidade: "3857",
      id_estado: "23",
      nm_cidade: "Almirante Tamandaré do Sul",
      nm_cidade_uf: "Almirante Tamandaré do Sul - RS"
  },
  {
      id_cidade: "3858",
      id_estado: "23",
      nm_cidade: "Alpestre",
      nm_cidade_uf: "Alpestre - RS"
  },
  {
      id_cidade: "3859",
      id_estado: "23",
      nm_cidade: "Alto Alegre",
      nm_cidade_uf: "Alto Alegre - RS"
  },
  {
      id_cidade: "3860",
      id_estado: "23",
      nm_cidade: "Alto Feliz",
      nm_cidade_uf: "Alto Feliz - RS"
  },
  {
      id_cidade: "3861",
      id_estado: "23",
      nm_cidade: "Alvorada",
      nm_cidade_uf: "Alvorada - RS"
  },
  {
      id_cidade: "3862",
      id_estado: "23",
      nm_cidade: "Amaral Ferrador",
      nm_cidade_uf: "Amaral Ferrador - RS"
  },
  {
      id_cidade: "3863",
      id_estado: "23",
      nm_cidade: "Ametista do Sul",
      nm_cidade_uf: "Ametista do Sul - RS"
  },
  {
      id_cidade: "3864",
      id_estado: "23",
      nm_cidade: "André da Rocha",
      nm_cidade_uf: "André da Rocha - RS"
  },
  {
      id_cidade: "3865",
      id_estado: "23",
      nm_cidade: "Anta Gorda",
      nm_cidade_uf: "Anta Gorda - RS"
  },
  {
      id_cidade: "3866",
      id_estado: "23",
      nm_cidade: "Antônio Prado",
      nm_cidade_uf: "Antônio Prado - RS"
  },
  {
      id_cidade: "3867",
      id_estado: "23",
      nm_cidade: "Arambaré",
      nm_cidade_uf: "Arambaré - RS"
  },
  {
      id_cidade: "3868",
      id_estado: "23",
      nm_cidade: "Araricá",
      nm_cidade_uf: "Araricá - RS"
  },
  {
      id_cidade: "3869",
      id_estado: "23",
      nm_cidade: "Aratiba",
      nm_cidade_uf: "Aratiba - RS"
  },
  {
      id_cidade: "3870",
      id_estado: "23",
      nm_cidade: "Arroio do Meio",
      nm_cidade_uf: "Arroio do Meio - RS"
  },
  {
      id_cidade: "3871",
      id_estado: "23",
      nm_cidade: "Arroio do Padre",
      nm_cidade_uf: "Arroio do Padre - RS"
  },
  {
      id_cidade: "3872",
      id_estado: "23",
      nm_cidade: "Arroio do Sal",
      nm_cidade_uf: "Arroio do Sal - RS"
  },
  {
      id_cidade: "3873",
      id_estado: "23",
      nm_cidade: "Arroio do Tigre",
      nm_cidade_uf: "Arroio do Tigre - RS"
  },
  {
      id_cidade: "3874",
      id_estado: "23",
      nm_cidade: "Arroio dos Ratos",
      nm_cidade_uf: "Arroio dos Ratos - RS"
  },
  {
      id_cidade: "3875",
      id_estado: "23",
      nm_cidade: "Arroio Grande",
      nm_cidade_uf: "Arroio Grande - RS"
  },
  {
      id_cidade: "3876",
      id_estado: "23",
      nm_cidade: "Arvorezinha",
      nm_cidade_uf: "Arvorezinha - RS"
  },
  {
      id_cidade: "3877",
      id_estado: "23",
      nm_cidade: "Augusto Pestana",
      nm_cidade_uf: "Augusto Pestana - RS"
  },
  {
      id_cidade: "3878",
      id_estado: "23",
      nm_cidade: "Áurea",
      nm_cidade_uf: "Áurea - RS"
  },
  {
      id_cidade: "3879",
      id_estado: "23",
      nm_cidade: "Bagé",
      nm_cidade_uf: "Bagé - RS"
  },
  {
      id_cidade: "3880",
      id_estado: "23",
      nm_cidade: "Balneário Pinhal",
      nm_cidade_uf: "Balneário Pinhal - RS"
  },
  {
      id_cidade: "3881",
      id_estado: "23",
      nm_cidade: "Barão",
      nm_cidade_uf: "Barão - RS"
  },
  {
      id_cidade: "3882",
      id_estado: "23",
      nm_cidade: "Barão de Cotegipe",
      nm_cidade_uf: "Barão de Cotegipe - RS"
  },
  {
      id_cidade: "3883",
      id_estado: "23",
      nm_cidade: "Barão do Triunfo",
      nm_cidade_uf: "Barão do Triunfo - RS"
  },
  {
      id_cidade: "3884",
      id_estado: "23",
      nm_cidade: "Barra do Guarita",
      nm_cidade_uf: "Barra do Guarita - RS"
  },
  {
      id_cidade: "3885",
      id_estado: "23",
      nm_cidade: "Barra do Quaraí",
      nm_cidade_uf: "Barra do Quaraí - RS"
  },
  {
      id_cidade: "3886",
      id_estado: "23",
      nm_cidade: "Barra do Ribeiro",
      nm_cidade_uf: "Barra do Ribeiro - RS"
  },
  {
      id_cidade: "3887",
      id_estado: "23",
      nm_cidade: "Barra do Rio Azul",
      nm_cidade_uf: "Barra do Rio Azul - RS"
  },
  {
      id_cidade: "3888",
      id_estado: "23",
      nm_cidade: "Barra Funda",
      nm_cidade_uf: "Barra Funda - RS"
  },
  {
      id_cidade: "3889",
      id_estado: "23",
      nm_cidade: "Barracão",
      nm_cidade_uf: "Barracão - RS"
  },
  {
      id_cidade: "3890",
      id_estado: "23",
      nm_cidade: "Barros Cassal",
      nm_cidade_uf: "Barros Cassal - RS"
  },
  {
      id_cidade: "3891",
      id_estado: "23",
      nm_cidade: "Benjamin Constant do Sul",
      nm_cidade_uf: "Benjamin Constant do Sul - RS"
  },
  {
      id_cidade: "3892",
      id_estado: "23",
      nm_cidade: "Bento Gonçalves",
      nm_cidade_uf: "Bento Gonçalves - RS"
  },
  {
      id_cidade: "3893",
      id_estado: "23",
      nm_cidade: "Boa Vista das Missões",
      nm_cidade_uf: "Boa Vista das Missões - RS"
  },
  {
      id_cidade: "3894",
      id_estado: "23",
      nm_cidade: "Boa Vista do Buricá",
      nm_cidade_uf: "Boa Vista do Buricá - RS"
  },
  {
      id_cidade: "3895",
      id_estado: "23",
      nm_cidade: "Boa Vista do Cadeado",
      nm_cidade_uf: "Boa Vista do Cadeado - RS"
  },
  {
      id_cidade: "3896",
      id_estado: "23",
      nm_cidade: "Boa Vista do Incra",
      nm_cidade_uf: "Boa Vista do Incra - RS"
  },
  {
      id_cidade: "3897",
      id_estado: "23",
      nm_cidade: "Boa Vista do Sul",
      nm_cidade_uf: "Boa Vista do Sul - RS"
  },
  {
      id_cidade: "3898",
      id_estado: "23",
      nm_cidade: "Bom Jesus",
      nm_cidade_uf: "Bom Jesus - RS"
  },
  {
      id_cidade: "3899",
      id_estado: "23",
      nm_cidade: "Bom Princípio",
      nm_cidade_uf: "Bom Princípio - RS"
  },
  {
      id_cidade: "3900",
      id_estado: "23",
      nm_cidade: "Bom Progresso",
      nm_cidade_uf: "Bom Progresso - RS"
  },
  {
      id_cidade: "3901",
      id_estado: "23",
      nm_cidade: "Bom Retiro do Sul",
      nm_cidade_uf: "Bom Retiro do Sul - RS"
  },
  {
      id_cidade: "3902",
      id_estado: "23",
      nm_cidade: "Boqueirão do Leão",
      nm_cidade_uf: "Boqueirão do Leão - RS"
  },
  {
      id_cidade: "3903",
      id_estado: "23",
      nm_cidade: "Bossoroca",
      nm_cidade_uf: "Bossoroca - RS"
  },
  {
      id_cidade: "3904",
      id_estado: "23",
      nm_cidade: "Bozano",
      nm_cidade_uf: "Bozano - RS"
  },
  {
      id_cidade: "3905",
      id_estado: "23",
      nm_cidade: "Braga",
      nm_cidade_uf: "Braga - RS"
  },
  {
      id_cidade: "3906",
      id_estado: "23",
      nm_cidade: "Brochier",
      nm_cidade_uf: "Brochier - RS"
  },
  {
      id_cidade: "3907",
      id_estado: "23",
      nm_cidade: "Butiá",
      nm_cidade_uf: "Butiá - RS"
  },
  {
      id_cidade: "3908",
      id_estado: "23",
      nm_cidade: "Caçapava do Sul",
      nm_cidade_uf: "Caçapava do Sul - RS"
  },
  {
      id_cidade: "3909",
      id_estado: "23",
      nm_cidade: "Cacequi",
      nm_cidade_uf: "Cacequi - RS"
  },
  {
      id_cidade: "3910",
      id_estado: "23",
      nm_cidade: "Cachoeira do Sul",
      nm_cidade_uf: "Cachoeira do Sul - RS"
  },
  {
      id_cidade: "3911",
      id_estado: "23",
      nm_cidade: "Cachoeirinha",
      nm_cidade_uf: "Cachoeirinha - RS"
  },
  {
      id_cidade: "3912",
      id_estado: "23",
      nm_cidade: "Cacique Doble",
      nm_cidade_uf: "Cacique Doble - RS"
  },
  {
      id_cidade: "3913",
      id_estado: "23",
      nm_cidade: "Caibaté",
      nm_cidade_uf: "Caibaté - RS"
  },
  {
      id_cidade: "3914",
      id_estado: "23",
      nm_cidade: "Caiçara",
      nm_cidade_uf: "Caiçara - RS"
  },
  {
      id_cidade: "3915",
      id_estado: "23",
      nm_cidade: "Camaquã",
      nm_cidade_uf: "Camaquã - RS"
  },
  {
      id_cidade: "3916",
      id_estado: "23",
      nm_cidade: "Camargo",
      nm_cidade_uf: "Camargo - RS"
  },
  {
      id_cidade: "3917",
      id_estado: "23",
      nm_cidade: "Cambará do Sul",
      nm_cidade_uf: "Cambará do Sul - RS"
  },
  {
      id_cidade: "3918",
      id_estado: "23",
      nm_cidade: "Campestre da Serra",
      nm_cidade_uf: "Campestre da Serra - RS"
  },
  {
      id_cidade: "3919",
      id_estado: "23",
      nm_cidade: "Campina das Missões",
      nm_cidade_uf: "Campina das Missões - RS"
  },
  {
      id_cidade: "3920",
      id_estado: "23",
      nm_cidade: "Campinas do Sul",
      nm_cidade_uf: "Campinas do Sul - RS"
  },
  {
      id_cidade: "3921",
      id_estado: "23",
      nm_cidade: "Campo Bom",
      nm_cidade_uf: "Campo Bom - RS"
  },
  {
      id_cidade: "3922",
      id_estado: "23",
      nm_cidade: "Campo Novo",
      nm_cidade_uf: "Campo Novo - RS"
  },
  {
      id_cidade: "3923",
      id_estado: "23",
      nm_cidade: "Campos Borges",
      nm_cidade_uf: "Campos Borges - RS"
  },
  {
      id_cidade: "3924",
      id_estado: "23",
      nm_cidade: "Candelária",
      nm_cidade_uf: "Candelária - RS"
  },
  {
      id_cidade: "3925",
      id_estado: "23",
      nm_cidade: "Cândido Godói",
      nm_cidade_uf: "Cândido Godói - RS"
  },
  {
      id_cidade: "3926",
      id_estado: "23",
      nm_cidade: "Candiota",
      nm_cidade_uf: "Candiota - RS"
  },
  {
      id_cidade: "3927",
      id_estado: "23",
      nm_cidade: "Canela",
      nm_cidade_uf: "Canela - RS"
  },
  {
      id_cidade: "3928",
      id_estado: "23",
      nm_cidade: "Canguçu",
      nm_cidade_uf: "Canguçu - RS"
  },
  {
      id_cidade: "3929",
      id_estado: "23",
      nm_cidade: "Canoas",
      nm_cidade_uf: "Canoas - RS"
  },
  {
      id_cidade: "3930",
      id_estado: "23",
      nm_cidade: "Canudos do Vale",
      nm_cidade_uf: "Canudos do Vale - RS"
  },
  {
      id_cidade: "3931",
      id_estado: "23",
      nm_cidade: "Capão Bonito do Sul",
      nm_cidade_uf: "Capão Bonito do Sul - RS"
  },
  {
      id_cidade: "3932",
      id_estado: "23",
      nm_cidade: "Capão da Canoa",
      nm_cidade_uf: "Capão da Canoa - RS"
  },
  {
      id_cidade: "3933",
      id_estado: "23",
      nm_cidade: "Capão do Cipó",
      nm_cidade_uf: "Capão do Cipó - RS"
  },
  {
      id_cidade: "3934",
      id_estado: "23",
      nm_cidade: "Capão do Leão",
      nm_cidade_uf: "Capão do Leão - RS"
  },
  {
      id_cidade: "3935",
      id_estado: "23",
      nm_cidade: "Capela de Santana",
      nm_cidade_uf: "Capela de Santana - RS"
  },
  {
      id_cidade: "3936",
      id_estado: "23",
      nm_cidade: "Capitão",
      nm_cidade_uf: "Capitão - RS"
  },
  {
      id_cidade: "3937",
      id_estado: "23",
      nm_cidade: "Capivari do Sul",
      nm_cidade_uf: "Capivari do Sul - RS"
  },
  {
      id_cidade: "3938",
      id_estado: "23",
      nm_cidade: "Caraá",
      nm_cidade_uf: "Caraá - RS"
  },
  {
      id_cidade: "3939",
      id_estado: "23",
      nm_cidade: "Carazinho",
      nm_cidade_uf: "Carazinho - RS"
  },
  {
      id_cidade: "3940",
      id_estado: "23",
      nm_cidade: "Carlos Barbosa",
      nm_cidade_uf: "Carlos Barbosa - RS"
  },
  {
      id_cidade: "3941",
      id_estado: "23",
      nm_cidade: "Carlos Gomes",
      nm_cidade_uf: "Carlos Gomes - RS"
  },
  {
      id_cidade: "3942",
      id_estado: "23",
      nm_cidade: "Casca",
      nm_cidade_uf: "Casca - RS"
  },
  {
      id_cidade: "3943",
      id_estado: "23",
      nm_cidade: "Caseiros",
      nm_cidade_uf: "Caseiros - RS"
  },
  {
      id_cidade: "3944",
      id_estado: "23",
      nm_cidade: "Catuípe",
      nm_cidade_uf: "Catuípe - RS"
  },
  {
      id_cidade: "3945",
      id_estado: "23",
      nm_cidade: "Caxias do Sul",
      nm_cidade_uf: "Caxias do Sul - RS"
  },
  {
      id_cidade: "3946",
      id_estado: "23",
      nm_cidade: "Centenário",
      nm_cidade_uf: "Centenário - RS"
  },
  {
      id_cidade: "3947",
      id_estado: "23",
      nm_cidade: "Cerrito",
      nm_cidade_uf: "Cerrito - RS"
  },
  {
      id_cidade: "3948",
      id_estado: "23",
      nm_cidade: "Cerro Branco",
      nm_cidade_uf: "Cerro Branco - RS"
  },
  {
      id_cidade: "3949",
      id_estado: "23",
      nm_cidade: "Cerro Grande",
      nm_cidade_uf: "Cerro Grande - RS"
  },
  {
      id_cidade: "3950",
      id_estado: "23",
      nm_cidade: "Cerro Grande do Sul",
      nm_cidade_uf: "Cerro Grande do Sul - RS"
  },
  {
      id_cidade: "3951",
      id_estado: "23",
      nm_cidade: "Cerro Largo",
      nm_cidade_uf: "Cerro Largo - RS"
  },
  {
      id_cidade: "3952",
      id_estado: "23",
      nm_cidade: "Chapada",
      nm_cidade_uf: "Chapada - RS"
  },
  {
      id_cidade: "3953",
      id_estado: "23",
      nm_cidade: "Charqueadas",
      nm_cidade_uf: "Charqueadas - RS"
  },
  {
      id_cidade: "3954",
      id_estado: "23",
      nm_cidade: "Charrua",
      nm_cidade_uf: "Charrua - RS"
  },
  {
      id_cidade: "3955",
      id_estado: "23",
      nm_cidade: "Chiapeta",
      nm_cidade_uf: "Chiapeta - RS"
  },
  {
      id_cidade: "3956",
      id_estado: "23",
      nm_cidade: "Chuí",
      nm_cidade_uf: "Chuí - RS"
  },
  {
      id_cidade: "3957",
      id_estado: "23",
      nm_cidade: "Chuvisca",
      nm_cidade_uf: "Chuvisca - RS"
  },
  {
      id_cidade: "3958",
      id_estado: "23",
      nm_cidade: "Cidreira",
      nm_cidade_uf: "Cidreira - RS"
  },
  {
      id_cidade: "3959",
      id_estado: "23",
      nm_cidade: "Ciríaco",
      nm_cidade_uf: "Ciríaco - RS"
  },
  {
      id_cidade: "3960",
      id_estado: "23",
      nm_cidade: "Colinas",
      nm_cidade_uf: "Colinas - RS"
  },
  {
      id_cidade: "3961",
      id_estado: "23",
      nm_cidade: "Colorado",
      nm_cidade_uf: "Colorado - RS"
  },
  {
      id_cidade: "3962",
      id_estado: "23",
      nm_cidade: "Condor",
      nm_cidade_uf: "Condor - RS"
  },
  {
      id_cidade: "3963",
      id_estado: "23",
      nm_cidade: "Constantina",
      nm_cidade_uf: "Constantina - RS"
  },
  {
      id_cidade: "3964",
      id_estado: "23",
      nm_cidade: "Coqueiro Baixo",
      nm_cidade_uf: "Coqueiro Baixo - RS"
  },
  {
      id_cidade: "3965",
      id_estado: "23",
      nm_cidade: "Coqueiros do Sul",
      nm_cidade_uf: "Coqueiros do Sul - RS"
  },
  {
      id_cidade: "3966",
      id_estado: "23",
      nm_cidade: "Coronel Barros",
      nm_cidade_uf: "Coronel Barros - RS"
  },
  {
      id_cidade: "3967",
      id_estado: "23",
      nm_cidade: "Coronel Bicaco",
      nm_cidade_uf: "Coronel Bicaco - RS"
  },
  {
      id_cidade: "3968",
      id_estado: "23",
      nm_cidade: "Coronel Pilar",
      nm_cidade_uf: "Coronel Pilar - RS"
  },
  {
      id_cidade: "3969",
      id_estado: "23",
      nm_cidade: "Cotiporã",
      nm_cidade_uf: "Cotiporã - RS"
  },
  {
      id_cidade: "3970",
      id_estado: "23",
      nm_cidade: "Coxilha",
      nm_cidade_uf: "Coxilha - RS"
  },
  {
      id_cidade: "3971",
      id_estado: "23",
      nm_cidade: "Crissiumal",
      nm_cidade_uf: "Crissiumal - RS"
  },
  {
      id_cidade: "3972",
      id_estado: "23",
      nm_cidade: "Cristal",
      nm_cidade_uf: "Cristal - RS"
  },
  {
      id_cidade: "3973",
      id_estado: "23",
      nm_cidade: "Cristal do Sul",
      nm_cidade_uf: "Cristal do Sul - RS"
  },
  {
      id_cidade: "3974",
      id_estado: "23",
      nm_cidade: "Cruz Alta",
      nm_cidade_uf: "Cruz Alta - RS"
  },
  {
      id_cidade: "3975",
      id_estado: "23",
      nm_cidade: "Cruzaltense",
      nm_cidade_uf: "Cruzaltense - RS"
  },
  {
      id_cidade: "3976",
      id_estado: "23",
      nm_cidade: "Cruzeiro do Sul",
      nm_cidade_uf: "Cruzeiro do Sul - RS"
  },
  {
      id_cidade: "3977",
      id_estado: "23",
      nm_cidade: "David Canabarro",
      nm_cidade_uf: "David Canabarro - RS"
  },
  {
      id_cidade: "3978",
      id_estado: "23",
      nm_cidade: "Derrubadas",
      nm_cidade_uf: "Derrubadas - RS"
  },
  {
      id_cidade: "3979",
      id_estado: "23",
      nm_cidade: "Dezesseis de Novembro",
      nm_cidade_uf: "Dezesseis de Novembro - RS"
  },
  {
      id_cidade: "3980",
      id_estado: "23",
      nm_cidade: "Dilermando de Aguiar",
      nm_cidade_uf: "Dilermando de Aguiar - RS"
  },
  {
      id_cidade: "3981",
      id_estado: "23",
      nm_cidade: "Dois Irmãos",
      nm_cidade_uf: "Dois Irmãos - RS"
  },
  {
      id_cidade: "3982",
      id_estado: "23",
      nm_cidade: "Dois Irmãos das Missões",
      nm_cidade_uf: "Dois Irmãos das Missões - RS"
  },
  {
      id_cidade: "3983",
      id_estado: "23",
      nm_cidade: "Dois Lajeados",
      nm_cidade_uf: "Dois Lajeados - RS"
  },
  {
      id_cidade: "3984",
      id_estado: "23",
      nm_cidade: "Dom Feliciano",
      nm_cidade_uf: "Dom Feliciano - RS"
  },
  {
      id_cidade: "3985",
      id_estado: "23",
      nm_cidade: "Dom Pedrito",
      nm_cidade_uf: "Dom Pedrito - RS"
  },
  {
      id_cidade: "3986",
      id_estado: "23",
      nm_cidade: "Dom Pedro de Alcântara",
      nm_cidade_uf: "Dom Pedro de Alcântara - RS"
  },
  {
      id_cidade: "3987",
      id_estado: "23",
      nm_cidade: "Dona Francisca",
      nm_cidade_uf: "Dona Francisca - RS"
  },
  {
      id_cidade: "3988",
      id_estado: "23",
      nm_cidade: "Doutor Maurício Cardoso",
      nm_cidade_uf: "Doutor Maurício Cardoso - RS"
  },
  {
      id_cidade: "3989",
      id_estado: "23",
      nm_cidade: "Doutor Ricardo",
      nm_cidade_uf: "Doutor Ricardo - RS"
  },
  {
      id_cidade: "3990",
      id_estado: "23",
      nm_cidade: "Eldorado do Sul",
      nm_cidade_uf: "Eldorado do Sul - RS"
  },
  {
      id_cidade: "3991",
      id_estado: "23",
      nm_cidade: "Encantado",
      nm_cidade_uf: "Encantado - RS"
  },
  {
      id_cidade: "3992",
      id_estado: "23",
      nm_cidade: "Encruzilhada do Sul",
      nm_cidade_uf: "Encruzilhada do Sul - RS"
  },
  {
      id_cidade: "3993",
      id_estado: "23",
      nm_cidade: "Engenho Velho",
      nm_cidade_uf: "Engenho Velho - RS"
  },
  {
      id_cidade: "3994",
      id_estado: "23",
      nm_cidade: "Entre Rios do Sul",
      nm_cidade_uf: "Entre Rios do Sul - RS"
  },
  {
      id_cidade: "3995",
      id_estado: "23",
      nm_cidade: "Entre-Ijuís",
      nm_cidade_uf: "Entre-Ijuís - RS"
  },
  {
      id_cidade: "3996",
      id_estado: "23",
      nm_cidade: "Erebango",
      nm_cidade_uf: "Erebango - RS"
  },
  {
      id_cidade: "3997",
      id_estado: "23",
      nm_cidade: "Erechim",
      nm_cidade_uf: "Erechim - RS"
  },
  {
      id_cidade: "3998",
      id_estado: "23",
      nm_cidade: "Ernestina",
      nm_cidade_uf: "Ernestina - RS"
  },
  {
      id_cidade: "3999",
      id_estado: "23",
      nm_cidade: "Erval Grande",
      nm_cidade_uf: "Erval Grande - RS"
  },
  {
      id_cidade: "4000",
      id_estado: "23",
      nm_cidade: "Erval Seco",
      nm_cidade_uf: "Erval Seco - RS"
  },
  {
      id_cidade: "4001",
      id_estado: "23",
      nm_cidade: "Esmeralda",
      nm_cidade_uf: "Esmeralda - RS"
  },
  {
      id_cidade: "4002",
      id_estado: "23",
      nm_cidade: "Esperança do Sul",
      nm_cidade_uf: "Esperança do Sul - RS"
  },
  {
      id_cidade: "4003",
      id_estado: "23",
      nm_cidade: "Espumoso",
      nm_cidade_uf: "Espumoso - RS"
  },
  {
      id_cidade: "4004",
      id_estado: "23",
      nm_cidade: "Estação",
      nm_cidade_uf: "Estação - RS"
  },
  {
      id_cidade: "4005",
      id_estado: "23",
      nm_cidade: "Estância Velha",
      nm_cidade_uf: "Estância Velha - RS"
  },
  {
      id_cidade: "4006",
      id_estado: "23",
      nm_cidade: "Esteio",
      nm_cidade_uf: "Esteio - RS"
  },
  {
      id_cidade: "4007",
      id_estado: "23",
      nm_cidade: "Estrela",
      nm_cidade_uf: "Estrela - RS"
  },
  {
      id_cidade: "4008",
      id_estado: "23",
      nm_cidade: "Estrela Velha",
      nm_cidade_uf: "Estrela Velha - RS"
  },
  {
      id_cidade: "4009",
      id_estado: "23",
      nm_cidade: "Eugênio de Castro",
      nm_cidade_uf: "Eugênio de Castro - RS"
  },
  {
      id_cidade: "4010",
      id_estado: "23",
      nm_cidade: "Fagundes Varela",
      nm_cidade_uf: "Fagundes Varela - RS"
  },
  {
      id_cidade: "4011",
      id_estado: "23",
      nm_cidade: "Farroupilha",
      nm_cidade_uf: "Farroupilha - RS"
  },
  {
      id_cidade: "4012",
      id_estado: "23",
      nm_cidade: "Faxinal do Soturno",
      nm_cidade_uf: "Faxinal do Soturno - RS"
  },
  {
      id_cidade: "4013",
      id_estado: "23",
      nm_cidade: "Faxinalzinho",
      nm_cidade_uf: "Faxinalzinho - RS"
  },
  {
      id_cidade: "4014",
      id_estado: "23",
      nm_cidade: "Fazenda Vilanova",
      nm_cidade_uf: "Fazenda Vilanova - RS"
  },
  {
      id_cidade: "4015",
      id_estado: "23",
      nm_cidade: "Feliz",
      nm_cidade_uf: "Feliz - RS"
  },
  {
      id_cidade: "4016",
      id_estado: "23",
      nm_cidade: "Flores da Cunha",
      nm_cidade_uf: "Flores da Cunha - RS"
  },
  {
      id_cidade: "4017",
      id_estado: "23",
      nm_cidade: "Floriano Peixoto",
      nm_cidade_uf: "Floriano Peixoto - RS"
  },
  {
      id_cidade: "4018",
      id_estado: "23",
      nm_cidade: "Fontoura Xavier",
      nm_cidade_uf: "Fontoura Xavier - RS"
  },
  {
      id_cidade: "4019",
      id_estado: "23",
      nm_cidade: "Formigueiro",
      nm_cidade_uf: "Formigueiro - RS"
  },
  {
      id_cidade: "4020",
      id_estado: "23",
      nm_cidade: "Forquetinha",
      nm_cidade_uf: "Forquetinha - RS"
  },
  {
      id_cidade: "4021",
      id_estado: "23",
      nm_cidade: "Fortaleza dos Valos",
      nm_cidade_uf: "Fortaleza dos Valos - RS"
  },
  {
      id_cidade: "4022",
      id_estado: "23",
      nm_cidade: "Frederico Westphalen",
      nm_cidade_uf: "Frederico Westphalen - RS"
  },
  {
      id_cidade: "4023",
      id_estado: "23",
      nm_cidade: "Garibaldi",
      nm_cidade_uf: "Garibaldi - RS"
  },
  {
      id_cidade: "4024",
      id_estado: "23",
      nm_cidade: "Garruchos",
      nm_cidade_uf: "Garruchos - RS"
  },
  {
      id_cidade: "4025",
      id_estado: "23",
      nm_cidade: "Gaurama",
      nm_cidade_uf: "Gaurama - RS"
  },
  {
      id_cidade: "4026",
      id_estado: "23",
      nm_cidade: "General Câmara",
      nm_cidade_uf: "General Câmara - RS"
  },
  {
      id_cidade: "4027",
      id_estado: "23",
      nm_cidade: "Gentil",
      nm_cidade_uf: "Gentil - RS"
  },
  {
      id_cidade: "4028",
      id_estado: "23",
      nm_cidade: "Getúlio Vargas",
      nm_cidade_uf: "Getúlio Vargas - RS"
  },
  {
      id_cidade: "4029",
      id_estado: "23",
      nm_cidade: "Giruá",
      nm_cidade_uf: "Giruá - RS"
  },
  {
      id_cidade: "4030",
      id_estado: "23",
      nm_cidade: "Glorinha",
      nm_cidade_uf: "Glorinha - RS"
  },
  {
      id_cidade: "4031",
      id_estado: "23",
      nm_cidade: "Gramado",
      nm_cidade_uf: "Gramado - RS"
  },
  {
      id_cidade: "4032",
      id_estado: "23",
      nm_cidade: "Gramado dos Loureiros",
      nm_cidade_uf: "Gramado dos Loureiros - RS"
  },
  {
      id_cidade: "4033",
      id_estado: "23",
      nm_cidade: "Gramado Xavier",
      nm_cidade_uf: "Gramado Xavier - RS"
  },
  {
      id_cidade: "4034",
      id_estado: "23",
      nm_cidade: "Gravataí",
      nm_cidade_uf: "Gravataí - RS"
  },
  {
      id_cidade: "4035",
      id_estado: "23",
      nm_cidade: "Guabiju",
      nm_cidade_uf: "Guabiju - RS"
  },
  {
      id_cidade: "4036",
      id_estado: "23",
      nm_cidade: "Guaíba",
      nm_cidade_uf: "Guaíba - RS"
  },
  {
      id_cidade: "4037",
      id_estado: "23",
      nm_cidade: "Guaporé",
      nm_cidade_uf: "Guaporé - RS"
  },
  {
      id_cidade: "4038",
      id_estado: "23",
      nm_cidade: "Guarani das Missões",
      nm_cidade_uf: "Guarani das Missões - RS"
  },
  {
      id_cidade: "4039",
      id_estado: "23",
      nm_cidade: "Harmonia",
      nm_cidade_uf: "Harmonia - RS"
  },
  {
      id_cidade: "4040",
      id_estado: "23",
      nm_cidade: "Herval",
      nm_cidade_uf: "Herval - RS"
  },
  {
      id_cidade: "4041",
      id_estado: "23",
      nm_cidade: "Herveiras",
      nm_cidade_uf: "Herveiras - RS"
  },
  {
      id_cidade: "4042",
      id_estado: "23",
      nm_cidade: "Horizontina",
      nm_cidade_uf: "Horizontina - RS"
  },
  {
      id_cidade: "4043",
      id_estado: "23",
      nm_cidade: "Hulha Negra",
      nm_cidade_uf: "Hulha Negra - RS"
  },
  {
      id_cidade: "4044",
      id_estado: "23",
      nm_cidade: "Humaitá",
      nm_cidade_uf: "Humaitá - RS"
  },
  {
      id_cidade: "4045",
      id_estado: "23",
      nm_cidade: "Ibarama",
      nm_cidade_uf: "Ibarama - RS"
  },
  {
      id_cidade: "4046",
      id_estado: "23",
      nm_cidade: "Ibiaçá",
      nm_cidade_uf: "Ibiaçá - RS"
  },
  {
      id_cidade: "4047",
      id_estado: "23",
      nm_cidade: "Ibiraiaras",
      nm_cidade_uf: "Ibiraiaras - RS"
  },
  {
      id_cidade: "4048",
      id_estado: "23",
      nm_cidade: "Ibirapuitã",
      nm_cidade_uf: "Ibirapuitã - RS"
  },
  {
      id_cidade: "4049",
      id_estado: "23",
      nm_cidade: "Ibirubá",
      nm_cidade_uf: "Ibirubá - RS"
  },
  {
      id_cidade: "4050",
      id_estado: "23",
      nm_cidade: "Igrejinha",
      nm_cidade_uf: "Igrejinha - RS"
  },
  {
      id_cidade: "4051",
      id_estado: "23",
      nm_cidade: "Ijuí",
      nm_cidade_uf: "Ijuí - RS"
  },
  {
      id_cidade: "4052",
      id_estado: "23",
      nm_cidade: "Ilópolis",
      nm_cidade_uf: "Ilópolis - RS"
  },
  {
      id_cidade: "4053",
      id_estado: "23",
      nm_cidade: "Imbé",
      nm_cidade_uf: "Imbé - RS"
  },
  {
      id_cidade: "4054",
      id_estado: "23",
      nm_cidade: "Imigrante",
      nm_cidade_uf: "Imigrante - RS"
  },
  {
      id_cidade: "4055",
      id_estado: "23",
      nm_cidade: "Independência",
      nm_cidade_uf: "Independência - RS"
  },
  {
      id_cidade: "4056",
      id_estado: "23",
      nm_cidade: "Inhacorá",
      nm_cidade_uf: "Inhacorá - RS"
  },
  {
      id_cidade: "4057",
      id_estado: "23",
      nm_cidade: "Ipê",
      nm_cidade_uf: "Ipê - RS"
  },
  {
      id_cidade: "4058",
      id_estado: "23",
      nm_cidade: "Ipiranga do Sul",
      nm_cidade_uf: "Ipiranga do Sul - RS"
  },
  {
      id_cidade: "4059",
      id_estado: "23",
      nm_cidade: "Iraí",
      nm_cidade_uf: "Iraí - RS"
  },
  {
      id_cidade: "4060",
      id_estado: "23",
      nm_cidade: "Itaara",
      nm_cidade_uf: "Itaara - RS"
  },
  {
      id_cidade: "4061",
      id_estado: "23",
      nm_cidade: "Itacurubi",
      nm_cidade_uf: "Itacurubi - RS"
  },
  {
      id_cidade: "4062",
      id_estado: "23",
      nm_cidade: "Itapuca",
      nm_cidade_uf: "Itapuca - RS"
  },
  {
      id_cidade: "4063",
      id_estado: "23",
      nm_cidade: "Itaqui",
      nm_cidade_uf: "Itaqui - RS"
  },
  {
      id_cidade: "4064",
      id_estado: "23",
      nm_cidade: "Itati",
      nm_cidade_uf: "Itati - RS"
  },
  {
      id_cidade: "4065",
      id_estado: "23",
      nm_cidade: "Itatiba do Sul",
      nm_cidade_uf: "Itatiba do Sul - RS"
  },
  {
      id_cidade: "4066",
      id_estado: "23",
      nm_cidade: "Ivorá",
      nm_cidade_uf: "Ivorá - RS"
  },
  {
      id_cidade: "4067",
      id_estado: "23",
      nm_cidade: "Ivoti",
      nm_cidade_uf: "Ivoti - RS"
  },
  {
      id_cidade: "4068",
      id_estado: "23",
      nm_cidade: "Jaboticaba",
      nm_cidade_uf: "Jaboticaba - RS"
  },
  {
      id_cidade: "4069",
      id_estado: "23",
      nm_cidade: "Jacuizinho",
      nm_cidade_uf: "Jacuizinho - RS"
  },
  {
      id_cidade: "4070",
      id_estado: "23",
      nm_cidade: "Jacutinga",
      nm_cidade_uf: "Jacutinga - RS"
  },
  {
      id_cidade: "4071",
      id_estado: "23",
      nm_cidade: "Jaguarão",
      nm_cidade_uf: "Jaguarão - RS"
  },
  {
      id_cidade: "4072",
      id_estado: "23",
      nm_cidade: "Jaguari",
      nm_cidade_uf: "Jaguari - RS"
  },
  {
      id_cidade: "4073",
      id_estado: "23",
      nm_cidade: "Jaquirana",
      nm_cidade_uf: "Jaquirana - RS"
  },
  {
      id_cidade: "4074",
      id_estado: "23",
      nm_cidade: "Jari",
      nm_cidade_uf: "Jari - RS"
  },
  {
      id_cidade: "4075",
      id_estado: "23",
      nm_cidade: "Jóia",
      nm_cidade_uf: "Jóia - RS"
  },
  {
      id_cidade: "4076",
      id_estado: "23",
      nm_cidade: "Júlio de Castilhos",
      nm_cidade_uf: "Júlio de Castilhos - RS"
  },
  {
      id_cidade: "4077",
      id_estado: "23",
      nm_cidade: "Lagoa Bonita do Sul",
      nm_cidade_uf: "Lagoa Bonita do Sul - RS"
  },
  {
      id_cidade: "4078",
      id_estado: "23",
      nm_cidade: "Lagoa dos Três Cantos",
      nm_cidade_uf: "Lagoa dos Três Cantos - RS"
  },
  {
      id_cidade: "4079",
      id_estado: "23",
      nm_cidade: "Lagoa Vermelha",
      nm_cidade_uf: "Lagoa Vermelha - RS"
  },
  {
      id_cidade: "4080",
      id_estado: "23",
      nm_cidade: "Lagoão",
      nm_cidade_uf: "Lagoão - RS"
  },
  {
      id_cidade: "4081",
      id_estado: "23",
      nm_cidade: "Lajeado",
      nm_cidade_uf: "Lajeado - RS"
  },
  {
      id_cidade: "4082",
      id_estado: "23",
      nm_cidade: "Lajeado do Bugre",
      nm_cidade_uf: "Lajeado do Bugre - RS"
  },
  {
      id_cidade: "4083",
      id_estado: "23",
      nm_cidade: "Lavras do Sul",
      nm_cidade_uf: "Lavras do Sul - RS"
  },
  {
      id_cidade: "4084",
      id_estado: "23",
      nm_cidade: "Liberato Salzano",
      nm_cidade_uf: "Liberato Salzano - RS"
  },
  {
      id_cidade: "4085",
      id_estado: "23",
      nm_cidade: "Lindolfo Collor",
      nm_cidade_uf: "Lindolfo Collor - RS"
  },
  {
      id_cidade: "4086",
      id_estado: "23",
      nm_cidade: "Linha Nova",
      nm_cidade_uf: "Linha Nova - RS"
  },
  {
      id_cidade: "4087",
      id_estado: "23",
      nm_cidade: "Maçambara",
      nm_cidade_uf: "Maçambara - RS"
  },
  {
      id_cidade: "4088",
      id_estado: "23",
      nm_cidade: "Machadinho",
      nm_cidade_uf: "Machadinho - RS"
  },
  {
      id_cidade: "4089",
      id_estado: "23",
      nm_cidade: "Mampituba",
      nm_cidade_uf: "Mampituba - RS"
  },
  {
      id_cidade: "4090",
      id_estado: "23",
      nm_cidade: "Manoel Viana",
      nm_cidade_uf: "Manoel Viana - RS"
  },
  {
      id_cidade: "4091",
      id_estado: "23",
      nm_cidade: "Maquiné",
      nm_cidade_uf: "Maquiné - RS"
  },
  {
      id_cidade: "4092",
      id_estado: "23",
      nm_cidade: "Maratá",
      nm_cidade_uf: "Maratá - RS"
  },
  {
      id_cidade: "4093",
      id_estado: "23",
      nm_cidade: "Marau",
      nm_cidade_uf: "Marau - RS"
  },
  {
      id_cidade: "4094",
      id_estado: "23",
      nm_cidade: "Marcelino Ramos",
      nm_cidade_uf: "Marcelino Ramos - RS"
  },
  {
      id_cidade: "4095",
      id_estado: "23",
      nm_cidade: "Mariana Pimentel",
      nm_cidade_uf: "Mariana Pimentel - RS"
  },
  {
      id_cidade: "4096",
      id_estado: "23",
      nm_cidade: "Mariano Moro",
      nm_cidade_uf: "Mariano Moro - RS"
  },
  {
      id_cidade: "4097",
      id_estado: "23",
      nm_cidade: "Marques de Souza",
      nm_cidade_uf: "Marques de Souza - RS"
  },
  {
      id_cidade: "4098",
      id_estado: "23",
      nm_cidade: "Mata",
      nm_cidade_uf: "Mata - RS"
  },
  {
      id_cidade: "4099",
      id_estado: "23",
      nm_cidade: "Mato Castelhano",
      nm_cidade_uf: "Mato Castelhano - RS"
  },
  {
      id_cidade: "4100",
      id_estado: "23",
      nm_cidade: "Mato Leitão",
      nm_cidade_uf: "Mato Leitão - RS"
  },
  {
      id_cidade: "4101",
      id_estado: "23",
      nm_cidade: "Mato Queimado",
      nm_cidade_uf: "Mato Queimado - RS"
  },
  {
      id_cidade: "4102",
      id_estado: "23",
      nm_cidade: "Maximiliano de Almeida",
      nm_cidade_uf: "Maximiliano de Almeida - RS"
  },
  {
      id_cidade: "4103",
      id_estado: "23",
      nm_cidade: "Minas do Leão",
      nm_cidade_uf: "Minas do Leão - RS"
  },
  {
      id_cidade: "4104",
      id_estado: "23",
      nm_cidade: "Miraguaí",
      nm_cidade_uf: "Miraguaí - RS"
  },
  {
      id_cidade: "4105",
      id_estado: "23",
      nm_cidade: "Montauri",
      nm_cidade_uf: "Montauri - RS"
  },
  {
      id_cidade: "4106",
      id_estado: "23",
      nm_cidade: "Monte Alegre dos Campos",
      nm_cidade_uf: "Monte Alegre dos Campos - RS"
  },
  {
      id_cidade: "4107",
      id_estado: "23",
      nm_cidade: "Monte Belo do Sul",
      nm_cidade_uf: "Monte Belo do Sul - RS"
  },
  {
      id_cidade: "4108",
      id_estado: "23",
      nm_cidade: "Montenegro",
      nm_cidade_uf: "Montenegro - RS"
  },
  {
      id_cidade: "4109",
      id_estado: "23",
      nm_cidade: "Mormaço",
      nm_cidade_uf: "Mormaço - RS"
  },
  {
      id_cidade: "4110",
      id_estado: "23",
      nm_cidade: "Morrinhos do Sul",
      nm_cidade_uf: "Morrinhos do Sul - RS"
  },
  {
      id_cidade: "4111",
      id_estado: "23",
      nm_cidade: "Morro Redondo",
      nm_cidade_uf: "Morro Redondo - RS"
  },
  {
      id_cidade: "4112",
      id_estado: "23",
      nm_cidade: "Morro Reuter",
      nm_cidade_uf: "Morro Reuter - RS"
  },
  {
      id_cidade: "4113",
      id_estado: "23",
      nm_cidade: "Mostardas",
      nm_cidade_uf: "Mostardas - RS"
  },
  {
      id_cidade: "4114",
      id_estado: "23",
      nm_cidade: "Muçum",
      nm_cidade_uf: "Muçum - RS"
  },
  {
      id_cidade: "4115",
      id_estado: "23",
      nm_cidade: "Muitos Capões",
      nm_cidade_uf: "Muitos Capões - RS"
  },
  {
      id_cidade: "4116",
      id_estado: "23",
      nm_cidade: "Muliterno",
      nm_cidade_uf: "Muliterno - RS"
  },
  {
      id_cidade: "4117",
      id_estado: "23",
      nm_cidade: "Não-Me-Toque",
      nm_cidade_uf: "Não-Me-Toque - RS"
  },
  {
      id_cidade: "4118",
      id_estado: "23",
      nm_cidade: "Nicolau Vergueiro",
      nm_cidade_uf: "Nicolau Vergueiro - RS"
  },
  {
      id_cidade: "4119",
      id_estado: "23",
      nm_cidade: "Nonoai",
      nm_cidade_uf: "Nonoai - RS"
  },
  {
      id_cidade: "4120",
      id_estado: "23",
      nm_cidade: "Nova Alvorada",
      nm_cidade_uf: "Nova Alvorada - RS"
  },
  {
      id_cidade: "4121",
      id_estado: "23",
      nm_cidade: "Nova Araçá",
      nm_cidade_uf: "Nova Araçá - RS"
  },
  {
      id_cidade: "4122",
      id_estado: "23",
      nm_cidade: "Nova Bassano",
      nm_cidade_uf: "Nova Bassano - RS"
  },
  {
      id_cidade: "4123",
      id_estado: "23",
      nm_cidade: "Nova Boa Vista",
      nm_cidade_uf: "Nova Boa Vista - RS"
  },
  {
      id_cidade: "4124",
      id_estado: "23",
      nm_cidade: "Nova Bréscia",
      nm_cidade_uf: "Nova Bréscia - RS"
  },
  {
      id_cidade: "4125",
      id_estado: "23",
      nm_cidade: "Nova Candelária",
      nm_cidade_uf: "Nova Candelária - RS"
  },
  {
      id_cidade: "4126",
      id_estado: "23",
      nm_cidade: "Nova Esperança do Sul",
      nm_cidade_uf: "Nova Esperança do Sul - RS"
  },
  {
      id_cidade: "4127",
      id_estado: "23",
      nm_cidade: "Nova Hartz",
      nm_cidade_uf: "Nova Hartz - RS"
  },
  {
      id_cidade: "4128",
      id_estado: "23",
      nm_cidade: "Nova Pádua",
      nm_cidade_uf: "Nova Pádua - RS"
  },
  {
      id_cidade: "4129",
      id_estado: "23",
      nm_cidade: "Nova Palma",
      nm_cidade_uf: "Nova Palma - RS"
  },
  {
      id_cidade: "4130",
      id_estado: "23",
      nm_cidade: "Nova Petrópolis",
      nm_cidade_uf: "Nova Petrópolis - RS"
  },
  {
      id_cidade: "4131",
      id_estado: "23",
      nm_cidade: "Nova Prata",
      nm_cidade_uf: "Nova Prata - RS"
  },
  {
      id_cidade: "4132",
      id_estado: "23",
      nm_cidade: "Nova Ramada",
      nm_cidade_uf: "Nova Ramada - RS"
  },
  {
      id_cidade: "4133",
      id_estado: "23",
      nm_cidade: "Nova Roma do Sul",
      nm_cidade_uf: "Nova Roma do Sul - RS"
  },
  {
      id_cidade: "4134",
      id_estado: "23",
      nm_cidade: "Nova Santa Rita",
      nm_cidade_uf: "Nova Santa Rita - RS"
  },
  {
      id_cidade: "4135",
      id_estado: "23",
      nm_cidade: "Novo Barreiro",
      nm_cidade_uf: "Novo Barreiro - RS"
  },
  {
      id_cidade: "4136",
      id_estado: "23",
      nm_cidade: "Novo Cabrais",
      nm_cidade_uf: "Novo Cabrais - RS"
  },
  {
      id_cidade: "4137",
      id_estado: "23",
      nm_cidade: "Novo Hamburgo",
      nm_cidade_uf: "Novo Hamburgo - RS"
  },
  {
      id_cidade: "4138",
      id_estado: "23",
      nm_cidade: "Novo Machado",
      nm_cidade_uf: "Novo Machado - RS"
  },
  {
      id_cidade: "4139",
      id_estado: "23",
      nm_cidade: "Novo Tiradentes",
      nm_cidade_uf: "Novo Tiradentes - RS"
  },
  {
      id_cidade: "4140",
      id_estado: "23",
      nm_cidade: "Novo Xingu",
      nm_cidade_uf: "Novo Xingu - RS"
  },
  {
      id_cidade: "4141",
      id_estado: "23",
      nm_cidade: "Osório",
      nm_cidade_uf: "Osório - RS"
  },
  {
      id_cidade: "4142",
      id_estado: "23",
      nm_cidade: "Paim Filho",
      nm_cidade_uf: "Paim Filho - RS"
  },
  {
      id_cidade: "4143",
      id_estado: "23",
      nm_cidade: "Palmares do Sul",
      nm_cidade_uf: "Palmares do Sul - RS"
  },
  {
      id_cidade: "4144",
      id_estado: "23",
      nm_cidade: "Palmeira das Missões",
      nm_cidade_uf: "Palmeira das Missões - RS"
  },
  {
      id_cidade: "4145",
      id_estado: "23",
      nm_cidade: "Palmitinho",
      nm_cidade_uf: "Palmitinho - RS"
  },
  {
      id_cidade: "4146",
      id_estado: "23",
      nm_cidade: "Panambi",
      nm_cidade_uf: "Panambi - RS"
  },
  {
      id_cidade: "4147",
      id_estado: "23",
      nm_cidade: "Pantano Grande",
      nm_cidade_uf: "Pantano Grande - RS"
  },
  {
      id_cidade: "4148",
      id_estado: "23",
      nm_cidade: "Paraí",
      nm_cidade_uf: "Paraí - RS"
  },
  {
      id_cidade: "4149",
      id_estado: "23",
      nm_cidade: "Paraíso do Sul",
      nm_cidade_uf: "Paraíso do Sul - RS"
  },
  {
      id_cidade: "4150",
      id_estado: "23",
      nm_cidade: "Pareci Novo",
      nm_cidade_uf: "Pareci Novo - RS"
  },
  {
      id_cidade: "4151",
      id_estado: "23",
      nm_cidade: "Parobé",
      nm_cidade_uf: "Parobé - RS"
  },
  {
      id_cidade: "4152",
      id_estado: "23",
      nm_cidade: "Passa Sete",
      nm_cidade_uf: "Passa Sete - RS"
  },
  {
      id_cidade: "4153",
      id_estado: "23",
      nm_cidade: "Passo do Sobrado",
      nm_cidade_uf: "Passo do Sobrado - RS"
  },
  {
      id_cidade: "4154",
      id_estado: "23",
      nm_cidade: "Passo Fundo",
      nm_cidade_uf: "Passo Fundo - RS"
  },
  {
      id_cidade: "4155",
      id_estado: "23",
      nm_cidade: "Paulo Bento",
      nm_cidade_uf: "Paulo Bento - RS"
  },
  {
      id_cidade: "4156",
      id_estado: "23",
      nm_cidade: "Paverama",
      nm_cidade_uf: "Paverama - RS"
  },
  {
      id_cidade: "4157",
      id_estado: "23",
      nm_cidade: "Pedras Altas",
      nm_cidade_uf: "Pedras Altas - RS"
  },
  {
      id_cidade: "4158",
      id_estado: "23",
      nm_cidade: "Pedro Osório",
      nm_cidade_uf: "Pedro Osório - RS"
  },
  {
      id_cidade: "4159",
      id_estado: "23",
      nm_cidade: "Pejuçara",
      nm_cidade_uf: "Pejuçara - RS"
  },
  {
      id_cidade: "4160",
      id_estado: "23",
      nm_cidade: "Pelotas",
      nm_cidade_uf: "Pelotas - RS"
  },
  {
      id_cidade: "4161",
      id_estado: "23",
      nm_cidade: "Picada Café",
      nm_cidade_uf: "Picada Café - RS"
  },
  {
      id_cidade: "4162",
      id_estado: "23",
      nm_cidade: "Pinhal",
      nm_cidade_uf: "Pinhal - RS"
  },
  {
      id_cidade: "4163",
      id_estado: "23",
      nm_cidade: "Pinhal da Serra",
      nm_cidade_uf: "Pinhal da Serra - RS"
  },
  {
      id_cidade: "4164",
      id_estado: "23",
      nm_cidade: "Pinhal Grande",
      nm_cidade_uf: "Pinhal Grande - RS"
  },
  {
      id_cidade: "4165",
      id_estado: "23",
      nm_cidade: "Pinheirinho do Vale",
      nm_cidade_uf: "Pinheirinho do Vale - RS"
  },
  {
      id_cidade: "4166",
      id_estado: "23",
      nm_cidade: "Pinheiro Machado",
      nm_cidade_uf: "Pinheiro Machado - RS"
  },
  {
      id_cidade: "4167",
      id_estado: "23",
      nm_cidade: "Pirapó",
      nm_cidade_uf: "Pirapó - RS"
  },
  {
      id_cidade: "4168",
      id_estado: "23",
      nm_cidade: "Piratini",
      nm_cidade_uf: "Piratini - RS"
  },
  {
      id_cidade: "4169",
      id_estado: "23",
      nm_cidade: "Planalto",
      nm_cidade_uf: "Planalto - RS"
  },
  {
      id_cidade: "4170",
      id_estado: "23",
      nm_cidade: "Poço das Antas",
      nm_cidade_uf: "Poço das Antas - RS"
  },
  {
      id_cidade: "4171",
      id_estado: "23",
      nm_cidade: "Pontão",
      nm_cidade_uf: "Pontão - RS"
  },
  {
      id_cidade: "4172",
      id_estado: "23",
      nm_cidade: "Ponte Preta",
      nm_cidade_uf: "Ponte Preta - RS"
  },
  {
      id_cidade: "4173",
      id_estado: "23",
      nm_cidade: "Portão",
      nm_cidade_uf: "Portão - RS"
  },
  {
      id_cidade: "4174",
      id_estado: "23",
      nm_cidade: "Porto Alegre",
      nm_cidade_uf: "Porto Alegre - RS"
  },
  {
      id_cidade: "4175",
      id_estado: "23",
      nm_cidade: "Porto Lucena",
      nm_cidade_uf: "Porto Lucena - RS"
  },
  {
      id_cidade: "4176",
      id_estado: "23",
      nm_cidade: "Porto Mauá",
      nm_cidade_uf: "Porto Mauá - RS"
  },
  {
      id_cidade: "4177",
      id_estado: "23",
      nm_cidade: "Porto Vera Cruz",
      nm_cidade_uf: "Porto Vera Cruz - RS"
  },
  {
      id_cidade: "4178",
      id_estado: "23",
      nm_cidade: "Porto Xavier",
      nm_cidade_uf: "Porto Xavier - RS"
  },
  {
      id_cidade: "4179",
      id_estado: "23",
      nm_cidade: "Pouso Novo",
      nm_cidade_uf: "Pouso Novo - RS"
  },
  {
      id_cidade: "4180",
      id_estado: "23",
      nm_cidade: "Presidente Lucena",
      nm_cidade_uf: "Presidente Lucena - RS"
  },
  {
      id_cidade: "4181",
      id_estado: "23",
      nm_cidade: "Progresso",
      nm_cidade_uf: "Progresso - RS"
  },
  {
      id_cidade: "4182",
      id_estado: "23",
      nm_cidade: "Protásio Alves",
      nm_cidade_uf: "Protásio Alves - RS"
  },
  {
      id_cidade: "4183",
      id_estado: "23",
      nm_cidade: "Putinga",
      nm_cidade_uf: "Putinga - RS"
  },
  {
      id_cidade: "4184",
      id_estado: "23",
      nm_cidade: "Quaraí",
      nm_cidade_uf: "Quaraí - RS"
  },
  {
      id_cidade: "4185",
      id_estado: "23",
      nm_cidade: "Quatro Irmãos",
      nm_cidade_uf: "Quatro Irmãos - RS"
  },
  {
      id_cidade: "4186",
      id_estado: "23",
      nm_cidade: "Quevedos",
      nm_cidade_uf: "Quevedos - RS"
  },
  {
      id_cidade: "4187",
      id_estado: "23",
      nm_cidade: "Quinze de Novembro",
      nm_cidade_uf: "Quinze de Novembro - RS"
  },
  {
      id_cidade: "4188",
      id_estado: "23",
      nm_cidade: "Redentora",
      nm_cidade_uf: "Redentora - RS"
  },
  {
      id_cidade: "4189",
      id_estado: "23",
      nm_cidade: "Relvado",
      nm_cidade_uf: "Relvado - RS"
  },
  {
      id_cidade: "4190",
      id_estado: "23",
      nm_cidade: "Restinga Seca",
      nm_cidade_uf: "Restinga Seca - RS"
  },
  {
      id_cidade: "4191",
      id_estado: "23",
      nm_cidade: "Rio dos Índios",
      nm_cidade_uf: "Rio dos Índios - RS"
  },
  {
      id_cidade: "4192",
      id_estado: "23",
      nm_cidade: "Rio Grande",
      nm_cidade_uf: "Rio Grande - RS"
  },
  {
      id_cidade: "4193",
      id_estado: "23",
      nm_cidade: "Rio Pardo",
      nm_cidade_uf: "Rio Pardo - RS"
  },
  {
      id_cidade: "4194",
      id_estado: "23",
      nm_cidade: "Riozinho",
      nm_cidade_uf: "Riozinho - RS"
  },
  {
      id_cidade: "4195",
      id_estado: "23",
      nm_cidade: "Roca Sales",
      nm_cidade_uf: "Roca Sales - RS"
  },
  {
      id_cidade: "4196",
      id_estado: "23",
      nm_cidade: "Rodeio Bonito",
      nm_cidade_uf: "Rodeio Bonito - RS"
  },
  {
      id_cidade: "4197",
      id_estado: "23",
      nm_cidade: "Rolador",
      nm_cidade_uf: "Rolador - RS"
  },
  {
      id_cidade: "4198",
      id_estado: "23",
      nm_cidade: "Rolante",
      nm_cidade_uf: "Rolante - RS"
  },
  {
      id_cidade: "4199",
      id_estado: "23",
      nm_cidade: "Ronda Alta",
      nm_cidade_uf: "Ronda Alta - RS"
  },
  {
      id_cidade: "4200",
      id_estado: "23",
      nm_cidade: "Rondinha",
      nm_cidade_uf: "Rondinha - RS"
  },
  {
      id_cidade: "4201",
      id_estado: "23",
      nm_cidade: "Roque Gonzales",
      nm_cidade_uf: "Roque Gonzales - RS"
  },
  {
      id_cidade: "4202",
      id_estado: "23",
      nm_cidade: "Rosário do Sul",
      nm_cidade_uf: "Rosário do Sul - RS"
  },
  {
      id_cidade: "4203",
      id_estado: "23",
      nm_cidade: "Sagrada Família",
      nm_cidade_uf: "Sagrada Família - RS"
  },
  {
      id_cidade: "4204",
      id_estado: "23",
      nm_cidade: "Saldanha Marinho",
      nm_cidade_uf: "Saldanha Marinho - RS"
  },
  {
      id_cidade: "4205",
      id_estado: "23",
      nm_cidade: "Salto do Jacuí",
      nm_cidade_uf: "Salto do Jacuí - RS"
  },
  {
      id_cidade: "4206",
      id_estado: "23",
      nm_cidade: "Salvador das Missões",
      nm_cidade_uf: "Salvador das Missões - RS"
  },
  {
      id_cidade: "4207",
      id_estado: "23",
      nm_cidade: "Salvador do Sul",
      nm_cidade_uf: "Salvador do Sul - RS"
  },
  {
      id_cidade: "4208",
      id_estado: "23",
      nm_cidade: "Sananduva",
      nm_cidade_uf: "Sananduva - RS"
  },
  {
      id_cidade: "4209",
      id_estado: "23",
      nm_cidade: "Santa Bárbara do Sul",
      nm_cidade_uf: "Santa Bárbara do Sul - RS"
  },
  {
      id_cidade: "4210",
      id_estado: "23",
      nm_cidade: "Santa Cecília do Sul",
      nm_cidade_uf: "Santa Cecília do Sul - RS"
  },
  {
      id_cidade: "4211",
      id_estado: "23",
      nm_cidade: "Santa Clara do Sul",
      nm_cidade_uf: "Santa Clara do Sul - RS"
  },
  {
      id_cidade: "4212",
      id_estado: "23",
      nm_cidade: "Santa Cruz do Sul",
      nm_cidade_uf: "Santa Cruz do Sul - RS"
  },
  {
      id_cidade: "4213",
      id_estado: "23",
      nm_cidade: "Santa Margarida do Sul",
      nm_cidade_uf: "Santa Margarida do Sul - RS"
  },
  {
      id_cidade: "4214",
      id_estado: "23",
      nm_cidade: "Santa Maria",
      nm_cidade_uf: "Santa Maria - RS"
  },
  {
      id_cidade: "4215",
      id_estado: "23",
      nm_cidade: "Santa Maria do Herval",
      nm_cidade_uf: "Santa Maria do Herval - RS"
  },
  {
      id_cidade: "4216",
      id_estado: "23",
      nm_cidade: "Santa Rosa",
      nm_cidade_uf: "Santa Rosa - RS"
  },
  {
      id_cidade: "4217",
      id_estado: "23",
      nm_cidade: "Santa Tereza",
      nm_cidade_uf: "Santa Tereza - RS"
  },
  {
      id_cidade: "4218",
      id_estado: "23",
      nm_cidade: "Santa Vitória do Palmar",
      nm_cidade_uf: "Santa Vitória do Palmar - RS"
  },
  {
      id_cidade: "4219",
      id_estado: "23",
      nm_cidade: "Santana da Boa Vista",
      nm_cidade_uf: "Santana da Boa Vista - RS"
  },
  {
      id_cidade: "4220",
      id_estado: "23",
      nm_cidade: "Santana do Livramento",
      nm_cidade_uf: "Santana do Livramento - RS"
  },
  {
      id_cidade: "4221",
      id_estado: "23",
      nm_cidade: "Santiago",
      nm_cidade_uf: "Santiago - RS"
  },
  {
      id_cidade: "4222",
      id_estado: "23",
      nm_cidade: "Santo Ângelo",
      nm_cidade_uf: "Santo Ângelo - RS"
  },
  {
      id_cidade: "4223",
      id_estado: "23",
      nm_cidade: "Santo Antônio da Patrulha",
      nm_cidade_uf: "Santo Antônio da Patrulha - RS"
  },
  {
      id_cidade: "4224",
      id_estado: "23",
      nm_cidade: "Santo Antônio das Missões",
      nm_cidade_uf: "Santo Antônio das Missões - RS"
  },
  {
      id_cidade: "4225",
      id_estado: "23",
      nm_cidade: "Santo Antônio do Palma",
      nm_cidade_uf: "Santo Antônio do Palma - RS"
  },
  {
      id_cidade: "4226",
      id_estado: "23",
      nm_cidade: "Santo Antônio do Planalto",
      nm_cidade_uf: "Santo Antônio do Planalto - RS"
  },
  {
      id_cidade: "4227",
      id_estado: "23",
      nm_cidade: "Santo Augusto",
      nm_cidade_uf: "Santo Augusto - RS"
  },
  {
      id_cidade: "4228",
      id_estado: "23",
      nm_cidade: "Santo Cristo",
      nm_cidade_uf: "Santo Cristo - RS"
  },
  {
      id_cidade: "4229",
      id_estado: "23",
      nm_cidade: "Santo Expedito do Sul",
      nm_cidade_uf: "Santo Expedito do Sul - RS"
  },
  {
      id_cidade: "4230",
      id_estado: "23",
      nm_cidade: "São Borja",
      nm_cidade_uf: "São Borja - RS"
  },
  {
      id_cidade: "4231",
      id_estado: "23",
      nm_cidade: "São Domingos do Sul",
      nm_cidade_uf: "São Domingos do Sul - RS"
  },
  {
      id_cidade: "4232",
      id_estado: "23",
      nm_cidade: "São Francisco de Assis",
      nm_cidade_uf: "São Francisco de Assis - RS"
  },
  {
      id_cidade: "4233",
      id_estado: "23",
      nm_cidade: "São Francisco de Paula",
      nm_cidade_uf: "São Francisco de Paula - RS"
  },
  {
      id_cidade: "4234",
      id_estado: "23",
      nm_cidade: "São Gabriel",
      nm_cidade_uf: "São Gabriel - RS"
  },
  {
      id_cidade: "4235",
      id_estado: "23",
      nm_cidade: "São Jerônimo",
      nm_cidade_uf: "São Jerônimo - RS"
  },
  {
      id_cidade: "4236",
      id_estado: "23",
      nm_cidade: "São João da Urtiga",
      nm_cidade_uf: "São João da Urtiga - RS"
  },
  {
      id_cidade: "4237",
      id_estado: "23",
      nm_cidade: "São João do Polêsine",
      nm_cidade_uf: "São João do Polêsine - RS"
  },
  {
      id_cidade: "4238",
      id_estado: "23",
      nm_cidade: "São Jorge",
      nm_cidade_uf: "São Jorge - RS"
  },
  {
      id_cidade: "4239",
      id_estado: "23",
      nm_cidade: "São José das Missões",
      nm_cidade_uf: "São José das Missões - RS"
  },
  {
      id_cidade: "4240",
      id_estado: "23",
      nm_cidade: "São José do Herval",
      nm_cidade_uf: "São José do Herval - RS"
  },
  {
      id_cidade: "4241",
      id_estado: "23",
      nm_cidade: "São José do Hortêncio",
      nm_cidade_uf: "São José do Hortêncio - RS"
  },
  {
      id_cidade: "4242",
      id_estado: "23",
      nm_cidade: "São José do Inhacorá",
      nm_cidade_uf: "São José do Inhacorá - RS"
  },
  {
      id_cidade: "4243",
      id_estado: "23",
      nm_cidade: "São José do Norte",
      nm_cidade_uf: "São José do Norte - RS"
  },
  {
      id_cidade: "4244",
      id_estado: "23",
      nm_cidade: "São José do Ouro",
      nm_cidade_uf: "São José do Ouro - RS"
  },
  {
      id_cidade: "4245",
      id_estado: "23",
      nm_cidade: "São José do Sul",
      nm_cidade_uf: "São José do Sul - RS"
  },
  {
      id_cidade: "4246",
      id_estado: "23",
      nm_cidade: "São José dos Ausentes",
      nm_cidade_uf: "São José dos Ausentes - RS"
  },
  {
      id_cidade: "4247",
      id_estado: "23",
      nm_cidade: "São Leopoldo",
      nm_cidade_uf: "São Leopoldo - RS"
  },
  {
      id_cidade: "4248",
      id_estado: "23",
      nm_cidade: "São Lourenço do Sul",
      nm_cidade_uf: "São Lourenço do Sul - RS"
  },
  {
      id_cidade: "4249",
      id_estado: "23",
      nm_cidade: "São Luiz Gonzaga",
      nm_cidade_uf: "São Luiz Gonzaga - RS"
  },
  {
      id_cidade: "4250",
      id_estado: "23",
      nm_cidade: "São Marcos",
      nm_cidade_uf: "São Marcos - RS"
  },
  {
      id_cidade: "4251",
      id_estado: "23",
      nm_cidade: "São Martinho",
      nm_cidade_uf: "São Martinho - RS"
  },
  {
      id_cidade: "4252",
      id_estado: "23",
      nm_cidade: "São Martinho da Serra",
      nm_cidade_uf: "São Martinho da Serra - RS"
  },
  {
      id_cidade: "4253",
      id_estado: "23",
      nm_cidade: "São Miguel das Missões",
      nm_cidade_uf: "São Miguel das Missões - RS"
  },
  {
      id_cidade: "4254",
      id_estado: "23",
      nm_cidade: "São Nicolau",
      nm_cidade_uf: "São Nicolau - RS"
  },
  {
      id_cidade: "4255",
      id_estado: "23",
      nm_cidade: "São Paulo das Missões",
      nm_cidade_uf: "São Paulo das Missões - RS"
  },
  {
      id_cidade: "4256",
      id_estado: "23",
      nm_cidade: "São Pedro da Serra",
      nm_cidade_uf: "São Pedro da Serra - RS"
  },
  {
      id_cidade: "4257",
      id_estado: "23",
      nm_cidade: "São Pedro das Missões",
      nm_cidade_uf: "São Pedro das Missões - RS"
  },
  {
      id_cidade: "4258",
      id_estado: "23",
      nm_cidade: "São Pedro do Butiá",
      nm_cidade_uf: "São Pedro do Butiá - RS"
  },
  {
      id_cidade: "4259",
      id_estado: "23",
      nm_cidade: "São Pedro do Sul",
      nm_cidade_uf: "São Pedro do Sul - RS"
  },
  {
      id_cidade: "4260",
      id_estado: "23",
      nm_cidade: "São Sebastião do Caí",
      nm_cidade_uf: "São Sebastião do Caí - RS"
  },
  {
      id_cidade: "4261",
      id_estado: "23",
      nm_cidade: "São Sepé",
      nm_cidade_uf: "São Sepé - RS"
  },
  {
      id_cidade: "4262",
      id_estado: "23",
      nm_cidade: "São Valentim",
      nm_cidade_uf: "São Valentim - RS"
  },
  {
      id_cidade: "4263",
      id_estado: "23",
      nm_cidade: "São Valentim do Sul",
      nm_cidade_uf: "São Valentim do Sul - RS"
  },
  {
      id_cidade: "4264",
      id_estado: "23",
      nm_cidade: "São Valério do Sul",
      nm_cidade_uf: "São Valério do Sul - RS"
  },
  {
      id_cidade: "4265",
      id_estado: "23",
      nm_cidade: "São Vendelino",
      nm_cidade_uf: "São Vendelino - RS"
  },
  {
      id_cidade: "4266",
      id_estado: "23",
      nm_cidade: "São Vicente do Sul",
      nm_cidade_uf: "São Vicente do Sul - RS"
  },
  {
      id_cidade: "4267",
      id_estado: "23",
      nm_cidade: "Sapiranga",
      nm_cidade_uf: "Sapiranga - RS"
  },
  {
      id_cidade: "4268",
      id_estado: "23",
      nm_cidade: "Sapucaia do Sul",
      nm_cidade_uf: "Sapucaia do Sul - RS"
  },
  {
      id_cidade: "4269",
      id_estado: "23",
      nm_cidade: "Sarandi",
      nm_cidade_uf: "Sarandi - RS"
  },
  {
      id_cidade: "4270",
      id_estado: "23",
      nm_cidade: "Seberi",
      nm_cidade_uf: "Seberi - RS"
  },
  {
      id_cidade: "4271",
      id_estado: "23",
      nm_cidade: "Sede Nova",
      nm_cidade_uf: "Sede Nova - RS"
  },
  {
      id_cidade: "4272",
      id_estado: "23",
      nm_cidade: "Segredo",
      nm_cidade_uf: "Segredo - RS"
  },
  {
      id_cidade: "4273",
      id_estado: "23",
      nm_cidade: "Selbach",
      nm_cidade_uf: "Selbach - RS"
  },
  {
      id_cidade: "4274",
      id_estado: "23",
      nm_cidade: "Senador Salgado Filho",
      nm_cidade_uf: "Senador Salgado Filho - RS"
  },
  {
      id_cidade: "4275",
      id_estado: "23",
      nm_cidade: "Sentinela do Sul",
      nm_cidade_uf: "Sentinela do Sul - RS"
  },
  {
      id_cidade: "4276",
      id_estado: "23",
      nm_cidade: "Serafina Corrêa",
      nm_cidade_uf: "Serafina Corrêa - RS"
  },
  {
      id_cidade: "4277",
      id_estado: "23",
      nm_cidade: "Sério",
      nm_cidade_uf: "Sério - RS"
  },
  {
      id_cidade: "4278",
      id_estado: "23",
      nm_cidade: "Sertão",
      nm_cidade_uf: "Sertão - RS"
  },
  {
      id_cidade: "4279",
      id_estado: "23",
      nm_cidade: "Sertão Santana",
      nm_cidade_uf: "Sertão Santana - RS"
  },
  {
      id_cidade: "4280",
      id_estado: "23",
      nm_cidade: "Sete de Setembro",
      nm_cidade_uf: "Sete de Setembro - RS"
  },
  {
      id_cidade: "4281",
      id_estado: "23",
      nm_cidade: "Severiano de Almeida",
      nm_cidade_uf: "Severiano de Almeida - RS"
  },
  {
      id_cidade: "4282",
      id_estado: "23",
      nm_cidade: "Silveira Martins",
      nm_cidade_uf: "Silveira Martins - RS"
  },
  {
      id_cidade: "4283",
      id_estado: "23",
      nm_cidade: "Sinimbu",
      nm_cidade_uf: "Sinimbu - RS"
  },
  {
      id_cidade: "4284",
      id_estado: "23",
      nm_cidade: "Sobradinho",
      nm_cidade_uf: "Sobradinho - RS"
  },
  {
      id_cidade: "4285",
      id_estado: "23",
      nm_cidade: "Soledade",
      nm_cidade_uf: "Soledade - RS"
  },
  {
      id_cidade: "4286",
      id_estado: "23",
      nm_cidade: "Tabaí",
      nm_cidade_uf: "Tabaí - RS"
  },
  {
      id_cidade: "4287",
      id_estado: "23",
      nm_cidade: "Tapejara",
      nm_cidade_uf: "Tapejara - RS"
  },
  {
      id_cidade: "4288",
      id_estado: "23",
      nm_cidade: "Tapera",
      nm_cidade_uf: "Tapera - RS"
  },
  {
      id_cidade: "4289",
      id_estado: "23",
      nm_cidade: "Tapes",
      nm_cidade_uf: "Tapes - RS"
  },
  {
      id_cidade: "4290",
      id_estado: "23",
      nm_cidade: "Taquara",
      nm_cidade_uf: "Taquara - RS"
  },
  {
      id_cidade: "4291",
      id_estado: "23",
      nm_cidade: "Taquari",
      nm_cidade_uf: "Taquari - RS"
  },
  {
      id_cidade: "4292",
      id_estado: "23",
      nm_cidade: "Taquaruçu do Sul",
      nm_cidade_uf: "Taquaruçu do Sul - RS"
  },
  {
      id_cidade: "4293",
      id_estado: "23",
      nm_cidade: "Tavares",
      nm_cidade_uf: "Tavares - RS"
  },
  {
      id_cidade: "4294",
      id_estado: "23",
      nm_cidade: "Tenente Portela",
      nm_cidade_uf: "Tenente Portela - RS"
  },
  {
      id_cidade: "4295",
      id_estado: "23",
      nm_cidade: "Terra de Areia",
      nm_cidade_uf: "Terra de Areia - RS"
  },
  {
      id_cidade: "4296",
      id_estado: "23",
      nm_cidade: "Teutônia",
      nm_cidade_uf: "Teutônia - RS"
  },
  {
      id_cidade: "4297",
      id_estado: "23",
      nm_cidade: "Tio Hugo",
      nm_cidade_uf: "Tio Hugo - RS"
  },
  {
      id_cidade: "4298",
      id_estado: "23",
      nm_cidade: "Tiradentes do Sul",
      nm_cidade_uf: "Tiradentes do Sul - RS"
  },
  {
      id_cidade: "4299",
      id_estado: "23",
      nm_cidade: "Toropi",
      nm_cidade_uf: "Toropi - RS"
  },
  {
      id_cidade: "4300",
      id_estado: "23",
      nm_cidade: "Torres",
      nm_cidade_uf: "Torres - RS"
  },
  {
      id_cidade: "4301",
      id_estado: "23",
      nm_cidade: "Tramandaí",
      nm_cidade_uf: "Tramandaí - RS"
  },
  {
      id_cidade: "4302",
      id_estado: "23",
      nm_cidade: "Travesseiro",
      nm_cidade_uf: "Travesseiro - RS"
  },
  {
      id_cidade: "4303",
      id_estado: "23",
      nm_cidade: "Três Arroios",
      nm_cidade_uf: "Três Arroios - RS"
  },
  {
      id_cidade: "4304",
      id_estado: "23",
      nm_cidade: "Três Cachoeiras",
      nm_cidade_uf: "Três Cachoeiras - RS"
  },
  {
      id_cidade: "4305",
      id_estado: "23",
      nm_cidade: "Três Coroas",
      nm_cidade_uf: "Três Coroas - RS"
  },
  {
      id_cidade: "4306",
      id_estado: "23",
      nm_cidade: "Três de Maio",
      nm_cidade_uf: "Três de Maio - RS"
  },
  {
      id_cidade: "4307",
      id_estado: "23",
      nm_cidade: "Três Forquilhas",
      nm_cidade_uf: "Três Forquilhas - RS"
  },
  {
      id_cidade: "4308",
      id_estado: "23",
      nm_cidade: "Três Palmeiras",
      nm_cidade_uf: "Três Palmeiras - RS"
  },
  {
      id_cidade: "4309",
      id_estado: "23",
      nm_cidade: "Três Passos",
      nm_cidade_uf: "Três Passos - RS"
  },
  {
      id_cidade: "4310",
      id_estado: "23",
      nm_cidade: "Trindade do Sul",
      nm_cidade_uf: "Trindade do Sul - RS"
  },
  {
      id_cidade: "4311",
      id_estado: "23",
      nm_cidade: "Triunfo",
      nm_cidade_uf: "Triunfo - RS"
  },
  {
      id_cidade: "4312",
      id_estado: "23",
      nm_cidade: "Tucunduva",
      nm_cidade_uf: "Tucunduva - RS"
  },
  {
      id_cidade: "4313",
      id_estado: "23",
      nm_cidade: "Tunas",
      nm_cidade_uf: "Tunas - RS"
  },
  {
      id_cidade: "4314",
      id_estado: "23",
      nm_cidade: "Tupanci do Sul",
      nm_cidade_uf: "Tupanci do Sul - RS"
  },
  {
      id_cidade: "4315",
      id_estado: "23",
      nm_cidade: "Tupanciretã",
      nm_cidade_uf: "Tupanciretã - RS"
  },
  {
      id_cidade: "4316",
      id_estado: "23",
      nm_cidade: "Tupandi",
      nm_cidade_uf: "Tupandi - RS"
  },
  {
      id_cidade: "4317",
      id_estado: "23",
      nm_cidade: "Tuparendi",
      nm_cidade_uf: "Tuparendi - RS"
  },
  {
      id_cidade: "4318",
      id_estado: "23",
      nm_cidade: "Turuçu",
      nm_cidade_uf: "Turuçu - RS"
  },
  {
      id_cidade: "4319",
      id_estado: "23",
      nm_cidade: "Ubiretama",
      nm_cidade_uf: "Ubiretama - RS"
  },
  {
      id_cidade: "4320",
      id_estado: "23",
      nm_cidade: "União da Serra",
      nm_cidade_uf: "União da Serra - RS"
  },
  {
      id_cidade: "4321",
      id_estado: "23",
      nm_cidade: "Unistalda",
      nm_cidade_uf: "Unistalda - RS"
  },
  {
      id_cidade: "4322",
      id_estado: "23",
      nm_cidade: "Uruguaiana",
      nm_cidade_uf: "Uruguaiana - RS"
  },
  {
      id_cidade: "4323",
      id_estado: "23",
      nm_cidade: "Vacaria",
      nm_cidade_uf: "Vacaria - RS"
  },
  {
      id_cidade: "4324",
      id_estado: "23",
      nm_cidade: "Vale do Sol",
      nm_cidade_uf: "Vale do Sol - RS"
  },
  {
      id_cidade: "4325",
      id_estado: "23",
      nm_cidade: "Vale Real",
      nm_cidade_uf: "Vale Real - RS"
  },
  {
      id_cidade: "4326",
      id_estado: "23",
      nm_cidade: "Vale Verde",
      nm_cidade_uf: "Vale Verde - RS"
  },
  {
      id_cidade: "4327",
      id_estado: "23",
      nm_cidade: "Vanini",
      nm_cidade_uf: "Vanini - RS"
  },
  {
      id_cidade: "4328",
      id_estado: "23",
      nm_cidade: "Venâncio Aires",
      nm_cidade_uf: "Venâncio Aires - RS"
  },
  {
      id_cidade: "4329",
      id_estado: "23",
      nm_cidade: "Vera Cruz",
      nm_cidade_uf: "Vera Cruz - RS"
  },
  {
      id_cidade: "4330",
      id_estado: "23",
      nm_cidade: "Veranópolis",
      nm_cidade_uf: "Veranópolis - RS"
  },
  {
      id_cidade: "4331",
      id_estado: "23",
      nm_cidade: "Vespasiano Correa",
      nm_cidade_uf: "Vespasiano Correa - RS"
  },
  {
      id_cidade: "4332",
      id_estado: "23",
      nm_cidade: "Viadutos",
      nm_cidade_uf: "Viadutos - RS"
  },
  {
      id_cidade: "4333",
      id_estado: "23",
      nm_cidade: "Viamão",
      nm_cidade_uf: "Viamão - RS"
  },
  {
      id_cidade: "4334",
      id_estado: "23",
      nm_cidade: "Vicente Dutra",
      nm_cidade_uf: "Vicente Dutra - RS"
  },
  {
      id_cidade: "4335",
      id_estado: "23",
      nm_cidade: "Victor Graeff",
      nm_cidade_uf: "Victor Graeff - RS"
  },
  {
      id_cidade: "4336",
      id_estado: "23",
      nm_cidade: "Vila Flores",
      nm_cidade_uf: "Vila Flores - RS"
  },
  {
      id_cidade: "4337",
      id_estado: "23",
      nm_cidade: "Vila Lângaro",
      nm_cidade_uf: "Vila Lângaro - RS"
  },
  {
      id_cidade: "4338",
      id_estado: "23",
      nm_cidade: "Vila Maria",
      nm_cidade_uf: "Vila Maria - RS"
  },
  {
      id_cidade: "4339",
      id_estado: "23",
      nm_cidade: "Vila Nova do Sul",
      nm_cidade_uf: "Vila Nova do Sul - RS"
  },
  {
      id_cidade: "4340",
      id_estado: "23",
      nm_cidade: "Vista Alegre",
      nm_cidade_uf: "Vista Alegre - RS"
  },
  {
      id_cidade: "4341",
      id_estado: "23",
      nm_cidade: "Vista Alegre do Prata",
      nm_cidade_uf: "Vista Alegre do Prata - RS"
  },
  {
      id_cidade: "4342",
      id_estado: "23",
      nm_cidade: "Vista Gaúcha",
      nm_cidade_uf: "Vista Gaúcha - RS"
  },
  {
      id_cidade: "4343",
      id_estado: "23",
      nm_cidade: "Vitória das Missões",
      nm_cidade_uf: "Vitória das Missões - RS"
  },
  {
      id_cidade: "4344",
      id_estado: "23",
      nm_cidade: "Westfália",
      nm_cidade_uf: "Westfália - RS"
  },
  {
      id_cidade: "4345",
      id_estado: "23",
      nm_cidade: "Xangri-lá",
      nm_cidade_uf: "Xangri-lá - RS"
  },
  {
      id_cidade: "4346",
      id_estado: "21",
      nm_cidade: "Alta Floresta d`Oeste",
      nm_cidade_uf: "Alta Floresta d`Oeste - RO"
  },
  {
      id_cidade: "4347",
      id_estado: "21",
      nm_cidade: "Alto Alegre dos Parecis",
      nm_cidade_uf: "Alto Alegre dos Parecis - RO"
  },
  {
      id_cidade: "4348",
      id_estado: "21",
      nm_cidade: "Alto Paraíso",
      nm_cidade_uf: "Alto Paraíso - RO"
  },
  {
      id_cidade: "4349",
      id_estado: "21",
      nm_cidade: "Alvorada d`Oeste",
      nm_cidade_uf: "Alvorada d`Oeste - RO"
  },
  {
      id_cidade: "4350",
      id_estado: "21",
      nm_cidade: "Ariquemes",
      nm_cidade_uf: "Ariquemes - RO"
  },
  {
      id_cidade: "4351",
      id_estado: "21",
      nm_cidade: "Buritis",
      nm_cidade_uf: "Buritis - RO"
  },
  {
      id_cidade: "4352",
      id_estado: "21",
      nm_cidade: "Cabixi",
      nm_cidade_uf: "Cabixi - RO"
  },
  {
      id_cidade: "4353",
      id_estado: "21",
      nm_cidade: "Cacaulândia",
      nm_cidade_uf: "Cacaulândia - RO"
  },
  {
      id_cidade: "4354",
      id_estado: "21",
      nm_cidade: "Cacoal",
      nm_cidade_uf: "Cacoal - RO"
  },
  {
      id_cidade: "4355",
      id_estado: "21",
      nm_cidade: "Campo Novo de Rondônia",
      nm_cidade_uf: "Campo Novo de Rondônia - RO"
  },
  {
      id_cidade: "4356",
      id_estado: "21",
      nm_cidade: "Candeias do Jamari",
      nm_cidade_uf: "Candeias do Jamari - RO"
  },
  {
      id_cidade: "4357",
      id_estado: "21",
      nm_cidade: "Castanheiras",
      nm_cidade_uf: "Castanheiras - RO"
  },
  {
      id_cidade: "4358",
      id_estado: "21",
      nm_cidade: "Cerejeiras",
      nm_cidade_uf: "Cerejeiras - RO"
  },
  {
      id_cidade: "4359",
      id_estado: "21",
      nm_cidade: "Chupinguaia",
      nm_cidade_uf: "Chupinguaia - RO"
  },
  {
      id_cidade: "4360",
      id_estado: "21",
      nm_cidade: "Colorado do Oeste",
      nm_cidade_uf: "Colorado do Oeste - RO"
  },
  {
      id_cidade: "4361",
      id_estado: "21",
      nm_cidade: "Corumbiara",
      nm_cidade_uf: "Corumbiara - RO"
  },
  {
      id_cidade: "4362",
      id_estado: "21",
      nm_cidade: "Costa Marques",
      nm_cidade_uf: "Costa Marques - RO"
  },
  {
      id_cidade: "4363",
      id_estado: "21",
      nm_cidade: "Cujubim",
      nm_cidade_uf: "Cujubim - RO"
  },
  {
      id_cidade: "4364",
      id_estado: "21",
      nm_cidade: "Espigão d`Oeste",
      nm_cidade_uf: "Espigão d`Oeste - RO"
  },
  {
      id_cidade: "4365",
      id_estado: "21",
      nm_cidade: "Governador Jorge Teixeira",
      nm_cidade_uf: "Governador Jorge Teixeira - RO"
  },
  {
      id_cidade: "4366",
      id_estado: "21",
      nm_cidade: "Guajará-Mirim",
      nm_cidade_uf: "Guajará-Mirim - RO"
  },
  {
      id_cidade: "4367",
      id_estado: "21",
      nm_cidade: "Itapuã do Oeste",
      nm_cidade_uf: "Itapuã do Oeste - RO"
  },
  {
      id_cidade: "4368",
      id_estado: "21",
      nm_cidade: "Jaru",
      nm_cidade_uf: "Jaru - RO"
  },
  {
      id_cidade: "4369",
      id_estado: "21",
      nm_cidade: "Ji-Paraná",
      nm_cidade_uf: "Ji-Paraná - RO"
  },
  {
      id_cidade: "4370",
      id_estado: "21",
      nm_cidade: "Machadinho d`Oeste",
      nm_cidade_uf: "Machadinho d`Oeste - RO"
  },
  {
      id_cidade: "4371",
      id_estado: "21",
      nm_cidade: "Ministro Andreazza",
      nm_cidade_uf: "Ministro Andreazza - RO"
  },
  {
      id_cidade: "4372",
      id_estado: "21",
      nm_cidade: "Mirante da Serra",
      nm_cidade_uf: "Mirante da Serra - RO"
  },
  {
      id_cidade: "4373",
      id_estado: "21",
      nm_cidade: "Monte Negro",
      nm_cidade_uf: "Monte Negro - RO"
  },
  {
      id_cidade: "4374",
      id_estado: "21",
      nm_cidade: "Nova Brasilândia d`Oeste",
      nm_cidade_uf: "Nova Brasilândia d`Oeste - RO"
  },
  {
      id_cidade: "4375",
      id_estado: "21",
      nm_cidade: "Nova Mamoré",
      nm_cidade_uf: "Nova Mamoré - RO"
  },
  {
      id_cidade: "4376",
      id_estado: "21",
      nm_cidade: "Nova União",
      nm_cidade_uf: "Nova União - RO"
  },
  {
      id_cidade: "4377",
      id_estado: "21",
      nm_cidade: "Novo Horizonte do Oeste",
      nm_cidade_uf: "Novo Horizonte do Oeste - RO"
  },
  {
      id_cidade: "4378",
      id_estado: "21",
      nm_cidade: "Ouro Preto do Oeste",
      nm_cidade_uf: "Ouro Preto do Oeste - RO"
  },
  {
      id_cidade: "4379",
      id_estado: "21",
      nm_cidade: "Parecis",
      nm_cidade_uf: "Parecis - RO"
  },
  {
      id_cidade: "4380",
      id_estado: "21",
      nm_cidade: "Pimenta Bueno",
      nm_cidade_uf: "Pimenta Bueno - RO"
  },
  {
      id_cidade: "4381",
      id_estado: "21",
      nm_cidade: "Pimenteiras do Oeste",
      nm_cidade_uf: "Pimenteiras do Oeste - RO"
  },
  {
      id_cidade: "4382",
      id_estado: "21",
      nm_cidade: "Porto Velho",
      nm_cidade_uf: "Porto Velho - RO"
  },
  {
      id_cidade: "4383",
      id_estado: "21",
      nm_cidade: "Presidente Médici",
      nm_cidade_uf: "Presidente Médici - RO"
  },
  {
      id_cidade: "4384",
      id_estado: "21",
      nm_cidade: "Primavera de Rondônia",
      nm_cidade_uf: "Primavera de Rondônia - RO"
  },
  {
      id_cidade: "4385",
      id_estado: "21",
      nm_cidade: "Rio Crespo",
      nm_cidade_uf: "Rio Crespo - RO"
  },
  {
      id_cidade: "4386",
      id_estado: "21",
      nm_cidade: "Rolim de Moura",
      nm_cidade_uf: "Rolim de Moura - RO"
  },
  {
      id_cidade: "4387",
      id_estado: "21",
      nm_cidade: "Santa Luzia d`Oeste",
      nm_cidade_uf: "Santa Luzia d`Oeste - RO"
  },
  {
      id_cidade: "4388",
      id_estado: "21",
      nm_cidade: "São Felipe d`Oeste",
      nm_cidade_uf: "São Felipe d`Oeste - RO"
  },
  {
      id_cidade: "4389",
      id_estado: "21",
      nm_cidade: "São Francisco do Guaporé",
      nm_cidade_uf: "São Francisco do Guaporé - RO"
  },
  {
      id_cidade: "4390",
      id_estado: "21",
      nm_cidade: "São Miguel do Guaporé",
      nm_cidade_uf: "São Miguel do Guaporé - RO"
  },
  {
      id_cidade: "4391",
      id_estado: "21",
      nm_cidade: "Seringueiras",
      nm_cidade_uf: "Seringueiras - RO"
  },
  {
      id_cidade: "4392",
      id_estado: "21",
      nm_cidade: "Teixeirópolis",
      nm_cidade_uf: "Teixeirópolis - RO"
  },
  {
      id_cidade: "4393",
      id_estado: "21",
      nm_cidade: "Theobroma",
      nm_cidade_uf: "Theobroma - RO"
  },
  {
      id_cidade: "4394",
      id_estado: "21",
      nm_cidade: "Urupá",
      nm_cidade_uf: "Urupá - RO"
  },
  {
      id_cidade: "4395",
      id_estado: "21",
      nm_cidade: "Vale do Anari",
      nm_cidade_uf: "Vale do Anari - RO"
  },
  {
      id_cidade: "4396",
      id_estado: "21",
      nm_cidade: "Vale do Paraíso",
      nm_cidade_uf: "Vale do Paraíso - RO"
  },
  {
      id_cidade: "4397",
      id_estado: "21",
      nm_cidade: "Vilhena",
      nm_cidade_uf: "Vilhena - RO"
  },
  {
      id_cidade: "4398",
      id_estado: "22",
      nm_cidade: "Alto Alegre",
      nm_cidade_uf: "Alto Alegre - RR"
  },
  {
      id_cidade: "4399",
      id_estado: "22",
      nm_cidade: "Amajari",
      nm_cidade_uf: "Amajari - RR"
  },
  {
      id_cidade: "4400",
      id_estado: "22",
      nm_cidade: "Boa Vista",
      nm_cidade_uf: "Boa Vista - RR"
  },
  {
      id_cidade: "4401",
      id_estado: "22",
      nm_cidade: "Bonfim",
      nm_cidade_uf: "Bonfim - RR"
  },
  {
      id_cidade: "4402",
      id_estado: "22",
      nm_cidade: "Cantá",
      nm_cidade_uf: "Cantá - RR"
  },
  {
      id_cidade: "4403",
      id_estado: "22",
      nm_cidade: "Caracaraí",
      nm_cidade_uf: "Caracaraí - RR"
  },
  {
      id_cidade: "4404",
      id_estado: "22",
      nm_cidade: "Caroebe",
      nm_cidade_uf: "Caroebe - RR"
  },
  {
      id_cidade: "4405",
      id_estado: "22",
      nm_cidade: "Iracema",
      nm_cidade_uf: "Iracema - RR"
  },
  {
      id_cidade: "4406",
      id_estado: "22",
      nm_cidade: "Mucajaí",
      nm_cidade_uf: "Mucajaí - RR"
  },
  {
      id_cidade: "4407",
      id_estado: "22",
      nm_cidade: "Normandia",
      nm_cidade_uf: "Normandia - RR"
  },
  {
      id_cidade: "4408",
      id_estado: "22",
      nm_cidade: "Pacaraima",
      nm_cidade_uf: "Pacaraima - RR"
  },
  {
      id_cidade: "4409",
      id_estado: "22",
      nm_cidade: "Rorainópolis",
      nm_cidade_uf: "Rorainópolis - RR"
  },
  {
      id_cidade: "4410",
      id_estado: "22",
      nm_cidade: "São João da Baliza",
      nm_cidade_uf: "São João da Baliza - RR"
  },
  {
      id_cidade: "4411",
      id_estado: "22",
      nm_cidade: "São Luiz",
      nm_cidade_uf: "São Luiz - RR"
  },
  {
      id_cidade: "4412",
      id_estado: "22",
      nm_cidade: "Uiramutã",
      nm_cidade_uf: "Uiramutã - RR"
  },
  {
      id_cidade: "4413",
      id_estado: "24",
      nm_cidade: "Abdon Batista",
      nm_cidade_uf: "Abdon Batista - SC"
  },
  {
      id_cidade: "4414",
      id_estado: "24",
      nm_cidade: "Abelardo Luz",
      nm_cidade_uf: "Abelardo Luz - SC"
  },
  {
      id_cidade: "4415",
      id_estado: "24",
      nm_cidade: "Agrolândia",
      nm_cidade_uf: "Agrolândia - SC"
  },
  {
      id_cidade: "4416",
      id_estado: "24",
      nm_cidade: "Agronômica",
      nm_cidade_uf: "Agronômica - SC"
  },
  {
      id_cidade: "4417",
      id_estado: "24",
      nm_cidade: "Água Doce",
      nm_cidade_uf: "Água Doce - SC"
  },
  {
      id_cidade: "4418",
      id_estado: "24",
      nm_cidade: "Águas de Chapecó",
      nm_cidade_uf: "Águas de Chapecó - SC"
  },
  {
      id_cidade: "4419",
      id_estado: "24",
      nm_cidade: "Águas Frias",
      nm_cidade_uf: "Águas Frias - SC"
  },
  {
      id_cidade: "4420",
      id_estado: "24",
      nm_cidade: "Águas Mornas",
      nm_cidade_uf: "Águas Mornas - SC"
  },
  {
      id_cidade: "4421",
      id_estado: "24",
      nm_cidade: "Alfredo Wagner",
      nm_cidade_uf: "Alfredo Wagner - SC"
  },
  {
      id_cidade: "4422",
      id_estado: "24",
      nm_cidade: "Alto Bela Vista",
      nm_cidade_uf: "Alto Bela Vista - SC"
  },
  {
      id_cidade: "4423",
      id_estado: "24",
      nm_cidade: "Anchieta",
      nm_cidade_uf: "Anchieta - SC"
  },
  {
      id_cidade: "4424",
      id_estado: "24",
      nm_cidade: "Angelina",
      nm_cidade_uf: "Angelina - SC"
  },
  {
      id_cidade: "4425",
      id_estado: "24",
      nm_cidade: "Anita Garibaldi",
      nm_cidade_uf: "Anita Garibaldi - SC"
  },
  {
      id_cidade: "4426",
      id_estado: "24",
      nm_cidade: "Anitápolis",
      nm_cidade_uf: "Anitápolis - SC"
  },
  {
      id_cidade: "4427",
      id_estado: "24",
      nm_cidade: "Antônio Carlos",
      nm_cidade_uf: "Antônio Carlos - SC"
  },
  {
      id_cidade: "4428",
      id_estado: "24",
      nm_cidade: "Apiúna",
      nm_cidade_uf: "Apiúna - SC"
  },
  {
      id_cidade: "4429",
      id_estado: "24",
      nm_cidade: "Arabutã",
      nm_cidade_uf: "Arabutã - SC"
  },
  {
      id_cidade: "4430",
      id_estado: "24",
      nm_cidade: "Araquari",
      nm_cidade_uf: "Araquari - SC"
  },
  {
      id_cidade: "4431",
      id_estado: "24",
      nm_cidade: "Araranguá",
      nm_cidade_uf: "Araranguá - SC"
  },
  {
      id_cidade: "4432",
      id_estado: "24",
      nm_cidade: "Armazém",
      nm_cidade_uf: "Armazém - SC"
  },
  {
      id_cidade: "4433",
      id_estado: "24",
      nm_cidade: "Arroio Trinta",
      nm_cidade_uf: "Arroio Trinta - SC"
  },
  {
      id_cidade: "4434",
      id_estado: "24",
      nm_cidade: "Arvoredo",
      nm_cidade_uf: "Arvoredo - SC"
  },
  {
      id_cidade: "4435",
      id_estado: "24",
      nm_cidade: "Ascurra",
      nm_cidade_uf: "Ascurra - SC"
  },
  {
      id_cidade: "4436",
      id_estado: "24",
      nm_cidade: "Atalanta",
      nm_cidade_uf: "Atalanta - SC"
  },
  {
      id_cidade: "4437",
      id_estado: "24",
      nm_cidade: "Aurora",
      nm_cidade_uf: "Aurora - SC"
  },
  {
      id_cidade: "4438",
      id_estado: "24",
      nm_cidade: "Balneário Arroio do Silva",
      nm_cidade_uf: "Balneário Arroio do Silva - SC"
  },
  {
      id_cidade: "4439",
      id_estado: "24",
      nm_cidade: "Balneário Barra do Sul",
      nm_cidade_uf: "Balneário Barra do Sul - SC"
  },
  {
      id_cidade: "4440",
      id_estado: "24",
      nm_cidade: "Balneário Camboriú",
      nm_cidade_uf: "Balneário Camboriú - SC"
  },
  {
      id_cidade: "4441",
      id_estado: "24",
      nm_cidade: "Balneário Gaivota",
      nm_cidade_uf: "Balneário Gaivota - SC"
  },
  {
      id_cidade: "4442",
      id_estado: "24",
      nm_cidade: "Bandeirante",
      nm_cidade_uf: "Bandeirante - SC"
  },
  {
      id_cidade: "4443",
      id_estado: "24",
      nm_cidade: "Barra Bonita",
      nm_cidade_uf: "Barra Bonita - SC"
  },
  {
      id_cidade: "4444",
      id_estado: "24",
      nm_cidade: "Barra Velha",
      nm_cidade_uf: "Barra Velha - SC"
  },
  {
      id_cidade: "4445",
      id_estado: "24",
      nm_cidade: "Bela Vista do Toldo",
      nm_cidade_uf: "Bela Vista do Toldo - SC"
  },
  {
      id_cidade: "4446",
      id_estado: "24",
      nm_cidade: "Belmonte",
      nm_cidade_uf: "Belmonte - SC"
  },
  {
      id_cidade: "4447",
      id_estado: "24",
      nm_cidade: "Benedito Novo",
      nm_cidade_uf: "Benedito Novo - SC"
  },
  {
      id_cidade: "4448",
      id_estado: "24",
      nm_cidade: "Biguaçu",
      nm_cidade_uf: "Biguaçu - SC"
  },
  {
      id_cidade: "4449",
      id_estado: "24",
      nm_cidade: "Blumenau",
      nm_cidade_uf: "Blumenau - SC"
  },
  {
      id_cidade: "4450",
      id_estado: "24",
      nm_cidade: "Bocaina do Sul",
      nm_cidade_uf: "Bocaina do Sul - SC"
  },
  {
      id_cidade: "4451",
      id_estado: "24",
      nm_cidade: "Bom Jardim da Serra",
      nm_cidade_uf: "Bom Jardim da Serra - SC"
  },
  {
      id_cidade: "4452",
      id_estado: "24",
      nm_cidade: "Bom Jesus",
      nm_cidade_uf: "Bom Jesus - SC"
  },
  {
      id_cidade: "4453",
      id_estado: "24",
      nm_cidade: "Bom Jesus do Oeste",
      nm_cidade_uf: "Bom Jesus do Oeste - SC"
  },
  {
      id_cidade: "4454",
      id_estado: "24",
      nm_cidade: "Bom Retiro",
      nm_cidade_uf: "Bom Retiro - SC"
  },
  {
      id_cidade: "4455",
      id_estado: "24",
      nm_cidade: "Bombinhas",
      nm_cidade_uf: "Bombinhas - SC"
  },
  {
      id_cidade: "4456",
      id_estado: "24",
      nm_cidade: "Botuverá",
      nm_cidade_uf: "Botuverá - SC"
  },
  {
      id_cidade: "4457",
      id_estado: "24",
      nm_cidade: "Braço do Norte",
      nm_cidade_uf: "Braço do Norte - SC"
  },
  {
      id_cidade: "4458",
      id_estado: "24",
      nm_cidade: "Braço do Trombudo",
      nm_cidade_uf: "Braço do Trombudo - SC"
  },
  {
      id_cidade: "4459",
      id_estado: "24",
      nm_cidade: "Brunópolis",
      nm_cidade_uf: "Brunópolis - SC"
  },
  {
      id_cidade: "4460",
      id_estado: "24",
      nm_cidade: "Brusque",
      nm_cidade_uf: "Brusque - SC"
  },
  {
      id_cidade: "4461",
      id_estado: "24",
      nm_cidade: "Caçador",
      nm_cidade_uf: "Caçador - SC"
  },
  {
      id_cidade: "4462",
      id_estado: "24",
      nm_cidade: "Caibi",
      nm_cidade_uf: "Caibi - SC"
  },
  {
      id_cidade: "4463",
      id_estado: "24",
      nm_cidade: "Calmon",
      nm_cidade_uf: "Calmon - SC"
  },
  {
      id_cidade: "4464",
      id_estado: "24",
      nm_cidade: "Camboriú",
      nm_cidade_uf: "Camboriú - SC"
  },
  {
      id_cidade: "4465",
      id_estado: "24",
      nm_cidade: "Campo Alegre",
      nm_cidade_uf: "Campo Alegre - SC"
  },
  {
      id_cidade: "4466",
      id_estado: "24",
      nm_cidade: "Campo Belo do Sul",
      nm_cidade_uf: "Campo Belo do Sul - SC"
  },
  {
      id_cidade: "4467",
      id_estado: "24",
      nm_cidade: "Campo Erê",
      nm_cidade_uf: "Campo Erê - SC"
  },
  {
      id_cidade: "4468",
      id_estado: "24",
      nm_cidade: "Campos Novos",
      nm_cidade_uf: "Campos Novos - SC"
  },
  {
      id_cidade: "4469",
      id_estado: "24",
      nm_cidade: "Canelinha",
      nm_cidade_uf: "Canelinha - SC"
  },
  {
      id_cidade: "4470",
      id_estado: "24",
      nm_cidade: "Canoinhas",
      nm_cidade_uf: "Canoinhas - SC"
  },
  {
      id_cidade: "4471",
      id_estado: "24",
      nm_cidade: "Capão Alto",
      nm_cidade_uf: "Capão Alto - SC"
  },
  {
      id_cidade: "4472",
      id_estado: "24",
      nm_cidade: "Capinzal",
      nm_cidade_uf: "Capinzal - SC"
  },
  {
      id_cidade: "4473",
      id_estado: "24",
      nm_cidade: "Capivari de Baixo",
      nm_cidade_uf: "Capivari de Baixo - SC"
  },
  {
      id_cidade: "4474",
      id_estado: "24",
      nm_cidade: "Catanduvas",
      nm_cidade_uf: "Catanduvas - SC"
  },
  {
      id_cidade: "4475",
      id_estado: "24",
      nm_cidade: "Caxambu do Sul",
      nm_cidade_uf: "Caxambu do Sul - SC"
  },
  {
      id_cidade: "4476",
      id_estado: "24",
      nm_cidade: "Celso Ramos",
      nm_cidade_uf: "Celso Ramos - SC"
  },
  {
      id_cidade: "4477",
      id_estado: "24",
      nm_cidade: "Cerro Negro",
      nm_cidade_uf: "Cerro Negro - SC"
  },
  {
      id_cidade: "4478",
      id_estado: "24",
      nm_cidade: "Chapadão do Lageado",
      nm_cidade_uf: "Chapadão do Lageado - SC"
  },
  {
      id_cidade: "4479",
      id_estado: "24",
      nm_cidade: "Chapecó",
      nm_cidade_uf: "Chapecó - SC"
  },
  {
      id_cidade: "4480",
      id_estado: "24",
      nm_cidade: "Cocal do Sul",
      nm_cidade_uf: "Cocal do Sul - SC"
  },
  {
      id_cidade: "4481",
      id_estado: "24",
      nm_cidade: "Concórdia",
      nm_cidade_uf: "Concórdia - SC"
  },
  {
      id_cidade: "4482",
      id_estado: "24",
      nm_cidade: "Cordilheira Alta",
      nm_cidade_uf: "Cordilheira Alta - SC"
  },
  {
      id_cidade: "4483",
      id_estado: "24",
      nm_cidade: "Coronel Freitas",
      nm_cidade_uf: "Coronel Freitas - SC"
  },
  {
      id_cidade: "4484",
      id_estado: "24",
      nm_cidade: "Coronel Martins",
      nm_cidade_uf: "Coronel Martins - SC"
  },
  {
      id_cidade: "4485",
      id_estado: "24",
      nm_cidade: "Correia Pinto",
      nm_cidade_uf: "Correia Pinto - SC"
  },
  {
      id_cidade: "4486",
      id_estado: "24",
      nm_cidade: "Corupá",
      nm_cidade_uf: "Corupá - SC"
  },
  {
      id_cidade: "4487",
      id_estado: "24",
      nm_cidade: "Criciúma",
      nm_cidade_uf: "Criciúma - SC"
  },
  {
      id_cidade: "4488",
      id_estado: "24",
      nm_cidade: "Cunha Porã",
      nm_cidade_uf: "Cunha Porã - SC"
  },
  {
      id_cidade: "4489",
      id_estado: "24",
      nm_cidade: "Cunhataí",
      nm_cidade_uf: "Cunhataí - SC"
  },
  {
      id_cidade: "4490",
      id_estado: "24",
      nm_cidade: "Curitibanos",
      nm_cidade_uf: "Curitibanos - SC"
  },
  {
      id_cidade: "4491",
      id_estado: "24",
      nm_cidade: "Descanso",
      nm_cidade_uf: "Descanso - SC"
  },
  {
      id_cidade: "4492",
      id_estado: "24",
      nm_cidade: "Dionísio Cerqueira",
      nm_cidade_uf: "Dionísio Cerqueira - SC"
  },
  {
      id_cidade: "4493",
      id_estado: "24",
      nm_cidade: "Dona Emma",
      nm_cidade_uf: "Dona Emma - SC"
  },
  {
      id_cidade: "4494",
      id_estado: "24",
      nm_cidade: "Doutor Pedrinho",
      nm_cidade_uf: "Doutor Pedrinho - SC"
  },
  {
      id_cidade: "4495",
      id_estado: "24",
      nm_cidade: "Entre Rios",
      nm_cidade_uf: "Entre Rios - SC"
  },
  {
      id_cidade: "4496",
      id_estado: "24",
      nm_cidade: "Ermo",
      nm_cidade_uf: "Ermo - SC"
  },
  {
      id_cidade: "4497",
      id_estado: "24",
      nm_cidade: "Erval Velho",
      nm_cidade_uf: "Erval Velho - SC"
  },
  {
      id_cidade: "4498",
      id_estado: "24",
      nm_cidade: "Faxinal dos Guedes",
      nm_cidade_uf: "Faxinal dos Guedes - SC"
  },
  {
      id_cidade: "4499",
      id_estado: "24",
      nm_cidade: "Flor do Sertão",
      nm_cidade_uf: "Flor do Sertão - SC"
  },
  {
      id_cidade: "4500",
      id_estado: "24",
      nm_cidade: "Florianópolis",
      nm_cidade_uf: "Florianópolis - SC"
  },
  {
      id_cidade: "4501",
      id_estado: "24",
      nm_cidade: "Formosa do Sul",
      nm_cidade_uf: "Formosa do Sul - SC"
  },
  {
      id_cidade: "4502",
      id_estado: "24",
      nm_cidade: "Forquilhinha",
      nm_cidade_uf: "Forquilhinha - SC"
  },
  {
      id_cidade: "4503",
      id_estado: "24",
      nm_cidade: "Fraiburgo",
      nm_cidade_uf: "Fraiburgo - SC"
  },
  {
      id_cidade: "4504",
      id_estado: "24",
      nm_cidade: "Frei Rogério",
      nm_cidade_uf: "Frei Rogério - SC"
  },
  {
      id_cidade: "4505",
      id_estado: "24",
      nm_cidade: "Galvão",
      nm_cidade_uf: "Galvão - SC"
  },
  {
      id_cidade: "4506",
      id_estado: "24",
      nm_cidade: "Garopaba",
      nm_cidade_uf: "Garopaba - SC"
  },
  {
      id_cidade: "4507",
      id_estado: "24",
      nm_cidade: "Garuva",
      nm_cidade_uf: "Garuva - SC"
  },
  {
      id_cidade: "4508",
      id_estado: "24",
      nm_cidade: "Gaspar",
      nm_cidade_uf: "Gaspar - SC"
  },
  {
      id_cidade: "4509",
      id_estado: "24",
      nm_cidade: "Governador Celso Ramos",
      nm_cidade_uf: "Governador Celso Ramos - SC"
  },
  {
      id_cidade: "4510",
      id_estado: "24",
      nm_cidade: "Grão Pará",
      nm_cidade_uf: "Grão Pará - SC"
  },
  {
      id_cidade: "4511",
      id_estado: "24",
      nm_cidade: "Gravatal",
      nm_cidade_uf: "Gravatal - SC"
  },
  {
      id_cidade: "4512",
      id_estado: "24",
      nm_cidade: "Guabiruba",
      nm_cidade_uf: "Guabiruba - SC"
  },
  {
      id_cidade: "4513",
      id_estado: "24",
      nm_cidade: "Guaraciaba",
      nm_cidade_uf: "Guaraciaba - SC"
  },
  {
      id_cidade: "4514",
      id_estado: "24",
      nm_cidade: "Guaramirim",
      nm_cidade_uf: "Guaramirim - SC"
  },
  {
      id_cidade: "4515",
      id_estado: "24",
      nm_cidade: "Guarujá do Sul",
      nm_cidade_uf: "Guarujá do Sul - SC"
  },
  {
      id_cidade: "4516",
      id_estado: "24",
      nm_cidade: "Guatambú",
      nm_cidade_uf: "Guatambú - SC"
  },
  {
      id_cidade: "4517",
      id_estado: "24",
      nm_cidade: "Herval d`Oeste",
      nm_cidade_uf: "Herval d`Oeste - SC"
  },
  {
      id_cidade: "4518",
      id_estado: "24",
      nm_cidade: "Ibiam",
      nm_cidade_uf: "Ibiam - SC"
  },
  {
      id_cidade: "4519",
      id_estado: "24",
      nm_cidade: "Ibicaré",
      nm_cidade_uf: "Ibicaré - SC"
  },
  {
      id_cidade: "4520",
      id_estado: "24",
      nm_cidade: "Ibirama",
      nm_cidade_uf: "Ibirama - SC"
  },
  {
      id_cidade: "4521",
      id_estado: "24",
      nm_cidade: "Içara",
      nm_cidade_uf: "Içara - SC"
  },
  {
      id_cidade: "4522",
      id_estado: "24",
      nm_cidade: "Ilhota",
      nm_cidade_uf: "Ilhota - SC"
  },
  {
      id_cidade: "4523",
      id_estado: "24",
      nm_cidade: "Imaruí",
      nm_cidade_uf: "Imaruí - SC"
  },
  {
      id_cidade: "4524",
      id_estado: "24",
      nm_cidade: "Imbituba",
      nm_cidade_uf: "Imbituba - SC"
  },
  {
      id_cidade: "4525",
      id_estado: "24",
      nm_cidade: "Imbuia",
      nm_cidade_uf: "Imbuia - SC"
  },
  {
      id_cidade: "4526",
      id_estado: "24",
      nm_cidade: "Indaial",
      nm_cidade_uf: "Indaial - SC"
  },
  {
      id_cidade: "4527",
      id_estado: "24",
      nm_cidade: "Iomerê",
      nm_cidade_uf: "Iomerê - SC"
  },
  {
      id_cidade: "4528",
      id_estado: "24",
      nm_cidade: "Ipira",
      nm_cidade_uf: "Ipira - SC"
  },
  {
      id_cidade: "4529",
      id_estado: "24",
      nm_cidade: "Iporã do Oeste",
      nm_cidade_uf: "Iporã do Oeste - SC"
  },
  {
      id_cidade: "4530",
      id_estado: "24",
      nm_cidade: "Ipuaçu",
      nm_cidade_uf: "Ipuaçu - SC"
  },
  {
      id_cidade: "4531",
      id_estado: "24",
      nm_cidade: "Ipumirim",
      nm_cidade_uf: "Ipumirim - SC"
  },
  {
      id_cidade: "4532",
      id_estado: "24",
      nm_cidade: "Iraceminha",
      nm_cidade_uf: "Iraceminha - SC"
  },
  {
      id_cidade: "4533",
      id_estado: "24",
      nm_cidade: "Irani",
      nm_cidade_uf: "Irani - SC"
  },
  {
      id_cidade: "4534",
      id_estado: "24",
      nm_cidade: "Irati",
      nm_cidade_uf: "Irati - SC"
  },
  {
      id_cidade: "4535",
      id_estado: "24",
      nm_cidade: "Irineópolis",
      nm_cidade_uf: "Irineópolis - SC"
  },
  {
      id_cidade: "4536",
      id_estado: "24",
      nm_cidade: "Itá",
      nm_cidade_uf: "Itá - SC"
  },
  {
      id_cidade: "4537",
      id_estado: "24",
      nm_cidade: "Itaiópolis",
      nm_cidade_uf: "Itaiópolis - SC"
  },
  {
      id_cidade: "4538",
      id_estado: "24",
      nm_cidade: "Itajaí",
      nm_cidade_uf: "Itajaí - SC"
  },
  {
      id_cidade: "4539",
      id_estado: "24",
      nm_cidade: "Itapema",
      nm_cidade_uf: "Itapema - SC"
  },
  {
      id_cidade: "4540",
      id_estado: "24",
      nm_cidade: "Itapiranga",
      nm_cidade_uf: "Itapiranga - SC"
  },
  {
      id_cidade: "4541",
      id_estado: "24",
      nm_cidade: "Itapoá",
      nm_cidade_uf: "Itapoá - SC"
  },
  {
      id_cidade: "4542",
      id_estado: "24",
      nm_cidade: "Ituporanga",
      nm_cidade_uf: "Ituporanga - SC"
  },
  {
      id_cidade: "4543",
      id_estado: "24",
      nm_cidade: "Jaborá",
      nm_cidade_uf: "Jaborá - SC"
  },
  {
      id_cidade: "4544",
      id_estado: "24",
      nm_cidade: "Jacinto Machado",
      nm_cidade_uf: "Jacinto Machado - SC"
  },
  {
      id_cidade: "4545",
      id_estado: "24",
      nm_cidade: "Jaguaruna",
      nm_cidade_uf: "Jaguaruna - SC"
  },
  {
      id_cidade: "4546",
      id_estado: "24",
      nm_cidade: "Jaraguá do Sul",
      nm_cidade_uf: "Jaraguá do Sul - SC"
  },
  {
      id_cidade: "4547",
      id_estado: "24",
      nm_cidade: "Jardinópolis",
      nm_cidade_uf: "Jardinópolis - SC"
  },
  {
      id_cidade: "4548",
      id_estado: "24",
      nm_cidade: "Joaçaba",
      nm_cidade_uf: "Joaçaba - SC"
  },
  {
      id_cidade: "4549",
      id_estado: "24",
      nm_cidade: "Joinville",
      nm_cidade_uf: "Joinville - SC"
  },
  {
      id_cidade: "4550",
      id_estado: "24",
      nm_cidade: "José Boiteux",
      nm_cidade_uf: "José Boiteux - SC"
  },
  {
      id_cidade: "4551",
      id_estado: "24",
      nm_cidade: "Jupiá",
      nm_cidade_uf: "Jupiá - SC"
  },
  {
      id_cidade: "4552",
      id_estado: "24",
      nm_cidade: "Lacerdópolis",
      nm_cidade_uf: "Lacerdópolis - SC"
  },
  {
      id_cidade: "4553",
      id_estado: "24",
      nm_cidade: "Lages",
      nm_cidade_uf: "Lages - SC"
  },
  {
      id_cidade: "4554",
      id_estado: "24",
      nm_cidade: "Laguna",
      nm_cidade_uf: "Laguna - SC"
  },
  {
      id_cidade: "4555",
      id_estado: "24",
      nm_cidade: "Lajeado Grande",
      nm_cidade_uf: "Lajeado Grande - SC"
  },
  {
      id_cidade: "4556",
      id_estado: "24",
      nm_cidade: "Laurentino",
      nm_cidade_uf: "Laurentino - SC"
  },
  {
      id_cidade: "4557",
      id_estado: "24",
      nm_cidade: "Lauro Muller",
      nm_cidade_uf: "Lauro Muller - SC"
  },
  {
      id_cidade: "4558",
      id_estado: "24",
      nm_cidade: "Lebon Régis",
      nm_cidade_uf: "Lebon Régis - SC"
  },
  {
      id_cidade: "4559",
      id_estado: "24",
      nm_cidade: "Leoberto Leal",
      nm_cidade_uf: "Leoberto Leal - SC"
  },
  {
      id_cidade: "4560",
      id_estado: "24",
      nm_cidade: "Lindóia do Sul",
      nm_cidade_uf: "Lindóia do Sul - SC"
  },
  {
      id_cidade: "4561",
      id_estado: "24",
      nm_cidade: "Lontras",
      nm_cidade_uf: "Lontras - SC"
  },
  {
      id_cidade: "4562",
      id_estado: "24",
      nm_cidade: "Luiz Alves",
      nm_cidade_uf: "Luiz Alves - SC"
  },
  {
      id_cidade: "4563",
      id_estado: "24",
      nm_cidade: "Luzerna",
      nm_cidade_uf: "Luzerna - SC"
  },
  {
      id_cidade: "4564",
      id_estado: "24",
      nm_cidade: "Macieira",
      nm_cidade_uf: "Macieira - SC"
  },
  {
      id_cidade: "4565",
      id_estado: "24",
      nm_cidade: "Mafra",
      nm_cidade_uf: "Mafra - SC"
  },
  {
      id_cidade: "4566",
      id_estado: "24",
      nm_cidade: "Major Gercino",
      nm_cidade_uf: "Major Gercino - SC"
  },
  {
      id_cidade: "4567",
      id_estado: "24",
      nm_cidade: "Major Vieira",
      nm_cidade_uf: "Major Vieira - SC"
  },
  {
      id_cidade: "4568",
      id_estado: "24",
      nm_cidade: "Maracajá",
      nm_cidade_uf: "Maracajá - SC"
  },
  {
      id_cidade: "4569",
      id_estado: "24",
      nm_cidade: "Maravilha",
      nm_cidade_uf: "Maravilha - SC"
  },
  {
      id_cidade: "4570",
      id_estado: "24",
      nm_cidade: "Marema",
      nm_cidade_uf: "Marema - SC"
  },
  {
      id_cidade: "4571",
      id_estado: "24",
      nm_cidade: "Massaranduba",
      nm_cidade_uf: "Massaranduba - SC"
  },
  {
      id_cidade: "4572",
      id_estado: "24",
      nm_cidade: "Matos Costa",
      nm_cidade_uf: "Matos Costa - SC"
  },
  {
      id_cidade: "4573",
      id_estado: "24",
      nm_cidade: "Meleiro",
      nm_cidade_uf: "Meleiro - SC"
  },
  {
      id_cidade: "4574",
      id_estado: "24",
      nm_cidade: "Mirim Doce",
      nm_cidade_uf: "Mirim Doce - SC"
  },
  {
      id_cidade: "4575",
      id_estado: "24",
      nm_cidade: "Modelo",
      nm_cidade_uf: "Modelo - SC"
  },
  {
      id_cidade: "4576",
      id_estado: "24",
      nm_cidade: "Mondaí",
      nm_cidade_uf: "Mondaí - SC"
  },
  {
      id_cidade: "4577",
      id_estado: "24",
      nm_cidade: "Monte Carlo",
      nm_cidade_uf: "Monte Carlo - SC"
  },
  {
      id_cidade: "4578",
      id_estado: "24",
      nm_cidade: "Monte Castelo",
      nm_cidade_uf: "Monte Castelo - SC"
  },
  {
      id_cidade: "4579",
      id_estado: "24",
      nm_cidade: "Morro da Fumaça",
      nm_cidade_uf: "Morro da Fumaça - SC"
  },
  {
      id_cidade: "4580",
      id_estado: "24",
      nm_cidade: "Morro Grande",
      nm_cidade_uf: "Morro Grande - SC"
  },
  {
      id_cidade: "4581",
      id_estado: "24",
      nm_cidade: "Navegantes",
      nm_cidade_uf: "Navegantes - SC"
  },
  {
      id_cidade: "4582",
      id_estado: "24",
      nm_cidade: "Nova Erechim",
      nm_cidade_uf: "Nova Erechim - SC"
  },
  {
      id_cidade: "4583",
      id_estado: "24",
      nm_cidade: "Nova Itaberaba",
      nm_cidade_uf: "Nova Itaberaba - SC"
  },
  {
      id_cidade: "4584",
      id_estado: "24",
      nm_cidade: "Nova Trento",
      nm_cidade_uf: "Nova Trento - SC"
  },
  {
      id_cidade: "4585",
      id_estado: "24",
      nm_cidade: "Nova Veneza",
      nm_cidade_uf: "Nova Veneza - SC"
  },
  {
      id_cidade: "4586",
      id_estado: "24",
      nm_cidade: "Novo Horizonte",
      nm_cidade_uf: "Novo Horizonte - SC"
  },
  {
      id_cidade: "4587",
      id_estado: "24",
      nm_cidade: "Orleans",
      nm_cidade_uf: "Orleans - SC"
  },
  {
      id_cidade: "4588",
      id_estado: "24",
      nm_cidade: "Otacílio Costa",
      nm_cidade_uf: "Otacílio Costa - SC"
  },
  {
      id_cidade: "4589",
      id_estado: "24",
      nm_cidade: "Ouro",
      nm_cidade_uf: "Ouro - SC"
  },
  {
      id_cidade: "4590",
      id_estado: "24",
      nm_cidade: "Ouro Verde",
      nm_cidade_uf: "Ouro Verde - SC"
  },
  {
      id_cidade: "4591",
      id_estado: "24",
      nm_cidade: "Paial",
      nm_cidade_uf: "Paial - SC"
  },
  {
      id_cidade: "4592",
      id_estado: "24",
      nm_cidade: "Painel",
      nm_cidade_uf: "Painel - SC"
  },
  {
      id_cidade: "4593",
      id_estado: "24",
      nm_cidade: "Palhoça",
      nm_cidade_uf: "Palhoça - SC"
  },
  {
      id_cidade: "4594",
      id_estado: "24",
      nm_cidade: "Palma Sola",
      nm_cidade_uf: "Palma Sola - SC"
  },
  {
      id_cidade: "4595",
      id_estado: "24",
      nm_cidade: "Palmeira",
      nm_cidade_uf: "Palmeira - SC"
  },
  {
      id_cidade: "4596",
      id_estado: "24",
      nm_cidade: "Palmitos",
      nm_cidade_uf: "Palmitos - SC"
  },
  {
      id_cidade: "4597",
      id_estado: "24",
      nm_cidade: "Papanduva",
      nm_cidade_uf: "Papanduva - SC"
  },
  {
      id_cidade: "4598",
      id_estado: "24",
      nm_cidade: "Paraíso",
      nm_cidade_uf: "Paraíso - SC"
  },
  {
      id_cidade: "4599",
      id_estado: "24",
      nm_cidade: "Passo de Torres",
      nm_cidade_uf: "Passo de Torres - SC"
  },
  {
      id_cidade: "4600",
      id_estado: "24",
      nm_cidade: "Passos Maia",
      nm_cidade_uf: "Passos Maia - SC"
  },
  {
      id_cidade: "4601",
      id_estado: "24",
      nm_cidade: "Paulo Lopes",
      nm_cidade_uf: "Paulo Lopes - SC"
  },
  {
      id_cidade: "4602",
      id_estado: "24",
      nm_cidade: "Pedras Grandes",
      nm_cidade_uf: "Pedras Grandes - SC"
  },
  {
      id_cidade: "4603",
      id_estado: "24",
      nm_cidade: "Penha",
      nm_cidade_uf: "Penha - SC"
  },
  {
      id_cidade: "4604",
      id_estado: "24",
      nm_cidade: "Peritiba",
      nm_cidade_uf: "Peritiba - SC"
  },
  {
      id_cidade: "4605",
      id_estado: "24",
      nm_cidade: "Petrolândia",
      nm_cidade_uf: "Petrolândia - SC"
  },
  {
      id_cidade: "4606",
      id_estado: "24",
      nm_cidade: "Piçarras",
      nm_cidade_uf: "Piçarras - SC"
  },
  {
      id_cidade: "4607",
      id_estado: "24",
      nm_cidade: "Pinhalzinho",
      nm_cidade_uf: "Pinhalzinho - SC"
  },
  {
      id_cidade: "4608",
      id_estado: "24",
      nm_cidade: "Pinheiro Preto",
      nm_cidade_uf: "Pinheiro Preto - SC"
  },
  {
      id_cidade: "4609",
      id_estado: "24",
      nm_cidade: "Piratuba",
      nm_cidade_uf: "Piratuba - SC"
  },
  {
      id_cidade: "4610",
      id_estado: "24",
      nm_cidade: "Planalto Alegre",
      nm_cidade_uf: "Planalto Alegre - SC"
  },
  {
      id_cidade: "4611",
      id_estado: "24",
      nm_cidade: "Pomerode",
      nm_cidade_uf: "Pomerode - SC"
  },
  {
      id_cidade: "4612",
      id_estado: "24",
      nm_cidade: "Ponte Alta",
      nm_cidade_uf: "Ponte Alta - SC"
  },
  {
      id_cidade: "4613",
      id_estado: "24",
      nm_cidade: "Ponte Alta do Norte",
      nm_cidade_uf: "Ponte Alta do Norte - SC"
  },
  {
      id_cidade: "4614",
      id_estado: "24",
      nm_cidade: "Ponte Serrada",
      nm_cidade_uf: "Ponte Serrada - SC"
  },
  {
      id_cidade: "4615",
      id_estado: "24",
      nm_cidade: "Porto Belo",
      nm_cidade_uf: "Porto Belo - SC"
  },
  {
      id_cidade: "4616",
      id_estado: "24",
      nm_cidade: "Porto União",
      nm_cidade_uf: "Porto União - SC"
  },
  {
      id_cidade: "4617",
      id_estado: "24",
      nm_cidade: "Pouso Redondo",
      nm_cidade_uf: "Pouso Redondo - SC"
  },
  {
      id_cidade: "4618",
      id_estado: "24",
      nm_cidade: "Praia Grande",
      nm_cidade_uf: "Praia Grande - SC"
  },
  {
      id_cidade: "4619",
      id_estado: "24",
      nm_cidade: "Presidente Castelo Branco",
      nm_cidade_uf: "Presidente Castelo Branco - SC"
  },
  {
      id_cidade: "4620",
      id_estado: "24",
      nm_cidade: "Presidente Getúlio",
      nm_cidade_uf: "Presidente Getúlio - SC"
  },
  {
      id_cidade: "4621",
      id_estado: "24",
      nm_cidade: "Presidente Nereu",
      nm_cidade_uf: "Presidente Nereu - SC"
  },
  {
      id_cidade: "4622",
      id_estado: "24",
      nm_cidade: "Princesa",
      nm_cidade_uf: "Princesa - SC"
  },
  {
      id_cidade: "4623",
      id_estado: "24",
      nm_cidade: "Quilombo",
      nm_cidade_uf: "Quilombo - SC"
  },
  {
      id_cidade: "4624",
      id_estado: "24",
      nm_cidade: "Rancho Queimado",
      nm_cidade_uf: "Rancho Queimado - SC"
  },
  {
      id_cidade: "4625",
      id_estado: "24",
      nm_cidade: "Rio das Antas",
      nm_cidade_uf: "Rio das Antas - SC"
  },
  {
      id_cidade: "4626",
      id_estado: "24",
      nm_cidade: "Rio do Campo",
      nm_cidade_uf: "Rio do Campo - SC"
  },
  {
      id_cidade: "4627",
      id_estado: "24",
      nm_cidade: "Rio do Oeste",
      nm_cidade_uf: "Rio do Oeste - SC"
  },
  {
      id_cidade: "4628",
      id_estado: "24",
      nm_cidade: "Rio do Sul",
      nm_cidade_uf: "Rio do Sul - SC"
  },
  {
      id_cidade: "4629",
      id_estado: "24",
      nm_cidade: "Rio dos Cedros",
      nm_cidade_uf: "Rio dos Cedros - SC"
  },
  {
      id_cidade: "4630",
      id_estado: "24",
      nm_cidade: "Rio Fortuna",
      nm_cidade_uf: "Rio Fortuna - SC"
  },
  {
      id_cidade: "4631",
      id_estado: "24",
      nm_cidade: "Rio Negrinho",
      nm_cidade_uf: "Rio Negrinho - SC"
  },
  {
      id_cidade: "4632",
      id_estado: "24",
      nm_cidade: "Rio Rufino",
      nm_cidade_uf: "Rio Rufino - SC"
  },
  {
      id_cidade: "4633",
      id_estado: "24",
      nm_cidade: "Riqueza",
      nm_cidade_uf: "Riqueza - SC"
  },
  {
      id_cidade: "4634",
      id_estado: "24",
      nm_cidade: "Rodeio",
      nm_cidade_uf: "Rodeio - SC"
  },
  {
      id_cidade: "4635",
      id_estado: "24",
      nm_cidade: "Romelândia",
      nm_cidade_uf: "Romelândia - SC"
  },
  {
      id_cidade: "4636",
      id_estado: "24",
      nm_cidade: "Salete",
      nm_cidade_uf: "Salete - SC"
  },
  {
      id_cidade: "4637",
      id_estado: "24",
      nm_cidade: "Saltinho",
      nm_cidade_uf: "Saltinho - SC"
  },
  {
      id_cidade: "4638",
      id_estado: "24",
      nm_cidade: "Salto Veloso",
      nm_cidade_uf: "Salto Veloso - SC"
  },
  {
      id_cidade: "4639",
      id_estado: "24",
      nm_cidade: "Sangão",
      nm_cidade_uf: "Sangão - SC"
  },
  {
      id_cidade: "4640",
      id_estado: "24",
      nm_cidade: "Santa Cecília",
      nm_cidade_uf: "Santa Cecília - SC"
  },
  {
      id_cidade: "4641",
      id_estado: "24",
      nm_cidade: "Santa Helena",
      nm_cidade_uf: "Santa Helena - SC"
  },
  {
      id_cidade: "4642",
      id_estado: "24",
      nm_cidade: "Santa Rosa de Lima",
      nm_cidade_uf: "Santa Rosa de Lima - SC"
  },
  {
      id_cidade: "4643",
      id_estado: "24",
      nm_cidade: "Santa Rosa do Sul",
      nm_cidade_uf: "Santa Rosa do Sul - SC"
  },
  {
      id_cidade: "4644",
      id_estado: "24",
      nm_cidade: "Santa Terezinha",
      nm_cidade_uf: "Santa Terezinha - SC"
  },
  {
      id_cidade: "4645",
      id_estado: "24",
      nm_cidade: "Santa Terezinha do Progresso",
      nm_cidade_uf: "Santa Terezinha do Progresso - SC"
  },
  {
      id_cidade: "4646",
      id_estado: "24",
      nm_cidade: "Santiago do Sul",
      nm_cidade_uf: "Santiago do Sul - SC"
  },
  {
      id_cidade: "4647",
      id_estado: "24",
      nm_cidade: "Santo Amaro da Imperatriz",
      nm_cidade_uf: "Santo Amaro da Imperatriz - SC"
  },
  {
      id_cidade: "4648",
      id_estado: "24",
      nm_cidade: "São Bento do Sul",
      nm_cidade_uf: "São Bento do Sul - SC"
  },
  {
      id_cidade: "4649",
      id_estado: "24",
      nm_cidade: "São Bernardino",
      nm_cidade_uf: "São Bernardino - SC"
  },
  {
      id_cidade: "4650",
      id_estado: "24",
      nm_cidade: "São Bonifácio",
      nm_cidade_uf: "São Bonifácio - SC"
  },
  {
      id_cidade: "4651",
      id_estado: "24",
      nm_cidade: "São Carlos",
      nm_cidade_uf: "São Carlos - SC"
  },
  {
      id_cidade: "4652",
      id_estado: "24",
      nm_cidade: "São Cristovão do Sul",
      nm_cidade_uf: "São Cristovão do Sul - SC"
  },
  {
      id_cidade: "4653",
      id_estado: "24",
      nm_cidade: "São Domingos",
      nm_cidade_uf: "São Domingos - SC"
  },
  {
      id_cidade: "4654",
      id_estado: "24",
      nm_cidade: "São Francisco do Sul",
      nm_cidade_uf: "São Francisco do Sul - SC"
  },
  {
      id_cidade: "4655",
      id_estado: "24",
      nm_cidade: "São João Batista",
      nm_cidade_uf: "São João Batista - SC"
  },
  {
      id_cidade: "4656",
      id_estado: "24",
      nm_cidade: "São João do Itaperiú",
      nm_cidade_uf: "São João do Itaperiú - SC"
  },
  {
      id_cidade: "4657",
      id_estado: "24",
      nm_cidade: "São João do Oeste",
      nm_cidade_uf: "São João do Oeste - SC"
  },
  {
      id_cidade: "4658",
      id_estado: "24",
      nm_cidade: "São João do Sul",
      nm_cidade_uf: "São João do Sul - SC"
  },
  {
      id_cidade: "4659",
      id_estado: "24",
      nm_cidade: "São Joaquim",
      nm_cidade_uf: "São Joaquim - SC"
  },
  {
      id_cidade: "4660",
      id_estado: "24",
      nm_cidade: "São José",
      nm_cidade_uf: "São José - SC"
  },
  {
      id_cidade: "4661",
      id_estado: "24",
      nm_cidade: "São José do Cedro",
      nm_cidade_uf: "São José do Cedro - SC"
  },
  {
      id_cidade: "4662",
      id_estado: "24",
      nm_cidade: "São José do Cerrito",
      nm_cidade_uf: "São José do Cerrito - SC"
  },
  {
      id_cidade: "4663",
      id_estado: "24",
      nm_cidade: "São Lourenço do Oeste",
      nm_cidade_uf: "São Lourenço do Oeste - SC"
  },
  {
      id_cidade: "4664",
      id_estado: "24",
      nm_cidade: "São Ludgero",
      nm_cidade_uf: "São Ludgero - SC"
  },
  {
      id_cidade: "4665",
      id_estado: "24",
      nm_cidade: "São Martinho",
      nm_cidade_uf: "São Martinho - SC"
  },
  {
      id_cidade: "4666",
      id_estado: "24",
      nm_cidade: "São Miguel da Boa Vista",
      nm_cidade_uf: "São Miguel da Boa Vista - SC"
  },
  {
      id_cidade: "4667",
      id_estado: "24",
      nm_cidade: "São Miguel do Oeste",
      nm_cidade_uf: "São Miguel do Oeste - SC"
  },
  {
      id_cidade: "4668",
      id_estado: "24",
      nm_cidade: "São Pedro de Alcântara",
      nm_cidade_uf: "São Pedro de Alcântara - SC"
  },
  {
      id_cidade: "4669",
      id_estado: "24",
      nm_cidade: "Saudades",
      nm_cidade_uf: "Saudades - SC"
  },
  {
      id_cidade: "4670",
      id_estado: "24",
      nm_cidade: "Schroeder",
      nm_cidade_uf: "Schroeder - SC"
  },
  {
      id_cidade: "4671",
      id_estado: "24",
      nm_cidade: "Seara",
      nm_cidade_uf: "Seara - SC"
  },
  {
      id_cidade: "4672",
      id_estado: "24",
      nm_cidade: "Serra Alta",
      nm_cidade_uf: "Serra Alta - SC"
  },
  {
      id_cidade: "4673",
      id_estado: "24",
      nm_cidade: "Siderópolis",
      nm_cidade_uf: "Siderópolis - SC"
  },
  {
      id_cidade: "4674",
      id_estado: "24",
      nm_cidade: "Sombrio",
      nm_cidade_uf: "Sombrio - SC"
  },
  {
      id_cidade: "4675",
      id_estado: "24",
      nm_cidade: "Sul Brasil",
      nm_cidade_uf: "Sul Brasil - SC"
  },
  {
      id_cidade: "4676",
      id_estado: "24",
      nm_cidade: "Taió",
      nm_cidade_uf: "Taió - SC"
  },
  {
      id_cidade: "4677",
      id_estado: "24",
      nm_cidade: "Tangará",
      nm_cidade_uf: "Tangará - SC"
  },
  {
      id_cidade: "4678",
      id_estado: "24",
      nm_cidade: "Tigrinhos",
      nm_cidade_uf: "Tigrinhos - SC"
  },
  {
      id_cidade: "4679",
      id_estado: "24",
      nm_cidade: "Tijucas",
      nm_cidade_uf: "Tijucas - SC"
  },
  {
      id_cidade: "4680",
      id_estado: "24",
      nm_cidade: "Timbé do Sul",
      nm_cidade_uf: "Timbé do Sul - SC"
  },
  {
      id_cidade: "4681",
      id_estado: "24",
      nm_cidade: "Timbó",
      nm_cidade_uf: "Timbó - SC"
  },
  {
      id_cidade: "4682",
      id_estado: "24",
      nm_cidade: "Timbó Grande",
      nm_cidade_uf: "Timbó Grande - SC"
  },
  {
      id_cidade: "4683",
      id_estado: "24",
      nm_cidade: "Três Barras",
      nm_cidade_uf: "Três Barras - SC"
  },
  {
      id_cidade: "4684",
      id_estado: "24",
      nm_cidade: "Treviso",
      nm_cidade_uf: "Treviso - SC"
  },
  {
      id_cidade: "4685",
      id_estado: "24",
      nm_cidade: "Treze de Maio",
      nm_cidade_uf: "Treze de Maio - SC"
  },
  {
      id_cidade: "4686",
      id_estado: "24",
      nm_cidade: "Treze Tílias",
      nm_cidade_uf: "Treze Tílias - SC"
  },
  {
      id_cidade: "4687",
      id_estado: "24",
      nm_cidade: "Trombudo Central",
      nm_cidade_uf: "Trombudo Central - SC"
  },
  {
      id_cidade: "4688",
      id_estado: "24",
      nm_cidade: "Tubarão",
      nm_cidade_uf: "Tubarão - SC"
  },
  {
      id_cidade: "4689",
      id_estado: "24",
      nm_cidade: "Tunápolis",
      nm_cidade_uf: "Tunápolis - SC"
  },
  {
      id_cidade: "4690",
      id_estado: "24",
      nm_cidade: "Turvo",
      nm_cidade_uf: "Turvo - SC"
  },
  {
      id_cidade: "4691",
      id_estado: "24",
      nm_cidade: "União do Oeste",
      nm_cidade_uf: "União do Oeste - SC"
  },
  {
      id_cidade: "4692",
      id_estado: "24",
      nm_cidade: "Urubici",
      nm_cidade_uf: "Urubici - SC"
  },
  {
      id_cidade: "4693",
      id_estado: "24",
      nm_cidade: "Urupema",
      nm_cidade_uf: "Urupema - SC"
  },
  {
      id_cidade: "4694",
      id_estado: "24",
      nm_cidade: "Urussanga",
      nm_cidade_uf: "Urussanga - SC"
  },
  {
      id_cidade: "4695",
      id_estado: "24",
      nm_cidade: "Vargeão",
      nm_cidade_uf: "Vargeão - SC"
  },
  {
      id_cidade: "4696",
      id_estado: "24",
      nm_cidade: "Vargem",
      nm_cidade_uf: "Vargem - SC"
  },
  {
      id_cidade: "4697",
      id_estado: "24",
      nm_cidade: "Vargem Bonita",
      nm_cidade_uf: "Vargem Bonita - SC"
  },
  {
      id_cidade: "4698",
      id_estado: "24",
      nm_cidade: "Vidal Ramos",
      nm_cidade_uf: "Vidal Ramos - SC"
  },
  {
      id_cidade: "4699",
      id_estado: "24",
      nm_cidade: "Videira",
      nm_cidade_uf: "Videira - SC"
  },
  {
      id_cidade: "4700",
      id_estado: "24",
      nm_cidade: "Vitor Meireles",
      nm_cidade_uf: "Vitor Meireles - SC"
  },
  {
      id_cidade: "4701",
      id_estado: "24",
      nm_cidade: "Witmarsum",
      nm_cidade_uf: "Witmarsum - SC"
  },
  {
      id_cidade: "4702",
      id_estado: "24",
      nm_cidade: "Xanxerê",
      nm_cidade_uf: "Xanxerê - SC"
  },
  {
      id_cidade: "4703",
      id_estado: "24",
      nm_cidade: "Xavantina",
      nm_cidade_uf: "Xavantina - SC"
  },
  {
      id_cidade: "4704",
      id_estado: "24",
      nm_cidade: "Xaxim",
      nm_cidade_uf: "Xaxim - SC"
  },
  {
      id_cidade: "4705",
      id_estado: "24",
      nm_cidade: "Zortéa",
      nm_cidade_uf: "Zortéa - SC"
  },
  {
      id_cidade: "4706",
      id_estado: "26",
      nm_cidade: "Adamantina",
      nm_cidade_uf: "Adamantina - SP"
  },
  {
      id_cidade: "4707",
      id_estado: "26",
      nm_cidade: "Adolfo",
      nm_cidade_uf: "Adolfo - SP"
  },
  {
      id_cidade: "4708",
      id_estado: "26",
      nm_cidade: "Aguaí",
      nm_cidade_uf: "Aguaí - SP"
  },
  {
      id_cidade: "4709",
      id_estado: "26",
      nm_cidade: "Águas da Prata",
      nm_cidade_uf: "Águas da Prata - SP"
  },
  {
      id_cidade: "4710",
      id_estado: "26",
      nm_cidade: "Águas de Lindóia",
      nm_cidade_uf: "Águas de Lindóia - SP"
  },
  {
      id_cidade: "4711",
      id_estado: "26",
      nm_cidade: "Águas de Santa Bárbara",
      nm_cidade_uf: "Águas de Santa Bárbara - SP"
  },
  {
      id_cidade: "4712",
      id_estado: "26",
      nm_cidade: "Águas de São Pedro",
      nm_cidade_uf: "Águas de São Pedro - SP"
  },
  {
      id_cidade: "4713",
      id_estado: "26",
      nm_cidade: "Agudos",
      nm_cidade_uf: "Agudos - SP"
  },
  {
      id_cidade: "4714",
      id_estado: "26",
      nm_cidade: "Alambari",
      nm_cidade_uf: "Alambari - SP"
  },
  {
      id_cidade: "4715",
      id_estado: "26",
      nm_cidade: "Alfredo Marcondes",
      nm_cidade_uf: "Alfredo Marcondes - SP"
  },
  {
      id_cidade: "4716",
      id_estado: "26",
      nm_cidade: "Altair",
      nm_cidade_uf: "Altair - SP"
  },
  {
      id_cidade: "4717",
      id_estado: "26",
      nm_cidade: "Altinópolis",
      nm_cidade_uf: "Altinópolis - SP"
  },
  {
      id_cidade: "4718",
      id_estado: "26",
      nm_cidade: "Alto Alegre",
      nm_cidade_uf: "Alto Alegre - SP"
  },
  {
      id_cidade: "4719",
      id_estado: "26",
      nm_cidade: "Alumínio",
      nm_cidade_uf: "Alumínio - SP"
  },
  {
      id_cidade: "4720",
      id_estado: "26",
      nm_cidade: "Álvares Florence",
      nm_cidade_uf: "Álvares Florence - SP"
  },
  {
      id_cidade: "4721",
      id_estado: "26",
      nm_cidade: "Álvares Machado",
      nm_cidade_uf: "Álvares Machado - SP"
  },
  {
      id_cidade: "4722",
      id_estado: "26",
      nm_cidade: "Álvaro de Carvalho",
      nm_cidade_uf: "Álvaro de Carvalho - SP"
  },
  {
      id_cidade: "4723",
      id_estado: "26",
      nm_cidade: "Alvinlândia",
      nm_cidade_uf: "Alvinlândia - SP"
  },
  {
      id_cidade: "4724",
      id_estado: "26",
      nm_cidade: "Americana",
      nm_cidade_uf: "Americana - SP"
  },
  {
      id_cidade: "4725",
      id_estado: "26",
      nm_cidade: "Américo Brasiliense",
      nm_cidade_uf: "Américo Brasiliense - SP"
  },
  {
      id_cidade: "4726",
      id_estado: "26",
      nm_cidade: "Américo de Campos",
      nm_cidade_uf: "Américo de Campos - SP"
  },
  {
      id_cidade: "4727",
      id_estado: "26",
      nm_cidade: "Amparo",
      nm_cidade_uf: "Amparo - SP"
  },
  {
      id_cidade: "4728",
      id_estado: "26",
      nm_cidade: "Analândia",
      nm_cidade_uf: "Analândia - SP"
  },
  {
      id_cidade: "4729",
      id_estado: "26",
      nm_cidade: "Andradina",
      nm_cidade_uf: "Andradina - SP"
  },
  {
      id_cidade: "4730",
      id_estado: "26",
      nm_cidade: "Angatuba",
      nm_cidade_uf: "Angatuba - SP"
  },
  {
      id_cidade: "4731",
      id_estado: "26",
      nm_cidade: "Anhembi",
      nm_cidade_uf: "Anhembi - SP"
  },
  {
      id_cidade: "4732",
      id_estado: "26",
      nm_cidade: "Anhumas",
      nm_cidade_uf: "Anhumas - SP"
  },
  {
      id_cidade: "4733",
      id_estado: "26",
      nm_cidade: "Aparecida",
      nm_cidade_uf: "Aparecida - SP"
  },
  {
      id_cidade: "4734",
      id_estado: "26",
      nm_cidade: "Aparecida d`Oeste",
      nm_cidade_uf: "Aparecida d`Oeste - SP"
  },
  {
      id_cidade: "4735",
      id_estado: "26",
      nm_cidade: "Apiaí",
      nm_cidade_uf: "Apiaí - SP"
  },
  {
      id_cidade: "4736",
      id_estado: "26",
      nm_cidade: "Araçariguama",
      nm_cidade_uf: "Araçariguama - SP"
  },
  {
      id_cidade: "4737",
      id_estado: "26",
      nm_cidade: "Araçatuba",
      nm_cidade_uf: "Araçatuba - SP"
  },
  {
      id_cidade: "4738",
      id_estado: "26",
      nm_cidade: "Araçoiaba da Serra",
      nm_cidade_uf: "Araçoiaba da Serra - SP"
  },
  {
      id_cidade: "4739",
      id_estado: "26",
      nm_cidade: "Aramina",
      nm_cidade_uf: "Aramina - SP"
  },
  {
      id_cidade: "4740",
      id_estado: "26",
      nm_cidade: "Arandu",
      nm_cidade_uf: "Arandu - SP"
  },
  {
      id_cidade: "4741",
      id_estado: "26",
      nm_cidade: "Arapeí",
      nm_cidade_uf: "Arapeí - SP"
  },
  {
      id_cidade: "4742",
      id_estado: "26",
      nm_cidade: "Araraquara",
      nm_cidade_uf: "Araraquara - SP"
  },
  {
      id_cidade: "4743",
      id_estado: "26",
      nm_cidade: "Araras",
      nm_cidade_uf: "Araras - SP"
  },
  {
      id_cidade: "4744",
      id_estado: "26",
      nm_cidade: "Arco-Íris",
      nm_cidade_uf: "Arco-Íris - SP"
  },
  {
      id_cidade: "4745",
      id_estado: "26",
      nm_cidade: "Arealva",
      nm_cidade_uf: "Arealva - SP"
  },
  {
      id_cidade: "4746",
      id_estado: "26",
      nm_cidade: "Areias",
      nm_cidade_uf: "Areias - SP"
  },
  {
      id_cidade: "4747",
      id_estado: "26",
      nm_cidade: "Areiópolis",
      nm_cidade_uf: "Areiópolis - SP"
  },
  {
      id_cidade: "4748",
      id_estado: "26",
      nm_cidade: "Ariranha",
      nm_cidade_uf: "Ariranha - SP"
  },
  {
      id_cidade: "4749",
      id_estado: "26",
      nm_cidade: "Artur Nogueira",
      nm_cidade_uf: "Artur Nogueira - SP"
  },
  {
      id_cidade: "4750",
      id_estado: "26",
      nm_cidade: "Arujá",
      nm_cidade_uf: "Arujá - SP"
  },
  {
      id_cidade: "4751",
      id_estado: "26",
      nm_cidade: "Aspásia",
      nm_cidade_uf: "Aspásia - SP"
  },
  {
      id_cidade: "4752",
      id_estado: "26",
      nm_cidade: "Assis",
      nm_cidade_uf: "Assis - SP"
  },
  {
      id_cidade: "4753",
      id_estado: "26",
      nm_cidade: "Atibaia",
      nm_cidade_uf: "Atibaia - SP"
  },
  {
      id_cidade: "4754",
      id_estado: "26",
      nm_cidade: "Auriflama",
      nm_cidade_uf: "Auriflama - SP"
  },
  {
      id_cidade: "4755",
      id_estado: "26",
      nm_cidade: "Avaí",
      nm_cidade_uf: "Avaí - SP"
  },
  {
      id_cidade: "4756",
      id_estado: "26",
      nm_cidade: "Avanhandava",
      nm_cidade_uf: "Avanhandava - SP"
  },
  {
      id_cidade: "4757",
      id_estado: "26",
      nm_cidade: "Avaré",
      nm_cidade_uf: "Avaré - SP"
  },
  {
      id_cidade: "4758",
      id_estado: "26",
      nm_cidade: "Bady Bassitt",
      nm_cidade_uf: "Bady Bassitt - SP"
  },
  {
      id_cidade: "4759",
      id_estado: "26",
      nm_cidade: "Balbinos",
      nm_cidade_uf: "Balbinos - SP"
  },
  {
      id_cidade: "4760",
      id_estado: "26",
      nm_cidade: "Bálsamo",
      nm_cidade_uf: "Bálsamo - SP"
  },
  {
      id_cidade: "4761",
      id_estado: "26",
      nm_cidade: "Bananal",
      nm_cidade_uf: "Bananal - SP"
  },
  {
      id_cidade: "4762",
      id_estado: "26",
      nm_cidade: "Barão de Antonina",
      nm_cidade_uf: "Barão de Antonina - SP"
  },
  {
      id_cidade: "4763",
      id_estado: "26",
      nm_cidade: "Barbosa",
      nm_cidade_uf: "Barbosa - SP"
  },
  {
      id_cidade: "4764",
      id_estado: "26",
      nm_cidade: "Bariri",
      nm_cidade_uf: "Bariri - SP"
  },
  {
      id_cidade: "4765",
      id_estado: "26",
      nm_cidade: "Barra Bonita",
      nm_cidade_uf: "Barra Bonita - SP"
  },
  {
      id_cidade: "4766",
      id_estado: "26",
      nm_cidade: "Barra do Chapéu",
      nm_cidade_uf: "Barra do Chapéu - SP"
  },
  {
      id_cidade: "4767",
      id_estado: "26",
      nm_cidade: "Barra do Turvo",
      nm_cidade_uf: "Barra do Turvo - SP"
  },
  {
      id_cidade: "4768",
      id_estado: "26",
      nm_cidade: "Barretos",
      nm_cidade_uf: "Barretos - SP"
  },
  {
      id_cidade: "4769",
      id_estado: "26",
      nm_cidade: "Barrinha",
      nm_cidade_uf: "Barrinha - SP"
  },
  {
      id_cidade: "4770",
      id_estado: "26",
      nm_cidade: "Barueri",
      nm_cidade_uf: "Barueri - SP"
  },
  {
      id_cidade: "4771",
      id_estado: "26",
      nm_cidade: "Bastos",
      nm_cidade_uf: "Bastos - SP"
  },
  {
      id_cidade: "4772",
      id_estado: "26",
      nm_cidade: "Batatais",
      nm_cidade_uf: "Batatais - SP"
  },
  {
      id_cidade: "4773",
      id_estado: "26",
      nm_cidade: "Bauru",
      nm_cidade_uf: "Bauru - SP"
  },
  {
      id_cidade: "4774",
      id_estado: "26",
      nm_cidade: "Bebedouro",
      nm_cidade_uf: "Bebedouro - SP"
  },
  {
      id_cidade: "4775",
      id_estado: "26",
      nm_cidade: "Bento de Abreu",
      nm_cidade_uf: "Bento de Abreu - SP"
  },
  {
      id_cidade: "4776",
      id_estado: "26",
      nm_cidade: "Bernardino de Campos",
      nm_cidade_uf: "Bernardino de Campos - SP"
  },
  {
      id_cidade: "4777",
      id_estado: "26",
      nm_cidade: "Bertioga",
      nm_cidade_uf: "Bertioga - SP"
  },
  {
      id_cidade: "4778",
      id_estado: "26",
      nm_cidade: "Bilac",
      nm_cidade_uf: "Bilac - SP"
  },
  {
      id_cidade: "4779",
      id_estado: "26",
      nm_cidade: "Birigui",
      nm_cidade_uf: "Birigui - SP"
  },
  {
      id_cidade: "4780",
      id_estado: "26",
      nm_cidade: "Biritiba-Mirim",
      nm_cidade_uf: "Biritiba-Mirim - SP"
  },
  {
      id_cidade: "4781",
      id_estado: "26",
      nm_cidade: "Boa Esperança do Sul",
      nm_cidade_uf: "Boa Esperança do Sul - SP"
  },
  {
      id_cidade: "4782",
      id_estado: "26",
      nm_cidade: "Bocaina",
      nm_cidade_uf: "Bocaina - SP"
  },
  {
      id_cidade: "4783",
      id_estado: "26",
      nm_cidade: "Bofete",
      nm_cidade_uf: "Bofete - SP"
  },
  {
      id_cidade: "4784",
      id_estado: "26",
      nm_cidade: "Boituva",
      nm_cidade_uf: "Boituva - SP"
  },
  {
      id_cidade: "4785",
      id_estado: "26",
      nm_cidade: "Bom Jesus dos Perdões",
      nm_cidade_uf: "Bom Jesus dos Perdões - SP"
  },
  {
      id_cidade: "4786",
      id_estado: "26",
      nm_cidade: "Bom Sucesso de Itararé",
      nm_cidade_uf: "Bom Sucesso de Itararé - SP"
  },
  {
      id_cidade: "4787",
      id_estado: "26",
      nm_cidade: "Borá",
      nm_cidade_uf: "Borá - SP"
  },
  {
      id_cidade: "4788",
      id_estado: "26",
      nm_cidade: "Boracéia",
      nm_cidade_uf: "Boracéia - SP"
  },
  {
      id_cidade: "4789",
      id_estado: "26",
      nm_cidade: "Borborema",
      nm_cidade_uf: "Borborema - SP"
  },
  {
      id_cidade: "4790",
      id_estado: "26",
      nm_cidade: "Borebi",
      nm_cidade_uf: "Borebi - SP"
  },
  {
      id_cidade: "4791",
      id_estado: "26",
      nm_cidade: "Botucatu",
      nm_cidade_uf: "Botucatu - SP"
  },
  {
      id_cidade: "4792",
      id_estado: "26",
      nm_cidade: "Bragança Paulista",
      nm_cidade_uf: "Bragança Paulista - SP"
  },
  {
      id_cidade: "4793",
      id_estado: "26",
      nm_cidade: "Braúna",
      nm_cidade_uf: "Braúna - SP"
  },
  {
      id_cidade: "4794",
      id_estado: "26",
      nm_cidade: "Brejo Alegre",
      nm_cidade_uf: "Brejo Alegre - SP"
  },
  {
      id_cidade: "4795",
      id_estado: "26",
      nm_cidade: "Brodowski",
      nm_cidade_uf: "Brodowski - SP"
  },
  {
      id_cidade: "4796",
      id_estado: "26",
      nm_cidade: "Brotas",
      nm_cidade_uf: "Brotas - SP"
  },
  {
      id_cidade: "4797",
      id_estado: "26",
      nm_cidade: "Buri",
      nm_cidade_uf: "Buri - SP"
  },
  {
      id_cidade: "4798",
      id_estado: "26",
      nm_cidade: "Buritama",
      nm_cidade_uf: "Buritama - SP"
  },
  {
      id_cidade: "4799",
      id_estado: "26",
      nm_cidade: "Buritizal",
      nm_cidade_uf: "Buritizal - SP"
  },
  {
      id_cidade: "4800",
      id_estado: "26",
      nm_cidade: "Cabrália Paulista",
      nm_cidade_uf: "Cabrália Paulista - SP"
  },
  {
      id_cidade: "4801",
      id_estado: "26",
      nm_cidade: "Cabreúva",
      nm_cidade_uf: "Cabreúva - SP"
  },
  {
      id_cidade: "4802",
      id_estado: "26",
      nm_cidade: "Caçapava",
      nm_cidade_uf: "Caçapava - SP"
  },
  {
      id_cidade: "4803",
      id_estado: "26",
      nm_cidade: "Cachoeira Paulista",
      nm_cidade_uf: "Cachoeira Paulista - SP"
  },
  {
      id_cidade: "4804",
      id_estado: "26",
      nm_cidade: "Caconde",
      nm_cidade_uf: "Caconde - SP"
  },
  {
      id_cidade: "4805",
      id_estado: "26",
      nm_cidade: "Cafelândia",
      nm_cidade_uf: "Cafelândia - SP"
  },
  {
      id_cidade: "4806",
      id_estado: "26",
      nm_cidade: "Caiabu",
      nm_cidade_uf: "Caiabu - SP"
  },
  {
      id_cidade: "4807",
      id_estado: "26",
      nm_cidade: "Caieiras",
      nm_cidade_uf: "Caieiras - SP"
  },
  {
      id_cidade: "4808",
      id_estado: "26",
      nm_cidade: "Caiuá",
      nm_cidade_uf: "Caiuá - SP"
  },
  {
      id_cidade: "4809",
      id_estado: "26",
      nm_cidade: "Cajamar",
      nm_cidade_uf: "Cajamar - SP"
  },
  {
      id_cidade: "4810",
      id_estado: "26",
      nm_cidade: "Cajati",
      nm_cidade_uf: "Cajati - SP"
  },
  {
      id_cidade: "4811",
      id_estado: "26",
      nm_cidade: "Cajobi",
      nm_cidade_uf: "Cajobi - SP"
  },
  {
      id_cidade: "4812",
      id_estado: "26",
      nm_cidade: "Cajuru",
      nm_cidade_uf: "Cajuru - SP"
  },
  {
      id_cidade: "4813",
      id_estado: "26",
      nm_cidade: "Campina do Monte Alegre",
      nm_cidade_uf: "Campina do Monte Alegre - SP"
  },
  {
      id_cidade: "4814",
      id_estado: "26",
      nm_cidade: "Campinas",
      nm_cidade_uf: "Campinas - SP"
  },
  {
      id_cidade: "4815",
      id_estado: "26",
      nm_cidade: "Campo Limpo Paulista",
      nm_cidade_uf: "Campo Limpo Paulista - SP"
  },
  {
      id_cidade: "4816",
      id_estado: "26",
      nm_cidade: "Campos do Jordão",
      nm_cidade_uf: "Campos do Jordão - SP"
  },
  {
      id_cidade: "4817",
      id_estado: "26",
      nm_cidade: "Campos Novos Paulista",
      nm_cidade_uf: "Campos Novos Paulista - SP"
  },
  {
      id_cidade: "4818",
      id_estado: "26",
      nm_cidade: "Cananéia",
      nm_cidade_uf: "Cananéia - SP"
  },
  {
      id_cidade: "4819",
      id_estado: "26",
      nm_cidade: "Canas",
      nm_cidade_uf: "Canas - SP"
  },
  {
      id_cidade: "4820",
      id_estado: "26",
      nm_cidade: "Cândido Mota",
      nm_cidade_uf: "Cândido Mota - SP"
  },
  {
      id_cidade: "4821",
      id_estado: "26",
      nm_cidade: "Cândido Rodrigues",
      nm_cidade_uf: "Cândido Rodrigues - SP"
  },
  {
      id_cidade: "4822",
      id_estado: "26",
      nm_cidade: "Canitar",
      nm_cidade_uf: "Canitar - SP"
  },
  {
      id_cidade: "4823",
      id_estado: "26",
      nm_cidade: "Capão Bonito",
      nm_cidade_uf: "Capão Bonito - SP"
  },
  {
      id_cidade: "4824",
      id_estado: "26",
      nm_cidade: "Capela do Alto",
      nm_cidade_uf: "Capela do Alto - SP"
  },
  {
      id_cidade: "4825",
      id_estado: "26",
      nm_cidade: "Capivari",
      nm_cidade_uf: "Capivari - SP"
  },
  {
      id_cidade: "4826",
      id_estado: "26",
      nm_cidade: "Caraguatatuba",
      nm_cidade_uf: "Caraguatatuba - SP"
  },
  {
      id_cidade: "4827",
      id_estado: "26",
      nm_cidade: "Carapicuíba",
      nm_cidade_uf: "Carapicuíba - SP"
  },
  {
      id_cidade: "4828",
      id_estado: "26",
      nm_cidade: "Cardoso",
      nm_cidade_uf: "Cardoso - SP"
  },
  {
      id_cidade: "4829",
      id_estado: "26",
      nm_cidade: "Casa Branca",
      nm_cidade_uf: "Casa Branca - SP"
  },
  {
      id_cidade: "4830",
      id_estado: "26",
      nm_cidade: "Cássia dos Coqueiros",
      nm_cidade_uf: "Cássia dos Coqueiros - SP"
  },
  {
      id_cidade: "4831",
      id_estado: "26",
      nm_cidade: "Castilho",
      nm_cidade_uf: "Castilho - SP"
  },
  {
      id_cidade: "4832",
      id_estado: "26",
      nm_cidade: "Catanduva",
      nm_cidade_uf: "Catanduva - SP"
  },
  {
      id_cidade: "4833",
      id_estado: "26",
      nm_cidade: "Catiguá",
      nm_cidade_uf: "Catiguá - SP"
  },
  {
      id_cidade: "4834",
      id_estado: "26",
      nm_cidade: "Cedral",
      nm_cidade_uf: "Cedral - SP"
  },
  {
      id_cidade: "4835",
      id_estado: "26",
      nm_cidade: "Cerqueira César",
      nm_cidade_uf: "Cerqueira César - SP"
  },
  {
      id_cidade: "4836",
      id_estado: "26",
      nm_cidade: "Cerquilho",
      nm_cidade_uf: "Cerquilho - SP"
  },
  {
      id_cidade: "4837",
      id_estado: "26",
      nm_cidade: "Cesário Lange",
      nm_cidade_uf: "Cesário Lange - SP"
  },
  {
      id_cidade: "4838",
      id_estado: "26",
      nm_cidade: "Charqueada",
      nm_cidade_uf: "Charqueada - SP"
  },
  {
      id_cidade: "4839",
      id_estado: "26",
      nm_cidade: "Chavantes",
      nm_cidade_uf: "Chavantes - SP"
  },
  {
      id_cidade: "4840",
      id_estado: "26",
      nm_cidade: "Clementina",
      nm_cidade_uf: "Clementina - SP"
  },
  {
      id_cidade: "4841",
      id_estado: "26",
      nm_cidade: "Colina",
      nm_cidade_uf: "Colina - SP"
  },
  {
      id_cidade: "4842",
      id_estado: "26",
      nm_cidade: "Colômbia",
      nm_cidade_uf: "Colômbia - SP"
  },
  {
      id_cidade: "4843",
      id_estado: "26",
      nm_cidade: "Conchal",
      nm_cidade_uf: "Conchal - SP"
  },
  {
      id_cidade: "4844",
      id_estado: "26",
      nm_cidade: "Conchas",
      nm_cidade_uf: "Conchas - SP"
  },
  {
      id_cidade: "4845",
      id_estado: "26",
      nm_cidade: "Cordeirópolis",
      nm_cidade_uf: "Cordeirópolis - SP"
  },
  {
      id_cidade: "4846",
      id_estado: "26",
      nm_cidade: "Coroados",
      nm_cidade_uf: "Coroados - SP"
  },
  {
      id_cidade: "4847",
      id_estado: "26",
      nm_cidade: "Coronel Macedo",
      nm_cidade_uf: "Coronel Macedo - SP"
  },
  {
      id_cidade: "4848",
      id_estado: "26",
      nm_cidade: "Corumbataí",
      nm_cidade_uf: "Corumbataí - SP"
  },
  {
      id_cidade: "4849",
      id_estado: "26",
      nm_cidade: "Cosmópolis",
      nm_cidade_uf: "Cosmópolis - SP"
  },
  {
      id_cidade: "4850",
      id_estado: "26",
      nm_cidade: "Cosmorama",
      nm_cidade_uf: "Cosmorama - SP"
  },
  {
      id_cidade: "4851",
      id_estado: "26",
      nm_cidade: "Cotia",
      nm_cidade_uf: "Cotia - SP"
  },
  {
      id_cidade: "4852",
      id_estado: "26",
      nm_cidade: "Cravinhos",
      nm_cidade_uf: "Cravinhos - SP"
  },
  {
      id_cidade: "4853",
      id_estado: "26",
      nm_cidade: "Cristais Paulista",
      nm_cidade_uf: "Cristais Paulista - SP"
  },
  {
      id_cidade: "4854",
      id_estado: "26",
      nm_cidade: "Cruzália",
      nm_cidade_uf: "Cruzália - SP"
  },
  {
      id_cidade: "4855",
      id_estado: "26",
      nm_cidade: "Cruzeiro",
      nm_cidade_uf: "Cruzeiro - SP"
  },
  {
      id_cidade: "4856",
      id_estado: "26",
      nm_cidade: "Cubatão",
      nm_cidade_uf: "Cubatão - SP"
  },
  {
      id_cidade: "4857",
      id_estado: "26",
      nm_cidade: "Cunha",
      nm_cidade_uf: "Cunha - SP"
  },
  {
      id_cidade: "4858",
      id_estado: "26",
      nm_cidade: "Descalvado",
      nm_cidade_uf: "Descalvado - SP"
  },
  {
      id_cidade: "4859",
      id_estado: "26",
      nm_cidade: "Diadema",
      nm_cidade_uf: "Diadema - SP"
  },
  {
      id_cidade: "4860",
      id_estado: "26",
      nm_cidade: "Dirce Reis",
      nm_cidade_uf: "Dirce Reis - SP"
  },
  {
      id_cidade: "4861",
      id_estado: "26",
      nm_cidade: "Divinolândia",
      nm_cidade_uf: "Divinolândia - SP"
  },
  {
      id_cidade: "4862",
      id_estado: "26",
      nm_cidade: "Dobrada",
      nm_cidade_uf: "Dobrada - SP"
  },
  {
      id_cidade: "4863",
      id_estado: "26",
      nm_cidade: "Dois Córregos",
      nm_cidade_uf: "Dois Córregos - SP"
  },
  {
      id_cidade: "4864",
      id_estado: "26",
      nm_cidade: "Dolcinópolis",
      nm_cidade_uf: "Dolcinópolis - SP"
  },
  {
      id_cidade: "4865",
      id_estado: "26",
      nm_cidade: "Dourado",
      nm_cidade_uf: "Dourado - SP"
  },
  {
      id_cidade: "4866",
      id_estado: "26",
      nm_cidade: "Dracena",
      nm_cidade_uf: "Dracena - SP"
  },
  {
      id_cidade: "4867",
      id_estado: "26",
      nm_cidade: "Duartina",
      nm_cidade_uf: "Duartina - SP"
  },
  {
      id_cidade: "4868",
      id_estado: "26",
      nm_cidade: "Dumont",
      nm_cidade_uf: "Dumont - SP"
  },
  {
      id_cidade: "4869",
      id_estado: "26",
      nm_cidade: "Echaporã",
      nm_cidade_uf: "Echaporã - SP"
  },
  {
      id_cidade: "4870",
      id_estado: "26",
      nm_cidade: "Eldorado",
      nm_cidade_uf: "Eldorado - SP"
  },
  {
      id_cidade: "4871",
      id_estado: "26",
      nm_cidade: "Elias Fausto",
      nm_cidade_uf: "Elias Fausto - SP"
  },
  {
      id_cidade: "4872",
      id_estado: "26",
      nm_cidade: "Elisiário",
      nm_cidade_uf: "Elisiário - SP"
  },
  {
      id_cidade: "4873",
      id_estado: "26",
      nm_cidade: "Embaúba",
      nm_cidade_uf: "Embaúba - SP"
  },
  {
      id_cidade: "4874",
      id_estado: "26",
      nm_cidade: "Embu",
      nm_cidade_uf: "Embu - SP"
  },
  {
      id_cidade: "4875",
      id_estado: "26",
      nm_cidade: "Embu-Guaçu",
      nm_cidade_uf: "Embu-Guaçu - SP"
  },
  {
      id_cidade: "4876",
      id_estado: "26",
      nm_cidade: "Emilianópolis",
      nm_cidade_uf: "Emilianópolis - SP"
  },
  {
      id_cidade: "4877",
      id_estado: "26",
      nm_cidade: "Engenheiro Coelho",
      nm_cidade_uf: "Engenheiro Coelho - SP"
  },
  {
      id_cidade: "4878",
      id_estado: "26",
      nm_cidade: "Espírito Santo do Pinhal",
      nm_cidade_uf: "Espírito Santo do Pinhal - SP"
  },
  {
      id_cidade: "4879",
      id_estado: "26",
      nm_cidade: "Espírito Santo do Turvo",
      nm_cidade_uf: "Espírito Santo do Turvo - SP"
  },
  {
      id_cidade: "4880",
      id_estado: "26",
      nm_cidade: "Estiva Gerbi",
      nm_cidade_uf: "Estiva Gerbi - SP"
  },
  {
      id_cidade: "4881",
      id_estado: "26",
      nm_cidade: "Estrela d`Oeste",
      nm_cidade_uf: "Estrela d`Oeste - SP"
  },
  {
      id_cidade: "4882",
      id_estado: "26",
      nm_cidade: "Estrela do Norte",
      nm_cidade_uf: "Estrela do Norte - SP"
  },
  {
      id_cidade: "4883",
      id_estado: "26",
      nm_cidade: "Euclides da Cunha Paulista",
      nm_cidade_uf: "Euclides da Cunha Paulista - SP"
  },
  {
      id_cidade: "4884",
      id_estado: "26",
      nm_cidade: "Fartura",
      nm_cidade_uf: "Fartura - SP"
  },
  {
      id_cidade: "4885",
      id_estado: "26",
      nm_cidade: "Fernando Prestes",
      nm_cidade_uf: "Fernando Prestes - SP"
  },
  {
      id_cidade: "4886",
      id_estado: "26",
      nm_cidade: "Fernandópolis",
      nm_cidade_uf: "Fernandópolis - SP"
  },
  {
      id_cidade: "4887",
      id_estado: "26",
      nm_cidade: "Fernão",
      nm_cidade_uf: "Fernão - SP"
  },
  {
      id_cidade: "4888",
      id_estado: "26",
      nm_cidade: "Ferraz de Vasconcelos",
      nm_cidade_uf: "Ferraz de Vasconcelos - SP"
  },
  {
      id_cidade: "4889",
      id_estado: "26",
      nm_cidade: "Flora Rica",
      nm_cidade_uf: "Flora Rica - SP"
  },
  {
      id_cidade: "4890",
      id_estado: "26",
      nm_cidade: "Floreal",
      nm_cidade_uf: "Floreal - SP"
  },
  {
      id_cidade: "4891",
      id_estado: "26",
      nm_cidade: "Flórida Paulista",
      nm_cidade_uf: "Flórida Paulista - SP"
  },
  {
      id_cidade: "4892",
      id_estado: "26",
      nm_cidade: "Florínia",
      nm_cidade_uf: "Florínia - SP"
  },
  {
      id_cidade: "4893",
      id_estado: "26",
      nm_cidade: "Franca",
      nm_cidade_uf: "Franca - SP"
  },
  {
      id_cidade: "4894",
      id_estado: "26",
      nm_cidade: "Francisco Morato",
      nm_cidade_uf: "Francisco Morato - SP"
  },
  {
      id_cidade: "4895",
      id_estado: "26",
      nm_cidade: "Franco da Rocha",
      nm_cidade_uf: "Franco da Rocha - SP"
  },
  {
      id_cidade: "4896",
      id_estado: "26",
      nm_cidade: "Gabriel Monteiro",
      nm_cidade_uf: "Gabriel Monteiro - SP"
  },
  {
      id_cidade: "4897",
      id_estado: "26",
      nm_cidade: "Gália",
      nm_cidade_uf: "Gália - SP"
  },
  {
      id_cidade: "4898",
      id_estado: "26",
      nm_cidade: "Garça",
      nm_cidade_uf: "Garça - SP"
  },
  {
      id_cidade: "4899",
      id_estado: "26",
      nm_cidade: "Gastão Vidigal",
      nm_cidade_uf: "Gastão Vidigal - SP"
  },
  {
      id_cidade: "4900",
      id_estado: "26",
      nm_cidade: "Gavião Peixoto",
      nm_cidade_uf: "Gavião Peixoto - SP"
  },
  {
      id_cidade: "4901",
      id_estado: "26",
      nm_cidade: "General Salgado",
      nm_cidade_uf: "General Salgado - SP"
  },
  {
      id_cidade: "4902",
      id_estado: "26",
      nm_cidade: "Getulina",
      nm_cidade_uf: "Getulina - SP"
  },
  {
      id_cidade: "4903",
      id_estado: "26",
      nm_cidade: "Glicério",
      nm_cidade_uf: "Glicério - SP"
  },
  {
      id_cidade: "4904",
      id_estado: "26",
      nm_cidade: "Guaiçara",
      nm_cidade_uf: "Guaiçara - SP"
  },
  {
      id_cidade: "4905",
      id_estado: "26",
      nm_cidade: "Guaimbê",
      nm_cidade_uf: "Guaimbê - SP"
  },
  {
      id_cidade: "4906",
      id_estado: "26",
      nm_cidade: "Guaíra",
      nm_cidade_uf: "Guaíra - SP"
  },
  {
      id_cidade: "4907",
      id_estado: "26",
      nm_cidade: "Guapiaçu",
      nm_cidade_uf: "Guapiaçu - SP"
  },
  {
      id_cidade: "4908",
      id_estado: "26",
      nm_cidade: "Guapiara",
      nm_cidade_uf: "Guapiara - SP"
  },
  {
      id_cidade: "4909",
      id_estado: "26",
      nm_cidade: "Guará",
      nm_cidade_uf: "Guará - SP"
  },
  {
      id_cidade: "4910",
      id_estado: "26",
      nm_cidade: "Guaraçaí",
      nm_cidade_uf: "Guaraçaí - SP"
  },
  {
      id_cidade: "4911",
      id_estado: "26",
      nm_cidade: "Guaraci",
      nm_cidade_uf: "Guaraci - SP"
  },
  {
      id_cidade: "4912",
      id_estado: "26",
      nm_cidade: "Guarani d`Oeste",
      nm_cidade_uf: "Guarani d`Oeste - SP"
  },
  {
      id_cidade: "4913",
      id_estado: "26",
      nm_cidade: "Guarantã",
      nm_cidade_uf: "Guarantã - SP"
  },
  {
      id_cidade: "4914",
      id_estado: "26",
      nm_cidade: "Guararapes",
      nm_cidade_uf: "Guararapes - SP"
  },
  {
      id_cidade: "4915",
      id_estado: "26",
      nm_cidade: "Guararema",
      nm_cidade_uf: "Guararema - SP"
  },
  {
      id_cidade: "4916",
      id_estado: "26",
      nm_cidade: "Guaratinguetá",
      nm_cidade_uf: "Guaratinguetá - SP"
  },
  {
      id_cidade: "4917",
      id_estado: "26",
      nm_cidade: "Guareí",
      nm_cidade_uf: "Guareí - SP"
  },
  {
      id_cidade: "4918",
      id_estado: "26",
      nm_cidade: "Guariba",
      nm_cidade_uf: "Guariba - SP"
  },
  {
      id_cidade: "4919",
      id_estado: "26",
      nm_cidade: "Guarujá",
      nm_cidade_uf: "Guarujá - SP"
  },
  {
      id_cidade: "4920",
      id_estado: "26",
      nm_cidade: "Guarulhos",
      nm_cidade_uf: "Guarulhos - SP"
  },
  {
      id_cidade: "4921",
      id_estado: "26",
      nm_cidade: "Guatapará",
      nm_cidade_uf: "Guatapará - SP"
  },
  {
      id_cidade: "4922",
      id_estado: "26",
      nm_cidade: "Guzolândia",
      nm_cidade_uf: "Guzolândia - SP"
  },
  {
      id_cidade: "4923",
      id_estado: "26",
      nm_cidade: "Herculândia",
      nm_cidade_uf: "Herculândia - SP"
  },
  {
      id_cidade: "4924",
      id_estado: "26",
      nm_cidade: "Holambra",
      nm_cidade_uf: "Holambra - SP"
  },
  {
      id_cidade: "4925",
      id_estado: "26",
      nm_cidade: "Hortolândia",
      nm_cidade_uf: "Hortolândia - SP"
  },
  {
      id_cidade: "4926",
      id_estado: "26",
      nm_cidade: "Iacanga",
      nm_cidade_uf: "Iacanga - SP"
  },
  {
      id_cidade: "4927",
      id_estado: "26",
      nm_cidade: "Iacri",
      nm_cidade_uf: "Iacri - SP"
  },
  {
      id_cidade: "4928",
      id_estado: "26",
      nm_cidade: "Iaras",
      nm_cidade_uf: "Iaras - SP"
  },
  {
      id_cidade: "4929",
      id_estado: "26",
      nm_cidade: "Ibaté",
      nm_cidade_uf: "Ibaté - SP"
  },
  {
      id_cidade: "4930",
      id_estado: "26",
      nm_cidade: "Ibirá",
      nm_cidade_uf: "Ibirá - SP"
  },
  {
      id_cidade: "4931",
      id_estado: "26",
      nm_cidade: "Ibirarema",
      nm_cidade_uf: "Ibirarema - SP"
  },
  {
      id_cidade: "4932",
      id_estado: "26",
      nm_cidade: "Ibitinga",
      nm_cidade_uf: "Ibitinga - SP"
  },
  {
      id_cidade: "4933",
      id_estado: "26",
      nm_cidade: "Ibiúna",
      nm_cidade_uf: "Ibiúna - SP"
  },
  {
      id_cidade: "4934",
      id_estado: "26",
      nm_cidade: "Icém",
      nm_cidade_uf: "Icém - SP"
  },
  {
      id_cidade: "4935",
      id_estado: "26",
      nm_cidade: "Iepê",
      nm_cidade_uf: "Iepê - SP"
  },
  {
      id_cidade: "4936",
      id_estado: "26",
      nm_cidade: "Igaraçu do Tietê",
      nm_cidade_uf: "Igaraçu do Tietê - SP"
  },
  {
      id_cidade: "4937",
      id_estado: "26",
      nm_cidade: "Igarapava",
      nm_cidade_uf: "Igarapava - SP"
  },
  {
      id_cidade: "4938",
      id_estado: "26",
      nm_cidade: "Igaratá",
      nm_cidade_uf: "Igaratá - SP"
  },
  {
      id_cidade: "4939",
      id_estado: "26",
      nm_cidade: "Iguape",
      nm_cidade_uf: "Iguape - SP"
  },
  {
      id_cidade: "4940",
      id_estado: "26",
      nm_cidade: "Ilha Comprida",
      nm_cidade_uf: "Ilha Comprida - SP"
  },
  {
      id_cidade: "4941",
      id_estado: "26",
      nm_cidade: "Ilha Solteira",
      nm_cidade_uf: "Ilha Solteira - SP"
  },
  {
      id_cidade: "4942",
      id_estado: "26",
      nm_cidade: "Ilhabela",
      nm_cidade_uf: "Ilhabela - SP"
  },
  {
      id_cidade: "4943",
      id_estado: "26",
      nm_cidade: "Indaiatuba",
      nm_cidade_uf: "Indaiatuba - SP"
  },
  {
      id_cidade: "4944",
      id_estado: "26",
      nm_cidade: "Indiana",
      nm_cidade_uf: "Indiana - SP"
  },
  {
      id_cidade: "4945",
      id_estado: "26",
      nm_cidade: "Indiaporã",
      nm_cidade_uf: "Indiaporã - SP"
  },
  {
      id_cidade: "4946",
      id_estado: "26",
      nm_cidade: "Inúbia Paulista",
      nm_cidade_uf: "Inúbia Paulista - SP"
  },
  {
      id_cidade: "4947",
      id_estado: "26",
      nm_cidade: "Ipaussu",
      nm_cidade_uf: "Ipaussu - SP"
  },
  {
      id_cidade: "4948",
      id_estado: "26",
      nm_cidade: "Iperó",
      nm_cidade_uf: "Iperó - SP"
  },
  {
      id_cidade: "4949",
      id_estado: "26",
      nm_cidade: "Ipeúna",
      nm_cidade_uf: "Ipeúna - SP"
  },
  {
      id_cidade: "4950",
      id_estado: "26",
      nm_cidade: "Ipiguá",
      nm_cidade_uf: "Ipiguá - SP"
  },
  {
      id_cidade: "4951",
      id_estado: "26",
      nm_cidade: "Iporanga",
      nm_cidade_uf: "Iporanga - SP"
  },
  {
      id_cidade: "4952",
      id_estado: "26",
      nm_cidade: "Ipuã",
      nm_cidade_uf: "Ipuã - SP"
  },
  {
      id_cidade: "4953",
      id_estado: "26",
      nm_cidade: "Iracemápolis",
      nm_cidade_uf: "Iracemápolis - SP"
  },
  {
      id_cidade: "4954",
      id_estado: "26",
      nm_cidade: "Irapuã",
      nm_cidade_uf: "Irapuã - SP"
  },
  {
      id_cidade: "4955",
      id_estado: "26",
      nm_cidade: "Irapuru",
      nm_cidade_uf: "Irapuru - SP"
  },
  {
      id_cidade: "4956",
      id_estado: "26",
      nm_cidade: "Itaberá",
      nm_cidade_uf: "Itaberá - SP"
  },
  {
      id_cidade: "4957",
      id_estado: "26",
      nm_cidade: "Itaí",
      nm_cidade_uf: "Itaí - SP"
  },
  {
      id_cidade: "4958",
      id_estado: "26",
      nm_cidade: "Itajobi",
      nm_cidade_uf: "Itajobi - SP"
  },
  {
      id_cidade: "4959",
      id_estado: "26",
      nm_cidade: "Itaju",
      nm_cidade_uf: "Itaju - SP"
  },
  {
      id_cidade: "4960",
      id_estado: "26",
      nm_cidade: "Itanhaém",
      nm_cidade_uf: "Itanhaém - SP"
  },
  {
      id_cidade: "4961",
      id_estado: "26",
      nm_cidade: "Itaóca",
      nm_cidade_uf: "Itaóca - SP"
  },
  {
      id_cidade: "4962",
      id_estado: "26",
      nm_cidade: "Itapecerica da Serra",
      nm_cidade_uf: "Itapecerica da Serra - SP"
  },
  {
      id_cidade: "4963",
      id_estado: "26",
      nm_cidade: "Itapetininga",
      nm_cidade_uf: "Itapetininga - SP"
  },
  {
      id_cidade: "4964",
      id_estado: "26",
      nm_cidade: "Itapeva",
      nm_cidade_uf: "Itapeva - SP"
  },
  {
      id_cidade: "4965",
      id_estado: "26",
      nm_cidade: "Itapevi",
      nm_cidade_uf: "Itapevi - SP"
  },
  {
      id_cidade: "4966",
      id_estado: "26",
      nm_cidade: "Itapira",
      nm_cidade_uf: "Itapira - SP"
  },
  {
      id_cidade: "4967",
      id_estado: "26",
      nm_cidade: "Itapirapuã Paulista",
      nm_cidade_uf: "Itapirapuã Paulista - SP"
  },
  {
      id_cidade: "4968",
      id_estado: "26",
      nm_cidade: "Itápolis",
      nm_cidade_uf: "Itápolis - SP"
  },
  {
      id_cidade: "4969",
      id_estado: "26",
      nm_cidade: "Itaporanga",
      nm_cidade_uf: "Itaporanga - SP"
  },
  {
      id_cidade: "4970",
      id_estado: "26",
      nm_cidade: "Itapuí",
      nm_cidade_uf: "Itapuí - SP"
  },
  {
      id_cidade: "4971",
      id_estado: "26",
      nm_cidade: "Itapura",
      nm_cidade_uf: "Itapura - SP"
  },
  {
      id_cidade: "4972",
      id_estado: "26",
      nm_cidade: "Itaquaquecetuba",
      nm_cidade_uf: "Itaquaquecetuba - SP"
  },
  {
      id_cidade: "4973",
      id_estado: "26",
      nm_cidade: "Itararé",
      nm_cidade_uf: "Itararé - SP"
  },
  {
      id_cidade: "4974",
      id_estado: "26",
      nm_cidade: "Itariri",
      nm_cidade_uf: "Itariri - SP"
  },
  {
      id_cidade: "4975",
      id_estado: "26",
      nm_cidade: "Itatiba",
      nm_cidade_uf: "Itatiba - SP"
  },
  {
      id_cidade: "4976",
      id_estado: "26",
      nm_cidade: "Itatinga",
      nm_cidade_uf: "Itatinga - SP"
  },
  {
      id_cidade: "4977",
      id_estado: "26",
      nm_cidade: "Itirapina",
      nm_cidade_uf: "Itirapina - SP"
  },
  {
      id_cidade: "4978",
      id_estado: "26",
      nm_cidade: "Itirapuã",
      nm_cidade_uf: "Itirapuã - SP"
  },
  {
      id_cidade: "4979",
      id_estado: "26",
      nm_cidade: "Itobi",
      nm_cidade_uf: "Itobi - SP"
  },
  {
      id_cidade: "4980",
      id_estado: "26",
      nm_cidade: "Itu",
      nm_cidade_uf: "Itu - SP"
  },
  {
      id_cidade: "4981",
      id_estado: "26",
      nm_cidade: "Itupeva",
      nm_cidade_uf: "Itupeva - SP"
  },
  {
      id_cidade: "4982",
      id_estado: "26",
      nm_cidade: "Ituverava",
      nm_cidade_uf: "Ituverava - SP"
  },
  {
      id_cidade: "4983",
      id_estado: "26",
      nm_cidade: "Jaborandi",
      nm_cidade_uf: "Jaborandi - SP"
  },
  {
      id_cidade: "4984",
      id_estado: "26",
      nm_cidade: "Jaboticabal",
      nm_cidade_uf: "Jaboticabal - SP"
  },
  {
      id_cidade: "4985",
      id_estado: "26",
      nm_cidade: "Jacareí",
      nm_cidade_uf: "Jacareí - SP"
  },
  {
      id_cidade: "4986",
      id_estado: "26",
      nm_cidade: "Jaci",
      nm_cidade_uf: "Jaci - SP"
  },
  {
      id_cidade: "4987",
      id_estado: "26",
      nm_cidade: "Jacupiranga",
      nm_cidade_uf: "Jacupiranga - SP"
  },
  {
      id_cidade: "4988",
      id_estado: "26",
      nm_cidade: "Jaguariúna",
      nm_cidade_uf: "Jaguariúna - SP"
  },
  {
      id_cidade: "4989",
      id_estado: "26",
      nm_cidade: "Jales",
      nm_cidade_uf: "Jales - SP"
  },
  {
      id_cidade: "4990",
      id_estado: "26",
      nm_cidade: "Jambeiro",
      nm_cidade_uf: "Jambeiro - SP"
  },
  {
      id_cidade: "4991",
      id_estado: "26",
      nm_cidade: "Jandira",
      nm_cidade_uf: "Jandira - SP"
  },
  {
      id_cidade: "4992",
      id_estado: "26",
      nm_cidade: "Jardinópolis",
      nm_cidade_uf: "Jardinópolis - SP"
  },
  {
      id_cidade: "4993",
      id_estado: "26",
      nm_cidade: "Jarinu",
      nm_cidade_uf: "Jarinu - SP"
  },
  {
      id_cidade: "4994",
      id_estado: "26",
      nm_cidade: "Jaú",
      nm_cidade_uf: "Jaú - SP"
  },
  {
      id_cidade: "4995",
      id_estado: "26",
      nm_cidade: "Jeriquara",
      nm_cidade_uf: "Jeriquara - SP"
  },
  {
      id_cidade: "4996",
      id_estado: "26",
      nm_cidade: "Joanópolis",
      nm_cidade_uf: "Joanópolis - SP"
  },
  {
      id_cidade: "4997",
      id_estado: "26",
      nm_cidade: "João Ramalho",
      nm_cidade_uf: "João Ramalho - SP"
  },
  {
      id_cidade: "4998",
      id_estado: "26",
      nm_cidade: "José Bonifácio",
      nm_cidade_uf: "José Bonifácio - SP"
  },
  {
      id_cidade: "4999",
      id_estado: "26",
      nm_cidade: "Júlio Mesquita",
      nm_cidade_uf: "Júlio Mesquita - SP"
  },
  {
      id_cidade: "5000",
      id_estado: "26",
      nm_cidade: "Jumirim",
      nm_cidade_uf: "Jumirim - SP"
  },
  {
      id_cidade: "5001",
      id_estado: "26",
      nm_cidade: "Jundiaí",
      nm_cidade_uf: "Jundiaí - SP"
  },
  {
      id_cidade: "5002",
      id_estado: "26",
      nm_cidade: "Junqueirópolis",
      nm_cidade_uf: "Junqueirópolis - SP"
  },
  {
      id_cidade: "5003",
      id_estado: "26",
      nm_cidade: "Juquiá",
      nm_cidade_uf: "Juquiá - SP"
  },
  {
      id_cidade: "5004",
      id_estado: "26",
      nm_cidade: "Juquitiba",
      nm_cidade_uf: "Juquitiba - SP"
  },
  {
      id_cidade: "5005",
      id_estado: "26",
      nm_cidade: "Lagoinha",
      nm_cidade_uf: "Lagoinha - SP"
  },
  {
      id_cidade: "5006",
      id_estado: "26",
      nm_cidade: "Laranjal Paulista",
      nm_cidade_uf: "Laranjal Paulista - SP"
  },
  {
      id_cidade: "5007",
      id_estado: "26",
      nm_cidade: "Lavínia",
      nm_cidade_uf: "Lavínia - SP"
  },
  {
      id_cidade: "5008",
      id_estado: "26",
      nm_cidade: "Lavrinhas",
      nm_cidade_uf: "Lavrinhas - SP"
  },
  {
      id_cidade: "5009",
      id_estado: "26",
      nm_cidade: "Leme",
      nm_cidade_uf: "Leme - SP"
  },
  {
      id_cidade: "5010",
      id_estado: "26",
      nm_cidade: "Lençóis Paulista",
      nm_cidade_uf: "Lençóis Paulista - SP"
  },
  {
      id_cidade: "5011",
      id_estado: "26",
      nm_cidade: "Limeira",
      nm_cidade_uf: "Limeira - SP"
  },
  {
      id_cidade: "5012",
      id_estado: "26",
      nm_cidade: "Lindóia",
      nm_cidade_uf: "Lindóia - SP"
  },
  {
      id_cidade: "5013",
      id_estado: "26",
      nm_cidade: "Lins",
      nm_cidade_uf: "Lins - SP"
  },
  {
      id_cidade: "5014",
      id_estado: "26",
      nm_cidade: "Lorena",
      nm_cidade_uf: "Lorena - SP"
  },
  {
      id_cidade: "5015",
      id_estado: "26",
      nm_cidade: "Lourdes",
      nm_cidade_uf: "Lourdes - SP"
  },
  {
      id_cidade: "5016",
      id_estado: "26",
      nm_cidade: "Louveira",
      nm_cidade_uf: "Louveira - SP"
  },
  {
      id_cidade: "5017",
      id_estado: "26",
      nm_cidade: "Lucélia",
      nm_cidade_uf: "Lucélia - SP"
  },
  {
      id_cidade: "5018",
      id_estado: "26",
      nm_cidade: "Lucianópolis",
      nm_cidade_uf: "Lucianópolis - SP"
  },
  {
      id_cidade: "5019",
      id_estado: "26",
      nm_cidade: "Luís Antônio",
      nm_cidade_uf: "Luís Antônio - SP"
  },
  {
      id_cidade: "5020",
      id_estado: "26",
      nm_cidade: "Luiziânia",
      nm_cidade_uf: "Luiziânia - SP"
  },
  {
      id_cidade: "5021",
      id_estado: "26",
      nm_cidade: "Lupércio",
      nm_cidade_uf: "Lupércio - SP"
  },
  {
      id_cidade: "5022",
      id_estado: "26",
      nm_cidade: "Lutécia",
      nm_cidade_uf: "Lutécia - SP"
  },
  {
      id_cidade: "5023",
      id_estado: "26",
      nm_cidade: "Macatuba",
      nm_cidade_uf: "Macatuba - SP"
  },
  {
      id_cidade: "5024",
      id_estado: "26",
      nm_cidade: "Macaubal",
      nm_cidade_uf: "Macaubal - SP"
  },
  {
      id_cidade: "5025",
      id_estado: "26",
      nm_cidade: "Macedônia",
      nm_cidade_uf: "Macedônia - SP"
  },
  {
      id_cidade: "5026",
      id_estado: "26",
      nm_cidade: "Magda",
      nm_cidade_uf: "Magda - SP"
  },
  {
      id_cidade: "5027",
      id_estado: "26",
      nm_cidade: "Mairinque",
      nm_cidade_uf: "Mairinque - SP"
  },
  {
      id_cidade: "5028",
      id_estado: "26",
      nm_cidade: "Mairiporã",
      nm_cidade_uf: "Mairiporã - SP"
  },
  {
      id_cidade: "5029",
      id_estado: "26",
      nm_cidade: "Manduri",
      nm_cidade_uf: "Manduri - SP"
  },
  {
      id_cidade: "5030",
      id_estado: "26",
      nm_cidade: "Marabá Paulista",
      nm_cidade_uf: "Marabá Paulista - SP"
  },
  {
      id_cidade: "5031",
      id_estado: "26",
      nm_cidade: "Maracaí",
      nm_cidade_uf: "Maracaí - SP"
  },
  {
      id_cidade: "5032",
      id_estado: "26",
      nm_cidade: "Marapoama",
      nm_cidade_uf: "Marapoama - SP"
  },
  {
      id_cidade: "5033",
      id_estado: "26",
      nm_cidade: "Mariápolis",
      nm_cidade_uf: "Mariápolis - SP"
  },
  {
      id_cidade: "5034",
      id_estado: "26",
      nm_cidade: "Marília",
      nm_cidade_uf: "Marília - SP"
  },
  {
      id_cidade: "5035",
      id_estado: "26",
      nm_cidade: "Marinópolis",
      nm_cidade_uf: "Marinópolis - SP"
  },
  {
      id_cidade: "5036",
      id_estado: "26",
      nm_cidade: "Martinópolis",
      nm_cidade_uf: "Martinópolis - SP"
  },
  {
      id_cidade: "5037",
      id_estado: "26",
      nm_cidade: "Matão",
      nm_cidade_uf: "Matão - SP"
  },
  {
      id_cidade: "5038",
      id_estado: "26",
      nm_cidade: "Mauá",
      nm_cidade_uf: "Mauá - SP"
  },
  {
      id_cidade: "5039",
      id_estado: "26",
      nm_cidade: "Mendonça",
      nm_cidade_uf: "Mendonça - SP"
  },
  {
      id_cidade: "5040",
      id_estado: "26",
      nm_cidade: "Meridiano",
      nm_cidade_uf: "Meridiano - SP"
  },
  {
      id_cidade: "5041",
      id_estado: "26",
      nm_cidade: "Mesópolis",
      nm_cidade_uf: "Mesópolis - SP"
  },
  {
      id_cidade: "5042",
      id_estado: "26",
      nm_cidade: "Miguelópolis",
      nm_cidade_uf: "Miguelópolis - SP"
  },
  {
      id_cidade: "5043",
      id_estado: "26",
      nm_cidade: "Mineiros do Tietê",
      nm_cidade_uf: "Mineiros do Tietê - SP"
  },
  {
      id_cidade: "5044",
      id_estado: "26",
      nm_cidade: "Mira Estrela",
      nm_cidade_uf: "Mira Estrela - SP"
  },
  {
      id_cidade: "5045",
      id_estado: "26",
      nm_cidade: "Miracatu",
      nm_cidade_uf: "Miracatu - SP"
  },
  {
      id_cidade: "5046",
      id_estado: "26",
      nm_cidade: "Mirandópolis",
      nm_cidade_uf: "Mirandópolis - SP"
  },
  {
      id_cidade: "5047",
      id_estado: "26",
      nm_cidade: "Mirante do Paranapanema",
      nm_cidade_uf: "Mirante do Paranapanema - SP"
  },
  {
      id_cidade: "5048",
      id_estado: "26",
      nm_cidade: "Mirassol",
      nm_cidade_uf: "Mirassol - SP"
  },
  {
      id_cidade: "5049",
      id_estado: "26",
      nm_cidade: "Mirassolândia",
      nm_cidade_uf: "Mirassolândia - SP"
  },
  {
      id_cidade: "5050",
      id_estado: "26",
      nm_cidade: "Mococa",
      nm_cidade_uf: "Mococa - SP"
  },
  {
      id_cidade: "5051",
      id_estado: "26",
      nm_cidade: "Mogi das Cruzes",
      nm_cidade_uf: "Mogi das Cruzes - SP"
  },
  {
      id_cidade: "5052",
      id_estado: "26",
      nm_cidade: "Mogi Guaçu",
      nm_cidade_uf: "Mogi Guaçu - SP"
  },
  {
      id_cidade: "5053",
      id_estado: "26",
      nm_cidade: "Moji Mirim",
      nm_cidade_uf: "Moji Mirim - SP"
  },
  {
      id_cidade: "5054",
      id_estado: "26",
      nm_cidade: "Mombuca",
      nm_cidade_uf: "Mombuca - SP"
  },
  {
      id_cidade: "5055",
      id_estado: "26",
      nm_cidade: "Monções",
      nm_cidade_uf: "Monções - SP"
  },
  {
      id_cidade: "5056",
      id_estado: "26",
      nm_cidade: "Mongaguá",
      nm_cidade_uf: "Mongaguá - SP"
  },
  {
      id_cidade: "5057",
      id_estado: "26",
      nm_cidade: "Monte Alegre do Sul",
      nm_cidade_uf: "Monte Alegre do Sul - SP"
  },
  {
      id_cidade: "5058",
      id_estado: "26",
      nm_cidade: "Monte Alto",
      nm_cidade_uf: "Monte Alto - SP"
  },
  {
      id_cidade: "5059",
      id_estado: "26",
      nm_cidade: "Monte Aprazível",
      nm_cidade_uf: "Monte Aprazível - SP"
  },
  {
      id_cidade: "5060",
      id_estado: "26",
      nm_cidade: "Monte Azul Paulista",
      nm_cidade_uf: "Monte Azul Paulista - SP"
  },
  {
      id_cidade: "5061",
      id_estado: "26",
      nm_cidade: "Monte Castelo",
      nm_cidade_uf: "Monte Castelo - SP"
  },
  {
      id_cidade: "5062",
      id_estado: "26",
      nm_cidade: "Monte Mor",
      nm_cidade_uf: "Monte Mor - SP"
  },
  {
      id_cidade: "5063",
      id_estado: "26",
      nm_cidade: "Monteiro Lobato",
      nm_cidade_uf: "Monteiro Lobato - SP"
  },
  {
      id_cidade: "5064",
      id_estado: "26",
      nm_cidade: "Morro Agudo",
      nm_cidade_uf: "Morro Agudo - SP"
  },
  {
      id_cidade: "5065",
      id_estado: "26",
      nm_cidade: "Morungaba",
      nm_cidade_uf: "Morungaba - SP"
  },
  {
      id_cidade: "5066",
      id_estado: "26",
      nm_cidade: "Motuca",
      nm_cidade_uf: "Motuca - SP"
  },
  {
      id_cidade: "5067",
      id_estado: "26",
      nm_cidade: "Murutinga do Sul",
      nm_cidade_uf: "Murutinga do Sul - SP"
  },
  {
      id_cidade: "5068",
      id_estado: "26",
      nm_cidade: "Nantes",
      nm_cidade_uf: "Nantes - SP"
  },
  {
      id_cidade: "5069",
      id_estado: "26",
      nm_cidade: "Narandiba",
      nm_cidade_uf: "Narandiba - SP"
  },
  {
      id_cidade: "5070",
      id_estado: "26",
      nm_cidade: "Natividade da Serra",
      nm_cidade_uf: "Natividade da Serra - SP"
  },
  {
      id_cidade: "5071",
      id_estado: "26",
      nm_cidade: "Nazaré Paulista",
      nm_cidade_uf: "Nazaré Paulista - SP"
  },
  {
      id_cidade: "5072",
      id_estado: "26",
      nm_cidade: "Neves Paulista",
      nm_cidade_uf: "Neves Paulista - SP"
  },
  {
      id_cidade: "5073",
      id_estado: "26",
      nm_cidade: "Nhandeara",
      nm_cidade_uf: "Nhandeara - SP"
  },
  {
      id_cidade: "5074",
      id_estado: "26",
      nm_cidade: "Nipoã",
      nm_cidade_uf: "Nipoã - SP"
  },
  {
      id_cidade: "5075",
      id_estado: "26",
      nm_cidade: "Nova Aliança",
      nm_cidade_uf: "Nova Aliança - SP"
  },
  {
      id_cidade: "5076",
      id_estado: "26",
      nm_cidade: "Nova Campina",
      nm_cidade_uf: "Nova Campina - SP"
  },
  {
      id_cidade: "5077",
      id_estado: "26",
      nm_cidade: "Nova Canaã Paulista",
      nm_cidade_uf: "Nova Canaã Paulista - SP"
  },
  {
      id_cidade: "5078",
      id_estado: "26",
      nm_cidade: "Nova Castilho",
      nm_cidade_uf: "Nova Castilho - SP"
  },
  {
      id_cidade: "5079",
      id_estado: "26",
      nm_cidade: "Nova Europa",
      nm_cidade_uf: "Nova Europa - SP"
  },
  {
      id_cidade: "5080",
      id_estado: "26",
      nm_cidade: "Nova Granada",
      nm_cidade_uf: "Nova Granada - SP"
  },
  {
      id_cidade: "5081",
      id_estado: "26",
      nm_cidade: "Nova Guataporanga",
      nm_cidade_uf: "Nova Guataporanga - SP"
  },
  {
      id_cidade: "5082",
      id_estado: "26",
      nm_cidade: "Nova Independência",
      nm_cidade_uf: "Nova Independência - SP"
  },
  {
      id_cidade: "5083",
      id_estado: "26",
      nm_cidade: "Nova Luzitânia",
      nm_cidade_uf: "Nova Luzitânia - SP"
  },
  {
      id_cidade: "5084",
      id_estado: "26",
      nm_cidade: "Nova Odessa",
      nm_cidade_uf: "Nova Odessa - SP"
  },
  {
      id_cidade: "5085",
      id_estado: "26",
      nm_cidade: "Novais",
      nm_cidade_uf: "Novais - SP"
  },
  {
      id_cidade: "5086",
      id_estado: "26",
      nm_cidade: "Novo Horizonte",
      nm_cidade_uf: "Novo Horizonte - SP"
  },
  {
      id_cidade: "5087",
      id_estado: "26",
      nm_cidade: "Nuporanga",
      nm_cidade_uf: "Nuporanga - SP"
  },
  {
      id_cidade: "5088",
      id_estado: "26",
      nm_cidade: "Ocauçu",
      nm_cidade_uf: "Ocauçu - SP"
  },
  {
      id_cidade: "5089",
      id_estado: "26",
      nm_cidade: "Óleo",
      nm_cidade_uf: "Óleo - SP"
  },
  {
      id_cidade: "5090",
      id_estado: "26",
      nm_cidade: "Olímpia",
      nm_cidade_uf: "Olímpia - SP"
  },
  {
      id_cidade: "5091",
      id_estado: "26",
      nm_cidade: "Onda Verde",
      nm_cidade_uf: "Onda Verde - SP"
  },
  {
      id_cidade: "5092",
      id_estado: "26",
      nm_cidade: "Oriente",
      nm_cidade_uf: "Oriente - SP"
  },
  {
      id_cidade: "5093",
      id_estado: "26",
      nm_cidade: "Orindiúva",
      nm_cidade_uf: "Orindiúva - SP"
  },
  {
      id_cidade: "5094",
      id_estado: "26",
      nm_cidade: "Orlândia",
      nm_cidade_uf: "Orlândia - SP"
  },
  {
      id_cidade: "5095",
      id_estado: "26",
      nm_cidade: "Osasco",
      nm_cidade_uf: "Osasco - SP"
  },
  {
      id_cidade: "5096",
      id_estado: "26",
      nm_cidade: "Oscar Bressane",
      nm_cidade_uf: "Oscar Bressane - SP"
  },
  {
      id_cidade: "5097",
      id_estado: "26",
      nm_cidade: "Osvaldo Cruz",
      nm_cidade_uf: "Osvaldo Cruz - SP"
  },
  {
      id_cidade: "5098",
      id_estado: "26",
      nm_cidade: "Ourinhos",
      nm_cidade_uf: "Ourinhos - SP"
  },
  {
      id_cidade: "5099",
      id_estado: "26",
      nm_cidade: "Ouro Verde",
      nm_cidade_uf: "Ouro Verde - SP"
  },
  {
      id_cidade: "5100",
      id_estado: "26",
      nm_cidade: "Ouroeste",
      nm_cidade_uf: "Ouroeste - SP"
  },
  {
      id_cidade: "5101",
      id_estado: "26",
      nm_cidade: "Pacaembu",
      nm_cidade_uf: "Pacaembu - SP"
  },
  {
      id_cidade: "5102",
      id_estado: "26",
      nm_cidade: "Palestina",
      nm_cidade_uf: "Palestina - SP"
  },
  {
      id_cidade: "5103",
      id_estado: "26",
      nm_cidade: "Palmares Paulista",
      nm_cidade_uf: "Palmares Paulista - SP"
  },
  {
      id_cidade: "5104",
      id_estado: "26",
      nm_cidade: "Palmeira d`Oeste",
      nm_cidade_uf: "Palmeira d`Oeste - SP"
  },
  {
      id_cidade: "5105",
      id_estado: "26",
      nm_cidade: "Palmital",
      nm_cidade_uf: "Palmital - SP"
  },
  {
      id_cidade: "5106",
      id_estado: "26",
      nm_cidade: "Panorama",
      nm_cidade_uf: "Panorama - SP"
  },
  {
      id_cidade: "5107",
      id_estado: "26",
      nm_cidade: "Paraguaçu Paulista",
      nm_cidade_uf: "Paraguaçu Paulista - SP"
  },
  {
      id_cidade: "5108",
      id_estado: "26",
      nm_cidade: "Paraibuna",
      nm_cidade_uf: "Paraibuna - SP"
  },
  {
      id_cidade: "5109",
      id_estado: "26",
      nm_cidade: "Paraíso",
      nm_cidade_uf: "Paraíso - SP"
  },
  {
      id_cidade: "5110",
      id_estado: "26",
      nm_cidade: "Paranapanema",
      nm_cidade_uf: "Paranapanema - SP"
  },
  {
      id_cidade: "5111",
      id_estado: "26",
      nm_cidade: "Paranapuã",
      nm_cidade_uf: "Paranapuã - SP"
  },
  {
      id_cidade: "5112",
      id_estado: "26",
      nm_cidade: "Parapuã",
      nm_cidade_uf: "Parapuã - SP"
  },
  {
      id_cidade: "5113",
      id_estado: "26",
      nm_cidade: "Pardinho",
      nm_cidade_uf: "Pardinho - SP"
  },
  {
      id_cidade: "5114",
      id_estado: "26",
      nm_cidade: "Pariquera-Açu",
      nm_cidade_uf: "Pariquera-Açu - SP"
  },
  {
      id_cidade: "5115",
      id_estado: "26",
      nm_cidade: "Parisi",
      nm_cidade_uf: "Parisi - SP"
  },
  {
      id_cidade: "5116",
      id_estado: "26",
      nm_cidade: "Patrocínio Paulista",
      nm_cidade_uf: "Patrocínio Paulista - SP"
  },
  {
      id_cidade: "5117",
      id_estado: "26",
      nm_cidade: "Paulicéia",
      nm_cidade_uf: "Paulicéia - SP"
  },
  {
      id_cidade: "5118",
      id_estado: "26",
      nm_cidade: "Paulínia",
      nm_cidade_uf: "Paulínia - SP"
  },
  {
      id_cidade: "5119",
      id_estado: "26",
      nm_cidade: "Paulistânia",
      nm_cidade_uf: "Paulistânia - SP"
  },
  {
      id_cidade: "5120",
      id_estado: "26",
      nm_cidade: "Paulo de Faria",
      nm_cidade_uf: "Paulo de Faria - SP"
  },
  {
      id_cidade: "5121",
      id_estado: "26",
      nm_cidade: "Pederneiras",
      nm_cidade_uf: "Pederneiras - SP"
  },
  {
      id_cidade: "5122",
      id_estado: "26",
      nm_cidade: "Pedra Bela",
      nm_cidade_uf: "Pedra Bela - SP"
  },
  {
      id_cidade: "5123",
      id_estado: "26",
      nm_cidade: "Pedranópolis",
      nm_cidade_uf: "Pedranópolis - SP"
  },
  {
      id_cidade: "5124",
      id_estado: "26",
      nm_cidade: "Pedregulho",
      nm_cidade_uf: "Pedregulho - SP"
  },
  {
      id_cidade: "5125",
      id_estado: "26",
      nm_cidade: "Pedreira",
      nm_cidade_uf: "Pedreira - SP"
  },
  {
      id_cidade: "5126",
      id_estado: "26",
      nm_cidade: "Pedrinhas Paulista",
      nm_cidade_uf: "Pedrinhas Paulista - SP"
  },
  {
      id_cidade: "5127",
      id_estado: "26",
      nm_cidade: "Pedro de Toledo",
      nm_cidade_uf: "Pedro de Toledo - SP"
  },
  {
      id_cidade: "5128",
      id_estado: "26",
      nm_cidade: "Penápolis",
      nm_cidade_uf: "Penápolis - SP"
  },
  {
      id_cidade: "5129",
      id_estado: "26",
      nm_cidade: "Pereira Barreto",
      nm_cidade_uf: "Pereira Barreto - SP"
  },
  {
      id_cidade: "5130",
      id_estado: "26",
      nm_cidade: "Pereiras",
      nm_cidade_uf: "Pereiras - SP"
  },
  {
      id_cidade: "5131",
      id_estado: "26",
      nm_cidade: "Peruíbe",
      nm_cidade_uf: "Peruíbe - SP"
  },
  {
      id_cidade: "5132",
      id_estado: "26",
      nm_cidade: "Piacatu",
      nm_cidade_uf: "Piacatu - SP"
  },
  {
      id_cidade: "5133",
      id_estado: "26",
      nm_cidade: "Piedade",
      nm_cidade_uf: "Piedade - SP"
  },
  {
      id_cidade: "5134",
      id_estado: "26",
      nm_cidade: "Pilar do Sul",
      nm_cidade_uf: "Pilar do Sul - SP"
  },
  {
      id_cidade: "5135",
      id_estado: "26",
      nm_cidade: "Pindamonhangaba",
      nm_cidade_uf: "Pindamonhangaba - SP"
  },
  {
      id_cidade: "5136",
      id_estado: "26",
      nm_cidade: "Pindorama",
      nm_cidade_uf: "Pindorama - SP"
  },
  {
      id_cidade: "5137",
      id_estado: "26",
      nm_cidade: "Pinhalzinho",
      nm_cidade_uf: "Pinhalzinho - SP"
  },
  {
      id_cidade: "5138",
      id_estado: "26",
      nm_cidade: "Piquerobi",
      nm_cidade_uf: "Piquerobi - SP"
  },
  {
      id_cidade: "5139",
      id_estado: "26",
      nm_cidade: "Piquete",
      nm_cidade_uf: "Piquete - SP"
  },
  {
      id_cidade: "5140",
      id_estado: "26",
      nm_cidade: "Piracaia",
      nm_cidade_uf: "Piracaia - SP"
  },
  {
      id_cidade: "5141",
      id_estado: "26",
      nm_cidade: "Piracicaba",
      nm_cidade_uf: "Piracicaba - SP"
  },
  {
      id_cidade: "5142",
      id_estado: "26",
      nm_cidade: "Piraju",
      nm_cidade_uf: "Piraju - SP"
  },
  {
      id_cidade: "5143",
      id_estado: "26",
      nm_cidade: "Pirajuí",
      nm_cidade_uf: "Pirajuí - SP"
  },
  {
      id_cidade: "5144",
      id_estado: "26",
      nm_cidade: "Pirangi",
      nm_cidade_uf: "Pirangi - SP"
  },
  {
      id_cidade: "5145",
      id_estado: "26",
      nm_cidade: "Pirapora do Bom Jesus",
      nm_cidade_uf: "Pirapora do Bom Jesus - SP"
  },
  {
      id_cidade: "5146",
      id_estado: "26",
      nm_cidade: "Pirapozinho",
      nm_cidade_uf: "Pirapozinho - SP"
  },
  {
      id_cidade: "5147",
      id_estado: "26",
      nm_cidade: "Pirassununga",
      nm_cidade_uf: "Pirassununga - SP"
  },
  {
      id_cidade: "5148",
      id_estado: "26",
      nm_cidade: "Piratininga",
      nm_cidade_uf: "Piratininga - SP"
  },
  {
      id_cidade: "5149",
      id_estado: "26",
      nm_cidade: "Pitangueiras",
      nm_cidade_uf: "Pitangueiras - SP"
  },
  {
      id_cidade: "5150",
      id_estado: "26",
      nm_cidade: "Planalto",
      nm_cidade_uf: "Planalto - SP"
  },
  {
      id_cidade: "5151",
      id_estado: "26",
      nm_cidade: "Platina",
      nm_cidade_uf: "Platina - SP"
  },
  {
      id_cidade: "5152",
      id_estado: "26",
      nm_cidade: "Poá",
      nm_cidade_uf: "Poá - SP"
  },
  {
      id_cidade: "5153",
      id_estado: "26",
      nm_cidade: "Poloni",
      nm_cidade_uf: "Poloni - SP"
  },
  {
      id_cidade: "5154",
      id_estado: "26",
      nm_cidade: "Pompéia",
      nm_cidade_uf: "Pompéia - SP"
  },
  {
      id_cidade: "5155",
      id_estado: "26",
      nm_cidade: "Pongaí",
      nm_cidade_uf: "Pongaí - SP"
  },
  {
      id_cidade: "5156",
      id_estado: "26",
      nm_cidade: "Pontal",
      nm_cidade_uf: "Pontal - SP"
  },
  {
      id_cidade: "5157",
      id_estado: "26",
      nm_cidade: "Pontalinda",
      nm_cidade_uf: "Pontalinda - SP"
  },
  {
      id_cidade: "5158",
      id_estado: "26",
      nm_cidade: "Pontes Gestal",
      nm_cidade_uf: "Pontes Gestal - SP"
  },
  {
      id_cidade: "5159",
      id_estado: "26",
      nm_cidade: "Populina",
      nm_cidade_uf: "Populina - SP"
  },
  {
      id_cidade: "5160",
      id_estado: "26",
      nm_cidade: "Porangaba",
      nm_cidade_uf: "Porangaba - SP"
  },
  {
      id_cidade: "5161",
      id_estado: "26",
      nm_cidade: "Porto Feliz",
      nm_cidade_uf: "Porto Feliz - SP"
  },
  {
      id_cidade: "5162",
      id_estado: "26",
      nm_cidade: "Porto Ferreira",
      nm_cidade_uf: "Porto Ferreira - SP"
  },
  {
      id_cidade: "5163",
      id_estado: "26",
      nm_cidade: "Potim",
      nm_cidade_uf: "Potim - SP"
  },
  {
      id_cidade: "5164",
      id_estado: "26",
      nm_cidade: "Potirendaba",
      nm_cidade_uf: "Potirendaba - SP"
  },
  {
      id_cidade: "5165",
      id_estado: "26",
      nm_cidade: "Pracinha",
      nm_cidade_uf: "Pracinha - SP"
  },
  {
      id_cidade: "5166",
      id_estado: "26",
      nm_cidade: "Pradópolis",
      nm_cidade_uf: "Pradópolis - SP"
  },
  {
      id_cidade: "5167",
      id_estado: "26",
      nm_cidade: "Praia Grande",
      nm_cidade_uf: "Praia Grande - SP"
  },
  {
      id_cidade: "5168",
      id_estado: "26",
      nm_cidade: "Pratânia",
      nm_cidade_uf: "Pratânia - SP"
  },
  {
      id_cidade: "5169",
      id_estado: "26",
      nm_cidade: "Presidente Alves",
      nm_cidade_uf: "Presidente Alves - SP"
  },
  {
      id_cidade: "5170",
      id_estado: "26",
      nm_cidade: "Presidente Bernardes",
      nm_cidade_uf: "Presidente Bernardes - SP"
  },
  {
      id_cidade: "5171",
      id_estado: "26",
      nm_cidade: "Presidente Epitácio",
      nm_cidade_uf: "Presidente Epitácio - SP"
  },
  {
      id_cidade: "5172",
      id_estado: "26",
      nm_cidade: "Presidente Prudente",
      nm_cidade_uf: "Presidente Prudente - SP"
  },
  {
      id_cidade: "5173",
      id_estado: "26",
      nm_cidade: "Presidente Venceslau",
      nm_cidade_uf: "Presidente Venceslau - SP"
  },
  {
      id_cidade: "5174",
      id_estado: "26",
      nm_cidade: "Promissão",
      nm_cidade_uf: "Promissão - SP"
  },
  {
      id_cidade: "5175",
      id_estado: "26",
      nm_cidade: "Quadra",
      nm_cidade_uf: "Quadra - SP"
  },
  {
      id_cidade: "5176",
      id_estado: "26",
      nm_cidade: "Quatá",
      nm_cidade_uf: "Quatá - SP"
  },
  {
      id_cidade: "5177",
      id_estado: "26",
      nm_cidade: "Queiroz",
      nm_cidade_uf: "Queiroz - SP"
  },
  {
      id_cidade: "5178",
      id_estado: "26",
      nm_cidade: "Queluz",
      nm_cidade_uf: "Queluz - SP"
  },
  {
      id_cidade: "5179",
      id_estado: "26",
      nm_cidade: "Quintana",
      nm_cidade_uf: "Quintana - SP"
  },
  {
      id_cidade: "5180",
      id_estado: "26",
      nm_cidade: "Rafard",
      nm_cidade_uf: "Rafard - SP"
  },
  {
      id_cidade: "5181",
      id_estado: "26",
      nm_cidade: "Rancharia",
      nm_cidade_uf: "Rancharia - SP"
  },
  {
      id_cidade: "5182",
      id_estado: "26",
      nm_cidade: "Redenção da Serra",
      nm_cidade_uf: "Redenção da Serra - SP"
  },
  {
      id_cidade: "5183",
      id_estado: "26",
      nm_cidade: "Regente Feijó",
      nm_cidade_uf: "Regente Feijó - SP"
  },
  {
      id_cidade: "5184",
      id_estado: "26",
      nm_cidade: "Reginópolis",
      nm_cidade_uf: "Reginópolis - SP"
  },
  {
      id_cidade: "5185",
      id_estado: "26",
      nm_cidade: "Registro",
      nm_cidade_uf: "Registro - SP"
  },
  {
      id_cidade: "5186",
      id_estado: "26",
      nm_cidade: "Restinga",
      nm_cidade_uf: "Restinga - SP"
  },
  {
      id_cidade: "5187",
      id_estado: "26",
      nm_cidade: "Ribeira",
      nm_cidade_uf: "Ribeira - SP"
  },
  {
      id_cidade: "5188",
      id_estado: "26",
      nm_cidade: "Ribeirão Bonito",
      nm_cidade_uf: "Ribeirão Bonito - SP"
  },
  {
      id_cidade: "5189",
      id_estado: "26",
      nm_cidade: "Ribeirão Branco",
      nm_cidade_uf: "Ribeirão Branco - SP"
  },
  {
      id_cidade: "5190",
      id_estado: "26",
      nm_cidade: "Ribeirão Corrente",
      nm_cidade_uf: "Ribeirão Corrente - SP"
  },
  {
      id_cidade: "5191",
      id_estado: "26",
      nm_cidade: "Ribeirão do Sul",
      nm_cidade_uf: "Ribeirão do Sul - SP"
  },
  {
      id_cidade: "5192",
      id_estado: "26",
      nm_cidade: "Ribeirão dos Índios",
      nm_cidade_uf: "Ribeirão dos Índios - SP"
  },
  {
      id_cidade: "5193",
      id_estado: "26",
      nm_cidade: "Ribeirão Grande",
      nm_cidade_uf: "Ribeirão Grande - SP"
  },
  {
      id_cidade: "5194",
      id_estado: "26",
      nm_cidade: "Ribeirão Pires",
      nm_cidade_uf: "Ribeirão Pires - SP"
  },
  {
      id_cidade: "5195",
      id_estado: "26",
      nm_cidade: "Ribeirão Preto",
      nm_cidade_uf: "Ribeirão Preto - SP"
  },
  {
      id_cidade: "5196",
      id_estado: "26",
      nm_cidade: "Rifaina",
      nm_cidade_uf: "Rifaina - SP"
  },
  {
      id_cidade: "5197",
      id_estado: "26",
      nm_cidade: "Rincão",
      nm_cidade_uf: "Rincão - SP"
  },
  {
      id_cidade: "5198",
      id_estado: "26",
      nm_cidade: "Rinópolis",
      nm_cidade_uf: "Rinópolis - SP"
  },
  {
      id_cidade: "5199",
      id_estado: "26",
      nm_cidade: "Rio Claro",
      nm_cidade_uf: "Rio Claro - SP"
  },
  {
      id_cidade: "5200",
      id_estado: "26",
      nm_cidade: "Rio das Pedras",
      nm_cidade_uf: "Rio das Pedras - SP"
  },
  {
      id_cidade: "5201",
      id_estado: "26",
      nm_cidade: "Rio Grande da Serra",
      nm_cidade_uf: "Rio Grande da Serra - SP"
  },
  {
      id_cidade: "5202",
      id_estado: "26",
      nm_cidade: "Riolândia",
      nm_cidade_uf: "Riolândia - SP"
  },
  {
      id_cidade: "5203",
      id_estado: "26",
      nm_cidade: "Riversul",
      nm_cidade_uf: "Riversul - SP"
  },
  {
      id_cidade: "5204",
      id_estado: "26",
      nm_cidade: "Rosana",
      nm_cidade_uf: "Rosana - SP"
  },
  {
      id_cidade: "5205",
      id_estado: "26",
      nm_cidade: "Roseira",
      nm_cidade_uf: "Roseira - SP"
  },
  {
      id_cidade: "5206",
      id_estado: "26",
      nm_cidade: "Rubiácea",
      nm_cidade_uf: "Rubiácea - SP"
  },
  {
      id_cidade: "5207",
      id_estado: "26",
      nm_cidade: "Rubinéia",
      nm_cidade_uf: "Rubinéia - SP"
  },
  {
      id_cidade: "5208",
      id_estado: "26",
      nm_cidade: "Sabino",
      nm_cidade_uf: "Sabino - SP"
  },
  {
      id_cidade: "5209",
      id_estado: "26",
      nm_cidade: "Sagres",
      nm_cidade_uf: "Sagres - SP"
  },
  {
      id_cidade: "5210",
      id_estado: "26",
      nm_cidade: "Sales",
      nm_cidade_uf: "Sales - SP"
  },
  {
      id_cidade: "5211",
      id_estado: "26",
      nm_cidade: "Sales Oliveira",
      nm_cidade_uf: "Sales Oliveira - SP"
  },
  {
      id_cidade: "5212",
      id_estado: "26",
      nm_cidade: "Salesópolis",
      nm_cidade_uf: "Salesópolis - SP"
  },
  {
      id_cidade: "5213",
      id_estado: "26",
      nm_cidade: "Salmourão",
      nm_cidade_uf: "Salmourão - SP"
  },
  {
      id_cidade: "5214",
      id_estado: "26",
      nm_cidade: "Saltinho",
      nm_cidade_uf: "Saltinho - SP"
  },
  {
      id_cidade: "5215",
      id_estado: "26",
      nm_cidade: "Salto",
      nm_cidade_uf: "Salto - SP"
  },
  {
      id_cidade: "5216",
      id_estado: "26",
      nm_cidade: "Salto de Pirapora",
      nm_cidade_uf: "Salto de Pirapora - SP"
  },
  {
      id_cidade: "5217",
      id_estado: "26",
      nm_cidade: "Salto Grande",
      nm_cidade_uf: "Salto Grande - SP"
  },
  {
      id_cidade: "5218",
      id_estado: "26",
      nm_cidade: "Sandovalina",
      nm_cidade_uf: "Sandovalina - SP"
  },
  {
      id_cidade: "5219",
      id_estado: "26",
      nm_cidade: "Santa Adélia",
      nm_cidade_uf: "Santa Adélia - SP"
  },
  {
      id_cidade: "5220",
      id_estado: "26",
      nm_cidade: "Santa Albertina",
      nm_cidade_uf: "Santa Albertina - SP"
  },
  {
      id_cidade: "5221",
      id_estado: "26",
      nm_cidade: "Santa Bárbara d`Oeste",
      nm_cidade_uf: "Santa Bárbara d`Oeste - SP"
  },
  {
      id_cidade: "5222",
      id_estado: "26",
      nm_cidade: "Santa Branca",
      nm_cidade_uf: "Santa Branca - SP"
  },
  {
      id_cidade: "5223",
      id_estado: "26",
      nm_cidade: "Santa Clara d`Oeste",
      nm_cidade_uf: "Santa Clara d`Oeste - SP"
  },
  {
      id_cidade: "5224",
      id_estado: "26",
      nm_cidade: "Santa Cruz da Conceição",
      nm_cidade_uf: "Santa Cruz da Conceição - SP"
  },
  {
      id_cidade: "5225",
      id_estado: "26",
      nm_cidade: "Santa Cruz da Esperança",
      nm_cidade_uf: "Santa Cruz da Esperança - SP"
  },
  {
      id_cidade: "5226",
      id_estado: "26",
      nm_cidade: "Santa Cruz das Palmeiras",
      nm_cidade_uf: "Santa Cruz das Palmeiras - SP"
  },
  {
      id_cidade: "5227",
      id_estado: "26",
      nm_cidade: "Santa Cruz do Rio Pardo",
      nm_cidade_uf: "Santa Cruz do Rio Pardo - SP"
  },
  {
      id_cidade: "5228",
      id_estado: "26",
      nm_cidade: "Santa Ernestina",
      nm_cidade_uf: "Santa Ernestina - SP"
  },
  {
      id_cidade: "5229",
      id_estado: "26",
      nm_cidade: "Santa Fé do Sul",
      nm_cidade_uf: "Santa Fé do Sul - SP"
  },
  {
      id_cidade: "5230",
      id_estado: "26",
      nm_cidade: "Santa Gertrudes",
      nm_cidade_uf: "Santa Gertrudes - SP"
  },
  {
      id_cidade: "5231",
      id_estado: "26",
      nm_cidade: "Santa Isabel",
      nm_cidade_uf: "Santa Isabel - SP"
  },
  {
      id_cidade: "5232",
      id_estado: "26",
      nm_cidade: "Santa Lúcia",
      nm_cidade_uf: "Santa Lúcia - SP"
  },
  {
      id_cidade: "5233",
      id_estado: "26",
      nm_cidade: "Santa Maria da Serra",
      nm_cidade_uf: "Santa Maria da Serra - SP"
  },
  {
      id_cidade: "5234",
      id_estado: "26",
      nm_cidade: "Santa Mercedes",
      nm_cidade_uf: "Santa Mercedes - SP"
  },
  {
      id_cidade: "5235",
      id_estado: "26",
      nm_cidade: "Santa Rita d`Oeste",
      nm_cidade_uf: "Santa Rita d`Oeste - SP"
  },
  {
      id_cidade: "5236",
      id_estado: "26",
      nm_cidade: "Santa Rita do Passa Quatro",
      nm_cidade_uf: "Santa Rita do Passa Quatro - SP"
  },
  {
      id_cidade: "5237",
      id_estado: "26",
      nm_cidade: "Santa Rosa de Viterbo",
      nm_cidade_uf: "Santa Rosa de Viterbo - SP"
  },
  {
      id_cidade: "5238",
      id_estado: "26",
      nm_cidade: "Santa Salete",
      nm_cidade_uf: "Santa Salete - SP"
  },
  {
      id_cidade: "5239",
      id_estado: "26",
      nm_cidade: "Santana da Ponte Pensa",
      nm_cidade_uf: "Santana da Ponte Pensa - SP"
  },
  {
      id_cidade: "5240",
      id_estado: "26",
      nm_cidade: "Santana de Parnaíba",
      nm_cidade_uf: "Santana de Parnaíba - SP"
  },
  {
      id_cidade: "5241",
      id_estado: "26",
      nm_cidade: "Santo Anastácio",
      nm_cidade_uf: "Santo Anastácio - SP"
  },
  {
      id_cidade: "5242",
      id_estado: "26",
      nm_cidade: "Santo André",
      nm_cidade_uf: "Santo André - SP"
  },
  {
      id_cidade: "5243",
      id_estado: "26",
      nm_cidade: "Santo Antônio da Alegria",
      nm_cidade_uf: "Santo Antônio da Alegria - SP"
  },
  {
      id_cidade: "5244",
      id_estado: "26",
      nm_cidade: "Santo Antônio de Posse",
      nm_cidade_uf: "Santo Antônio de Posse - SP"
  },
  {
      id_cidade: "5245",
      id_estado: "26",
      nm_cidade: "Santo Antônio do Aracanguá",
      nm_cidade_uf: "Santo Antônio do Aracanguá - SP"
  },
  {
      id_cidade: "5246",
      id_estado: "26",
      nm_cidade: "Santo Antônio do Jardim",
      nm_cidade_uf: "Santo Antônio do Jardim - SP"
  },
  {
      id_cidade: "5247",
      id_estado: "26",
      nm_cidade: "Santo Antônio do Pinhal",
      nm_cidade_uf: "Santo Antônio do Pinhal - SP"
  },
  {
      id_cidade: "5248",
      id_estado: "26",
      nm_cidade: "Santo Expedito",
      nm_cidade_uf: "Santo Expedito - SP"
  },
  {
      id_cidade: "5249",
      id_estado: "26",
      nm_cidade: "Santópolis do Aguapeí",
      nm_cidade_uf: "Santópolis do Aguapeí - SP"
  },
  {
      id_cidade: "5250",
      id_estado: "26",
      nm_cidade: "Santos",
      nm_cidade_uf: "Santos - SP"
  },
  {
      id_cidade: "5251",
      id_estado: "26",
      nm_cidade: "São Bento do Sapucaí",
      nm_cidade_uf: "São Bento do Sapucaí - SP"
  },
  {
      id_cidade: "5252",
      id_estado: "26",
      nm_cidade: "São Bernardo do Campo",
      nm_cidade_uf: "São Bernardo do Campo - SP"
  },
  {
      id_cidade: "5253",
      id_estado: "26",
      nm_cidade: "São Caetano do Sul",
      nm_cidade_uf: "São Caetano do Sul - SP"
  },
  {
      id_cidade: "5254",
      id_estado: "26",
      nm_cidade: "São Carlos",
      nm_cidade_uf: "São Carlos - SP"
  },
  {
      id_cidade: "5255",
      id_estado: "26",
      nm_cidade: "São Francisco",
      nm_cidade_uf: "São Francisco - SP"
  },
  {
      id_cidade: "5256",
      id_estado: "26",
      nm_cidade: "São João da Boa Vista",
      nm_cidade_uf: "São João da Boa Vista - SP"
  },
  {
      id_cidade: "5257",
      id_estado: "26",
      nm_cidade: "São João das Duas Pontes",
      nm_cidade_uf: "São João das Duas Pontes - SP"
  },
  {
      id_cidade: "5258",
      id_estado: "26",
      nm_cidade: "São João de Iracema",
      nm_cidade_uf: "São João de Iracema - SP"
  },
  {
      id_cidade: "5259",
      id_estado: "26",
      nm_cidade: "São João do Pau d`Alho",
      nm_cidade_uf: "São João do Pau d`Alho - SP"
  },
  {
      id_cidade: "5260",
      id_estado: "26",
      nm_cidade: "São Joaquim da Barra",
      nm_cidade_uf: "São Joaquim da Barra - SP"
  },
  {
      id_cidade: "5261",
      id_estado: "26",
      nm_cidade: "São José da Bela Vista",
      nm_cidade_uf: "São José da Bela Vista - SP"
  },
  {
      id_cidade: "5262",
      id_estado: "26",
      nm_cidade: "São José do Barreiro",
      nm_cidade_uf: "São José do Barreiro - SP"
  },
  {
      id_cidade: "5263",
      id_estado: "26",
      nm_cidade: "São José do Rio Pardo",
      nm_cidade_uf: "São José do Rio Pardo - SP"
  },
  {
      id_cidade: "5264",
      id_estado: "26",
      nm_cidade: "São José do Rio Preto",
      nm_cidade_uf: "São José do Rio Preto - SP"
  },
  {
      id_cidade: "5265",
      id_estado: "26",
      nm_cidade: "São José dos Campos",
      nm_cidade_uf: "São José dos Campos - SP"
  },
  {
      id_cidade: "5266",
      id_estado: "26",
      nm_cidade: "São Lourenço da Serra",
      nm_cidade_uf: "São Lourenço da Serra - SP"
  },
  {
      id_cidade: "5267",
      id_estado: "26",
      nm_cidade: "São Luís do Paraitinga",
      nm_cidade_uf: "São Luís do Paraitinga - SP"
  },
  {
      id_cidade: "5268",
      id_estado: "26",
      nm_cidade: "São Manuel",
      nm_cidade_uf: "São Manuel - SP"
  },
  {
      id_cidade: "5269",
      id_estado: "26",
      nm_cidade: "São Miguel Arcanjo",
      nm_cidade_uf: "São Miguel Arcanjo - SP"
  },
  {
      id_cidade: "5270",
      id_estado: "26",
      nm_cidade: "São Paulo",
      nm_cidade_uf: "São Paulo - SP"
  },
  {
      id_cidade: "5271",
      id_estado: "26",
      nm_cidade: "São Pedro",
      nm_cidade_uf: "São Pedro - SP"
  },
  {
      id_cidade: "5272",
      id_estado: "26",
      nm_cidade: "São Pedro do Turvo",
      nm_cidade_uf: "São Pedro do Turvo - SP"
  },
  {
      id_cidade: "5273",
      id_estado: "26",
      nm_cidade: "São Roque",
      nm_cidade_uf: "São Roque - SP"
  },
  {
      id_cidade: "5274",
      id_estado: "26",
      nm_cidade: "São Sebastião",
      nm_cidade_uf: "São Sebastião - SP"
  },
  {
      id_cidade: "5275",
      id_estado: "26",
      nm_cidade: "São Sebastião da Grama",
      nm_cidade_uf: "São Sebastião da Grama - SP"
  },
  {
      id_cidade: "5276",
      id_estado: "26",
      nm_cidade: "São Simão",
      nm_cidade_uf: "São Simão - SP"
  },
  {
      id_cidade: "5277",
      id_estado: "26",
      nm_cidade: "São Vicente",
      nm_cidade_uf: "São Vicente - SP"
  },
  {
      id_cidade: "5278",
      id_estado: "26",
      nm_cidade: "Sarapuí",
      nm_cidade_uf: "Sarapuí - SP"
  },
  {
      id_cidade: "5279",
      id_estado: "26",
      nm_cidade: "Sarutaiá",
      nm_cidade_uf: "Sarutaiá - SP"
  },
  {
      id_cidade: "5280",
      id_estado: "26",
      nm_cidade: "Sebastianópolis do Sul",
      nm_cidade_uf: "Sebastianópolis do Sul - SP"
  },
  {
      id_cidade: "5281",
      id_estado: "26",
      nm_cidade: "Serra Azul",
      nm_cidade_uf: "Serra Azul - SP"
  },
  {
      id_cidade: "5282",
      id_estado: "26",
      nm_cidade: "Serra Negra",
      nm_cidade_uf: "Serra Negra - SP"
  },
  {
      id_cidade: "5283",
      id_estado: "26",
      nm_cidade: "Serrana",
      nm_cidade_uf: "Serrana - SP"
  },
  {
      id_cidade: "5284",
      id_estado: "26",
      nm_cidade: "Sertãozinho",
      nm_cidade_uf: "Sertãozinho - SP"
  },
  {
      id_cidade: "5285",
      id_estado: "26",
      nm_cidade: "Sete Barras",
      nm_cidade_uf: "Sete Barras - SP"
  },
  {
      id_cidade: "5286",
      id_estado: "26",
      nm_cidade: "Severínia",
      nm_cidade_uf: "Severínia - SP"
  },
  {
      id_cidade: "5287",
      id_estado: "26",
      nm_cidade: "Silveiras",
      nm_cidade_uf: "Silveiras - SP"
  },
  {
      id_cidade: "5288",
      id_estado: "26",
      nm_cidade: "Socorro",
      nm_cidade_uf: "Socorro - SP"
  },
  {
      id_cidade: "5289",
      id_estado: "26",
      nm_cidade: "Sorocaba",
      nm_cidade_uf: "Sorocaba - SP"
  },
  {
      id_cidade: "5290",
      id_estado: "26",
      nm_cidade: "Sud Mennucci",
      nm_cidade_uf: "Sud Mennucci - SP"
  },
  {
      id_cidade: "5291",
      id_estado: "26",
      nm_cidade: "Sumaré",
      nm_cidade_uf: "Sumaré - SP"
  },
  {
      id_cidade: "5292",
      id_estado: "26",
      nm_cidade: "Suzanápolis",
      nm_cidade_uf: "Suzanápolis - SP"
  },
  {
      id_cidade: "5293",
      id_estado: "26",
      nm_cidade: "Suzano",
      nm_cidade_uf: "Suzano - SP"
  },
  {
      id_cidade: "5294",
      id_estado: "26",
      nm_cidade: "Tabapuã",
      nm_cidade_uf: "Tabapuã - SP"
  },
  {
      id_cidade: "5295",
      id_estado: "26",
      nm_cidade: "Tabatinga",
      nm_cidade_uf: "Tabatinga - SP"
  },
  {
      id_cidade: "5296",
      id_estado: "26",
      nm_cidade: "Taboão da Serra",
      nm_cidade_uf: "Taboão da Serra - SP"
  },
  {
      id_cidade: "5297",
      id_estado: "26",
      nm_cidade: "Taciba",
      nm_cidade_uf: "Taciba - SP"
  },
  {
      id_cidade: "5298",
      id_estado: "26",
      nm_cidade: "Taguaí",
      nm_cidade_uf: "Taguaí - SP"
  },
  {
      id_cidade: "5299",
      id_estado: "26",
      nm_cidade: "Taiaçu",
      nm_cidade_uf: "Taiaçu - SP"
  },
  {
      id_cidade: "5300",
      id_estado: "26",
      nm_cidade: "Taiúva",
      nm_cidade_uf: "Taiúva - SP"
  },
  {
      id_cidade: "5301",
      id_estado: "26",
      nm_cidade: "Tambaú",
      nm_cidade_uf: "Tambaú - SP"
  },
  {
      id_cidade: "5302",
      id_estado: "26",
      nm_cidade: "Tanabi",
      nm_cidade_uf: "Tanabi - SP"
  },
  {
      id_cidade: "5303",
      id_estado: "26",
      nm_cidade: "Tapiraí",
      nm_cidade_uf: "Tapiraí - SP"
  },
  {
      id_cidade: "5304",
      id_estado: "26",
      nm_cidade: "Tapiratiba",
      nm_cidade_uf: "Tapiratiba - SP"
  },
  {
      id_cidade: "5305",
      id_estado: "26",
      nm_cidade: "Taquaral",
      nm_cidade_uf: "Taquaral - SP"
  },
  {
      id_cidade: "5306",
      id_estado: "26",
      nm_cidade: "Taquaritinga",
      nm_cidade_uf: "Taquaritinga - SP"
  },
  {
      id_cidade: "5307",
      id_estado: "26",
      nm_cidade: "Taquarituba",
      nm_cidade_uf: "Taquarituba - SP"
  },
  {
      id_cidade: "5308",
      id_estado: "26",
      nm_cidade: "Taquarivaí",
      nm_cidade_uf: "Taquarivaí - SP"
  },
  {
      id_cidade: "5309",
      id_estado: "26",
      nm_cidade: "Tarabai",
      nm_cidade_uf: "Tarabai - SP"
  },
  {
      id_cidade: "5310",
      id_estado: "26",
      nm_cidade: "Tarumã",
      nm_cidade_uf: "Tarumã - SP"
  },
  {
      id_cidade: "5311",
      id_estado: "26",
      nm_cidade: "Tatuí",
      nm_cidade_uf: "Tatuí - SP"
  },
  {
      id_cidade: "5312",
      id_estado: "26",
      nm_cidade: "Taubaté",
      nm_cidade_uf: "Taubaté - SP"
  },
  {
      id_cidade: "5313",
      id_estado: "26",
      nm_cidade: "Tejupá",
      nm_cidade_uf: "Tejupá - SP"
  },
  {
      id_cidade: "5314",
      id_estado: "26",
      nm_cidade: "Teodoro Sampaio",
      nm_cidade_uf: "Teodoro Sampaio - SP"
  },
  {
      id_cidade: "5315",
      id_estado: "26",
      nm_cidade: "Terra Roxa",
      nm_cidade_uf: "Terra Roxa - SP"
  },
  {
      id_cidade: "5316",
      id_estado: "26",
      nm_cidade: "Tietê",
      nm_cidade_uf: "Tietê - SP"
  },
  {
      id_cidade: "5317",
      id_estado: "26",
      nm_cidade: "Timburi",
      nm_cidade_uf: "Timburi - SP"
  },
  {
      id_cidade: "5318",
      id_estado: "26",
      nm_cidade: "Torre de Pedra",
      nm_cidade_uf: "Torre de Pedra - SP"
  },
  {
      id_cidade: "5319",
      id_estado: "26",
      nm_cidade: "Torrinha",
      nm_cidade_uf: "Torrinha - SP"
  },
  {
      id_cidade: "5320",
      id_estado: "26",
      nm_cidade: "Trabiju",
      nm_cidade_uf: "Trabiju - SP"
  },
  {
      id_cidade: "5321",
      id_estado: "26",
      nm_cidade: "Tremembé",
      nm_cidade_uf: "Tremembé - SP"
  },
  {
      id_cidade: "5322",
      id_estado: "26",
      nm_cidade: "Três Fronteiras",
      nm_cidade_uf: "Três Fronteiras - SP"
  },
  {
      id_cidade: "5323",
      id_estado: "26",
      nm_cidade: "Tuiuti",
      nm_cidade_uf: "Tuiuti - SP"
  },
  {
      id_cidade: "5324",
      id_estado: "26",
      nm_cidade: "Tupã",
      nm_cidade_uf: "Tupã - SP"
  },
  {
      id_cidade: "5325",
      id_estado: "26",
      nm_cidade: "Tupi Paulista",
      nm_cidade_uf: "Tupi Paulista - SP"
  },
  {
      id_cidade: "5326",
      id_estado: "26",
      nm_cidade: "Turiúba",
      nm_cidade_uf: "Turiúba - SP"
  },
  {
      id_cidade: "5327",
      id_estado: "26",
      nm_cidade: "Turmalina",
      nm_cidade_uf: "Turmalina - SP"
  },
  {
      id_cidade: "5328",
      id_estado: "26",
      nm_cidade: "Ubarana",
      nm_cidade_uf: "Ubarana - SP"
  },
  {
      id_cidade: "5329",
      id_estado: "26",
      nm_cidade: "Ubatuba",
      nm_cidade_uf: "Ubatuba - SP"
  },
  {
      id_cidade: "5330",
      id_estado: "26",
      nm_cidade: "Ubirajara",
      nm_cidade_uf: "Ubirajara - SP"
  },
  {
      id_cidade: "5331",
      id_estado: "26",
      nm_cidade: "Uchoa",
      nm_cidade_uf: "Uchoa - SP"
  },
  {
      id_cidade: "5332",
      id_estado: "26",
      nm_cidade: "União Paulista",
      nm_cidade_uf: "União Paulista - SP"
  },
  {
      id_cidade: "5333",
      id_estado: "26",
      nm_cidade: "Urânia",
      nm_cidade_uf: "Urânia - SP"
  },
  {
      id_cidade: "5334",
      id_estado: "26",
      nm_cidade: "Uru",
      nm_cidade_uf: "Uru - SP"
  },
  {
      id_cidade: "5335",
      id_estado: "26",
      nm_cidade: "Urupês",
      nm_cidade_uf: "Urupês - SP"
  },
  {
      id_cidade: "5336",
      id_estado: "26",
      nm_cidade: "Valentim Gentil",
      nm_cidade_uf: "Valentim Gentil - SP"
  },
  {
      id_cidade: "5337",
      id_estado: "26",
      nm_cidade: "Valinhos",
      nm_cidade_uf: "Valinhos - SP"
  },
  {
      id_cidade: "5338",
      id_estado: "26",
      nm_cidade: "Valparaíso",
      nm_cidade_uf: "Valparaíso - SP"
  },
  {
      id_cidade: "5339",
      id_estado: "26",
      nm_cidade: "Vargem",
      nm_cidade_uf: "Vargem - SP"
  },
  {
      id_cidade: "5340",
      id_estado: "26",
      nm_cidade: "Vargem Grande do Sul",
      nm_cidade_uf: "Vargem Grande do Sul - SP"
  },
  {
      id_cidade: "5341",
      id_estado: "26",
      nm_cidade: "Vargem Grande Paulista",
      nm_cidade_uf: "Vargem Grande Paulista - SP"
  },
  {
      id_cidade: "5342",
      id_estado: "26",
      nm_cidade: "Várzea Paulista",
      nm_cidade_uf: "Várzea Paulista - SP"
  },
  {
      id_cidade: "5343",
      id_estado: "26",
      nm_cidade: "Vera Cruz",
      nm_cidade_uf: "Vera Cruz - SP"
  },
  {
      id_cidade: "5344",
      id_estado: "26",
      nm_cidade: "Vinhedo",
      nm_cidade_uf: "Vinhedo - SP"
  },
  {
      id_cidade: "5345",
      id_estado: "26",
      nm_cidade: "Viradouro",
      nm_cidade_uf: "Viradouro - SP"
  },
  {
      id_cidade: "5346",
      id_estado: "26",
      nm_cidade: "Vista Alegre do Alto",
      nm_cidade_uf: "Vista Alegre do Alto - SP"
  },
  {
      id_cidade: "5347",
      id_estado: "26",
      nm_cidade: "Vitória Brasil",
      nm_cidade_uf: "Vitória Brasil - SP"
  },
  {
      id_cidade: "5348",
      id_estado: "26",
      nm_cidade: "Votorantim",
      nm_cidade_uf: "Votorantim - SP"
  },
  {
      id_cidade: "5349",
      id_estado: "26",
      nm_cidade: "Votuporanga",
      nm_cidade_uf: "Votuporanga - SP"
  },
  {
      id_cidade: "5350",
      id_estado: "26",
      nm_cidade: "Zacarias",
      nm_cidade_uf: "Zacarias - SP"
  },
  {
      id_cidade: "5351",
      id_estado: "25",
      nm_cidade: "Amparo de São Francisco",
      nm_cidade_uf: "Amparo de São Francisco - SE"
  },
  {
      id_cidade: "5352",
      id_estado: "25",
      nm_cidade: "Aquidabã",
      nm_cidade_uf: "Aquidabã - SE"
  },
  {
      id_cidade: "5353",
      id_estado: "25",
      nm_cidade: "Aracaju",
      nm_cidade_uf: "Aracaju - SE"
  },
  {
      id_cidade: "5354",
      id_estado: "25",
      nm_cidade: "Arauá",
      nm_cidade_uf: "Arauá - SE"
  },
  {
      id_cidade: "5355",
      id_estado: "25",
      nm_cidade: "Areia Branca",
      nm_cidade_uf: "Areia Branca - SE"
  },
  {
      id_cidade: "5356",
      id_estado: "25",
      nm_cidade: "Barra dos Coqueiros",
      nm_cidade_uf: "Barra dos Coqueiros - SE"
  },
  {
      id_cidade: "5357",
      id_estado: "25",
      nm_cidade: "Boquim",
      nm_cidade_uf: "Boquim - SE"
  },
  {
      id_cidade: "5358",
      id_estado: "25",
      nm_cidade: "Brejo Grande",
      nm_cidade_uf: "Brejo Grande - SE"
  },
  {
      id_cidade: "5359",
      id_estado: "25",
      nm_cidade: "Campo do Brito",
      nm_cidade_uf: "Campo do Brito - SE"
  },
  {
      id_cidade: "5360",
      id_estado: "25",
      nm_cidade: "Canhoba",
      nm_cidade_uf: "Canhoba - SE"
  },
  {
      id_cidade: "5361",
      id_estado: "25",
      nm_cidade: "Canindé de São Francisco",
      nm_cidade_uf: "Canindé de São Francisco - SE"
  },
  {
      id_cidade: "5362",
      id_estado: "25",
      nm_cidade: "Capela",
      nm_cidade_uf: "Capela - SE"
  },
  {
      id_cidade: "5363",
      id_estado: "25",
      nm_cidade: "Carira",
      nm_cidade_uf: "Carira - SE"
  },
  {
      id_cidade: "5364",
      id_estado: "25",
      nm_cidade: "Carmópolis",
      nm_cidade_uf: "Carmópolis - SE"
  },
  {
      id_cidade: "5365",
      id_estado: "25",
      nm_cidade: "Cedro de São João",
      nm_cidade_uf: "Cedro de São João - SE"
  },
  {
      id_cidade: "5366",
      id_estado: "25",
      nm_cidade: "Cristinápolis",
      nm_cidade_uf: "Cristinápolis - SE"
  },
  {
      id_cidade: "5367",
      id_estado: "25",
      nm_cidade: "Cumbe",
      nm_cidade_uf: "Cumbe - SE"
  },
  {
      id_cidade: "5368",
      id_estado: "25",
      nm_cidade: "Divina Pastora",
      nm_cidade_uf: "Divina Pastora - SE"
  },
  {
      id_cidade: "5369",
      id_estado: "25",
      nm_cidade: "Estância",
      nm_cidade_uf: "Estância - SE"
  },
  {
      id_cidade: "5370",
      id_estado: "25",
      nm_cidade: "Feira Nova",
      nm_cidade_uf: "Feira Nova - SE"
  },
  {
      id_cidade: "5371",
      id_estado: "25",
      nm_cidade: "Frei Paulo",
      nm_cidade_uf: "Frei Paulo - SE"
  },
  {
      id_cidade: "5372",
      id_estado: "25",
      nm_cidade: "Gararu",
      nm_cidade_uf: "Gararu - SE"
  },
  {
      id_cidade: "5373",
      id_estado: "25",
      nm_cidade: "General Maynard",
      nm_cidade_uf: "General Maynard - SE"
  },
  {
      id_cidade: "5374",
      id_estado: "25",
      nm_cidade: "Gracho Cardoso",
      nm_cidade_uf: "Gracho Cardoso - SE"
  },
  {
      id_cidade: "5375",
      id_estado: "25",
      nm_cidade: "Ilha das Flores",
      nm_cidade_uf: "Ilha das Flores - SE"
  },
  {
      id_cidade: "5376",
      id_estado: "25",
      nm_cidade: "Indiaroba",
      nm_cidade_uf: "Indiaroba - SE"
  },
  {
      id_cidade: "5377",
      id_estado: "25",
      nm_cidade: "Itabaiana",
      nm_cidade_uf: "Itabaiana - SE"
  },
  {
      id_cidade: "5378",
      id_estado: "25",
      nm_cidade: "Itabaianinha",
      nm_cidade_uf: "Itabaianinha - SE"
  },
  {
      id_cidade: "5379",
      id_estado: "25",
      nm_cidade: "Itabi",
      nm_cidade_uf: "Itabi - SE"
  },
  {
      id_cidade: "5380",
      id_estado: "25",
      nm_cidade: "Itaporanga d`Ajuda",
      nm_cidade_uf: "Itaporanga d`Ajuda - SE"
  },
  {
      id_cidade: "5381",
      id_estado: "25",
      nm_cidade: "Japaratuba",
      nm_cidade_uf: "Japaratuba - SE"
  },
  {
      id_cidade: "5382",
      id_estado: "25",
      nm_cidade: "Japoatã",
      nm_cidade_uf: "Japoatã - SE"
  },
  {
      id_cidade: "5383",
      id_estado: "25",
      nm_cidade: "Lagarto",
      nm_cidade_uf: "Lagarto - SE"
  },
  {
      id_cidade: "5384",
      id_estado: "25",
      nm_cidade: "Laranjeiras",
      nm_cidade_uf: "Laranjeiras - SE"
  },
  {
      id_cidade: "5385",
      id_estado: "25",
      nm_cidade: "Macambira",
      nm_cidade_uf: "Macambira - SE"
  },
  {
      id_cidade: "5386",
      id_estado: "25",
      nm_cidade: "Malhada dos Bois",
      nm_cidade_uf: "Malhada dos Bois - SE"
  },
  {
      id_cidade: "5387",
      id_estado: "25",
      nm_cidade: "Malhador",
      nm_cidade_uf: "Malhador - SE"
  },
  {
      id_cidade: "5388",
      id_estado: "25",
      nm_cidade: "Maruim",
      nm_cidade_uf: "Maruim - SE"
  },
  {
      id_cidade: "5389",
      id_estado: "25",
      nm_cidade: "Moita Bonita",
      nm_cidade_uf: "Moita Bonita - SE"
  },
  {
      id_cidade: "5390",
      id_estado: "25",
      nm_cidade: "Monte Alegre de Sergipe",
      nm_cidade_uf: "Monte Alegre de Sergipe - SE"
  },
  {
      id_cidade: "5391",
      id_estado: "25",
      nm_cidade: "Muribeca",
      nm_cidade_uf: "Muribeca - SE"
  },
  {
      id_cidade: "5392",
      id_estado: "25",
      nm_cidade: "Neópolis",
      nm_cidade_uf: "Neópolis - SE"
  },
  {
      id_cidade: "5393",
      id_estado: "25",
      nm_cidade: "Nossa Senhora Aparecida",
      nm_cidade_uf: "Nossa Senhora Aparecida - SE"
  },
  {
      id_cidade: "5394",
      id_estado: "25",
      nm_cidade: "Nossa Senhora da Glória",
      nm_cidade_uf: "Nossa Senhora da Glória - SE"
  },
  {
      id_cidade: "5395",
      id_estado: "25",
      nm_cidade: "Nossa Senhora das Dores",
      nm_cidade_uf: "Nossa Senhora das Dores - SE"
  },
  {
      id_cidade: "5396",
      id_estado: "25",
      nm_cidade: "Nossa Senhora de Lourdes",
      nm_cidade_uf: "Nossa Senhora de Lourdes - SE"
  },
  {
      id_cidade: "5397",
      id_estado: "25",
      nm_cidade: "Nossa Senhora do Socorro",
      nm_cidade_uf: "Nossa Senhora do Socorro - SE"
  },
  {
      id_cidade: "5398",
      id_estado: "25",
      nm_cidade: "Pacatuba",
      nm_cidade_uf: "Pacatuba - SE"
  },
  {
      id_cidade: "5399",
      id_estado: "25",
      nm_cidade: "Pedra Mole",
      nm_cidade_uf: "Pedra Mole - SE"
  },
  {
      id_cidade: "5400",
      id_estado: "25",
      nm_cidade: "Pedrinhas",
      nm_cidade_uf: "Pedrinhas - SE"
  },
  {
      id_cidade: "5401",
      id_estado: "25",
      nm_cidade: "Pinhão",
      nm_cidade_uf: "Pinhão - SE"
  },
  {
      id_cidade: "5402",
      id_estado: "25",
      nm_cidade: "Pirambu",
      nm_cidade_uf: "Pirambu - SE"
  },
  {
      id_cidade: "5403",
      id_estado: "25",
      nm_cidade: "Poço Redondo",
      nm_cidade_uf: "Poço Redondo - SE"
  },
  {
      id_cidade: "5404",
      id_estado: "25",
      nm_cidade: "Poço Verde",
      nm_cidade_uf: "Poço Verde - SE"
  },
  {
      id_cidade: "5405",
      id_estado: "25",
      nm_cidade: "Porto da Folha",
      nm_cidade_uf: "Porto da Folha - SE"
  },
  {
      id_cidade: "5406",
      id_estado: "25",
      nm_cidade: "Propriá",
      nm_cidade_uf: "Propriá - SE"
  },
  {
      id_cidade: "5407",
      id_estado: "25",
      nm_cidade: "Riachão do Dantas",
      nm_cidade_uf: "Riachão do Dantas - SE"
  },
  {
      id_cidade: "5408",
      id_estado: "25",
      nm_cidade: "Riachuelo",
      nm_cidade_uf: "Riachuelo - SE"
  },
  {
      id_cidade: "5409",
      id_estado: "25",
      nm_cidade: "Ribeirópolis",
      nm_cidade_uf: "Ribeirópolis - SE"
  },
  {
      id_cidade: "5410",
      id_estado: "25",
      nm_cidade: "Rosário do Catete",
      nm_cidade_uf: "Rosário do Catete - SE"
  },
  {
      id_cidade: "5411",
      id_estado: "25",
      nm_cidade: "Salgado",
      nm_cidade_uf: "Salgado - SE"
  },
  {
      id_cidade: "5412",
      id_estado: "25",
      nm_cidade: "Santa Luzia do Itanhy",
      nm_cidade_uf: "Santa Luzia do Itanhy - SE"
  },
  {
      id_cidade: "5413",
      id_estado: "25",
      nm_cidade: "Santa Rosa de Lima",
      nm_cidade_uf: "Santa Rosa de Lima - SE"
  },
  {
      id_cidade: "5414",
      id_estado: "25",
      nm_cidade: "Santana do São Francisco",
      nm_cidade_uf: "Santana do São Francisco - SE"
  },
  {
      id_cidade: "5415",
      id_estado: "25",
      nm_cidade: "Santo Amaro das Brotas",
      nm_cidade_uf: "Santo Amaro das Brotas - SE"
  },
  {
      id_cidade: "5416",
      id_estado: "25",
      nm_cidade: "São Cristóvão",
      nm_cidade_uf: "São Cristóvão - SE"
  },
  {
      id_cidade: "5417",
      id_estado: "25",
      nm_cidade: "São Domingos",
      nm_cidade_uf: "São Domingos - SE"
  },
  {
      id_cidade: "5418",
      id_estado: "25",
      nm_cidade: "São Francisco",
      nm_cidade_uf: "São Francisco - SE"
  },
  {
      id_cidade: "5419",
      id_estado: "25",
      nm_cidade: "São Miguel do Aleixo",
      nm_cidade_uf: "São Miguel do Aleixo - SE"
  },
  {
      id_cidade: "5420",
      id_estado: "25",
      nm_cidade: "Simão Dias",
      nm_cidade_uf: "Simão Dias - SE"
  },
  {
      id_cidade: "5421",
      id_estado: "25",
      nm_cidade: "Siriri",
      nm_cidade_uf: "Siriri - SE"
  },
  {
      id_cidade: "5422",
      id_estado: "25",
      nm_cidade: "Telha",
      nm_cidade_uf: "Telha - SE"
  },
  {
      id_cidade: "5423",
      id_estado: "25",
      nm_cidade: "Tobias Barreto",
      nm_cidade_uf: "Tobias Barreto - SE"
  },
  {
      id_cidade: "5424",
      id_estado: "25",
      nm_cidade: "Tomar do Geru",
      nm_cidade_uf: "Tomar do Geru - SE"
  },
  {
      id_cidade: "5425",
      id_estado: "25",
      nm_cidade: "Umbaúba",
      nm_cidade_uf: "Umbaúba - SE"
  },
  {
      id_cidade: "5426",
      id_estado: "27",
      nm_cidade: "Abreulândia",
      nm_cidade_uf: "Abreulândia - TO"
  },
  {
      id_cidade: "5427",
      id_estado: "27",
      nm_cidade: "Aguiarnópolis",
      nm_cidade_uf: "Aguiarnópolis - TO"
  },
  {
      id_cidade: "5428",
      id_estado: "27",
      nm_cidade: "Aliança do Tocantins",
      nm_cidade_uf: "Aliança do Tocantins - TO"
  },
  {
      id_cidade: "5429",
      id_estado: "27",
      nm_cidade: "Almas",
      nm_cidade_uf: "Almas - TO"
  },
  {
      id_cidade: "5430",
      id_estado: "27",
      nm_cidade: "Alvorada",
      nm_cidade_uf: "Alvorada - TO"
  },
  {
      id_cidade: "5431",
      id_estado: "27",
      nm_cidade: "Ananás",
      nm_cidade_uf: "Ananás - TO"
  },
  {
      id_cidade: "5432",
      id_estado: "27",
      nm_cidade: "Angico",
      nm_cidade_uf: "Angico - TO"
  },
  {
      id_cidade: "5433",
      id_estado: "27",
      nm_cidade: "Aparecida do Rio Negro",
      nm_cidade_uf: "Aparecida do Rio Negro - TO"
  },
  {
      id_cidade: "5434",
      id_estado: "27",
      nm_cidade: "Aragominas",
      nm_cidade_uf: "Aragominas - TO"
  },
  {
      id_cidade: "5435",
      id_estado: "27",
      nm_cidade: "Araguacema",
      nm_cidade_uf: "Araguacema - TO"
  },
  {
      id_cidade: "5436",
      id_estado: "27",
      nm_cidade: "Araguaçu",
      nm_cidade_uf: "Araguaçu - TO"
  },
  {
      id_cidade: "5437",
      id_estado: "27",
      nm_cidade: "Araguaína",
      nm_cidade_uf: "Araguaína - TO"
  },
  {
      id_cidade: "5438",
      id_estado: "27",
      nm_cidade: "Araguanã",
      nm_cidade_uf: "Araguanã - TO"
  },
  {
      id_cidade: "5439",
      id_estado: "27",
      nm_cidade: "Araguatins",
      nm_cidade_uf: "Araguatins - TO"
  },
  {
      id_cidade: "5440",
      id_estado: "27",
      nm_cidade: "Arapoema",
      nm_cidade_uf: "Arapoema - TO"
  },
  {
      id_cidade: "5441",
      id_estado: "27",
      nm_cidade: "Arraias",
      nm_cidade_uf: "Arraias - TO"
  },
  {
      id_cidade: "5442",
      id_estado: "27",
      nm_cidade: "Augustinópolis",
      nm_cidade_uf: "Augustinópolis - TO"
  },
  {
      id_cidade: "5443",
      id_estado: "27",
      nm_cidade: "Aurora do Tocantins",
      nm_cidade_uf: "Aurora do Tocantins - TO"
  },
  {
      id_cidade: "5444",
      id_estado: "27",
      nm_cidade: "Axixá do Tocantins",
      nm_cidade_uf: "Axixá do Tocantins - TO"
  },
  {
      id_cidade: "5445",
      id_estado: "27",
      nm_cidade: "Babaçulândia",
      nm_cidade_uf: "Babaçulândia - TO"
  },
  {
      id_cidade: "5446",
      id_estado: "27",
      nm_cidade: "Bandeirantes do Tocantins",
      nm_cidade_uf: "Bandeirantes do Tocantins - TO"
  },
  {
      id_cidade: "5447",
      id_estado: "27",
      nm_cidade: "Barra do Ouro",
      nm_cidade_uf: "Barra do Ouro - TO"
  },
  {
      id_cidade: "5448",
      id_estado: "27",
      nm_cidade: "Barrolândia",
      nm_cidade_uf: "Barrolândia - TO"
  },
  {
      id_cidade: "5449",
      id_estado: "27",
      nm_cidade: "Bernardo Sayão",
      nm_cidade_uf: "Bernardo Sayão - TO"
  },
  {
      id_cidade: "5450",
      id_estado: "27",
      nm_cidade: "Bom Jesus do Tocantins",
      nm_cidade_uf: "Bom Jesus do Tocantins - TO"
  },
  {
      id_cidade: "5451",
      id_estado: "27",
      nm_cidade: "Brasilândia do Tocantins",
      nm_cidade_uf: "Brasilândia do Tocantins - TO"
  },
  {
      id_cidade: "5452",
      id_estado: "27",
      nm_cidade: "Brejinho de Nazaré",
      nm_cidade_uf: "Brejinho de Nazaré - TO"
  },
  {
      id_cidade: "5453",
      id_estado: "27",
      nm_cidade: "Buriti do Tocantins",
      nm_cidade_uf: "Buriti do Tocantins - TO"
  },
  {
      id_cidade: "5454",
      id_estado: "27",
      nm_cidade: "Cachoeirinha",
      nm_cidade_uf: "Cachoeirinha - TO"
  },
  {
      id_cidade: "5455",
      id_estado: "27",
      nm_cidade: "Campos Lindos",
      nm_cidade_uf: "Campos Lindos - TO"
  },
  {
      id_cidade: "5456",
      id_estado: "27",
      nm_cidade: "Cariri do Tocantins",
      nm_cidade_uf: "Cariri do Tocantins - TO"
  },
  {
      id_cidade: "5457",
      id_estado: "27",
      nm_cidade: "Carmolândia",
      nm_cidade_uf: "Carmolândia - TO"
  },
  {
      id_cidade: "5458",
      id_estado: "27",
      nm_cidade: "Carrasco Bonito",
      nm_cidade_uf: "Carrasco Bonito - TO"
  },
  {
      id_cidade: "5459",
      id_estado: "27",
      nm_cidade: "Caseara",
      nm_cidade_uf: "Caseara - TO"
  },
  {
      id_cidade: "5460",
      id_estado: "27",
      nm_cidade: "Centenário",
      nm_cidade_uf: "Centenário - TO"
  },
  {
      id_cidade: "5461",
      id_estado: "27",
      nm_cidade: "Chapada da Natividade",
      nm_cidade_uf: "Chapada da Natividade - TO"
  },
  {
      id_cidade: "5462",
      id_estado: "27",
      nm_cidade: "Chapada de Areia",
      nm_cidade_uf: "Chapada de Areia - TO"
  },
  {
      id_cidade: "5463",
      id_estado: "27",
      nm_cidade: "Colinas do Tocantins",
      nm_cidade_uf: "Colinas do Tocantins - TO"
  },
  {
      id_cidade: "5464",
      id_estado: "27",
      nm_cidade: "Colméia",
      nm_cidade_uf: "Colméia - TO"
  },
  {
      id_cidade: "5465",
      id_estado: "27",
      nm_cidade: "Combinado",
      nm_cidade_uf: "Combinado - TO"
  },
  {
      id_cidade: "5466",
      id_estado: "27",
      nm_cidade: "Conceição do Tocantins",
      nm_cidade_uf: "Conceição do Tocantins - TO"
  },
  {
      id_cidade: "5467",
      id_estado: "27",
      nm_cidade: "Couto de Magalhães",
      nm_cidade_uf: "Couto de Magalhães - TO"
  },
  {
      id_cidade: "5468",
      id_estado: "27",
      nm_cidade: "Cristalândia",
      nm_cidade_uf: "Cristalândia - TO"
  },
  {
      id_cidade: "5469",
      id_estado: "27",
      nm_cidade: "Crixás do Tocantins",
      nm_cidade_uf: "Crixás do Tocantins - TO"
  },
  {
      id_cidade: "5470",
      id_estado: "27",
      nm_cidade: "Darcinópolis",
      nm_cidade_uf: "Darcinópolis - TO"
  },
  {
      id_cidade: "5471",
      id_estado: "27",
      nm_cidade: "Dianópolis",
      nm_cidade_uf: "Dianópolis - TO"
  },
  {
      id_cidade: "5472",
      id_estado: "27",
      nm_cidade: "Divinópolis do Tocantins",
      nm_cidade_uf: "Divinópolis do Tocantins - TO"
  },
  {
      id_cidade: "5473",
      id_estado: "27",
      nm_cidade: "Dois Irmãos do Tocantins",
      nm_cidade_uf: "Dois Irmãos do Tocantins - TO"
  },
  {
      id_cidade: "5474",
      id_estado: "27",
      nm_cidade: "Dueré",
      nm_cidade_uf: "Dueré - TO"
  },
  {
      id_cidade: "5475",
      id_estado: "27",
      nm_cidade: "Esperantina",
      nm_cidade_uf: "Esperantina - TO"
  },
  {
      id_cidade: "5476",
      id_estado: "27",
      nm_cidade: "Fátima",
      nm_cidade_uf: "Fátima - TO"
  },
  {
      id_cidade: "5477",
      id_estado: "27",
      nm_cidade: "Figueirópolis",
      nm_cidade_uf: "Figueirópolis - TO"
  },
  {
      id_cidade: "5478",
      id_estado: "27",
      nm_cidade: "Filadélfia",
      nm_cidade_uf: "Filadélfia - TO"
  },
  {
      id_cidade: "5479",
      id_estado: "27",
      nm_cidade: "Formoso do Araguaia",
      nm_cidade_uf: "Formoso do Araguaia - TO"
  },
  {
      id_cidade: "5480",
      id_estado: "27",
      nm_cidade: "Fortaleza do Tabocão",
      nm_cidade_uf: "Fortaleza do Tabocão - TO"
  },
  {
      id_cidade: "5481",
      id_estado: "27",
      nm_cidade: "Goianorte",
      nm_cidade_uf: "Goianorte - TO"
  },
  {
      id_cidade: "5482",
      id_estado: "27",
      nm_cidade: "Goiatins",
      nm_cidade_uf: "Goiatins - TO"
  },
  {
      id_cidade: "5483",
      id_estado: "27",
      nm_cidade: "Guaraí",
      nm_cidade_uf: "Guaraí - TO"
  },
  {
      id_cidade: "5484",
      id_estado: "27",
      nm_cidade: "Gurupi",
      nm_cidade_uf: "Gurupi - TO"
  },
  {
      id_cidade: "5485",
      id_estado: "27",
      nm_cidade: "Ipueiras",
      nm_cidade_uf: "Ipueiras - TO"
  },
  {
      id_cidade: "5486",
      id_estado: "27",
      nm_cidade: "Itacajá",
      nm_cidade_uf: "Itacajá - TO"
  },
  {
      id_cidade: "5487",
      id_estado: "27",
      nm_cidade: "Itaguatins",
      nm_cidade_uf: "Itaguatins - TO"
  },
  {
      id_cidade: "5488",
      id_estado: "27",
      nm_cidade: "Itapiratins",
      nm_cidade_uf: "Itapiratins - TO"
  },
  {
      id_cidade: "5489",
      id_estado: "27",
      nm_cidade: "Itaporã do Tocantins",
      nm_cidade_uf: "Itaporã do Tocantins - TO"
  },
  {
      id_cidade: "5490",
      id_estado: "27",
      nm_cidade: "Jaú do Tocantins",
      nm_cidade_uf: "Jaú do Tocantins - TO"
  },
  {
      id_cidade: "5491",
      id_estado: "27",
      nm_cidade: "Juarina",
      nm_cidade_uf: "Juarina - TO"
  },
  {
      id_cidade: "5492",
      id_estado: "27",
      nm_cidade: "Lagoa da Confusão",
      nm_cidade_uf: "Lagoa da Confusão - TO"
  },
  {
      id_cidade: "5493",
      id_estado: "27",
      nm_cidade: "Lagoa do Tocantins",
      nm_cidade_uf: "Lagoa do Tocantins - TO"
  },
  {
      id_cidade: "5494",
      id_estado: "27",
      nm_cidade: "Lajeado",
      nm_cidade_uf: "Lajeado - TO"
  },
  {
      id_cidade: "5495",
      id_estado: "27",
      nm_cidade: "Lavandeira",
      nm_cidade_uf: "Lavandeira - TO"
  },
  {
      id_cidade: "5496",
      id_estado: "27",
      nm_cidade: "Lizarda",
      nm_cidade_uf: "Lizarda - TO"
  },
  {
      id_cidade: "5497",
      id_estado: "27",
      nm_cidade: "Luzinópolis",
      nm_cidade_uf: "Luzinópolis - TO"
  },
  {
      id_cidade: "5498",
      id_estado: "27",
      nm_cidade: "Marianópolis do Tocantins",
      nm_cidade_uf: "Marianópolis do Tocantins - TO"
  },
  {
      id_cidade: "5499",
      id_estado: "27",
      nm_cidade: "Mateiros",
      nm_cidade_uf: "Mateiros - TO"
  },
  {
      id_cidade: "5500",
      id_estado: "27",
      nm_cidade: "Maurilândia do Tocantins",
      nm_cidade_uf: "Maurilândia do Tocantins - TO"
  },
  {
      id_cidade: "5501",
      id_estado: "27",
      nm_cidade: "Miracema do Tocantins",
      nm_cidade_uf: "Miracema do Tocantins - TO"
  },
  {
      id_cidade: "5502",
      id_estado: "27",
      nm_cidade: "Miranorte",
      nm_cidade_uf: "Miranorte - TO"
  },
  {
      id_cidade: "5503",
      id_estado: "27",
      nm_cidade: "Monte do Carmo",
      nm_cidade_uf: "Monte do Carmo - TO"
  },
  {
      id_cidade: "5504",
      id_estado: "27",
      nm_cidade: "Monte Santo do Tocantins",
      nm_cidade_uf: "Monte Santo do Tocantins - TO"
  },
  {
      id_cidade: "5505",
      id_estado: "27",
      nm_cidade: "Muricilândia",
      nm_cidade_uf: "Muricilândia - TO"
  },
  {
      id_cidade: "5506",
      id_estado: "27",
      nm_cidade: "Natividade",
      nm_cidade_uf: "Natividade - TO"
  },
  {
      id_cidade: "5507",
      id_estado: "27",
      nm_cidade: "Nazaré",
      nm_cidade_uf: "Nazaré - TO"
  },
  {
      id_cidade: "5508",
      id_estado: "27",
      nm_cidade: "Nova Olinda",
      nm_cidade_uf: "Nova Olinda - TO"
  },
  {
      id_cidade: "5509",
      id_estado: "27",
      nm_cidade: "Nova Rosalândia",
      nm_cidade_uf: "Nova Rosalândia - TO"
  },
  {
      id_cidade: "5510",
      id_estado: "27",
      nm_cidade: "Novo Acordo",
      nm_cidade_uf: "Novo Acordo - TO"
  },
  {
      id_cidade: "5511",
      id_estado: "27",
      nm_cidade: "Novo Alegre",
      nm_cidade_uf: "Novo Alegre - TO"
  },
  {
      id_cidade: "5512",
      id_estado: "27",
      nm_cidade: "Novo Jardim",
      nm_cidade_uf: "Novo Jardim - TO"
  },
  {
      id_cidade: "5513",
      id_estado: "27",
      nm_cidade: "Oliveira de Fátima",
      nm_cidade_uf: "Oliveira de Fátima - TO"
  },
  {
      id_cidade: "5514",
      id_estado: "27",
      nm_cidade: "Palmas",
      nm_cidade_uf: "Palmas - TO"
  },
  {
      id_cidade: "5515",
      id_estado: "27",
      nm_cidade: "Palmeirante",
      nm_cidade_uf: "Palmeirante - TO"
  },
  {
      id_cidade: "5516",
      id_estado: "27",
      nm_cidade: "Palmeiras do Tocantins",
      nm_cidade_uf: "Palmeiras do Tocantins - TO"
  },
  {
      id_cidade: "5517",
      id_estado: "27",
      nm_cidade: "Palmeirópolis",
      nm_cidade_uf: "Palmeirópolis - TO"
  },
  {
      id_cidade: "5518",
      id_estado: "27",
      nm_cidade: "Paraíso do Tocantins",
      nm_cidade_uf: "Paraíso do Tocantins - TO"
  },
  {
      id_cidade: "5519",
      id_estado: "27",
      nm_cidade: "Paranã",
      nm_cidade_uf: "Paranã - TO"
  },
  {
      id_cidade: "5520",
      id_estado: "27",
      nm_cidade: "Pau d`Arco",
      nm_cidade_uf: "Pau d`Arco - TO"
  },
  {
      id_cidade: "5521",
      id_estado: "27",
      nm_cidade: "Pedro Afonso",
      nm_cidade_uf: "Pedro Afonso - TO"
  },
  {
      id_cidade: "5522",
      id_estado: "27",
      nm_cidade: "Peixe",
      nm_cidade_uf: "Peixe - TO"
  },
  {
      id_cidade: "5523",
      id_estado: "27",
      nm_cidade: "Pequizeiro",
      nm_cidade_uf: "Pequizeiro - TO"
  },
  {
      id_cidade: "5524",
      id_estado: "27",
      nm_cidade: "Pindorama do Tocantins",
      nm_cidade_uf: "Pindorama do Tocantins - TO"
  },
  {
      id_cidade: "5525",
      id_estado: "27",
      nm_cidade: "Piraquê",
      nm_cidade_uf: "Piraquê - TO"
  },
  {
      id_cidade: "5526",
      id_estado: "27",
      nm_cidade: "Pium",
      nm_cidade_uf: "Pium - TO"
  },
  {
      id_cidade: "5527",
      id_estado: "27",
      nm_cidade: "Ponte Alta do Bom Jesus",
      nm_cidade_uf: "Ponte Alta do Bom Jesus - TO"
  },
  {
      id_cidade: "5528",
      id_estado: "27",
      nm_cidade: "Ponte Alta do Tocantins",
      nm_cidade_uf: "Ponte Alta do Tocantins - TO"
  },
  {
      id_cidade: "5529",
      id_estado: "27",
      nm_cidade: "Porto Alegre do Tocantins",
      nm_cidade_uf: "Porto Alegre do Tocantins - TO"
  },
  {
      id_cidade: "5530",
      id_estado: "27",
      nm_cidade: "Porto Nacional",
      nm_cidade_uf: "Porto Nacional - TO"
  },
  {
      id_cidade: "5531",
      id_estado: "27",
      nm_cidade: "Praia Norte",
      nm_cidade_uf: "Praia Norte - TO"
  },
  {
      id_cidade: "5532",
      id_estado: "27",
      nm_cidade: "Presidente Kennedy",
      nm_cidade_uf: "Presidente Kennedy - TO"
  },
  {
      id_cidade: "5533",
      id_estado: "27",
      nm_cidade: "Pugmil",
      nm_cidade_uf: "Pugmil - TO"
  },
  {
      id_cidade: "5534",
      id_estado: "27",
      nm_cidade: "Recursolândia",
      nm_cidade_uf: "Recursolândia - TO"
  },
  {
      id_cidade: "5535",
      id_estado: "27",
      nm_cidade: "Riachinho",
      nm_cidade_uf: "Riachinho - TO"
  },
  {
      id_cidade: "5536",
      id_estado: "27",
      nm_cidade: "Rio da Conceição",
      nm_cidade_uf: "Rio da Conceição - TO"
  },
  {
      id_cidade: "5537",
      id_estado: "27",
      nm_cidade: "Rio dos Bois",
      nm_cidade_uf: "Rio dos Bois - TO"
  },
  {
      id_cidade: "5538",
      id_estado: "27",
      nm_cidade: "Rio Sono",
      nm_cidade_uf: "Rio Sono - TO"
  },
  {
      id_cidade: "5539",
      id_estado: "27",
      nm_cidade: "Sampaio",
      nm_cidade_uf: "Sampaio - TO"
  },
  {
      id_cidade: "5540",
      id_estado: "27",
      nm_cidade: "Sandolândia",
      nm_cidade_uf: "Sandolândia - TO"
  },
  {
      id_cidade: "5541",
      id_estado: "27",
      nm_cidade: "Santa Fé do Araguaia",
      nm_cidade_uf: "Santa Fé do Araguaia - TO"
  },
  {
      id_cidade: "5542",
      id_estado: "27",
      nm_cidade: "Santa Maria do Tocantins",
      nm_cidade_uf: "Santa Maria do Tocantins - TO"
  },
  {
      id_cidade: "5543",
      id_estado: "27",
      nm_cidade: "Santa Rita do Tocantins",
      nm_cidade_uf: "Santa Rita do Tocantins - TO"
  },
  {
      id_cidade: "5544",
      id_estado: "27",
      nm_cidade: "Santa Rosa do Tocantins",
      nm_cidade_uf: "Santa Rosa do Tocantins - TO"
  },
  {
      id_cidade: "5545",
      id_estado: "27",
      nm_cidade: "Santa Tereza do Tocantins",
      nm_cidade_uf: "Santa Tereza do Tocantins - TO"
  },
  {
      id_cidade: "5546",
      id_estado: "27",
      nm_cidade: "Santa Terezinha do Tocantins",
      nm_cidade_uf: "Santa Terezinha do Tocantins - TO"
  },
  {
      id_cidade: "5547",
      id_estado: "27",
      nm_cidade: "São Bento do Tocantins",
      nm_cidade_uf: "São Bento do Tocantins - TO"
  },
  {
      id_cidade: "5548",
      id_estado: "27",
      nm_cidade: "São Félix do Tocantins",
      nm_cidade_uf: "São Félix do Tocantins - TO"
  },
  {
      id_cidade: "5549",
      id_estado: "27",
      nm_cidade: "São Miguel do Tocantins",
      nm_cidade_uf: "São Miguel do Tocantins - TO"
  },
  {
      id_cidade: "5550",
      id_estado: "27",
      nm_cidade: "São Salvador do Tocantins",
      nm_cidade_uf: "São Salvador do Tocantins - TO"
  },
  {
      id_cidade: "5551",
      id_estado: "27",
      nm_cidade: "São Sebastião do Tocantins",
      nm_cidade_uf: "São Sebastião do Tocantins - TO"
  },
  {
      id_cidade: "5552",
      id_estado: "27",
      nm_cidade: "São Valério da Natividade",
      nm_cidade_uf: "São Valério da Natividade - TO"
  },
  {
      id_cidade: "5553",
      id_estado: "27",
      nm_cidade: "Silvanópolis",
      nm_cidade_uf: "Silvanópolis - TO"
  },
  {
      id_cidade: "5554",
      id_estado: "27",
      nm_cidade: "Sítio Novo do Tocantins",
      nm_cidade_uf: "Sítio Novo do Tocantins - TO"
  },
  {
      id_cidade: "5555",
      id_estado: "27",
      nm_cidade: "Sucupira",
      nm_cidade_uf: "Sucupira - TO"
  },
  {
      id_cidade: "5556",
      id_estado: "27",
      nm_cidade: "Taguatinga",
      nm_cidade_uf: "Taguatinga - TO"
  },
  {
      id_cidade: "5557",
      id_estado: "27",
      nm_cidade: "Taipas do Tocantins",
      nm_cidade_uf: "Taipas do Tocantins - TO"
  },
  {
      id_cidade: "5558",
      id_estado: "27",
      nm_cidade: "Talismã",
      nm_cidade_uf: "Talismã - TO"
  },
  {
      id_cidade: "5559",
      id_estado: "27",
      nm_cidade: "Tocantínia",
      nm_cidade_uf: "Tocantínia - TO"
  },
  {
      id_cidade: "5560",
      id_estado: "27",
      nm_cidade: "Tocantinópolis",
      nm_cidade_uf: "Tocantinópolis - TO"
  },
  {
      id_cidade: "5561",
      id_estado: "27",
      nm_cidade: "Tupirama",
      nm_cidade_uf: "Tupirama - TO"
  },
  {
      id_cidade: "5562",
      id_estado: "27",
      nm_cidade: "Tupiratins",
      nm_cidade_uf: "Tupiratins - TO"
  },
  {
      id_cidade: "5563",
      id_estado: "27",
      nm_cidade: "Wanderlândia",
      nm_cidade_uf: "Wanderlândia - TO"
  },
  {
      id_cidade: "5564",
      id_estado: "27",
      nm_cidade: "Xambioá",
      nm_cidade_uf: "Xambioá - TO"
  }
];
