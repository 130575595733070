<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom"
      id="navbar-main"
      :type="navbarType ? navbarType : ''"
      effect="dark"
      expand
    >
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/dashboard">
        <h3 class="display-3 mb-0 titulo-mj" style="color: white">MatchJob</h3>
      </router-link>

      <div class="row" slot="content-header" slot-scope="{ closeMenu }">
        <div class="col-6 collapse-brand">
          <a href="javascript:void(0)">
            <h3 class="display-3 mb-0">Matchjob</h3>
          </a>
        </div>
        <div class="col-6 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'vagas' }" class="nav-link">Vagas</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'curriculos' }" class="nav-link">Currículos</router-link>
        </li>
        <!-- Vagas -->
        <!-- <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <span class="nav-link-inner--text">Vagas</span>
          </a>
          <router-link :to="{ name: 'procurarvagas' }" class="dropdown-item">
            <font-awesome-icon icon="search" />
            Procurar Vagas
          </router-link>
          <router-link to="/sections#features" class="dropdown-item">
            <i class="ni ni-archive-2 text-primary"></i>
            Histórico de Candidatura
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link to="/sections#blogs" class="dropdown-item">
            <i class="ni ni-settings-gear-65 text-warning"></i>
            Configurar Avisos
          </router-link>
        </base-dropdown> -->
        <!-- Curriculos -->
        <!-- <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <span class="nav-link-inner--text">Currículos</span>
          </a>
          <router-link to="/curriculos" class="dropdown-item">
            <i class="ni ni-bullet-list-67 text-info"></i>
            Ver Currículos
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link to="/blog-post" class="dropdown-item">
            <i class="ni ni-diamond text-success"></i>
            Currículos <span class="badge badge-warning">PRO</span>
          </router-link>
        </base-dropdown> -->
        <!-- Menu de Notificações -->
        <!-- <base-dropdown
          class="nav-item"
          menu-classes="dropdown-menu-xl dropdown-menu-not"
        >
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <i class="ni ni-bell-55"></i>
            <span class="badge badge-default" v-if="qtdNotificacoes > 0">
              {{ qtdNotificacoes }}</span
            >
          </a>
          <template v-for="notificacao in notificacoesFB">
            <router-link
              :to="notificacao.link"
              class="media d-flex align-items-center"
              :key="notificacao.id"
            >
              <div class="dropdown-menu-inner">
                <div class="media-body ml-3">
                  <h5
                    :class="
                      `heading text-${classNotificacao(
                        notificacao.unread,
                        notificacao.color
                      )} mb-md-1`
                    "
                  >
                    <i :class="`ni ni-${notificacao.icon}`"></i>
                    {{ notificacao.title }}
                  </h5>
                  <p class="description d-none d-md-inline-block mb-0">
                    {{ notificacao.body }}
                  </p>
                </div>
              </div>
            </router-link>
          </template>
        </base-dropdown> -->
        <!-- Menu do Perfil do Candidato -->
        <base-dropdown class="nav-item">
          <a
            role="button"
            slot="title"
            href="#"
            class="nav-link"
            data-toggle="dropdown"
          >
            <img
              alt="Foto de Perfil"
              class="avatar avatar-sm rounded-circle"
              :src="headerFoto"
            />
            <span class="font-weight-bold nav-link-inner--text">
              {{ headerNome }}
            </span>
          </a>
          <router-link to="/perfil" class="dropdown-item">
            <i class="ni ni-single-02 text-primary"></i>
            Meu Perfil
          </router-link>
          <!-- <router-link to="/about" class="dropdown-item">
            <i class="ni ni-support-16 text-primary"></i>
            Ajuda
          </router-link> -->
          <div class="dropdown-divider"></div>
          <base-button class="dropdown-item" tag="li" @click="logout()">
            <i class="ni ni-user-run text-warning"></i>
            Sair
          </base-button>
        </base-dropdown>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import { userKey, cvAtual } from "@/global";
import { projectFirestore } from "../firebase/config";
//import axios from "axios";

import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import BaseDropdown from "@/components/BaseDropdown";
import Headroom from "headroom.js";

export default {
  name: "Header",
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  },
  props: {
    navbarType: String
  },
  data: function() {
    return {
      //c: {},
      infoHeader: [],
      notificacoesFB: []
    };
  },
  computed: {
    qtdNotificacoes() {
      return this.notificacoesFB.filter(element => element.unread).length;
    },
    headerNome() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      return userData.nm_nome;
    },
    headerFoto() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      return userData.hl_foto;
    }
  },
  methods: {
    classNotificacao(notificacaoLida, cor) {
      return notificacaoLida ? cor : "muted";
    },
    async getNotificacao() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      const db = projectFirestore
        .collection("candidatos")
        .doc(userData.hl_email);
      const res = await db.collection("notificacoes").get();
      this.notificacoesFB = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
    },
    logout() {
      //localStorage.removeItem(userKey);
      //localStorage.removeItem(cvAtual);
      sessionStorage.removeItem(userKey);
      sessionStorage.removeItem(cvAtual);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('session_end_datetime');
      this.$store.dispatch("logout", null);
      this.$session.destroy();
      this.$router.push({ name: "login" });
    }
  },
  mounted() {
    this.getNotificacao();
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
  }
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
.navbar {
  padding: 0.5rem !important;
}
.dropdown-menu-not {
  min-width: 350px !important;
}
.dropdown-menu-inner {
  padding: 0.5rem !important;
}
.media {
  margin-bottom: 0.5rem !important;
}
.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
</style>
