<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <CardDashboardSemTitulo>
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <h3 class="mt-1">Buscar Vagas</h3>
                  </div>
                </div>
                <div class="row justify-content-center mb-2">
                  <!-- Cargo -->
                  <div class="col-lg-5">
                    <div class="form-group">
                      <label for="cargo">Cargo Desejado <el-tooltip
                        placement="top"
                        effect="light"
                        :tabindex="-1"
                      ><div slot="content">Cargo ou posição que deseja se alocar<br/>Ex. Analista Administrativo</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                      <el-autocomplete
                        ref="cargo"
                        id="cargo"
                        class="inuptAutocomplete"
                        :maxlength="255"
                        v-model="procurar.cargo"
                        :fetch-suggestions="querySearchCargos"
                        placeholder="Cargo"
                        :trigger-on-focus="false"
                      ></el-autocomplete>
                    </div>
                  </div>
                  <!-- Local de Trabalho -->
                  <div class="col-lg-5">
                    <div class="form-group">
                      <label for="cidade">Local de Trabalho <el-tooltip :tabindex="-1" placement="top" effect="light"><div slot="content">Informe a cidade que você deseja trabalhar.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                      <el-autocomplete
                        ref="cidade"
                        id="cidade"
                        class="inuptAutocomplete"
                        :maxlength="255"
                        v-model="procurar.cidade"
                        :fetch-suggestions="querySearchCidade"
                        placeholder="Cidade"
                        :trigger-on-focus="false"
                      ></el-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <!-- Botão Procurar -->
                  <div class="col-lg-2 align-self-end">
                    <div class="form-group">
                      <base-button
                        type="primary"
                        @click="procurarVagas"
                        :disabled="validaCamposProcura()"
                        >Procurar</base-button
                      >
                    </div>
                  </div>
                </div>
              </CardDashboardSemTitulo>
            </div>
          </div>
          <!-- <div class="row">
            <CardBullets
              titulo="VAGAS"
              subtitulo="Total"
              valor="5.897"
              cor="orange"
              icone="ni-active-40"
            />
            <CardBullets
              titulo="VAGAS"
              subtitulo="Hoje"
              valor="2.356"
              cor="yellow"
              icone="ni-chart-pie-35"
            />
            <CardBullets
              titulo="CV PRO"
              subtitulo="Status"
              :valor="cvpro.fl_status ? 'ON' : 'OFF'"
              :cor="cvpro.fl_status ? 'green' : 'red'"
              icone="ni-diamond"
            />
            <CardBullets
              titulo="VAGAS"
              subtitulo="Participando"
              :valor="candidaturas.length.toString()"
              cor="info"
              icone="ni-chart-bar-32"
            />
          </div> -->
        </div>
      </div>
    </header>
    <section class="section-cards mb-5">
      <div class="content-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <CardDashboard icone="ni-like-2" titulo="Bem-vindo!">
                <p>
                  Olá <strong>{{candidato.nm_nome}}</strong>, esta é a sua área
                  de trabalho na MatchJob.
                </p>
                <p>
                  Aqui você encontrará todos os recursos necessários para
                  encontrar a sua re-colocação no mercado de trabalho.
                </p>
                <p>
                  Caso você tenha qualquer dificuldade, entre em contato conosco
                  através do formulário de contato logo a baixo.
                </p>
                <p>Será um prazer ajuda-lo!</p>
              </CardDashboard>
              <!-- <CardDashboard
                icone="ni-tv-2"
                titulo="Conheça a MatchJob Empregos"
              >
                Conteúdo...
              </CardDashboard> -->
            </div>
            <div class="col-lg-5">
              <CardDashboard icone="ni-collection" titulo="Currículos">
                <template v-if="curriculos.length > 0">
                  <template v-for="(cv, index) in curriculos">
                    <div class="row" :key="index">
                      <div class="col">
                        <router-link to="/curriculos"><strong>{{cv.nm_curriculo}}</strong></router-link> <small class="text-muted">{{retornaTipoCv(cv.fl_tipo)}}</small>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col">
                      <h4>Você não possui um currículo.</h4>
                      <router-link class="btn btn-primary" to="/curriculos">Crie um agora mesmo!</router-link>
                    </div>
                  </div>
                </template>
              </CardDashboard>
            </div>
          </div>
          <!-- Ultimas Vagas -->
          <div class="row">
            <div class="col-lg-12">
              <CardDashboard icone="ni-collection" titulo="Últimas vagas">
                <template v-for="(vaga, index) in ultimasVagas">
                  <div class="row my-2 border-bottom" :key="index">
                    <div class="col-lg-1 mb-1">
                      <span :class="`badge badge-${retornaTipoTrabalhoBadge(vaga.json_vaga.fl_tipo_trabalho)}`">{{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}}</span>
                    </div>
                    <div class="col-lg-11 mb-1">
                      <router-link :to="`/vaga/${vaga.id_processo_vaga}/${vaga.hl_link}`"><strong>{{vaga.nm_cargo}}</strong></router-link> <span class="text-muted">em <strong>{{vaga.json_vaga.nm_cidade}}</strong></span> <span
                        v-if="vaga.json_vaga.fl_deficientes" class="text-success"> -
                          <el-tooltip content="Vaga também para PcD" placement="top" effect="light"><i class="fab fa-accessible-icon"></i></el-tooltip>
                        </span> - Vaga publicada em: <strong>{{vaga.dt_abertura | formatDate}}</strong> <span class="badge badge-success" v-if="validaCandidatura(vaga.id_processo_vaga)"><i class="fas fa-thumbs-up"></i> Participando</span>
                    </div>
                  </div>
                </template>
              </CardDashboard>
            </div>
          </div>
          <!-- Formulário de Contato -->
          <div class="row">
            <div class="col-lg-12">
              <CardDashboard icone="ni-email-83" titulo="Entre em contato">
                <form>
                  <div class="form-group">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      v-model="msgAssunto"
                      required
                    >
                      <option value="Estou com Duvidas" selected
                        >Estou com Duvidas</option
                      >
                      <option value="Preciso relatar um problema"
                        >Preciso relatar um problema</option
                      >
                      <option value="Quero enviar uma sugestão"
                        >Quero enviar uma sugestão</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <textarea
                      maxlength="255"
                      placeholder="Mensagem"
                      v-model="msgTexto"
                      required
                      style="width: 100%; height: 125px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;"
                    ></textarea>
                  </div>
                  <base-button
                    class="btn-icon"
                    type="primary"
                    icon="ni ni-bold-right"
                    @click="enviaMensagem()"
                    >Enviar</base-button
                  >
                </form>
              </CardDashboard>
            </div>
          </div>
          <!-- Ultimas Noticias -->
          <div class="row">
            <div class="col-lg-12">
              <CardDashboard icone="ni-notification-70" titulo="Últimas Notícias">
                <div v-for="noticia in noticias" :key="noticia.id">
                  <div class="row">
                    <div class="col-lg-3">
                      <img
                        :src="noticia.jetpack_featured_media_url"
                        class="img-fluid shadow-sm"

                        ref="novaFoto"
                      />
                    </div>
                    <div class="col-lg-9">
                      <h3>
                        <a :href="noticia.link" target="_blank">{{ noticia.title.rendered }}</a>
                      </h3>
                      <small>Publicado por: <a :href="noticia._embedded.author[0].link" target="_blank">{{ noticia._embedded.author[0].name }}</a> em <strong>{{ noticia.date | formatDate }}</strong></small>
                      <span v-html="noticia.excerpt.rendered"></span>
                      <small>
                        Saiba mais em: <a :href="noticia.link" target="_blank">{{ noticia.link }}</a><br>
                      </small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <hr style="width: 100%;">
                    </div>
                  </div>
                </div>
                <!-- <pre>{{noticias}}</pre> -->
              </CardDashboard>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { userKey, baseApiUrl, showError } from "@/global";
import { projectFirestore, timestamp } from "../firebase/config";
import moment from "moment";
import varCidades from '@/data/cidades_br.js';

import CardBullets from "@/components/CardBullets";
import CardDashboard from "@/components/CardDashboard.vue";
import CardDashboardSemTitulo from "@/components/CardDashboardSemTitulo.vue";
import { Select, Option, Autocomplete, Input } from 'element-ui';

export default {
  components: {
    //CardBullets,
    CardDashboard,
    CardDashboardSemTitulo,
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      tiposCvs: [
        { id: 1, text: "Padrão (Normal)", selected: true },
        { id: 2, text: "Inglês", selected: false },
        { id: 3, text: "Espanhol", selected: false },
        { id: 4, text: "Portfólio", selected: false }
      ],
      tipoTrabalho: [
        { value: "1", text: "Presencial" },
        { value: "2", text: "Híbrido" },
        { value: "3", text: "Remoto" }
      ],
      //ultimasVagas: [],
      teste: "123",
      noticias: [
                  {
                    "id": 252,//Fica
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/curriculos.jpg",//fica
                    "link": "https://blog.matchjob.com.br/matchjob-empregos-o-seu-curriculo-blindado-para-o-sucesso/",//fica
                    "title": {
                      "rendered": "MatchJob Empregos: O Seu Currículo Blindado para o Sucesso!"
                    },//Fica
                    "_embedded": {
                      "author": [
                        {
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/",//fica
                          "name": "Edgard A Michel",//fica
                        }
                      ],
                    },
                    "excerpt": {
                      "rendered": "<p>Cansado de enviar currículos e não receber respostas? Sentindo-se desmotivado na busca por um novo emprego? A MatchJob Empregos chegou para ser a sua arma secreta na conquista da vaga dos sonhos!</p>\n",
                    },
                    "date": "2024-06-12T09:00:00",//Fica
                  },
                  {
                    "id": 233,
                    "date": "2024-06-11T09:00:00",
                    "link": "https://blog.matchjob.com.br/matchjob-empresas-a-revolucao-do-recrutamento-personalizado/",
                    "title": {
                      "rendered": "MatchJob Empresas: A Revolução do Recrutamento Personalizado"
                    },
                    "excerpt": {
                      "rendered": "<p>O processo de recrutamento e seleção precisa ser eficiente, preciso e personalizado para atender às necessidades específicas de cada vaga e da empresa como um todo.</p>\n",
                    },
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/dashboard-vaga1.png",
                    "_embedded": {
                      "author": [
                        {
                          "name": "Edgard A Michel",
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/"
                        }
                      ]
                    }
                  },
                  {
                    "id": 224,
                    "date": "2024-06-10T09:00:00",
                    "link": "https://blog.matchjob.com.br/matchjob-empresas-talentos-certificados/",
                    "title": {
                      "rendered": "MatchJob Empresas: Talentos Certificados"
                    },
                    "excerpt": {
                      "rendered": "<p>As certificações representam um investimento na carreira profissional e garantem que o candidato possui as competências necessárias para desempenhar a função com excelência.</p>\n",
                    },
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/certificacoes2.png",
                    "_embedded": {
                      "author": [
                        {
                          "name": "Edgard A Michel",
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/"
                        }
                      ]
                    }
                  },
                  {
                    "id": 216,
                    "date": "2024-06-07T09:00:00",
                    "link": "https://blog.matchjob.com.br/matchjob-empresas-encontrando-os-talentos-com-a-formacao-ideal/",
                    "title": {
                      "rendered": "MatchJob Empresas: Encontrando os Talentos com a Formação Ideal"
                    },
                    "excerpt": {
                      "rendered": "<p>Em um mercado de trabalho cada vez mais competitivo, encontrar os candidatos com a formação e o nível de escolaridade adequados é crucial para o sucesso das empresas. A qualificação dos profissionais é um fator determinante para o seu desempenho, produtividade e capacidade de contribuir para a organização.</p>\n",
                    },
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/formacoes-3.png",
                    "_embedded": {
                      "author": [
                        {
                          "name": "Edgard A Michel",
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/",
                        }
                      ]
                    }
                  },
                  {
                    "id": 207,
                    "date": "2024-06-06T09:00:00",
                    "link": "https://blog.matchjob.com.br/matchjob-empresas-o-seu-radar-de-talentos-especificos/",
                    "title": {
                      "rendered": "MatchJob Empresas: O Seu Radar de Talentos Específicos"
                    },
                    "excerpt": {
                      "rendered": "<p>Cansado de garimpar candidatos em um mar de currículos e entrevistas que não levam a lugar nenhum? O futuro do recrutamento chegou com o MatchJob Empresas, um sistema ATS que vai te ajudar a encontrar os diamantes brutos que sua empresa precisa para brilhar.</p>\n",
                    },
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/config-competencias2.png",
                    "_embedded": {
                      "author": [
                        {
                          "name": "Edgard A Michel",
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/",
                        }
                      ]
                    }
                  },
                  {
                    "id": 198,
                    "date": "2024-06-05T09:00:00",
                    "link": "https://blog.matchjob.com.br/cansado-de-perder-talentos-por-barreiras-linguisticas-de-um-up-na-sua-empresa-com-o-matchjob-empresas/",
                    "title": {
                      "rendered": "Cansado de Perder Talentos por Barreiras Linguísticas? Dê um Up na Sua Empresa com o MatchJob Empresas!"
                    },
                    "excerpt": {
                      "rendered": "<p>Chega de se contentar com candidatos que &#8220;quase&#8221; dominam os idiomas que você precisa! O futuro do recrutamento chegou com o MatchJob Empresas, um sistema ATS que vai te ajudar a encontrar os talentos multilíngues que sua empresa precisa para conquistar o mundo.</p>\n",
                    },
                    "jetpack_featured_media_url": "https://blog.matchjob.com.br/wp-content/uploads/2024/04/idioma-na-vaga.png",
                    "_embedded": {
                      "author": [
                        {
                          "name": "Edgard A Michel",
                          "link": "https://blog.matchjob.com.br/author/edgard-michel/"
                        }
                      ]
                    }
                  }
                ],
      msgAssunto: "Estou com Duvidas",
      msgTexto: "",
      procurar: {
        cargo: "",
        cidade: ""
      },
      cidades: []
    };
  },
  computed: {
    cargosState() {
      return this.$store.state.cargos;
    },
    mostraCandidato() {
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      return userData;
    },
    candidato() {
      return this.$store.state.candidato;
    },
    cvpro() {
      return this.$store.state.cvpro;
    },
    candidaturas() {
      return this.$store.state.candidaturas;
    },
    curriculos() {
      return this.$store.state.curriculos;
    },
    ultimasVagas() {
      return this.$store.state.vagas;
    }
  },
  methods: {
    async enviaMensagem() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      if (this.msgTexto != "") {
        try {
          const db = await projectFirestore.collection("msg-candidatos").add({
            fl_origem: 2,
            id_remetente: userData.id_candidato,
            nm_remetente: userData.nm_nome,
            hl_remetente: userData.hl_email,
            fl_assunto: this.msgAssunto,
            tx_mensagem: this.msgTexto,
            fl_status: 1,
            fl_tipo_mensagem: 2,
            unread: true,
            datetime: timestamp()
          });
          this.$toasted.global.defaultSuccess({
            msg: "Mensagem Enviada!"
          });
          this.msgTexto = null;
          console.log(db.id);
        } catch (err) {
          this.$toasted.global.defaultError({
            msg: "Ops! Mensagem não enviada."
          });
          console.log(err.message);
        }
      }
    },
    async getNoticias() {
      /* const res = await projectFirestore
        .collection("noticias-dashboard")
        .orderBy("datetime", "desc")
        .get();
      this.noticias = res.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      }); */
      //console.log('noticias...')
      const apiBlog = axios.create();
      await apiBlog.get('https://blog.matchjob.com.br/wp-json/wp/v2/posts?_embed&per_page=6')
      //await this.$httpVagas.get('https://blog.matchjob.com.br/wp-json/wp/v2/posts?_embed&per_page=6')
      .then(res => {
        this.noticias = res.data;
        //this.loading = false;
      })
      .catch(err => {
        error({ statusCode: 404, message: 'Falha de comunicação com o Blog da MatchJob' })
        console.log(err)
      })
    },
    retornaTipoCv(tipo) {
      var tipoCv = this.tiposCvs.find(t => t.id === parseInt(tipo));
      return tipoCv.text;
    },
    async getUltimasVagas() {
      if(this.ultimasVagas.length < 1) {
        await this.$http
          .get(`${baseApiUrl}/ultimasvagas`)
          .then(res => {
            this.$store.dispatch('defineVagas', res.data.vagas)
            //this.ultimasVagas = res.data.vagas;
            //console.log('getCurriculos -> ', res.data);
          })
          .catch(showError);
      }
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.value === value);
      return res.text;
    },
    retornaTipoTrabalhoBadge(value) {
      if (value === '1'){
        return "primary";
      } else if(value === '2'){
        return "info";
      } else {
        return "success";
      }
    },
    validaCandidatura(id) {
      var vaga = this.candidaturas.findIndex(i => i.id_processo_vaga === id);
      if(vaga > -1) {
        return true;
      } else {
        return false;
      }
    },
    querySearchCargos(queryString, cb) {
      let cargos = this.cargosState;
      var results = queryString ? cargos.filter(this.createFilterCargos(queryString)) : cargos;
      cb(results)
    },
    createFilterCargos(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    querySearchCidade(queryString, cb) {
      let cidades = this.cidades;
      var results = queryString ? cidades.filter(this.createFilterCidade(queryString)) : cidades;
      cb(results)
    },
    createFilterCidade(queryString) {
      return (cidade) => {
        return (cidade.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    validaCamposProcura() {
      if(this.procurar.cidade === '' && this.procurar.cargo === '') {
        return true;
      } else {
        return false;
      }
    },
    procurarVagas() {
      this.$router.push({ name: 'procurarvagas', params: { cargo: this.procurar.cargo, cidade: this.procurar.cidade } });
    },
    ajustaCidades() {
      varCidades.forEach(element => {
        let city = {
          id: element.id_cidade,
          value: element.nm_cidade_uf
        }
        this.cidades.push(city);
      });
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.getNoticias();
    this.getUltimasVagas();
    this.ajustaCidades();
  }
};
</script>
<style scoped>
.page-header {
  min-height: 0 !important;
  max-height: 900px;
  margin-top: 10px;
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
</style>
