<template>
  <card class="meucard" headerClasses="meucard-header">
    <template slot="header">
      <p class="display-4 mb-0">
        <i :class="'ni ' + icone" v-if="icone"></i>
        {{ titulo }}
      </p>
    </template>
    <template slot="body">
      <slot></slot>
    </template>
  </card>
</template>

<script>
export default {
  props: {
    icone: { type: String, required: false },
    titulo: { type: String, required: true }
    /* valor: { type: String, required: true},
    cor: { type: String, required: true}, */
  }
};
</script>

<style>
.meucard {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.meucard-header {
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>
