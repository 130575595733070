<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <!-- <div class="row">
            <div class="col">
              <base-alert type="info" dismissible v-if="statusCvpro">
                <span class="alert-inner--icon"
                  ><i class="ni ni-like-2"></i
                ></span>
                <span class="alert-inner--text"
                  >A sua assinatura do CV Pro está ativa até
                  {{ cvpro.dt_fim | formatoData }}</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
              <base-alert type="default" dismissible v-else>
                <span class="alert-inner--icon"
                  ><i class="ni ni-buld-61"></i
                ></span>
                <span class="alert-inner--text"
                  >Assine agora mesmo o CV Pro e tenha até 5 currículos.</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </div> -->
        </div>
      </div>
    </header>
    <section class="section-cards mb-5" v-loading="loading">
      <div class="content-center">
        <div class="container">
          <CardDashboard titulo="Seus Currículos">
            <transition-group name="fade" mode="out-in">
              <div
                class="row justify-content-sm-between"
                v-for="curriculo in curriculos"
                :key="curriculo.id_curriculo"
              >
                <!-- Listagem dos Curriculos -->
                <div class="col-lg-4 m-4">
                  <h5 class="mb-0">
                    {{ curriculo.nm_curriculo }}
                    <i class="ni ni-check-bold text-green" v-if="curriculo.fl_principal === '1'" v-b-tooltip.hover.top="'Currículo Principal.'"></i>
                  </h5>
                  <small>Última Atualização: {{ curriculo.updated_at | formatDateTime }}</small>
                </div>
                <!-- Botão de ação dos Curriculos -->
                <div class="col-lg-6 m-4 align-middle">
                  <base-button
                    type="primary"
                    size="sm"
                    class="my-1"
                    :disabled="buttonStatus(curriculo.fl_principal)"
                    @click="editarCv(curriculo.id_curriculo)">
                    Editar
                  </base-button>
                  <base-button
                    type="secondary"
                    size="sm"
                    class="my-1"
                    @click="visualizarCv(curriculo.id_curriculo)">
                    Visualizar</base-button>
                  <base-button
                    type="success"
                    size="sm"
                    class="my-1"
                    :disabled="cvPrincipal(curriculo.fl_principal)"
                    @click="defineCvPrincipal(curriculo.id_curriculo)">
                    Principal
                  </base-button>
                  <base-button
                    type="danger"
                    size="sm"
                    class="my-1"
                    :disabled="buttonStatus(curriculo.fl_principal)"
                    @click="apagarCv(curriculo.id_curriculo)">
                    Apagar
                  </base-button>
                </div>
              </div>
            </transition-group>
            <!-- Linha de Adição de CV -->
            <template v-if="statusCvpro">
              <!-- Alerta de quantidade de CVs disponíveis -->
              <div class="row ml-2 my-1">
                <div class="col-lg-5">
                  <!-- base-alert -->
                  <alerta-cv
                    :tipo="alertaCvPro.tipo"
                    :mensagem="alertaCvPro.mensagem"
                  />
                </div>
              </div>
              <div
                class="row"
                v-if="qtdCvs === 0 || (5 - qtdCvs < 5 && 5 - qtdCvs >= 0)"
              >
                <!-- Select do Tipo do Curriculo -->
                <div class="col-lg-4 my-4 ml-4">
                  <div class="form-group">
                    <label for="tipoCv">Escolha o tipo do Currículo</label>
                    <select
                      class="form-control"
                      id="tipoCv"
                      v-model="tipoCv"
                    >
                      <option
                        v-for="tipo in tiposCvs"
                        :key="tipo.id"
                        :value="tipo.id"
                      >
                        {{ tipo.text }}</option
                      >
                    </select>
                  </div>
                </div>
                <!-- Input do Nome do Currículo -->
                <div class="col-lg-4 my-4 ml-2">
                  <base-input
                    type="text"
                    maxlength="20"
                    placeholder="Ex. Profissional"
                    label="Dê um nome para o currículo"
                    v-model="novoCv"
                  ></base-input>
                </div>
                <!-- Botão Gravar Curriculo -->
                <div class="col-lg-2 my-4 mx-2 pt-2">
                  <base-button
                    type="primary"
                    class="mt-4"
                    @click="novoCurriculo"
                    >Incluir</base-button
                  >
                </div>
              </div>
            </template>
            <!-- Bloco de criação de Currículo -->
            <template v-else>
              <div class="row justify-content-start" v-if="qtdCvs == 0">
                <div class="col-lg-5 m-4" v-if="flImportLinkedIn">
                  <h4>Importar Perfil do LinkedIn</h4>
                  <base-input
                    type="text"
                    maxlength="255"
                    placeholder="https://www.linkedin.com/in/nome-sobrenome"
                    label="URL do seu perfil do LinkedIn"
                    v-model="linkedinImport.url"></base-input>
                  <base-button type="success" size="sm" @click="importLinkedInProfile">Importar</base-button>
                </div>
                <div class="col-lg-4 m-4">
                  <h4>Crie um manualmente</h4>
                  <base-input type="text" maxlength="20" placeholder="Ex. Profissional" label="Dê um nome para o currículo" v-model="novoCv"></base-input>
                  <input type="hidden" name="tipo" value="1" v-model="tipoCv" />
                  <base-button type="primary" size="sm" @click="novoCurriculo">Incluir</base-button>
                </div>
                <div class="col-lg-5 m-4">
                  <h4>Faça o upload de um arquivo</h4>
                  <form @submit.prevent="atualizarFoto" enctype="multipart/form-data">
                    <div class="custom-file">
                      <base-input type="file" inputClasses="custom-file-input" id="customFileLang" @change="onChangeFile($event)"></base-input>
                      <label class="custom-file-label" for="customFileLang">Selecione um arquivo.</label>
                    </div>
                    <small>
                      O documento deve ser PDF, DOC ou DOCX, até 11 MB de tamanho.
                    </small>
                  </form>
                </div>
              </div>
            </template>
            <!-- <pre>{{ novoCurriculoDefault }}</pre> -->
          </CardDashboard>
          <div class="row">
            <div class="col-lg">
              <br>
              <br>
              <!-- <CardDashboard titulo="Currículos Pro">
                <h1>Contrate agora o Currículos PRO</h1>
                <p>
                  Essa contratação libera a criação de até 5 Currículos que
                  serão válidos por 1 ano.<br />
                  Após o período de 1 ano, os currículos adicionais estarão
                  disponíveis apenas para a sua visualização.
                </p>
                <p>
                  Com o Currículos PRO você poderá criar currículos para
                  diversos perfis de vaga.
                </p>
                <p>Veja alguns exemplos:<br /></p>
                <ul>
                  <li>Currículo adicional em Inglês ou outro idioma.</li>
                  <li>
                    Currículo adicional para um perfil de Coordenador ou
                    Supervisor, visando um vagas que possam alavancar a sua
                    carreira.
                  </li>
                  <li>
                    Currículos específicos para para direcionar para setores
                    diferentes.
                  </li>
                </ul>
                <p>
                  As opções são variadas e irão colaborar com a sua
                  realocação profissional.
                </p>
                <h1><small>Currículos PRO por apenas: </small> R$ 5,99</h1>
                <base-button type="primary">Contratar Agora!</base-button>
              </CardDashboard> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import { userKey, baseApiUrl, cvAtual, showError } from "@/global";
import moment from "moment";
import { Loading } from 'element-ui';

import CardDashboard from "@/components/CardDashboard.vue";
import AlertaCv from "@/components/AlertaCv.vue";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

Vue.use(Loading.directive);

export default {
  directives: {
    BTooltip: VBTooltip
    //BPopover: VBPopover
  },
  components: { CardDashboard, AlertaCv },
  data() {
    return {
      loading: true,
      id_candidato: "",
      //curriculos: [],
      //cvpro: [],
      novoCv: "",
      tipoCv: "1",
      modalDelete: false,
      tiposCvs: [
        { id: 1, text: "Padrão (Normal)", selected: true },
        { id: 2, text: "Inglês", selected: false },
        { id: 3, text: "Espanhol", selected: false },
        { id: 4, text: "Portfólio", selected: false }
      ],
      novoCurriculoDefault: {
        nm_cargo: "",
        nm_area: 51,
        nm_nivel: 2,
        fl_jornada: 1,
        fl_tipo_contrato: 3,
        fl_pj: true,
        fl_primeiro_emprego: false,
        nu_salario: "",
        nm_cidade: "",
        tx_resumo: "",
        experiencias: [],
        formacoes: [],
        competencias: [],
        certificacoes: [],
        idiomas: [],
        tx_info_extra: ""
      },
      arquivoCv: null,
      resultCvUploaded: null,
      dataCheckApiImport: null,
      flImportLinkedIn: null,
      linkedinImport: {
        url: "",
        origem: 1,
        useApi: '',
        idcandidato: ''
      },
      validacaoForm: {
        url: null
      }
    };
  },
  computed: {
    candidato() {
      return this.$store.state.candidato;
    },
    curriculos() {
      return this.$store.state.curriculos;
    },
    cvpro() {
      return this.$store.state.cvpro;
    },
    statusCvpro() {
      if (!this.cvpro.fl_status) {
        return false;
      } else {
        return true;
      }
    },
    qtdCvs() {
      return this.curriculos.length;
    },
    alertaCvPro() {
      if (this.qtdCvs === 0) {
        const data = {
          tipo: "default",
          mensagem: `Você ainda pode cadastrar 5 curriculos.`
        };
        return data;
      } else if (5 - this.qtdCvs < 5 && 5 - this.qtdCvs > 1) {
        const data = {
          tipo: "default",
          mensagem: `Você ainda pode cadastrar ${5 - this.qtdCvs} curriculos.`
        };
        return data;
      } else if (5 - this.qtdCvs === 1) {
        const data = {
          tipo: "warning",
          mensagem: "Você ainda pode cadastrar 1 curriculo."
        };
        return data;
      } else {
        const data = {
          tipo: "danger",
          mensagem: "Você não pode cadastrar novos currículos."
        };
        return data;
      }
    },
    selected(value) {
      return value ? "selected" : "";
    }
  },
  methods: {
    async checkImportLinkedin() {
      await this.$http
      .get('/cadastro/check')
      .then(res => {
          this.dataCheckApiImport = res.data;
          this.flImportLinkedIn = this.validaUsoApiLinkedIn();
        })
        .catch(showError);
    },
    validaUsoApiLinkedIn() {
      if (this.dataCheckApiImport.proxycurl.credit_balance > 0) {
        this.linkedinImport.useApi = 'proxycurl'
        this.loading = false;
        return true;
      } else if (this.dataCheckApiImport.prospeo.response.remaining_credits > 0) {
        this.linkedinImport.useApi = 'prospeo'
        this.loading = false;
        return true;
      } else {
        this.loading = false;
        return false;
      }
    },
    inicializaValores() {
      //const json = localStorage.getItem(userKey);
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.id_candidato = userData.id_candidato;
      this.novoCurriculoDefault.nm_cidade = this.candidato.nm_cidade + " - " + this.candidato.nm_estado;//Ajusta a cidade do currículo com a cidade do candidato
    },
    async getCurriculos() {
      await this.$http
        .get(`${baseApiUrl}/curriculos`, {
          params: { id_candidato: this.candidato.id_candidato }
        })
        .then(res => {
          this.$store.dispatch('defineCurriculos', res.data.curriculos);
        })
        .catch(showError);
    },
    getCvPro() {
      this.$http
        .get(`${baseApiUrl}/cvpro`, {
          params: { id_candidato: this.candidato.id_candidato }
        })
        .then(res => {
          this.cvpro = res.data;
          console.log(this.cvpro);
        })
        .catch(showError);
    },
    editarCv(id) {
      const data = {
        id_candidato: this.candidato.id_candidato,
        id_curriculo: id
      };
      //localStorage.setItem(cvAtual, JSON.stringify(data));
      sessionStorage.setItem(cvAtual, JSON.stringify(data));
      this.$router.push({ name: "novocurriculo" });
    },
    visualizarCv(id) {
      const data = {
        id_candidato: this.candidato.id_candidato,
        id_curriculo: id
      };
      //localStorage.setItem(cvAtual, JSON.stringify(data));
      sessionStorage.setItem(cvAtual, JSON.stringify(data));
      this.$router.push({ name: "visualizarcv" });
    },
    cvPrincipal(cvPrincipal) {
      if (cvPrincipal === "1") {
        return true;
      } else {
        if(this.statusCvpro) {
          return false;
        } else {
          return true;
        }
      }
    },
    defineCvPrincipal(id) {
      var cvPrincipalAtual = this.curriculos.findIndex(c => c.fl_principal === '1');
      var cvPrincipalNovo = this.curriculos.findIndex(c => c.id_curriculo === id);
      const data = {
        atual: cvPrincipalAtual,
        novo: cvPrincipalNovo
      }
      this.$store.dispatch('alteraCvPrincipal', data)
      setTimeout(() => {
        this.salvarCv(cvPrincipalAtual);
        setTimeout(() => {
          this.salvarCv(cvPrincipalNovo);
        }, 500);
      }, 500);
    },
    buttonStatus(cvPrincipal) {
      if (this.statusCvpro) {
        //console.log("retornou no 1 if");
        return false;
      } else {
        if (this.qtdCvs === 1 && cvPrincipal === "1") {
          //console.log("retornou no 1 else");
          return false; //retorna falso porque o botão ficará ativo
        } else if (this.qtdCvs > 1 && cvPrincipal === "1") {
          //console.log("retornou no 2 else");
          return false; //
        } else {
          //console.log("retornou no 3 else");
          return true;
        }
      }
    },
    novoCurriculo() {
      if (this.novoCv !== "") {
        const data = {
          id_candidato: this.candidato.id_candidato,
          nm_curriculo: this.novoCv,
          fl_tipo: this.tipoCv,
          fl_status: 1,
          fl_principal: this.qtdCvs > 0 ? false : true,
          json_curriculo: this.novoCurriculoDefault
        };
        this.$http.post(`${baseApiUrl}/curriculos/salvar`, data).then(() => {
          this.getCurriculos();
          this.$toasted.global.defaultSuccess({
            msg: "Currículo adicionado com sucesso!"
          });
        });
      } else {
        this.$toasted.global.defaultError({
          msg: "Defina um nome para o Currículo!"
        });
      }
    },
    apagarCv(idcv) {
      if (confirm("Tem certeza que deseja apagar este currículo?")) {
        const data = {
          id_candidato: this.candidato.id_candidato,
          id_curriculo: idcv
        };
        this.$http.post(`${baseApiUrl}/curriculos/delete`, data).then(() => {
          this.getCurriculos();
          this.$toasted.global.defaultSuccess({
            msg: "Apagado com sucesso!"
          });
        });
      }
    },
    async carregaCargos() {
      await this.$http.get('/sysinfos/getall')
        .then(res => {
          this.$store.dispatch('defineSysInfos', res);
          //console.log('res asyncData ->', res)
          /* this.cargos = res.cargos;
          this.competencias = res.competencias;
          this.idiomas = res.idiomas;
          this.instituicoes = res.instituicoes;
          this.formacoes = res.formacoes;
          this.certificacoes = res.certificacoes; */
        })
        .catch(err => {
          console.log('erro do axios ->> ', err)
        });
    },
    async salvarCv(indice) {
      await this.$http({
        method: "post",
        url: '/curriculos/salvar',
        data: this.curriculos[indice]
      })
        .then(res => {
          //this.notificacao("Foto atualizada com sucesso!", 'success');
          //console.log('res -> ', res.data)
          this.$toasted.global.defaultSuccess({
            msg: `<strong>${res.data.nm_curriculo}</strong> -alterado com sucesso!`
          });
        })
        .catch(err => {
          //this.notificacao("Não foi possível atualizar a Foto.", 'warning');
          console.log(err);
        });
    },
    onChangeFile(event) {
      const file = event.target.files[0];
      var novaFoto = URL.createObjectURL(file);
      this.arquivoCv = file;
      setTimeout(() => {
        this.uploadCv();
      }, 300);
    },
    uploadCv() {
      const form = new FormData();
      form.append("id_candidato", this.candidato.id_candidato);
      form.append("doc", this.arquivoCv);
      form.append("flGravacao", true);
      const headers = { 'Content-Type': 'multipart/form-data' };
      this.$http
        .post(`${baseApiUrl}/curriculos/uploadcv`, form, {headers})
        .then(res => {
          if(res) {
            this.$toasted.global.defaultSuccess({
              msg: "Currículo Importado com sucesso!"
            });
            alert("Revise as informações do Currículo importado!\nPodem haver erros na importação.");
            this.getCurriculos();
            setTimeout(() => {
              const data = {
                id_candidato: this.candidato.id_candidato,
                id_curriculo: res.data
              };
              sessionStorage.setItem(cvAtual, JSON.stringify(data));
              this.$router.push({ name: "novocurriculo" });
            }, 500);
          } else {
            this.$toasted.global.defaultError({
              msg: "Falha na importação do arquivo."
            });
          }
        })
        .catch(err => {
          this.$toasted.global.defaultError({
            msg: "Não foi possível importar o arquivo.<br>Falha na leitura do arquivo."
          });
          console.log(err);
        });
    },
    async importLinkedInProfile() {
      this.loading = true;
      this.linkedinImport.idcandidato = this.candidato.id_candidato;
      await this.$http.post('/cadastro/importarlinkedin', this.linkedinImport)
      .then(res => {
        //console.log('res -> ', res.data)
        if (res.data.error) {
          this.validacaoForm.url = false;
          this.loading = false;
          this.$toasted.global.defaultError({
            msg: "Erro na importação do Perfil!"
          });
        } else {
          this.validacaoForm.url = true;
          const importLinkedin = res.data;
          this.novoCurriculoDefault.nm_cargo = importLinkedin.cargo;
          this.novoCurriculoDefault.tx_resumo = importLinkedin.resumo;
          this.novoCurriculoDefault.certificacoes = importLinkedin.certificacoes;
          this.novoCurriculoDefault.competencias = importLinkedin.competencias;
          this.novoCurriculoDefault.formacoes = importLinkedin.formacoes;
          this.novoCurriculoDefault.experiencias = importLinkedin.experiencias;
          this.novoCurriculoDefault.idiomas = importLinkedin.idiomas;
          this.novoCv = "Currículo Padrão"
          sessionStorage.setItem('importLinkedin', JSON.stringify(res.data));
          this.goToNovoCvImportado();
        }
        })
        .catch( err => {
          if (err.response.status) {
            this.validacaoForm.url = false;
            this.loading = false;
            this.$toasted.global.defaultError({
              msg: "Erro na comunicação Tente mais tarde!"
            });
          }
        });
    },
    async goToNovoCvImportado() {
      const data = {
        id_candidato: this.candidato.id_candidato,
        nm_curriculo: this.novoCv,
        fl_tipo: this.tipoCv,
        fl_status: 1,
        fl_principal: this.qtdCvs > 0 ? false : true,
        json_curriculo: this.novoCurriculoDefault
      };
      await this.$http.post(`${baseApiUrl}/curriculos/salvar`, data).then(res => {
        const data = {
          id_candidato: this.candidato.id_candidato,
          id_curriculo: res.data.id_curriculo.toString()
        };
        sessionStorage.setItem(cvAtual, JSON.stringify(data));
        this.getCurriculos();
        this.$toasted.global.defaultSuccess({
          msg: "Currículo adicionado com sucesso!"
        });
        setTimeout(() => {
          this.loading = false;
          this.$router.push({ name: "novocurriculo" });
        }, 500);
      })
      .catch(err => {
        this.$toasted.global.defaultError({
          msg: "Ops! Houve uma falha... Atualize a pagina por favor."
        });
        console.log(err);
      });
    }
  },
  filters: {
    formatDateTime: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.checkImportLinkedin();
    this.inicializaValores();
    this.carregaCargos();
  }
};
</script>
<style scoped>
.page-header {
  min-height: 0 !important;
  max-height: 999px;
  margin-top: 40px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
</style>
