<template>
  <div>
    <base-button
      type="primary"
      size="sm"
      class="mb-3"
      @click="flNovaCertificacao = true"
    >
      Adicionar Certificação</base-button
    >
    <div v-for="cert in certificacoes" :key="cert.id">
      <div class="row">
        <!-- Campo titulo -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Título<br><strong>{{cert.nm_titulo}}</strong></p>
          </div>
        </div>
        <!-- Campo Orgão Emissor -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Orgão Emissor<br><strong>{{cert.nm_orgao_emissor}}</strong></p>
          </div>
        </div>
        <!-- Numero da certificação -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Número<br><strong>{{cert.nm_numero}}</strong></p>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Data da Certificação -->
        <div class="col-lg-4">
          <div class="form-group">
            <p>Data da Certificacao<br><strong>{{cert.dt_certificacao | formatDate}}</strong></p>
          </div>
        </div>
        <!-- Link da certificação -->
        <div class="col-lg-4">
          <div class="form-group">
            <label>Link do certificado:</label>
            <a :href="cert.hl_link_certificado" target="_blank">{{cert.hl_link_certificado}}</a>
          </div>
        </div>
      </div>
      <!-- Botões Atualizar e Apagar -->
      <div class="row">
        <div class="col d-flex justify-content-end pb-4">
          <base-button
            type="danger"
            size="sm"
            @click="removerCertificacao(cert.id)"
            >Apagar</base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butUp(cert.id)" @click="moveUp(cert.id)"
            ><i class="fas fa-arrow-up"></i></base-button
          >
          <base-button
            type="secondary"
            size="sm"
            v-if="butDown(cert.id)" @click="moveDown(cert.id)"
            ><i class="fas fa-arrow-down"></i></base-button
          >
          <base-button
            type="primary"
            size="sm"
            @click="editarCertificacao(cert.id)"
            >Atualizar</base-button
          >
        </div>
      </div>
      <!-- Divisor HR -->
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
    </div>
    <modal
      :show.sync="flNovaCertificacao"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <h5
        slot="header"
        class="modal-title"
        id="modal-title-default"
      >
        Adicionar Certificação
      </h5>
      <div class="row">
        <!-- Campo Titulo -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="tituloCertificacao">Título <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">Informe o titulo da certificação<br>Ex. <strong>ISO 27001</strong> ou <strong>Microsoft Certified: Azure Administrator Associate</strong></div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label><br>
            <el-autocomplete
              ref="tituloCertificacao"
              id="tituloCertificacao"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaCertificacao.nm_titulo"
              :fetch-suggestions="querySearchCertificacao"
              placeholder="Título da Certificação"
              :trigger-on-focus="false"
            ></el-autocomplete>
          </div>
        </div>
        <!-- Campo Orgão Emissor -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="orgaoemissor">Orgão Emissor <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">Informe o nome da Empresa ou Instituição que emite a certificação</div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label><br>
            <el-input
              ref="orgaoemissor"
              id="orgaoemissor"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaCertificacao.nm_orgao_emissor"
              placeholder="Orgão Emissor"
            ></el-input>
          </div>
        </div>
        <!-- Campo Numero da Certificação -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="numerocertificacao">Numero <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">Informe o numero do certificado ou coloque 0 (zero) caso não exista</div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label><br>
            <el-input
              ref="numerocertificacao"
              id="numerocertificacao"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaCertificacao.nm_numero"
              placeholder="Numero"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Campo Data da Certificação -->
        <div class="col-lg-4">
          <div class="form-group">
            <label class="form-control-label">Data de Certificação <el-tooltip
              placement="top" effect="light">
              <div slot="content">Informe ao menos o mês e ano que você conquistou a certificação</div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label>
            <el-input
              type="date"
              id="datacert"
              class="inuptAutocomplete"
              v-model="novaCertificacao.dt_certificacao"
            ></el-input>
          </div>
        </div>
        <!-- Campo Link do Certificado -->
        <div class="col-lg-4">
          <div class="form-group">
            <label for="lickcertificacao">Link do Certificado <el-tooltip
              placement="top" effect="light">
              <div slot="content">Informe o link do certificado online ou deixe em branco caso não exista</div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip></label><br>
            <el-input
              ref="lickcertificacao"
              id="lickcertificacao"
              class="inuptAutocomplete"
              :maxlength="255"
              v-model="novaCertificacao.hl_link_certificado"
              placeholder="Link do Certificado"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <base-button
            type="secondary"
            @click="cancelaNovaCertificacao"
            >Cancelar</base-button
          >
          <base-button type="primary" @click="addNovaCertificacao"
            >Incluir</base-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Select, Option, Autocomplete, Input } from 'element-ui';
import Modal from "@/components/Modal.vue";
import moment from "moment";

export default {
  name: "Certificacoes",
  props: {
    propCertificacoes: { type: Array },
    procDadosCertificacoes: { type: Function }
  },
  components: {
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },
  data() {
    return {
      certificacoes: [],
      novaCertificacao: {
        id: 0,
        nm_titulo: "",
        nm_orgao_emissor: "",
        nm_numero: "",
        hl_link_certificado: "",
        dt_certificacao: ""
      },
      flNovaCertificacao: false
    }
  },
  computed: {
    certificacoesState() {
      return this.$store.state.certificacoes;
    }
  },
  methods: {
    emiteDados() {
      this.$emit('resCertificacoes', this.certificacoes);
      setTimeout(() => {
        this.procDadosCertificacoes();
      }, 300);
    },
    querySearchCertificacao(queryString, cb) {
      let varCertificacao = this.certificacoesState;
      var results = queryString ? varCertificacao.filter(this.createFilterCertificacao(queryString)) : varCertificacao;
      cb(results)
    },
    createFilterCertificacao(queryString) {
      return (cert) => {
        return (cert.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    cancelaNovaCertificacao() {
      this.novaCertificacao.id = 0;
      this.novaCertificacao.nm_titulo = "";
      this.novaCertificacao.nm_orgao_emissor = "";
      this.novaCertificacao.nm_numero = "";
      this.novaCertificacao.hl_link_certificado = "";
      this.novaCertificacao.dt_certificacao = "";
      this.flNovaCertificacao = false;
    },
    validaNovaCertificacao() {
      if (this.novaCertificacao.nm_titulo === "") {
        return false;
      } else if (this.novaCertificacao.nm_orgao_emissor === "") {
        return false;
      } else if (this.novaCertificacao.nm_numero === "") {
        return false;
      } else if (this.novaCertificacao.dt_certificacao === "") {
        return false;
      } else {
        return true;
      }
    },
    addNovaCertificacao() {
      if (this.validaNovaCertificacao()) {
        if (this.novaCertificacao.id === 0) {
          if (this.certificacoes.length > 0) {
            const lastItem = this.certificacoes.pop();
            let id = lastItem.id;
            this.certificacoes.push(lastItem);
            this.novaCertificacao.id = ++id;
          } else {
            this.novaCertificacao.id = 1;
          }
          let newItem = structuredClone(this.novaCertificacao)
          this.certificacoes.push(newItem);
          this.cancelaNovaCertificacao();
          this.emiteDados();
        } else {
          var pos = this.certificacoes.findIndex( item => item.id === this.novaCertificacao.id);
          let tempRes = structuredClone(this.novaCertificacao);
          this.certificacoes.splice(pos, 1, tempRes);
          this.cancelaNovaCertificacao();
          this.emiteDados();
        }
      }
    },
    butUp(id){
      var pos = this.certificacoes.findIndex( item => item.id === id);
      if (pos < 1) {
        return false;
      } else {
        return true;
      }
    },
    butDown(id){
      //var pos = this.newTest.findIndex( item => item.id === id);
      if (this.certificacoes.length === id){
        return false;
      } else {
        return true;
      }
    },
    moveDown(id){
      var idNow = id;
      var idNext = ++id;
      var pos0 = this.certificacoes.findIndex( item => item.id === idNow);
      var pos1 = this.certificacoes.findIndex( item => item.id === idNext);
      var arr0 = this.certificacoes.find( item => item.id === idNow);
      var arr1 = this.certificacoes.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.certificacoes.splice(pos0, 1, arr1);
      this.certificacoes.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    moveUp(id){
      var idNow = id;
      var idNext = --id;
      var pos0 = this.certificacoes.findIndex( item => item.id === idNow);
      var pos1 = this.certificacoes.findIndex( item => item.id === idNext);
      var arr0 = this.certificacoes.find( item => item.id === idNow);
      var arr1 = this.certificacoes.find( item => item.id === idNext);
      arr0.id = idNext;
      arr1.id = idNow;
      this.certificacoes.splice(pos0, 1, arr1);
      this.certificacoes.splice(pos1, 1, arr0);
      this.emiteDados();
    },
    removerCertificacao(id) {
      if (confirm('Tem certeza que deseja remover essa certificação?')) {
        var pos = this.certificacoes.findIndex( item => item.id === id);
        var tam = this.certificacoes.length;
        if (tam === (pos + 1)) {
          this.certificacoes.splice(pos, 1);
          this.emiteDados();
        } else {
          for (var i = pos+1; i < tam; i++){
            this.certificacoes[i].id = this.certificacoes[i].id-1;
          }
          this.certificacoes.splice(pos, 1);
          this.emiteDados();
        }
      }
    },
    editarCertificacao(id) {
      var editCertificacao = this.certificacoes.find( item => item.id === id);
      this.novaCertificacao.id = editCertificacao.id;
      this.novaCertificacao.nm_titulo = editCertificacao.nm_titulo;
      this.novaCertificacao.nm_orgao_emissor = editCertificacao.nm_orgao_emissor;
      this.novaCertificacao.nm_numero = editCertificacao.nm_numero;
      this.novaCertificacao.hl_link_certificado = editCertificacao.hl_link_certificado;
      this.novaCertificacao.dt_certificacao = editCertificacao.dt_certificacao;
      this.flNovaCertificacao = true;
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    }
  },
  mounted() {
    this.certificacoes = structuredClone(this.propCertificacoes);
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
