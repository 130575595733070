import Vue from "vue";
import axios from "axios";
import { userKey, baseApiUrl, encryptKey, cvAtual, showError } from "@/global";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      //baseURL: "http://api.candidato.matchjob.local/",
      baseURL: "https://api.candidato.matchjob.com.br/",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    });
    Vue.prototype.$httpVagas = axios.create({
      baseURL: "https://api.vagas.matchjob.com.br",
      //baseURL: "http://api.vagas.matchjob.local",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest"
      }
    });
  }
});

const success = res => res;
const error = err => {
  if (401 === err.response.status) {
    window.location = "/";
  } else {
    return Promise.reject(err);
  }
};

axios.interceptors.response.use(success, error);
