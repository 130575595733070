import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes"


import DefaultLayout from "@/views/Layout/DefaultLayout"
import Login from "@/views/Login";
import RecuperaSenha from "@/views/RecuperaSenha";
import Header from "@/layout/AppHeader";
import Footer from "@/layout/AppFooter";
import Dashboard from "@/views/Dashboard";
import Perfil from "@/views/Perfil/Account";
import Curriculos from "@/views/Curriculos/Curriculos";
import NovoCurriculo from "@/views/Curriculos/NovoCurriculo";
import VisualizarCv from "@/views/Curriculos/VisualizarCv";
import ProcurarVagas from "@/views/Vagas/ProcurarVagas";
import VisualizarVaga from "@/views/Vagas/VisualizarVaga";


Vue.use(VueRouter);

/* const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  console.log('to -> ', to);
  console.log('from -> ', from);
})

export default router; */

export default new VueRouter({
  mode: "history",
  routes,

  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Inicio'
    },
    {
      path: "/login",
      name: "login",
      components: {
        //header: Header,
        default: Login,
        footer: Footer
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      components: {
        header: Header,
        default: Dashboard,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/recuperarsenha",
      name: "recuperarsenha",
      components: {
        //header: Header,
        default: RecuperaSenha,
        footer: Footer
      }
    },
    {
      path: "/perfil",
      name: "perfil",
      components: {
        header: Header,
        default: Perfil,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/curriculos",
      name: "curriculos",
      components: {
        header: Header,
        default: Curriculos,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/novocurriculo",
      name: "novocurriculo",
      components: {
        header: Header,
        default: NovoCurriculo,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/visualizarcv",
      name: "visualizarcv",
      components: {
        header: Header,
        default: VisualizarCv,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/procurarvagas/:cargo/:cidade",
      name: "procurarvagas",
      components: {
        header: Header,
        default: ProcurarVagas,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/vagas",
      name: "vagas",
      components: {
        header: Header,
        default: ProcurarVagas,
        footer: Footer
      },
      props: {
        header: { navbarType: "primary" }
      }
    },
    {
      path: "/vaga/:id/:url",
      name: "vaga",
      components: {
        header: Header,
        default: VisualizarVaga,
        footer: Footer
      },
      props: {
        header: {
          navbarType: "primary"
        }
      }
    }
  ],

  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
