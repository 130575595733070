<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label for="nmIdioma">Idioma <span class="text-danger">*</span></label>
          <el-autocomplete
            ref="nmIdioma"
            id="nmIdioma"
            class="inuptAutocomplete"
            :maxlength="255"
            v-model="novoIdioma.nm_idioma"
            :fetch-suggestions="querySearchIdioma"
            placeholder="Idioma"
            :trigger-on-focus="false"
          ></el-autocomplete>
        </div>
      </div>
      <div class="col-lg-4 col-6">
        <div class="form-group">
          <label for="tipoformacao">Nível do Idioma <span class="text-danger">*</span> <el-tooltip
              placement="top" effect="light">
              <div slot="content">
                <strong>Básico</strong> = Pode ler mas não consegue redigir um texto ou entrar em uma conversa<br>
                <strong>Intermediário</strong> = Consegue ler e pode redigir um texto ou entrar em uma conversa rápida<br>
                <strong>Avançado</strong> = Consegue ler e redigir um texto e participa ativamente de conversas<br>
                <strong>Fluente</strong> = Não possui nenhuma dificuldade com o idioma
              </div>
              <i class="help-icon fas fa-question-circle"></i>
            </el-tooltip>
          </label>
          <el-select class="select-primary selectAutocomplete" v-model="novoIdioma.fl_nivel">
            <el-option
              v-for="idioma in nivelIdioma"
              :key="idioma.value"
              class="select-primary"
              :value="idioma.value"
              :label="idioma.text"></el-option>
          </el-select>
        </div>
      </div>
      <div
        class="col-lg-4 d-flex justify-content-end align-items-center"
      >
        <base-button
          type="secondary"
          size="sm"
          class="mt-2"
          @click="cancelaNovoIdioma"
          >Cancelar</base-button
        >
        <base-button
          type="primary"
          size="sm"
          class="mt-2"
          @click="addNovoIdioma"
          >Incluir</base-button
        >
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-4">
        <p>Idioma - Nível</p>
      </div>
    </div>
    <div
      class="row"
      v-for="idioma in idiomas"
      :key="idioma.id_idioma"
    >
      <div class="col-lg-4">
        {{idioma.nm_idioma}} - <strong>{{ retornaNivelIdioma(idioma.fl_nivel) }}</strong>
      </div>
      <div
        class="col-lg-4 d-flex justify-content-start align-items-center"
      >
        <base-button
          type="warning"
          size="sm"
          class="mb-3"
          @click="editarIdioma(idioma.id)"
          >Editar</base-button
        >
        <base-button
          type="danger"
          size="sm"
          class="mb-3"
          @click="removerIdioma(idioma.id)"
          >Excluir</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option, Autocomplete } from 'element-ui';

export default {
  name: "Idiomas",
  props: {
    propIdiomas: { type: Array },
    procDadosIdiomas: { type: Function }
  },
  components: {
    [Autocomplete.name]: Autocomplete,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      idiomas: [],
      novoIdioma: {
        id: 0,
        nm_idioma: "",
        fl_nivel: 1
      },
      flNovoIdioma: false,
      nivelIdioma: [
        { value: 1, text: "Básico" },
        { value: 2, text: "Intermediário" },
        { value: 3, text: "Avançado" },
        { value: 4, text: "Fluente" }
      ]
    }
  },
  computed: {
    idiomasState() {
      return this.$store.state.idiomas;
    }
  },
  methods: {
    retornaNivelIdioma(id) {
      var res = this.nivelIdioma.find(i => i.value === parseInt(id));
      return res.text;
    },
    querySearchIdioma(queryString, cb) {
      let varIdioma = this.idiomasState;
      var results = queryString ? varIdioma.filter(this.createFilterIdioma(queryString)) : varIdioma;
      cb(results)
    },
    createFilterIdioma(queryString) {
      return (idioma) => {
        return (idioma.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    cancelaNovoIdioma() {
      this.novoIdioma.id = 0;
      this.novoIdioma.nm_idioma = "";
      this.novoIdioma.fl_nivel = 1;
      this.flNovoIdioma = false;
    },
    validaNovoIdioma() {
      if (this.novoIdioma.nm_idioma === "") {
        return false;
      } else {
        return true;
      }
    },
    addNovoIdioma() {
      if (this.validaNovoIdioma()) {
        if (this.novoIdioma.id === 0) {
          if (this.idiomas.length > 0) {
            const lastItem = this.idiomas.pop();
            let id = lastItem.id;
            this.idiomas.push(lastItem);
            this.novoIdioma.id = ++id;
          } else {
            this.novoIdioma.id = 1;
          }
          let newItem = structuredClone(this.novoIdioma)
          this.idiomas.push(newItem);
          this.cancelaNovoIdioma();
        } else {
          var pos = this.idiomas.findIndex( item => item.id === this.novoIdioma.id);
          let tempRes = structuredClone(this.novoIdioma);
          this.idiomas.splice(pos, 1, tempRes);
          this.cancelaNovoIdioma();
        }
      }
    },
    removerIdioma(id) {
      if (confirm('Tem certeza que deseja remover esse idioma?')) {
        var pos = this.idiomas.findIndex( item => item.id === id);
        var tam = this.idiomas.length;
        if (tam === (pos + 1)) {
          this.idiomas.splice(pos, 1);
        } else {
          for (var i = pos+1; i < tam; i++){
            this.idiomas[i].id = this.idiomas[i].id-1;
          }
          this.idiomas.splice(pos, 1);
        }
      }
    },
    editarIdioma(id) {
      var editIdioma = this.idiomas.find( item => item.id === id);
      this.novoIdioma.id = editIdioma.id;
      this.novoIdioma.nm_idioma = editIdioma.nm_idioma;
      this.novoIdioma.fl_nivel = editIdioma.fl_nivel;
      this.flNovoIdioma = true;
    }
  },
  mounted() {
    this.idiomas = structuredClone(this.propIdiomas);
  }
}
</script>

<style scoped>
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
</style>
