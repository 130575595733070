<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row" v-if="testeVar">
            <div class="col">
              <base-alert type="info" dismissible v-if="statusCvpro">
                <span class="alert-inner--icon"
                  ><i class="ni ni-like-2"></i
                ></span>
                <span class="alert-inner--text"
                  >A sua assinatura do CV Pro está ativa até
                  {{ cvpro[0].dt_fim }}</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
              <base-alert type="default" dismissible v-else>
                <span class="alert-inner--icon"
                  ><i class="ni ni-buld-61"></i
                ></span>
                <span class="alert-inner--text"
                  >Assine agora mesmo o CV Pro e tenha até 5 currículos.</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="section-cards mb-5" v-loading="loading">
      <div class="content-center">
        <div class="container">
          <div class="row">
            <div class="col-lg" v-if="!loading">
              <CardDashboard titulo="Objetivos Profissionais">
                <Objetivos
                  :propObjetivo="curriculo.json_curriculo"
                  @resObjetivo="objetivo = $event"
                  :procDadosObjetivo="atualizaObjetivo"
                  :propValidacao="validaObjetivo"
                />
              </CardDashboard>
              <CardDashboard titulo="Resumo Profissional">
                <div class="row" v-if="curriculo !== undefined">
                  <div class="col-lg">
                    <div class="form-group">
                      <label for="resumo">Resumo</label>
                      <textarea
                        class="form-control"
                        id="resumo"
                        maxlength="4000"
                        rows="10"
                        v-model="curriculo.json_curriculo.tx_resumo"
                        @blur="salvaCurriculoState"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <transition name="fade" mode="in-out">
                      <base-button
                        type="primary"
                        size="sm"
                        class="mb-3"
                        v-if="buttonSave.resumo"
                        @click="atualizaResumo"
                      >
                        Salvar</base-button
                      >
                    </transition>
                  </div>
                </div>
              </CardDashboard>
              <CardDashboard titulo="Experiência Profissional">
                <Experiencias
                  :propExperiencias="curriculo.json_curriculo.experiencias"
                  @resExperiencias="experiencias = $event"
                  :procDadosExperiencias="atualizaExperiencias"
                />
              </CardDashboard>
              <CardDashboard titulo="Formação Acadêmica">
                <Formacoes
                  :propFormacoes="curriculo.json_curriculo.formacoes"
                  @resFormacoes="formacoes = $event"
                  :procDadosFormacoes="atualizaFormacoes"
                />
              </CardDashboard>
              <CardDashboard titulo="Competências">
                <Competencias
                  :propCompetencias="curriculo.json_curriculo.competencias"
                  @resCompetencias="competencias = $event"
                  :procDadosCompetencias="atualizaCompetencias"
                />
              </CardDashboard>
              <CardDashboard titulo="Certificações">
                <Certificacoes
                  :propCertificacoes="curriculo.json_curriculo.certificacoes"
                  @resCertificacoes="certificacoes = $event"
                  :procDadosCertificacoes="atualizaCertificacoes"
                />
              </CardDashboard>
              <CardDashboard titulo="Idiomas">
                <Idiomas
                  :propIdiomas="curriculo.json_curriculo.idiomas"
                  @resIdiomas="idiomas = $event"
                  :procDadosIdiomas="atualizaIdiomas"
                />
              </CardDashboard>
              <CardDashboard titulo="Extras">
                <div class="row" v-if="curriculo !== undefined">
                  <div class="col-lg">
                    <div class="form-group">
                      <label for="resumo">Informações Complementares</label>
                      <textarea
                        class="form-control"
                        id="resumo"
                        rows="10"
                        v-model="curriculo.json_curriculo.tx_info_extra"
                        @blur="salvaCurriculoState()"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <transition name="fade" mode="in-out">
                      <base-button
                        type="primary"
                        size="sm"
                        class="mb-3"
                        v-if="buttonSave.resumo"
                        @click="atualizaResumo"
                      >
                        Salvar</base-button
                      >
                    </transition>
                  </div>
                </div>
              </CardDashboard>
              <card
                headerClasses="p-0"
                class="d-flex justify-content-end align-items-center"
              >
                <template slot="body">
                  <base-button type="primary" size="lg" @click="salvarCv"
                    >Salvar</base-button
                  >
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import { userKey, baseApiUrl, cvAtual, showError } from "@/global";

import { Loading } from 'element-ui';
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

import CardDashboard from "@/components/CardDashboard.vue";
import Objetivos from '../components/EdicaoCurriculo/Objetivos.vue';
import Experiencias from '../components/EdicaoCurriculo/Experiencias.vue';
import Formacoes from '../components/EdicaoCurriculo/Formacoes.vue';
import Competencias from '../components/EdicaoCurriculo/Competencias.vue';
import Certificacoes from '../components/EdicaoCurriculo/Certificacoes.vue';
import Idiomas from '../components/EdicaoCurriculo/Idiomas.vue';

Vue.use(Loading.directive);

export default {
  directives: {
    BTooltip: VBTooltip
    //BPopover: VBPopover
  },
  components: {
    CardDashboard,
    Objetivos,
    Experiencias,
    Formacoes,
    Competencias,
    Certificacoes,
    Idiomas
  },
  data() {
    return {
      loading: true,
      testeVar: false,
      radio: "",
      id_candidato: "",
      id_curriculo: "",
      curriculo: {},
      objetivo: {},
      resumo: [],
      experiencias: [],
      formacoes: [],
      competencias: [],
      certificacoes: [],
      idiomas: [],
      buttonSave: {
        objetivos: false,
        resumo: false
      },
      /* Variaveis de validação */
      validaObjetivo: false
    };
  },
  computed: {
    candidato() {
      return this.$store.state.candidato;
    },
    curriculoState() {
      //const json2 = localStorage.getItem(cvAtual);
      const json2 = sessionStorage.getItem(cvAtual);
      const cvData = JSON.parse(json2);
      return this.$store.state.curriculos.find(c => c.id_curriculo === cvData.id_curriculo);
    },
    statusCvpro() {
      return this.cvpro[0].fl_status === 1 ? true : false;
    },
    qtdCvs() {
      return this.curriculos.length;
    },
    cargosState() {
      return this.$store.state.cargos;
    },
    competenciasState() {
      return this.$store.state.competencias;
    },
    idiomasState() {
      return this.$store.state.idiomas;
    },
    instituicoesState() {
      return this.$store.state.instituicoes;
    },
    formacoesState() {
      return this.$store.state.formacoes;
    },
    certificacoesState() {
      return this.$store.state.certificacoesState;
    },
  },
  methods: {
    inicializaValores() {
      const json = sessionStorage.getItem(userKey);
      const userData = JSON.parse(json);
      this.id_candidato = userData.id_candidato;
      const json2 = sessionStorage.getItem(cvAtual);
      const cvData = JSON.parse(json2);
      this.id_curriculo = cvData.id_curriculo;
      if(this.curriculoState === undefined) {
        setTimeout(() => {
          this.curriculo = structuredClone(this.curriculoState);
          this.loading = false;
        }, 1000);
      } else{
        this.curriculo = structuredClone(this.curriculoState);
        this.loading = false;
      }
      //console.log('this.curriculo -> ', this.curriculoState)
    },
    salvaCurriculoState() {
      this.$store.dispatch("atualizaCurriculo", this.curriculo);
    },
    atualizaResumo() {
      this.$http
        .post(`${baseApiUrl}/curriculos/atualizaresumo`, this.resumo)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: "Informação Atualizada!"
          });
          this.buttonSave.resumo = false;
        });
    },
    visualizarCv() {
      this.$router.push({ name: "visualizarcv" });
    },
    setSwtichsValue(value) {
      if (value === "1") {
        return true;
      } else {
        return false;
      }
    },
    ativaSalvar(area) {
      if (area == "objetivo") {
        //this.curriculo = this.curriculo
        return (this.buttonSave.objetivos = true);
      } else if (area == "resumo") {
        return (this.buttonSave.resumo = true);
      }
    },
    //Novas funções de Emit de componentes
    atualizaObjetivo() {
      this.curriculo.json_curriculo.nm_cargo = this.objetivo.nm_cargo;
      this.curriculo.json_curriculo.nm_area = this.objetivo.nm_area;
      this.curriculo.json_curriculo.nm_nivel = this.objetivo.nm_nivel;
      this.curriculo.json_curriculo.fl_jornada = this.objetivo.fl_jornada;
      this.curriculo.json_curriculo.fl_tipo_contrato = this.objetivo.fl_tipo_contrato;
      this.curriculo.json_curriculo.fl_pj = this.objetivo.fl_pj;
      this.curriculo.json_curriculo.fl_primeiro_emprego = this.objetivo.fl_primeiro_emprego;
      this.curriculo.json_curriculo.nu_salario = this.objetivo.nu_salario;
      this.curriculo.json_curriculo.nm_cidade = this.objetivo.nm_cidade;
      this.salvaCurriculoState();
    },
    atualizaExperiencias() {
      this.curriculo.json_curriculo.experiencias = this.experiencias;
      this.salvaCurriculoState();
    },
    atualizaFormacoes() {
      this.curriculo.json_curriculo.formacoes = this.formacoes;
      this.salvaCurriculoState();
    },
    atualizaCompetencias() {
      this.curriculo.json_curriculo.competencias = this.competencias;
      this.salvaCurriculoState();
    },
    atualizaCertificacoes() {
      this.curriculo.json_curriculo.certificacoes = this.certificacoes;
      this.salvaCurriculoState();
    },
    atualizaIdiomas() {
      this.curriculo.json_curriculo.idiomas = this.idiomas;
      this.salvaCurriculoState();
    },
    async salvarCv() {
      await this.$http({
        method: "post",
        url: '/curriculos/salvar',
        data: this.curriculo
      })
        .then(() => {
          //this.notificacao("Foto atualizada com sucesso!", 'success');
          //console.log('res -> ', res.data)
          this.$toasted.global.defaultSuccess({
            msg: "Currículo Salvo!"
          });
        })
        .catch(err => {
          //this.notificacao("Não foi possível atualizar a Foto.", 'warning');
          console.log(err);
        });
    },
  },
  mounted() {
    this.inicializaValores();
  }
  /* beforeDestroy() {
    localStorage.removeItem(cvAtual);
  } */
};
</script>
<style scoped>
.page-header {
  min-height: 0 !important;
  max-height: 999px;
  margin-top: 40px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
</style>
