<template>
  <div class="box-question">
    <div class="row mt-1 mx-1">
      <div class="col">
        <h5 class="my-0">{{propValue}}<span v-if="propRequired" class="text-danger"> *</span></h5>
      </div>
    </div>
    <div class="row mt-1 mx-1">
      <div class="col">
        <div class="form-group">
        <input type="text" class="form-control" v-model="resp" @blur="emitData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextoSimples",
  props: {
    propValue: { type: String },
    propRequired: { type: Boolean }
  },
  data() {
    return {
      resp: ""
    }
  },
  methods: {
    emitData() {
      this.$emit('resp', this.resp);
    }
  }
}
</script>

<style>
.box-question {
  box-sizing: border-box;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin: 10px 5px;
  padding: 5px 5px 5px;
}
</style>
