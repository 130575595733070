<template>
  <div class="wrapper">
    <header class="header">
      <div class="page-header">
        <div class="container">
          <div class="row">
            <!-- Cargo -->
            <div class="col-lg-4">
              <div class="form-group">
                <label for="cargo">Cargo Desejado <el-tooltip placement="top" effect="light"><div slot="content">Cargo ou posição que deseja se alocar<br/>Ex. Analista Administrativo</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                <el-autocomplete
                  ref="cargo"
                  id="cargo"
                  class="inuptAutocomplete"
                  :maxlength="255"
                  v-model="procurar.cargo"
                  :fetch-suggestions="querySearchCargos"
                  placeholder="Cargo"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </div>
            </div>
            <!-- Local de Trabalho -->
            <div class="col-lg-4">
              <div class="form-group">
                <label for="cidade">Local de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">Informe a cidade que você deseja trabalhar.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                <el-autocomplete
                  ref="cidade"
                  id="cidade"
                  class="inuptAutocomplete"
                  :maxlength="255"
                  v-model="procurar.cidade"
                  :fetch-suggestions="querySearchCidade"
                  placeholder="Cidade"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </div>
            </div>
            <!-- Botão Procurar -->
            <div class="col-lg-2 align-self-end">
              <div class="form-group">
                <base-button
                  type="primary"
                  @click="procurarVagas"
                  :disabled="validaCamposProcura()"
                  >Procurar</base-button
                >
              </div>
            </div>
            <!-- Botão de filtros -->
            <div class="col-lg-2 align-self-end">
              <div class="form-group">
                <base-button
                  type="secondary"
                  @click="flFiltros = !flFiltros"
                  ><i class="fas fa-filter"></i> Filtros</base-button
                >
              </div>
            </div>
          </div>
          <!-- Outros filtros -->
          <transition-group name="fade" mode="out-in">
            <div class="row" v-if="flFiltros" key="1">
              <!-- Campo Área de Atuação -->
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="areaatuacao">Área de Atuação <el-tooltip placement="top" effect="light"><div slot="content">Área em que você deseja trabalhar.<br/>Não é o ramo da empresa</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                  <el-select
                    ref="areaatuacao"
                    name="Área de Atuação"
                    class="inuptAutocomplete"
                    label="Área de Atuação"
                    id="areaatuacao"
                    clearable
                    v-model="procurar.areasatuacao"
                  >
                    <el-option
                      v-for="area in areasatuacao"
                      :key="area.id"
                      :value="area.id"
                      :label="area.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- Campo Nível Hierarquico -->
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="nivel">Nível Hieráquico <el-tooltip placement="top" effect="light"><div slot="content">Nível Hierárquico define o grau<br>de experiência e responsabilidades </div><i class="fas fa-question-circle"></i></el-tooltip></label>
                  <el-select
                    ref="nivel"
                    name="Nível Hieráquico"
                    class="inuptAutocomplete"
                    label="Nível Hieráquico"
                    id="nivel"
                    clearable
                    v-model="procurar.nivelhierarquico"
                  >
                    <el-option
                      v-for="nivel in niveishierarquicos"
                      :key="nivel.id"
                      :value="nivel.id"
                      :label="nivel.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- Campo Jornada -->
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="jornada">Jornada de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">A jornada se trabalho deve ser<br>entendida como o período de trabalho</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                  <el-select
                    ref="jornada"
                    name="Jornada de Trabalho"
                    class="inuptAutocomplete"
                    label="Jornada de Trabalho"
                    id="jornada"
                    clearable
                    v-model="procurar.jornada"
                  >
                    <el-option
                      v-for="jornada in jornadas"
                      :key="jornada.id"
                      :value="jornada.id"
                      :label="jornada.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- Campo Tipo de contrato -->
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="tipocontrato">Tipo de Contrato <el-tooltip placement="top" effect="light"><div slot="content">A forma de você deseja ser contratato.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                  <el-select
                    ref="tipocontrato"
                    name="Tipo de Contrato"
                    class="inuptAutocomplete"
                    label="Tipo de Contrato"
                    id="tipocontrato"
                    clearable
                    v-model="procurar.tipocontrato"
                  >
                    <el-option
                      v-for="tipo in tiposcontrato"
                      :key="tipo.id"
                      :value="tipo.id"
                      :label="tipo.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="row" v-if="flFiltros" key="2">
              <!-- Tipo de Trabalho (presencial/hibrido/homeoffice)  -->
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="tipotrabalho">Tipo de Trabalho <el-tooltip placement="top" effect="light"><div slot="content">A forma de você deseja trabalhar.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                  <el-select
                    ref="tipotrabalho"
                    name="Tipo de Trabalho"
                    class="inuptAutocomplete"
                    label="Tipo de Trabalho"
                    id="tipotrabalho"
                    clearable
                    v-model="procurar.fl_tipo_trabalho"
                  >
                    <el-option
                      v-for="tipo in tipoTrabalho"
                      :key="tipo.id"
                      :value="tipo.id"
                      :label="tipo.text"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <!-- PCD -->
              <div class="col-lg-4">
                <div class="row ml-1">
                  <label>Incluir vagas para PCD <el-tooltip placement="top" effect="light"><div slot="content">Marque essa opção se você busca<br>vagas para PCD.</div><i class="fas fa-question-circle"></i></el-tooltip></label>
                </div>
                <div class="row ml-1">
                  <base-switch
                    type="primary"
                    on-text="Sim"
                    off-text="Não"
                    v-model="procurar.pcd"
                  ></base-switch>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </header>
    <section class="section-cards mb-5">
      <div class="content-center">
        <div class="container">
          <div class="row">
            <div class="col"><h3>Vagas encontradas ({{total}})</h3></div>
          </div>
          <div class="row justify-content-sm-between">
            <div class="col-lg-3 m-1">
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-lg-3 m-1">
              <base-input v-model="searchQuery"
                prepend-icon="fas fa-search"
                placeholder="Procurar nos resultados">
              </base-input>
            </div>
          </div>
          <!-- Listagem das vagas encontradas -->
          <div class="row">
            <div class="col">
              <template v-for="(vaga, index) in queriedData">
                <div class="row" :key="index">
                  <div class="col-lg-1 align-self-center">
                    <span :class="`badge badge-${retornaTipoTrabalhoBadge(vaga.json_vaga.fl_tipo_trabalho)}`">{{retornaTipoTrabalho(vaga.json_vaga.fl_tipo_trabalho)}}</span>
                  </div>
                  <div class="col-lg-10 ml-1">
                    <p class="h5">
                      <span v-if="vaga.json_vaga.fl_deficientes">
                        <el-tooltip content="Vaga também para PcD" placement="top" effect="light"><i class="text-success fab fa-accessible-icon"></i></el-tooltip> -
                      </span>
                      <router-link :to="`/vaga/${vaga.id_processo_vaga}/${vaga.hl_link}`">{{vaga.nm_cargo}}</router-link> em <span class="text-muted">{{vaga.json_vaga.nm_cidade}}</span>
                       na {{vaga.json_vaga.fl_oculta_empresa ? 'Confidencial' : vaga.json_vaga.nm_empresa}} <span class="badge badge-success" v-if="validaCandidatura(vaga.id_processo_vaga)"><i class="fas fa-thumbs-up"></i> Participando</span>
                      <br><small>
                          Aberta em: <strong>{{vaga.dt_abertura | formatDate}}</strong> -
                          Nível: <strong>{{retornaNivelHierarquico(vaga.json_vaga.nm_nivel)}}</strong> -
                          Contratação: <strong>{{retornaTipoContrato(vaga.json_vaga.fl_contratacao)}}</strong>
                          <template v-if="!vaga.json_vaga.fl_oculta_salario"> - Salário: <strong>{{vaga.json_vaga.nu_salario.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</strong>
                          </template>
                          <br>
                          Descrição: <span class="text-muted">{{vaga.json_vaga.tx_descricao.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 300) + '...'}}</span>
                      </small>
                    </p>
                  </div>
                <hr>
                </div>
              </template>
            </div>
          </div>
          <div class="row justify-content-sm-between">
            <div class="col-lg-4">
              Mostrando {{ from + 1 }} de {{ to }} em {{ total }} vagas.
            </div>
            <div class="col-lg-4 align-self-end">
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { userKey, baseApiUrl, encryptKey, cvAtual, showError } from "@/global";
import { Select, Option, Autocomplete, Input } from 'element-ui';
import varCidades from '@/data/cidades_br.js';
import moment from "moment";
import clientPaginationMixin from './vagasPaginacao';

import BasePagination from "../../components/BasePagination.vue"

export default {
  name: "ProcurarVagas",
  mixins: [clientPaginationMixin],
  components: {
    [Autocomplete.name]: Autocomplete,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    BasePagination
  },
  data() {
    return {
      cidades: [],
      flFiltros: false,
      procurar: {
        cargo: "",
        cidade: "",
        areasatuacao: "",
        nivelhierarquico: "",
        jornada: "",
        tipocontrato: "",
        pcd: "",
        fl_tipo_trabalho: ""
      },
      //vagasEncontradas: [],
      areasatuacao: [
        { id: 51, text: "Administração" },
        { id: 52, text: "Agricultura, Pecuária, Veterinária" },
        { id: 92, text: "Alimentação / Gastronomia" },
        { id: 55, text: "Arquitetura, Decoração, Design" },
        { id: 56, text: "Artes" },
        { id: 57, text: "Auditoria" },
        { id: 58, text: "Ciências, Pesquisa " },
        { id: 59, text: "Comercial, Vendas" },
        { id: 60, text: "Comércio Exterior, Importação, Exportação" },
        { id: 62, text: "Compras" },
        { id: 63, text: "Comunicação, TV, Cinema" },
        { id: 64, text: "Construção, Manutenção" },
        { id: 66, text: "Contábil, Finanças, Economia" },
        { id: 67, text: "Cultura, Lazer, Entretenimento" },
        { id: 69, text: "Educação, Ensino, Idiomas " },
        { id: 70, text: "Engenharia" },
        { id: 71, text: "Estética" },
        { id: 72, text: "Hotelaria, Turismo" },
        { id: 73, text: "Industrial, Produção, Fábrica" },
        { id: 74, text: "Informática, TI, Telecomunicações" },
        { id: 75, text: "Jurídica" },
        { id: 54, text: "Logística" },
        { id: 77, text: "Marketing" },
        { id: 78, text: "Meio Ambiente, Ecologia" },
        { id: 93, text: "Moda" },
        { id: 79, text: "Qualidade " },
        { id: 80, text: "Química, Petroquímica" },
        { id: 81, text: "Recursos Humanos" },
        { id: 82, text: "Saúde" },
        { id: 83, text: "Segurança" },
        { id: 76, text: "Serviços Gerais" },
        { id: 85, text: "Serviço Social e Comunitário" },
        { id: 86, text: "Telemarketing" },
        { id: 87, text: "Transportes" }
      ],
      niveishierarquicos: [
        { id: 1, text: "Estagiário" },
        { id: 2, text: "Operacional" },
        { id: 3, text: "Auxiliar" },
        { id: 4, text: "Assistente" },
        { id: 5, text: "Trainee" },
        { id: 6, text: "Analista" },
        { id: 7, text: "Encarregado" },
        { id: 8, text: "Supervisor" },
        { id: 9, text: "Consultor" },
        { id: 10, text: "Especialista" },
        { id: 11, text: "Coordenador" },
        { id: 12, text: "Gerente" },
        { id: 13, text: "Diretor" }
      ],
      jornadas: [
        { id: 1, text: "Período Integral" },
        { id: 2, text: "Parcial manhãs" },
        { id: 5, text: "Parcial tardes" },
        { id: 6, text: "Parcial noites" },
        { id: 12, text: "Noturno" }
      ],
      tiposcontrato: [
        { id: 1, text: "Autônomo" },
        { id: 2, text: "Cooperado" },
        { id: 3, text: "CLT" },
        { id: 4, text: "Estágio" },
        { id: 5, text: "Outros" },
        { id: 6, text: "PJ" },
        { id: 7, text: "Temporário" },
        { id: 8, text: "Trainee" }
      ],
      tipoTrabalho: [
        { id: 1, text: "Presencial" },
        { id: 2, text: "Híbrido" },
        { id: 3, text: "Home Office" }
      ],
    }
  },
  computed: {
    cargosState() {
      return this.$store.state.cargos;
    },
    candidato() {
      return this.$store.state.candidato;
    },
    cidadeCandidato() {
      const cidade = varCidades.find(c => c.nm_cidade === this.candidato.nm_cidade);
      return cidade.nm_cidade_uf
    },
    vagasEncontradas() {
      return this.$store.state.vagas;
    },
    candidaturas() {
      return this.$store.state.candidaturas;
    }
  },
  methods: {
    querySearchCidade(queryString, cb) {
      let cidades = this.cidades;
      var results = queryString ? cidades.filter(this.createFilterCidade(queryString)) : cidades;
      cb(results)
    },
    createFilterCidade(queryString) {
      return (cidade) => {
        return (cidade.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    ajustaCidades() {
      varCidades.forEach(element => {
        let city = {
          id: element.id_cidade,
          value: element.nm_cidade_uf
        }
        this.cidades.push(city);
      });
    },
    querySearchCargos(queryString, cb) {
      let cargos = this.cargosState;
      var results = queryString ? cargos.filter(this.createFilterCargos(queryString)) : cargos;
      cb(results)
    },
    createFilterCargos(queryString) {
      return (cargo) => {
        return (cargo.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    retornaTipoTrabalho(value) {
      var res = this.tipoTrabalho.find(i => i.id === parseInt(value));
      return res.text;
    },
    retornaTipoTrabalhoBadge(value) {
      if (value === '1'){
        return "primary";
      } else if(value === '2'){
        return "info";
      } else {
        return "success";
      }
    },
    retornaNivelHierarquico(id) {
      let item = this.niveishierarquicos.find(u => u.id == id);
      return item.text;
    },
    retornaJornada(id) {
      let item = this.jornadas.find(u => u.id == id);
      return item.text;
    },
    retornaTipoContrato(id) {
      let item = this.tiposcontrato.find(u => u.id == id);
      return item.text;
    },
    validaCandidatura(id) {
      var vaga = this.candidaturas.findIndex(i => i.id_processo_vaga === id);
      if(vaga > -1) {
        return true;
      } else {
        return false;
      }
    },
    validaCamposProcura() {
      if(this.procurar.cidade === '' && this.procurar.cargo === '') {
        return true;
      } else {
        return false;
      }
    },
    async carregaCargos() {
      await this.$http.get('/sysinfos/getall')
        .then(res => {
          this.$store.dispatch('defineSysInfos', res);
        })
        .catch(err => {
          console.log('erro do axios ->> ', err)
        });
    },
    async procurarVagas() {
      await this.$http
        .post(`${baseApiUrl}/procurarvagas`, this.procurar)
        .then(res => {
          this.$store.dispatch('defineVagas', res.data.vagas)
          //this.vagasEncontradas = res.data.vagas;
          ///console.log('res -> ', res)
        })
        .catch(err => {
          console.log('err -> ', err)
        });
    },
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
        //return moment(String(value)).format("L");
      }
    }
  },
  mounted() {
    this.ajustaCidades();
    if(this.cargosState.length < 1) {
      this.carregaCargos();
    }
    if(this.$route.params.cargo) {
      this.procurar.cargo = this.$route.params.cargo;
      this.procurar.cidade = this.$route.params.cidade;
      setTimeout(() => {
        this.procurarVagas();
      }, 500);
    } else {
      this.procurar.cidade = this.cidadeCandidato;
    }
  }
}
</script>

<style scoped>
.page-header {
  min-height: 0 !important;
  max-height: 999px;
  margin-top: 40px;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.page-header > .container {
  padding-bottom: 10px;
  padding-top: 60px;
}
.inuptAutocomplete {
  width: 100%;
  height: 40px;
}
hr {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
</style>
