import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyAeez1FKafpVmLg1bQLmAOmQ_WqcgU2yxQ",
  authDomain: "matchjob-candidato.firebaseapp.com",
  databaseURL: "https://matchjob-candidato-default-rtdb.firebaseio.com",
  projectId: "matchjob-candidato",
  storageBucket: "matchjob-candidato.appspot.com",
  messagingSenderId: "1087258883202",
  appId: "1:1087258883202:web:7f5da43585b60658b4f09b",
  measurementId: "G-K1QZJG16GJ"
};

//const firebaseApp = firebase.initializeApp(firebaseConfig)
firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

//export default firebaseApp
export { projectFirestore, projectAuth, timestamp };
