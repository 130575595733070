<template>
  <card class="meucard" headerClasses="meucard-header">
    <template slot="body">
      <slot></slot>
    </template>
  </card>
</template>

<script>
export default {
  name: "CardDashboardSemTitulo",
  props: {
    icone: { type: String, required: false },
    titulo: { type: String, required: false }
    /* valor: { type: String, required: true},
    cor: { type: String, required: true}, */
  }
};
</script>

<style>
.meucard {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.meucard-header {
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>
