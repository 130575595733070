<template>
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini">
      <div class="page-header page-header-small header-filter">
        <div
          class="page-header-image"
          style="background-image: url('https://storagematchjobdefault.blob.core.windows.net/bg-matchjob/project17.jpg');"
        ></div>
        <div class="container">
          <div class="header-body text-center mb-7">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                <h1 class="text-white">Olá Candidato!</h1>
                <p class="text-lead text-white">
                  Já vamos providenciar a recuperação da sua senha de acesso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="upper">
      <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
          <div class="card bg-secondary shadow border-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h3 class="display-3 mb-0">MATCHJOB</h3>
                <small>Digite o seu e-mail cadastrado no campo a baixo.</small>
              </div>
              <form role="form">
                <div class="form-group mb-3">
                  <base-input
                    alternative
                    type="email"
                    name="username"
                    placeholder="E-mail"
                    addonLeftIcon="ni ni-email-83"
                    v-model="user.username"
                  ></base-input>
                </div>
                <base-alert type="warning" v-if="errLogin.status">
                  <strong>Ops!</strong> {{ errLogin.msg }}
                </base-alert>
                <base-alert type="success" v-if="resp.status">
                  <strong>Ops!</strong> {{ resp.body.error_description }}
                </base-alert>
                <div class="text-center">
                  <base-button type="primary" class="my-4" @click="resetSenha"
                    >Recuperar Senha</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
export default {
  bodyClass: "login-page",
  data() {
    return {
      user: {
        username: ""
      },
      resp: {
        status: false
      },
      errLogin: {
        status: false,
        msg: ""
      }
    };
  },
  methods: {
    resetSenha() {
      this.$http
        .post(`${baseApiUrl}/login/reenviarsenha`, this.user)
        .then(res => {
          this.resp.status = true;
          this.resp.body = res.data;
        })
        .catch(err => {
          this.errLogin.status = true;
          this.errLogin.msg = err.response.data.error_description;
        });
    }
  }
};
</script>

<style></style>
